import Select from "react-select";

const GenericSelect = (props) => {
  return (
    <>
      <Select {...props} styles={colourStyles} />
    </>
  );
};
export default GenericSelect;

var colourStyles = {
  menuList: (styles) => ({
    ...styles,
    background: "white",
    width: "100%",
  }),
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "white",
      borderColor: "#ff7200",
      boxShadow: "none",
      cursor: "pointer",
      ":focus": {
        ...styles[":focus"],
        borderColor: "#ff7200",
        boxShadow: "none",
      },
      ":active": {
        ...styles[":active"],
        borderColor: "#ff7200",
        boxShadow: "none",
      },

      ":hover": {
        ...styles[":hover"],
        borderColor: "#ff7200",
        boxShadow: "none",
      },
      //   height: 35,
      //   minHeight: 35,
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    background: isDisabled
      ? "#727376"
      : isFocused
      ? "#ff7200"
      : isSelected
      ? "#fff"
      : undefined,
    color: isDisabled ? "#fff" : isFocused ? "#fff" : "black",
    ":active": {
      ...styles[":active"],
      backgroundColor: !isDisabled
        ? isSelected
          ? "var(--clr--accent)"
          : undefined
        : undefined,
      boxShadow: "none",
      border: "1px solid #ff7200",
    },

    cursor: isDisabled ? "not-allowed" : "default",
    zIndex: 1,
  }),
  //   menu: (base) => ({
  //     ...base,
  //     zIndex: 100,
  //   }),
  //   valueContainer: (provided, state) => ({
  //     ...provided,
  //     height: "35px",
  //     // margin: "-8px 0 0 0",
  //   }),

  //   input: (provided, state) => ({
  //     ...provided,
  //     margin: "0",
  //   }),
  //   indicatorSeparator: (state) => ({
  //     display: "none",
  //   }),
  //   indicatorsContainer: (provided, state) => ({
  //     ...provided,
  //     height: "35px",
  //   }),
};
