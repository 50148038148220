import { post } from './../../../services/requests'
import { DATA_FIELDS } from './types'

export const getDataFields = () => {
    return (dispatch) => {
        post('contract/getfields', {})
            .then(response => {
                const data = response.data
                dispatch(dataFields(data))
            })
            .catch(error => {
                console.log('error', error);
            })
    }
}
export const dataFields = (data) => {
    return {
        type: DATA_FIELDS,
        payload: data
    }
}

