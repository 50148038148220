import { post } from './../../../services/requests'
import { GET_DEPARTMENT } from './types'

const pageSize = 5
export const getDepartments = () =>{ 
    return(dispatch)=>{
        post('department/GetAllDepartments',{pageSize: pageSize, bookmark: ''})
        .then(response=>{
             const departmentData= response.data
             if(departmentData !== undefined || departmentData.length !== 0){
                dispatch(storeDepartment(departmentData))
             }
             else{
                console.log('departmentData',departmentData);
             }
             
             
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const storeDepartment = (departmentData) => {
    return {
        type: GET_DEPARTMENT,
        payload: departmentData
    }
}