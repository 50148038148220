import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import axios from 'axios'
import { getDepartments } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'

const UsersModal = (props) => {
    const dispatch = useDispatch()
    const [department, setDepartment] = useState({
        departmentName: ''
    })
    const [overlapText, setOverlapText] = useState('')
    const [useroverlapText, setUserOverlapText] = useState('')

    const _closeModal = (id) => {
        $("#userModal").removeClass("custom-modal-is-visible")
    }
    const categoryInput = (e) => {
        setDepartment({ ...department, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        if (department.departmentName.length !== 0) {
            setOverlapText('overlap')
        }
        else {
            setOverlapText('')
        }
    }, [department])

    const addDepart = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/department/add-department', { departmentName: department.departmentName }, { headers: config.headers })
            .then(response => {
                console.log('add department response', response);
                const status = response.data.error
                const message = response.data.message
                const err = response.data.error
                dispatch(getDepartments())
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                if(err === false){
                    $("#userModal").removeClass("custom-modal-is-visible")
                }
                
            })
            .catch(error => {
                console.log('error', error);
                window.$.fn.show_notification({ message: `Request Failed`, title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            })
    }
    const saveUserType = () => {
        $("#userModal").removeClass("custom-modal-is-visible")
    }

    return (
        <>
            {
                props.text === 'department' ?
                    <section id="userModal" className="custom-modal" style={{ width: "45%" }}>
                        <div className="custom-modal-header">
                            <h5>Add your Department </h5>
                            <span className="ct-close" onClick={() => _closeModal('userModal')}></span>
                        </div>
                        <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '75vh' }}>
                            <div className='categoryDiv'>
                                <div className='categoryDiv1'>
                                    <span className='categorySpan'>Please Enter your Department</span>
                                    <input className='categoryInput' name='departmentName' value={department.departmentName} onChange={(e) => categoryInput(e)} type='text' placeholder='Enter Department Name' />
                                    {overlapText === 'overlap' ?
                                        <span className='overlapCategoryCode'>Department Name</span>
                                        : ''}
                                </div>
                                <div className='categoryDiv2'>
                                    <button className='saveCategory' onClick={() => addDepart()}>Save</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section id="userModal" className="custom-modal" style={{ width: "45%" }}>
                        <div className="custom-modal-header">
                            <h5>Add User Type </h5>
                            <span className="ct-close" onClick={() => _closeModal('userModal')}></span>
                        </div>
                        <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '75vh' }}>
                            <div className='categoryDiv'>
                                <div className='categoryDiv1'>
                                    <span className='categorySpan'>Please Enter User Type</span>
                                    <input className='categoryInput' name='userType' value={department.userType} onChange={(e) => categoryInput(e)} type='text' placeholder='Enter User Type' />
                                    {useroverlapText === 'overlap' ?
                                        <span className='overlapCategoryCode'>User Type</span>
                                        : ''}
                                </div>
                                <div className='categoryDiv2'>
                                    <button className='saveCategory' onClick={saveUserType}>Save</button>
                                </div>
                            </div>
                        </div>
                    </section>
            }
        </>

    )
}

export default UsersModal;