import {DELIVERY , DELIVERY_HIDE , DELIVERY_SHOW } from  "../actions/types";

const initialState={
    podLists: [], 
    loading:true
} 

const deliverRed =(state=initialState,{type,payload})=>{
    switch(type){
        case DELIVERY : return{
            ...state,
            podLists : payload 
        }
        case DELIVERY_HIDE : return{
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default deliverRed ;