import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import axios from 'axios'
import FileUpload from './../FileUpload'
import { get_pdf_thumbnail, base64Convert } from './../../../services/functions'
import history from '../../../services/history';

const GeneratePod = (props) => {
    const sharedObj= props.generateObj
    const [fileArr, setFileArr] = useState([])
    const [imgBase, setImgBase] = useState('')
    const [imgIndex, setImgIndex] = useState()
    const lrIndex = sharedObj.ind

    const [podObj, setPodObject] = useState(
        {
            lrId: "",
            customerName: "",
            documents: []
        }
    )
    const _handleChange = (e) => {
        setPodObject({ ...podObj, [e.target.name]: e.target.value })
    }

    const _handleFiles = async (files) => {
        if (files.length > 0) {
            let tempArr = [...fileArr]
            let tarr = Array.from(files)
            for (let el of tarr) {
                if (el.type.includes('image') || el.type === "application/pdf") {
                    try {
                        let base64 = await base64Convert(el)
                        let obj = { base64, fileType: el.type.includes('image') ? 'image' : 'pdf', name: el.name }

                        if (obj.fileType === 'pdf') {
                            let pdf = base64.replace(/^data:application\/\pdf;base64,/, "")
                            var binary_string = window.atob(pdf);
                            var len = binary_string.length;
                            var bytes = new Uint8Array(len);
                            for (var i = 0; i < len; i++) {
                                bytes[i] = binary_string.charCodeAt(i);
                            }
                            var buffer = bytes.buffer
                            var file3 = new Blob([buffer], { type: 'application/pdf' });
                            obj['dataLocalUrl'] = file3
                            get_pdf_thumbnail(file3).then((url) => {
                                obj['imageSrc'] = url
                            })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                        tempArr.unshift(obj)
                    }
                    catch (err) {

                    }
                }
                else {
                    //Swal.fire("Oops","Only image or PDF file is allowed to upload",'warning')
                }
            }
            setFileArr(tempArr)
        }
    }
    const deleteFile = (index) => {
        let tempArr = [...fileArr]
        tempArr.splice(index, 1)
        setFileArr(tempArr)
    }
    const previewImg = ({ item, index }) => {
        setImgBase(item.base64)
        setImgIndex(index)
    }
    const removeBase = () => {
        setImgBase()
    }
    useEffect(() => {
    }, [fileArr, props])

    const generate = () => {
        let result = fileArr.map(a => a.base64);
        podObj.documents = result
        podObj.lrId = lrIndex

        if (podObj.customerName !== '') {
            const token = localStorage.getItem('secretkey')
            const config = {
                headers: { Authorization: token }
            };
            axios.post('https://www.betaenterprise.lynkit.io/api/booking/GeneratePOD', { ...podObj }, { headers: config.headers })
                .then(response => {
                    console.log('res', response);
                    const error = response.data.error
                    const message = response.data.message
                    if (error === false) {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                        history.push({
                            state: sharedObj,
                            pathname:'/dashboard/delivery'
                        })
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('err', error);
                })
        }
        else{
            window.$.fn.show_notification({ message: 'Customer Name cannot be empty..', title: 'Message!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }
    
    return (
        <>
            {imgBase === '' || imgBase === undefined ?
                <section id="GeneratePoDModal" className="custom-modal" style={{ width: "45%" }}>
                    <div className="custom-modal-header">
                        <h5>Generate POD </h5>
                        <span className="ct-close" onClick={props.closeModal}></span>
                    </div>
                    <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '75vh' }}>
                        <div className='podCustName'>
                            <span>Customer Name :</span>
                            <input name='customerName' value={podObj.customerName} onChange={(e) => _handleChange(e)} className='custInpPod' type='text' placeholder='Customer Name' />
                        </div>
                        <div className="cust-row">
                            <div className='cust-col-10'>
                                <FileUpload handleFiles={_handleFiles} />
                            </div>
                        </div>
                        <div className="vd_cont_3_sec_bdy">
                            {fileArr.length ?
                                <div className='file_preview'>
                                    <p>Uploaded Files</p>
                                    <div className='file_preview_cont'>
                                        {
                                            fileArr.map((item, index) => {
                                                return <div key={`pp-` + index} className='preview_itm bttmm-mg' >
                                                    <div className='closeb' onClick={() => { deleteFile(index) }}>
                                                        <i className='fa fa-close'></i>
                                                    </div>
                                                    <img src={item.base64} alt="" onClick={() => previewImg({ item, index })} />
                                                    <div className='fullscreen'>
                                                        <i className='fa fa-expand' onClick={() => previewImg({ item, index })}></i>
                                                    </div>
                                                    {/* <div className='downloadDocBtn'>
                                                    <i className="fa fa-download" onClick={() => DownloadImage(index)}></i>
                                                </div> */}
                                                    <span className='overSpanName'>{item.name}</span>
                                                </div>

                                            })
                                        }
                                    </div>
                                </div>
                                : ""}
                        </div>
                        <div className='btn_cont post-btm'>
                            <button className='button bti bg-orange-out m-0 btn-size'>
                                <div className='btn_icon'>
                                    <i className='fa fa-upload'></i>
                                </div>
                                <div className='btn_txt font-lg' onClick={() => generate()}>Confirm POD</div>
                            </button>
                        </div>
                    </div>
                </section>
                :
                <section id="UploadContractCopyModal" className="custom-modal" style={{ width: "45%" }}>
                    <div className="custom-modal-header">
                        <h1>Uploaded Document</h1>
                        <span className="ct-close" onClick={() => { removeBase() }}></span>
                    </div>
                    <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '60vh' }}>
                        <div className='imgContainer'>
                            <img src={imgBase} alt='' />
                        </div>
                    </div>
                </section>
            }

        </>

    )
}

export default GeneratePod;