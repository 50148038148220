import { get } from './../../../services/requests'
import { GET_VEHICLE, HIDE } from './types'

export const getVehicleData = (VehicleNumber) =>{ 
    return(dispatch)=>{
        get(`vehicle/GetVehicle/${VehicleNumber}`)
        .then(response=>{
             console.log('single vehicle response',response);
             const vehicleData= response.data
             dispatch(storeVehicle(vehicleData)) 
             dispatch(hideLoader())
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
} 
export const storeVehicle = (vehicleData) => {
    return {
        type: GET_VEHICLE,
        payload: vehicleData
    }
}
export const hideLoader = () => {
    return {
        type: HIDE
    }
}


