import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import './../../static/css/driver.css'
import history from './../../services/history';
import moment from 'moment'
import view from './../../static/images/icon/buttons/ViewDetail.svg'
import cancel from './../../static/images/icon/buttons/Cancel.svg'
import Delete from './../../static/images/icon/buttons/Delete.svg'
import update from './../../static/images/icon/buttons/Update.svg'
import documentView from './../../static/images/icon/buttons/ViewDocuments.svg'
import { selectInit } from './../../services/functions'
import { listingApi } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

const Driver = () => {
    const dispatch = useDispatch()
    const driverList = useSelector((state) => state.getListingReducer.driverListing)
    const bookmark = useSelector((state) => state.getListingReducer.bookmarks)
    const loader = useSelector((state) => state.getListingReducer.loading)
    const records = useSelector((state) => state.getListingReducer.records)
    const transportersList = useSelector((state) => state.getListingReducer.transporterListing)
    const [searchObject, setSearchObject] = useState(
        {
            pageSize: "",
            bookmark: "",
            searchType: "",
            searchValue: "",
            orgId: "",
            docType: 'driver'
        }
    )
    const [val, setVal] = useState('')
    const [valBlack, setValBlack] = useState('')
    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")
        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }
    const navigateAndShow = (DLNumber) => {
        // history.push({
        //     state: item,
        //     pathname: '/dashboard/driver/detail'
        // })
        history.push({
            pathname: "/dashboard/driver/details",
            state: { State: 'driver', DLNumber: DLNumber }
        })
    }
    const [obj, setObj] = useState(
        {
            docType: "driver",
            bookmark: '',
            param: "next"
        }
    )
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 100);
        dispatch(listingApi({
            docType: "transporter",
            bookmark: "",
            param: "next"
        }))

        bookmark.length = 0
        setObj(
            {
                docType: "driver",
                bookmark: '',
                param: "next",
                pageSize: "",
                searchType: "",
                searchValue: "",
                orgName: "",
            }
        )
        dispatch(listingApi(obj))

    }, [dispatch])

    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            docType: "driver"
        }
        setObj(object)
        dispatch(listingApi(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            docType: "driver"
        }
        setObj(object)
        dispatch(listingApi(object))
        bookmark.pop()
    }
    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchType = value
            setSearchObject(temp)
        }
        else if (id === 'driverStatus') {
            setVal(value)
        }
        else if (id === 'transporter') {
            setValBlack(value)
        }

    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(listingApi({
            pageSize: "",
            bookmark: "",
            searchType: searchObject.searchType,
            searchValue: searchObject.searchValue,
            orgId: "",
            docType: 'driver'
        }))
    }
    useEffect(() => {
        if (val) {
            dispatch(listingApi({
                pageSize: "",
                bookmark: "",
                searchType: '',
                searchValue: '',
                orgId: "",
                docType: 'driver',
                orgId: "",
                blacklistedFlag: JSON.parse(val),
            }))
        }
    }, [val])

    useEffect(() => {
        if (valBlack) {
            dispatch(listingApi({
                pageSize: "",
                bookmark: "",
                searchType: '',
                searchValue: '',
                orgId: "",
                docType: 'driver',
                orgId: transportersList[valBlack]._id,
                blacklistedFlag: "",
            }))
        }
    }, [valBlack])

    const blacklist = (item) => {
        const finalObj = {
            dlNumber : item.DLNumber,
            endorseDate  :  item.EndorseDate,
            endorseNumber : item.EndorseNumber,
            expiryDate : item.ExpiryDate,
            fatherName : item.FatherName,
            issuedDate : item.IssuedDate,
            licenseType : item.LicenseType,
            vaccinationStatus : item.VaccinationStatus,
            mobileNo : item.MobileNo,
            addresses : item.Addresses,
            blacklistedFlag: item.BlacklistedFlag === false ? true : false,
            name : item.Name,
            emailId : item.EmailId 
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: `Are you sure ? `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {
                axios.post('https://www.betaenterprise.lynkit.io/api/driver/UpdateDriver', { ...finalObj }, { headers: config.headers })
                    .then(response => {
                        const message = response.data.message
                        const err = response.data.error
                       dispatch(listingApi(obj))
                        if (err === 'false') {
                            window.$.fn.show_notification({ message: `${message} `, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message} `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                    })
            }
        })

    }
    return (
        <div className="contentpanel">
            <h1 className="title">Driver</h1> 
            <hr />
            <div className="cust-container">
                <div className='cust-row'>
                    <div className='cust-col-2 flex-algn-cent'>
                        <div className='tt2'>Drivers List</div>
                    </div>
                    <div className='cust-col-4-new'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <select className="select2-single" defaultValue="select" id="searchType">
                                    <option value="select" disabled>Select</option>
                                    <option value="dlNumber">License No.</option>
                                    <option value="name">Driver Name</option>
                                    <option value="mobileNo">Mobile Number</option>
                                </select>
                            </div>
                            <input type="text" className="form-control" name='searchValue' value={searchObject.searchValue} onChange={(e) => _onChangeSearch(e)} placeholder="Search here..." />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2"
                                    disabled={searchObject.searchType === 'select' || searchObject.searchType === undefined ? true : false}
                                    onClick={() => triggerSearch()}>
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='cust-col-2'>
                        <select className="form-control select2-single" defaultValue="status" id="driverStatus">
                            <option value="status" disabled>Driver Status</option>
                            <option value="false">Approved Driver</option>
                            <option value="true">Blacklisted Driver</option>
                        </select>
                    </div>
                    <div className='cust-col-2'>
                        {
                            localStorage.getItem("userGroup") !== 'transporter' ?
                                <select className="form-control select2-single" defaultValue="all" id="transporter">
                                    <option value="all" disabled>Transporter's Name</option>
                                    {transportersList.map((item, index) => {
                                        return (
                                            <option value={index} key={index} >{item.OrgName}</option>
                                        )
                                    })}
                                </select>
                                : ""
                        }
                    </div>
                    <div className='cust-col-2 flex-jc-end'>
                    </div>
                </div>

                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='data-cont'>

                            {driverList && driverList.length !== 0 ?
                                driverList.map((item, index) => {
                                    return (
                                        <div key={index} className={item.BlacklistedFlag === true ? 'data-cont-item blackListBrdr' : 'data-cont-item'}>
                                            {localStorage.getItem("userGroup") === 'transporter' ? "" :
                                                <div className='data_item_content align-div pad-0'>
                                                    <div className='wd_30 clip-path display-col'>
                                                        <span className='tbl-hd-1'>Transporter :</span>
                                                        <span className='tbl-hd-2'>{item.OrgName ? item.OrgName : "-"}</span>
                                                        <span className='tbl-hd-3'>{item.OrgReadableID ? `(ID - ${item.OrgReadableID})` : ""}</span>
                                                    </div>
                                                    <div className='wd_30 bg-trans inc-hgt'>
                                                        <div className='dateTime'>
                                                            <span>Date/Time</span>
                                                        </div>
                                                        <div className='date'>
                                                            <i className="fa fa-calendar"></i>
                                                            <span>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div className='time'>
                                                            <i className="fa fa-clock-o xl"></i>
                                                            <span>{moment(item.AddedOn).format("h:mm:ss a")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className='data_item_content set_height pad-top-0'>
                                                <div className='wd_5 align-cent set-hght'>
                                                    <div className='sr-no '>
                                                        <span>{index + 1}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span>Driver Name</span>
                                                    <span>{item.Name}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Driver Phone Number</span>
                                                    <span>{item.MobileNo}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>License Number</span>
                                                    <span>{item.DLNumber}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Validity from</span>
                                                    <span>{moment(item.IssuedDate).format('DD/MM/YYYY')}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Validity to</span>
                                                    <span>{moment(item.ExpiryDate).format('DD/MM/YYYY')}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Blacklisted Status</span>
                                                    <span>{item.BlacklistedFlag === true ? 'True' : 'False'}</span>
                                                </div>

                                                <div className='wd_5 align-cent set-hght set-bg'>
                                                    <div className='sr-no trans' onClick={() => { toogleItemView(index) }}>
                                                        <i className="fa fa-plus expand-tbl open"></i>
                                                        <i className="fa fa-minus contract-tbl hide" ></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='data_item_content set_height_xxl setColor clps  '>
                                                <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_15" : "wd_15"}>
                                                    <span>Date of Birth</span>
                                                    <span>{moment(item.UserDOB).format('DD/MM/YYYY')}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Aadhar No.</span>
                                                    <span>{item.AadhaarNumber}</span>
                                                </div>
                                                <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_15" : "wd_15"}>
                                                    <span>State</span>
                                                    <span>Local -{item.Addresses[0] && item.Addresses[0].state ? item.Addresses[0].state : '-'}</span>
                                                    <span>Permanent - {item.Addresses[1] && item.Addresses[1].state ? item.Addresses[1].state : '-'}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>City</span>
                                                    <span>Local - {item.Addresses[0] && item.Addresses[0].district ? item.Addresses[0].district : '-'}</span>
                                                    <span>Permanent - {item.Addresses[1] && item.Addresses[1].district ? item.Addresses[1].district : '-'}</span>
                                                </div>
                                                <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_20" : "wd_20"}>
                                                    <span>Endorse Date</span>
                                                    <span>{moment(item.EndorseDate).format('DD/MM/YYYY')}</span>

                                                </div>
                                                <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_20" : "wd_20"}>
                                                    <span>Vaccination status</span>
                                                    <span>-</span>
                                                    {/* <i className="fa fa-file" id="vaccination_document"></i> */}
                                                </div>
                                                {localStorage.getItem("userGroup") === 'transporter' ?
                                                    <div className='wd_10' >
                                                        <span>Black Listed status</span>
                                                        <span>(Lynkit)</span>
                                                    </div>
                                                    : ""}
                                                <div className={localStorage.getItem("userGroup") === 'transporter' ? 'wd_30 no-mg-pd designChange' : 'wd_40 no-mg-pd designChange'} >
                                                    <div className='btn_row_1'>
                                                        {localStorage.getItem("userGroup") !== 'transporter' ?
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={update} alt="none" />Update</button>
                                                            : ""}
                                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={documentView} alt="none" />View Documents</button>
                                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={Delete} alt="none" />Delete</button>
                                                        {/* </div>

                                            <div className='btn_row_2'> */}
                                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={() => navigateAndShow(item._id)} ><img src={view} alt="none" />View Detail</button>
                                                        {localStorage.getItem("userGroup") !== 'transporter' ?
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={() => blacklist(item)}><img src={cancel} alt="none" />Blacklist</button>
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <span className='noDataClass'>No Data Found</span>
                            }
                        </div>

                    </div>
                </div>
            </div>
            {driverList && driverList.length !== 0 ?
                <div className='paginationDiv'>
                    <button disabled={obj && obj.bookmark === '' ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                    <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                </div>
                : ''}
        </div>

    )

}

export default Driver;