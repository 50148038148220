import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery'
import InspectImgModal from '../../Dashboard/Modal/InspectImgModal'

const MaterialReport = (props) => {
    const report = props.report

    const [imgItem, setItem] = useState('')

    const toogleHubView = (index, check = true) => {
        if ($(".collapse-view").eq(index).hasClass("open") && check) {
            $(".collapse-view").eq(index).removeClass("open");
            $(".collapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
            let height =
                Number($(".collapse-view").eq(index).children("table").height()) + 2;
            $(".collapse-view")
                .eq(index)
                .animate({ height }, 500, () => {
                    $(".collapse-view").eq(index).addClass("open");
                });
        }
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }


    return (
        <section id="MaterialReportModal" className="custom-modal" style={{ 'width': '60%' }}>
            <div className="custom-modal-header">
                <h1>Material Gate Out Report</h1>
                <span className="ct-close" onClick={_closeModal}></span>
            </div>
            <div className="custom-modal-body" >
                <div className='MaterialDiv' style={{ overflow: 'auto', height:"auto" }}>
                    {report ?
                        report.TruckDetails && report.TruckDetails.Inspection[1] && report.TruckDetails.Inspection[1].InspectionReport.map((item, index) => {
                            return (
                                item.type === "GateOutChecks" ?
                                    <div key={index} className="table-responsive collapse-view collapseTableInspect">
                                        <div class="collapsable_icon" onClick={() => { toogleHubView(0) }}></div>
                                        <table className='cctbl'>
                                            <thead>
                                                <tr class="bg_black">
                                                    <div className='insTableHead'>
                                                        <div className='insHeader1'>
                                                            <span>Gate Out Inspection</span>
                                                        </div>
                                                        <div className='insHeader2'>
                                                            <span>{item.status === 'Fail' ? <span className='inspectFail'>{item.status} </span> : <span className='inspectPass'>{item.status} </span>}</span>
                                                        </div>
                                                    </div>
                                                </tr>
                                                <tr class="bg_black">

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <div>
                                                        <div className="btblock">

                                                            <div className='inpectionInner1'>
                                                                <div className='inpectionHeader'>
                                                                    <div className='inpectBox22'>
                                                                        <span className='inpectHeadSpan'>SNo.</span>
                                                                    </div>
                                                                    <div className='inpectBox33'>
                                                                        <span className='inpectHeadSpan'>Clauses</span>
                                                                    </div>
                                                                    <div className='inpectBox33'>
                                                                        <span className='inpectHeadSpan'>Status</span>
                                                                    </div>
                                                                </div>
                                                                {item.checks.map((a, b) => {
                                                                    return (
                                                                        <div className='inspectBody'>
                                                                            <div className='inpectBox22'>
                                                                                <span>{b + 1}</span>
                                                                            </div>
                                                                            <div className='inpectBox33'>
                                                                                <span>{a.clause}</span>
                                                                            </div>
                                                                            <div className='inpectBox33'>
                                                                                {a.result === 'Pass' ? <span className='inpectHeadSpan inspectPass'>Pass</span>
                                                                                    :
                                                                                    <span className='inpectHeadSpan inspectFail'>Fail</span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>




                                                        </div>
                                                    </div>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    : ''
                            )
                        })
                        :
                        <div className='reportElemNotFound'>
                            <span>No Report found..</span>
                        </div>
                    }


                </div>
            </div>


            <InspectImgModal base={imgItem} />
        </section>
    )

}

export default MaterialReport;