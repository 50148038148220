import React from 'react';

const Analysis = (props) => { 

    return(
        <section id="analysisModal" className="custom-modal bg-wht" style={{width:"40%",height:"70vh"}}>
            <div className="custom-modal-header">
            <h1 className='font-bold grey_color'>Trip Duration Analysis</h1>
            <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{overflowX:'auto',height:'60vh'}}>
                <div className="cust-row">
                    <div className='cust-col-10'>
                        <div className="analysis_flex1">
                            <div className='analysis_item1'>
                                <div className='dv_flex wdt100 pp1'>
                                    <div className='wdt30 no_brd_rht'>
                                        <p className='black_color font-bold font-md mb-1'>At Pickup Point</p>
                                        <p className='grey_color font-bold font-lg mb-1'>20%</p>
                                    </div>
                                    <div className='wdt50 no_brd_rht'>
                                        <div class="adc22">
                                            <div class="joining-line"></div>
                                            <h5>
                                                <p className='wdt10 text-left font-bold'>Hindalco Faridabad</p>
                                            </h5>
                                            <h5>
                                                <p className='wdt10 text-left font-bold'>Hindalco Bhilad</p>
                                            </h5> 
                                        </div>
                                    </div>
                                    <div className='flex-algn-cent flex-jc-center wdt30'>
                                        <p className='black_color font-bold font-lg mb-0 ls10'>00d 10h 20m</p>
                                    </div>
                                </div>
                                {/* <div className='ntc'></div> */}
                            </div>
                            <div className="analysis_item1">
                                <div className='dv_flex wdt100'>
                                    <div className='wdt25'>
                                        <div className='wdt100 flex-jc-center position-relative'>
                                            <div class="pie animate-chart no-round" style={{"--p":"20","--c":"#FFD300","--b":"15px","--w":"120px"}}></div>
                                        </div>
                                        <div className='lbl1'>
                                            <div className='tt cc1'>Source</div>
                                        </div>
                                    </div>
                                    <div className='wdt25'>
                                        <div className='wdt100 flex-jc-center position-relative'>
                                            <div class="pie animate-chart no-round" style={{"--p":"35","--c":"#FF7200","--b":"15px","--w":"120px"}}> </div>
                                        </div>
                                        <div className='lbl1'>
                                            <div className='tt cc2'>In Transit</div>
                                        </div>
                                    </div>
                                    <div className='wdt25'>
                                        <div className='wdt100 flex-jc-center position-relative'>
                                            <div class="pie animate-chart no-round" style={{"--p":"15","--c":"#800080","--b":"15px","--w":"120px"}}></div>
                                        </div>
                                        <div className='lbl1'>
                                            <div className='tt cc3'>Via Hub</div>
                                        </div>
                                    </div>
                                    <div className='wdt25'>
                                        <div className='wdt100 flex-jc-center position-relative'>
                                            <div class="pie animate-chart no-round" style={{"--p":"30","--c":"#228B22","--b":"15px","--w":"120px"}}></div>
                                        </div>
                                        <div className='lbl1'>
                                            <div className='tt cc4'>Arrived</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="analysis_item2">
                                <div>
                                    <p id="total_time" className='font-bold font-lg mb-1'>5d 1h 37m</p>
                                    <p className='font-bold font-md grey_color mb-1'>Total Time</p>
                                </div>
                                <div>
                                    <p id="stopage_time" className='font-bold font-lg mb-1'>5d 1h 37m</p>
                                    <p className='font-bold font-md grey_color mb-1'>Time Spend At Hub</p>
                                </div>
                                <div>
                                    <p id="transit_time" className='font-bold font-lg mb-1'>0d 0h 0m</p>
                                    <p className='font-bold font-md grey_color mb-1'>Time Spend In Transit</p>
                                </div>
                            </div>
                            <p className='text-center mb-1 mt-1 ddtxt'>
                                <i className='fa fa-truck green_color font-bold'></i>
                                <span className='font-bold font-md'>Delivered On :</span> 
                                <span className='font-bold font-md green_color ml-1'>Nov 30th 2021 12:06 PM</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Analysis;