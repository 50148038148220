import {GET_ALL_CONTRACTS , HIDE_CONTRACT } from  "../actions/types";

const initialState={
    contractData: [],
    loading:true,
    bookmarks: [],
    records: '',
} 

const getContractReducers = (state=initialState,{type,payload})=>{
    switch(type){
        case GET_ALL_CONTRACTS : return{
            ...state,
            contractData : payload 
        }
        case HIDE_CONTRACT : return{
            ...state,
            loading : false
        }
        // case PAGINATION:
        //     state.bookmarks.push(payload)
        //     return {
        //         ...state,
        //         bookmarks: state.bookmarks
        //     }
        // case RECORDS:
        //     return {
        //         ...state,
        //         records: payload
        //     }
        default: return state;  
    }
}
export default getContractReducers;