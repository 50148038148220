export const selectInit = (callback, elem = '') => {
  window.jQuery((elem ? elem : ".select2-single") + ":not(.bound)").addClass('bound').select2({
    maximumSelectionSize: 6,
    containerCssClass: ":all:",
    minimumResultsForSearch: 10
  }).on('change', (evt) => {
    callback && callback(evt.target.id, evt.target.value)
  })

}

export const base64Convert = (file) => {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = (e) => {
      resolve(fr.result)
    }
    fr.onerror = (error) => {
      reject('Error: ' + error);
    }
    fr.readAsDataURL(file)
  })
}

export const base64MimeType = (encoded) => {
  var result = null;
  if (typeof encoded !== 'string') {
    return result;
  }
  try {
    var mime = encoded.split(";base64")[0].split(":")[1].split("/")
    if (mime && mime.length) {
      if (mime[0] === 'image') {
        result = mime[0]
      }
      else {
        result = mime[1];
      }
    }
  }
  catch (e) {
    console.error(e.message)
  }
  return result;
}

export const get_pdf_thumbnail = (base64) => {
  return new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = function (ev) {
      window.pdfjsLib.getDocument(fileReader.result).promise.then(function (doc) {
        // var pages = []; while (pages.length < doc.numPages) pages.push(pages.length + 1);
        return doc.getPage(1).then(makeThumb)
          .then(function (canvas) {
            resolve(canvas.toDataURL('image/jpeg'))
          })
          .catch((err) => {
            console.log("Error", err)
            resolve()
          })
        // return Promise.all(pages.map(function (num) {
        // }))
      }, function (error) {
        console.log(error);
        resolve()
      });
    };
    fileReader.readAsArrayBuffer(base64);
  })
}

export const make_donut_chart = (id, options) => {
  var canvas = document.getElementById(id)
  var ctx = canvas.getContext("2d")
  var total_value = 0;
  var color_index = 0;
  var colors = []
  for (var categ of options.data) {
    colors.push(categ.color)
    total_value += categ.value
  }
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  if (total_value === 0) {
    drawPieSlice(
      ctx,
      canvas.width / 2,
      canvas.height / 2,
      Math.min(canvas.width / 2, canvas.height / 2),
      0,
      6.28319,
      "#eaeaea",
      ""
    )
  }
  else {
    var start_angle = 0;
    for (categ of options.data) {
      var slice_angle = 2 * Math.PI * categ.value / total_value;
      drawPieSlice(
        ctx,
        canvas.width / 2,
        canvas.height / 2,
        Math.min(canvas.width / 2, canvas.height / 2),
        start_angle,
        start_angle + slice_angle,
        colors[color_index % colors.length],
        categ['label']
      )
      start_angle += slice_angle;
      color_index++;
    }
    //drawing a white circle over the chart
    //to create the doughnut chart
    if (options.doughnutHoleSize) {
      drawPieSlice(
        ctx,
        canvas.width / 2,
        canvas.height / 2,
        options.doughnutHoleSize * Math.min(canvas.width / 2, canvas.height / 2),
        0,
        2 * Math.PI,
        "#fff",
        "Total-" + total_value
      );
    }
    start_angle = 0;
    for (categ of options.data) {
      slice_angle = 2 * Math.PI * categ.value / total_value;
      var pieRadius = Math.min(canvas.width / 2, canvas.height / 2);
      var labelX = canvas.width / 2 + (pieRadius / 2) * Math.cos(start_angle + slice_angle / 2);
      var labelY = canvas.height / 2 + (pieRadius / 2) * Math.sin(start_angle + slice_angle / 2);

      if (options.doughnutHoleSize) {
        var offset = (pieRadius * options.doughnutHoleSize) / 2;
        labelX = canvas.width / 2 + (offset + pieRadius / 2) * Math.cos(start_angle + slice_angle / 2);
        labelY = canvas.height / 2 + (offset + pieRadius / 2) * Math.sin(start_angle + slice_angle / 2);
      }

      // var labelText = Math.round(100 * categ.value / total_value);
      ctx.fillStyle = "white";
      ctx.font = "bold 12px Arial";
      if (categ.label && categ.label != '0') {
        ctx.fillText(categ.label, labelX, labelY);
      }
      start_angle += slice_angle;
    }
  }
}

export const make_pie_chart = (id, arr) => {
  var canvas = document.getElementById(id);
  var elemLeft = canvas.offsetLeft
  var elemTop = canvas.offsetTop
  var elements = []
  var ctx = canvas.getContext("2d");
  var lastend = 0;
  var data = [];
  var myTotal = 0;
  var myColor = [];
  var labels = [];
  arr.map((el) => {
    data.push(el.data)
    myColor.push(el.color)
    labels.push(el.label)
  })

  for (var e = 0; e < data.length; e++) {
    myTotal += data[e];
  }

  // make the chart 10 px smaller to fit on canvas
  var off = 10
  var w = (canvas.width - off) / 2
  var h = (canvas.height - off) / 2
  for (var i = 0; i < data.length; i++) {
    ctx.fillStyle = myColor[i];
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(w, h);
    var len = (data[i] / myTotal) * 2 * Math.PI
    var r = h - off / 2
    ctx.arc(w, h, r, lastend, lastend + len, false);
    ctx.lineTo(w, h);
    ctx.fill();
    ctx.stroke();
    ctx.fillStyle = 'white';
    ctx.font = "16px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    var mid = lastend + len / 2
    ctx.fillText(labels[i], w + Math.cos(mid) * (r / 2), h - 10 + Math.sin(mid) * (r / 2));
    ctx.font = "12px Arial";
    let percent = Math.floor(((data[i] / myTotal) * 100) + 0.5)
    ctx.fillText("(" + percent + "%)", w + Math.cos(mid) * (r / 2), h + 10 + Math.sin(mid) * (r / 2));
    elements.push({ start: lastend, end: Math.PI * 2 * (data[i] / myTotal), text: `${labels[i]}:${data[i]}`, w, h, r })
    lastend += Math.PI * 2 * (data[i] / myTotal);
  }
  // try{
  //   canvas.addEventListener('click', function(event) {
  //     console.log("hello")
  //     // var x = event.pageX - elemLeft,
  //     //     y = event.pageY - elemTop;
  //     // console.log(x, y);
  //     // console.log(elements)
  //     var mouse = oMousePos(canvas, event)
  //     try{
  //       elements.forEach(function(element) {
  //         // drawElement(element, ctx);
  //         let circle = new Path2D();
  //         circle.arc(element.w , element.h, element.r, element.start,element.end,false);
  //         let context = canvas.getContext("2d");
  //         if(context.isPointInPath(circle,mouse.x, mouse.y)){console.log(element.text)}else{console.log("not in path")}
  //       });

  //     }
  //     catch(e){
  //       console.log(e)
  //     }

  //   }, false);

  // }
  // catch(e){
  //   console.log(e)
  // }
}

export const formatLineChartData = (values, chartHeight) => {

  const widgetSize = chartHeight;
  const pointSize = 16;

  const base = (widgetSize - pointSize / 2) / values.length;

  let sortedValues = sortValues([...values]);

  const topMostPoint = sortedValues[0].value;
  let leftOffset = pointSize; //padding for left axis labels
  let nextPoint = 0;
  let rise = 0;
  let cssValues = [];

  for (var i = 0, len = values.length - 1; i < len; i++) {

    var currentValue = {
      left: 0,
      bottom: 0,
      hypotenuse: 0,
      angle: 0,
      value: 0
    };

    currentValue.value = values[i].value;
    currentValue.name = values[i].name;
    currentValue.left = leftOffset;
    leftOffset += base;
    currentValue.bottom = (widgetSize - pointSize) * (currentValue.value / topMostPoint);
    nextPoint = (widgetSize - pointSize) * (values[i + 1].value / topMostPoint);

    rise = currentValue.bottom - nextPoint;
    currentValue.hypotenuse = Math.sqrt((base * base) + (rise * rise));
    currentValue.angle = radiansToDegrees(Math.asin(rise / currentValue.hypotenuse));

    cssValues.push(currentValue);
  }

  var lastPoint = {
    left: leftOffset,
    bottom: (widgetSize - pointSize) * (values[values.length - 1].value / topMostPoint),
    hypotenuse: 0,
    angle: 0,
    value: values[values.length - 1].value,
    name: values[values.length - 1].name
  };

  cssValues.push(lastPoint);

  return cssValues;
}

const sortValues = values => values.sort((a, b) => b.value - a.value)

const radiansToDegrees = (rads) => rads * (180 / Math.PI)

// const sum = (total, value) => total + value.value

function drawPieSlice(ctx, centerX, centerY, radius, startAngle, endAngle, color, label = '') {
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(centerX, centerY);
  ctx.arc(centerX, centerY, radius, startAngle, endAngle);
  ctx.closePath();
  ctx.fill();
}

function makeThumb(page) {
  // console.log(page.view)
  // draw page to fit into 96x96 canvas
  var vp = page.getViewport({ scale: 1 });
  var canvas = document.createElement("canvas");
  canvas.width = 612;
  canvas.height = 792;
  var scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
  return page.render({ canvasContext: canvas.getContext("2d"), viewport: { scale: scale } }).promise.then(function () {
    return canvas;
  })
}