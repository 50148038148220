import React, { useEffect, useState } from 'react';
import history from './../../services/history';
import { getAddressData } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'
import axios from 'axios'
import ImgPreviewModal from './../Dashboard/Modal/ImgPreviewModal'

const AddressBookDetails = (props) => {
    const dispatch = useDispatch()
    const getIndex = props.location.state.addressIndex
    const singleAddressData = useSelector((state) => state.getSingleAddReducer.addressData)
    const loader = useSelector((state) => state.getSingleAddReducer.loading)
    const [disableState, setDisableState] = useState(true)
    const [base, setBase] = useState('')

    const _goBack = () => {
        history.push('/dashboard/address-book')
    }
    const allowEdit = () => {
        if ($(".add-detail-span2").hasClass("underline")) {
            $(".add-detail-span2").removeClass("underline");
            setDisableState(true)
            setUpdateObj(
                {
                    CompanyName: '',
                    Address: '',
                    State: '',
                    City: '',
                    Country: 'India',
                    PinCode: '',
                    PAN: '',
                    PanCopy: '',
                    GSTIN: '',
                    GstinUpload: '',
                    Name: '',
                    MobileNo: '',
                    EmailId: '',
                    AddressId: '',
                    TripsCount: '',
                    Status: ''
                }
            )
        } else {
            $(".add-detail-span2").addClass("underline");
            setDisableState(false)
            let dummy = { ...updateObj }
            dummy.CompanyName = singleAddressData.CompanyName
            dummy.Address = singleAddressData.Address
            dummy.State = singleAddressData.State
            dummy.City = singleAddressData.City
            dummy.PinCode = singleAddressData.PinCode
            dummy.PAN = singleAddressData.PAN
            dummy.GSTIN = singleAddressData.GSTIN
            dummy.Name = singleAddressData.Name
            dummy.MobileNo = singleAddressData.MobileNo
            dummy.EmailId = singleAddressData.EmailId
            dummy.AddressId = singleAddressData._id
            dummy.MobileNo = singleAddressData.MobileNo
            dummy.TripsCount = singleAddressData.TripsCount
            dummy.Status = singleAddressData.Status
            setUpdateObj(dummy)
        }
    }
    const [updateObj, setUpdateObj] = useState({
        CompanyName: '',
        Address: '',
        State: '',
        City: '',
        Country: 'India',
        PinCode: '',
        PAN: '',
        PanCopy: '',
        GSTIN: '',
        GstinUpload: '',
        Name: '',
        MobileNo: '',
        EmailId: '',
        AddressId: '',
        TripsCount: '',
        Status: ''
    })
    const updateData = (e) => {
        setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
    }
    const _handleClick1 = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const updateFun = () => {
        const finalObj = {
            addressId: updateObj.AddressId,
            companyName: updateObj.CompanyName,
            name: updateObj.Name,
            mobileNo: updateObj.MobileNo,
            emailId: updateObj.EmailId,
            pan: updateObj.PAN,
            panCopy: updateObj.PanCopy,
            gstin: updateObj.GSTIN,
            gstinUpload: updateObj.GstinUpload,
            address: updateObj.Address,
            country: 'India',
            state: updateObj.State,
            city: updateObj.City,
            pinCode: updateObj.PinCode,
            tripsCount: updateObj.TripsCount,
            status: updateObj.Status,
        }

        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/addressbook/UpdateAddress', { ...finalObj }, { headers: config.headers })
            .then(response => {
                const err = response.data.error
                const message = response.data.message
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                if (err === false) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log('err', error)
            })
    }
    const uploadFrontImage = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        const localArray = updateObj
        if (item === 'pan') {
            localArray.PanCopy = base64
            setUpdateObj(localArray)
        }
        else if (item === 'gst') {
            localArray.GstinUpload = base64
            setUpdateObj(localArray)
        }
    }
    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }

                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const SharedData = (item) => {
        if (item === 'gst') {
            let key = singleAddressData.GstinUpload.file_key
            const token = localStorage.getItem('secretkey')
            const config = {
                headers: { Authorization: token }
            };
            axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                .then(response => {
                    const storeBase = response.data.file
                    setBase(storeBase)
                })
                .catch(error => {
                    // dispatch(showError(err))
                })
        }
        else if (item === 'pan') {
            let key = singleAddressData.PanCopy.file_key
            const token = localStorage.getItem('secretkey')
            const config = {
                headers: { Authorization: token }
            };
            axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                .then(response => {
                    const storeBase = response.data.file
                    setBase(storeBase)
                })
                .catch(error => {
                    // dispatch(showError(err))
                })
        }

    }

    useEffect(() => {
        dispatch(getAddressData(getIndex))
    }, [dispatch]);
    return (
        <>
            <div className="contentpanel">
                <div className="cust-container">
                    {singleAddressData !== undefined ?
                        loader === true ?
                            <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                <i className="fa fa-spinner fa-spin loadingSpinner font-xxl  "></i>
                            </div>
                            :
                            <div className="contentpanel">
                                <div className='cust-row'>
                                    <div className='cust-col-4 flex-algn-cent'>
                                        <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                                            <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                                        </button>
                                        <h1 className="title">Address Book Details</h1>
                                    </div>
                                    <div className='cust-col-6 flex-jc-end'>
                                        <button className='button bg-orange-out mr-2 wd10' onClick={allowEdit}>
                                            <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                                            <div className="titleBoxnew">
                                                <span>Edit </span>
                                            </div>
                                        </button>
                                        <button className='button bg-orange-out mr-2 wd10'>
                                            <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                                            <div className="titleBoxnew">
                                                <span>Download </span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <hr className='bgg' />
                                <div className="cust-container">
                                    <div className='cust-row'>
                                        <div className='cust-col-10'>
                                            <div className='AddressDetailMain'>
                                                <div className='addressDetails-L'>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>Company Name :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <input className='add-detail-span2' name='CompanyName' onChange={(e) => updateData(e)} disabled={disableState}
                                                                value={disableState === true ? singleAddressData.CompanyName : updateObj.CompanyName} />
                                                        </div>
                                                    </div>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>Registered Address :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <input className='add-detail-span2' name='Address' onChange={(e) => updateData(e)} disabled={disableState}
                                                                value={disableState === true ? singleAddressData.Address : updateObj.Address} />
                                                        </div>
                                                    </div>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>State :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <input className='add-detail-span2'
                                                                name='State' onChange={(e) => updateData(e)}
                                                                disabled={disableState}
                                                                value={disableState === true ? singleAddressData.State : updateObj.State} />
                                                        </div>
                                                    </div>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>City :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <input className='add-detail-span2' name='City' onChange={(e) => updateData(e)} disabled={disableState}
                                                                value={disableState === true ? singleAddressData.City : updateObj.City} />
                                                        </div>
                                                    </div>
  
                                                </div>
                                                <div className='addressDetails-R'>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>PAN Number :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <input className='add-detail-span2' name='PAN' onChange={(e) => updateData(e)}
                                                                value={disableState === true ? singleAddressData.PAN : updateObj.PAN} disabled={disableState} />
                                                            {disableState === false ?
                                                                <div className='addUploadItem' style={{ border: 'none' }}>
                                                                    <i className="fa fa-upload uploadIconn"  ></i>
                                                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'pan')} type="file" />
                                                                </div>
                                                                : ''}
                                                            <i className="fa fa-eye userEye black" onClick={() => { _handleClick1('ImgPreviewModal'); SharedData('pan') }}></i>
                                                        </div>
                                                    </div>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>GSTIN Number :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <input className='add-detail-span2' name='GSTIN'
                                                                onChange={(e) => updateData(e)}
                                                                value={disableState === true ? singleAddressData.GSTIN : updateObj.GSTIN}
                                                                disabled={disableState}
                                                            />
                                                            {disableState === false ?
                                                                <div className='addUploadItem' style={{ border: 'none' }}>
                                                                    <i className="fa fa-upload uploadIconn"  ></i>
                                                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'gst')} type="file" />
                                                                </div>
                                                                : ''}
                                                            <i className="fa fa-eye userEye black" onClick={() => { _handleClick1('ImgPreviewModal'); SharedData('gst') }}></i>
                                                        </div>
                                                    </div>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>Pin Code :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <input className='add-detail-span2' name='PinCode' onChange={(e) => updateData(e)}
                                                                value={disableState === true ? singleAddressData.PinCode : updateObj.PinCode} disabled={disableState} />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='AddressDetailMain noBrdrTop'>
                                                <span className='AddressDetailMainSpans' >Contact Details</span>
                                                <div className='addressDetails-L'>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>Contact Person Name :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <input className='add-detail-span2' name='Name' onChange={(e) => updateData(e)} disabled={disableState}
                                                                value={disableState === true ? singleAddressData.Name : updateObj.Name} />
                                                        </div>
                                                    </div>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>Contact Number :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <input className='add-detail-span2' name='MobileNo' onChange={(e) => updateData(e)} disabled={disableState}
                                                                value={disableState === true ? singleAddressData.MobileNo : updateObj.MobileNo} />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='addressDetails-R'>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>Email Id :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <input className='add-detail-span2' name='EmailId' onChange={(e) => updateData(e)} disabled={disableState}
                                                                value={disableState === true ? singleAddressData.EmailId : updateObj.EmailId} />
                                                        </div>
                                                    </div>

                                                    <div className='add-Details-tab'>
                                                    </div>

                                                </div>

                                            </div>
                                            {disableState === false ?
                                                <div className='updateElem'>
                                                    <button onClick={() => updateFun()}>Update</button>
                                                </div>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                        <span className='noDataClass'>No Data Found</span>
                    }
                </div>
                <ImgPreviewModal base={base} />
            </div>

        </>
    )
}

export default AddressBookDetails;