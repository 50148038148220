import {GET_ADDRESS} from  "../actions/types";

const initialState={
    AddressData: []
} 

const getAddressReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_ADDRESS : return{
            ...state,
            AddressData:payload
        }
        default: return state;  
    }
}
export default getAddressReducer