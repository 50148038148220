import { post } from './../../../services/requests'
import { GET_IMAGE, RESET_IMAGE } from './types'

export const getImages = (key) =>{ 
    return(dispatch)=>{
        post(`common/get-image`, {key})
        .then(async response=>{
             const storeBase64 =await response.file
             dispatch(storeBase(storeBase64))
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const storeBase = (storeBase64) => {
    return {
        type: GET_IMAGE,
        payload: storeBase64
    }
}
export const resetBase = () => {
    return {
        type: RESET_IMAGE,
       
    }
}
