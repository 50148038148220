import React,{useEffect}from 'react';
import history from '../../services/history'
import { selectInit } from './../../services/functions'


const Invoice = () => {

    const _goBack = () => {
        history.push("/dashboard/lorry-receipt/Select-PoD")
    }
    useEffect(() => {
        setTimeout(() => {
            selectInit()
        }, 100);
    })
    return (
        <>
            <div className="contentpanel">
                <div className='cust-row'>
                    <div className='cust-col-4 flex-algn-cent'>
                        <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                            <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                        </button>
                        <h1 className="title">Invoice</h1>
                    </div>
                    <div className='cust-col-6 flex-jc-end'></div>
                </div>
                <hr className='bgg' />
                <div className="cust-container">
                    <div className='cust-row'>
                    </div>
                </div>

                <div className='InvoiceOuter'>
                    <div className='InvoiceInner'>
                        <div className="InvoiceHeader">
                            <div className='invoiceheader1'>
                                <div className="invoicePI">
                                    <span>Have your own PI number ?</span>
                                </div>
                                <div className="invoicecheckbox">
                                    <div className='invoiceYes'>
                                        <div className='invoiceL'>
                                            <input className='customCheckbox' type="checkbox" />
                                         </div>
                                         <div className='invoiceR'>
                                            <span className='invoiceLabel'>Yes</span>
                                        </div>
                                    </div>

                                    <div className='invoiceNo'>
                                    <div className='invoiceL'>
                                            <input className='customCheckbox' type="checkbox" />
                                         </div>
                                         <div className='invoiceR'>
                                            <span className='invoiceLabel'>No</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='invoiceheader2'>
                                <div className='invoiceNumberDiv'>
                                    <input type="text" placeholder='Enter Invoice No.' />
                                </div>
                            </div>
                           
                        </div>
                        <div className='incoiveDropdownDiv'>
                            <div className='invoicedrop-1'>
                                <div className='invoicedropI-1'>
                                    <span>Bill To <span className='asterisk'>*</span></span>
                                </div>
                                <div className='invoicedropI-2'>
                                    <input className='invoiceBillInput' type="text" placeholder="Hindaloco Faridabad - (GSTIN-24FF8652901)" />
                                </div>
                            </div>
                            <div className='invoicedrop-2'>
                                <div className='invoicedropI-1'>
                                    <span>Bill By <span className='asterisk'>*</span></span>
                                </div>
                                <div className='invoicedropI-2'>
                                    <select className='form-control select2-single'>
                                        <option>Dahej Plant</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='invoiceForm'>
                            <div className='invoiceforminner'>
                                <div className='invoiceform1'>
                                    <div className='invoiceformR-1'>
                                        <div className='InvoiceC-1'>
                                            <div className="invoiceC-inner">
                                                <span className='invoicespan1'>Sr No.</span>
                                                <span className='invoicespan2'>1.)</span>
                                            </div>
                                        </div>
                                        <div className='InvoiceC-2'>
                                            <div className="invoiceC-inner">
                                                <span className='invoicespan1'>Trip ID</span>
                                                <span className='invoicespan2 '>XXXXXX001</span>
                                            </div>
                                        </div>
                                        <div className='InvoiceC-3'>
                                            <div className="invoiceC-inner">
                                                <span className='invoicespan1'>From</span>
                                                <span className='invoicespan2'>Khasra No. 35, Kila No. 6/2, Sikri - Piyala Road, Tehsil Ballabgarh,Faridabad, Haryana, Pin – 121004</span>
                                            </div>
                                        </div>
                                        <div className='InvoiceC-4'>
                                            <div className="invoiceC-inner">
                                                <span className='invoicespan1'>To</span>
                                                <span className='invoicespan2'>New Delhi Office, G-89 Govind Puri, New Delhi- 110009</span>
                                            </div>
                                        </div>
                                        <div className='InvoiceC-5'>
                                            <div className="invoiceC-inner">
                                                <span className='invoicespan1'>Vehicle No.</span>
                                                <span className='invoicespan2'>DL - 2C775</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='invoiceformR-2'>
                                        <div className='invoiceR-2-c-1'>
                                            <div className="invoiceR-2-c-1Inner">
                                                <span className='invoicespan1-new'>LR No.</span>
                                                <span className='invoicespan2-new'>0008</span>
                                            </div>
                                        </div>
                                        <div className='invoiceR-2-c-2'>
                                            <div className="invoiceR-2-c-1Inner">
                                                <span className='invoicespan1-new'>Consignor. </span>
                                                <span className='invoicespan2-new'>HIL Faridabad- Khasra No. 35, Kila No. 6/2, 15, 16/1, 16/2/1, Sikri - Piyala Road, Village Piyala, Tehsil Ballabgarh, District Faridabad, Haryana, Pin – 121004, India</span>
                                            </div>
                                        </div>
                                        <div className='invoiceR-2-c-3'>
                                            <div className="invoiceR-2-c-1Inner">
                                                <span className='invoicespan1-new'>Consignee. </span>
                                                <span className='invoicespan2-new'>RISHABH Consignor, Address of Consignor - Bhaglapur Bhopal - 250002</span>
                                            </div>
                                        </div>
                                        <div className='invoiceR-2-c-4'>
                                            <div className="invoiceR-2-c-1Inner">
                                                <span className='invoicespan1-new'>Material. </span>
                                                <span className='invoicespan2-new'>Boxes</span>
                                            </div>
                                        </div>
                                        <div className='invoiceR-2-c-5'>
                                            <div className="invoiceR-2-c-1Inner">
                                                <span className='invoicespan1-new'>Pkgs. </span>
                                                <span className='invoicespan2-new'>9</span>
                                            </div>
                                        </div>
                                        <div className='invoiceR-2-c-6'>
                                            <div className="invoiceR-2-c-1Inner">
                                                <span className='invoicespan1-new'>Weight. </span>
                                                <span className='invoicespan2-new'>55 kg</span>
                                            </div>
                                        </div>
                                        <div className='invoiceR-2-c-7'>
                                            <div className='invoiceformInner1'>
                                                <span className='IE1-Modified'>Charges. </span>
                                            </div>
                                            <div className='invoice-7Parent'>
                                                <div className="invoiceformInner2">
                                                    <span className='invoicespan2-new'>Insurance Charges </span>
                                                    <span className='invoicespan2-new'>Weight Charges </span>
                                                </div>
                                                <div className="invoiceformInner3">
                                                    <span>₹ 55</span>
                                                    <span>₹ 100</span>
                                                </div>
                                            </div>
                                            <div className='invoicebtndiv'>
                                                <button className='invoiceaddbtn'>ADD</button>
                                            </div>
                                        </div>
                                        <div className='invoiceR-2-c-8'>
                                            <div className='invoiceformInner1'>
                                                <span className='IE1-Modified'>Penalty. </span>
                                            </div>
                                            <div className='invoice-7Parent'>
                                                <div className="invoiceformInner2">
                                                    <span className='invoicespan2-new'>Late Deliver Charges </span>
                                                    <span className='invoicespan2-new'> </span>
                                                </div>
                                                <div className="invoiceformInner3">
                                                    <span>₹ 150</span>
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div className='invoicebtndiv'>

                                            </div>
                                        </div>
                                        <div className='invoiceR-2-c-9'>
                                            <div className="invoiceR-2-c-1Inner">
                                                <span className='invoicespan1-new'>Amount. </span>
                                                <span className='invoicespan2-new'>₹ 150  </span>
                                            </div>
                                        </div>
                                        <div className='invoiceR-2-c-10'>
                                            <div className='totalChargediv1'>
                                                <span>Total Charge</span>
                                            </div>
                                            <div className='totalChargediv2'>
                                                <div className='totalchargesinner1'>
                                                    <span className="tableHead">Adv.</span>
                                                    <span className='tablerow'>50</span>
                                                </div>
                                                <div className='totalchargesinner2'>
                                                    <span className="tableHead">Bal.</span>
                                                    <span className='tablerow'>₹50</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='invoiceR-2-c-11'>
                                            <div className="invoiceR-2-c-1Inner">
                                                <span className='invoicespan1-new'>Remove. </span>
                                                <span className='invoicespan2-new'><i className="fa fa-trash-o" id="invoiceTrash"></i>  </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='invoicepanel'>
                                    <div className='invoicpalnel-L'></div>
                                    <div className='invoicpalnel-R'>
                                        <div className="invoice-r1">
                                            <div className='invoice-r1-L'>
                                                <span className='invoicepanelSpan1'>Total Deduction</span>
                                            </div>
                                            <div className='invoice-r1-R'>
                                                <span className='invoicepanelSpan2'>₹ 100.00</span>
                                            </div>
                                        </div>
                                        <div className="invoice-r2">
                                            <div className='invoice-r1-L'>
                                                <span className='invoicepanelSpan1'>Total Taxable Amount</span>
                                            </div>
                                            <div className='invoice-r1-R'>
                                                <span className='invoicepanelSpan2'>₹ 20340.00</span>
                                            </div>
                                        </div>
                                        <div className="invoice-r3">
                                            <div className="invoicegstparent">
                                                <div className='invoice-gst-1'>
                                                    <span>GST</span>
                                                    <select className='form-control select2-single'>
                                                        <option>18%</option>
                                                        <option>20%</option>
                                                        <option>22%</option>
                                                    </select>
                                                </div>

                                                <div className='invoice-gst-2'>
                                                    <span>9% SGST: ₹ 915.00</span>
                                                    <span>9% CGST: ₹ 915.00</span>
                                                </div>
                                            </div>

                                            <div className='invoice-r1-R'>
                                                <span className='invoicepanelSpan2'>₹ 1830.00</span>
                                            </div>
                                        </div>
                                        <div className="invoice-r4">
                                            <div className='invoicepanelTotal'>
                                                <span >Total Amount(to be Payed)</span>
                                            </div>
                                            <div className='invoicetotalno'>
                                                <span >₹ 22070.00</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Pibtndiv'>
                                    <button className='pibtn'>Create Proforma Invoice</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Invoice;