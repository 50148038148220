import { post } from './../../../services/requests'
import {ARRIVED_ACTION , ARRIVED_HIDE , ARRIVED_SHOW} from './types'


const pageSize = 5
export const arrivedAction = (data) =>{  
    return(dispatch)=>{
        dispatch(showLoader())
        post('booking/GetAllLr',{
            pageSize: pageSize,
            bookmark: "",
            status: "ArrivedAtDestination",
            searchValue: data.searchValue,
            searchType: data.searchType,
        })
        .then(async response=>{
             const arrivedList =await response.data
             const status = response.error
             dispatch(fechArrivedData(arrivedList))
             if(status === false){
                dispatch(hideLoader())  
             }
             else{
                dispatch(hideLoader())  
             }
        }) 
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const fechArrivedData = (arrivedList) =>{
    return{
        type :ARRIVED_ACTION,
        payload:{arrivedList}
    }
}
export const showLoader = () =>{
    return{
        type : ARRIVED_SHOW,
    }
}
export const hideLoader = () =>{
    return{
        type : ARRIVED_HIDE,
    }
}
