import React, { useEffect, useState } from 'react'
import './../../static/css/login.css'
import bg from './../../static/images/loginBackground.jpg'
import lynkit from './../../static/images/lynkit.png'
import history from './../../services/history';
import axios from 'axios'
import jwt_decode from "jwt-decode";

const Login = (props) => {
    const [passState, setPassState] = useState({
        userGroup: "Goods-Owner",
        email: "",
        currentPass: "",
        newPass: "",
        confirmPass: ""
    })
    const [loading, setLoading] = useState(false)
    const [userType, setUserType] = useState('Goods-Owner')
    const [info_icon, set_infoIcon] = useState("info")
    const [length, setLength] = useState("list-1")
    const [upper, setUpper] = useState("list-1")
    const [lower, setLower] = useState("list-1")
    const [special, setSpecial] = useState("list-1")
    const [number, setNumber] = useState("list-1")
    const [verify_icon, set_verify_icon] = useState("hide")
    const [recover_modal, set_recover_modal] = useState(false)
    const [otp_modal, set_otp_modal] = useState(false)
    const [password_modal, set_pass_modal] = useState(false)
    const [policies, setpolicies] = useState(false)
    const [eyes, setEyes] = useState("hide")
    const [passwordType, setPasswordType] = useState("password")
    const [placeTxt, setPlaceTxt] = useState('Email Id')
    const [ssnValues, setValue] = useState({
        ssn1: "",
        ssn2: "",
        ssn3: "",
        ssn4: ""
    });
    const [otpPasswordType, setOtpPasswordType] = useState("password")
    const [timerStatus, setTimerStatus] = useState(false)
    const [timer, setTimer] = useState(60)
    const [emailState, setEmail] = useState({ emailId: '' })
    const [verifyOtp, setVerifyOtp] = useState(
        {
            emailId: "",
            token: ""
        }
    )
    const [update, setUpdate] = useState(
        {
            emailId: "",
            token: "",
            newPassword: "",
            confirmNewPassword: ""
        }
    )

    const storeValue = (e) => {
        setPassState({ ...passState, [e.target.name]: e.target.value })
    }
    const recover_function = () => {
        set_recover_modal(!recover_modal)
    }
    const close_recover_modal = () => {
        set_recover_modal(!recover_modal)
        setTimer(60)
        setTimerStatus(true)
    }
    const close_otp_modal = () => {
        set_otp_modal(!otp_modal)
    }
    const close_password = () => {
        set_pass_modal(!password_modal)
        if (policies === true) {
            setpolicies(!policies)
        }
    }
    const show_policies = () => {
        setpolicies(!policies)
    }
    const close_policies = () => {
        setpolicies(!policies)
    }
    const handlePassword = (e) => {
        setPassState({ ...passState, [e.target.name]: e.target.value })
    }
    const togglePassType = () => {
        if (passwordType == "password") {
            setPasswordType("text")
        }
        else {
            setPasswordType("password")
        }

    }
    const otpTogglePassType = () => {
        if (otpPasswordType == "password") {
            setOtpPasswordType("text")
        }
        else {
            setOtpPasswordType("password")
        }

    }
    const userGroupFunction = (index) => {
        if (index === 1) {
            setUserType('Goods-Owner')
            passState.userGroup = 'Goods-Owner'
            setPlaceTxt('Email Id')
        }
        else if (index === 2) {
            setUserType('transporter')
            passState.userGroup = 'transporter'
            setPlaceTxt('Email Id')
        }
    }
    const sendDataa = () => {
        if (passState.email === '') {
            window.$.fn.show_notification({ message: `Please enter your ${placeTxt}.`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else if (passState.currentPass === '') {
            window.$.fn.show_notification({ message: `Please enter your Password.`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            axios.post('https://www.betaenterprise.lynkit.io/api/user/login', { userGroup: passState.userGroup, loginId: passState.email, password: passState.currentPass })
                .then(response => {
                    let error = response.data.error
                    let message = response.data.message
                    if (error !== true) {
                        setLoading(true)
                        var key = 'bearer '
                        var token = key.concat(response.data.token)
                        var decoded = jwt_decode(token);
                        console.log(decoded);
                        var userGroup = decoded.UserGroup
                        localStorage.setItem("secretkey", token)
                        localStorage.setItem("userGroup", userGroup)
                        setTimeout(() => {
                            history.push('/dashboard')
                        }, 500)
                    }
                    else if (error === true) {
                        setLoading(false)
                        window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    setLoading(false)
                    console.log('error', error);
                    window.$.fn.show_notification({ message: `Please Enter Correct Credentials.`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                })
        }
    }
    const _moveTo = () => {
        history.push('/registration')
    }
    const _moveToTrans = () => {
        history.push('/registration/transporter')
    }
    useEffect(() => {
        if (passState.newPass.match(/[a-z]/g) && passState.newPass.length > 8 && passState.newPass.match(/[A-Z]/g) && passState.newPass.match(/[0-9]/g) && passState.newPass.match(/[!@#$%^&*]/g)) {
            if (passState.newPass === passState.confirmPass) {
                set_verify_icon("verify")
            }
            else {
                set_verify_icon("hide")
            }
            set_infoIcon("green-info")
        }
        else {
            set_infoIcon("info")
        }
        // toggle eye
        if (passState.currentPass.length == 0) {
            setEyes("hide")
        }
        else {
            setEyes("show-eye")
        }

        // consitions for password policy
        if (passState.newPass.match(/[a-z]/g)) {
            setLower("green-list")
        }
        else {
            setLower("list-1")
        }

        if (passState.newPass.match(/[A-Z]/g)) {
            setUpper("green-list")
        }
        else {
            setUpper("list-1")
        }

        if (passState.newPass.length > 8) {
            setLength("green-list")
        }
        else {
            setLength("list-1")
        }
        if (passState.newPass.match(/[0-9]/g)) {
            setNumber("green-list")
        }
        else {
            setNumber("list-1")
        }

        if (passState.newPass.match(/[!@#$%^&*]/g)) {
            setSpecial("green-list")
        }
        else {
            setSpecial("list-1")
        }
        localStorage.setItem('userType', passState.email)

    }, [passState, lower, upper, number, length, special, verify_icon, eyes])

    const _handleChange = (e, item) => {
        if (item === 'email') {
            setEmail({ ...emailState, [e.target.name]: e.target.value })
        }
        else {

        }
    }
    const open_otp_modal = () => {
        if (emailState.emailId !== '') {
            axios.post('https://www.betaenterprise.lynkit.io/api/user/forgot-password', { ...emailState })
                .then(response => {
                    setTimer(60)
                    setTimerStatus(true)
                    const error = response.data.error
                    const message = response.data.message
                    if (error === false) {
                        set_otp_modal(!otp_modal)
                        set_recover_modal(!recover_modal)
                        setTimerStatus(true)
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
        else {
            window.$.fn.show_notification({ message: 'Email field cannot be empty.', title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }

    }
    const _handleOtp = (e) => {

        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        if (value.length >= maxLength) {
            if (parseInt(fieldIndex, 10) < 4) {
                const nextSibling = document.querySelector(
                    `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
                );
                if (nextSibling !== null) {
                    nextSibling.focus();
                }
            }
        }
        setValue({ ...ssnValues, [`ssn${fieldIndex}`]: value });
    }
    const verifyAndOpen = () => {
        const combineOtp = `${ssnValues.ssn1}` + `${ssnValues.ssn2}` + `${ssnValues.ssn3}` + `${ssnValues.ssn4}`
        verifyOtp.token = combineOtp
        verifyOtp.emailId = emailState.emailId

        axios.post('https://www.betaenterprise.lynkit.io/api/user/verify-otp', { ...verifyOtp })
            .then(response => {
                setTimer(60)
                setTimerStatus(false)
                const error = response.data.error
                const message = response.data.message
                if (error === false) {
                    set_pass_modal(!password_modal)
                    set_otp_modal(!otp_modal)
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
            })
    }
    const changePass = () => {
        update.emailId = emailState.emailId
        update.token = verifyOtp.token
        update.newPassword = passState.newPass
        update.confirmNewPassword = passState.confirmPass

        if (passState.newPass.match(/[a-z]/g) && passState.newPass.length > 8 && passState.newPass.match(/[A-Z]/g) && passState.newPass.match(/[0-9]/g) && passState.newPass.match(/[!@#$%^&*]/g)) {
            if (passState.newPass === passState.confirmPass) {
                axios.post('https://www.betaenterprise.lynkit.io/api/user/update-password', { ...update })
                    .then(response => {
                        const error = response.data.error
                        const message = response.data.message
                        if (error === false) {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Success!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            set_pass_modal(!password_modal)
                            setEmail('')
                            setPassState({
                                userGroup: "Goods-Owner",
                                email: "",
                                currentPass: "",
                                newPass: "",
                                confirmPass: ""
                            })
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                    })
            }
            else {
                window.$.fn.show_notification({ message: 'Password does not match..', title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
        }
        else {
            window.$.fn.show_notification({ message: 'Check Password policies..', title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }
    const resendEmail = () => {
        axios.post('https://www.betaenterprise.lynkit.io/api/user/forgot-password', { ...emailState, resend: true })
            .then(response => {
                setTimer(60)
                setTimerStatus(true)
                const error = response.data.error
                const message = response.data.message
                if (error === false) {
                   
                    window.$.fn.show_notification({ message: 'OTP sent again...', title: 'Success!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
            })
    }
    useEffect(() => {
        if (timer <= 0) {
            setTimerStatus(false)
        }
        if (timerStatus === true) {
            if (timer !== 0) {
                setInterval(() => {
                    setTimer(timer => timer - 1)
                }, 1000)
            }
        }
    }, [timerStatus])


    return (
        <>
            <div className='login-parent-div'>
                <img src={bg} alt='' />
                <div className='loginElement'>
                    <div className='loginLogoElem'>
                        <img src={lynkit} alt="" />
                    </div>
                    <div className='loginHeadingElem'>
                        <span>Enterprise Login</span>
                    </div>
                    {/* <div className='loginToggleBtn'>
                        <div className='userTypeDiv'>
                            <div className={userType === 'Goods-Owner' ? 'userTypesActive' : 'userTypes'} onClick={() => userGroupFunction(1)}>
                                <span className={userType === 'Goods-Owner' ? 'userTypeActiveSpan' : 'userTypeSpan'}>Goods Owner</span>
                            </div>
                            <div className={userType === 'transporter' ? 'userTypesActive' : 'userTypes'} onClick={() => userGroupFunction(2)}>
                                <span className={userType === 'transporter' ? 'userTypeActiveSpan' : 'userTypeSpan'}>Transporter</span>
                            </div>
                        </div>
                    </div> */}

                    <div className='loginInputElem'>
                        <div className='loginInput1'>
                            <div className='loginImg'>
                                <i className="fa fa-user iconxl" ></i>
                            </div>
                            <div className='loginInput'>
                                <input className='loginField' name="email" value={passState.email} onChange={(e) => storeValue(e)} type='email' placeholder={placeTxt} />
                            </div>
                        </div>
                        <div className='loginInput1'>
                            <div className='loginImg'>
                                <i className="fa fa-lock iconxl"></i>
                            </div>
                            <div className='loginInput'>
                                <input className='loginField' name="currentPass" value={passState.currentPass} onChange={(e) => storeValue(e)} type={passwordType} placeholder='Password' />
                            </div>
                            <div className='loginImg'>
                                {passwordType === 'password' ?
                                    <i className="fa fa-eye-slash iconEye" onClick={togglePassType}></i>
                                    :
                                    <i onClick={togglePassType} className="fa fa-eye iconEye" ></i>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='loginBtnElem'>
                        <button onClick={() => { sendDataa() }}>Log In</button>
                        <span className='forgotPassSpan'>Forgot Password ? <b><span className='clickPointer' onClick={recover_function}>Click Here</span></b></span>
                        {loading === true ?
                            <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                            : ''}
                        <span className='registerLink' onClick={() => _moveTo()}>Register</span>
                        {/* <button onClick={() => _moveToTrans()}>Register Transporter</button> */}
                    </div>
                </div>
            </div>

            {recover_modal && (
                <div className='recover-moadal'>
                    <div className='recover-overlay'></div>
                    <div className='recover-content'>
                        <div className='recover_data'>
                            <div className='inner-div'>
                                <div className='recover-heading'>
                                    <span className='recoverSpan'> Recover Password</span>
                                    <i onClick={close_recover_modal} id="heading-cross" className="fa fa-times"></i>
                                </div>

                                <div className='recover-input'>
                                    <div className='recover-span-field'>
                                        <span>Please Enter your registered email to recover password.</span>
                                    </div>
                                    <div className='recover-input-field'>
                                        <input style={{ fontSize: '0.8rem' }} name='emailId' value={emailState.emailId} onChange={(e) => _handleChange(e, 'email')} type="email" placeholder="Enter your registered email." />
                                    </div>
                                </div>

                                <div className='recover-button'>
                                    <div className='btn-element'>
                                        <button onClick={open_otp_modal} > Send OTP</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {otp_modal && (
                <div className='recover-moadal'>
                    <div className='recover-overlay' ></div>
                    <div className='recover-content-xl '>
                        <div className='recover_data'>
                            <div className='inner-div'>
                                <div className='recover-heading align-cent'>
                                    <span style={{ fontWeight: '700' }}> Recover Password</span>
                                    <i onClick={close_otp_modal} id="heading-cross" className="fa fa-times"></i>
                                </div>
                                <div className='recover-input'>
                                    <div className='otp-input'>
                                        <div className='input-1'>
                                            <input name='ssn-1' onChange={(e) => _handleOtp(e)} maxLength={1} className='otp-field' />
                                        </div>
                                        <div className='input-2'>
                                            <input name='ssn-2' onChange={(e) => _handleOtp(e)} maxLength={1} className='otp-field' />
                                        </div>
                                        <div className='input-3'>
                                            <input name='ssn-3' onChange={(e) => _handleOtp(e)} maxLength={1} className='otp-field' />
                                        </div>
                                        <div className='input-4'>
                                            <input name='ssn-4' onChange={(e) => _handleOtp(e)} maxLength={1} className='otp-field' />
                                        </div>
                                    </div>
                                </div>
                                <div className='verifyDiv'>
                                    <button onClick={verifyAndOpen} > Verify OTP</button>
                                    <span className='resend'>Didn't receive it yet?
                                        <button id="resend-style" disabled={timer >= 0 ? true : false} onClick={resendEmail}> Resend OTP</button>
                                        {timer >= 0 ?
                                            <span>in ({timer}) seconds</span>
                                            : ''
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {password_modal && (
                <div className='password-moadal'>
                    <div className='password-overlay'></div>
                    <div className='password-content'>
                        <div className='password_data'>
                            <div className='password-div'>

                                <div className='recover-heading med-hgt'>
                                    <span className='recoverSpan'> Recover Password</span>
                                    <i onClick={close_password} id="heading-cross" className="fa fa-times" ></i>
                                </div>

                                <div className='recover-pass-input'>
                                    <div className='pass-inner-div'>
                                        <div className='pass-top-div'>
                                            <div className='new-pass'>
                                                <input name="newPass" value={passState.newPass} type={otpPasswordType} placeholder="Enter New Password" onChange={(e) => handlePassword(e)} />
                                            </div>
                                            <div className='requirements'>
                                                {otpPasswordType === 'password' ?
                                                    <i className="fa fa-eye-slash iconEye otpEye" onClick={otpTogglePassType}></i>
                                                    :
                                                    <i onClick={otpTogglePassType} className="fa fa-eye iconEye otpEye" ></i>
                                                }
                                                <i onClick={show_policies} id={info_icon} className="fa fa-info-circle" ></i>
                                            </div>
                                        </div>

                                        <div className='pass-bottom-div'>
                                            <div className='confirm-pass'>
                                                <input name="confirmPass" value={passState.confirmPass} type='password' placeholder="Confirm Password" onChange={(e) => handlePassword(e)} />
                                            </div>

                                            <div className='right-tick'>
                                                <i id={verify_icon} className="fa fa-check"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='changePassDiv'>
                                    <button onClick={changePass}> Change Password </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {policies && (
                <div className='policy-moadal'>
                    <div className='policies-content'>
                        <div className='policies-div'>
                            <div className='policies_list'>
                                <i onClick={close_policies} id="policy-cross" className="fa fa-times" ></i>
                                <ul>
                                    <li className={length}>Password must have minimum 8 Characters.</li>
                                    <li className={upper}>Password must have atleast one Uppercase Character.</li>
                                    <li className={lower}>Password must have atleast one Lowercase Character.</li>
                                    <li className={special}>Password must have atleast one special(@#$%) Character.</li>
                                    <li className={number}>Password must have atleast one Numeric Character.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </>
    );
};


export default Login;