import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery'
import { selectInit } from '../../../services/functions'
import history from '../././../../services/history'
import ConfirmBookingModal from '../../Dashboard/Modal/ConfirmBooking'
import AllocateTruckModal from '../../Dashboard/Modal/AllocateTruck'
import InspectionModal from '../../Dashboard/Modal/Inspection'
import view from '../../../static/images/icon/buttons/ViewDetail.svg'
import documentView from '../../../static/images/icon/buttons/ViewDocuments.svg'
import allocateSales from '../../../static/images/icon/buttons/allocateSales.svg'
import AnalysisModal from '../../Dashboard/Modal/Analysis'
import TrackingModal from '../../Dashboard/Modal/Tracking'
import ViewDocumentsModal from '../../Dashboard/Modal/ViewDocuments'
import { BookingAction, listingApi } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';

const Inspection = (props) => {
    const dispatch = useDispatch() 
    // Inspection 
    const inspectionList = useSelector((state) => state.bookingReducers.inspectionListing)
    const bookmark = useSelector((state) => state.bookingReducers.bookmarks)
    const records = useSelector((state) => state.bookingReducers.records)
    const [gateInRequestData, setGateInData] = useState({})
    const listingLoader = useSelector((state) => state.bookingReducers.loader)
    const transportersList = useSelector((state) => state.getListingReducer.transporterListing)
    const [type, setType] = useState('truck-reporting')
    const [transVal , setTransVal] = useState('')
    const [userGroup, setUserGroup] = useState('')
    const [subPart, setSubPart] = useState('')
    const [val, setVal] = useState('')
    const [status, setStatus] = useState('')

    const [allocateObject, setAllocateObject] = useState({
        load: " ",
        shipmentGID: "",
        RemainingLoadableCapacity: "",
        GrossWeight: "",
        TareWeight: ""
    })
    const [allowGateIn, setAllowGateIn] = useState({
        action: "gatedIn",
        shipmentGID: "",
        truckAllocationId: ""
    })
    const [searchObject, setSearchObject] = useState(
        {
            bookmark: "",
            vehicleStatus: "",
            search: "",
            searchBy: "",
            inspectionStatus: "",
            inspectionType: "",
            vehicleBelongsTo: ""
        }
    )
    const [obj, setObj] = useState({
        param: "next",
        status: props.type,
        bookmark: "",
        vehicleStatus: "",
        search: "",
        searchBy: "",
        inspectionStatus: "",
        inspectionType: "",
        vehicleBelongsTo: ""
    })

    useEffect(() => {
        setType(props.type)
        setProps()
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 100);
        setTimeout(() => {
            selectInit(onChangeValue)
        }, 100);
        setUserGroup(localStorage.getItem("userGroup") === 'transporter' ? '2' : "1")
        bookmark.length = 0
        setObj(
            {
                param: "next",
                status: props.type,
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: ""
            }
        )
        if (type !== '') {
            dispatch(BookingAction(obj))
        }
        else { }
    }, [])

    const setProps = () => {
        searchObject.vehicleStatus = 'vehicleInspection'
    }
    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")
        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }
    const onChangeValue = (evt) => {
        if (evt === 'hil') {
            setSubPart('hil')
        }
        else if (evt === 'customer') {
            setSubPart('customer')
        }
    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(BookingAction({
            param: "next",
            status: props.type,
            bookmark: "",
            vehicleStatus: "",
            search: searchObject.search,
            searchBy: searchObject.searchBy,
            inspectionStatus: "",
            inspectionType: "",
            vehicleBelongsTo: ""
        }))
    }
    const confirmBooking = () => {
        window.$.fn.show_notification({ message: "confirmation is required for acceptance of booking", title: 'Are you sure!!', type: 'confirm', autohide: false }, (result) => {
            if (result.type === "confirm") {
                _closeModal()
            }
        })
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const cancelBooking = (index) => {
        window.$.fn.show_notification({ message: "Cancel your booking from your OTM Platform.", title: 'Are you sure!!', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
            if (result.type === 'confirm') {
            }
        })
    }
    const _viewDetail = (item) => {
        history.push({
            pathname: "/dashboard/booking/detail",
            state: { url_param: type, data: item }

        })
    }
    const storeInd = (item) => {
        let requestData = { ...item, show: true }
        setGateInData(requestData)
    }
    const reportFun = (item) => {
        let requestData = { ...item, show: false }
        setGateInData(requestData)
    }
    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            status: props.type
        }
        setObj(object)
        dispatch(BookingAction(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            status: props.type
        }
        setObj(object)
        dispatch(BookingAction(object))
        bookmark.pop()
    }
    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchBy = value
            searchObject.searchBy = value
            setSearchObject(temp)
        }
        else if (id === 'vehicleOf') {
            setVal(value)
        }
        else if (id === 'status') {
            setStatus(value)
        }
        else if (id === 'transporter'){
            setTransVal(value)
        }
    }
    useEffect(() => {
        dispatch(listingApi({
            docType: "transporter",
            param: "",
            pageSize: "",
            bookmark: "",
            blacklistedFlag: "",
            searchType: "",
            searchValue: ""
        }))
    }, [])
    useEffect(() => {
        if (val) {
            dispatch(BookingAction({
                param: "next",
                status: props.type,
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: val
            }))
        }
    }, [val])

    useEffect(() => {
        if (status) {
            dispatch(BookingAction({
                param: "next",
                status: props.type,
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: status,
                inspectionType: "",
                vehicleBelongsTo: "",
            }))
        }
    }, [status])

    useEffect(() => {
        if (transVal) {
            dispatch(BookingAction({
                param: "next",
                status: props.type,
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: "",
                transporterId: transVal
            }))
        }
    }, [transVal])

    return (
        <div className="contentpanel">
            <h1 className="title">Booking</h1>
            <hr />
            <div className="cust-container">
                <div className='cust-row'>
                    <div className='cust-col-1_2 flex-algn-cent'>
                        <div className='tt2'>Booking List</div>
                    </div>

                    <div className='cust-col-3'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <select className="form-control select2-single" defaultValue="select" id="searchType" >
                                    <option value="select">Select</option>
                                    <option value="shipmentGID">Shipment GID</option>
                                    <option value="vehicle">Vehicle Number</option>
                                    <option value="driverName">Driver Name</option>
                                    <option value="dlNo">DL Number</option>
                                </select>
                            </div>
                            <input type="text" name='search' value={searchObject.search} onChange={(e) => _onChangeSearch(e)} className="form-control" placeholder="Search here..." />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2" onClick={() => triggerSearch()}>
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    {type === 'inspection' && userGroup === '1' ?
                        <div className='cust-col-2' >
                            <div className='inspectionRadioDiv'>
                                <select className="form-control select2-single" defaultValue="select" id="vehicleOf" >
                                <option value="select">Vehicle of</option>
                                    <option value="transporter">Transporter vehicles</option>
                                    <option value="customer" >Customer Vehicle</option>
                                </select>
                            </div>

                        </div>
                        : ""}
                    {type === 'inspection' ?
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="all" id="status">
                                <option value="inspection_status">Inspection status</option>
                                <option value="Pass">Pass</option>
                                <option value="Fail">Fail</option>
                            </select>
                        </div>
                        : ''}
                        {userGroup === '1'  ?
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="pending" id="transporter">
                                <option value="select">Transporter Name</option>
                                {transportersList && transportersList.map((item, index) => {
                                    return (
                                        <option key={index} value={transportersList[index]._id}>{item.OrgName}</option>
                                    )
                                })}
                            </select>
                        </div>
                        : ""}

                    {/* {type !== 'all' && userGroup !== '2' ?
                        <div className='cust-col-1_2'>
                            <select className="form-control select2-single" defaultValue="Vehicle" id="VehicleOf">
                                <option value="Vehicle">Vehicle of</option>
                                <option value="t_vehicle">Transporter vehicles</option>
                                <option value="c_ehicle">Customer vehicles</option>
                            </select>
                        </div>
                        : ""} */}
                </div>

                <div className='cust-row'>

                    <div className='cust-col-10'>
                        <div className='data-cont'>
                            {inspectionList && inspectionList.length !== 0 ?
                                inspectionList && inspectionList.map((item, index) => {
                                    return (
                                        <div key={index} className='data-cont-item '>
                                            {userGroup === '1' ?
                                                <div className='data_item_content align-div pad-0'>
                                                    <div className='wd_30 clip-path display-col'>
                                                        <span className='tbl-hd-1'>Transporter :</span>
                                                        <span className='tbl-hd-2'>{item.TransporterName ? item.TransporterName : "-"}</span>
                                                        <span className='tbl-hd-3'>{item.OrgReadableID ? `(ID - ${item.OrgReadableID})` : ""}</span>
                                                    </div>
                                                    <div className='wd_30 bg-trans inc-hgt'>
                                                        <div className='dateTime'>
                                                            <span>Date/Time</span>
                                                        </div>
                                                        <div className='date'>
                                                            <i className="fa fa-calendar"></i>
                                                            <span>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div className='time'>
                                                            <i className="fa fa-clock-o xl"></i>
                                                            <span>{moment(item.TruckReportingTime).format("h:mm:ss a")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                            <div className='data_item_content set_height_xxl pad-top-0 set-new-hght'>
                                                <div className='wd_5 align-cent set-hght'>
                                                    <div className='sr-no '>
                                                        <span>{index + 1}</span>
                                                    </div>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Shipment GID</span>
                                                    <span className='orange_color font-lg'>{item.ShipmentGID}</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Vehicle Number</span>
                                                    <span>{item.TruckDetails && item.TruckDetails.VehicleNumber ? item.TruckDetails.VehicleNumber : "-"}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Source Location GID</span>
                                                    <span className='flex_box_2'>{item.SourceLocationGID}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Destination Location GID</span>
                                                    <span className='flex_box_2'>{item.DestinationLocationGID}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Reference Ship ID</span>
                                                    <span>{item.ReferenceShipGID}</span>
                                                </div>
                                                <div className="wd_10">
                                                    <span className='flex_box_2'>Transport Mode GID</span>
                                                    <span>{item.TransportModeGID}</span>
                                                </div>
                                                <div className="wd_10">
                                                    <span className='flex_box_2'>Service Provider GID</span>
                                                    <span>{item.ServiceProviderGID}</span>
                                                </div>

                                                {type === 'inspection' && subPart !== 'hil' && subPart !== 'customer' ?
                                                    <div className='wd_10'>
                                                        <span>Status</span>
                                                        <span>Gate in Request pending</span>
                                                    </div>
                                                    : ""}
                                                {subPart === 'hil' ?
                                                    <div className='wd_10'>
                                                        <span>Status</span>
                                                        <span>Gate in Request pending</span>
                                                    </div>
                                                    : ""}
                                                {subPart === 'customer' ?
                                                    <div className='wd_10'>
                                                        <span>Status</span>
                                                        <span>Sales order allocated</span>
                                                    </div>
                                                    : ""}
                                                <div className='wd_5 align-cent set-hght set-bg'>
                                                    <div className='sr-no trans' onClick={() => { toogleItemView(index) }}>
                                                        <i className="fa fa-plus expand-tbl open"></i>
                                                        <i className="fa fa-minus contract-tbl hide" ></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='data_item_content clps set_height_xxxl setColor '>
                                                <>
                                                    <div className="wd_20">
                                                        <span>Total weight</span>
                                                        <span>{item.TotalWeight}</span>
                                                    </div>
                                                    <div className='wd_20'>
                                                        <span>Ship Quantity Accepted</span>
                                                        <span>{item.ShipQuantityAccepted}</span>
                                                    </div>
                                                    <div className='wd_20'>
                                                        <span>Gate No.</span>
                                                        <span>8</span>
                                                    </div>

                                                    <div className='wd_20'>
                                                        <span>Inspection Date & Time</span>
                                                        <span>{item.TruckDetails && item.TruckDetails.Inspection[0] && item.TruckDetails.Inspection[0].InspectionTime ? moment(item.TruckDetails.Inspection[0].InspectionTime).format("DD/MM/YYYY") : '-'} ,{item.TruckDetails && item.TruckDetails.Inspection[0] && item.TruckDetails.Inspection[0].InspectionTime ? moment(item.TruckDetails.Inspection[0].InspectionTime).format("h:mm:ss a") : '-'}</span>
                                                    </div>

                                                    <div className='wd_20'>
                                                        <span>Inspection Status</span>
                                                        <span>
                                                            {item.TruckDetails && item.TruckDetails.Inspection[0] && item.TruckDetails.Inspection[0].InspectionStatus && item.TruckDetails.Inspection[0].InspectionStatus === 'Fail' ?
                                                                <i class="fa fa-times-circle-o font-xl red_color mr-1" ></i>
                                                                :
                                                                <i className='fa fa-check-circle-o font-xl green_color mr-1'></i>
                                                            }

                                                            <span className='color_green font-lg'>{item.TruckDetails && item.TruckDetails.Inspection[0] && item.TruckDetails.Inspection[0].InspectionStatus ? item.TruckDetails.Inspection[0].InspectionStatus : '-'}</span>
                                                            <button className='reportBtnNew' onClick={() => { _handleClick("InspectionModal"); reportFun(item) }}>Report</button>
                                                        </span>
                                                    </div>
                                                    <div className='wd_40 no-mg-pd designChange'>
                                                        <div className='btn_row_1'>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 btn_size ' onClick={() => { _handleClick('ViewDocumentsModal') }}><img src={documentView} alt="none" />View Documents</button>



                                                            {userGroup === '1' ?
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 ' onClick={() => { _handleClick("InspectionModal"); storeInd(item) }}> <img src={allocateSales} alt="none" />Gate In Request</button>
                                                                : ''}

                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={() => { _viewDetail(item) }}> <img src={view} alt="none" />View Detail</button>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <span className='noDataClass'>No Data Found</span>
                            }
                        </div>

                        {inspectionList && inspectionList.length !== 0 ?
                            <div className='paginationDiv'>
                                <button disabled={obj && obj.bookmark === '' ? true : false ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                                <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                            </div>
                            : ''}

                    </div>

                </div>
            </div>
            <ConfirmBookingModal closeModal={_closeModal} confirmBooking={confirmBooking} allocate={allocateObject} />
            <AllocateTruckModal closeModal={_closeModal} AllocateTruck={AllocateTruckModal} />
            <AnalysisModal closeModal={_closeModal} />
            <TrackingModal closeModal={_closeModal} />
            <InspectionModal closeModal={_closeModal} gateInRequestData={gateInRequestData} />
            <ViewDocumentsModal closeModal={_closeModal} />

        </div>
    )
}
export default Inspection;