import React, { useEffect, useState } from 'react';
import FileUpload from './../FileUpload'
import { get_pdf_thumbnail, base64Convert } from './../../../services/functions'
import { getFetchedData, getRegisteredVehicle } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { storeAddress } from '../../../state/redux/actions/getAddress';
import moment from 'moment'

const AddVehicle = (props) => {
    const [status, setStatus] = useState(false)
    const [imgBase, setImgBase] = useState('')
    const [fileArr, setFileArr] = useState([])
    const dispatch = useDispatch()
    const vehData = useSelector((state) => state.fetchVehRed.fetchedData.vehData)
    const loader = useSelector((state) => state.fetchVehRed.loading)
    const [vehicleNumData , setVehicleNum] = useState({vehicleNumber :''})

    const [vehicleData, setVehicleData] = useState({
        vehicleNumber: "",
        vehicleSerialNo: "",
        truckSerialNo: "",
        ownerName: "",
        chassisNumber: "",
        engineNumber: "",
        gpsNumber: " ",
        bodyGroup: "",
        vehicleType: "",
        bodyType: "",
        truckType: "",
        truckModel: "",
        registrationDate: "",
        registrationValidTill: "",
        registeredCapacity: "",
        capacityUOM: "",
        ftlRegisteredCapacity: "",
        pucCheck: "",
        pucValidTill: "",
        insuranceCheck: "",
        insuranceValidTill: "",
        blacklistedFlag: false,
        address: "",
        district: "",
        state: "",
        country: "",
        postalCode: "",
        phone: "",
        mobile: "",
        permitStates: [],
        permitValidTill: "",
        permitRegisteredCapacity: "",
        permitCapacityUOM: "",
        permitActive: "",
        addedBy: "",
        unloadWeight :"",
        grossVehicleWeight:"",
        belongsTo:"transporter"

    }
    )
    const _handleChange = (e) => {
        setVehicleNum({ ...vehicleNumData, [e.target.name]: e.target.value}) 
    }

    const fetch = () => {
        dispatch(getFetchedData(vehicleNumData))
    }
    const addVehicle = () => {
        let tempArr = vehicleData
        tempArr.unloadWeight = vehData.unloadWeight
        tempArr.grossVehicleWeight = vehData.grossVehicleWeight
        tempArr.truckSerialNo = vehData.vehicleSerialNo
        tempArr.ownerName = vehData.ownerName
        tempArr.chassisNumber = vehData.chassisNumber
        tempArr.engineNumber = vehData.engineNumber
        tempArr.gpsNumber = vehData.gpsNumber
        tempArr.bodyGroup = vehData.bodyGroup
        tempArr.vehicleType = vehData.vehicleType
        tempArr.bodyType = vehData.bodyType
        tempArr.truckType = vehData.vehicleType
        tempArr.vehicleModel = vehData.vehicleModel
        tempArr.registrationDate = vehData.registrationDate
        tempArr.registrationValidTill = vehData.registrationValidTill
        tempArr.registeredCapacity = vehData.registeredCapacity
        tempArr.capacityUOM = vehData.capacityUOM
        tempArr.ftlRegisteredCapacity = vehData.ftlRegisteredCapacity
        tempArr.pucCheck = vehData.pucCheck
        tempArr.pucValidTill = vehData.pucValidTill
        tempArr.insuranceCheck = vehData.insuranceCheck
        tempArr.insuranceValidTill = vehData.insuranceValidTill
        tempArr.blacklistedFlag = vehData.blacklistedFlag === "NA" ? false : true
        tempArr.address = vehData.address
        tempArr.district = vehData.district
        tempArr.state = vehData.state
        tempArr.country= vehData.country
        tempArr.postalCode = vehData.pinCode
        tempArr.phone = vehData.mobileNo
        tempArr.mobile = vehData.mobileNo
        tempArr.permitStates=vehData.permitStates
        tempArr.permitValidTill = vehData.permitValidTill
        tempArr.permitRegisteredCapacity = vehData.permitRegisteredCapacity
        tempArr.permitCapacityUOM = vehData.permitCapacityUOM
        tempArr.permitActive = vehData.permitActive
        tempArr.addedBy = vehData.ownerName
        tempArr.belongsTo = 'transporter'

        setVehicleData(tempArr)
        const concate = {...vehicleData,...vehicleNumData}
        dispatch(getRegisteredVehicle(concate))
    }
    const _handleFiles = async (files) => {
        if (files.length > 0) {
            let tempArr = [...fileArr]
            let tarr = Array.from(files)
            for (let el of tarr) {
                if (el.type.includes('image') || el.type === "application/pdf") {
                    try {
                        let base64 = await base64Convert(el)
                        let obj = { base64, fileType: el.type.includes('image') ? 'image' : 'pdf', name: el.name }

                        if (obj.fileType === 'pdf') {
                            let pdf = base64.replace(/^data:application\/\pdf;base64,/, "")
                            var binary_string = window.atob(pdf);
                            var len = binary_string.length;
                            var bytes = new Uint8Array(len);
                            for (var i = 0; i < len; i++) {
                                bytes[i] = binary_string.charCodeAt(i);
                            }
                            var buffer = bytes.buffer
                            var file3 = new Blob([buffer], { type: 'application/pdf' });
                            obj['dataLocalUrl'] = file3
                            get_pdf_thumbnail(file3).then((url) => {
                                obj['imageSrc'] = url
                            })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                        tempArr.unshift(obj)
                    }
                    catch (err) {

                    }
                }
                else {
                }
            }
            setFileArr(tempArr)
        }
    }
    const previewImg = ({ item, index }) => {
        setImgBase(item.base64)
    }
    const removeBase = () => {
        setImgBase()
    }
    const deleteFile = (index) => {
        let tempArr = [...fileArr]
        tempArr.splice(index, 1)
        setFileArr(tempArr)
    }

    useEffect(() => {
        if (loader === true) {
            setStatus(false)
        }
        else {
            setStatus(true)
        }
       
    })
  
    return (
        <>
            {imgBase === '' || imgBase === undefined ?
                <section id="addVehicleModal" className="custom-modal">
                    <div className="custom-modal-header">
                        <h1>Add Vehicle</h1>
                        <span className="ct-close" onClick={props.closeModal}></span>
                    </div>
                    
                    <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                        <div className="cust-row">
                            <div className='cust-col-2'></div>
                            <div className='cust-col-6'>
                                <div className="input-group">
                                    <input type="text" name="vehicleNumber" value={vehicleNumData.vehicleNumber} className="form-control" onChange={(e) => _handleChange(e)} placeholder="Enter Vehicle Number..." />
                                    <div className="input-group-append">
                                        <button onClick={fetch} type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                            Fetch
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {status === true ?
                            <div className="cust-row">
                                <div className='cust-col-10'>
                                    <div className='table-responsive'>
                                        <table className='bordered_table'>
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className='thd'>Owner Name</span>
                                                        <h2 className='tht'>{vehData && vehData.ownerName ? vehData.ownerName : ''}</h2>
                                                    </th>
                                                    <th>
                                                        <span className='thd'>Son/Daughter/Wife/Husband Name</span>
                                                        <h2 className='tht'>None</h2>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className='thd'>Engine Number</span>
                                                        <h2 className='tht'>{vehData && vehData.engineNumber ? vehData.engineNumber : ''}</h2>
                                                    </th>
                                                    <th>
                                                        <span className='thd'>Chasis Number</span>
                                                        <h2 className='tht'>{vehData && vehData.chassisNumber ? vehData.chassisNumber : ''}</h2>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className='thd'>Truck Model</span>
                                                        <h2 className='tht'>{vehData && vehData.vehicleModel ? vehData.vehicleModel : ''}</h2>
                                                    </th>
                                                    <th>
                                                        <span className='thd'>Truck Type</span>
                                                        <h2 className='tht'>{vehData && vehData.vehicleType ? vehData.vehicleType : ''}</h2>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className='thd'>Issue Date</span>
                                                        <h2 className='tht'>{vehData && vehData.registrationDate ? moment(vehData.registrationDate).format("h:mm:ss a") : ''}</h2>
                                                    </th>
                                                    
                                                    <th>
                                                        <span className='thd'>Valid Upto</span>
                                                        <h2 className='tht'>{vehData && vehData.registrationValidTill ? moment(vehData.registrationValidTill).format("h:mm:ss a") : ''}</h2>
                                                    </th>
                                                    
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className='thd'>Insurance Validity Upto</span>
                                                        <h2 className='tht'>{vehData && vehData.insuranceValidTill ? moment(vehData.insuranceValidTill).format("h:mm:ss a") : ''}</h2>
                                                    </th>
                                                    
                                                    <th>
                                                        <span className='thd'>PUC Validity Upto</span>
                                                        <h2 className='tht'>{vehData && vehData.pucValidTill ? moment(vehData.pucValidTill).format("h:mm:ss a") : ''}</h2>
                                                    </th>
                                                    
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className='thd'>Truck Serial Number</span>
                                                        <h2 className='tht'>{vehData && vehData.vehicleSerialNo ? vehData.vehicleSerialNo : ''}</h2>
                                                    </th>
                                                    <th>
                                                        <span className='thd'>GPS Number</span>
                                                        <h2 className='tht'>{vehData && vehData.gpsNumber ? vehData.gpsNumber : ''}</h2>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            : ''}
                            {status === true ? 
                        <div className="cust-row">
                            <div className='cust-col-10'>
                                <div className='collapse-view'>
                                    {/* <div className='collapsable_icon' onClick={() => { props.toogleView(0) }}></div> */}
                                    <div className='doc_container'>
                                        <div className='doc_container_head'>
                                            Upload Vehicle Documents
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}
                        {status === true ? 
                        <div className='doc_container_bdy'>
                            <FileUpload handleFiles={_handleFiles} />
                            {fileArr.length ?
                                <div className='file_preview'>
                                    <p>Uploaded Files</p>
                                    <div className='file_preview_cont'>
                                        {
                                            fileArr.map((item, index) => {
                                                return <div key={`pp-` + index} className='preview_itm'>
                                                    <div className='closeb' onClick={() => { deleteFile(index) }}>
                                                        <i className='fa fa-close'></i>
                                                    </div>
                                                    <img src={item.base64} alt="" onClick={() => previewImg({ item, index })} />:
                                                    <div className='fullscreen'>
                                                        <i className='fa fa-expand' onClick={() => previewImg({ item, index })}></i>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                : ""}
                        </div>
                        : ''}
                        {status === true ? 
                        <div className="row-div">
                            <div className="share-div">
                                <button className="action_btn" onClick={()=>addVehicle()}>Add Vehicle</button>
                            </div>
                        </div>
                        : ''}

                    </div>
                </section>
                :
                <section id="UploadContractCopyModal" className="custom-modal" style={{ width: "45%" }}>
                    <div className="custom-modal-header">
                        <h1>Uploaded Document</h1>
                        <span className="ct-close" onClick={() => { removeBase() }}></span>
                    </div>
                    <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '60vh' }}>
                        <div className='imgContainer'>
                            <img src={imgBase} alt='' />
                        </div>
                        {/* <div className='downloadContractdoc'>
                            <button onClick={() => Download()}>Download</button>
                        </div> */}
                    </div>
                </section>
            }
        </>
    )

}

export default AddVehicle;