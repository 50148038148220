import React,{ useEffect, useRef, useState } from 'react' ;
import SignatureCanvas from 'react-signature-canvas'
const PodConfirmation= (props) =>{

    let sigPad= useRef({})
    const [sentPckg, setsentpckg]=useState(5)
    const [packagestate, setpackagestate]=useState(1)
    const [btnstate, setbtnstate]=useState("")
    const [weightsent, setwdtsent]=useState(5)
    const [wgtbtn , setwgtbtn]=useState()

    const [toggleinput, setToggleInput]=useState("hide")  
   
    const [wgtstate, setwgtstate]=useState(1)
    const [checked, setchecked]=useState(true)
    const [checked1, setchecked1]=useState(false)
    const [dropdown, setdropdown]=useState("")
    const [divheight, setDivHeight]=useState("podDiv-2")
    const [inputstate, setinputstate]=useState({
        nameofuser:"",
        designation:"",
        exception:"",
        goods:"",
        missingdoc:"",
        delayeddelivery:"",
        others:""
    })

    const [img, setimg] = useState(null)
    const [signDiv, setsignDiv] = useState("signatureimage")
    const [faIcon, setFaIcon] = useState("hide-btn")

    const incrementpck = () => {
        setpackagestate(packagestate + 1)
    }
    const decrementpck = () => {
        if (packagestate > 0) {
            setpackagestate(packagestate - 1)
        }
    }
    const incrementwgt = () => {
        setwgtstate(wgtstate + 1)
    }
    const decrementwgt = () => {
        if (wgtstate > 0) {
            setwgtstate(wgtstate - 1)
        }
    }
    useEffect(() => {
        if (checked) {
            setchecked(true)
            setchecked1(false)
            setdropdown("podD-2-R-2-select")
            setDivHeight("podDiv-2")

        }
        else if (checked1) {
            setchecked(false)
            setchecked1(true)
            setdropdown("hide")
            setDivHeight("toggle-podDiv-2")
            console.log("2");
        }
        if (sentPckg == packagestate) {
            setbtnstate("togglebtnclass")
        }
        else {
            setbtnstate("redbtnclass")
        }
        if (weightsent == wgtstate) {
            setwgtbtn("togglebtnclass")
        }
        else {
            setwgtbtn("redbtnclass")
        }
        if (inputstate.others == "Others" && checked) {
            setToggleInput("other_input")
        }
        else {
            setToggleInput("hide")
        }

    }, [checked, checked1, sentPckg, packagestate, weightsent, wgtstate, inputstate])

    const handleCheckboxChange = () => {
        setchecked(true)
        setchecked1(false)

    }
    const handleCheckboxChange1 = () => {
        setchecked(false)
        setchecked1(true)
    }
    const handleInputChange = (e) => {
        setinputstate({ ...inputstate, [e.target.name]: e.target.value })
    }

    const Chooseimg = (e) => {
        const files = e.target.files[0]
        if (e.target.files) {
            const image = URL.createObjectURL(files)
            setimg(image)
            sigPad.current.clear();
            setsignDiv("togglesignatureimage")
            setFaIcon("signcanvasbtn")
        }
    }
    const deletePhoto = () => {
        setimg(null)
        sigPad.current.clear();
        setsignDiv("signatureimage")
        setFaIcon("hide-btn")
    }

    const clearSign = () => {
        sigPad.current.clear();
    }

    return (
        <section id="podModal" className="custom-modal" style={{ width: "50%" }}>
            <div className="custom-modal-header">
                <h1>Confirm PoD</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>

            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '60vh' }}>
                <div className="cust-row">

                    <div className="podM">
                        <div className="podouter1">
                            <div className="podH-1">

                                <div className="podr-1">
                                    <span>PACKAGE DETAILS</span>
                                </div>
                                <div className="podr-2">
                                    <div className="podr2-c1">
                                        <span className="podT-h1">PACKAGE TYPE</span>
                                    </div>
                                    <div className="podr2-c2">
                                        <span className="podT-h1">SENT</span>
                                    </div>
                                    <div className="podr2-c3">
                                        <span className="podT-h1">RECEIVED</span></div>
                                </div>
                                <div className="podr-3">
                                    <div className="podr3-c1">
                                        <span className="podT-h1">5</span>
                                    </div>
                                    <div className="podr3-c2">
                                        <span className="podT-h1">{sentPckg}</span>
                                    </div>
                                    <div className="podr3-c3">
                                        <div className="podr3-mid">
                                            <button className={btnstate} onClick={decrementpck}><i id="fa-math-icon" className="fa fa-minus" aria-hidden="true"></i></button>
                                            <span className="podT-h1">{packagestate}</span>
                                            <button className={btnstate} onClick={incrementpck}><i id="fa-math-icon" className="fa fa-plus" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="podH-2">
                                <div className="podH-2-div1">
                                    <span>ACTUAL WEIGHT DETAILS</span>
                                </div>
                                <div className="podH-2-div2">

                                    <div className="podH-2-R-1">
                                        <span>TOTAL WEIGHT(KG)</span>
                                    </div>

                                    <div className="podH-2-R-2">
                                        <div className="podH-2-v1">
                                            <span>SENT</span>
                                        </div>

                                        <div className="podH-2-v2">
                                            <span>{weightsent}</span>
                                        </div>
                                    </div>

                                    <div className="podH-2-R-3">
                                        <div className="podR-3-v1">
                                            <span>RECEIVED</span>
                                        </div>

                                        <div className="podR-3-v2">
                                            <div className="podr3-mid">
                                                <button className={wgtbtn} onClick={decrementwgt}><i id="fa-math-icon" className="fa fa-minus" aria-hidden="true"></i></button>
                                                <span className="podT-h1">{wgtstate}</span>
                                                <button className={wgtbtn} onClick={incrementwgt}><i id="fa-math-icon" className="fa fa-plus" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={divheight}>
                            <div className="podD-2-R-1">
                                <div className="podD-2-I-1">
                                    <span>MARK THE SHIPMENT AS EXCEPTION?</span>
                                </div>
                                <div className="podD-2-I-2">
                                    <div className='choiceyes'>
                                        <input type="checkbox" checked={checked} value="Yes" onChange={handleCheckboxChange} />
                                        <label htmlFor="yesCheckbox">Yes</label>
                                    </div>

                                    <div className='choiceno'>
                                        <input type="checkbox" checked={checked1} onChange={handleCheckboxChange1} value="No" />
                                        <label htmlFor="noCheckbox">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="podD-2-R-2">
                                <select className={dropdown} name="others" value={inputstate.others} onChange={(e) => handleInputChange(e)}>
                                    <option>Select Exception</option>
                                    <option>Damaged Goods</option>
                                    <option>Missing Documents </option>
                                    <option>Delayed Delivery </option>
                                    <option>Others</option>
                                </select>
                                <input className={toggleinput} type="text" placeholder='Enter Remarks' />
                            </div>
                        </div>

                        <div className="podDiv-3">
                            <div className="name-div">
                                <div className="name-div1">
                                    <span>Name</span>
                                </div>
                                <div className="desig-div1">
                                    <span>Designation</span>
                                </div>
                            </div>

                            <div className="input-div">
                                <div className="name-input">
                                    <input name="nameofuser" value={inputstate.nameofuser} onChange={(e) => handleInputChange(e)} type="text" placeholder="Enter Name" />
                                </div>

                                <div className="desig-input">
                                    <input name="designation" value={inputstate.designation} onChange={(e) => handleInputChange(e)} type="text" placeholder="Enter Designation" />
                                </div>
                            </div>

                        </div>

                        <div className="podDiv-4outer">
                            <div className="podDiv-4">
                                <div className="podsigndiv">
                                    <span>Sign Here</span>
                                </div>

                                <div className="pod2btns">

                                    <label className="custom-file-upload">
                                        <input id="selectImage" type="file" onChange={(e) => Chooseimg(e)} />
                                        Select Image
                                    </label>

                                    <button onClick={clearSign} className="claerbtn">Clear Sign</button>
                                </div>
                            </div>

                            <div className="signaturediv">
                                <div className='signcanvas'>
                                <button id={faIcon} onClick={deletePhoto}><i id="fa-removeImg" class="fa fa-minus" aria-hidden="true"></i> </button>
                                    <SignatureCanvas
                                        ref={sigPad}
                                        canvasProps={{width: 400, height: 180, className: 'sigCanvas'}}
                                    />
                                   
                                </div>

                                <div className='pod-btn_cont'>
                                    <button className='button bti bg-orange-out m-0'>
                                        <div className='btn_icon'>
                                            <i className='fa fa-upload'></i>
                                        </div>
                                        <div className='btn_txt font-lg'>Upload</div>
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
};

export default PodConfirmation