import React, { useEffect, useState } from "react";
import "./../../static/css/contracts.css";
import history from "./../../services/history";
import { selectInit } from "./../../services/functions";
import {
  getAllContracts,
  getContractDetail,
  listingApi,
} from "../../state/redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import PerformanceModal from "./Modal/Performance";
import $ from "jquery";
import moment from "moment";

const Contracts = () => {
  const [userGroup, setUserGroup] = useState("");
  const dispatch = useDispatch();

  const contractList = useSelector(
    (state) => state.getContractReducers.contractData
  );
  const loader = useSelector((state) => state.getContractReducers.loading);
  const transportersList = useSelector(
    (state) => state.getListingReducer.transporterListing
  );

  const _handleClick = (id) => {
    $(".custom-modal-overlay").addClass("custom-modal-is-visible");
    $(`#${id}`).addClass("custom-modal-is-visible");
  };

  const _closeModal = () => {
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible");
    $(".custom-modal").removeClass("custom-modal-is-visible");
  };

  const _viewDetail = (index) => {
    history.push({
      state: { index: index },
      pathname: "/dashboard/Contracts/details",
    });
    // dispatch(getContractDetail(index))
  };

  const _addContract = () => {
    history.push({
      pathname: "/dashboard/Contracts/Add-Contract",
      state: { state: "clause" },
    });
  };

  const _addClause = (index) => {
    history.push({
      state: { index: index },
      pathname: "/dashboard/Contracts/Add-Contract",
    });
    // dispatch(getContractDetail(index))
  };

  useEffect(() => {
    setTimeout(() => {
      selectInit();
    }, 100);
    setUserGroup(
      localStorage.getItem("userGroup") === "transporter" ? "2" : "1"
    );
    dispatch(
      getAllContracts({
        docType: "contracts",
        bookmark: "",
        param: "next",
      })
    );
    dispatch(
      listingApi({
        docType: "transporter",
        bookmark: "",
        param: "next",
      })
    );
  }, []);

  return (
    <>
      <div className="contractscontentpanel">
        <div className="cust-row flex-algn-cent">
          <div className="cust-col-2">
            <h1 className="title font-bold font-xl">Contract</h1>
          </div>
          <div className="cust-col-4">
            <div className="input-group">
              <div className="input-group-prepend">
                <select
                  className="form-control select2-single"
                  defaultValue="truck_no"
                  id="searchType"
                >
                  <option value="booking_no">Contract ID</option>
                </select>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
              />
              <div className="input-group-append">
                <button
                  type="submit"
                  className="btn btn-orange p-1 pl-3 pr-3 pt-2"
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="cust-col-2">
            {userGroup !== "2" ? (
              <select
                className="form-control select2-single"
                defaultValue="all"
                id="bookingStatus"
              >
                <option value="all">Select Transporter</option>
                {transportersList &&
                  transportersList.map((item, index) => {
                    return (
                      <option value={index} key={index}>
                        {item.OrgName}
                      </option>
                    );
                  })}
              </select>
            ) : (
              ""
            )}
          </div>

          <div className="cust-col-2 flex-jc-end">
            {userGroup === "1" ? (
              <button
                className="btn btn-shadow btn-secondary size_hgt"
                onClick={() => {
                  _addContract();
                }}
              >
                Add Contract
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <hr className="bgg" />
        <div className="cust-row"></div>
        <div className="contract-container">
          <div className="contract-header1">
            {contractList !== undefined && contractList.length !== 0 ? (
              contractList.map((item, index) => {
                return (
                  <div key={index} className="contractHeader1-one">
                    <div className="contractNum">
                      <button>{index + 1}</button>
                    </div>
                    <div className="Contractone">
                      <div className="contractone-1">
                        <span className="contractspan1">Contract Name</span>
                        <span className="contractspan2">
                          {item.ContractName}
                        </span>
                      </div>
                      <div className="contractone-2">
                        <span className="contractspan1">Contract ID</span>
                        <span className="contractspan2">
                          {item.ContractId}{" "}
                        </span>
                      </div>
                      <div className="contractone-3">
                        <span className="contractspan1">Start Date</span>
                        <span className="contractspan2">
                          {moment(item.StartDate).format("DD/MM/YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className="Contracttwo">
                      <div className="contractTwo-3">
                        <span className="contractspan1">End Date</span>
                        <span className="contractspan2">
                          {moment(item.EndDate).format("DD/MM/YYYY")}
                        </span>
                      </div>
                      <div className="contractTwo-1">
                        <span className="contractspan1">Creation Date</span>
                        <span className="contractspan2">
                          {moment(item.AddedOn).format("DD/MM/YYYY")}
                        </span>
                      </div>
                      <div className="contractTwo-2">
                        <span className="contractspan1">Transporter Name</span>
                        <span className="contractspan2">
                          {item.TransporterName}
                        </span>
                      </div>
                    </div>
                    <div className="Contractthree">
                      <button
                        className="addClausebtn"
                        onClick={() => {
                          _addClause(item._id);
                        }}
                      >
                        Add Clauses
                      </button>
                      <button
                        className="viewClausebtn"
                        onClick={() => {
                          _viewDetail(item._id);
                        }}
                      >
                        View
                      </button>
                      <button
                        className="performanceBtn"
                        onClick={() => {
                          _handleClick("PerformanceModal");
                        }}
                      >
                        Performance
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <span className="noDataClass">No Data Found</span>
            )}
            {contractList !== undefined &&
            contractList !== [] &&
            contractList.length !== 0 ? (
              <div className={loader === true ? "loaderDiv" : "loaderDivNone"}>
                {loader === true ? (
                  <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <PerformanceModal closeModal={_closeModal} />
      </div>
    </>
  );
};

export default Contracts;
