import React, { useState } from 'react';
import $ from 'jquery'
import history from './../../../services/history'

const Vaccine = ({props,parentCallback}) => {
    const [otpRecived, setOtpReceived] = useState('') 
    const [otpVerified, setOtpVerified] = useState('')
    const [submitId,setSubmitId]=useState('')

    const getOtp = (item) => {
        setOtpReceived(item)
        if (item === 'otpReceived' && ($(".vaccineRow").hasClass("hide"))) {
            $(".vaccineRow").removeClass("hide");
            $(".vaccineRow").addClass("vaccineRow");
        } 
    }
    const verifyOtp = (item) => {
        setOtpVerified(item)
        $(".VaccineMob").addClass("hide");
    }
    const submitID = (item) => {
        setSubmitId(item)
        setTimeout(()=>{
            $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
            $(".custom-modal").removeClass("custom-modal-is-visible")
        },1000) 
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }

    return (
        <section id="VaccineModal" className="custom-modal" style={{ width: "33%" }}>
            <div className="custom-modal-header">
                <h5> {otpVerified === 'verified' ? 'Submit your Beneficary ID' : 'Get your document by entering the required details.'}</h5>
                <span className="ct-close" onClick={_closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '75vh' }}>
                <div className="VaccineMob">
                    <div className='vaccineRow justify-end'>
                        <span className='vaccine_span1'>An OTP will be sent to your mobile number for verification</span>
                    </div>
                    <div className='vaccineRow'>
                        <input className='vaccineField' type='number' placeholder='Enter your mobile number' />
                    </div>
                    <div className='vaccineRow hide'>
                        <input className='vaccineField heightXl' type='number' />
                        <span className='overlapCode'>Enter the 6-digit OTP</span>
                    </div>
                    <div className='vaccineRow'>
                        {otpRecived === 'otpReceived' ?
                            <button className='vaccineotpbtn' onClick={() => verifyOtp('verified')}>VERIFY OTP</button>
                            :
                            <button className='vaccineotpbtn' onClick={() => getOtp('otpReceived')}>GET OTP</button>
                        }
                    </div>
                    <div className='vaccineRowText'>
                        <span>Didn't Receive a code ? <span className='resendText'>Resend</span></span>
                    </div>
                </div>

                {otpVerified === 'verified' ?
                    <div className="VaccineNum"> 
                        <div className='vaccineRow justify-end'>
                            <span className='vaccine_span1'>Please Enter your Beneficary Reference ID</span>
                        </div>
                        <div className='vaccineRow'>
                            <input className='vaccineField' type='number' placeholder='Enter your Beneficary Reference ID' />
                        </div>
                        <div className='vaccineRow'>
                            <button className='vaccineotpbtn' onClick={() =>submitID('successfully',parentCallback(true))}>SUBMIT</button>
                        </div>
                        {submitId==='successfully'?
                            <div className='vaccineRowText'>
                                <span className='success_text'>Submitted Successfully! Fetching your data...</span>
                            </div>
                        :''}
                    </div>
                    : ''}
            </div>
        </section>
    )
}

export default Vaccine;