import { get } from './../../../services/requests'
import { getImages } from './getImage'
import { GET_CONTRACT , HIDE ,RESET_CONTRACT_DATA} from './types'

export const getContractDetail = (index) =>{ 
    return(dispatch)=>{
        get(`contract/GetContract/${index}`)
        .then(response=>{
             const contractData = response.data
             dispatch(storeContract(contractData)) 
             dispatch(hideLoader())   
             let arr = contractData.AttachedDocuments
                for (let i = 0; i < arr.length; i++) {
                    let key = contractData && contractData.AttachedDocuments && contractData.AttachedDocuments[i] && contractData.AttachedDocuments[i].documentKey ? contractData.AttachedDocuments[i].documentKey : ''
                    if (key.length !== 0) {
                        dispatch(getImages(key))
                    }
                }  
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
} 
export const storeContract = (contractData) => {
    return {
        type: GET_CONTRACT ,
        payload: contractData
    }
}
export const hideLoader = () => {
    return {
        type: HIDE
    }
}
export const resetContract = () => {
    return {
        type: RESET_CONTRACT_DATA
    }
}

