import React from 'react'

const Radio = (props) => {
    const { ele, mainIndex, inputValueHandler , shareIndex , removeItem } = props;

    const setTrue = (innerIndex) => {
        ele.show = true
        shareIndex(mainIndex, ele.values)
        inputValueHandler({e:'a' , index: mainIndex , innerIndex: innerIndex})
    }
    return (
        <> 
            <div className='flexCol'>
            {ele.values.length !== 0 ?
                ele.values && ele.values.length && ele.values.map((data, index) => {
                    return ( 
                        <div className='FieldElem alignCenter incOperHgt' key={index}>
                        <i className="fa fa-minus absMius" onClick={(e)=>removeItem({e,index: mainIndex,innerIndex:index})}></i>
                            <input className='RadioinputField' type={ele.type} checked={data.check} onChange={()=>setTrue(index)}  onClick={() => setTrue()} />
                            <span onClick={() => setTrue()}>{data.label}</span>
                        </div>
                    )
                })   
            :''}  
            </div>
        </>

    )
}

export default Radio;