import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import axios from 'axios';

const ImgPreviewModal = (props) => {
    const base64 = props.base || ''
    
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    useEffect(() => {
    }, [props])

    return (
        <section id="ImgPreviewModal" className="custom-modal" style={{ width: '50%', top: '50%' }}>
            <div className="custom-modal-header">
                <h1>Uploaded Document</h1>
                <span className="ct-close" onClick={() => _closeModal("Inspectimgmodal")}></span>
            </div>
            <div className="custom-modal-body" style={{ overflow: 'auto', background: '#F8F8F8', height: '305px' }}>
                <div className="cust-row centerImage">
                    {base64  ?
                        <img className='classicImg' src={base64} alt='' />
                        :
                        <div className='noImgCont'>
                            <span>No Image Found</span>
                        </div>
                    }
                </div>
            </div>
        </section>
    )

}

export default ImgPreviewModal;