import { selectInit } from './../../../services/functions'
import { useEffect } from 'react'
const AddBankDetail = (props) =>{

    useEffect(()=>{
        setTimeout(() => {
            selectInit()
        }, 100); 
    })
 
    return(
        <section id="addBankDetailModal" className="custom-modal toggle_width" style={{width:"60%"}}>
            <div className="custom-modal-header">
            <h1>Add Bank Detail</h1>
            <span className="ct-close" onClick={props.closeModal}></span>
            </div> 
            <div className="custom-modal-body" >
                <div className="cust-row mb-2">
                    <div className="cust-col-5">
                        <label className="black_color font-bold text-left wdt100">Account Number<span className="red_color">*</span></label>
                        <input className="cust-input" type="text" placeholder="Account"/>
                    </div>
                    <div className="cust-col-5">
                        <label className="black_color font-bold text-left wdt100">Account Type<span className="red_color">*</span></label>
                        <select className="cust-dropdown">
                            <option>Select Account</option>
                            <option>Saving Account</option>
                            <option>Curent Account</option>
                        </select>
                        
                    </div>
                </div>
                <div className="cust-row mb-2">
                    <div className="cust-col-5">
                        <label className="black_color font-bold text-left wdt100">Bank Name<span className="red_color">*</span></label>
                        <input className="cust-input" type="text" placeholder="Bank Name"/>
                    </div>
                    <div className="cust-col-5">
                        <label className="black_color font-bold text-left wdt100">Beneficiary Name<span className="red_color">*</span></label>
                        <input className="cust-input" type="text" placeholder="Beneficiary Name"/>
                    </div>
                </div>
                <div className="cust-row mb-2">
                    <div className="cust-col-5">
                        <label className="black_color font-bold text-left wdt100">Branch Name<span className="red_color">*</span></label>
                        <input className="cust-input" type="text" placeholder="Branch Name"/>
                    </div>
                    <div className="cust-col-5">
                        <label className="black_color font-bold text-left wdt100">IFSC Code<span className="red_color">*</span></label>
                        <input className="cust-input" type="text" placeholder="IFSC"/>
                    </div>
                </div>
                <div className="cust-row">
                    <div className="cust-col-3">

                    </div>
                    <div className="cust-col-4">
                        <button className='button bti bg-grey-out size-reduce'>
                            <div className='btn_icon'>
                                <i className='fa fa-plus'></i>
                            </div>
                            <div className='btn_txt '>Add Bank Detail</div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddBankDetail