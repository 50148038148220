import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import AddUser from './Modal/AddUser'
import UserPlantView from './Modal/UserPlantView'
import { getPlantsListing } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { selectInit } from './../../services/functions' 

const Plants = (id) => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.getPlantsListReducers.plantsData)
    console.log('listing',listing);
    const loader = useSelector((state) => state.getPlantsListReducers.loading)
    const bookmark = useSelector((state) => state.getPlantsListReducers.bookmarks)
    const message = useSelector((state) => state.getPlantsListReducers.message)
    const records = useSelector((state) => state.getPlantsListReducers.records)
    const [plantDetail, setPlantDetail] = useState()
    const [plantData, setData] = useState('')
    const [plantInfo, setPlantInfo] = useState([])
    const [val, setVal] = useState('')
    const [obj, setObj] = useState(
        {
            docType: "plants",
            bookmark: '',
            param: "next"
        }
    )
    const [searchObject, setSearchObject] = useState(
        {
            bookmark: "",
            param: "",
            pageSize: "",
            searchType: "",
            searchValue: "",
            status: "",
            docType: "plants"
        }
    )
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const plantView = (item) => {
        setPlantDetail(item)
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const updateCreate = (item, info) => {
        if (item === 'createPlant') {
            setData('createPlant')
        }
        else {
            setData('updatePlant')
            setPlantInfo({ list: info, update: true })
        }
    }
    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            docType: "plants"
        }
        setObj(object)
        dispatch(getPlantsListing(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            docType: "plants"
        }
        setObj(object)
        dispatch(getPlantsListing(object))
        bookmark.pop()
    }
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 20);
        bookmark.length = 0
        setObj(
            {
                bookmark: "",
                param: "next",
                pageSize: "",
                searchType: "",
                searchValue: "",
                status: "",
                docType: "plants"
            }
        )
        dispatch(getPlantsListing(obj))
    }, [dispatch])

    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchType = value
            setSearchObject(temp)
        }
        else if (id === 'status') {
            setVal(value)
        }
    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(getPlantsListing({
            pageSize: "",
            bookmark: "",
            searchType: searchObject.searchType,
            searchValue: searchObject.searchValue,
            status: "",
            docType: "plants",
            param: "next"
        }))
    }
    useEffect(() => {
        if (val) {
            dispatch(getPlantsListing({
                pageSize: "",
                bookmark: "",
                searchType: "",
                searchValue: "",
                status: val,
                docType: "plants",
                param: "next"
            }))
        }
    }, [val])
    return (
        <>
            <div className="contentpanel">
                <h1 className="title">Plants</h1>
                <hr />
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Plants List</div>
                        </div>
                        <div className='cust-col-4-new'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="select" id="searchType">
                                        <option value="select" disabled>Select</option>
                                        <option value="plantName">Plant Name</option>
                                        <option value="gstin">GSTIN</option>
                                    </select>
                                </div>
                                <input type="text" name='searchValue' value={searchObject.searchValue} onChange={(e) => _onChangeSearch(e)} className="form-control" placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2"
                                        disabled={searchObject.searchType === 'select' || searchObject.searchType === undefined ? true : false}
                                        onClick={() => triggerSearch()}>
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                        <select className="form-control select2-single" defaultValue="select" id="status">
                            <option value="select" disabled>Status</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">InActive</option>
                        </select>
                    </div>
                        <div className='cust-col-3 flex-jc-end'></div>
                        <div className='cust-col-2 flex-jc-end'>
                            <button className='btn btn-shadow btn-secondary size_hgt' onClick={() => { _handleClick('addUserModal'); updateCreate("createPlant"); }}>Create Plant</button>
                        </div>
                    </div>
                    <div className='cust-row'>
                        {listing && listing.length !== 0 ?
                            loader === false ?
                                <div className={loader === false ? 'loaderDiv' : 'loaderDivsne'}>
                                    <i className="fa fa-spinner fa-spin loadingSpinner font-xxl  "></i>
                                </div>
                                :
                                <div className='cust-col-10'>
                                    <div className='userTable'>
                                        <div className='userRow'>
                                            <div className='userR1'>
                                                <span className='userspan'>S. No.</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>PLANT NAME</span>
                                            </div>
                                            <div className='userR2' style={{ width: '22%' }} >
                                                <span className='userspan'>ADDRESS</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>STATE</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>CITY</span>
                                            </div>
                                            <div className='userR2' style={{ width: '10%' }}>
                                                <span className='userspan'>PIN CODE</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>GSTIN</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>ACTION</span>
                                            </div>
                                        </div>
                                        {listing.map((item, index) => {
                                            return (
                                                <div className='userDynamicRow'>
                                                    <div className='userR1 hgt4'>
                                                        <span className='userspan roundSpan'>{index + 1}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan txtColored'>{item.PlantName}</span>
                                                    </div>
                                                    <div className='userR2 hgt100' style={{ width: '22%' }}>
                                                        <span className='userspan normaltxt'>{item.Address}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan normaltxt'>{item.State}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan normaltxt'>{item.City}</span>
                                                    </div>
                                                    <div className='userR2 hgt100' style={{ width: '10%' }}>
                                                        <span className='userspan normaltxt'>{item.PinCode}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan normaltxt'>{item.GSTIN}</span>
                                                    </div>
                                                    <div className='userR2 hgt100 justItem'>
                                                        <i className="fa fa-eye userEye" onClick={() => { _handleClick('UserPlantView'); plantView(item) }}></i>
                                                        <i className="fa fa-edit userEdit" onClick={() => { _handleClick('addUserModal'); updateCreate("updatePlant", item); }}></i>
                                                        <i className="fa fa-times userCross" ></i>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            :
                            <span className='noDataClass'>{message}</span>
                        }
                    </div>
                </div>
                {listing && listing.length !== 0 ?
                    <div className='paginationDiv'>
                        <button disabled={obj && obj.bookmark === '' ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                        <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                    </div>
                    : ''}
                <AddUser closeModal={_closeModal} sharedData={{ userdata: plantData, item: plantInfo }} />
                <UserPlantView closeModal={_closeModal} plantDetail={plantDetail} />
            </div>
        </>
    )
}
export default Plants;