import { post } from './../../../services/requests'
import { GET_USERS, SHOW_USER, HIDE_USER, PAGINATION, RECORDS } from './types'
const pageSize = 5
export const getUserList = (data) => {
    return (dispatch) => {
        dispatch(showUserLoader())
        post('user/GetAllUsers', {
            pageSize: pageSize,
            bookmark: data.bookmark,
            departmentId: data.departmentId,
            plantId: data.plantId,
            searchType: data.searchType,
            searchValue: data.searchValue,
            userType: data.userType
        })
            .then(response => {
                const userData = response.data
                dispatch(hideUserLoader())
                if (userData && userData === undefined ) {
                }
                else {

                    const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                    const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                    dispatch(storeUserData(userData))
                    dispatch(getRecordCount(records))

                    if (data.param === "next" || data.param === "") {
                        dispatch(pagination(bookmark))
                    }
                }
            })
            .catch(error => {
                console.log('user error', error);
            })
    }
}
export const storeUserData = (addressData) => {
    return {
        type: GET_USERS,
        payload: addressData
    }
}
export const showUserLoader = () => {
    return {
        type: SHOW_USER
    }
}
export const hideUserLoader = () => {
    return {
        type: HIDE_USER
    }
}
export const pagination = (bookmark) => {
    return {
        type: PAGINATION,
        payload: bookmark
    }
}
export const getRecordCount = (records) => {
    return {
        type: RECORDS,
        payload: records
    }
} 