import React, { useState, useEffect } from 'react';
import history from './../../services/history';
import { getAddressData, listingApi } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { selectInit } from './../../services/functions'

const AddressBook = () => {
    const dispatch = useDispatch()
    const [lat, setLat] = useState(28.5470088)
    const [lng, setLng] = useState(77.3560634)
    const addressList = useSelector((state) => state.getListingReducer.addressListing)
    const loader = useSelector((state) => state.getListingReducer.loading)
    const bookmark = useSelector((state) => state.getListingReducer.bookmarks)
    const records = useSelector((state) => state.getListingReducer.records)
    const [obj, setObj] = useState(
        {
            docType: "address-book",
            bookmark: "",
            param: "next"
        }
    )
    const [val, setVal] = useState('')
    const [searchObject, setSearchObject] = useState(
        {
            bookmark: "",
            docType: "address-book",
            param: "",
            pageSize: "",
            searchType: "",
            searchValue: "",
            status: ""
        }
    )
    const openAddressDetails = (addressIndex) => {
        history.push({
            pathname: "/dashboard/address-book/details",
            state: { addressIndex: addressIndex }
        })
        // dispatch(getAddressData(addressIndex))
    }
    const openAddAddress = () => {
        history.push("/dashboard/address-book/add-address")
    }
    const openMap = () => {
        history.push({
            pathname: '/dashboard/Map',
            state: { lat, lng }
        })
    }
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 100);
        bookmark.length = 0
        setObj(
            {
                bookmark: "",
                docType: "address-book",
                param: "next",
                pageSize: "",
                searchType: "",
                searchValue: "",
                status: ""
            }
        )
        dispatch(listingApi(obj))
    }, [dispatch])

    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            docType: "address-book"
        }
        setObj(object)
        dispatch(listingApi(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            docType: "address-book"
        }
        setObj(object)
        dispatch(listingApi(object))
        bookmark.pop()
    }
    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchType = value
            setSearchObject(temp)
        }
        else if (id === 'status') {
            setVal(value)
        }
    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(listingApi({
            bookmark: "",
            docType: "address-book",
            param: "",
            pageSize: "",
            searchType: searchObject.searchType,
            searchValue: searchObject.searchValue,
            status: ""
        }))
    }
    useEffect(() => {
        if (val) {
            dispatch(listingApi({
                bookmark: "",
                docType: "address-book",
                param: "",
                pageSize: "",
                searchType: "",
                searchValue: "",
                status: val
            }))
        } 
    }, [val])
    return (
        <div className="contentpanel">
            <div className='cust-row'>
                <div className='cust-col-8 flex-algn-cent'>
                    <h1 className="title">Address Book</h1>
                </div>
                <div className='cust-col-2 flex-jc-end' >
                    <div className='cust-col-2 flex-jc-end' onClick={openAddAddress}>
                        <button className='btn btn-shadow btn-secondary size_hgt'>Add Address</button>
                    </div>
                </div>
            </div> 
            <hr className='bgg' />
            <div className="cust-container">

                <div className='cust-row'>
                    <div className='cust-col-2 flex-algn-cent'>
                        <div className='tt2'>Address Book List</div>
                    </div>
                    <div className='cust-col-4-new'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <select className="select2-single" defaultValue="select" id="searchType">
                                    <option value="select" disabled>Select</option>
                                    <option value="name">Company Name</option>
                                    <option value="pan">PAN</option>
                                    <option value="gstin">GSTIN</option>
                                </select>
                            </div>
                            <input type="text" name='searchValue' value={searchObject.searchValue} onChange={(e) => _onChangeSearch(e)} className="form-control" placeholder="Search here..." />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2"
                                    disabled={searchObject.searchType === 'select' || searchObject.searchType === undefined ? true : false}
                                    onClick={() => triggerSearch()}
                                >
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='cust-col-2'>
                        <select className="form-control select2-single" defaultValue="select" id="status">
                            <option value="select" disabled>Status</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">InActive</option>
                        </select>
                    </div>
                </div>
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='book_content'>
                            {addressList && addressList.length !== 0 ?
                                loader === true ?
                                    <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                        <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                                    </div>
                                    :
                                    <div className='userTable'  >
                                        <div className='userRow'>
                                            <div className='userR1'>
                                                <span className='userspan'>S. No.</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>BUSINESS ID</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>PAN NO.</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>GSTIN</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>COMPANY NAME</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>ADDRESS</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>NO OF DELIVERY LOCATIONS</span>
                                            </div>
                                            {/* <div className='userR2'>
                                                        <span className='userspan'>GSTIN</span>
                                                    </div> */}
                                            <div className='userR2'>
                                                <span className='userspan'>ACTION</span>
                                            </div>
                                        </div>
                                        {addressList.map((item, index) => {
                                            return (
                                                <div key={index} className='userDynamicRow'>
                                                    <div className='userR1 hgt4'>
                                                        <span className='userspan roundSpan'>{index + 1}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan txtColored'>{item.ReadableID}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan normaltxt'>{item.PAN}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan normaltxt'>{item.GSTIN}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan normaltxt'>{item.CompanyName}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan normaltxt'>{item.Address}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan normaltxt'>2</span>
                                                    </div>
                                                    <div className='userR2 hgt100 justItem'>
                                                        <i className="fa fa-eye userEye" onClick={() => openAddressDetails(item._id)}></i>
                                                        <i className="fa fa-map-marker" onClick={() => openMap()}></i>
                                                        <i className="fa fa-times userCross" ></i>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                :
                                <span className='noDataClass'>No Data Found</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {addressList && addressList.length !== 0 ?
                <div className='paginationDiv'>
                    <button disabled={obj && obj.bookmark === '' ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                    <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                </div>
                : ''}
        </div>

    )
}
export default AddressBook;