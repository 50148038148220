import React, { Component } from 'react';
import { selectInit } from './../../services/functions'
import $ from 'jquery'
import AnalysisModal from './../Dashboard/Modal/Analysis'
import TrackingModal from './../Dashboard/Modal/Tracking' 
import ViewDocumentModal from './ViewDocuments'
import history from '../../services/history';

import view from './../../static/images/icon/buttons/ViewDetail.svg'
import analytics from './../../static/images/icon/buttons/Analytics.svg'
import cancel from './../../static/images/icon/buttons/Cancel.svg'
import Delete from './../../static/images/icon/buttons/Delete.svg'
import tracking from './../../static/images/icon/buttons/Tracking.svg'
import update from './../../static/images/icon/buttons/Update.svg'
import documentView from './../../static/images/icon/buttons/ViewDocuments.svg'


class Receipt extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userType: localStorage.getItem("userType") === 'userType2' ? '2' : "1",
            type: props.type
        }
    }

    componentDidMount() {
        setTimeout(() => {
            selectInit(this._onChange)
        }, 100)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.type !== prevState.type) {
            return ({
                type: nextProps.type
            });
        }
        return null
    }

    toogleItemView(index) {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")
        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }

    _onChange(id, value) {
        console.log("id", id, "value", value)
    }

    _handleClick(id) {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    _closeModal() {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    } 

    _copyID() {
        var copyText = document.getElementById("myInput");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        navigator.clipboard.writeText(copyText.value);

    }

    _goto() {
        history.push({
            pathname: "/dashboard/goods-receipt/detail",
            state: { url_param: this.state.type }
        })
    }

    _createReceipt() {
        history.push(history.push("/dashboard/goods-receipt/create"))
    }

    render() {
        return (
            <div className="contentpanel">
                <h1 className="title">Lorry Receipt</h1>
                <hr />
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Receipt List</div>
                        </div>
                        <div className='cust-col-4'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="truck_no" id="searchType">
                                        <option value="truck_no">Receipt No.</option>
                                    </select>
                                </div>
                                <input type="text" className="form-control" placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="all" id="ownershipStatus">
                                <option value="all">Vehicle Type</option>
                                <option value="owned">Owned Vehicle</option>
                                <option value="hired">Hired Vehicle</option>
                            </select>
                        </div>
                        {localStorage.userType === 'userType2' ? "" :
                            <div className='cust-col-2 flex-jc-end'>
                                <button className='btn btn-shadow btn-secondary size_hgt' onClick={() => { this._createReceipt() }}>Create Receipt</button>
                            </div>
                        }
                    </div>
                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='data-cont'>
                                <div className='data-cont-item'>
                                    {localStorage.getItem("userType") === 'userType2' ? "" :
                                        <div className='data_item_content align-div pad-0'>
                                            <div className='wd_30 clip-path display-col'>
                                                <span className='tbl-hd-1'>Transporter :</span>
                                                <span className='tbl-hd-2'>Ajay</span>
                                                <span className='tbl-hd-3'>(ID : XXXX)</span>
                                            </div>
                                            <div className='wd_25 bg-trans inc-hgt'>
                                                <div className='dateTime'>
                                                    <span>Date/Time</span>
                                                </div>
                                                <div className='date'>
                                                    <i className="fa fa-calendar"></i>
                                                    <span>18-03-2022</span>
                                                </div>
                                                <div className='time'>
                                                    <i className="fa fa-clock-o xl"></i>
                                                    <span>08:30:24 AM</span>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                    <div className='data_item_content set_height_xxl pad-top-0'>
                                        <div className='wd_5 align-cent set-hght'>
                                            <div className='sr-no '>
                                                <span>1</span>
                                            </div>
                                        </div>
                                        <div className='wd_10'>
                                            <span>LR. No.</span>
                                            <span className='orange_color font-lg'>12345</span>
                                            <span>(Oct 12th 2021 11:35 AM)</span>
                                        </div>
                                        {this.state.type === 'document-ready' ?
                                            <div className='wd_10' >
                                                <span>Pickup</span>
                                                <span className='flex_box_2'>Khasra No. 35, Kila No. 6/2, 15, 16/1, 16/2/1, Sikri - Piyala Road, Village Piyala, Tehsil Ballabgarh, District Faridabad, Haryana, Pin – 121004, India</span>
                                            </div>
                                            : ''}
                                        <div className='wd_10'>
                                            <span>Dropoff</span>
                                            <span className='flex_box_2'>New Delhi Office, g-89 , govind puri, New Delhi, Delhi - 110099</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Sales Order</span>
                                            <span>Ekayan</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Goods Type</span>
                                            <span>Ekayan</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Vehicle No./Type</span>
                                            <span>UP-AC-2277 , <br /> (Ekayan)</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Loading Slip No. / Date & Time</span>
                                            <span>LR-0001 <br /> 18-03-2022 , 08-30-45 AM </span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Estimate Delivery date</span>
                                            <span>12-01-2023</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Gate-Out Gate No.</span>
                                            <span>02</span>
                                        </div>
                                        {this.state.type === 'logistics-gate-out' ?
                                            <div className='wd_10'>
                                                <span>Status</span>
                                                <span>Gate-Out Done</span>
                                            </div>
                                            : ''}
                                        {this.state.type === 'material-gate-out' ?
                                            <div className='wd_10'>
                                                <span>Status</span>
                                                <span>Material Gate-Out Done</span>
                                            </div>
                                            : ""}
                                        <div className='wd_5 align-cent set-hght set-bg'>
                                            <div className='sr-no trans' onClick={() => { this.toogleItemView(0) }}>
                                                <i className="fa fa-plus expand-tbl open"></i>
                                                <i className="fa fa-minus contract-tbl hide" ></i>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='data_item_content set_height_xxxl clps setColor'>
                                        {
                                            this.state.type === 'all' ?
                                                <>
                                                    {/* <div className='wd_10'>
                                                    <span>Contract Type</span>
                                                    <span>Full Truck Load</span>
                                                </div> */}
                                                    <div className='wd_15'>
                                                        <span>Actual Weight(KG)</span>
                                                        <span>1000</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Package Details</span>
                                                        <span>15(Box)</span>
                                                    </div>
                                                    <div>
                                                        <span>Consignor</span>
                                                        <span className='flex_box_2'>HIL Faridabad- Khasra No. 35, Kila No. 6/2, 15, 16/1, 16/2/1, Sikri - Piyala Road, Village Piyala, Tehsil Ballabgarh, District Faridabad, Haryana, Pin – 121004, India</span>
                                                    </div>
                                                    <div>
                                                        <span>Consignee</span>
                                                        <span className='flex_box_2'>RISHABH CONSIGNOR, Test Address of consignor, Bhagalpur, Bihar - 110092</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Total Freight</span>
                                                        <span>100000</span>
                                                    </div>
                                                    <div className={localStorage.userType === 'userType2' ? 'wd_30' : 'wd_20'}>
                                                        <span>Tracking ID</span>
                                                        <input type="text" value="9988776655" onChange={() => { this._onChange() }} id="myInput" />
                                                        <i onClick={() => { this._copyID() }} className='fa fa-clone copyIcon align-start '></i>
                                                    </div>
                                                    {localStorage.userType === 'userType2' ?
                                                        <div className='wd_10'>
                                                            <span>Status</span>
                                                            <span>Change Pending</span>
                                                        </div>
                                                        : ""}
                                                </>
                                                : ""
                                        }
                                        {
                                            this.state.type === 'document-ready' ?
                                                <>
                                                    <div className='wd_10'>
                                                        <span>Consignor</span>
                                                        <span className='flex_box_2'>HIL Faridabad- Khasra No. 35, Kila No. 6/2, 15, 16/1, 16/2/1, Sikri - Piyala Road, Village Piyala, Tehsil Ballabgarh, District Faridabad, Haryana, Pin – 121004, India</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Consignee</span>
                                                        <span className='flex_box_2'>RISHABH CONSIGNOR, Test Address of consignor, Bhagalpur, Bihar - 110092</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Actual Weight(KG)</span>
                                                        <span>1000</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Package Details</span>
                                                        <span>15(Box)</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Total Freight</span>
                                                        <span>100000</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Invoice No./ Date & Time</span>
                                                        <span>IN0001 , <br /> 18-03-2022 , 08-30-45 AM </span>
                                                    </div>

                                                    {/* <div className={localStorage.userType === 'userType2' ? 'wd_20' : 'wd_10'}>
                                                        <span>Tracking ID</span>
                                                        <input type="text" value="9988776655" id="myInput"></input>
                                                        <i onClick={() => { this._copyID() }} className='fa fa-clone copyIcon align-start'></i>
                                                    </div> */}

                                                    <div className='wd_35 no-mg-pd' >
                                                        <div className='btn_row_1'>
                                                            <button className='btn btn-shadow btn-sm btn-outline-secondary ml-1 mr-1 ' onClick={() => { this._handleClick('viewDocumentsModal') }}><img className='imgg' src={documentView} alt="none" />View Documents</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 '><img src={update} alt="none" />Update</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1 '><img src={Delete} alt="none" />Delete</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1 '><img src={cancel} alt="none" />Cancel</button>
                                                        </div>
                                                        <div className='btn_row_2'>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 ' onClick={() => { this._handleClick('trackingModal') }}><img src={tracking} alt="none" />Tracking</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 ' onClick={() => { this._handleClick('analysisModal') }}><img src={analytics} alt="none" />Analytics</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-info ml-1 mr-1 ' onClick={() => { this._goto() }}><img src={view} alt="none" />View Detail</button>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""
                                        }

                                        {
                                            this.state.type === 'logistics-gate-out' ?
                                                <>
                                                    <div className='wd_10'>
                                                        <span>Driver Name</span>
                                                        <span>(Ekayan)</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Invoice No./ Date & Time</span>
                                                        <span>IN0001 , <br /> 18-03-2022 , 08-30-45 AM </span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Loadable Capacity </span>
                                                        <span>40 Ton</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Tare Weight</span>
                                                        <span>1 Ton</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Gross Weight</span>
                                                        <span>32 Ton</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Gate Out Date / Time</span>
                                                        <span>10-05-2021, <br /> 03:30:00 PM</span>
                                                    </div>
                                                    <div className='wd_35 no-mg-pd' >
                                                        <div className='btn_row_1'>
                                                            <button className='btn btn-shadow btn-sm btn-outline-secondary ml-1 mr-1 ' onClick={() => { this._handleClick('viewDocumentsModal') }}><img className='imgg' src={documentView} alt="none" />View Documents</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 '><img src={update} alt="none" />Update</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1 '><img src={Delete} alt="none" />Delete</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1 '><img src={cancel} alt="none" />Cancel</button>
                                                        </div>
                                                        <div className='btn_row_2'>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 ' onClick={() => { this._handleClick('trackingModal') }}><img src={tracking} alt="none" />Tracking</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 ' onClick={() => { this._handleClick('analysisModal') }}><img src={analytics} alt="none" />Analytics</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-info ml-1 mr-1 ' onClick={() => { this._goto() }}><img src={view} alt="none" />View Detail</button>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""
                                        }
                                        {
                                            this.state.type === 'material-gate-out' ?
                                                <>
                                                    <div className='wd_10'>
                                                        <span>Booking Weight</span>
                                                        <span>40/100</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Loadable Capacity </span>
                                                        <span>40 Ton</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Tare Weight</span>
                                                        <span>1 Ton</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Gross Weight</span>
                                                        <span>32 Ton</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Invoice No./ Date & Time</span>
                                                        <span>IN0001 , <br /> 18-03-2022 , 08-30-45 AM </span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Material Gate-Out Time</span>
                                                        <span>10-05-2021, <br /> 03:30:00 PM</span>
                                                    </div>
                                                    <div className='wd_35 no-mg-pd' >
                                                        <div className='btn_row_1'>
                                                            <button className='btn btn-shadow btn-sm btn-outline-secondary ml-1 mr-1 ' onClick={() => { this._handleClick('viewDocumentsModal') }}><img className='imgg' src={documentView} alt="none" />View Documents</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 '><img src={update} alt="none" />Update</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1 '><img src={Delete} alt="none" />Delete</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-danger ml-1 mr-1 '><img src={cancel} alt="none" />Cancel</button>
                                                        </div>
                                                        <div className='btn_row_2'>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 ' onClick={() => { this._handleClick('trackingModal') }}><img src={tracking} alt="none" />Tracking</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 ' onClick={() => { this._handleClick('analysisModal') }}><img src={analytics} alt="none" />Analytics</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-info ml-1 mr-1 ' onClick={() => { this._goto() }}><img src={view} alt="none" />View Detail</button>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AnalysisModal closeModal={this._closeModal} />
                <TrackingModal closeModal={this._closeModal} />
                <ViewDocumentModal closeModal={this._closeModal} />
            </div>
        )
    }
}

export default Receipt;