import { get } from './../../../services/requests'
import { getImages } from './getImage'
import { GET_SINGLE_STAFF, HIDE_SINGLE_STAFF, RESET_STAFF_DATA } from './types'

export const getStaffinfo = (StaffId) => {
    return (dispatch) => {
        get(`staff/GetStaffDetails/${StaffId}`)
            .then(response => {
                const singleStaffData = response.data
                dispatch(storeStaffData(singleStaffData))
                dispatch(hideStaffLoader())
                let arr = singleStaffData.AttachedDocuments
                for (let i = 0; i < arr.length; i++) {
                    let key = singleStaffData && singleStaffData.AttachedDocuments && singleStaffData.AttachedDocuments[i] && singleStaffData.AttachedDocuments[i].documentKey ? singleStaffData.AttachedDocuments[i].documentKey : ''
                    if (key.length !== 0) {
                        dispatch(getImages(key))
                    }
                }  
            })
            .catch(error => {
                console.log('error', error);
            })
    }
}
export const storeStaffData = (addressData) => {
    return {
        type: GET_SINGLE_STAFF,
        payload: addressData
    }
}
export const hideStaffLoader = () => {
    return {
        type: HIDE_SINGLE_STAFF
    }
} 
export const resetStaff = () => {
    return {
        type: RESET_STAFF_DATA
    }
}