import React from 'react';
import TotalPOD from './../../../static/images/icon/contract/TotalPOD.svg'
import TotalRevenue from './../../../static/images/icon/contract/TotalRevenue.svg'
import TotalTonnage from './../../../static/images/icon/contract/TotalTonnage.svg'
import TotalBooking from './../../../static/images/icon/contract/TotalBooking.svg'
import Accepted from './../../../static/images/icon/contract/Accepted.svg'
import Rejected from './../../../static/images/icon/contract/Rejected.svg'
import Overspeed from './../../../static/images/icon/contract/Overspeed.svg'
import OverStoppage from './../../../static/images/icon/contract/OverStoppage.svg'
import LateIndenting from './../../../static/images/icon/contract/LateIndenting.svg'
import Latedelivery from './../../../static/images/icon/contract/Latedelivery.svg'

const Performance = (props) => {

    return (
        <>
            <section id="PerformanceModal" className="custom-modal" style={{width: '60%'}}>
                <div className="custom-modal-header">
                    <h1>Contract Performance</h1>
                    <span className="ct-close" onClick={props.closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                <div className="contract-header2">
                        <div className="Contract-h2-div-1">
                            <div className="contract-h2-r1">
                                <span>Contract Performance</span>
                            </div>
                            <div className="contract-h2-r2">
                                <div className="Contract-div-1-c1">
                                    <div className="contract-c1-r1">
                                        <img src={TotalPOD} alt="logo" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">245</span>
                                        <span className="c1-r2-t2">Total POD</span>
                                    </div>
                                </div>
                                <div className="Contract-div-1-c1">
                                    <div className="contract-c1-r1">
                                        <img src={TotalRevenue} alt="logo" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">5000</span>
                                        <span className="c1-r2-t2">Total revenue</span>
                                    </div>
                                </div>
                                <div className="Contract-div-1-c1">
                                    <div className="contract-c1-r1">
                                        <img src={TotalTonnage} alt="logo" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">40</span>
                                        <span className="c1-r2-t2">Total tonnage</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="Contract-h2-div-2">
                            <div className="contract-h2-r1">
                                <span>Total Bookings </span>
                            </div>
                            <div className="contract-h2-r2">
                                <div className="Contract-div-1-c1">
                                    <div className="contract-c1-r1">
                                        <img src={TotalBooking} alt="none" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">75</span>
                                        <span className="c1-r2-t2">Total Booking</span>
                                    </div>
                                </div>
                                <div className="Contract-div-1-c1">
                                    <div className="contract-c1-r1">
                                        <img src={Accepted} alt="logo" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">50</span>
                                        <span className="c1-r2-t2">Accepted</span>
                                    </div>
                                </div>
                                <div className="Contract-div-1-c1">
                                    <div className="contract-c1-r1">
                                        <img src={Rejected} alt="logo" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">20</span>
                                        <span className="c1-r2-t2">Rejected</span>
                                    </div>
                                </div>                              
                            </div>
                        </div>

                        <div className="Contract-h2-div-3">
                            <div className="contract-h2-r1">
                                <span>Total Penalties </span>
                            </div>

                            <div className="contract-h2-r3">
                                <div className="Contract-div-r3-c1">
                                    <div className="contract-c1-r1">
                                        <img src={Overspeed} alt="none" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">5</span>
                                        <span className="c1-r2-t2">Overspeed</span>
                                    </div>
                                </div>
                                <div className="Contract-div-r3-c1">
                                    <div className="contract-c1-r1">
                                        <img src={OverStoppage} alt="none" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">12</span>
                                        <span className="c1-r2-t2">Over stoppage</span>
                                    </div>
                                </div>
                                <div className="Contract-div-r3-c1">
                                    <div className="contract-c1-r1">
                                        <img src={LateIndenting} alt="none" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">16</span>
                                        <span className="c1-r2-t2">Late intending</span>
                                    </div>
                                </div>
                                <div className="Contract-div-r3-c1">
                                    <div className="contract-c1-r1">
                                        <img src={Latedelivery} alt="none" />
                                    </div>
                                    <div className="contract-c1-r2">
                                        <span className="c1-r2-t1">4</span>
                                        <span className="c1-r2-t2">Late delivery</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </>
    )

}

export default Performance;