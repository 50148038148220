import React, { useEffect, useState } from 'react';
import history from './../../services/history';
import { selectInit } from './../../services/functions'
import { getStaffData, getDriverData, getThirdDriverData } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'
import DatePicker from "react-datepicker";
import moment from 'moment'
import DocViewAddressModal from './Modal/DocViewAddress'
import Select from 'react-select';

const AddDriverStaff = (props) => {
    const dispatch = useDispatch()
    const states = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry"
    ]
    const option = props.location.state.checkState
    const [trStartDate, setTrStartDate] = useState();
    const [btnState, setBtnState] = useState("")
    const [passwordType, setPasswordType] = useState("password")
    const [trueState, setTrue] = useState(false)
    const thirdPartyData = useSelector((state) => state.thirdDriverRed.fetchedData.driverData)
    const loading = useSelector((state) => state.thirdDriverRed.fetchedData.loading)
    const [base, setBase] = useState('')
    const [aadharVisible, setAadharVisible] = useState(false)
    const [driverAadharVisible, setDriverAadharVisible] = useState(false)
    const [driverDetail, setDriverDetail] = useState({
        DLNumber: "",
        userDOB: ""
    })
    const [staffDetail, setStaffDetail] = useState({
        name: '',
        lastName: '',
        // staffId: '',
        staffType: '',
        gender: '',
        userDOB: '',
        mobileNo: '',
        aadhaarNumber: '',
        emailId: '',
        address: '',
        state: '',
        city: '',
        pinCode: '',
        role: '',
        aadhaarNumber: "",
        documents: [],
        dateOfJoining: ""

    })
    const [driverObject, setDriverObject] = useState({
        dlNumber: "",
        userDOB: "",
        name: "",
        endorseDate: "",
        endorseNumber: "",
        fatherName: "",
        issuedDate: "",
        expiryDate: "",
        licenseType: "",
        vaccinationStatus: "",
        mobileNo: "",
        aadhaarNumber: "",
        documents: [],
        addresses: "",
        password: "",
        confirmPassword: "",
        emailId: "a"
    })
    const [driverInputData, setDriverInputData] = useState({
        password: "",
        confirmPassword: "",
        emailId: "a",
        mobileNo: "",
        aadhaarNumber: ""
    })
    const handleStaffData = (e) => {
        setStaffDetail({ ...staffDetail, [e.target.name]: e.target.value })
    }
    const addStaff = () => {
        dispatch(getStaffData(staffDetail))
    }
    const handleDriverData = (e) => {
        setDriverDetail({ ...driverDetail, [e.target.name]: e.target.value })
    }
    const hanldeDriverInput = (e) => {
        setDriverObject({ ...driverObject, [e.target.name]: e.target.value })
    }
    const addDriver = () => {
        let tempArr = driverObject
        tempArr.dlNumber = thirdPartyData.dlNumber
        tempArr.userDOB = thirdPartyData.userDOB
        tempArr.name = thirdPartyData.name
        tempArr.endorseDate = thirdPartyData.endorseDate
        tempArr.endorseNumber = thirdPartyData.endorseNumber
        tempArr.fatherName = thirdPartyData.fatherName
        tempArr.issuedDate = thirdPartyData.issuedDate
        tempArr.expiryDate = thirdPartyData.expiryDate
        tempArr.licenseType = thirdPartyData.licenseType
        tempArr.vaccinationStatus = thirdPartyData.vaccinationStatus
        // tempArr.mobileNo= thirdPartyData.mobileNo
        // tempArr.aadhaarNumber= thirdPartyData.aadhaarNumber
        tempArr.documents = driverObject.documents
        tempArr.addresses = thirdPartyData.addresses
        setDriverObject(tempArr)
        const concate = { ...driverInputData, ...driverObject }
        dispatch(getDriverData(concate))
    }
    const togglePassType = () => {
        if (passwordType == "password") {
            setPasswordType("text")
        }
        else {
            setPasswordType("password")
        }
    }
    const _goBack = () => {
        history.push("/dashboard/workforce")
    }
    const slideFun = (item) => {
        if (item === 'driver') {
            setBtnState('driver')
            $(".driverBtn").addClass("activeWFBtn")
            $(".staffBtn").removeClass("activeWFBtn")
        }
        else if (item === 'staff') {
            setBtnState('staff')
            $(".staffBtn").addClass("activeWFBtn")
            $(".driverBtn").removeClass("activeWFBtn")
        }
    }
    const toggleElem = () => {
        if (driverDetail.DLNumber !== '') {
            driverDetail.userDOB = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(trStartDate)
            dispatch(getThirdDriverData(driverDetail))
            if (loading === false) {
                if ($(".driverrow2").hasClass("open")) {
                    $(".driverrow2").removeClass("open");
                    $(".driverrow2").addClass("hideMe");
                }
            }
            else {
                $(".driverrow2").addClass("open");
                $(".driverrow2").removeClass("hideMe");

            }
        }
        else {
            window.$.fn.show_notification({ message: 'DL Number cannot be empty..', title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }
    const uploadFrontImage = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        if (item === 'driver') {
            // Driver
            setDriverAadharVisible(true)
            driverObject.documents.push(base64)
        }
        else {
            // staff
            setAadharVisible(true)
            staffDetail.documents.push(base64)
        }
    }
    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }

                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _sendData = (item) => {
        if(item === 'driver'){
            var newArray = driverObject
            setBase(newArray.documents)
        }
        else{
            var newArray = staffDetail
            setBase(newArray.documents)
        }
    }
    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white',
            width: '100%',

        }),
        control: (styles, state) => {
            return {
                ...styles,
                backgroundColor: "transparent",


                boxShadow:
                    state.isFocused
                        ? "0 0 3px #28a745"
                        : state.isFocused
                            ? "0 0 3px #dc3545"
                            : "none",
                cursor: "pointer",
                ":focus": {
                    ...styles[":focus"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                ":active": {
                    ...styles[":active"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },

                ":hover": {
                    ...styles[":hover"],
                    // borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                height: 35,
                minHeight: 35,
            };
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
            ...styles,
            background: isFocused ? '#ff7200' : isSelected ? '#fff' : undefined,
            color: isFocused ? '#fff' : 'black',
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? "var(--clr--accent)"
                        : undefined
                    : undefined,
            },
            zIndex: 1
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '35px',
            margin: '-8px 0 0 0'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '35px',
        }),


    }
    const userOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' }
    ];
    const _handleUserChange = (item) => {
        let temp = { ...staffDetail }
        temp.gender = item.label
        setStaffDetail(temp)
    }

    useEffect(() => {
        setTimeout(() => {
            selectInit()
        }, 100);
        setBtnState(option)

        if (btnState === 'driver') {
            setBtnState('driver')
            $(".driverBtn").addClass("activeWFBtn")
            $(".staffBtn").removeClass("activeWFBtn")
        }
        else if (btnState === 'staff') {
            setBtnState('staff')
            $(".staffBtn").addClass("activeWFBtn")
            $(".driverBtn").removeClass("activeWFBtn")
        }
        // if (driverDetail.confirmPassword.length > 0 && driverDetail.password === driverDetail.confirmPassword) {
        //     setTrue(true)
        // }
        // else {
        //     setTrue(false)
        // }
    })

    return (
        <div className="contentpanel">
            <div className="cust-container">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-4'>
                        <h1 className="title">
                            <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                                <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                            </button>
                            {btnState === 'driver' ? 'Add Driver' : 'Add Staff'}
                        </h1>
                    </div>
                    <div className='cust-col-2'>
                        <div className='sliderBtn'>
                            <button className='driverBtn' onClick={() => slideFun('driver')}>DRIVER</button>
                            <button className='staffBtn' onClick={() => slideFun('staff')}>STAFF</button>
                        </div>
                    </div>
                    <div className='cust-col-4 flex-jc-end'>
                        {/* <button className='button bg-orange-out mr-2 wd15'>
                            <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd15'>
                            <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                        </button> */}
                    </div>
                </div>
                <hr className='bgg' />
                <div className="cust-row">
                    <div className='cust-col-10'>
                        {btnState === 'staff' ?
                            <div className='staffElem' style={{ justifyContent: 'space-between' }}>
                                <div className='staffElemRow' style={{ justifyContent: 'space-between' }}>
                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>First Name</span>
                                        <input className='staffInp' type='text' name="name" value={staffDetail.name} onChange={(e) => handleStaffData(e)} placeholder="Enter First Name" />
                                    </div>
                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>Last Name</span>
                                        <input className='staffInp' type='text' name="lastName" value={staffDetail.lastName} onChange={(e) => handleStaffData(e)} placeholder="Enter Last Name" />
                                    </div>
                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>Staff Type</span>
                                        <input className='staffInp' type='text' name="staffType" value={staffDetail.staffType} onChange={(e) => handleStaffData(e)} placeholder="Enter Staff Type" />
                                    </div>
                                </div>
                                <div className='staffElemRow' style={{ justifyContent: 'space-between' }}>
                                    <div className='staffElemColl'>
                                        <span className='staffSpan'>Gender</span>
                                        <Select
                                            onChange={(value) => _handleUserChange(value)}
                                            options={userOptions}
                                            styles={colourStyles}
                                        // value={{ label: updateUser.UserType, value: updateUser.UserType }}
                                        />
                                    </div>
                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>Date of Birth</span>
                                        <input className='staffInp' type='date' placeholder="18-03-2001" name="userDOB" value={staffDetail.UserDOB} onChange={(e) => handleStaffData(e)} />
                                        {/* <i className="fa fa-calendar user-calendaNew" ></i> */}
                                    </div>
                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>Mobile Number</span>
                                        <input className='staffInp' type='number' placeholder="Enter Mobile Number" name="mobileNo" value={staffDetail.mobileNo} onChange={(e) => handleStaffData(e)} />
                                        {staffDetail.mobileNo.length === 10 ?
                                            <i className="fa fa-check validationCheckn1"></i>
                                            :
                                            staffDetail.mobileNo.length > 0 ?
                                                <i className="fa fa-close validationCheckWrongn1"></i>
                                                : ''
                                        }
                                    </div>
                                </div>
                                <div className='staffElemRow' style={{ justifyContent: 'space-between' }}>

                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>Aadhar Number</span>
                                        <input className='staffInp' type='text' placeholder="Enter Aadhar Number" name="aadhaarNumber" value={staffDetail.aadhaarNumber} onChange={(e) => handleStaffData(e)} />

                                        <div className='addUploadItemHub makeAbs'>
                                            <i className="fa fa-upload uploadIconn" ></i>
                                            <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'staff')} type="file" />
                                            {aadharVisible === true ?
                                                <i className="fa fa-eye myVisibleEyeNew" onClick={() => { _handleClick('DocViewAddressModal'); _sendData('staff') }}></i>
                                                : ''}
                                        </div>
                                        {/* 
                                        {staffDetail.aadhaarNumber.length === 12 ?
                                            <i className="fa fa-check validationCheckn1"></i>
                                            :
                                            staffDetail.aadhaarNumber.length > 0 ?
                                                <i className="fa fa-close validationCheckWrongn1"></i>
                                                : ''
                                        } */}
                                    </div>
                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>Email ID</span>
                                        <input className='staffInp' type='email' placeholder="Enter Email Id" name="emailId" value={staffDetail.emailId} onChange={(e) => handleStaffData(e)} />
                                        {staffDetail.emailId.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ?
                                            <i className="fa fa-check validationCheckn1"></i>
                                            :
                                            staffDetail.emailId.length > 0 ?
                                                <i className="fa fa-close validationCheckWrongn1"></i>
                                                : ''
                                        }
                                    </div>
                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>Address</span>
                                        <input className='staffInp' type='text' placeholder="Enter Address" name="address" value={staffDetail.address} onChange={(e) => handleStaffData(e)} />
                                    </div>

                                </div>
                                <div className='staffElemRow' style={{ justifyContent: 'space-between' }}>


                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>State</span>
                                        <select className="form-controct2-single" name='state' value={staffDetail.state} onChange={(e) => handleStaffData(e)}>
                                            {states.map((item, index) => {
                                                return (
                                                    <option>{item}</option>
                                                )
                                            })}
                                        </select>
                                        {/* <input className='staffInp' type='text' placeholder="New Delhi" name="state" value={staffDetail.state} onChange={(e) => handleStaffData(e)} /> */}
                                    </div>
                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>City</span>
                                        <input className='staffInp' type='text' placeholder="Enter City Name" name="city" value={staffDetail.city} onChange={(e) => handleStaffData(e)} />
                                    </div>
                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>Pin Code</span>
                                        <input className='staffInp' type='text' placeholder="Enter PinCode" name="pinCode" value={staffDetail.pinCode} onChange={(e) => handleStaffData(e)} />
                                        {staffDetail.pinCode.match(/^(\d{6})$/) ?
                                            <i className="fa fa-check validationCheckn1"></i>
                                            :
                                            staffDetail.pinCode.length > 0 ?
                                                <i className="fa fa-close validationCheckWrongn1"></i>
                                                : ''
                                        }
                                    </div>
                                </div>
                                <div className='staffElemRow' style={{ justifyContent: 'space-between' }}>
                                    <div className='staffElemCol'>
                                        <span className='staffSpan'>Role</span>
                                        <input className='staffInp' type='text' placeholder="Enter role" name="role" value={staffDetail.role} onChange={(e) => handleStaffData(e)} />
                                    </div>
                                    <div className='staffElemCol'>

                                    </div>

                                </div>
                                <div className='staffElemRow'>
                                    <div className='staffElembtnCol'>
                                        <button className='button bti bg-orange m-0'>
                                            <div className='btn_icon'>
                                                <i className='fa fa-plus font-lg'></i>
                                            </div>
                                            <div className='btn_txt font-lg' onClick={() => addStaff()}>Add Staff</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            : ''}
                        {btnState === 'driver' ?
                            <div className='driverElem'>
                                <div className='driverrow1'>
                                    <div className='dlNum1'>
                                        <span className='staffSpan'>Driving License No.</span>
                                    </div>
                                    <div className='dlNum2' style={{ marginRight: "20px" }}>
                                        <input name="DLNumber" value={driverDetail.DLNumber} onChange={(e) => handleDriverData(e)} className='staffInpNew' type='text' placeholder="Enter Driving License No." />
                                    </div>
                                    <div className='dlNum1'>
                                        <span className='staffSpan'>D.O.B</span>
                                    </div>
                                    <div className='dlNum2'>
                                        <input name='userDOB' value={driverDetail.userDOB} onChange={(e) => handleDriverData(e)} className='staffInpNew' type='date' />
                                        {/* <DatePicker
                                            className='datePickerClassNew'
                                            selected={trStartDate}
                                            onChange={(date) => setTrStartDate(date)}
                                            // showTimeSelect={true}
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            placeholder='Enter Truck Reporting time Time'
                                        /> */}
                                    </div>
                                    <div className='dlNum3' onClick={() => toggleElem()}>
                                        <i className="fa fa-search racket" ></i>
                                    </div>
                                </div>

                                <div className='driverrow2'>
                                    <div className='driverThirdOuter'>
                                        <div className='thirdDataR'>
                                            <span className='staffSpan'>Name</span>
                                            <input value={thirdPartyData && thirdPartyData.name ? thirdPartyData.name : ''} className='staffInp' />

                                        </div>
                                        <div className='thirdDataR'>
                                            <span className='staffSpan'>Endorse Date</span>
                                            <input value={thirdPartyData && thirdPartyData.endorseDate ? thirdPartyData.endorseDate : ''} className='staffInp' />

                                        </div>
                                        <div className='thirdDataR'>
                                            <span className='staffSpan'>Endorse Number</span>
                                            <input value={thirdPartyData && thirdPartyData.endorseNumber ? thirdPartyData.endorseNumber : ''} className='staffInp' />

                                        </div>
                                    </div>
                                    <div className='driverThirdOuter brdrBtm'>
                                        <div className='thirdDataR'>
                                            <span className='staffSpan'>Issued Date</span>
                                            <input value={thirdPartyData && thirdPartyData.issuedDate ? moment(thirdPartyData.issuedDate).format('DD/MM/YYYY') : ''} className='staffInp driverInpHgt' />
                                        </div>
                                        { }
                                        <div className='thirdDataR'>
                                            <span className='staffSpan'>Expiry Date</span>
                                            <input value={thirdPartyData && thirdPartyData.expiryDate ? moment(thirdPartyData.expiryDate).format('DD/MM/YYYY') : ''} className='staffInp driverInpHgt' />
                                        </div>
                                        <div className='thirdDataR'>
                                            <span className='staffSpan'>License Type</span>
                                            <input value={thirdPartyData && thirdPartyData.licenseType ? thirdPartyData.licenseType : ''} className='staffInp driverInpHgt' />
                                        </div>
                                    </div>

                                </div>

                                <div className='driverrow2'>
                                    <div className='driverThirdOuter'>
                                        {/* <div className='thirdDataR'>
                                            <span className='staffSpan'>Date of Birth</span>
                                            <input className='staffInp' type='date' name="userDOB" value={driverObject.userDOB} onChange={(e) => handleDriverData(e)} placeholder="Enter DOB" />
                                            <i className="fa fa-calendar user-calendaNewDriver" ></i>
                                        </div> */}
                                        <div className='thirdDataR'>
                                            <span className='staffSpan'>Mobile Number</span>
                                            <input className='staffInp' type='number' name="mobileNo" value={driverObject.mobileNo} onChange={(e) => hanldeDriverInput(e)} placeholder="Enter Mobile Number" />
                                            {driverObject.mobileNo.length === 10 ?
                                                <i className="fa fa-check validationCheckNew"></i>
                                                :
                                                driverObject.mobileNo.length > 0 ?
                                                    <i className="fa fa-close validationCheckWrongNew"></i>
                                                    : ''
                                            }
                                        </div>
                                        <div className='thirdDataR'>
                                            <span className='staffSpan'>Aadhar Number</span>
                                            <input className='staffInp' type='number' name="aadhaarNumber" value={driverObject.aadhaarNumber} onChange={(e) => hanldeDriverInput(e)} placeholder="Enter Aadhar Number" />
                                            <div className='addUploadItemHub makeAbsDriver'>
                                                <i className="fa fa-upload uploadIconn" ></i>
                                                <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'driver')} type="file" />
                                                {driverAadharVisible === true ?
                                                    <i className="fa fa-eye myVisibleEyeNew" onClick={() => { _handleClick('DocViewAddressModal'); _sendData('driver') }}></i>
                                                    : ''}
                                            </div>
                                            {/* {driverObject.aadhaarNumber.length === 12 ?
                                                <i className="fa fa-check validationCheckNew"></i>
                                                :
                                                driverObject.aadhaarNumber.length > 0 ?
                                                    <i className="fa fa-close validationCheckWrongNew"></i>
                                                    : ''
                                            } */}
                                        </div>
                                        <div className='thirdDataR'>
                                            <span className='staffSpan'>Password</span>
                                            <input name='password' value={driverObject.password} onChange={(e) => hanldeDriverInput(e)} className='staffInp driverInpHgt' placeholder='Enter your password' type={passwordType} />
                                            {passwordType === 'password' ?
                                                <i className="fa fa-eye-slash validationCheckNew" onClick={togglePassType}></i>
                                                :
                                                <i onClick={togglePassType} className="fa fa-eye validationCheckNew" ></i>
                                            }
                                        </div>
                                    </div>
                                    <div className='driverThirdOuter brdrBtm'>
                                        <div className='thirdDataR'>
                                            <span className='staffSpan'>Confirm Password</span>
                                            <input name='confirmPassword' type="password" value={driverObject.confirmPassword} onChange={(e) => hanldeDriverInput(e)} placeholder='confirm your password' className='staffInp driverInpHgt' />
                                            {trueState === true ?
                                                <i className="fa fa-check validationCheckNew"></i>
                                                :
                                                ''
                                            }
                                        </div>
                                        <div className='thirdDataR'>

                                        </div>
                                    </div>
                                    <div className='staffElemRow'>
                                        <div className='staffElembtnCol algnCent'>
                                            <button className='button bti bg-orange '>
                                                <div className='btn_icon'>
                                                    <i className='fa fa-plus font-lg'></i>
                                                </div>
                                                <div className='btn_txt font-lg' onClick={addDriver}>Add Driver</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}

                    </div>
                </div>

            </div>
            <DocViewAddressModal imageData={base} />
        </div>

    );
};

export default AddDriverStaff;