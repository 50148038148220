import { useEffect, useState } from "react"
import axios from 'axios'

const ChangePassword = (props) => {
    const [passwordType1, setPasswordType1] = useState("password")
    const [passwordType2, setPasswordType2] = useState("password")
    const [confirmpass, setConfrimpass] = useState("password")
    const [matchStatus, setMatchStatus] = useState(false)
    const [passState, setPassState] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    })
    const togglePassType1 = () => {
        if (passwordType1 == "password") {
            setPasswordType1("text")
        }
        else {
            setPasswordType1("password")
        }
    }
    const togglePassType2 = () => {
        if (passwordType2 == "password") {
            setPasswordType2("text")
        }
        else {
            setPasswordType2("password")
        }

    }
    const togglePassType3 = () => {
        if (confirmpass == "password") {
            setConfrimpass("text")
        }
        else {
            setConfrimpass("password")
        }

    }
    const _handleChange = (e) => {
        setPassState({ ...passState, [e.target.name]: e.target.value })
    }
    const changePass = () => {
        if (passState.newPassword.match(/[a-z]/g) && passState.newPassword.match(/[A-Z]/g) && passState.newPassword.length > 8 && passState.newPassword.match(/[0-9]/g) && passState.newPassword.match(/[!@#$%^&*]/g)) {
            const token = localStorage.getItem('secretkey')
            const config = {
                headers: { Authorization: token }
            };
            axios.post('https://www.betaenterprise.lynkit.io/api/user/change-password', { ...passState }, { headers: config.headers })
                .then(response => {
                    console.log('change pass response', response);
                    const message = response.data.message
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                })
                .catch(error => {
                    console.log('err', error);
                })
        }
        else {
            window.$.fn.show_notification({ message: "Password should have 1 special character ,1 uppercase , 1 lowercase , 1 number and minimum 8 characters ", title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
    }
    useEffect(()=>{
        if (passState.newPassword.length !== 0 && passState.confirmNewPassword.length !== 0 && passState.newPassword === passState.confirmNewPassword) {
            setMatchStatus(true)
        }
        else {
            setMatchStatus(false)
        }
    },[passState])
    return (
        <section id="changePasswordModal" className="custom-modal" style={{ width: "40%" }}>
            <div className="custom-modal-header">
                <h1>Change Password</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '80vh' }}>
                <div className="cust-row mb-2">
                    <div className="cust-col-10" style={{ position: 'relative' }}>
                        <label className="black_color font-bold text-left wdt100">Old Password</label>
                        <input className="cust-input" name='oldPassword' value={passState.oldPassword} onChange={(e) => _handleChange(e)} type={passwordType1} placeholder="Enter Old Password" />
                        {passwordType1 === 'password' ?
                            <i className="fa fa-eye-slash iconEye assignEye" onClick={togglePassType1}></i>
                            :
                            <i onClick={togglePassType1} className="fa fa-eye iconEye assignEye" ></i>
                        }
                    </div>
                </div>
                <div className="cust-row mb-2">
                    <div className="cust-col-10" style={{ position: 'relative' }}>
                        <label className="black_color font-bold text-left wdt100">New Password</label>
                        <input className="cust-input" name='newPassword' value={passState.newPassword} onChange={(e) => _handleChange(e)} type={passwordType2} placeholder="Enter New Password" />
                        {passwordType2 === 'password' ?
                            <i className="fa fa-eye-slash iconEye assignEye" onClick={togglePassType2}></i>
                            :
                            <i onClick={togglePassType2} className="fa fa-eye iconEye assignEye" ></i>
                        }
                    </div>
                </div>
                <div className="cust-row mb-2">
                    <div className="cust-col-10" style={{ position: 'relative' }}>
                        <label className="black_color font-bold text-left wdt100">Confirm Password</label>
                        <input className="cust-input" name='confirmNewPassword' value={passState.confirmNewPassword} onChange={(e) => _handleChange(e)} type={confirmpass} placeholder="Re-enter New Password" />
                        {confirmpass === 'password' ?
                            <i className="fa fa-eye-slash iconEye confirmassignEye" onClick={togglePassType3}></i>
                            :
                            <i onClick={togglePassType3} className="fa fa-eye iconEye confirmassignEye" ></i>
                        }
                        {matchStatus === false ?
                            <i class="fa fa-times assignEye Wrong" ></i>
                            :
                            <i class="fa fa-check assignEye right" ></i>
                        }
                    </div>
                </div>
                <div className="cust-row">
                    <div className="cust-col-3">

                    </div>
                    <div className="cust-col-5">
                        <button className='button bti bg-grey-out m-0'>
                            <div className='btn_icon'>
                                <i className='fa fa-check'></i>
                            </div>
                            <div className='btn_txt font-md' onClick={changePass}>Change Password</div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChangePassword