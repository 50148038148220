import React, { useEffect, useState } from 'react';
import './../../static/css/userprofile.css'
import AddBankDetailModal from './Modal/AddBankDetail'
import $ from 'jquery'
import user from './../../static/images/dummy-user.png'
import DocViewAddressModal from './Modal/DocViewAddress'
import ImgPreviewModal from './../Dashboard/Modal/ImgPreviewModal'
import { getUserProfile } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { storeUserData } from '../../state/redux/actions/getUsers';
import moment from 'moment'
import axios from 'axios'

const UserProfile = () => {
    const dispatch = useDispatch()
    const usersList = useSelector((state) => state.getUserProfileRed.profileData)
    const loader = useSelector((state) => state.getUserProfileRed.loading)
    const [group, setGroup] = useState('')
    const [profileDetails, setProfileDetails] = useState(
        {
            panBase: '',
            gstBase: '',
            ibaBase: '',
            imgBase: user
        }
    )
    const [updateUser, setUpdateUser] = useState({
        DepartmentName: "",
        DepartmentId: "",
        UserType: "",
        PlantId: "",
        PlantName: "",
        Name: "",
        MobileNo: "",
        EmailId: "",
        Address: "",
        City: "",
        State: "",
        PinCode: "",
        Password: "",
        ConfirmPassword: "",
        UserType: "",
        UserSubGroup: "Department",
        Status: "",
        UserId: "",
        Permissions: {
            CreateUser: true,
            EditUser: true,
            ViewUser: true,
            EditHub: true,
            DisableUser: true,
            ViewListing: true,
            AddPlant: true,
            ViewPlant: true,
            AddStaff: true,
            DeleteStaff: true,
            ViewStaff: true,
            EditStaff: true,
            AddHub: true,
            DeleteHub: true,
            ViewHub: true,
            ViewInvoice: true,
            CreateInvoice: true,
            EditInvoice: true,
            AddVehicle: true,
            ViewVehicle: true,
            EditVehicle: true,
            AddDriver: true,
            ViewDriver: true,
            EditDriver: true,
            AddAddress: true,
            EditAddress: true,
            ViewAddress: true,
            AddContract: true,
            ViewContract: true,
            ViewProfile: true,
            EditProfile: true,
            ChangePassword: true,
            ViewSettingPanel: true,
            AddSettingsInSettingPanel: true,
            EditSettingPanel: true,
            ViewDashboard: true,
            DownloadReport: true,
            VehicleAllocation: true,
            ViewBookingDetails: true,
            RequestForGateInInspecPassed: true,
            RequestForGateInInspecFailed: true,
            WeighmentOfVehicle: true,
            VehicleInspection: true,
            DriverInspection: true,
            GpsDeviceInspection: true,
            AllowVehicleForGateIn: true,
            AddClauseForInspection: true,
            WaivePenalty: true,
            RejectDriver: true,
            AddCustomerVehicle: true,
            AddTransporter: true,
            ViewTransporter: true,
            ApproveTransporter: true,
            RejectTransporter: true,
            AllocateSalesOrderCustVehicle: true,
            UploadDocuments: true,
            BookingViewDetails: true,
            PickupDropOffLocation: true,
            AddDepartment: true,
            ViewDepartment: true,
            EditOrganisation: true,
            AddBooking: true,
            EditPlant: true,
            EditDepartment: true,
            GeneratePOD: true,
            ArrivedAtDestination: true,
            Viewlr: true,
            editContract: true
        }
    })
    const [updateOrg, setUpdateOrg] = useState(
        {
            OrgName: "",
            PAN: "",
            PanCopy: "",
            Address: "",
            State: "",
            City: "",
            Country: "India",
            PinCode: "",
            GSTIN: "",
            GstinUpload: "",
            TotalPlantLicense: "",
            TotalTransporterLicense: "",
            LicenseStartDate: "",
            LicenseEndDate: "",
            Status: ""
        }
    )
    const [base, setBase] = useState('')
    const [panShow, setPanShow] = useState(false)
    const [gstShow, setGstShow] = useState(false)
    const [ibaShow, setIbaShow] = useState(false)
    const [userStatus, setuserStatus] = useState(false)
    const [orgStatus, setOrgStatus] = useState(false)
    const [progress, setProgress] = useState(90)

    const _openModal = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const uploadFrontImage = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        const localArray = profileDetails
        if (item === 'pan') {
            localArray.panBase = base64
            setPanShow(true)
            setBase(base64)
            if (orgStatus === true) {
                updateOrg.PanCopy = base64
            }
        }
        else if (item === 'gst') {
            localArray.gstBase = base64
            setGstShow(true)
            setBase(base64)
            if (orgStatus === true) {
                updateOrg.GstinUpload = base64
            }
        }
        else if (item === 'iba') {
            localArray.ibaBase = base64
            setIbaShow(true)
            setBase(base64)
        }
        else if (item === 'img') {
            localArray.imgBase = base64
            setBase(base64)
        }
    }
    function _handleClick(id) {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible");
        $(`#${id}`).addClass("custom-modal-is-visible");
    }
    const setUserEdit = () => {
        if ($(".ltInp1").hasClass("nobd")) {
            $(".ltInp1").removeClass("nobd");
            $(".ltInp1").addClass("bd");
            setuserStatus(true)
            let dummy = { ...updateUser }
            dummy.DepartmentName = usersList[0].DepartmentName
            dummy.DepartmentId = usersList[0].DepartmentId
            dummy.UserType = usersList[0].UserType
            dummy.PlantId = usersList[0].PlantId
            dummy.PlantName = usersList[0].PlantName
            dummy.Name = usersList[0].Name
            dummy.MobileNo = usersList[0].MobileNo
            dummy.EmailId = usersList[0].EmailId
            dummy.Address = usersList[0].Address
            dummy.City = usersList[0].City
            dummy.State = usersList[0].State
            dummy.PinCode = usersList[0].PinCode
            dummy.Password = usersList[0].PasswordHash
            dummy.ConfirmPassword = usersList[0].PasswordHash
            dummy.Status = usersList[0].organisation.Status
            dummy.UserSubGroup = "Department"
            dummy.Permissions = usersList[0].Permissions
            dummy.UserId = usersList[0]._id
            setUpdateUser(dummy)
        }
        else {
            $(".ltInp1").removeClass("bd");
            $(".ltInp1").addClass("nobd");
            setuserStatus(false)
            setUpdateUser({
                DepartmentName: "",
                DepartmentId: "",
                UserType: "",
                PlantId: "",
                PlantName: "",
                Name: "",
                MobileNo: "",
                EmailId: "",
                Address: "",
                City: "",
                State: "",
                PinCode: "",
                Password: "",
                ConfirmPassword: "",
                UserType: "",
                UserSubGroup: "Department",
                Status: "",
                UserId: ""
            })
        }
    }
    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }

                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const _handleChange = (e, item) => {
        setUpdateUser({ ...updateUser, [e.target.name]: e.target.value })
    }
    const updateUserFun = () => {
        const finalObj = {
            name: updateUser.Name,
            mobileNo: updateUser.MobileNo,
            emailId: updateUser.EmailId,
            plantId: updateUser.PlantId,
            plantName: updateUser.PlantName,
            address: updateUser.Address,
            city: updateUser.City,
            state: updateUser.State,
            pinCode: updateUser.Name,
            departmentName: updateUser.DepartmentName,
            departmentId: updateUser.DepartmentId,
            userType: updateUser.UserType,
            status: updateUser.Status,
            permissions: updateUser.Permissions,
            userId: updateUser.UserId

        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/user/UpdateUser', { ...finalObj }, { headers: config.headers })
            .then(response => {
                const err = response.data.error
                const message = response.data.message
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                if (err === false) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log('err', error)
            })
    }
    const orgStatusFun = () => {
        if ($(".ltInp").hasClass("nobd")) {
            $(".ltInp").removeClass("nobd");
            $(".ltInp").addClass("bd");
            setOrgStatus(true)
            let dummy = { ...updateOrg }
            dummy.OrgName = usersList[0].organisation.OrgName
            dummy.PAN = usersList[0].organisation.PAN
            dummy.Address = usersList[0].organisation.Address
            dummy.State = usersList[0].State
            dummy.City = usersList[0].City
            dummy.Country = usersList[0].Country
            dummy.PinCode = usersList[0].PinCode
            dummy.GSTIN = usersList[0].organisation.GSTIN
            dummy.GstinUpload = usersList[0].organisation.GstinUpload
            dummy.TotalPlantLicense = usersList[0].organisation.TotalPlantLicense
            dummy.TotalTransporterLicense = usersList[0].organisation.TotalTransporterLicense
            dummy.LicenseStartDate = usersList[0].organisation.LicenseStartDate
            dummy.LicenseEndDate = usersList[0].organisation.LicenseEndDate
            dummy.Status = usersList[0].organisation.Status
            setUpdateOrg(dummy)
        }
        else {
            $(".ltInp").removeClass("bd");
            $(".ltInp").addClass("nobd");
            setOrgStatus(false)
            setUpdateOrg({
                OrgName: "",
                PAN: "",
                PanCopy: "",
                Address: "",
                State: "",
                City: "",
                Country: "India",
                PinCode: "",
                GSTIN: "",
                GstinUpload: "",
                TotalPlantLicense: "",
                TotalTransporterLicense: "",
                LicenseStartDate: "",
                LicenseEndDate: "",
                Status: ""
            })
        }
    }
    const _handleOrgChange = (e, item) => {
        if (item === 'sd') {
            let dummy = { ...updateOrg }
            dummy.LicenseStartDate = e.target.value
            setUpdateOrg(dummy)
        }
        else if (item === 'ld') {
            let dummy = { ...updateOrg }
            dummy.LicenseEndDate = e.target.value
            setUpdateOrg(dummy)
        }
        setUpdateOrg({ ...updateOrg, [e.target.name]: e.target.value })
    }
    const updateOrganisation = () => {
        const finalObj = {
            orgName: updateOrg.OrgName,
            pan: updateOrg.PAN,
            panCopy: updateOrg.PanCopy,
            address: updateOrg.Address,
            state: updateOrg.State,
            city: updateOrg.City,
            country: "India",
            pinCode: updateOrg.PinCode,
            gstin: updateOrg.GSTIN,
            gstinUpload: updateOrg.GstinUpload,
            TotalPlantLicense: updateOrg.TotalPlantLicense,
            TotalTransporterLicense: updateOrg.TotalTransporterLicense,
            LicenseStartDate: updateOrg.LicenseStartDate,
            LicenseEndDate: updateOrg.LicenseEndDate,
            status: updateOrg.Status,

        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/user/UpdateOrganisation', { ...finalObj }, { headers: config.headers })
            .then(response => {
                const err = response.data.error
                const message = response.data.message
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                if (err === false) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log('err', error)
            })
    }
    const _handleClick1 = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const SharedData = (item) => {
        if (item === 'gst') {
            let key = usersList[0] && usersList[0].organisation && usersList[0].organisation.GstinUpload && usersList[0].organisation.GstinUpload.file_key ? usersList[0].organisation.GstinUpload.file_key : ''
            const token = localStorage.getItem('secretkey')
            const config = {
                headers: { Authorization: token }      
            };
            axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                .then(response => {
                    const storeBase = response.data.file
                    setBase(storeBase)
                })
                .catch(error => {
                    // dispatch(showError(err))
                })
        }
        else if (item === 'pan') {
            let key = usersList[0] && usersList[0].organisation && usersList[0].organisation.PanCopy && usersList[0].organisation.PanCopy.file_key ? usersList[0].organisation.PanCopy.file_key : ''
            console.log('key', key)
            const token = localStorage.getItem('secretkey')
            const config = {
                headers: { Authorization: token }
            };
            axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                .then(response => {
                    const storeBase = response.data.file
                    setBase(storeBase)
                })
                .catch(error => {
                    // dispatch(showError(err))
                })
        }

    }
    useEffect(() => {
        const user = localStorage.getItem('userGroup')
        setGroup(user)
        dispatch(getUserProfile())
    }, [dispatch])

    return (
        <>
            <div className="profileParent">
                <div className='profElem'>
                    <h4 className="title">My Profile</h4>
                    <button onClick={() => { _openModal('addBankDetailModal') }}>Add Bank Details</button>
                    {/* <div className='progress-div'>
                        <label>Update your Profile :</label>
                        <progress id="file" value={progress} max="100"></progress> <span id="value"> {progress} %</span>
                    </div> */}

                </div>
                <hr />
                {usersList !== undefined ?
                    loader === true ?
                        <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                            <i className="fa fa-spinner fa-spin loadingSpinner font-xxl  "></i>
                        </div>
                        :
                        <div className="profile-div">
                            <div className='left-profile'>
                                <div className='profile-picture'>
                                    <div className='img-container' >
                                        <div className='img-element'>
                                            <i className="fa fa-pencil-square-o edit_img"> </i>
                                            <input className='file_upload' type='file' onChange={(e) => uploadFrontImage(e, 'img')} />
                                        </div>
                                        <img className='imageDim' src={profileDetails.imgBase} alt='' />
                                    </div>
                                </div>

                                <div className='profile-info-1'>
                                    <div className='lt'>
                                        <div className='ltTop'>
                                            <span className='ltAbs'>User Details</span>
                                            <button className='profileEdit' onClick={setUserEdit}>Edit</button>
                                        </div>
                                        <div className='ltBot' style={{ height: '100px' }}>
                                            <div className='ltRow'>
                                                <div className='lt40'>
                                                    <span className='ltspan'>Contact Person :</span>
                                                </div>
                                                <div className='lt60'>
                                                    <input className='ltInp1 nobd'
                                                        disabled={userStatus === true ? false : true}
                                                        name={userStatus === true ? 'Name' : 'Name'}
                                                        type='text'
                                                        value={userStatus !== true ? usersList && usersList[0] && usersList[0].Name ? usersList[0].Name : '-' : updateUser.Name}
                                                        onChange={(e) => _handleChange(e, 'email')}
                                                    />
                                                </div>
                                            </div>
                                            <div className='ltRow'>
                                                <div className='lt40'>
                                                    <span className='ltspan'>Email :</span>
                                                </div>
                                                <div className='lt60'>
                                                    <input className='ltInp1 nobd'
                                                        disabled={userStatus === true ? false : true}
                                                        name={userStatus === true ? 'EmailId' : 'EmailId'}
                                                        type='text'
                                                        value={userStatus !== true ? usersList && usersList[0].EmailId ? usersList[0].EmailId : "-" : updateUser.EmailId}
                                                        onChange={(e) => _handleChange(e, 'email')}
                                                    />
                                                </div>
                                            </div>
                                            <div className='ltRow'>
                                                <div className='lt40'>
                                                    <span className='ltspan'>Mobile Number :</span>
                                                </div>
                                                <div className='lt60'>
                                                    <input className='ltInp1 nobd'
                                                        disabled={userStatus === true ? false : true}
                                                        name={userStatus === true ? 'MobileNo' : 'mobileNo'}
                                                        type='number'
                                                        value={userStatus !== true ? usersList && usersList[0] && usersList[0].MobileNo ? usersList[0].MobileNo : '-' : updateUser.MobileNo}
                                                        onChange={(e) => _handleChange(e, 'mobile')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {userStatus === true ?
                                            <div className='profUpdate'>
                                                <button className='profBtn' onClick={() => updateUserFun()}>Update</button>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                            </div>

                            <div className='right-profile'>
                                <div className='ltTop'>
                                    <span className='ltAbs'>Organistaion Details</span>
                                    <button className='profileEdit' onClick={orgStatusFun}>Edit</button>
                                </div>
                                <div className='ltBot'>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>Company Name :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp nobd bdFull'
                                                type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'OrgName' : 'OrgName'}
                                                value={orgStatus !== true ? usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.OrgName ? usersList[0].organisation.OrgName : "-" : updateOrg.OrgName}
                                                onChange={(e) => _handleOrgChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>Role :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp nobd bdFull'
                                                type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'UserSubGroup' : 'UserSubGroup'}
                                                value={orgStatus !== true ? usersList[0].UserSubGroup : updateOrg.UserSubGroup}
                                                onChange={(e) => _handleOrgChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>Vendor PAN :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp nobd bdFull' type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'PAN' : 'PAN'}
                                                value={orgStatus !== true ? usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.PAN ? usersList[0].organisation.PAN : '-' : updateOrg.PAN}
                                                onChange={(e) => _handleOrgChange(e)}
                                            />
                                            {orgStatus === true ?
                                                <div className='addUploadItem removeBrdr ' style={{ paddingLeft: '10px' }}>
                                                    <i className="fa fa-upload uploadIn" ></i>

                                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'pan')} type="file" />
                                                    {/* {panShow === true ?
                                                    <i className="fa fa-eye myVisibleEyeBlack" onClick={() => { _handleClick('DocViewAddressModal') }}></i>
                                                    : ''} */}

                                                </div>
                                                : ''}
                                            <i className="fa fa-eye userEye black" style={{ position: 'relative', left: '20px' }} onClick={() => { _handleClick1('ImgPreviewModal'); SharedData('pan') }}></i>
                                        </div>
                                    </div>

                                </div>
                                <div className='ltBot'>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>Address :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp nobd bdFull'
                                                type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'Address' : 'Address'}
                                                value={orgStatus !== true ? usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.Address ? usersList[0].organisation.Address : '-' : updateOrg.Address}
                                                onChange={(e) => _handleOrgChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>State :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp nobd bdFull'
                                                type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'State' : 'State'}
                                                value={orgStatus !== true ? usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.State ? usersList[0].organisation.State : '-' : updateOrg.State}
                                                onChange={(e) => _handleOrgChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>City :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp nobd bdFull' type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'City' : 'City'}
                                                value={orgStatus !== true ? usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.City ? usersList[0].organisation.City : '-' : updateOrg.City}
                                                onChange={(e) => _handleOrgChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='ltBot'>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>Pincode :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp nobd bdFull'
                                                type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'PinCode' : 'PinCode'}
                                                value={orgStatus !== true ? usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.PinCode ? usersList[0].organisation.PinCode : '-' : updateOrg.PinCode}
                                                onChange={(e) => _handleOrgChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>Total Plant License :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp nobd bdFull' type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'TotalPlantLicense' : 'TotalPlantLicense'}
                                                value={orgStatus !== true ? usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.TotalPlantLicense ? usersList[0].organisation.TotalPlantLicense : '-' : updateOrg.TotalPlantLicense}
                                                onChange={(e) => _handleOrgChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>Total Transporter License :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp nobd bdFull' type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'TotalTransporterLicense' : 'TotalTransporterLicense'}
                                                value={orgStatus !== true ? usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.TotalTransporterLicense ? usersList[0].organisation.TotalTransporterLicense : '-' : updateOrg.TotalTransporterLicense}
                                                onChange={(e) => _handleOrgChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='ltBot'>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>License Start Date :</span>
                                        </div>
                                        <div className='lt60'>
                                            {orgStatus !== true ?
                                                <input className='ltInp nobd bdFull' type='text'
                                                    disabled={orgStatus === true ? false : true}
                                                    value={usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.LicenseStartDate ? moment(usersList[0].organisation.LicenseStartDate).format('DD/MM/YYYY') : '-'}
                                                    onChange={(e) => _handleOrgChange(e)}
                                                />
                                                :
                                                <span className='driverSpan2 calenderInp alterCalend' style={{ color: '#727376', fontSize: '0.7rem', fontWeight: '300' }}>
                                                    <span>{moment(updateOrg.LicenseStartDate).format('DD/MM/YYYY')}</span>
                                                    <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                        <i className="fa fa-calendar updateCalender" style={{ paddingTop: '4px' }}></i>
                                                        <input className='hideCalend' name='LicenseStartDate' value={updateOrg.LicenseStartDate} onChange={(e) => _handleOrgChange(e, 'sd')} type="date" />
                                                    </div>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>License End Date :</span>
                                        </div>
                                        <div className='lt60'>
                                            {orgStatus !== true ?
                                                <input className='ltInp nobd bdFull' type='text'
                                                    disabled={orgStatus === true ? false : true}
                                                    name={orgStatus === true ? 'LicenseEndDate' : 'LicenseEndDate'}
                                                    value={orgStatus !== true ? usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.LicenseEndDate ? moment(usersList[0].organisation.LicenseEndDate).format('DD/MM/YYYY') : '-' : updateOrg.LicenseEndDate}
                                                    onChange={(e) => _handleOrgChange(e)}
                                                />
                                                :
                                                <span className='driverSpan2 calenderInp alterCalend' style={{ color: '#727376', fontSize: '0.7rem', fontWeight: '300' }}>
                                                    <span>{moment(updateOrg.LicenseEndDate).format('DD/MM/YYYY')}</span>
                                                    <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                        <i className="fa fa-calendar updateCalender" style={{ paddingTop: '4px' }}></i>
                                                        <input className='hideCalend' name='LicenseEndDate' value={updateOrg.LicenseEndDate} onChange={(e) => _handleOrgChange(e, 'ld')} type="date" />
                                                    </div>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>GSTIN :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp nobd bdFull' type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'GSTIN' : 'GSTIN'}
                                                value={orgStatus !== true ? usersList[0].organisation.GSTIN : updateOrg.GSTIN}
                                                onChange={(e) => _handleOrgChange(e)}

                                            />
                                            {orgStatus === true ?
                                                <div className='addUploadItem removeBrdr'>
                                                    <i className="fa fa-upload uploadIn" ></i>
                                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'gst')} type="file" />
                                                    {/* {gstShow === true ?
                                                    <i className="fa fa-eye myVisibleEyeBlack" onClick={() => { _handleClick('DocViewAddressModal') }}></i>
                                                    : ''} */}
                                                </div>
                                                : ''}
                                            <i className="fa fa-eye userEye black" style={{ position: 'relative', left: '20px' }} onClick={() => { _handleClick1('ImgPreviewModal'); SharedData('gst') }}></i>

                                        </div>
                                    </div>
                                </div>
                                {group === 'goodsowner' ? ''
                                    :
                                    <div className='ltBot'>
                                        <div className='ltRow'>
                                            <div className='lt40'>
                                                <span className='ltspan'>Vendor ID :</span>
                                            </div>
                                            <div className='lt60'>
                                                <input className='ltInp nobd bdFull'
                                                    type='text'
                                                    disabled={orgStatus === true ? false : true}
                                                    name={orgStatus === true ? 'IbaCode' : 'IbaCode'}
                                                    value={orgStatus !== true ? usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.IbaCode ? usersList[0].organisation.IbaCode : "-" : updateOrg.IbaCode}
                                                    onChange={(e) => _handleOrgChange(e)}
                                                />

                                            </div>
                                        </div>
                                        <div className='ltRow'>
                                            <div className='lt40'>
                                                <span className='ltspan'>IBA Validity :</span>
                                            </div>
                                            <div className='lt60'>
                                                <input className='ltInp nobd bdFull'
                                                    type='text'
                                                    disabled={orgStatus === true ? false : true}
                                                    name={orgStatus === true ? 'IbaValidity' : 'IbaValidity'}
                                                    value={orgStatus !== true ? usersList && usersList[0].organisation && usersList[0].organisation.IbaValidity ? usersList[0].organisation.IbaValidity : "-" : updateOrg.IbaValidity}
                                                    onChange={(e) => _handleOrgChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='ltRow'>
                                            <div className='lt40'>
                                                <span className='ltspan'>IBA Code :</span>
                                            </div>
                                            <div className='lt60'>
                                                <input className='ltInp nobd bdFull'
                                                    type='text'
                                                    disabled={orgStatus === true ? false : true}
                                                    name={orgStatus === true ? 'IbaCode' : 'IbaCode'}
                                                    value={orgStatus !== true ? usersList && usersList[0] && usersList[0].organisation && usersList[0].organisation.IbaCode ? usersList[0].organisation.IbaCode : "-" : updateOrg.IbaCode}
                                                    onChange={(e) => _handleOrgChange(e)}
                                                />
                                                <div className='addUploadItem removeBrdr'>
                                                    <i className="fa fa-upload uploadIn" ></i>
                                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'iba')} type="file" />
                                                    {ibaShow === true ?
                                                        <i className="fa fa-eye myVisibleEyeBlack" onClick={() => { _handleClick('DocViewAddressModal') }}></i>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='ltBot'>
                                    {/* <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>Registration Date :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp bdFull' type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'AddedOn' : 'AddedOn'}
                                                value={orgStatus !== true ? usersList[0] && usersList[0].organisation && usersList[0].organisation.AddedOn ? moment(usersList[0].organisation.AddedOn).format('DD/MM/YYYY') : "-" : updateOrg.AddedOn}
                                                onChange={(e) => _handleOrgChange(e)}
                                            />
                                        </div>
                                    </div> */}
                                    <div className='ltRow'>
                                        <div className='lt40'>
                                            <span className='ltspan'>Account Status :</span>
                                        </div>
                                        <div className='lt60'>
                                            <input className='ltInp nobd bdFull'
                                                type='text'
                                                disabled={orgStatus === true ? false : true}
                                                name={orgStatus === true ? 'Status' : 'Status'}
                                                value={orgStatus !== true ? usersList[0] && usersList[0].organisation && usersList[0].organisation.Status ? usersList[0].organisation.Status : "-" : updateOrg.Status}
                                                onChange={(e) => _handleOrgChange(e)} />
                                        </div>
                                    </div>
                                    <div className='ltRow'>

                                    </div>
                                    <div className='ltRow'>

                                    </div>
                                </div>
                                {orgStatus === true ?
                                    <div className='profUpdate'>
                                        <button className='profBtn' onClick={updateOrganisation}>Update</button>
                                    </div>
                                    : ''}


                            </div>
                        </div>
                    :
                    <span className='noDataClass'>No Data Found</span>
                }
            </div>
            <AddBankDetailModal closeModal={_closeModal} />
            <DocViewAddressModal closeModal={_closeModal} imageData={base} />
            <ImgPreviewModal base={base} />
        </>
    );
};

export default UserProfile;