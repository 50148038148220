import React, { useEffect, useState } from 'react'
import history from '../../services/history'
import ConfirmBookingModal from './../Dashboard/Modal/ConfirmBooking'
import $ from 'jquery'
import gatedIn from './../../static/images/icon/buttons/gatedIn.svg'
import moment from 'moment'
import BookDetail from './../Dashboard/Modal/BookDetail'

const BookingDetail = (props) => {
    const [imgItem, setItem] = useState('')
    const [getUrlParam, setUrlParam] = useState('')
    const allData = props.location.state
    const bookingDetail = props.location.state.data

    useEffect(() => {
        setUrlParam(allData.url_param)
    }, [getUrlParam])

    const _goBack = () => {
        history.goBack()
    }
    const confirmBooking = () => {
        window.$.fn.show_notification({ message: "confirmation is required for acceptance of booking", title: 'Are you sure!!', type: 'confirm', autohide: false }, (result) => {
            if (result.type === "confirm") {
                this._closeModal()
            }
        })
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const cancelBooking = () => {
        window.$.fn.show_notification({ message: "Cancel your booking from your OTM Platform.", title: 'Are you sure!!', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
            if (result.type === 'confirm') {
            }
        })
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const toggleRepport = () => {
        if ($(".reportElem").hasClass("close")) {
            $(".reportElem").removeClass("close");
            $(".reportElem").addClass("open");
        } else {
            $(".reportElem").removeClass("open");
            $(".reportElem").addClass("close");

        }
    }
    const SharedData = (item) => {
        setItem(item)
    }

    return (
        <div className="contentpanel">
            <div className="cust-container">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-4'>
                        <h1 className="title">
                            <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                                <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                            </button>
                            Booking Detail
                        </h1>
                    </div>
                    <div className='cust-col-6 flex-jc-end'>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                            <div className="titleBoxnew">
                                <span>Edit </span>
                            </div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                            <div className="titleBoxnew">
                                <span>Download </span>
                            </div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-upload'></i></div>
                            <div className="titleBoxnew">
                                <span>Upload </span>
                            </div>
                        </button>
                    </div>
                </div>
                <hr className='bgg' />
                <div className="cust-row">
                    <div className='cust-col-10'>
                        {getUrlParam === 'truck-reporting' || getUrlParam === 'inspection' || getUrlParam === 'gate-in-allowed' || getUrlParam === 'gatein' || getUrlParam === 'weighment-in' || getUrlParam === 'weighment-out' ?
                            <div className="vd_cont post-15" style={{ marginBottom: '10px' }}>
                                <span className='overflowingText'>TRUCK REPORTING</span>
                                <div className="vd_cont_tbl brtd">
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Shipment GID
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            <span className='font-weight-bold'>{bookingDetail.ShipmentGID}</span>
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Reference Ship GID
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.ReferenceShipGID}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Transport Mode GID
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TransportModeGID}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Source Location GID
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.SourceLocationGID}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Destination Location GID
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.DestinationLocationGID}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Planning Type
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.PlanningType}
                                        </div>
                                    </div>

                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Created By
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            -
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Creation Day
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {moment(bookingDetail.AddedOn).format('DD/MM/YYYY')}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Last Update On
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            -
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Last Updated By
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            -
                                        </div>
                                    </div>
                                </div>
                                <div className="vd_cont_tbl no_btr">
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Status
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            <span className='font-weight-bold'>Truck Allocation Pending</span>
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Truck Reporting Time
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckReportingTime !== '' ? moment(bookingDetail.TruckReportingTime).format('h:mm:ss a') : "-"}
                                        </div>

                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Service Provider GID
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.ServiceProviderGID}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Ship Quantity Accepted
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.ShipQuantityAccepted}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Total Weight
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TotalWeight}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Total Weight UOM Code
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TotalWeightUOMCode}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Contract ID
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.ContractId !== '' ? bookingDetail.ContractId : "-"}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            SO Number
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.SoNo !== "" ? bookingDetail.SoNo : "-"}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Goods Type
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.GoodsType}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}
                        {getUrlParam === 'inspection' || getUrlParam === 'gate-in-allowed' || getUrlParam === 'gatein' || getUrlParam === 'weighment-in' || getUrlParam === 'weighment-out' ?
                            <div className="vd_cont post-15" style={{ marginBottom: '10px' }}>
                                <span className='overflowingText'>VEHICLE INSPECTION</span>
                                <div className="vd_cont_tbl brtd">
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Inspection Date Time
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails && bookingDetail.TruckDetails.Inspection[0] && bookingDetail.TruckDetails.Inspection[0].InspectionTime ? moment(bookingDetail.TruckDetails.Inspection[0].InspectionTime).format("DD/MM/YYYY") : '-'} ,
                                            {bookingDetail.TruckDetails && bookingDetail.TruckDetails.Inspection[0] && bookingDetail.TruckDetails.Inspection[0].InspectionTime ? moment(bookingDetail.TruckDetails.Inspection[0].InspectionTime).format("h:mm:ss a") : '-'}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Inspection Status
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails && bookingDetail.TruckDetails.Inspection[0].InspectionStatus && bookingDetail.TruckDetails.Inspection[0] ? bookingDetail.TruckDetails.Inspection[0].InspectionStatus : '-'}
                                            <button className='reportBtn' onClick={toggleRepport}>View Report</button>
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Vehicle No.
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails ? bookingDetail.TruckDetails.VehicleNumber : '-'}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Vehicle Type
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails ? bookingDetail.TruckDetails.VehicleType : '-'}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Driver Name
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails.DriverDetails !== [] ? bookingDetail.TruckDetails.DriverDetails.map((a, b) => { return (a.name) }) : '-'}
                                        </div>
                                    </div>
                                </div>
                                <div className="vd_cont_tbl no_btr">
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            User
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails ? '-' : '-'}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            User Type
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails ? '-' : '-'}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Gate Number
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails ? '5' : '5'}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            DL Number
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails.DriverDetails !== [] ? bookingDetail.TruckDetails.DriverDetails.map((a, b) => { return (a.dlNumber) }) : '-'}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Driver Mobile
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails.DriverDetails !== [] ? bookingDetail.TruckDetails.DriverDetails.map((a, b) => { return (a.mobileNo) }) : '-'}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            : ''}
                        {getUrlParam === 'inspection' || getUrlParam === 'gate-in-allowed' || getUrlParam === 'gatein' || getUrlParam === 'weighment-in' || getUrlParam === 'weighment-out' ?
                            bookingDetail.TruckDetails && bookingDetail.TruckDetails.Inspection[0] && bookingDetail.TruckDetails.Inspection[0].InspectionReport ?
                                <div className='reportElem close'>
                                    {bookingDetail.TruckDetails && bookingDetail.TruckDetails.Inspection[0] && bookingDetail.TruckDetails.Inspection[0].InspectionReport ? bookingDetail.TruckDetails.Inspection[0].InspectionReport.map((item, index) => {
                                        return (
                                            item.type === "vehicleChecks" ?
                                                <table key={index} className='cctbl inspectDetTb'>
                                                    <thead>
                                                        <tr class="bg_black">
                                                            <div className='insTableHead'>
                                                                <div className='insHeader1'>
                                                                    <span>Vehicle Checks</span>
                                                                </div>
                                                                <div className='insHeader2'>
                                                                    <span>{item.status === 'Fail' ? <span className='inspectFail'>{item.status} </span> : <span className='inspectPass'>{item.status} </span>}</span>
                                                                </div>
                                                            </div>
                                                        </tr>
                                                        <tr class="bg_black">
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <div>
                                                                <div className="btblock">
                                                                    <div className='inpectionInner1'>
                                                                        <div className='inpectionHeader'>
                                                                            <div className='inpectBox11'>
                                                                                <span className='inpectHeadSpan'>SNo.</span>
                                                                            </div>
                                                                            <div className='inpectBox33'>
                                                                                <span className='inpectHeadSpan'>Clauses</span>
                                                                            </div>
                                                                            <div className='inpectBox22'>
                                                                                <span className='inpectHeadSpan'>Status</span>
                                                                            </div>
                                                                            <div className='inpectBox22'>
                                                                                <span className='inpectHeadSpan'>Reason</span>
                                                                            </div>
                                                                            <div className='inpectBox22'>
                                                                                <span className='inpectHeadSpan'>Action</span>
                                                                            </div>
                                                                        </div>
                                                                        {item.checks.map((a, b) => {
                                                                            return (
                                                                                <div className='inspectBody'>
                                                                                    <div className='inpectBox11'>
                                                                                        <span>{b + 1}</span>
                                                                                    </div>
                                                                                    <div className='inpectBox33'>
                                                                                        <span>{a.clause}</span>
                                                                                    </div>
                                                                                    <div className='inpectBox22'>
                                                                                        {a.result === 'Pass' ? <span className='inpectHeadSpan inspectPass'>Pass</span>
                                                                                            :
                                                                                            <span className='inpectHeadSpan inspectFail'>Fail</span>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='inpectBox22'>
                                                                                        <span className='inpectHeadBody fontCCL'>{a.result === 'Fail' ? a.reason : '-'}</span>
                                                                                    </div>
                                                                                    <div className='inpectBox22'>
                                                                                        <span className='inpectHeadBody fontCCL '>
                                                                                            <span className='inpectHeadBody'>
                                                                                                {a.attachedDocuments && a.attachedDocuments.length !== 0 ?
                                                                                                    <i className="fa fa-eye InspectionEye" onClick={() => { _handleClick('BookDeatilModal'); SharedData(a.attachedDocuments[0].documentKey) }}></i>
                                                                                                    : '-'}
                                                                                            </span>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </tr>
                                                    </tbody>
                                                </table>
                                                : ''
                                        )
                                    }) : ''}
                                    {bookingDetail.TruckDetails.Inspection[0] && bookingDetail.TruckDetails.Inspection[0].InspectionReport ? bookingDetail.TruckDetails.Inspection[0].InspectionReport.map((item, index) => {
                                        return (
                                            item.type === "validityChecks" ?
                                                <table key={index} className='cctbl inspectDetTb'>
                                                    <thead>
                                                        <tr class="bg_black">
                                                            <div className='insTableHead'>
                                                                <div className='insHeader1'>
                                                                    <span>Statutory Checks</span>
                                                                </div>
                                                                <div className='insHeader2'>
                                                                    <span>{item.status === 'Fail' ? <span className='inspectFail'>{item.status} </span> : <span className='inspectPass'>{item.status} </span>}</span>
                                                                </div>
                                                            </div>
                                                        </tr>
                                                        <tr class="bg_black">

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <div>
                                                                <div className="btblock">
                                                                    <div className='inpectionInner1'>
                                                                        <div className='inpectionHeader'>
                                                                            <div className='inpectBox11'>
                                                                                <span className='inpectHeadSpan'>SNo.</span>
                                                                            </div>
                                                                            <div className='inpectBox33'>
                                                                                <span className='inpectHeadSpan'>Clauses</span>
                                                                            </div>
                                                                            <div className='inpectBox33'>
                                                                                <span className='inpectHeadSpan'>Expiry</span>
                                                                            </div>
                                                                            <div className='inpectBox33'>
                                                                                <span className='inpectHeadSpan'>Action</span>
                                                                            </div>
                                                                        </div>
                                                                        {item.checks.map((a, b) => {
                                                                            return (
                                                                                <div key={b} className='inspectBody'>
                                                                                    <div className='inpectBox11'>
                                                                                        <span>{b + 1}</span>
                                                                                    </div>
                                                                                    <div className='inpectBox33'>
                                                                                        <span>{a.clause}</span>
                                                                                    </div>
                                                                                    <div className='inpectBox33'>
                                                                                        <span className='inpectHeadBody fontCCL '>
                                                                                            {a.date}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className='inpectBox33'>
                                                                                        <span className='inpectHeadBody'>{a.result === 'Fail' ? <span className='inspectFail'>{a.result} </span> : <span className='inspectPass'>{a.result} </span>}</span>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </tr>
                                                    </tbody>
                                                </table>
                                                : ''
                                        )
                                    }) : ''}
                                    {bookingDetail.TruckDetails.Inspection[0] && bookingDetail.TruckDetails.Inspection[0].InspectionReport ? bookingDetail.TruckDetails.Inspection[0].InspectionReport.map((item, index) => {
                                        return (
                                            item.type === "driverChecks" ?
                                                <table className='cctbl inspectDetTb'>
                                                    <thead>
                                                        <tr class="bg_black">
                                                            <div className='insTableHead'>
                                                                <div className='insHeader1'>
                                                                    <span>Driver Checks</span>
                                                                </div>
                                                                <div className='insHeader2'>
                                                                    <span>{item.status === 'Fail' ? <span className='inspectFail'>{item.status} </span> : <span className='inspectPass'>{item.status} </span>}</span>
                                                                </div>
                                                            </div>
                                                        </tr>
                                                        <tr class="bg_black">

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <div>
                                                                <div className="btblock">
                                                                    <div className='inpectionInner1'>
                                                                        <div className='inpectionHeader'>
                                                                            <div className='inpectBox11'>
                                                                                <span className='inpectHeadSpan'>SNo.</span>
                                                                            </div>
                                                                            <div className='inpectBox33'>
                                                                                <span className='inpectHeadSpan'>Clauses</span>
                                                                            </div>
                                                                            <div className='inpectBox22'>
                                                                                <span className='inpectHeadSpan'>Status</span>
                                                                            </div>
                                                                            <div className='inpectBox22'>
                                                                                <span className='inpectHeadSpan'>Reason</span>
                                                                            </div>
                                                                            <div className='inpectBox22'>
                                                                                <span className='inpectHeadSpan'>Action</span>
                                                                            </div>
                                                                        </div>
                                                                        {item.checks.map((a, b) => {
                                                                            return (
                                                                                <div className='inspectBody'>
                                                                                    <div className='inpectBox11'>
                                                                                        <span>{b + 1}</span>
                                                                                    </div>
                                                                                    <div className='inpectBox33'>
                                                                                        <span>{a.clause}</span>
                                                                                    </div>
                                                                                    <div className='inpectBox22'>
                                                                                        {a.result === 'Pass' ? <span className='inpectHeadSpan inspectPass'>Pass</span>
                                                                                            :
                                                                                            <span className='inpectHeadSpan inspectFail'>Fail</span>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='inpectBox22'>
                                                                                        <span className='inpectHeadBody fontCCL'>{a.result === 'Fail' ? a.reason : '-'}</span>
                                                                                    </div>
                                                                                    <div className='inpectBox22'>
                                                                                        <span className='inpectHeadBody'>
                                                                                        {a.attachedDocuments && a.attachedDocuments.length !== 0 ?
                                                                                        <i className="fa fa-eye InspectionEye" onClick={() => {
                                                                                            _handleClick('BookDeatilModal');

                                                                                            SharedData(a && a.attachedDocuments[0] && a.attachedDocuments[0].documentKey ? a.attachedDocuments[0].documentKey : '')
                                                                                        }}></i>
                                                                                        :'-'}</span>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </tr>
                                                    </tbody>
                                                </table>
                                                : ''
                                        )
                                    }) : ''}
                                </div>
                                :
                                <div className='reportElemNotFound'>
                                    <span>No Report found..</span>
                                </div>
                            : ''}


                        {getUrlParam === 'gate-in-allowed' || getUrlParam === 'gatein' || getUrlParam === 'weighment-in' || getUrlParam === 'weighment-out' ?
                            <div className="vd_cont post-15" style={{ marginBottom: '10px' }}>
                                <span className='overflowingText'>GATE IN REQUEST</span>
                                <div className="vd_cont_tbl brtd">

                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Requested Gate Number
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails ? bookingDetail.TruckDetails.RequestedGateNo : "-"}
                                        </div>
                                    </div>
                                </div>

                                <div className="vd_cont_tbl no_btr">
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Requested Date & Time
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails ? `${moment(bookingDetail.TruckDetails.RequestedDateTime).format("DD/MM/YYYY")},  ${moment(bookingDetail.TruckDetails.RequestedDateTime).format("h:mm:ss a")}` : "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}
                        {getUrlParam === 'gatein' || getUrlParam === 'weighment-in' || getUrlParam === 'weighment-out' ?
                            <div className="vd_cont post-15" style={{ marginBottom: '10px' }}>
                                <span className='overflowingText'>GATED IN</span>
                                <div className="vd_cont_tbl brtd">
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Gate Number
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails ? bookingDetail.TruckDetails.RequestedGateNo : "-"}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            HIL Trip ID
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            -
                                        </div>
                                    </div>
                                </div>
                                <div className="vd_cont_tbl no_btr">
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Gate In Date & Time
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails ? moment(bookingDetail.TruckDetails.GateInTime).format("DD/MM/YYYY") : '-'} ,
                                            {bookingDetail.TruckDetails ? moment(bookingDetail.TruckDetails.GateInTime).format("h:mm:ss a") : '-'}

                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Gate In Allowed By
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail.TruckDetails !== undefined ?
                                                <span>{bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.GateInAllowedBy &&  bookingDetail.TruckDetails.GateInAllowedBy.name !== null ? bookingDetail.TruckDetails.GateInAllowedBy.name : "-"}</span>
                                                : '-'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}
                        {getUrlParam === 'weighment-in' || getUrlParam === 'weighment-out' ?
                            <div className="vd_cont post-15" style={{ marginBottom: '10px' }}>
                                <span className='overflowingText'>WEIGHMENT IN</span>
                                <div className="vd_cont_tbl brtd">
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Tare Weight
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.TareWeight ? bookingDetail.TruckDetails.TareWeight : "-"}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Weighment Number
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.WeighmentNo  ? bookingDetail.TruckDetails.WeighmentNo : "-"}
                                        </div>
                                    </div>
                                </div>
                                <div className="vd_cont_tbl no_btr">
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Weighment Date & Time
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.WeighmentDateTime  ? `${moment(bookingDetail.TruckDetails.WeighmentDateTime).format("DD/MM/YYYY")} , ${moment(bookingDetail.TruckDetails.WeighmentDateTime).format("h:mm:ss a")}` : "-"}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            : ''}
                        {getUrlParam === 'weighment-out' ?
                            <div className="vd_cont post-15" style={{ marginBottom: '10px' }}>
                                <span className='overflowingText'>WEIGHMENT OUT</span>
                                <div className="vd_cont_tbl brtd">
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Gross Weight
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.GrossWeight ? bookingDetail.TruckDetails.GrossWeight : "-"}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Loaded Weight
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.FirstLoadedWeight ? bookingDetail.TruckDetails.FirstLoadedWeight : "-"}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Loading Date & Time
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.LoadedDateTime ? `${moment(bookingDetail.TruckDetails.LoadedDateTime).format("DD/MM/YYYY")} ${moment(bookingDetail.TruckDetails.LoadedDateTime).format("h:mm:ss a")}` : "-"}
                                        </div>
                                    </div>
                                </div>

                                <div className="vd_cont_tbl no_btr">
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Asset Tracker ID
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.AssetTrackerId ? bookingDetail.TruckDetails.AssetTrackerId : "-"}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Fixed Tracker ID
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.FixedTrackerID ? bookingDetail.TruckDetails.FixedTrackerID : "-"}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Loading Slip Number
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.LoadingSlipNo ? bookingDetail.TruckDetails.LoadingSlipNo : "-"}
                                        </div>
                                    </div>
                                    <div className="vd_cont_tbl_sec">
                                        <div className="vd_cont_tbl_sec_1">
                                            Coil Number
                                        </div>
                                        <div className="vd_cont_tbl_sec_2">
                                            {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.CoilNo ? bookingDetail.TruckDetails.CoilNo : "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}
                    </div>
                </div>
                <div className="cust-row flex-algn-cent space ">
                    {/* {getUrlParam === 'inspection' ?
                        <div className='cust-col-2 inc_wd_30' >
                            <button className='button bti bg-orange-out m-0 ' onClick={() => { _handleClick('confirmBookingModal') }}>
                                <div className='btn_icon'>
                                    <img className='gateIn' src={gatedIn} alt="none" />
                                </div>
                                <div className='btn_txt font-lg size'>Request for Gate In</div>
                            </button>
                        </div>
                        : ''} */}
                    <div className='cust-col-2 inc_wd_30'>
                        <button className='button bti bg-success-out m-0 ' onClick={() => { _handleClick('confirmBookingModal') }}>
                            <div className='btn_icon'>
                                <i className='fa fa-edit font-lg '></i>
                            </div>
                            <div className='btn_txt font-lg size'>Update Truck Number</div>
                        </button>
                    </div>
                    <div className='cust-col-2 inc_wd_30' >
                        <button className='button bti bg-danger-out m-0 ' onClick={() => { cancelBooking() }}>
                            <div className='btn_icon'>
                                <i className='fa fa-close'></i>
                            </div>
                            <div className='btn_txt font-lg size'>Cancel Booking</div>
                        </button>
                    </div>
                </div>
            </div>
            <ConfirmBookingModal closeModal={_closeModal} confirmBooking={confirmBooking} />
            <BookDetail base={imgItem} />
        </div>
    )
}

export default BookingDetail