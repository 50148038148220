import {GET_SINGLE_STAFF, HIDE_SINGLE_STAFF, RESET_STAFF_DATA} from  "../actions/types";

const initialState={
    staffData: [],
    loading:true
} 

const getSingleStaffReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_SINGLE_STAFF : return{
            ...state,
            staffData : payload
        }
        case HIDE_SINGLE_STAFF : return{
            ...state, 
            loading:false  
        }
        case RESET_STAFF_DATA:
            return initialState
               
        default: return state;  
    }
}
export default getSingleStaffReducer