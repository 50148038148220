import { MESSAGE, PLANTS_LIST, PLANTS_HIDE, PLANTS_SUCCESS, PAGINATION, PLANTS_RECORDS } from './types'
import { post } from './../../../services/requests'

const pageSize = 5
export const getPlantsListing = (data) => {
    return (dispatch) => {
        dispatch(showLoader()) 
        post('plant/GetAllPlants', { 
            pageSize: pageSize,
             bookmark: data.bookmark,
             searchType : data.searchType,
             searchValue : data.searchValue,  
             status : data.status
            })
            .then(response => {  
                const plantsLists = response.data
                console.log('plantsLists',plantsLists);
                const message = response.message
                dispatch(storeMessage(message))
                dispatch(hideLoader()) 
                if (plantsLists && plantsLists.length === 0 ) {
                }
                else {
                    const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                    const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                    
                    dispatch(getListingOfPlant(plantsLists))
                    dispatch(getRecordCount(records))

                    if (data.param === "next" || data.param === "") {
                        dispatch(vehiclePagination(bookmark))
                    }
                }
            })
            .catch(error => {
                console.log('error', error); 
            })
    }
}
export const getListingOfPlant = (plantsLists) => {
    return {
        type: PLANTS_LIST,
        payload: plantsLists
    }
}
export const showLoader = () => {
    return {
        type: PLANTS_SUCCESS,
    }
}
export const hideLoader = () => {
    return {
        type: PLANTS_HIDE,
    }
}
export const vehiclePagination = (bookmark) => {
    return {
        type: PAGINATION,
        payload: bookmark
    }
}
export const getRecordCount = (records) => {
    return {
        type: PLANTS_RECORDS,
        payload: records
    }
} 
export const storeMessage = (message) => {
    return {
        type: MESSAGE,
        payload: message
    }
}
