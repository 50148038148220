import React, { useEffect, useState } from 'react';
import history from './../../services/history'
import DocViewAddressModal from './Modal/DocViewAddress'
import $ from 'jquery'
import { useDispatch, useSelector } from 'react-redux'
import { getPlantsListing } from '../../state/redux/actions/index';
import axios from 'axios'
import Select from 'react-select';

const RegisterTrans = () => {
    const [base, setBase] = useState('')
    const [panShow, setPanShow] = useState(false)
    const [gstShow, setGstShow] = useState(false)
    const dispatch = useDispatch()
    const [passwordType, setPasswordType] = useState("password")
    const listing = useSelector((state) => state.getPlantsListReducers.plantsData)
    const [tick, setTick] = useState(false)

    const [transObject, setTransObject] = useState(
        {
            registrationType: "transporter",
            orgName: "",
            plantId: "",
            plantName: "",
            ibaCode: "",
            ibaValidity: "",
            mobileNo: "",
            phone: "",
            emailId: "",
            fax: "",
            name: "",
            address: "",
            pan: "",
            panCopy: "",
            tan: "",
            city: "",
            state: "",
            country: "India",
            pinCode: "",
            gstin: "",
            gstinUpload: "",
            password: "",
            referralToken: "",
            permissions: {
                createUser: true,
                editUser: true,
                viewUser: true,
                editHub: true,
                disableUser: true,
                viewListing: true,
                addPlant: true,
                viewPlant: true,
                addStaff: true,
                deleteStaff: true,
                viewStaff: true,
                editStaff: true,
                addHub: true,
                deleteHub: true,
                viewHub: true,
                viewInvoice: true,
                createInvoice: true,
                editInvoice: true,
                addVehicle: true,
                viewVehicle: true,
                editVehicle: true,
                addDriver: true,
                viewDriver: true,
                editDriver: true,
                addAddress: true,
                editAddress: true,
                viewAddress: true,
                addContract: true,
                viewContract: true,
                viewProfile: true,
                editProfile: true,
                changePassword: true,
                viewSettingPanel: true,
                addSettingsInSettingPanel: true,
                editSettingPanel: true,
                viewDashboard: true,
                downloadReport: true,
                vehicleAllocation: true,
                viewBookingDetails: true,
                requestForGateInInspecPassed: true,
                requestForGateInInspecFailed: true,
                weighmentOfVehicle: true,
                vehicleInspection: true,
                driverInspection: true,
                gpsDeviceInspection: true,
                allowVehicleForGateIn: true,
                addClauseForInspection: true,
                waivePenalty: true,
                rejectDriver: true,
                addCustomerVehicle: true,
                addTransporter: true,
                viewTransporter: true,
                approveTransporter: true,
                rejectTransporter: true,
                allocateSalesOrderCustVehicle: true,
                uploadDocuments: true,
                bookingViewDetails: true,
                pickupDropOffLocation: true,
                addDepartment: true,
                viewDepartment: true,
                editOrganisation: true,
                addBooking: true,
                editPlant: true,
                editDepartment: true,
                GeneratePOD: true,
                ArrivedAtDestination: true,
                viewlr: true,
                editContract: true
            }
        }
    )
    const OpenAddressBook = () => {
        history.push("/dashboard/transporter-master")
    }
    const togglePassType = () => {
        if (passwordType == "password") {
            setPasswordType("text")
        }
        else {
            setPasswordType("password")
        }

    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const _handleChange = (e, item) => {
        setTransObject({ ...transObject, [e.target.name]: e.target.value })
    }
    const uploadFrontImage = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        const localArray = { ...transObject }
        if (item === 'pan') {
            localArray.panCopy = base64
            setPanShow(true)
            setBase(base64)
            setTransObject(localArray)
        }
        else if (item === 'gst') {
            localArray.gstinUpload = base64
            setGstShow(true)
            setBase(base64)
            setTransObject(localArray)
        }
    }
    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }

                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    function _handleClick(id) {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible");
        $(`#${id}`).addClass("custom-modal-is-visible");
    }
    const register = () => {
        if (transObject.orgName === '' || transObject.tan === '' || transObject.plantName === '' || transObject.ibaCode === '' || transObject.ibaValidity === '' || transObject.mobileNo === '' || transObject.emailId === '' || transObject.name === '' || transObject.pan === '' || transObject.state === '' || transObject.pinCode === '' || transObject.city === '' || transObject.address === '' || transObject.gstin === '') {
            window.$.fn.show_notification({ message: `*  fields mandatory.. `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            if (transObject.password === transObject.confirmPassword) {
                // setLoading(true)
                const token = localStorage.getItem('secretkey')
                const config = {
                    headers: { Authorization: token }
                };
                axios.post('https://www.betaenterprise.lynkit.io/api/user/register-admin', { ...transObject }, { headers: config.headers })
                    .then(response => {
                        console.log('Register user response', response);
                        const message = response.data.message
                        const error = response.data.error
                        if (error === false) {
                            history.push('/dashboard/transporter-master')
                            window.location.reload();
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }

                    })
                    .catch(error => {
                        // dispatch(showError(err))
                    })


            }
            else {
                window.$.fn.show_notification({ message: `Password does not match.. `, title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
        }
    }
    useEffect(() => {
        dispatch(getPlantsListing({
            docType: "plants",
            bookmark: '',
            param: "next"
        }))
        if (transObject.password.length !== 0 && transObject.password === transObject.confirmPassword) {
            setTick(true)
        }
        else {
            setTick(false)
        }
    }, [transObject])

   
    const addPlant = () => {
        history.push('/dashboard/plants')
    }
    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white',
            width: '100%',

        }),
        control: (styles, state) => {
            return {
                ...styles,
                backgroundColor: "transparent",


                boxShadow:
                    state.isFocused
                        ? "0 0 3px #28a745"
                        : state.isFocused
                            ? "0 0 3px #dc3545"
                            : "none",
                cursor: "pointer",
                ":focus": {
                    ...styles[":focus"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                ":active": {
                    ...styles[":active"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },

                ":hover": {
                    ...styles[":hover"],
                    // borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                height: 35,
                minHeight: 35,
            };
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
            ...styles,
            background: isFocused ? '#ff7200' : isSelected ? '#fff' : undefined,
            color: isFocused ? '#fff' : 'black',
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? "var(--clr--accent)"
                        : undefined
                    : undefined,
            },
            zIndex: 1
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '35px',
            margin: '-8px 0 0 0'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '35px',
        }),


    }
    const options = [];
    listing && listing.map((item) => {
        options.push({ value: item._id, label: item.PlantName });
    });
    const _handlePlantChange = (item) => {
        let temp = {...transObject}
        temp.plantName = item.label 
        temp.plantId =item.value
        setTransObject(temp)
    }
    return (
        <>
            <div className="contentpanel">
                <div className="cust-container">
                    <div className="cust-row flex-algn-cent">
                        <div className='cust-col-4'>
                            <h1 className="title">
                                <button className='button bg-orange mr-2 wd10 flex-inline' onClick={OpenAddressBook} >
                                    <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                                </button>
                                Add Transporter
                            </h1>
                        </div>

                    </div>
                    <hr className='bgg' />
                </div>

                <div className='transComp'>
                    <div className='transRow'>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>Org Name<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='orgName' value={transObject.orgName} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter organistaion name' />
                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>Plant Name<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans700'>
                                <Select
                                    onChange={(value) => _handlePlantChange(value)}
                                    options={options}
                                    styles={colourStyles}
                                    // value={{ label: updateUser.PlantName, value: updateUser.PlantId }}
                                />
                                <button className='addPlant' onClick={addPlant}>Add</button>
                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>Name<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='name' value={transObject.name} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter Name' />
                            </div>
                        </div>
                    </div>
                    <div className='transRow'>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>Address<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='address' value={transObject.address} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter Address' />
                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>Mobile Number<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='mobileNo' value={transObject.mobileNo} onChange={(e) => _handleChange(e)} type='number' placeholder='Enter mobile number' />
                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>Phone Number :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='phone' value={transObject.phone} onChange={(e) => _handleChange(e)} type='number' placeholder='Enter phone number code' />
                            </div>
                        </div>
                    </div>
                    <div className='transRow'>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>Email id<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='emailId' value={transObject.emailId} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter your email id' />
                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>IBA code<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='ibaCode' value={transObject.ibaCode} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter IBA code' />
                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>IBA validity<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='ibaValidity' value={transObject.ibaValidity} onChange={(e) => _handleChange(e)} type='date' placeholder='Enter IBA validity date' />
                            </div>
                        </div>
                    </div>
                    <div className='transRow'>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>Fax :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='fax' value={transObject.fax} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter Fax no.' />
                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>PAN<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='pan' value={transObject.pan} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter pan number' />
                                <div className='uploadElement'>
                                    <i className="fa fa-upload transupload"></i>
                                    <input className='fileType' type='file' onChange={(e) => uploadFrontImage(e, 'pan')} />
                                </div>
                                {panShow === true ?
                                    <i className="fa fa-eye transEYE" onClick={() => { _handleClick('DocViewAddressModal') }} ></i>
                                    : ''}

                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>TAN <span style={{ color: 'red' }}> *</span>:</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='tan' value={transObject.tan} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter tan number' />
                            </div>
                        </div>
                    </div>
                    <div className='transRow'>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>State<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='state' value={transObject.state} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter state' />
                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>City<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='city' value={transObject.city} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter city' />
                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>Pincode<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='pinCode' value={transObject.pinCode} onChange={(e) => _handleChange(e)} type='number' placeholder='Enter pin code' />
                            </div>
                        </div>
                    </div>
                    <div className='transRow'>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>GSTIN<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='gstin' value={transObject.gstin} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter GSTIN number' />
                                <div className='uploadElement'>
                                    <i className="fa fa-upload transupload"></i>
                                    <input className='fileType' type='file' onChange={(e) => uploadFrontImage(e, 'gst')} />
                                </div>
                                {gstShow === true ?
                                    <i className="fa fa-eye transEYE" onClick={() => { _handleClick('DocViewAddressModal') }} ></i>
                                    : ''}

                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>Password<span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='password' value={transObject.password} onChange={(e) => _handleChange(e)} type={passwordType} placeholder='Enter ypur password' />
                                {passwordType === 'password' ?
                                    <i className="fa fa-eye-slash iconEye transEYE" onClick={togglePassType}></i>
                                    :
                                    <i onClick={togglePassType} className="fa fa-eye iconEye transEYE" ></i>
                                }
                            </div>
                        </div>
                        <div className='trans30'>
                            <div className='trans30Inner'>
                                <span className='transSpan'>Confirm <span style={{ color: 'red' }}> *</span> :</span>
                            </div>
                            <div className='trans70'>
                                <input className='transInput' name='confirmPassword' value={transObject.confirmPassword} onChange={(e) => _handleChange(e)} type='text' placeholder='confirm your password ' />
                                {tick === true ?
                                    <i className="fa fa-check match myVisibleEyeBlack  transEYE"></i>
                                    : ''}
                            </div>
                        </div>
                    </div>
                    <div className='transRow transCentr'>
                        <button className='registerTransBtn' onClick={() => register()}>Register</button>
                    </div>
                </div>

                <DocViewAddressModal closeModal={_closeModal} imageData={base} />
            </div>
        </>

    );
};

export default RegisterTrans;