import {GET_SINGLE_TRANS, HIDE_TRANS_LOADER} from  "../actions/types";

const initialState={
    transData: [],
    loading:true
} 

const getTransReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_SINGLE_TRANS : return{
            ...state,
            transData:payload
        }
        case HIDE_TRANS_LOADER : return{
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default getTransReducer