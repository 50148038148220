import {FETCH_DRI_THIRD , SHOW_LOADER , HIDE_LOADER} from "../actions/types";

const initialState={
    fetchedData: [], 
    loading:true
} 

const thirdDriverRed =(state=initialState,{type,payload})=>{
    switch(type){
        case FETCH_DRI_THIRD : return{
            ...state,
            fetchedData : payload 
        }
        case HIDE_LOADER : return{
            ...state,
            loading:false
        }
        default: return state;   
    }
}
export default thirdDriverRed;