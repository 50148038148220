import { post } from './../../../services/requests'
import {GET_ALL_CONTRACTS , HIDE_CONTRACT } from './types'

const pageSize = 50
export const getAllContracts = (data) =>{ 
    return(dispatch)=>{
        post(`contract/getallcontracts`, { pageSize: pageSize, bookmark: data.bookmark })
        .then(response=>{
            //  console.log('Contracts response',response);
             const contractData= response.data
             dispatch(storeContractData(contractData))
             dispatch(hideContract())
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const storeContractData = (contractData) => {
    return {
        type: GET_ALL_CONTRACTS,
        payload: contractData
    }
}
export const hideContract = () => {
    return {
        type: HIDE_CONTRACT
    }
}