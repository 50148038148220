// import React from 'react';
// import { Switch, Route } from 'react-router-dom';
// // import { Switch } from 'react-router-dom';
// // import Route from './Route';
// import Landing from '../components/App';
// import Dashboard from '../components/Dashboard';

// export default function Routes() {
//   return (
//     <>
//       <Switch>
//         <Route path="/" exact component={Landing} />
//         <Route path="/dashboard" component={Dashboard} isPrivate />
//         {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
//         <Route component={Landing} />
//       </Switch>
//     </>
//   );
// }

import React from "react";
import Login from "../views/Landing/Login";
import Registration from "../views/Landing/Registration";
import TransRegister from "../views/Landing/TransRegister";
import { Switch, Redirect } from "react-router-dom";
// import { Switch } from "react-router-dom";
// import Route from "./Route";
import Landing from "../components/App";
import Dashboard from "../components/Dashboard";
import PrivateRoute from "./Route";
import Contracts from "../views/Dashboard/Contracts";
export default function Routes() {
  return (
    <Switch>
      {/* <PrivateRoute path="/" exact component={Landing} /> */}

      <PrivateRoute path="/dashboard" component={Dashboard} isPrivate />
      <PrivateRoute
        path={"/"}
        exact={true}
        render={(props) => <Login {...props} />}
      />
      <PrivateRoute
        path={"/registration/transporter"}
        exact={true}
        render={(props) => <TransRegister {...props} />}
      />
      <PrivateRoute
        path={`/registration`}
        exact={true}
        render={(props) => <Registration {...props} />}
      />

      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Redirect to="/" />
    </Switch>
  );
}
