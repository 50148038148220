import {VIEW_LR , LR_HIDE } from  "../actions/types";

const initialState={
    lrData: [], 
    loading:true
} 

const viewLrDetailRed =(state=initialState,{type,payload})=>{
    switch(type){
        case VIEW_LR : return{
            ...state,
            lrData : payload 
        }
        case LR_HIDE : return{
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default viewLrDetailRed; 