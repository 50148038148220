import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import { get_pdf_thumbnail, base64Convert } from './../../../services/functions'
import FileUpload from './../FileUpload'
import axios from 'axios'

const UploadContract = (props) => {
    const {contractData} = props 
    const [fileArr, setFileArr] = useState([])
    const [previewArray, setPreviewArray] = useState('')  
    const [condition, setCondition] = useState(true)
    const _handleFiles = async (files) => {
        if (files.length > 0) {
            let tempArr = [...fileArr]
            let tarr = Array.from(files)
            for (let el of tarr) {
                if (el.type.includes('image') || el.type === "application/pdf") {
                    try {
                        let base64 = await base64Convert(el)
                        let obj = { base64, fileType: el.type.includes('image') ? 'image' : 'pdf', name: el.name }

                        if (obj.fileType === 'pdf') {
                            let pdf = base64.replace(/^data:application\/\pdf;base64,/, "")
                            var binary_string = window.atob(pdf);
                            var len = binary_string.length;
                            var bytes = new Uint8Array(len);
                            for (var i = 0; i < len; i++) {
                                bytes[i] = binary_string.charCodeAt(i);
                            }
                            var buffer = bytes.buffer
                            var file3 = new Blob([buffer], { type: 'application/pdf' });
                            obj['dataLocalUrl'] = file3
                            get_pdf_thumbnail(file3).then((url) => {
                                obj['imageSrc'] = url
                            })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                        tempArr.unshift(obj)
                    }
                    catch (err) {

                    }
                }
                else {
                    //Swal.fire("Oops","Only image or PDF file is allowed to upload",'warning')
                }
            }
            setFileArr(tempArr)
        }
    }
    const deleteFile = (index) => {
        let tempArr = [...fileArr]
        tempArr.splice(index, 1)
        setFileArr(tempArr)
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const expandImg = (index) => {
        let tempArr = [...fileArr]
        let getBase = tempArr[index].base64
        setPreviewArray(getBase)

    }
    const togglePreview = () => {
        setPreviewArray('')
    }
    const DownloadImage = (index) => {
        var newArray = [...fileArr]
        var a = document.createElement("a");
        a.href = newArray[index].base64
        a.download = fileArr[index].name
        a.click();
    }
    useEffect(() => {
        if(condition  && contractData && Object.keys(contractData).length>0){
            let arr = contractData.AttachedDocuments && contractData.AttachedDocuments.length>0?contractData.AttachedDocuments:[]
            if (arr && arr.length>0) {
                for (let i = 0; i < arr.length; i++) {
                    const token = localStorage.getItem('secretkey')
                    const config = {
                        headers: { Authorization: token }
                    };
                    let key = arr[i].documentKey
                    axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                        .then(response => {
                            const storeBase = response.data.file
                            // setBase(storeBase)
                            let temp = [...fileArr]
                            temp.push({
                                base64: storeBase
                            })
                            setFileArr(temp)
                        })
                        .catch(error => {
                            console.log('err2', error)
                        })
                }
            }
            else {
                setFileArr([])
            }

            if(condition){
                setCondition(false)
            }
        }


    }, [contractData])

    return (
        <section id="UploadContractModal" className="custom-modal" style={{ width: "40%" }}>
            <div className="custom-modal-header">
                <h5>Upload Contract</h5>
                <span className="ct-close" onClick={_closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '75vh' }}>
                <div className='contarctModal'>
                    <div className="vd_cont_3">
                        <div className="vd_cont_3_sec">
                            <div className="vd_cont_3_sec_header">
                                {/* Upload Vehicle Documents */}
                            </div>
                            {!previewArray ?
                                <div className="vd_cont_3_sec_bdy">
                                    {fileArr &&
                                    fileArr.length>0?
                                        <div className='file_preview'>
                                            <p>Uploaded Files</p>
                                            <div className='file_preview_cont'>
                                                {
                                                    fileArr.map((item, index) => {
                                                        return <div key={`pp-` + index} className='preview_itm'>
                                                            <div className='closeb' onClick={() => { deleteFile(index) }}>
                                                                <i className='fa fa-close'></i>
                                                            </div>
                                                            <img src={item.base64} alt="" />
                                                            <i className="fa fa-download downloadIcon" onClick={() => DownloadImage(index)}></i>
                                                            <div className='fullscreen'>
                                                                <i className='fa fa-expand' onClick={() => expandImg(index)}></i>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        "No Document uploaded.."
                                    }
                                    <FileUpload handleFiles={_handleFiles} />
                                    <div className='btn_cont post-btm'>
                                        <button className='button bti bg-orange-out m-0 btn-size'>
                                            <div className='btn_icon'>
                                                <i className='fa fa-upload'></i>
                                            </div>
                                            <div className='btn_txt font-lg'>Update</div>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className='previewDiv'>
                                    <i className='fa fa-close closeIcon' onClick={() => togglePreview()}></i>
                                    <img src={previewArray} alt='' />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UploadContract;