import React, { useState, useEffect } from 'react';
import history from '../../services/history'
import $ from 'jquery'
import DocViewAddressModal from './Modal/DocViewAddress'
import { listingApi, getPlantsListing } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import ImgPreviewModal from './../Dashboard/Modal/ImgPreviewModal'
import axios from 'axios'

const TransporterMasterDetail = (props) => {
    const dispatch = useDispatch()
    // const singleTransData = useSelector((state) => state)
    const sharedData = props.location.state
    console.log('sharedData', sharedData);
    // const loader = useSelector((state) => state.getSingleAddReducer.loading)

    const [base, setBase] = useState('')
    const [panShow, setPanShow] = useState(false)
    const [gstShow, setGstShow] = useState(false)
    const [ibaShow, setIbaShow] = useState(false)
    const [disableState, setDisableState] = useState(true)
    const plantListing = useSelector((state) => state.getPlantsListReducers.plantsData)
    const [obj, setObj] = useState(
        {
            docType: "transporter",
            param: "next",
            pageSize: "",
            bookmark: "",
            blacklistedFlag: "",
            searchType: "",
            searchValue: ""
        }
    )
    const [transporterDetail, setTransporterDetail] = useState(
        {
            panBase: '',
            gstBase: '',
            ibaBase: ''
        }
    )
    const _goBack = () => {
        history.push('/dashboard/transporter-master')
    }
    const uploadFrontImage = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        const localArray = transporterDetail
        if (item === 'pan') {
            localArray.panBase = base64
            setPanShow(true)
            setBase(base64)
        }
        else if (item === 'gst') {
            localArray.gstBase = base64
            setGstShow(true)
            setBase(base64)
        }
        if (item === 'iba') {
            localArray.ibaBase = base64
            setIbaShow(true)
            setBase(base64)
        }

    }
    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }

                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const allowEdit = () => {
        if ($(".add-detail-span2").hasClass("underline")) {
            $(".add-detail-span2").removeClass("underline");
            setDisableState(true)
            setUpdateObj(
                {
                    VendorId: '',
                    VendorCode: '',
                    PAN: '',
                    PanCopy: '',
                    TAN: '',
                    GSTIN: '',
                    GstinUpload: '',
                    Address: '',
                    State: '',
                    City: '',
                    PinCode: '',
                    SiteId: '',
                    SiteCode: '',
                    OrgName: '',
                    PlantName: '',
                    PlantId: '',
                    IbaCode: '',
                    IbaUpload: '',
                    IbaValidity: '',
                    Fax: '',
                    Status: ''
                }
            )
        } else {
            $(".add-detail-span2").addClass("underline");
            setDisableState(false)
            let dummy = { ...updateObj }
            dummy.VendorId = sharedData.VendorId
            dummy.VendorCode = sharedData.VendorCode
            dummy.PAN = sharedData.PAN
            // dummy.PanCopy = sharedData.PanCopy
            dummy.TAN = sharedData.TAN
            dummy.GSTIN = sharedData.GSTIN
            // dummy.GstinUpload = sharedData.GstinUpload
            dummy.Address = sharedData.Address
            dummy.State = sharedData.State
            dummy.City = sharedData.City
            dummy.PinCode = sharedData.PinCode
            dummy.SiteId = sharedData.SiteId
            dummy.SiteCode = sharedData.SiteCode
            dummy.OrgName = sharedData.OrgName
            dummy.PlantName = sharedData.PlantName
            dummy.PlantId = sharedData.PlantId
            dummy.IbaCode = sharedData.IbaCode
            // dummy.IbaUpload = sharedData.IbaUpload
            dummy.IbaValidity = sharedData.IbaValidity
            dummy.Fax = sharedData.Fax
            dummy.Status = sharedData.Status
            setUpdateObj(dummy)
        }
    }
    const [updateObj, setUpdateObj] = useState({
        VendorId: '',
        VendorCode: '',
        PAN: '',
        PanCopy: '',
        TAN: '',
        GSTIN: '',
        GstinUpload: '',
        Address: '',
        State: '',
        City: '',
        PinCode: '',
        SiteId: '',
        SiteCode: '',
        OrgName: '',
        PlantName: '',
        PlantId: '',
        IbaCode: '',
        IbaUpload: '',
        IbaValidity: '',
        Fax: '',
        Status: ''
    })
    const SharedData = (item) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if (item === 'gst') {
            let key = sharedData.GstinUpload.file_key
            axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                .then(response => {
                    const storeBase = response.data.file
                    setBase(storeBase)
                })
                .catch(error => {
                    console.log('err', error)
                })
        }
        else if (item === 'pan') {
            let key = sharedData.PanCopy.file_key
            axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                .then(response => {
                    const storeBase = response.data.file
                    setBase(storeBase)
                })
                .catch(error => {
                    console.log('err', error)
                })
        }
        else if (item === 'iba') {
            let key = sharedData.GstinUpload.file_key
            axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                .then(response => {
                    const storeBase = response.data.file
                    setBase(storeBase)
                })
                .catch(error => {
                    console.log('err', error)
                })
        }
    }
    const updateData = (e) => {
        setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
    }
    const updateFun = () => {
        const finalObj = {
            vendorId: updateObj.VendorId,
            vendorCode: updateObj.VendorCode,
            pan: updateObj.PAN,
            // panCopy: updateObj
            tan: updateObj.TAN,
            gstin: updateObj.GSTIN,
            // gstinUpload: updateObj
            address: updateObj.Address,
            state: updateObj.State,
            city: updateObj.City,
            pinCode: updateObj.PinCode,
            siteId: updateObj.SiteId,
            siteCode: updateObj.SiteCode,
            orgName: updateObj.OrgName,
            plantName: updateObj.PlantName,
            plantId: updateObj.PlantId,
            ibaCode: updateObj.IbaCode,
            // IbaUpload: updateObj
            ibaValidity: updateObj.IbaValidity,
            fax: updateObj.Fax,
            status: updateObj.Status
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/user/UpdateOrganisation', { ...finalObj }, { headers: config.headers })
            .then(response => {
                const err = response.data.error
                const message = response.data.message
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                if (err === false) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log('err', error)
            })
    }
    const _onChange = (e) => {
        updateObj.PlantName = plantListing[e.target.value].PlantName
        updateObj.PlantId = plantListing[e.target.value]._id
    }
    useEffect(() => {
        dispatch(getPlantsListing({
            docType: "plants",
            bookmark: '',
            param: "next"
        }))
    }, [dispatch]);

    const blacklistOrRemove = () => {
        const finalObj = {
            OrgId: sharedData._id,
            orgName: sharedData.OrgName,
            pan: sharedData.PAN,
            panCopy: sharedData.PanCopy,
            address: sharedData.Address,
            state: sharedData.State,
            city: sharedData.City,
            country: sharedData.Country,
            pinCode: sharedData.PinCode,
            gstin: sharedData.GSTIN,
            gstinUpload: sharedData.gstinUpload,
            vendorId: sharedData.VendorId,
            vendorCode: sharedData.VendorCode,
            siteId: sharedData.SiteId,
            siteCode: sharedData.SiteCode,
            plantId: sharedData.PlantId,
            plantName: sharedData.PlantName,
            ibaCode: sharedData.IbaCode,
            ibaValidity: sharedData.IbaValidity,
            fax: sharedData.Fax,
            tan: sharedData.TAN,
            status: sharedData.Status,
            blacklistedFlag: sharedData.BlacklistedFlag === false ? true : false,
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: `Are you sure ? `, type: 'confirm', title: 'Alert!!', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {
                axios.post('https://www.betaenterprise.lynkit.io/api/user/UpdateOrganisation', { ...finalObj }, { headers: config.headers })
                    .then(response => {
                        const message = response.data.message
                        const err = response.data.error
                        dispatch(listingApi(obj))
                        if (err === 'false') {
                            window.$.fn.show_notification({ message: `${message} `, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })

                        }
                        else {
                            window.$.fn.show_notification({ message: `${message} `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                    })
            }
        })
    }
    return (
        <>
            <div className="contentpanel">
                <div className='cust-row'>
                    <div className='cust-col-4 flex-algn-cent'>
                        <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                            <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                        </button>
                        <h1 className="title">Transporter Master Details</h1>
                    </div>
                    <div className='cust-col-6 flex-jc-end'>
                        <button className='button bg-orange-out mr-2 wd10' onClick={allowEdit}>
                            <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                            <div className="titleBoxnew">
                                <span>Edit </span>
                            </div>
                        </button>
                        <button className='button bg-grey-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className="fa fa-file"></i></div>
                            <div className="titleBoxnew">
                                <span>Contract </span>
                            </div>
                        </button>
                        {sharedData.BlacklistedFlag === false || sharedData.Status === 'INACTIVE' ? ''
                            : 
                            <button disabled={sharedData.BlacklistedFlag === false || sharedData.Status === 'INACTIVE' ? true : false} className='button bg-success-out mr-2 wd10' onClick={blacklistOrRemove}>
                                <div className='btn_txt font-lg'><i className="fa fa-check"></i></div>
                                <div className="titleBoxnew">
                                    <span>Approve </span>
                                </div>
                            </button>
                        }
                        {sharedData.BlacklistedFlag === true || sharedData.Status === 'INACTIVE' ? ''
                        :
                        <button className='button bg-danger-out mr-2 wd10' onClick={blacklistOrRemove}>
                            <div className='btn_txt font-lg'><i className="fa fa-close"></i></div>
                            <div className="titleBoxnew">
                                <span>Blacklist </span>
                            </div>
                        </button>
                        }
                    </div>
                </div>
                <hr className='bgg' />
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='TM-details'>
                                <div className='TM-details-L'>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>Vendor ID</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2'
                                                name='VendorId'
                                                value={disableState === true ? sharedData.VendorId : updateObj.VendorId}
                                                disabled={disableState}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>Vendor Code</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2'
                                                name='VendorCode'
                                                value={disableState === true ? sharedData.VendorCode : updateObj.VendorCode}
                                                disabled={disableState}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>PAN Number</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2'
                                                name="PAN"
                                                value={disableState === true ? sharedData.PAN : updateObj.PAN}
                                                disabled={disableState}
                                                onChange={(e) => updateData(e)}
                                            />
                                            {disableState === false ?
                                                <div className='addUploadItem removeBrdr'>
                                                    <i className="fa fa-upload uploadIn" ></i>
                                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'pan')} type="file" />
                                                </div>
                                                : ''}
                                            <i className="fa fa-eye myVisibleEyeBlack" onClick={() => { _handleClick('DocViewAddressModal'); SharedData('pan') }}></i>
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>TAN Number</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2'
                                                name="TAN"
                                                value={disableState === true ? sharedData.TAN : updateObj.TAN}
                                                disabled={disableState}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>GSTIN</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2'
                                                name='GSTIN'
                                                value={disableState === true ? sharedData.GSTIN : updateObj.GSTIN}
                                                disabled={disableState}
                                                onChange={(e) => updateData(e)}
                                            />
                                            {disableState === false ?
                                                <div className='addUploadItem removeBrdr'>
                                                    <i className="fa fa-upload uploadIn" ></i>
                                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'gst')} type="file" />
                                                </div>
                                                : ''}
                                            <i className="fa fa-eye myVisibleEyeBlack" onClick={() => { _handleClick('DocViewAddressModal'); SharedData('gst') }}></i>
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>Registered Address</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2' disabled={disableState}
                                                name='Address'
                                                value={disableState === true ? sharedData.Address : updateObj.Address}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>State</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2'
                                                name="State"
                                                value={disableState === true ? sharedData.State : updateObj.State}
                                                disabled={disableState}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>City</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2'
                                                name="City"
                                                value={disableState === true ? sharedData.City : updateObj.City}
                                                disabled={disableState}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>Pin Code</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2'
                                                name="PinCode"
                                                value={disableState === true ? sharedData.PinCode : updateObj.PinCode}
                                                disabled={disableState}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>Site ID</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2' disabled={disableState}
                                                name='SiteId'
                                                value={disableState === true ? sharedData.SiteId : updateObj.SiteId}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='TM-details-R'>

                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>Site Code</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2' disabled={disableState}
                                                name='SiteCode'
                                                value={disableState === true ? sharedData.SiteCode : updateObj.SiteCode}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>Vendor Name</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2' disabled={disableState}
                                                name="OrgName"
                                                value={disableState === true ? sharedData.OrgName : updateObj.OrgName}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>Contact Person</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2' disabled={disableState} value={sharedData.UserDetails.map((x, y) => { return (x.Name) })} />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>Mobile Number</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2' disabled={disableState} value={sharedData.UserDetails.map((x, y) => { return (x.MobileNo) })} />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>Email</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2' disabled={disableState} value={sharedData.UserDetails.map((x, y) => { return (x.EmailId) })} />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>Plant Name</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            {disableState === true ?
                                                <input className='add-detail-span2' disabled={disableState}
                                                    name="PlantName"
                                                    value={disableState === true ? sharedData.PlantName : updateObj.PlantName}
                                                    onChange={(e) => updateData(e)}
                                                />
                                                :
                                                <select defaultValue='default' className='transBooking' style={{ width: '70%' }} onChange={(e) => _onChange(e, 'plants')}>
                                                    <option value='default'>Select Plant</option>
                                                    {plantListing !== undefined ?
                                                        plantListing.map((item, index) => {
                                                            return (
                                                                <option value={index} key={index} >{item.PlantName}</option>
                                                            )
                                                        })
                                                        : ''}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>IBA code</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2' disabled={disableState}
                                                name="IbaCode"
                                                value={disableState === true ? sharedData.IbaCode : updateObj.IbaCode}
                                                onChange={(e) => updateData(e)}
                                            />
                                            {disableState === false ?
                                                <div className='addUploadItem removeBrdr'>
                                                    <i className="fa fa-upload uploadIn" ></i>
                                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'iba')} type="file" />
                                                </div>
                                                : ''}
                                            <i className="fa fa-eye myVisibleEyeBlack" onClick={() => { _handleClick('DocViewAddressModal'); SharedData('iba') }}></i>
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>IBA validity</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2' disabled={disableState}
                                                name="IbaValidity"
                                                value={disableState === true ? sharedData.IbaValidity : updateObj.IbaValidity}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='TM-detail-tab'>
                                        <div className='Tm-detail-C1'>
                                            <span className='tr-span1'>FAX</span>
                                        </div>
                                        <div className='Tm-detail-C2'>
                                            <input className='add-detail-span2' disabled={disableState}
                                                name="Fax"
                                                value={disableState === true ? sharedData.Fax : updateObj.Fax}
                                                onChange={(e) => updateData(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {disableState === false ?
                                <div className='updateElem'>
                                    <button onClick={() => updateFun()}>Update</button>
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
                <DocViewAddressModal closeModal={_closeModal} imageData={base} />
                <ImgPreviewModal base={base} />
            </div>
        </>
    );
};

export default TransporterMasterDetail;