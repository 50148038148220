import React from 'react';
import Select from 'react-select';
const Input = (props) => {
    const { ele, mainIndex, inputValueHandler, shareIndex , removeItem } = props;
    
    const setTrue = () => {
        if (ele.value !== "") {
            ele.show = true
            ele.dropDownShow = false
            shareIndex(mainIndex, ele) 
        } 
        else {
            ele.show = false
        } 
    }
    const setDropTrue = () => {
        ele.dropDownShow = true  
        ele.show = false
        shareIndex(mainIndex , ele.values , inputValueHandler)
    }

    // const colourStyles = {
    //     menuList: styles => ({
    //         ...styles,
    //         background: 'white',
    //         width: '100%',

    //     }),
    //     control: (styles, state) => {
    //         return {
    //             ...styles,
    //             backgroundColor: "transparent",


    //             boxShadow:
    //                 state.isFocused
    //                     ? "0 0 3px #28a745"
    //                     : state.isFocused
    //                         ? "0 0 3px #dc3545"
    //                         : "none",
    //             cursor: "pointer",
    //             ":focus": {
    //                 ...styles[":focus"],
    //                 //   borderColor: "var(--clr--accent)",
    //                 // boxShadow: "0 0 3px var(--clr--accent)",
    //             },
    //             ":active": {
    //                 ...styles[":active"],
    //                 //   borderColor: "var(--clr--accent)",
    //                 // boxShadow: "0 0 3px var(--clr--accent)",
    //             },

    //             ":hover": {
    //                 ...styles[":hover"],
    //                 // borderColor: "var(--clr--accent)",
    //                 // boxShadow: "0 0 3px var(--clr--accent)",
    //             },
    //             height: 35,
    //             minHeight: 35,
    //         };
    //     },
    //     option: (styles, { isDisabled, isFocused, isSelected }) => ({
    //         ...styles,
    //         background: isFocused ? '#ff7200' : isSelected ? '#fff' : undefined,
    //         color: isFocused ? '#fff' : 'black',
    //         ":active": {
    //             ...styles[":active"],
    //             backgroundColor: !isDisabled
    //                 ? isSelected
    //                     ? "var(--clr--accent)"
    //                     : undefined
    //                 : undefined,
    //         },
    //         zIndex: 999999999,
    //     }),
    //     menu: base => ({
    //         ...base,
    //         zIndex: 99999999
    //     }),
    //     valueContainer: (provided, state) => ({
    //         ...provided,
    //         height: '35px',
    //         margin: '-8px 0 0 0'
    //     }),

    //     input: (provided, state) => ({
    //         ...provided,
    //         margin: '0',
    //     }),
    //     indicatorSeparator: state => ({
    //         display: 'none',
    //     }),
    //     indicatorsContainer: (provided, state) => ({
    //         ...provided,
    //         height: '35px',
    //     }),


    // }

    // const options = [];
    // ele.values && ele.values.map((item) => {
    //     options.push({ value: item.label, label: item.label });
    // });

    // const _handleChange = (item) => {
    //     var e = item.label
    //     inputValueHandler({ e , index: mainIndex })
    //     setDropTrue()
    // }
    return (
        <>
            <div className='FieldElem alignCenter'>
            <i className="fa fa-minus absMius" onClick={(e)=>removeItem({e,index: mainIndex})} ></i>
                <input className='inputField' name='input' type='text' value={ele.value} placeholder={ele.placeholder} onChange={(e) => { inputValueHandler({ e, index: mainIndex }); setTrue(); }} />

                <select className='dropElem' defaultValue='default' name='drop' onChange={(e) => {inputValueHandler({ e, index: mainIndex });setDropTrue();}} >
                <option value='default'>select</option>
                    {ele.values && ele.values.length && ele.values.map((data, index) => {
                        return (
                            <option value={index} key={index}>{data.label}</option>
                        )
                    })}
                </select>

              
            </div>
        </>
    )
}

export default Input;