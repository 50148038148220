import React,{Component} from 'react';
import $ from 'jquery'

class DashboardView extends Component {

    componentDidMount(){
        setTimeout(() => {
            this.toogleHubView(0)  
        }, 100); 
    }

    toogleHubView(index, check = true) {
        if ($(".collapse-view").eq(index).hasClass("open") && check) {
          $(".collapse-view").eq(index).removeClass("open");
          $(".collapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
          let height =
            Number($(".collapse-view").eq(index).children("table").height()) + 2;
          $(".collapse-view")
            .eq(index)
            .animate({ height }, 500, () => {
              $(".collapse-view").eq(index).addClass("open");
            });
        }  
    }
 
    render(){
        return(
            <div className="contentpanel">
                <div className="cust-row flex-algn-cent"> 
                    <div className='cust-col-5'>
                        <h1 className="title">
                            Dashboard
                        </h1>
                    </div>
                    <div className='cust-col-5 flex-jc-end'>
                        <h1 className="title font-bold orange_color">
                        {/* Hindalco Dahej Copper Unit  */}
                        </h1>
                    </div>
                </div>
                <hr className='bgg'/>
                <div className="cust-container">
                    <div class="cust-row">
                        <div class="cust-col-10">

                            <div class="table-responsive collapse-view ">
                                <div class="collapsable_icon" onClick={()=>{this.toogleHubView(0)}}></div>
                                <table className='cctbl'>
                                    <thead>
                                        <tr class="bg_black">
                                            <th colSpan="6">Hindalco Dahej Copper Unit </th>
                                        </tr>
                                        <tr class="bg_black">
                                            <th colSpan="2">Incoming Shipments</th>
                                            <th colSpan="2">At Plant</th>
                                            <th colSpan="2">Outgoing Shipments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr> 
                                            <td colSpan={2}>
                                                <div class="dd_sec orange">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-5</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Faridabad to Hindalco Dahej, Godown Delivery)</span>
                                                                <div class="hh_sec">
                                                                    <div>Goods Dispatch from Hindalco Faridabad</div>
                                                                    {localStorage.getItem('userGroup') !== 'transporter' ?
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    :""}
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Faridabad</p>
                                                                    <p><b>Dropoff Address :</b>Hindalco Bhilad</p>
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td colSpan={2}>
                                                <div class="dd_sec purple">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-6</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Dahej to Finolex, Door Delivery )</span>
                                                                <div class="hh_sec">
                                                                    <div>Ready for dispatch from Source Hub: Hindalco Dahej</div>
                                                                    {localStorage.getItem('userGroup') !== 'transporter' ?
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    :""}
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Dahej</p>
                                                                    <p><b>Dropoff Address :</b>Finolex</p>
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dd_sec grey">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-7</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Dahej to Hindalco Bhilad, Godown Delivery )</span>
                                                                <div class="hh_sec">
                                                                    <div>Goods delivered at Destination Hub: Hindalco Bhilad</div>
                                                                    {localStorage.getItem('userGroup') !== 'transporter' ?
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    :""}
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Faridabad</p>
                                                                    <p><b>Dropoff Address :</b>Hindalco Bhilad</p>
                                                                    Proof of delivery generated on Thursday, February 3, 2022 5:55 PM
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td colSpan={2}>
                                                <div class="dd_sec orange">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-8</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Dahej to Godrej Bangalore, Door Delivery )</span>
                                                                <div class="hh_sec">
                                                                    <div>Goods dispatch for Destination: Godrej Bangalore</div>
                                                                    {localStorage.getItem('userGroup') !== 'transporter' ?
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    :""}
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Dahej</p>
                                                                    <p><b>Dropoff Address :</b>Godrej Bangalore</p>
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody> 
                                </table>
                            </div>
                            
                            {localStorage.getItem('userGroup') !== 'transporter'?
                            <div class="table-responsive collapse-view">
                                <div class="collapsable_icon" onClick={()=>{this.toogleHubView(1)}}></div>
                                <table className='cctbl'>
                                    <thead>
                                        <tr class="bg_black">
                                            <th colSpan="6">Hindalco Bhilad</th>
                                        </tr>
                                        <tr class="bg_black">
                                            <th colSpan="2">Incoming Shipments</th>
                                            <th colSpan="2">At Plant</th>
                                            <th colSpan="2">Outgoing Shipments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                <div class="dd_sec orange">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-1</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Faridabad to Hindalco Bhilad, Godown Delivery)</span>
                                                                <div class="hh_sec">
                                                                    <div>Goods Dispatch from Hindalco Faridabad</div>
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Faridabad</p>
                                                                    <p><b>Dropoff Address :</b>Hindalco Bhilad</p>
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td colSpan={2}>
                                                <div class="dd_sec purple">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-2</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Faridabad to Hindalco Bhilad, Godown Delivery )</span>
                                                                <div class="hh_sec">
                                                                    <div>Goods Arrived At Destination Hub: Hindalco Bhilad</div>
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Faridabad</p>
                                                                    <p><b>Dropoff Address :</b>Hindalco Bhilad</p>
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dd_sec grey">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-4</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Faridabad to Hindalco Bhilad, Godown Delivery )</span>
                                                                <div class="hh_sec">
                                                                    <div>Goods delivered at Destination Hub: Hindalco Bhilad</div>
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Faridabad</p>
                                                                    <p><b>Dropoff Address :</b>Hindalco Bhilad</p>
                                                                    Proof of delivery generated on Thursday, February 3, 2022 5:55 PM
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td colSpan={2}>
                                                <div class="dd_sec orange">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-3</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Bhilad to Hindalco Faridabad, Godown Delivery )</span>
                                                                <div class="hh_sec">
                                                                    <div>Goods dispatch for Destination Hub: Hindalco Faridabad</div>
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Bhilad</p>
                                                                    <p><b>Dropoff Address :</b>Hindalco Faridabad</p>
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            :""}
                            {localStorage.getItem('userGroup') !== 'transporter' ?
                            <div class="table-responsive collapse-view">
                                <div class="collapsable_icon" onClick={()=>{this.toogleHubView(2)}}></div>
                                <table className='cctbl'>
                                    <thead>
                                        <tr class="bg_black">
                                            <th colSpan="6">Hindalco Faridabad</th>
                                        </tr>
                                        <tr class="bg_black">
                                            <th colSpan="2">Incoming Shipments</th>
                                            <th colSpan="2">At Plant</th>
                                            <th colSpan="2">Outgoing Shipments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td colSpan={2}>
                                                <div class="dd_sec orange">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-3</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Bhilad to Hindalco Faridabad, Godown Delivery )</span>
                                                                <div class="hh_sec">
                                                                    <div>Goods dispatch from Source Hub: Hindalco Bhilad</div>
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Bhilad</p>
                                                                    <p><b>Dropoff Address :</b>Hindalco Faridabad</p>
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>                                            
                                            <td colSpan={2}>
                                                <div class="dd_sec purple">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-2</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Faridabad to Hindalco Bhilad, Godown Delivery )</span>
                                                                <div class="hh_sec">
                                                                    <div>Goods Arrived At Destination Hub: Hindalco Bhilad</div>
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Faridabad</p>
                                                                    <p><b>Dropoff Address :</b>Hindalco Bhilad</p>
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dd_sec grey">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-4</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Faridabad to Hindalco Bhilad, Godown Delivery )</span>
                                                                <div class="hh_sec">
                                                                    <div>Goods delivered at Destination Hub: Hindalco Bhilad</div>
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Faridabad</p>
                                                                    <p><b>Dropoff Address :</b>Hindalco Bhilad</p>
                                                                    Proof of delivery generated on Thursday, February 3, 2022 5:55 PM
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td colSpan={2}>
                                                <div class="dd_sec orange">
                                                    <div>
                                                        <div class="btblock">
                                                            <span class="bold">HIL-DA-1</span>
                                                            <div class="popup_ss btm_ss">
                                                                <span class="bold">(from Hindalco Faridabad to Hindalco Bhilad, Godown Delivery)</span>
                                                                <div class="hh_sec">
                                                                    <div>Goods Dispatch for destination hub Hindalco Bhilad</div>
                                                                    <p><b>Transporter Name : </b>Express Logistic </p>
                                                                    <p><b>Vehicle No. :</b>HP12C6767</p>
                                                                    <p><b>Pickup Address :</b>Hindalco Faridabad</p>
                                                                    <p><b>Dropoff Address :</b>Hindalco Bhilad</p>
                                                                    <i></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            :""}
                           
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default DashboardView;


















