import React, { useEffect, useState } from 'react';
import bg from './../../static/images/registBg.png'
import Select from "react-dropdown-select";
import $ from 'jquery'
import history from './../../services/history';
import DocViewAddressModal from '../Dashboard/Modal/DocViewAddress'
import { useDispatch, useSelector } from 'react-redux'
import { getPlantsListing } from '../../state/redux/actions/index';
import axios from 'axios'

const TransRegister = (props) => {
    const dispatch = useDispatch()
    const [panShow, setPanShow] = useState(false)
    const [gstShow, setGstShow] = useState(false)
    const [base, setBase] = useState('')
    const [passwordType, setPasswordType] = useState("password")
    const [loading, setLoading] = useState(false)
    const listing = useSelector((state) => state.getPlantsListReducers.plantsData)
    const [tick, setTick] = useState(false)
    const [tokenObject, setTokenObject] = useState({ token: '' })
    const [transObject, setTransObject] = useState(
        {
            registrationType: "transporter",
            orgName: "",
            plantId: "",
            plantName: "test",
            ibaCode: "",
            ibaValidity: "",
            mobileNo: "",
            phone: "",
            emailId: "",
            fax: "",
            name: "",
            address: "",
            pan: "",
            panCopy: "",
            tan: "",
            city: "",
            state: "",
            country: "India",
            pinCode: "",
            gstin: "",
            gstinUpload: "",
            password: "",
            referralToken: "",
            permissions: {
                createUser: true,
                editUser: true,
                viewUser: true,
                editHub: true,
                disableUser: true,
                viewListing: true,
                addPlant: true,
                viewPlant: true,
                addStaff: true,
                deleteStaff: true,
                viewStaff: true,
                editStaff: true,
                addHub: true,
                deleteHub: true,
                viewHub: true,
                viewInvoice: true,
                createInvoice: true,
                editInvoice: true,
                addVehicle: true,
                viewVehicle: true,
                editVehicle: true,
                addDriver: true,
                viewDriver: true,
                editDriver: true,
                addAddress: true,
                editAddress: true,
                viewAddress: true,
                addContract: true,
                viewContract: true,
                viewProfile: true,
                editProfile: true,
                changePassword: true,
                viewSettingPanel: true,
                addSettingsInSettingPanel: true,
                editSettingPanel: true,
                viewDashboard: true,
                downloadReport: true,
                vehicleAllocation: true,
                viewBookingDetails: true,
                requestForGateInInspecPassed: true,
                requestForGateInInspecFailed: true,
                weighmentOfVehicle: true,
                vehicleInspection: true,
                driverInspection: true,
                gpsDeviceInspection: true,
                allowVehicleForGateIn: true,
                addClauseForInspection: true,
                waivePenalty: true,
                rejectDriver: true,
                addCustomerVehicle: true,
                addTransporter: true,
                viewTransporter: true,
                approveTransporter: true,
                rejectTransporter: true,
                allocateSalesOrderCustVehicle: true,
                uploadDocuments: true,
                bookingViewDetails: true,
                pickupDropOffLocation: true,
                addDepartment: true,
                viewDepartment: true,
                editOrganisation: true,
                addBooking: true,
                editPlant: true,
                editDepartment: true,
                GeneratePOD: true,
                ArrivedAtDestination: true,
                viewlr: true,
                editContract: true
            }

        }
    )
    const _handleChange = (e, item) => {
        if (item === 'plants') {
            var ind = e.target.value
            transObject.plantName = listing[ind].PlantName
            transObject.plantId = listing[ind]._id
            setTransObject(transObject)
        }
        setTransObject({ ...transObject, [e.target.name]: e.target.value })
    }
    const uploadFrontImage = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        const localArray = { ...transObject }
        if (item === 'pan') {
            localArray.panCopy = base64
            setPanShow(true)
            setBase(base64)
        }
        else if (item === 'gst') {
            localArray.gstinUpload = base64
            setGstShow(true)
            setBase(base64)
        }
    }
    const togglePassType = () => {
        if (passwordType == "password") {
            setPasswordType("text")
        }
        else {
            setPasswordType("password")
        }

    }
    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }

                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const register = () => {
        if (transObject.orgName === '' || transObject.tan === '' || transObject.ibaCode === '' || transObject.ibaValidity === '' || transObject.mobileNo === '' || transObject.emailId === '' || transObject.name === '' || transObject.pan === '' || transObject.state === '' || transObject.pinCode === '' || transObject.city === '' || transObject.address === '' || transObject.gstin === '') {
            window.$.fn.show_notification({ message: `*  fields mandatory.. `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            if (transObject.password === transObject.confirmPassword) {
                // setLoading(true)
                const token = localStorage.getItem('secretkey')
                const config = {
                    headers: { Authorization: token }
                };
                axios.post('https://www.betaenterprise.lynkit.io/api/user/register-admin', { ...transObject }, { headers: config.headers })
                    .then(response => {
                        console.log('Register user response', response);
                        const message = response.data.message
                        const error = response.data.error
                        if (error === true) {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            history.push('/');

                        }

                    })
                    .catch(error => {
                        // dispatch(showError(err))
                    })
            }
            else {
                window.$.fn.show_notification({ message: `Password does not match.. `, title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
        }

    }
    const _moveBack = () => {
        history.push('/');
    }
    function _handleClick(id) {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible");
        $(`#${id}`).addClass("custom-modal-is-visible");
    }
    useEffect(() => {
        if (transObject.password.length !== 0 && transObject.password === transObject.confirmPassword) {
            setTick(true)
        }
        else {
            setTick(false)
        }

        const query = new URLSearchParams(props.location.search);
        const getToken = query.get('token')
        tokenObject.token = getToken

        axios.post('https://www.betaenterprise.lynkit.io/api/user/verify-referral', { ...tokenObject })
            .then(response => {
                console.log('token response', response);
                const tokenResponse = response.data.data
                const err = response.data.error
                const message = response.data.message

                if (err === false) {
                    transObject.plantId = tokenResponse.PlantId
                    transObject.referralToken = getToken
                }
                else if (err === true) {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                    history.push('/');
                }

            })

            .catch(error => {
                console.log('err', error);
            })

    }, [])

    return (

        <div className="registrationElem">
            <div className='registrationR'>
                <button className='newregisterBack' onClick={() => _moveBack()}>Back</button>
                <div className='regInner'>
                    <div className='regRow alignRow'>
                        <div className='reg3'>
                            <span className='regHeading'>Org Name<span style={{ color: 'red' }}> *</span> :</span>
                            <div className='regSelect'>
                                <input className='regIn' name='orgName' value={transObject.orgName} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter organistaion name' />
                            </div>
                        </div>

                        <div className='reg3'>
                            <span className='regHeading'>NAME<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='name' value={transObject.name} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter Name' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>EMAIL ID<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='emailId' value={transObject.emailId} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter your email id' />
                            </div>
                        </div>
                    </div>

                    <div className='regRow alignRow'>
                        <div className='reg3'>
                            <span className='regHeading'>ADDRESS<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='address' value={transObject.address} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter Address' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>MOBILE NUMBER<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='mobileNo' value={transObject.mobileNo} onChange={(e) => _handleChange(e)} type='number' placeholder='Enter mobile number' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>PHONE NUMBER</span>
                            <div className='regSelect'>
                                <input className='regIn' name='phone' value={transObject.phone} onChange={(e) => _handleChange(e)} type='number' placeholder='Enter phone number code' />
                            </div>
                        </div>
                    </div>
                    <div className='regRow alignRow'>
                        <div className='reg3'>
                            <span className='regHeading'>FAX</span>
                            <div className='regSelect'>
                                <input className='regIn' name='fax' value={transObject.fax} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter Fax no.' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>IBA CODE<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='ibaCode' value={transObject.ibaCode} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter IBA code' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>IBA VAIDITY<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='ibaValidity' value={transObject.ibaValidity} onChange={(e) => _handleChange(e)} type='date' placeholder='Enter IBA validity date' />
                            </div>
                        </div>
                    </div>
                    <div className='regRow alignRow'>
                        <div className='reg50'>
                            <span className='regHeading'>PAN<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='pan' value={transObject.pan} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter pan number' />
                                <div className='uploadElement topPad'>
                                    <i className="fa fa-upload transupload"></i>
                                    <input className='fileType' type='file' onChange={(e) => uploadFrontImage(e, 'pan')} />
                                </div>
                                {panShow === true ?
                                    <i className="fa fa-eye transEYE topPadEye" onClick={() => { _handleClick('DocViewAddressModal') }} ></i>
                                    : ''}
                            </div>
                        </div>
                        <div className='reg50'>
                            <span className='regHeading'>GSTIN<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='gstin' value={transObject.gstin} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter GSTIN number' />
                                <div className='uploadElement topPad'>
                                    <i className="fa fa-upload transupload"></i>
                                    <input className='fileType' type='file' onChange={(e) => uploadFrontImage(e, 'gst')} />
                                </div>
                                {gstShow === true ?
                                    <i className="fa fa-eye transEYE topPadEye" onClick={() => { _handleClick('DocViewAddressModal') }} ></i>
                                    : ''}
                            </div>
                        </div>
                    </div>
                    <div className='regRow alignRow'>
                        <div className='reg3'>
                            <span className='regHeading'>State<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='state' value={transObject.state} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter state' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>City<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='city' value={transObject.city} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter city' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>PinCode<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='pinCode' value={transObject.pinCode} onChange={(e) => _handleChange(e)} type='number' placeholder='Enter pin code' />
                            </div>
                        </div>
                    </div>
                    <div className='regRow alignRow'>
                        <div className='reg3'>
                            <span className='regHeading'>TAN<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='tan' value={transObject.tan} onChange={(e) => _handleChange(e)} type='text' placeholder='Enter tan number' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>PASSWORD<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='password' value={transObject.password} onChange={(e) => _handleChange(e)} type={passwordType} placeholder='Enter your password' />
                                {passwordType === 'password' ?
                                    <i className="fa fa-eye-slash iconEye transEYE topPadd" onClick={togglePassType}></i>
                                    :
                                    <i onClick={togglePassType} className="fa fa-eye iconEye transEYE" ></i>
                                }
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>CONFIRM PASSWORD<span style={{ color: 'red' }}> *</span></span>
                            <div className='regSelect'>
                                <input className='regIn' name='confirmPassword' value={transObject.confirmPassword} onChange={(e) => _handleChange(e)} type={passwordType} placeholder='Confirm password' />
                                {tick === true ?
                                    <i className="fa fa-check match myVisibleEyeBlack transEYE topPadd"></i>
                                    : ''}
                            </div>
                        </div>
                    </div>

                    <div className='regRow alignCen'>
                        <button className='registerNowBtn' onClick={() => register()}>Register</button>
                        {loading === true ?
                            <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                            : ''}
                    </div>
                </div>
            </div>
            <div className='registrationL'>
                <img src={bg} alt='' />
                <div className='regOver'>
                    <span>Transporter <br />Registration Form</span>
                </div>
            </div>
            <DocViewAddressModal closeModal={_closeModal} imageData={base} />
        </div>
    )
}
export default TransRegister;