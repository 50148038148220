import { pagination } from "../actions/booking";
import {
    LISTING_DATA, PAGINATION, DRIVER_LIST, STAFF_LIST, SHOW_VEHICLE_LOADER, ADDRESS_LIST, HIDE_VEHICLE_LOADER,
    TRANS_PAGE, TRANS_RECORD, HUB_LIST, TRANSPORTER_LIST, RECORDS, PAGINATION_STAFF, RECORDS_STAFF
} from "../actions/types";

const initialState = {
    listing: [],
    driverListing: [],
    staffListing: [],
    hubListing: [],
    addressListing: [],
    transporterListing: [],
    bookmarks: [],
    records: '',
    transBookmarks: [],
    transRecords: '',
    loading: true,
    staffBook: [],
    staffRecords: []
}

const getListingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case LISTING_DATA: return {
            ...state,
            listing: payload
        }
        case PAGINATION:
            state.bookmarks.push(payload)
            return {
                ...state,
                bookmarks: state.bookmarks
            }
        case RECORDS:
            return {
                ...state,
                records: payload
            }
        // staff pagination record
        case PAGINATION_STAFF:
            state.staffBook.push(payload)
            return {
                ...state,
                staffBook: state.staffBook
            }
        case RECORDS_STAFF:
            return {
                ...state,
                staffRecords: payload
            }
        // transporter pagination
        case TRANS_PAGE:
            state.transBookmarks.push(payload)
            return {
                ...state,
                transBookmarks: state.transBookmarks
            }
        case TRANS_RECORD:
            return {
                ...state,
                transRecords: payload
            }
        case SHOW_VEHICLE_LOADER: return {
            ...state,
            loading: true
        }
        case HIDE_VEHICLE_LOADER: return {
            ...state,
            loading: false
        }
        case DRIVER_LIST: return {
            ...state,
            driverListing: payload
        }
        case STAFF_LIST: return {
            ...state,
            staffListing: payload
        }
        case HUB_LIST: return {
            ...state,
            hubListing: payload
        }
        case ADDRESS_LIST: return {
            ...state,
            addressListing: payload
        }
        case TRANSPORTER_LIST: return {
            ...state,
            transporterListing: payload
        }
        default: return state;
    }
}
export default getListingReducer 