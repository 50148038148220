import {GET_DRIVER} from  "../actions/types";

const initialState={
    driverData: []
} 

const getDriverReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_DRIVER : return{
            ...state,
            driverData:payload
        }
        default: return state;  
    }
}
export default getDriverReducer