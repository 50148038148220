import { PLANTS_LIST, PLANTS_HIDE, PLANTS_SUCCESS, PAGINATION, PLANTS_RECORDS, MESSAGE } from "../actions/types";

const initialState = {
    plantsData: [],
    loading: true,
    bookmarks: [],
    records: '',
    message: ''
}

const getPlantsListReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case PLANTS_LIST: return {
            ...state,
            plantsData: payload
        }
        case PLANTS_SUCCESS: return {
            ...state,
            loading: false
        }
        case PLANTS_HIDE: return {
            ...state,
            loading: true
        }
        case PAGINATION:
            state.bookmarks.push(payload)
            return {
                ...state,
                bookmarks: state.bookmarks
            }
        case PLANTS_RECORDS:
            return {
                ...state,
                records: payload
            }
        case MESSAGE:
            return {
                ...state,
                message: payload
            }
        default: return state;
    }
}
export default getPlantsListReducers;