import { post } from './../../../services/requests'
import { GET_CLAUSE , HIDE_CONTRACT} from './types'

export const getClause = (ContractId) =>{ 
    return(dispatch)=>{
        post(`contract/getclauses` , {ContractId })
        .then(async response=>{
             const getClauseData =await response.data
             const error =response.error
             const message =response.message
             
             if(error === false && message !== 'Clauses Not Found'){
                dispatch(storeGetClause(getClauseData))
                dispatch(hideContract())
             }
             else{
                dispatch(storeGetClause(''))
                // window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                //     if (result.type === 'confirm') {
                //     }
                // })
             }
            
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
} 
export const storeGetClause = (getClauseData) => {
    return {
        type: GET_CLAUSE,
        payload: getClauseData
    }
}
export const hideContract = () => {
    return {
        type: HIDE_CONTRACT
    }
}

