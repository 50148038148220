import { post } from './../../../services/requests'
import {
    BOOKING, BOOK_SHOW_LOADER, BOOK_HIDE_LOADER, INSPECTION, GATEINALLOWED, GATEIN, WEIGHMENTIN, WEIGHMENTOUT, DOCREADY, LOGISTICS, MATERIALS, ARRIVED, BOOKINGRECORD, TRUCKPAGINATION} from './types'

const pageSize = 5
export const BookingAction = (sharedData) => {
    if (sharedData.status === 'truck-reporting') {
        return (dispatch) => {
            post('booking/GetAllBooking', {
                pageSize: pageSize,
                bookmark: sharedData.bookmark,
                vehicleStatus: "truckReporting",
                search: sharedData.search,
                searchBy: sharedData.searchBy,
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: sharedData.vehicleBelongsTo,
                transporterId : sharedData.transporterId

            })
           
                .then(async response => {
                    const data = await response.data
                    if (data && data.length === 0 ) {
                    }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        
                        dispatch(storeBookingLists(data))
                        dispatch(getRecordCount(records))
                        if (sharedData.param === "next" || sharedData.param === "") {
                            dispatch(pagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (sharedData.status === 'inspection') {
        return (dispatch) => {
            post('booking/GetAllBooking', {
                pageSize: pageSize,
                bookmark: sharedData.bookmark,
                vehicleStatus: "vehicleInspection",
                search: sharedData.search,
                searchBy: sharedData.searchBy,
                inspectionStatus: sharedData.inspectionStatus,
                inspectionType: "GateIn",
                vehicleBelongsTo: sharedData.vehicleBelongsTo,
                transporterId : sharedData.transporterId
            })
                .then(async response => {
                    const data = await response.data
                    if (data && data.length === 0  ) {
                    }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(storeInpectionLists(data))
                        dispatch(getRecordCount(records))
                        if (sharedData.param === "next" || sharedData.param === "") {
                            dispatch(pagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (sharedData.status === 'gate-in-allowed') {
        return (dispatch) => {
            post('booking/GetAllBooking', {
                pageSize: pageSize,
                bookmark: sharedData.bookmark,
                vehicleStatus: "gateInRequest",
                search: sharedData.search,
                searchBy: sharedData.searchBy,
                inspectionStatus: "",
                inspectionType: "GateIn",
                vehicleBelongsTo: sharedData.vehicleBelongsTo,
                transporterId : sharedData.transporterId
            })
                .then(async response => {
                    const data = await response.data
                    if (data && data.length === 0 ) {
                    }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(gateinallowedata(data))
                        dispatch(getRecordCount(records))
                        if (sharedData.param === "next" || sharedData.param === "") {
                            dispatch(pagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (sharedData.status === 'gatein') {
        return (dispatch) => {
            post('booking/GetAllBooking', {
                pageSize: pageSize,
                bookmark: sharedData.bookmark,
                vehicleStatus: "gatedIn",
                search: sharedData.search,
                searchBy: sharedData.searchBy,
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: sharedData.vehicleBelongsTo,
                transporterId : sharedData.transporterId
            })
                .then(async response => {
                    const data = await response.data
                    dispatch(bookShowLoader())
                    if (data && data.length === 0 ) {
                        
                    }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(gateInFun(data))
                        dispatch(getRecordCount(records))
                        if (sharedData.param === "next" || sharedData.param === "") {
                            dispatch(pagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (sharedData.status === 'weighment-in') {
        return (dispatch) => {
            post('booking/GetAllBooking', {
                pageSize: pageSize,
                bookmark: sharedData.bookmark,
                vehicleStatus: "weighmentIn",
                search: sharedData.search,
                searchBy: sharedData.searchBy,
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: sharedData.vehicleBelongsTo,
                transporterId : sharedData.transporterId
            })
                .then(async response => {
                    console.log(' weighment-in response', response);
                    const data = await response.data
                    if (data && data.length === 0 ) {
                       
                    }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(weighmentInFun(data))
                        dispatch(getRecordCount(records))
                        if (sharedData.param === "next" || sharedData.param === "") {
                            dispatch(pagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (sharedData.status === 'weighment-out') {
        return (dispatch) => {
            post('booking/GetAllBooking', {
                pageSize: pageSize,
                bookmark: sharedData.bookmark,
                vehicleStatus: "weighmentOut",
                search: sharedData.search,
                searchBy: sharedData.searchBy,
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: sharedData.vehicleBelongsTo,
                transporterId : sharedData.transporterId
            })
                .then(async response => {
                    const data = await response.data
                    dispatch(bookShowLoader())
                    if (data && data.length === 0 ) {
                        
                    }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(weighmentOutFun(data))
                        dispatch(getRecordCount(records))
                        if (sharedData.param === "next" || sharedData.param === "") {
                            dispatch(pagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (sharedData.status === 'document-ready') {
        return (dispatch) => {
            post('booking/GetAllBooking', {
                pageSize: pageSize,
                bookmark: sharedData.bookmark,
                vehicleStatus: "documentReady",
                search: sharedData.search,
                searchBy: sharedData.searchBy,
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: sharedData.vehicleBelongsTo,
                transporterId : sharedData.transporterId
            })
                .then(async response => {
                    const data = await response.data
                    if (data && data.length === 0) {
                    }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(docreadylist(data))
                        dispatch(getRecordCount(records))
                        if (sharedData.param === "next" || sharedData.param === "") {
                            dispatch(pagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (sharedData.status === 'logistics-gate-out') {
        return (dispatch) => {
            post('booking/GetAllBooking', {
                pageSize: pageSize,
                bookmark: sharedData.bookmark,
                vehicleStatus: "logisticsGateOut",
                search: sharedData.search,
                searchBy: sharedData.searchBy,
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: sharedData.vehicleBelongsTo,
                transporterId : sharedData.transporterId
            })
                .then(async response => {
                    const data = await response.data
                    if (data && data.length === 0) {
                    }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(logisticlist(data))
                        dispatch(getRecordCount(records))
                        if (sharedData.param === "next" || sharedData.param === "") {
                            dispatch(pagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (sharedData.status === 'material-gate-out') {
        return (dispatch) => {
            post('booking/GetAllBooking', {
                pageSize: pageSize,
                bookmark: sharedData.bookmark,
                vehicleStatus: "materialsGateOut",
                search: sharedData.search,
                searchBy: sharedData.searchBy,
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: sharedData.vehicleBelongsTo,
                transporterId : sharedData.transporterId
            })
                .then(async response => {
                    const data = await response.data
                    if (data && data.length === 0) {
                    }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(materiallist(data))
                        dispatch(getRecordCount(records))
                        if (sharedData.param === "next" || sharedData.param === "") {
                            dispatch(pagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (sharedData.status === 'arrived') {
        return (dispatch) => {
            post('booking/GetAllBooking', { pageSize: pageSize, bookmark: sharedData.bookmark, vehicleStatus: "inspection" })
                .then(async response => {
                    console.log(' arrived response', response);
                    const data = await response.data
                    if (data === undefined && data === [] && data.length === 0) { }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(arrivedlist(data))
                        dispatch(getRecordCount(records))
                        if (sharedData.param === "next" || sharedData.param === "") {
                            dispatch(pagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else { }
}
export const storeBookingLists = (data) => {
    return {
        type: BOOKING,
        payload: data
    }
}
export const storeInpectionLists = (data) => {
    return {
        type: INSPECTION,
        payload: data
    }
}
export const gateinallowedata = (data) => {
    return {
        type: GATEINALLOWED,
        payload: data
    }
}
export const gateInFun = (data) => {
    return {
        type: GATEIN,
        payload: data
    }
}
export const weighmentInFun = (data) => {
    return {
        type: WEIGHMENTIN,
        payload: data
    }
}
export const weighmentOutFun = (data) => {
    return {
        type: WEIGHMENTOUT,
        payload: data
    }
}
export const docreadylist = (data) => {
    return {
        type: DOCREADY,
        payload: data
    }
}
export const logisticlist = (data) => {
    return {
        type: LOGISTICS,
        payload: data
    }
}
export const materiallist = (data) => {
    return {
        type: MATERIALS,
        payload: data
    }
}
export const arrivedlist = (data) => {
    return {
        type: ARRIVED,
        payload: data
    }
}
// truck reproting pagination
export const getRecordCount = (records) => {
    return {
        type: BOOKINGRECORD,
        payload: records
    }
}
export const pagination = (bookmark) => {
    return {
        type: TRUCKPAGINATION,
        payload: bookmark
    }
}

// Loader
export const bookShowLoader = () => {
    return {
        type: BOOK_SHOW_LOADER,
    }
}
export const bookHideLoader = () => {
    return {
        type: BOOK_HIDE_LOADER,
    }
}

// EMPTY

// export const emptyState = () => {
//     return {
//         type: EMPTY,
//         payload:[]
//     }
// }