import React from 'react';

const SimpleText = (props) =>{ 
    const { ele , mainIndex , inputValueHandler, removeItem } = props;

    return(
        <>
            <div className='FieldElem alignCenter'>
            <i className="fa fa-minus absMius" onClick={(e)=>removeItem({e,index: mainIndex})}></i>
                <input className='simpleText' type='text' value={ele.value} placeholder={ele.placeholder} onChange={(e) => inputValueHandler({ e, index: mainIndex })}/>
            </div>
        </>

    ) 
}

export default SimpleText;