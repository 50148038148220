import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import axios from 'axios'
import history from '../../../services/history';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getPlantsListing, getAllContracts } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select';

const AddBooking = (props) => {
    const [startDate, setStartDate] = useState();
    const [trStartDate, setTrStartDate] = useState();
    const dispatch = useDispatch()
    const transportersList = props.transportersList
    const plantListing = useSelector((state) => state.getPlantsListReducers.plantsData)
    const contractList = useSelector((state) => state.getContractReducers.contractData)
    const [bookingState, setBookingState] = useState(
        {
            transporterId: "",
            shipmentGID: "",
            referenceShipGID: "",
            transportModeGID: "",
            serviceProviderGID: "",
            totalWeight: "",
            totalWeightUOMCode: "",
            shipQuantityAccepted: "",
            sourceLocationGID: "",
            destinationLocationGID: "",
            planningType: "",
            startTime: "",
            soNo: "",
            goodsType: "",
            truckReportingTime: "",
            loadableCapacity: "0",
            contractId: "",
            action: "truckReporting",
            plantId: "",
            plantName: "",
            plantAddress: ""
        }
    )

    const _onChange = (e, item) => {
        if (item === 'transporter') {
            bookingState.transporterId = transportersList[e.target.value]._id
        }
        else if (item === 'plants') {
            bookingState.plantId = plantListing[e.target.value]._id
            bookingState.plantName = plantListing[e.target.value].PlantName
            bookingState.plantAddress = plantListing[e.target.value].Address
        }
        else if (item === 'contract') {
            bookingState.contractId = contractList[e.target.value].ContractId
        }
        else {
            bookingState.startTime = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(startDate)
            bookingState.truckReportingTime = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(trStartDate)
            setBookingState({ ...bookingState, [e.target.name]: e.target.value })
        }
    }
    const addBooking = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if (bookingState.shipmentGID === '' || bookingState.referenceShipGID === '' || bookingState.startTime === '' || bookingState.transportModeGID === '' || bookingState.serviceProviderGID === '' || bookingState.totalWeight === '' || bookingState.totalWeightUOMCode === '' || bookingState.shipQuantityAccepted === '' || bookingState.destinationLocationGID === '' || bookingState.startTime === '') {
            window.$.fn.show_notification({ message: `Please fill all mandatory Details! `, title: 'Alert!!', type: 'confirm', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            axios.post('https://www.betaenterprise.lynkit.io/api/booking/booking', { ...bookingState }, { headers: config.headers })
                .then(response => {
                    // setLoading(true)
                    const message = response.data.message
                    const err = response.data.error
                    if (err === false) {
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                        setBookingState({
                            transporterId: "",
                            shipmentGID: "",
                            referenceShipGID: "",
                            transportModeGID: "",
                            serviceProviderGID: "",
                            totalWeight: "",
                            totalWeightUOMCode: "",
                            shipQuantityAccepted: "",
                            sourceLocationGID: "",
                            destinationLocationGID: "",
                            planningType: "",
                            startTime: "",
                            soNo: "",
                            goodsType: "",
                            truckReportingTime: "",
                            loadableCapacity: "",
                            contractId: "",
                            action: "truckReporting",

                        })
                    }
                    window.$.fn.show_notification({ message: `${message} `, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                    window.location.reload();


                })
                .catch(error => {
                    // setLoading(false)
                    console.log('error', error);
                    window.$.fn.show_notification({ message: `Some error occured.. `, title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })

                })
        }
    }
    useEffect(() => {
        // dispatch(listingApi({
        //     docType: "transporter",
        //     bookmark: "",
        //     param: "next"
        // }))
        dispatch(getPlantsListing({
            docType: "plants",
            bookmark: '',
            param: "next"
        }))
        dispatch(getAllContracts({
            docType: "contracts",
            bookmark: '',
            param: "next"
        }))
    }, [dispatch])

    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white',
            width: '100%',

        }),
        control: (styles, state) => {
            return {
                ...styles,
                backgroundColor: "transparent",


                boxShadow:
                    state.isFocused
                        ? "0 0 3px #28a745"
                        : state.isFocused
                            ? "0 0 3px #dc3545"
                            : "none",
                cursor: "pointer",
                ":focus": {
                    ...styles[":focus"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                ":active": {
                    ...styles[":active"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },

                ":hover": {
                    ...styles[":hover"],
                    // borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                height: 35,
                minHeight: 35,
            };
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
            ...styles,
            background: isFocused ? '#ff7200' : isSelected ? '#fff' : undefined,
            color: isFocused ? '#fff' : 'black',
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? "var(--clr--accent)"
                        : undefined
                    : undefined,
            },
            zIndex: 1
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '35px',
            margin: '-8px 0 0 0'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '35px',
        }),


    }
    const options = [];
    transportersList && transportersList.map((item) => {
        options.push({ value: item._id, label: item.OrgName });
    });

    const _handleTrans = (item) => {
        let temp ={...bookingState}
        temp.transporterId = item.value
        setBookingState(temp)
    }

    const plantName = [];
    plantListing && plantListing.map((item) => {
        plantName.push({ value: item._id, label: item.PlantName, address: item.Address });
    });

    const _handlePlantChange = (item) => {
        let temp ={...bookingState}
        temp.plantId = item.value
        temp.plantName = item.label
        temp.plantAddress = item.address
        setBookingState(temp)
    }

    const contractArray = [];
    contractList && contractList.map((item) => {
        contractArray.push({ value: item.ContractId, label: item.ContractName });
    });

    const _handleContractChange = (item) => {
        let temp ={...bookingState}
        temp.contractId = item.value
        setBookingState(temp)
    }

    return (
        <section id="AddBookingModal" className="custom-modal" style={{ width: '75vw', maxHeight: '85vh' }}>
            <div className="custom-modal-header">
                <h1>Add Shipment Booking</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                <div className='bookingElem'>
                    <div className='bookingElemRow'>
                        <div className='bookingElemI reactSelect1'>
                            <span>Transporter Name <span className='Asteric'>*</span></span>
                            <Select
                                onChange={(value) => _handleTrans(value)}
                                options={options}
                                styles={colourStyles}
                                
                            />
                        </div>
                        <div className='bookingElemI reactSelect1'>
                            <span>Plant Name <span className='Asteric'>*</span></span>
                            <Select
                                onChange={(value) => _handlePlantChange(value)}
                                options={plantName}
                                styles={colourStyles}
                                
                            />
                            {/* <select defaultValue='default' className='transBooking' onChange={(e) => _onChange(e, 'plants')}>
                                <option value='default'>Select Plant</option>
                                {plantListing !== undefined ?
                                    plantListing.map((item, index) => {
                                        return (
                                            <option value={index} key={index} >{item.PlantName}</option>
                                        )
                                    })
                                    : ''}
                            </select> */}
                        </div>
                        <div className='bookingElemI reactSelect1'>
                            <span>Contract Name <span className='Asteric'>*</span></span>
                            <Select
                                onChange={(value) => _handleContractChange(value)}
                                options={plantName}
                                styles={colourStyles}
                                
                            />
                            
                            {/* <select defaultValue='default' className='transBooking' onChange={(e) => _onChange(e, 'contract')}>
                                <option value='default'>Select Contract</option>
                                {contractList !== undefined ?
                                    contractList.map((item, index) => {
                                        return (
                                            <option value={index} key={index} >{item.ContractName}</option>
                                        )
                                    })
                                    : ''}
                            </select> */}
                        </div>
                    </div>
                    <div className='bookingElemRow'>
                        <div className='bookingElemI'>
                            <span>SO No <span className='Asteric'>*</span></span>
                            <input name='soNo' value={bookingState.soNo} onChange={(e) => _onChange(e)} type='text' placeholder='Enter So Number' />
                        </div>
                        <div className='bookingElemI'>
                            <span>Shipment  GID <span className='Asteric'>*</span></span>
                            <input name='shipmentGID' value={bookingState.shipmentGID} onChange={(e) => _onChange(e)} type='text' placeholder='Enter Shipment GID' />
                        </div>
                        <div className='bookingElemI'>
                            <span>Reference Shipment  GID <span className='Asteric'>*</span></span>
                            <input name='referenceShipGID' value={bookingState.referenceShipGID} onChange={(e) => _onChange(e)} type='text' placeholder='Enter Reference Shipment GID' />
                        </div>
                    </div>
                    <div className='bookingElemRow'>
                        <div className='bookingElemI'>
                            <span>Transport Mode GID <span className='Asteric'>*</span></span>
                            <input name='transportModeGID' value={bookingState.transportModeGID} onChange={(e) => _onChange(e)} type='text' placeholder='Enter Transport Mode GID' />
                        </div>
                        <div className='bookingElemI'>
                            <span>Service Provider GID <span className='Asteric'>*</span></span>
                            <input name='serviceProviderGID' value={bookingState.serviceProviderGID} onChange={(e) => _onChange(e)} type='text' placeholder='Enter Service Provider GID' />
                        </div>
                        <div className='bookingElemI'>
                            <span>Total Weight <span className='Asteric'>*</span></span>
                            <input name='totalWeight' value={bookingState.totalWeight} onChange={(e) => _onChange(e)} type='number' placeholder='Enter Total Weight' />
                        </div>
                    </div>
                    <div className='bookingElemRow'>
                        {/* <div className='bookingElemI'>
                            <span>loadable Capacity<span className='Asteric'>*</span></span>
                            <input name='loadableCapacity' value={bookingState.loadableCapacity} onChange={(e) => _onChange(e)} type='number' placeholder='Enter Loadable Capacity' />
                        </div> */}
                        <div className='bookingElemI'>
                            <span>Total Weight UOM Code <span className='Asteric'>*</span></span>
                            <input name='totalWeightUOMCode' value={bookingState.totalWeightUOMCode} onChange={(e) => _onChange(e)} type='text' placeholder='Enter Total Weight UOM Code' />
                        </div>
                        <div className='bookingElemI'>
                            <span>Ship Quantity Accepted <span className='Asteric'>*</span></span>
                            <input name='shipQuantityAccepted' value={bookingState.shipQuantityAccepted} onChange={(e) => _onChange(e)} type='number' placeholder='Enter Ship Quantity Accepted' />
                        </div>
                        <div className='bookingElemI'>
                            <span>Source Location GID(optional) </span>
                            <input name='sourceLocationGID' value={bookingState.sourceLocationGID} onChange={(e) => _onChange(e)} type='text' placeholder='Enter Source Location GID' />
                        </div>
                    </div>
                    <div className='bookingElemRow'>
                        <div className='bookingElemI'>
                            <span>Destination Location GID <span className='Asteric'>*</span></span>
                            <input name='destinationLocationGID' value={bookingState.destinationLocationGID} onChange={(e) => _onChange(e)} type='text' placeholder='Enter Destination Location GID' />
                        </div>
                        <div className='bookingElemI'>
                            <span>Planning Type </span>
                            <input name='planningType' value={bookingState.planningType} onChange={(e) => _onChange(e)} type='text' placeholder='Enter Planning Type' />
                        </div>
                        <div className='bookingElemI'>
                            <span>Start Time <span className='Asteric'>*</span></span>
                            <DatePicker
                                className='datePickerClass'
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                showTimeSelect
                                dateFormat="MM/dd/yyyy h:mm aa"
                                placeholder='Enter Start Time'
                            />
                            {/* <input name='startTime' value={bookingState.startTime} onChange={(e) => _onChange(e)} type='time' placeholder='Enter Start Time' /> */}
                        </div>
                    </div>
                    <div className='bookingElemRow'>

                        <div className='bookingElemI'>
                            <span>No. of Vehicles <span className='Asteric'>*</span></span>
                            <input type='text' placeholder='Enter No. of Vehicles' />
                        </div>
                        <div className='bookingElemI'>
                            <span>Truck Reporting Time</span>
                            <DatePicker
                                className='datePickerClass'
                                selected={trStartDate}
                                onChange={(date) => setTrStartDate(date)}
                                showTimeSelect={true}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                placeholder='Enter Truck Reporting time Time'
                            />
                        </div>
                        <div className='bookingElemI'>
                            <span>Vehicle Type</span>
                            <input type='text' placeholder='Enter Vehicle Type' />
                        </div>
                    </div>
                    <div className='bookingElemRow'>

                        <div className='bookingElemI'>
                            <span>Goods Type</span>
                            <input name='goodsType' value={bookingState.goodsType} onChange={(e) => _onChange(e)} type='text' placeholder='Enter Goods Type' />
                        </div>
                        <div className='bookingElemI'>
                        </div>
                        <div className='bookingElemI'>
                        </div>
                    </div>

                    <div className='bookingElemRow flexCenter'>
                        <button className='addBookingBtn' onClick={() => addBooking()}>Add</button>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default AddBooking;