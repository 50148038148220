import { useState, useEffect, useRef } from "react";
import history from "../../services/history";
import $ from "jquery";
import UploadContract from "./../Dashboard/Modal/UploadContract";
import {
  getContractDetail,
  getClause,
  getImages,
} from "../../state/redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ContractClauses from "./ContractClauses";
import axios from "axios";
import DocumentModal from "./../Dashboard/Modal/DocumentModal";
import { resetBase } from "../../state/redux/actions/getImage";

const ContractDetail = (props) => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const getIndex = props.location.state.index;

  const contractData = useSelector(
    (state) => state.getContractDetailRed
  ).contractData;
  const imageData = useSelector((state) => state.getImgBase.imgBaseArr);
  const clauseLists = useSelector((state) => state.getClauseRed.getClause);
  const [btnState1, setBtnState1] = useState("transporter");
  const [sortedArr, setSortedArr] = useState([]);
  const [base, setBase] = useState("");
  const [attachedDocuments, setAttachedDocuments] = useState([]);

  const [userGroup, setUserGroup] = useState("");
  const [contractStatus, setContractStatus] = useState(false);
  const [condition, setCondition] = useState(false);
  const [contractState, setContractState] = useState({
    id: "",
    ContractId: "",
    ContractName: "",
    ContractType: "",
    StartDate: "",
    EndDate: "",
    TransporterName: "",
    TransporterAddress: "",
    TransporterUserName: "",
    TransporterEmailId: "",
    PlantName: "",
    PlantAddress: "",
    Documents: [],
  });

  const _handleClick = (id) => {
    $(".custom-modal-overlay").addClass("custom-modal-is-visible");
    $(`#${id}`).addClass("custom-modal-is-visible");
  };
  const _closeModal = () => {
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible");
    $(".custom-modal").removeClass("custom-modal-is-visible");
  };
  const _goBack = () => {
    history.goBack();
    clauseLists.length = 0;
  };
  const slideFun = (item) => {
    setBtnState1(item);
  };
  const sortValues = () => {
    const unique = [
      ...new Set(clauseLists && clauseLists.map((item) => item.Category)),
    ];
    let newlist = [];
    unique.forEach((element) => {
      let tmp = [];
      let tmps = clauseLists.map((item) => {
        if (item.Category == element) tmp.push(item);
        return;
      });
      newlist.push({
        Category: element,
        clause: tmp,
      });
    });
    setSortedArr(newlist);
  };
  const viewEdit = (item) => {
    history.push({
      pathname: "/dashboard/Contracts/Create-Formula",
      state: item,
    });
  };
  const expandElement = () => {
    if ($(".addClauseUi").hasClass("close")) {
      $(".addClauseUi").removeClass("close");
      $(".addClauseUi").addClass("open");
    } else {
      $(".addClauseUi").addClass("close");
      $(".addClauseUi").removeClass("open");
    }
  };
  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth" });

  const setStatus = () => {
    if ($(".contractDetailSpan4").hasClass("cbdhide")) {
      $(".contractDetailSpan4").removeClass("cbdhide");
      $(".contractDetailSpan4").addClass("bdhide");
      $(".contractDetailSpan2").removeClass("cbdhide");
      $(".contractDetailSpan2").addClass("bdhide");
      setContractStatus(true);
      let dummy = { ...contractState };
      dummy.id = contractData._id;
      dummy.ContractId = contractData.ContractId;
      dummy.ContractName = contractData.ContractName;
      dummy.ContractType = contractData.ContractType;
      dummy.StartDate = contractData.StartDate;
      dummy.EndDate = contractData.EndDate;
      dummy.TransporterName = contractData.TransporterName;
      dummy.TransporterAddress = contractData.TransporterAddress;
      // dummy.TransporterUserName = contractData._id
      // dummy.TransporterEmailId = contractData._id
      dummy.PlantName = contractData.PlantName;
      dummy.PlantAddress = contractData.PlantAddress;
      dummy.Documents = contractData.AttachedDocuments;
      setContractState(dummy);
    } else {
      $(".contractDetailSpan4").removeClass("bdhide");
      $(".contractDetailSpan4").addClass("cbdhide");
      $(".contractDetailSpan2").removeClass("bdhide");
      $(".contractDetailSpan2").addClass("cbdhide");
      setContractStatus(false);
      setContractState({
        id: "",
        ContractId: "",
        ContractName: "",
        ContractType: "",
        StartDate: "",
        EndDate: "",
        TransporterName: "",
        TransporterAddress: "",
        TransporterUserName: "",
        TransporterEmailId: "",
        PlantName: "",
        PlantAddress: "",
        Documents: [],
      });
    }
  };
  const _onUpdate = (e) => {
    setContractState({ ...contractState, [e.target.name]: e.target.value });
  };
  const updateContractFun = () => {
    const finalObj = {
      id: contractState.id,
      contractId: contractState.ContractId,
      contractName: contractState.ContractName,
      contractType: contractState.ContractType,
      startDate: contractState.StartDate,
      endDate: contractState.EndDate,
      transporterName: contractState.TransporterName,
      transporterAddress: contractState.TransporterAddress,
      // transporterUserName:contractState.ContractId,
      // transporterEmailId: contractState.ContractId,
      plantName: contractState.PlantName,
      plantAddress: contractState.PlantAddress,
      documents: contractState.Documents,
    };
    const token = localStorage.getItem("secretkey");
    const config = {
      headers: { Authorization: token },
    };
    axios
      .post(
        "https://www.betaenterprise.lynkit.io/api/contract/UpdateContract",
        { ...finalObj },
        { headers: config.headers }
      )
      .then((response) => {
        const err = response.data.error;
        const message = response.data.message;
        dispatch(getContractDetail());
        window.$.fn.show_notification(
          {
            message: `${message}`,
            title: "Message!!",
            autohide: true,
            showInput: false,
          },
          (result) => {
            if (result.type === "confirm") {
            }
          }
        );
        if (err === false) {
          $(".custom-modal-overlay").removeClass("custom-modal-is-visible");
          $(".custom-modal").removeClass("custom-modal-is-visible");
        }
      })
      .catch((error) => {
        console.log("err", error);
      });
  };
  useEffect(() => {
    setUserGroup(
      localStorage.getItem("userGroup") === "transporter" ? "2" : "1"
    );
    dispatch(resetBase());
    dispatch(getContractDetail(getIndex));
    dispatch(getClause(getIndex));
  }, []);

  useEffect(() => {
    sortValues();
    console.log(clauseLists);
  }, [clauseLists]);

  useEffect(() => {
    if (imageData.length !== 0) {
      setAttachedDocuments([]);
      let temp = [...imageData];
      setAttachedDocuments(temp);
    }
  }, [imageData]);

  const deleteFile = (index) => {
    let tempArr = [...attachedDocuments];
    tempArr.splice(index, 1);
    setAttachedDocuments(tempArr);

    let updatLocal = { ...contractState };
    updatLocal.Documents.splice(index, 1);
    setContractState(updatLocal);
  };
  const _sendData = (index) => {
    var newArray = [...attachedDocuments];
    setBase(newArray[index]);
  };
  const DownloadImage = (index) => {
    var newArray = [...attachedDocuments];
    var a = document.createElement("a");
    a.href = newArray[index].documentBase64;
    a.download = newArray[index].documentName;
    a.click();
  };
  const uploadFrontImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    var newArray = [...attachedDocuments];
    newArray.push(base64);
    setAttachedDocuments(newArray);
    contractState.Documents.push(base64);
  };
  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        }, 400);
      });
    } else {
    }
  };

  const handleAddClause = () => {
    // expandElement();
    // executeScroll();
    console.log("add");
  };
  return (
    <div className="contentpanel removescroll">
      <div className="cust-container">
        <div className="cust-row flex-algn-cent">
          <div className="cust-col-4">
            <h1 className="title">
              <button
                className="button bg-orange mr-2 wd10 flex-inline"
                onClick={_goBack}
              >
                <div className="btn_txt font-lg">
                  <i className="fa fa-arrow-left"></i>
                </div>
              </button>
              Contract
            </h1>
          </div>
          <div className="cust-col-6 flex-jc-end">
            {userGroup === "1" ? (
              <button
                className="button bg-orange-out mr-2 wd10"
                onClick={setStatus}
              >
                <div className="btn_txt font-lg">
                  <i className="fa fa-edit"></i>
                </div>
                <div className="titleBoxnew">
                  <span>Edit </span>
                </div>
              </button>
            ) : (
              ""
            )}
            {/* <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                            <div className="titleBoxnew">
                                <span>Download </span>
                            </div>
                        </button> */}
            <button
              className="button bg-orange-out mr-2 wd10"
              onClick={() => {
                _handleClick("UploadContractModal");
              }}
            >
              <div className="btn_txt font-lg">
                <i className="fa fa-upload"></i>
              </div>
              <div className="titleBoxnew">
                <span>Upload </span>
              </div>
            </button>
          </div>
        </div>
        <hr className="bgg" />
        <div className="cust-row">
          <div className="cust-col-10">
            <div className="contract-div-1">
              <div className="transporterPlant">
                <div className="transporterPlantInner">
                  <div className="togglabelDiv">
                    <div className="togglebTns">
                      <button
                        className={
                          btnState1 === "transporter"
                            ? "activeTPBtn"
                            : "transporterBtn"
                        }
                        onClick={() => slideFun("transporter")}
                      >
                        TRANSPORTER'S DETAIL
                      </button>
                      <button
                        className={
                          btnState1 === "plant" ? "activeTPBtn" : "plantBtn"
                        }
                        onClick={() => slideFun("plant")}
                      >
                        PLANT'S DETAIL
                      </button>
                    </div>
                  </div>
                  <div className="cust-col-10">
                    {btnState1 === "transporter" ? (
                      <div className="ContractSection newColorContract">
                        <div className="contract-details newHeight">
                          <div className="contract-inner">
                            <div className="contract-data1">
                              <span className="contractDetailSpan3">
                                Transporter Name :
                              </span>
                            </div>
                            <div className="contract-data2">
                              <input
                                // className='contractDetailSpan4 cbdhide'
                                className={
                                  contractStatus === true
                                    ? "contractDetailSpan4 bdhide"
                                    : "contractDetailSpan4 cbdhide"
                                }
                                name="TransporterName"
                                disabled={
                                  contractStatus === false ? true : false
                                }
                                value={
                                  contractStatus === false
                                    ? contractData &&
                                      contractData.TransporterName
                                      ? contractData.TransporterName
                                      : "-"
                                    : contractState.TransporterName
                                }
                                onChange={(e) => _onUpdate(e)}
                              />
                            </div>
                          </div>
                          <div className="contract-inner">
                            <div className="contract-data1">
                              <span className="contractDetailSpan3">
                                Transporter Address :
                              </span>
                            </div>
                            <div className="contract-data2">
                              <input
                                // className='contractDetailSpan4 cbdhide'
                                className={
                                  contractStatus === true
                                    ? "contractDetailSpan4 bdhide"
                                    : "contractDetailSpan4 cbdhide"
                                }
                                name="TransporterAddress"
                                disabled={
                                  contractStatus === false ? true : false
                                }
                                value={
                                  contractStatus === false
                                    ? contractData &&
                                      contractData.TransporterAddress
                                      ? contractData.TransporterAddress
                                      : "-"
                                    : contractState.TransporterAddress
                                }
                                onChange={(e) => _onUpdate(e)}
                              />
                            </div>
                          </div>
                          <div className="contract-inner"></div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {btnState1 === "plant" ? (
                      <div className="ContractSection newColorContract">
                        <div className="contract-details newHeight">
                          <div className="contract-inner">
                            <div className="contract-data1">
                              <span className="contractDetailSpan3">
                                Plant Name :
                              </span>
                            </div>
                            <div className="contract-data2">
                              <input
                                className={
                                  contractStatus === true
                                    ? "contractDetailSpan4 bdhide"
                                    : "contractDetailSpan4 cbdhide"
                                }
                                // className='contractDetailSpan5 cbdhide'
                                name="PlantName"
                                disabled={
                                  contractStatus === false ? true : false
                                }
                                value={
                                  contractStatus === false
                                    ? contractData && contractData.PlantName
                                      ? contractData.PlantName
                                      : "-"
                                    : contractState.PlantName
                                }
                                onChange={(e) => _onUpdate(e)}
                              />
                            </div>
                          </div>
                          <div className="contract-inner">
                            <div className="contract-data1">
                              <span className="contractDetailSpan3">
                                Plant Address :
                              </span>
                            </div>
                            <div className="contract-data2">
                              <input
                                className={
                                  contractStatus === true
                                    ? "contractDetailSpan4 bdhide"
                                    : "contractDetailSpan4 cbdhide"
                                }
                                // className='contractDetailSpan5 cbdhide'
                                name="PlantAddress"
                                disabled={
                                  contractStatus === false ? true : false
                                }
                                value={
                                  contractStatus === false
                                    ? contractData && contractData.PlantName
                                      ? contractData.PlantAddress
                                      : "-"
                                    : contractState.PlantAddress
                                }
                                onChange={(e) => _onUpdate(e)}
                              />
                            </div>
                          </div>
                          <div className="contract-inner"></div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="togglabelDiv">
                <div className="togglebTns reduceWidth">
                  <button className="contracBtn">CONTRACT DETAIL</button>
                </div>
              </div>
              <div className="ContractSection btmBorder">
                <div className="contract-details">
                  <div className="contract-inner">
                    <div className="contract-data1">
                      <span className="contractDetailSpan1">
                        Contract Name :
                      </span>
                    </div>
                    <div className="contract-data2">
                      <input
                        className="contractDetailSpan2 cbdhide"
                        name="ContractName"
                        disabled={contractStatus === false ? true : false}
                        value={
                          contractStatus === false
                            ? contractData && contractData.ContractName
                              ? contractData.ContractName
                              : "-"
                            : contractState.ContractName
                        }
                        onChange={(e) => _onUpdate(e)}
                      />
                    </div>
                  </div>
                  <div className="contract-inner">
                    <div className="contract-data1">
                      <span className="contractDetailSpan1">Contract ID :</span>
                    </div>
                    <div className="contract-data2">
                      <input
                        className="contractDetailSpan2 cbdhide"
                        name="ContractId"
                        disabled={contractStatus === false ? true : false}
                        value={
                          contractStatus === false
                            ? contractData && contractData.ContractId
                              ? contractData.ContractId
                              : "-"
                            : contractState.ContractId
                        }
                        onChange={(e) => _onUpdate(e)}
                      />
                    </div>
                  </div>
                  <div className="contract-inner">
                    <div className="contract-data1">
                      <span className="contractDetailSpan1">
                        Contract Type :
                      </span>
                    </div>
                    <div className="contract-data2">
                      <input
                        className="contractDetailSpan2 cbdhide"
                        name="ContractType"
                        disabled={contractStatus === false ? true : false}
                        value={
                          contractStatus === false
                            ? contractData && contractData.ContractType
                              ? contractData.ContractType
                              : "-"
                            : contractState.ContractType
                        }
                        onChange={(e) => _onUpdate(e)}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="contract-details"
                  style={{ borderBottom: "none" }}
                >
                  <div className="contract-inner">
                    <div className="contract-data1">
                      <span className="contractDetailSpan1">Start Date :</span>
                    </div>
                    <div className="contract-data2">
                      {contractStatus === false ? (
                        <span className="contractDetailSpan2 cbdhide">
                          {contractData && contractData.StartDate
                            ? moment(contractData.StartDate).format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </span>
                      ) : (
                        <span
                          className="driverSpan2 calenderInpCont"
                          style={{ color: "black", fontSize: "0.75rem" }}
                        >
                          {moment(contractState.StartDate).format("DD/MM/YYYY")}
                          <div
                            className="hubUploadIcon"
                            style={{ border: "none" }}
                          >
                            <i className="fa fa-calendar updateCalender"></i>
                            <input
                              className="hideCalend"
                              name="StartDate"
                              value={contractState.StartDate}
                              onChange={(e) => _onUpdate(e)}
                              type="date"
                            />
                          </div>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="contract-inner">
                    <div className="contract-data1">
                      <span className="contractDetailSpan1">End Date :</span>
                    </div>
                    <div className="contract-data2">
                      {contractStatus === false ? (
                        <span className="contractDetailSpan2 cbdhide">
                          {contractData && contractData.EndDate
                            ? moment(contractData.EndDate).format("DD/MM/YYYY")
                            : ""}
                        </span>
                      ) : (
                        <span
                          className="driverSpan2 calenderInpCont"
                          style={{ color: "black", fontSize: "0.75rem" }}
                        >
                          {moment(contractState.EndDate).format("DD/MM/YYYY")}
                          <div
                            className="hubUploadIcon"
                            style={{ border: "none" }}
                          >
                            <i className="fa fa-calendar updateCalender"></i>
                            <input
                              className="hideCalend"
                              name="EndDate"
                              value={contractState.EndDate}
                              onChange={(e) => _onUpdate(e)}
                              type="date"
                            />
                          </div>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="contract-inner"></div>
                </div>
                <div className="uploadDiv" style={{ width: "100%" }}>
                  <div className="contHeaddd">
                    <span>Uploaded Files :</span>
                    {contractStatus === true ? (
                      <button
                        className="uploadimgBtn"
                        style={{
                          width: "6%",
                          height: "26px",
                          marginLeft: "20px",
                        }}
                      >
                        Upload
                        <input
                          className="hideInputFile"
                          type="file"
                          onChange={(e) => uploadFrontImage(e)}
                        />
                      </button>
                    ) : (
                      ""
                    )}
                    {attachedDocuments.length === 0 ? (
                      <span className="noImgText">No Files uploaded...</span>
                    ) : (
                      ""
                    )}
                  </div>
                  {attachedDocuments &&
                    attachedDocuments.map((item, index) => {
                      return (
                        <div key={index} className="pictureDisplay">
                          {contractStatus === true ? (
                            <div className="closeTab">
                              <i
                                className="fa fa-close"
                                onClick={() => deleteFile(index)}
                              ></i>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className="picturePreview"
                            onClick={() => {
                              _handleClick("DocumentModal");
                              _sendData(index);
                            }}
                          >
                            <img alt="" src={item} />
                          </div>

                          <div className="fullscreen med-size">
                            <i
                              className="fa fa-expand"
                              onClick={() => {
                                _handleClick("DocumentModal");
                                _sendData(index);
                              }}
                            ></i>
                          </div>
                          <div className="downloadDocBtn">
                            <i
                              className="fa fa-download"
                              onClick={() => DownloadImage(index)}
                            ></i>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              {contractStatus === true ? (
                <div className="cupdateElem">
                  <button onClick={updateContractFun}>Update</button>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="contract-div-2">
              <div className="contractClauseHead justifyEnd">
                <span>Contract Clauses</span>
                <button onClick={handleAddClause}>Add more Clauses</button>
              </div>

              <div className="contractClauseBody">
                {sortedArr.length !== 0 ? (
                  sortedArr &&
                  sortedArr.map((item, index) => {
                    return (
                      <div key={index} className="contractClauseCat1">
                        <div className="catDiv">
                          <span className="catspan">
                            Category :{" "}
                            <span className="catOrangeSpan">
                              {" "}
                              {item.Category}{" "}
                            </span>
                          </span>
                        </div>
                        {item &&
                          item.clause.map((a, b) => {
                            return (
                              <div
                                key={b}
                                className="catDivBody contractClauseItem"
                                onClick={() => viewEdit(a)}
                              >
                                <div className="cat5">{b + 1}.</div>
                                {a &&
                                  a.Clause.map((x, y) => {
                                    return x.field !== "checkbox" &&
                                      x.field !== "radio" ? (
                                      <div
                                        key={y}
                                        className={
                                          x.field === "text"
                                            ? "catClauses"
                                            : "catClausesGrey"
                                        }
                                      >
                                        {x.value} {x.unit}
                                      </div>
                                    ) : (
                                      x.values &&
                                        x.values.map((obj, ind) => {
                                          return obj.check === true ? (
                                            <div
                                              key={ind}
                                              className={
                                                x.field === "text"
                                                  ? "catClauses"
                                                  : "catClausesGrey"
                                              }
                                            >
                                              <span className="">
                                                {obj.label}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          );
                                        })
                                    );
                                  })}
                                <div className="catIcon clauseIcon">
                                  {/* <button
                                    className="clauseEditBtn orangeBg"
                                    onClick={() => viewEdit(a)}
                                  >
                                    View / Edit
                                  </button> */}
                                  <button className="clauseEditBtn greyBg">
                                    <i className="fa fa-trash catDelete"></i>
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })
                ) : (
                  <div className="notFound">
                    <span>No Clause found.</span>
                  </div>
                )}
              </div>

              {/* <div
                className="addClauseUi close topSide"
                ref={myRef}
                style={{ behavior: "smooth", block: "start" }}
              >
                <ContractClauses
                  id={contractData !== undefined ? contractData._id : ""}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <UploadContract closeModal={_closeModal} contractData={contractData} />
      <DocumentModal closeModal={_closeModal} imageData={{ base }} />
    </div>
  );
};

export default ContractDetail;
