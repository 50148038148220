import KYCLogo from './../../static/images/icon/address/kyc.svg'
import BusinessLogo from './../../static/images/icon/address/business_id.svg'
import AddressLogo from './../../static/images/icon/address/address.svg'
import CompanyLogo from './../../static/images/icon/address/company.svg'
import ContactLogo from './../../static/images/icon/address/contact.svg'
import Name from './../../static/images/icon/address/Name.svg'
import code from './../../static/images/icon/address/code.svg'
import email from './../../static/images/icon/address/email.svg'
import since from './../../static/images/icon/address/since.svg'
import history from './../../services/history';
import $ from 'jquery'
import { useState, useEffect } from 'react'
import { selectInit } from './../../services/functions'
import { listingApi } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import ReferTransModal from './Modal/ReferTrans'
import moment from 'moment'
import axios from 'axios'

const TransporterMaster = (props) => {  
    const dispatch = useDispatch()
    const transportersList = useSelector((state) => state.getListingReducer.transporterListing)
    const loader = useSelector((state) => state.getListingReducer.loading)
    const bookmark = useSelector((state) => state.getListingReducer.transBookmarks)
    const records = useSelector((state) => state.getListingReducer.transRecords)
    const [val, setVal] = useState('')
    const [searchObject, setSearchObject] = useState(
        {
            bookmark: "",
            docType: "transporter",
            pageSize: "",
            searchValue: "",
            searchType: "",
            orgId: "",
            blacklistedFlag: "",
        }
    )
    const [obj, setObj] = useState(
        {
            docType: "transporter",
            param: "next",
            pageSize: "",
            bookmark: "",
            blacklistedFlag: "",
            searchType: "",
            searchValue: ""
        }
    )
    const _viewContract = () => {
        history.push("/dashboard/Contracts/details")
    }
    const _viewDetails = (item) => {
        history.push({
            pathname: '/dashboard/transporter-master/details',
            state: item
        })
    }
    const _searchDrop = (id, value) => {
        if (id === 'transSearchType') {
            let temp = { ...searchObject }
            temp.searchType = value
            setSearchObject(temp)
        }
        else if (id === 'blacklist') {
            if(value !== 'select'){
                setVal(value)
            }
            else{}
        }

    }

    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 50)


    }, [])
    useEffect(() => {
        bookmark.length = 0
        setObj(
            {
                param: "next",
                docType: "transporter",
                param: "next",
                pageSize: "",
                bookmark: "",
                blacklistedFlag: "",
                searchType: "",
                searchValue: ""
            }
        )
        dispatch(listingApi(obj))
    }, [dispatch])

    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            docType: "transporter"
        }
        setObj(object)
        dispatch(listingApi(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            docType: "transporter"
        }
        setObj(object)
        dispatch(listingApi(object))
        bookmark.pop()
    }
    const refer = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const registerTrans = () => {
        history.push('/dashboard/transporter-master/registration')
    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(listingApi({
            docType: "transporter",
            param: "next",
            pageSize: "",
            bookmark: "",
            blacklistedFlag: "",
            searchType: searchObject.searchType,
            searchValue: searchObject.searchValue

        }))
    }
    useEffect(() => {
        if (val) {
            dispatch(listingApi({
                docType: "transporter",
                param: "",
                pageSize: "",
                bookmark: "",
                blacklistedFlag: JSON.parse(val),
                searchType: "",
                searchValue: ""
            }))
        }
    }, [val])

    const blacklist = (item) => {
        const finalObj = {
            OrgId: item._id,
            orgName: item.OrgName,
            pan: item.PAN,
            panCopy: item.PanCopy,
            address: item.Address,
            state: item.State,
            city: item.City,
            country: item.Country,
            pinCode: item.PinCode,
            gstin: item.GSTIN,
            gstinUpload: item.gstinUpload,
            vendorId: item.VendorId,
            vendorCode: item.VendorCode,
            siteId: item.SiteId,
            siteCode: item.SiteCode,
            plantId: item.PlantId,
            plantName: item.PlantName,
            ibaCode: item.IbaCode,
            ibaValidity: item.IbaValidity,
            fax: item.Fax,
            tan: item.TAN,
            status: item.Status,
            blacklistedFlag: item.BlacklistedFlag === false ? true : false,
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: `Are you sure ? `, type: 'confirm', title: 'Alert!!', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {
                axios.post('https://www.betaenterprise.lynkit.io/api/user/UpdateOrganisation', { ...finalObj }, { headers: config.headers })
                    .then(response => {
                        const message = response.data.message
                        const err = response.data.error
                        dispatch(listingApi(obj))
                        if (err === 'false') {
                            window.$.fn.show_notification({ message: `${message} `, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })

                        }
                        else {
                            window.$.fn.show_notification({ message: `${message} `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                    })
            }
        })
    }
    const approve = (item) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        let id = item._id
        window.$.fn.show_notification({ message: `Are you sure ? `, type: 'confirm', title: 'Alert!!', autohide: true, showInput: false },
            (result) => {
                if (result.type === 'confirm') {
                    axios.post('https://www.betaenterprise.lynkit.io/api/user/ApproveTransporter', { transporterId: id }, { headers: config.headers })
                        .then(response => {
                            const message = response.data.message
                            const err = response.data.error
                            dispatch(listingApi(obj))
                            if (err === 'false') {
                                window.$.fn.show_notification({ message: `${message} `, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                                    if (result.type === 'confirm') {
                                    }
                                })

                            }
                            else {
                                window.$.fn.show_notification({ message: `${message} `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                    if (result.type === 'confirm') {
                                    }
                                })
                            }
                        })
                        .catch(error => {
                            console.log('error', error);
                        })
                }
            })
    }
    return (
        <>
            <div className="contentpanel">
                <div className='cust-row'>
                    <div className='cust-col-8 flex-algn-cent'>
                        <h1 className="title">Transporter Master</h1>
                    </div>
                    <div className='cust-col-2 flex-jc-end' >
                        <div className='cust-col-2 flex-jc-end'>
                            <button className='btn btn-shadow btn-secondary size_hgt' style={{ marginRight: '20px' }} onClick={registerTrans}>Add Transporter</button>
                        </div>
                        <div className='cust-col-2 flex-jc-end'>
                            <button className='btn btn-shadow btn-secondary size_hgt' onClick={() => { refer('ReferTransModal') }}>Refer Transporter</button>
                        </div>
                    </div>
                </div>
                <hr className='bgg' />
                <div className="cust-container">
                    <div className='cust-row'>   
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Transporter's List</div>
                        </div>
                        <div className='cust-col-4-new'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="select" id="transSearchType">
                                        <option value="select" disabled>Select</option>
                                        <option value="orgName">Organistaion Name</option>
                                        <option value="pan">PAN</option>
                                        <option value="gstin">GSTIN</option>
                                        <option value="vendorId">Vendor Id</option>
                                        <option value="vendorCode">Vendor Code</option>
                                    </select>
                                </div>
                                <input type="text" name='searchValue' value={searchObject.searchValue} onChange={(e) => _onChangeSearch(e)} className="form-control" placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2"
                                        onClick={() => triggerSearch()}
                                    // disabled={searchObject.searchType === 'select' || searchObject.searchType === undefined ? true : false}
                                    >
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="select" id="blacklist">
                                <option value="select" disabled>Status</option>
                                <option value="false">Approved</option>
                                <option value="true">Blacklisted</option>
                            </select>
                        </div>
                    </div>


                    <div className='cust-col-10'>
                        <div className='book_content'>
                            {transportersList && transportersList.length !== 0 ?
                                loader === true ?
                                    <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                        <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                                    </div>
                                    :
                                    transportersList.map((item, index) => {
                                        return (
                                            <div
                                                className={item.BlacklistedFlag === true ? 'book_content_item addShadow blackListBrdr' : 'book_content_item addShadow'}
                                                key={index}>
                                                <table className='book_tbl'>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span className='tbl_tt'>
                                                                    <img src={BusinessLogo} alt="" />
                                                                    Vendor ID
                                                                </span>
                                                                <span className='tbl_txt'>{item.BlacklistedFlag === true ? 'true' : 'false' }</span>
                                                            </td>
                                                            <td>
                                                                <span className='tbl_tt'>
                                                                    <img src={code} alt="" />
                                                                    Vendor Code
                                                                </span>
                                                                <span className='tbl_txt'>CODE - {item.VendorCode}</span>
                                                            </td>
                                                            <td>
                                                                <span className='tbl_tt'>
                                                                    <img src={Name} alt="" />
                                                                    Vendor Name
                                                                </span>
                                                                <span className='tbl_txt'>{item.OrgName ? item.OrgName : '-'}</span>
                                                            </td>
                                                            <td>
                                                                <span className='tbl_tt'>
                                                                    <img src={email} alt="" />
                                                                    Email
                                                                </span>
                                                                <span className='tbl_txt'>{item.UserDetails.map((x, y) => { return (x.EmailId) })}</span>
                                                            </td>
                                                            <td rowspan="2" className="actbt">
                                                                <div className="button bti bg-orange-out" onClick={() => _viewDetails(item)}>
                                                                    <div className="btn_icon"><i className="fa fa-eye"></i></div>
                                                                    <div className="btn_txt">Detail</div>
                                                                </div>
                                                                <div className="button bti bg-grey-out" onClick={_viewContract}>
                                                                    <div className="btn_icon"><i className="fa fa-file"></i></div>
                                                                    <div className="btn_txt">Contract</div>
                                                                </div>
                                                                {/* {item.BlacklistedFlag === false ?
                                                                <div className="button bti bg-danger-out" onClick={() => blacklist(item)}>
                                                                    <div className="btn_icon"><i className="fa fa-close"></i></div>
                                                                    <div className="btn_txt">Blacklist</div>
                                                                </div>
                                                                : */}
                                                                {item.Status === 'INACTIVE' ?
                                                                    <div className="button bti bg-success-out" onClick={() => approve(item)}>
                                                                        <div className="btn_icon"><i className="fa fa-check"></i></div>
                                                                        <div className="btn_txt">Approve</div>
                                                                    </div>
                                                                    :
                                                                    ""
                                                                }
                                                                {item.Status === 'ACTIVE' && item.BlacklistedFlag === false ?
                                                                    <div className="button bti bg-danger-out" onClick={() => blacklist(item)}>
                                                                        <div className="btn_icon"><i className="fa fa-close"></i></div>
                                                                        <div className="btn_txt">Blacklist</div>
                                                                    </div>
                                                                    : ''}
                                                                {item.Status === 'ACTIVE' && item.BlacklistedFlag === true ?
                                                                    <div className="button bti bg-danger-out" style={{ background: 'red', color: '#fff' }}>
                                                                        <div className="btn_icon"><i className="fa fa-close"></i></div>
                                                                        <div className="btn_txt">Blacklisted</div>
                                                                    </div>
                                                                    : ''
                                                                }

                                                                {/* } */}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <span className='tbl_tt'>
                                                                    <img src={ContactLogo} alt="" />
                                                                    Contact Details
                                                                </span>
                                                                <span className='tbl_txt'>Contact Person -{item.UserDetails.map((x, y) => { return (x.Name) })}</span>
                                                                <span className='tbl_txt'>Mobile - {item.UserDetails.map((x, y) => { return (x.Phone) })}</span>
                                                            </td>
                                                            <td className='wd25'>
                                                                <span className='tbl_tt'>
                                                                    <img src={KYCLogo} alt="" />
                                                                    KYC Details
                                                                </span>
                                                                <span className='tbl_txt'>PAN - {item.PAN}</span>
                                                                <span className='tbl_txt'>GSTIN -{item.GSTIN}</span>
                                                            </td>
                                                            <td>
                                                                <span className='tbl_tt'>
                                                                    <img src={since} alt="" />
                                                                    Registered Since
                                                                </span>
                                                                <span className='tbl_txt'>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>

                                                            </td>
                                                            <td>
                                                                <span className='tbl_tt'>
                                                                    <img src={AddressLogo} alt="" />
                                                                    Registered Address
                                                                </span>
                                                                <span className='tbl_txt'>{item.Address},  {item.City} ,  {item.State} , {item.PinCode}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    })
                                :
                                <span className='noDataClass'>No Data Found</span>
                            }
                        </div>
                    </div>

                </div>
                {transportersList && transportersList.length !== 0 ?
                    <div className='paginationDiv'>
                        <button disabled={obj && obj.bookmark === '' ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                        <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                    </div>
                    : ''}
            </div>
            <ReferTransModal closeModal={_closeModal} />
        </>
    )
}

export default TransporterMaster;