import React from "react";
import Select from "react-select";

const CustomSelect = (props) => {
  const { ele, mainIndex, inputValueHandler, shareIndex, removeItem } = props;
  const setTrue = () => {
    ele.show = true;
    shareIndex(mainIndex, ele.values);
  };
  const colourStyles = {
    menuList: (styles) => ({
      ...styles,
      background: "white",
      width: "100%",
      height: '175px'
    }),
    control: (styles, state) => {
      return {
        ...styles,
        backgroundColor: "transparent",

        boxShadow: state.isFocused
          ? "0 0 3px #28a745"
          : state.isFocused
          ? "0 0 3px #dc3545"
          : "none",
        cursor: "pointer",
        ":focus": {
          ...styles[":focus"],
          //   borderColor: "var(--clr--accent)",
          // boxShadow: "0 0 3px var(--clr--accent)",
        },
        ":active": {
          ...styles[":active"],
          //   borderColor: "var(--clr--accent)",
          // boxShadow: "0 0 3px var(--clr--accent)",
        },

        ":hover": {
          ...styles[":hover"],
          // borderColor: "var(--clr--accent)",
          // boxShadow: "0 0 3px var(--clr--accent)",
        },
        height: 35,
        minHeight: 35,
      };
    },
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#ff7200" : isSelected ? "#fff" : undefined,
      color: isFocused ? "#fff" : "black",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "var(--clr--accent)"
            : undefined
          : undefined,
      },
      zIndex: 999999999,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 99999999,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      margin: "-8px 0 0 0",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
    }),
  };

  const options =
    ele.values &&
    ele.values.map((item, index) => ({
      value: item.label,
      label: item.label,
      innerIndex: index,
    }));

  const _handleChange = (item) => {
    var e = item.label;
    inputValueHandler({ e, index: mainIndex, innerIndex: item.innerIndex });
    setTrue();
  };
  return (
    <>
      <div className="FieldElem alignCenter">
        <i
          className="fa fa-minus absMius"
          onClick={(e) => removeItem({ e, index: mainIndex })}
        ></i>
        <Select
          onChange={(value) => _handleChange(value)}
          options={options}
          styles={colourStyles}
        />
      </div>
    </>
  );
};

export default CustomSelect;
