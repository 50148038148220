import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import history from './../../../services/history'

const DocumentModal = ({props,imageData}) => {

    const [imagebase,setImageBase]= useState('')
    const [imgName,setImgName]=useState('')


    useEffect(()=>{
        setImageBase(imageData.base)
        setImgName(imageData.fileName)
    },[imageData])
    
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const DownloadImage = () => {
        var a = document.createElement("a"); 
        a.href = imagebase
        a.download = `${imageData.fileName}`
        a.click();
    }
  

    return (
        <section id="DocumentModal" className="custom-modal" style={{ width: "40%" }}>
            <div className="custom-modal-header">
                <h5>Image Preview</h5>
                <span className="ct-close" onClick={_closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '75vh' }}>
                <div className='myImage'>
                    <img src={imagebase} alt="" />
                    <span className='docName'>{imgName}</span>
                </div>
                {/* <div className='myImageDownload'>
                    <button onClick={DownloadImage}>Download</button>
                </div>   */}
            </div>
        </section>
    )
}

export default DocumentModal;