import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery'
import InspectImgModal from '../../Dashboard/Modal/InspectImgModal'

const Inpection = (props) => {
    const [requestedGateNo, setrequestedGateNo] = useState()
    const gateInRequestData = props.gateInRequestData;
    const [requestData, setRequestData] = useState({
        action: "gateInRequest",
        shipmentGID: "",
        truckAllocationId: "",
        productType: "",

    })
    const [imgItem, setItem] = useState('')

    const handleChange = (e) => {
        setrequestedGateNo({ ...requestedGateNo, [e.target.name]: e.target.value })
    }
    const concate = { ...requestData, ...requestedGateNo }

    const gateInRequest = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if (requestedGateNo !== undefined && requestedGateNo !== '') {
            axios.post('https://www.betaenterprise.lynkit.io/api/booking/booking', { ...concate }, { headers: config.headers })
                .then(response => {
                    const error = response.data.error
                    const message = response.data.message
                    if (error === false) {
                        props.closeModal()
                        setrequestedGateNo()
                        window.$.fn.show_notification({ message: `${message}`, title: 'Success...!!', type: 'confirm', autohide: false }, (result) => {
                            if (result.type === "confirm") {
                            }
                        })
                        window.location.reload();
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Alert..!!', type: 'confirm', autohide: false }, (result) => {
                            if (result.type === "confirm") {
                            }
                        })
                    }

                })
                .catch(error => {
                    console.log('err', error);
                })
        }
        else {
            window.$.fn.show_notification({ message: "Requested Gate Number cannot be empty..", title: 'Alert!!', autohide: true, confirmVal: "Okay", showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }

    }

    const toogleHubView = (index, check = true) => {
        if ($(".collapse-view").eq(index).hasClass("open") && check) {
            $(".collapse-view").eq(index).removeClass("open");
            $(".collapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
            let height =
                Number($(".collapse-view").eq(index).children("table").height()) + 2;
            $(".collapse-view")
                .eq(index)
                .animate({ height }, 500, () => {
                    $(".collapse-view").eq(index).addClass("open");
                });
        }
    }
    const _handleClick = (id) => {
        $(".custom-modal-ov-erlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const SharedData = (item) => {
        setItem(item)
    }


    useEffect(() => {
        requestData.shipmentGID = gateInRequestData.ShipmentGID
        if ('TruckDetails' in gateInRequestData) {
            requestData.truckAllocationId = gateInRequestData.TruckDetails.TruckAllocationId
            requestData.productType = gateInRequestData.TruckDetails.VehicleType
        }
    }, [props])

    return (
        <section id="InspectionModal" className="custom-modal" style={{ 'width': '60%' }}>
            <div className="custom-modal-header">
                {gateInRequestData.show === true ?
                    <h1>Request fot Gate-In</h1>
                    :
                    <h1>Inspection Report</h1>
                }
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" >
                <div className='inspectionDiv' style={{ overflow: 'auto' }}>
                    {gateInRequestData.show === true ?
                        <div className='inspectionHeaderElem'>
                            <div className='inspectHeaderElem1'>
                                <span className='inspectVeNum'>Vehicle Number:</span>
                                <span className='inspectVehcValue'>{gateInRequestData.TruckDetails ? gateInRequestData.TruckDetails.VehicleNumber : ''}</span>
                            </div>
                            <div className='inspectHeaderElem1'>
                                <span className='inspectVeNum'>Transporter Name:</span>
                                <span className='inspectVehcValue'>{gateInRequestData ? gateInRequestData.TransporterName : ''} , ({gateInRequestData ? gateInRequestData.OrgReadableID : ''}) </span>
                            </div>
                            <div className='inspectHeaderElem1'>
                                <span className='inspectVeNum'>Requested Gate Number :</span>
                                <input className='inspectIn' name='requestedGateNo' onChange={(e) => handleChange(e)} type='number'></input>
                            </div>
                        </div>
                        : ""}

                    {gateInRequestData.TruckDetails !== undefined ? gateInRequestData.TruckDetails.Inspection[0].InspectionReport.map((item, index) => {
                        return (
                            item.type === "vehicleChecks" ?
                                <div key={index} className="table-responsive collapse-view collapseTableInspect">
                                    <div class="collapsable_icon" onClick={() => { toogleHubView(index) }}></div>
                                    <table className='cctbl'>
                                        <thead>
                                            <tr class="bg_black">
                                                <div className='insTableHead'>
                                                    <div className='insHeader1'>
                                                        <span>Vehicle Checks</span>
                                                    </div>
                                                    <div className='insHeader2'>
                                                        <span>{item.status === 'Fail' ? <span className='inspectFail'>{item.status} </span> : <span className='inspectPass'>{item.status} </span>}</span>
                                                    </div>
                                                </div>
                                            </tr>
                                            <tr class="bg_black">

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                <div>
                                                    <div className="btblock">
                                                        <div className='inpectionInner1'>
                                                            <div className='inpectionHeader'>
                                                                <div className='inpectBox11'>
                                                                    <span className='inpectHeadSpan'>SNo.</span>
                                                                </div>
                                                                <div className='inpectBox33'>
                                                                    <span className='inpectHeadSpan'>Clauses</span>
                                                                </div>
                                                                <div className='inpectBox22'>
                                                                    <span className='inpectHeadSpan'>Status</span>
                                                                </div>
                                                                <div className='inpectBox22'>
                                                                    <span className='inpectHeadSpan'>Reason</span>
                                                                </div>
                                                                <div className='inpectBox22'>
                                                                    <span className='inpectHeadSpan'>Action</span>
                                                                </div>
                                                            </div>
                                                            {item.checks.map((a, b) => {
                                                                return (
                                                                    <div key={b} className='inspectBody'>
                                                                        <div className='inpectBox11'>
                                                                            <span>{b + 1}</span>
                                                                        </div>
                                                                        <div className='inpectBox33'>
                                                                            <span>{a.clause}</span>
                                                                        </div>
                                                                        <div className='inpectBox22'>
                                                                            {a.result === 'Pass' ? <span className='inpectHeadSpan inspectPass'>Pass</span>
                                                                                :
                                                                                <span className='inpectHeadSpan inspectFail'>Fail</span>
                                                                            }
                                                                        </div>
                                                                        <div className='inpectBox22'>
                                                                            <span className='inpectHeadBody fontCCL'>{a.result === 'Fail' ? a.reason : '-'}</span>
                                                                        </div>
                                                                        <div className='inpectBox22'>
                                                                            <span className='inpectHeadBody fontCCL '>
                                                                                <span className='inpectHeadBody'>
                                                                                    {a.attachedDocuments.length !== 0 ?
                                                                                        <i className="fa fa-eye InspectionEye" onClick={() => { _handleClick('Inspectimgmodal'); SharedData(a.attachedDocuments[0].documentKey) }}></i>
                                                                                        : '-'}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>


                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                item.type === "validityChecks" ?
                                    <div className="table-responsive collapse-view collapseTableInspect">
                                        <div class="collapsable_icon" onClick={() => { toogleHubView(index) }}></div>
                                        <table className='cctbl'>
                                            <thead>
                                                <tr class="bg_black">
                                                    <div className='insTableHead'>
                                                        <div className='insHeader1'>
                                                            <span>Statutory Checks</span>
                                                        </div>
                                                        <div className='insHeader2'>
                                                            <span>{item.status === 'Fail' ? <span className='inspectFail'>{item.status} </span> : <span className='inspectPass'>{item.status} </span>}</span>
                                                        </div>
                                                    </div>
                                                </tr>
                                                <tr class="bg_black">

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <div>
                                                        <div className="btblock">
                                                            <div className='inpectionInner1'>
                                                                <div className='inpectionHeader'>
                                                                    <div className='inpectBox11'>
                                                                        <span className='inpectHeadSpan'>SNo.</span>
                                                                    </div>
                                                                    <div className='inpectBox33'>
                                                                        <span className='inpectHeadSpan'>Clauses</span>
                                                                    </div>
                                                                    <div className='inpectBox33'>
                                                                        <span className='inpectHeadSpan'>Expiry</span>
                                                                    </div>
                                                                    <div className='inpectBox33'>
                                                                        <span className='inpectHeadSpan'>Action</span>
                                                                    </div>
                                                                </div>
                                                                {item.checks.map((a, b) => {
                                                                    return (
                                                                        <div key={b} className='inspectBody'>
                                                                            <div className='inpectBox11'>
                                                                                <span>{b + 1}</span>
                                                                            </div>
                                                                            <div className='inpectBox33'>
                                                                                <span>{a.clause}</span>
                                                                            </div>
                                                                            <div className='inpectBox33'>
                                                                                <span className='inpectHeadBody fontCCL '>
                                                                                    {a.date}
                                                                                </span>
                                                                            </div>
                                                                            <div className='inpectBox33'>
                                                                                <span className='inpectHeadBody'>{a.result === 'Fail' ? <span className='inspectFail'>{a.result} </span> : <span className='inspectPass'>{a.result} </span>}</span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    item.type === "driverChecks" ?
                                        <div className="table-responsive collapse-view collapseTableInspect">
                                            <div class="collapsable_icon" onClick={() => { toogleHubView(index) }}></div>
                                            <table className='cctbl'>
                                                <thead>
                                                    <tr class="bg_black">
                                                        <div className='insTableHead'>
                                                            <div className='insHeader1'>
                                                                <span>Driver Checks</span>
                                                            </div>
                                                            <div className='insHeader2'>
                                                                <span>{item.status === 'Fail' ? <span className='inspectFail'>{item.status} </span> : <span className='inspectPass'>{item.status} </span>}</span>
                                                            </div>
                                                        </div>
                                                    </tr>
                                                    <tr class="bg_black">

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>

                                                        <div>
                                                            <div className="btblock">
                                                                <div className='inpectionInner1'>
                                                                    <div className='inpectionHeader'>
                                                                        <div className='inpectBox11'>
                                                                            <span className='inpectHeadSpan'>SNo.</span>
                                                                        </div>
                                                                        <div className='inpectBox33'>
                                                                            <span className='inpectHeadSpan'>Clauses</span>
                                                                        </div>
                                                                        <div className='inpectBox22'>
                                                                            <span className='inpectHeadSpan'>Status</span>
                                                                        </div>
                                                                        <div className='inpectBox22'>
                                                                            <span className='inpectHeadSpan'>Reason</span>
                                                                        </div>
                                                                        <div className='inpectBox22'>
                                                                            <span className='inpectHeadSpan'>Action</span>
                                                                        </div>
                                                                    </div>
                                                                    {item.checks.map((a, b) => {
                                                                        return (
                                                                            <div key={b} className='inspectBody'>
                                                                                <div className='inpectBox11'>
                                                                                    <span>{b + 1}</span>
                                                                                </div>
                                                                                <div className='inpectBox33'>
                                                                                    <span>{a.clause}</span>
                                                                                </div>
                                                                                <div className='inpectBox22'>
                                                                                    {a.result === 'Pass' ? <span className='inpectHeadSpan inspectPass'>Pass</span>
                                                                                        :
                                                                                        <span className='inpectHeadSpan inspectFail'>Fail</span>
                                                                                    }
                                                                                </div>
                                                                                <div className='inpectBox22'>
                                                                                    <span className='inpectHeadBody fontCCL'>{a.result === 'Fail' ? a.reason : '-'}</span>
                                                                                </div>
                                                                                <div className='inpectBox22'>
                                                                                    <span className='inpectHeadBody fontCCL '>
                                                                                        <span className='inpectHeadBody'>
                                                                                            {a.attachedDocuments.length !== 0 ?
                                                                                                <i className="fa fa-eye InspectionEye" onClick={() => { _handleClick('Inspectimgmodal'); SharedData(a.attachedDocuments[0].documentKey) }}></i>
                                                                                                : '-'}
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        : ''

                        )
                    })
                        : ''}
                    {gateInRequestData.show === true ?
                        <div className='inspectButtons'>
                            <button className='inspectAccept' onClick={gateInRequest}>Request</button>
                        </div>
                        : ''}
                </div>
            </div>


            <InspectImgModal base={imgItem} />
        </section>
    )

}

export default Inpection;