import React, { useState, useEffect } from 'react';
import UsersModal from './../Modal/UsersModal'
import OverlapModal from './../Modal/OverlapModal'
import $ from 'jquery'
import axios from 'axios'
import { selectInit } from './../../../services/functions'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { getPlantsListing, getUserList } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import InspectImgModal from '../../Dashboard/Modal/InspectImgModal'
import Select from 'react-select';

const AddUser = (props) => {
    const dispatch = useDispatch()
    const plantInfo = props.sharedData.item && props.sharedData.item.list ? props.sharedData.item.list : ''
    const plantStatus = props.sharedData.item && props.sharedData.item.update ? props.sharedData.item.update : ''

    const userInfo = props.sharedData.userInfo && props.sharedData.userInfo.list ? props.sharedData.userInfo.list : ''
    const userStatus = props.sharedData.userInfo && props.sharedData.userInfo.update ? props.sharedData.userInfo.update : ''
    const [imgItem, setItem] = useState('')
    const listing = useSelector((state) => state.getPlantsListReducers.plantsData)
    const departListing = useSelector((state) => state.getDepartReducer.departmentData)

    const [updatePlant, setUpdatePlant] = useState(
        {
            PlantId: "",
            Country: "India",
            Address: "",
            State: "",
            City: "",
            PinCode: "",
            GSTIN: "",
            GstinUpload: "",
            Lattitude: "",
            Longitude: "",
            status: ""
        }
    )
    const [registerPlant, setRegisterPlant] = useState({
        plantName: "",
        address: "",
        city: "",
        state: "",
        pinCode: "",
        gstin: "",
        gstinUpload: "",
        lattitude: "",
        longitude: ""
    })
    const [registerUser, setRegisterUser] = useState(
        {
            departmentName: "",
            departmentId: "",
            userType: "",
            plantId: "",
            plantName: "",
            name: "",
            mobileNo: "",
            emailId: "",
            address: "",
            city: "",
            state: "",
            pinCode: "",
            password: "",
            confirmPassword: "",

            userSubGroup: "Department",
            permissions: {
                // createUser: true,
                // editUser: true,
                // viewUser: true,
                // editHub: true,
                // disableUser: true,
                // viewListing: true,
                // addPlant: true,
                // viewPlant: true,
                // addStaff: true,
                // deleteStaff: true,
                // viewStaff: true,
                // editStaff: true,
                // addHub: true,
                // deleteHub: true,
                // viewHub: true,
                // viewInvoice: true,
                // createInvoice: true,
                // editInvoice: true,
                // addVehicle: true,
                // viewVehicle: true,
                // editVehicle: true,
                // addDriver: true,
                // viewDriver: true,
                // editDriver: true,
                // addAddress: true,
                // editAddress: true,
                // viewAddress: true,
                // addContract: true,
                // viewContract: true,
                // viewProfile: true,
                // editProfile: true,
                // changePassword: true,
                // viewSettingPanel: true,
                // addSettingsInSettingPanel: true,
                // editSettingPanel: true,
                // viewDashboard: true,
                // downloadReport: true,
                // vehicleAllocation: true,
                // viewBookingDetails: true,
                // requestForGateInInspecPassed: true,
                // requestForGateInInspecFailed: true,
                // weighmentOfVehicle: true,
                // vehicleInspection: true,
                // driverInspection: true,
                // gpsDeviceInspection: true,
                // allowVehicleForGateIn: true,
                // addClauseForInspection: true,
                // waivePenalty: true,
                // rejectDriver: true,
                // addCustomerVehicle: true,
                // addTransporter: true,
                // viewTransporter: true,
                // approveTransporter: true,
                // rejectTransporter: true,
                // allocateSalesOrderCustVehicle: true,
                // uploadDocuments: true,
                // bookingViewDetails: true,
                // pickupDropOffLocation: true,
                // addDepartment: true,
                // viewDepartment: true,
                // editOrganisation: true,
                // addBooking: true,
                // editPlant: true,
                // editDepartment: true,
                // GeneratePOD: true,
                // ArrivedAtDestination: true,
                // viewlr: true,
                // editContract: true
            }
        }
    )
    const [updateUser, setUpdateUser] = useState({
        DepartmentName: "",
        DepartmentId: "",
        UserType: "",
        PlantId: "",
        PlantName: "",
        Name: "",
        MobileNo: "",
        EmailId: "",
        Address: "",
        City: "",
        State: "",
        PinCode: "",
        Password: "",
        ConfirmPassword: "",
        UserSubGroup: "Department",
        Permissions: {
            CreateUser: true,
            EditUser: true,
            ViewUser: true,
            EditHub: true,
            DisableUser: true,
            ViewListing: true,
            AddPlant: true,
            ViewPlant: true,
            AddStaff: true,
            DeleteStaff: true,
            ViewStaff: true,
            EditStaff: true,
            AddHub: true,
            DeleteHub: true,
            ViewHub: true,
            ViewInvoice: true,
            CreateInvoice: true,
            EditInvoice: true,
            AddVehicle: true,
            ViewVehicle: true,
            EditVehicle: true,
            AddDriver: true,
            ViewDriver: true,
            EditDriver: true,
            AddAddress: true,
            EditAddress: true,
            ViewAddress: true,
            AddContract: true,
            ViewContract: true,
            ViewProfile: true,
            EditProfile: true,
            ChangePassword: true,
            ViewSettingPanel: true,
            AddSettingsInSettingPanel: true,
            EditSettingPanel: true,
            ViewDashboard: true,
            DownloadReport: true,
            VehicleAllocation: true,
            ViewBookingDetails: true,
            RequestForGateInInspecPassed: true,
            RequestForGateInInspecFailed: true,
            WeighmentOfVehicle: true,
            VehicleInspection: true,
            DriverInspection: true,
            GpsDeviceInspection: true,
            AllowVehicleForGateIn: true,
            AddClauseForInspection: true,
            WaivePenalty: true,
            RejectDriver: true,
            AddCustomerVehicle: true,
            AddTransporter: true,
            ViewTransporter: true,
            ApproveTransporter: true,
            RejectTransporter: true,
            AllocateSalesOrderCustVehicle: true,
            UploadDocuments: true,
            BookingViewDetails: true,
            PickupDropOffLocation: true,
            AddDepartment: true,
            ViewDepartment: true,
            EditOrganisation: true,
            AddBooking: true,
            EditPlant: true,
            EditDepartment: true,
            GeneratePOD: true,
            ArrivedAtDestination: true,
            Viewlr: true,
            editContract: true
        }
    })
    const [userPasswordType, setUserPasswordType] = useState("password")
    const [gstShow, setGstShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userLoading, setUserLoading] = useState(false)
    const [base, setBase] = useState('')
    const [toggleHead, setToggleHead] = useState("")
    const [map, setMap] = useState(false)
    const [address, setAddress] = useState('')
    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('')
    const [status, setStatus] = useState(null);
    const [tick, setTick] = useState(false)
    let userType= localStorage.getItem('userGroup')
    const [ids, setIds] = useState({
        plantId: '',
        departmentId: '',
        user: '',
        plantUpdateId: '',
    })
    const [allCheck, setAllCheck] = useState(false)
    const [permissionsObj, setPermissonsObj] = useState(
        {
            createUser: true,
            editUser: true,
            disableUser: true,
            editInvoice: true,
            editVehicle: true,
            addDriver: true,
            editDriver: true,
            viewDriver : true,
            addAddress: true,
            viewAddress: true, 
            editAddress: true,
            addContract: true,
            editProfile: true,
            viewProfile: true,
            addSettingsInSettingPanel: true,
            editSettingPanel: true,
            viewSettingPanel: true,
            viewDashboard: true,
            downloadReport: true,
            requestForGateInInspecFailed: true,
            requestForGateInInspecPassed: true,
            weighmentOfVehicle : true ,
            vehicleInspection : true ,
            driverInspection : true ,
            gpsDeviceInspection : true ,
            allowVehicleForGateIn : true ,
            addClauseForInspection : true ,
            waivePenalty : true ,
            rejectDriver : true ,
            addCustomerVehicle : true ,
            addTransporter : true ,
            viewTransporter: true,
            approveTransporter : true ,
            rejectTransporter : true ,
            allocateSalesOrderCustVehicle : true ,
            uploadDocuments : true ,
            pickupDropOffLocation : true ,
            addDepartment : true ,
            viewDepartment : true ,
            editOrganisation : true ,
            addBooking : true ,
            addPlant : true,
            viewPlant : true,
            editPlant : true ,
            editDepartment : true ,
            viewBookingDetails: true,
            arrivedAtDestination : true ,
            editContract : true,
            viewUser: true,
            viewContract: true,
            viewInvoice : true, 
            // viewlr: true
        }
    )

    const [permissionsObjTrans, setPermissonsObjTrans] = useState(
        {
            createUser: true,
            editUser: true,
            disableUser: true,
            editInvoice: true,
            addVehicle: true,
            editVehicle: true,
            addDriver: true,
            editDriver: true,
            addAddress: true,
            editAddress: true,
            addContract: true,
            editProfile: true,
            addSettingsInSettingPanel: true,
            editSettingPanel: true,
            viewDashboard: true,
            downloadReport: true,
            requestForGateInInspecPassed: true,
            requestForGateInInspecFailed: true,
            weighmentOfVehicle: true,
            vehicleInspection: true,
            driverInspection: true,
            gpsDeviceInspection: true,
            allowVehicleForGateIn: true,
            addClauseForInspection: true,
            waivePenalty: true,
            rejectDriver: true,
            addCustomerVehicle: true,
            addTransporter: true,
            approveTransporter: true,
            rejectTransporter: true,
            allocateSalesOrderCustVehicle: true,
            uploadDocuments: true,
            pickupDropOffLocation: true,
            addDepartment: true,
            viewDepartment: true,
            editOrganisation: true,
            addBooking: true,
            editPlant: true,
            editDepartment: true,
            arrivedAtDestination: true,
            editContract: true
           
        }
    )
    const setCheckBox = (item, value, index) => {
        let obj = { ...permissionsObj, [item]: value }
        setPermissonsObj(obj)

        for (const property in obj) {
            if (obj[property] === false) {
                setAllCheck(false)
                break;
            }
            else {
                setAllCheck(true)
            }

        }


    }
    const setAllCheckBox = (e) => {
        let check = e.target.checked
        let tempObj = {}

        for (let key in permissionsObj) {
            if (check) {
                tempObj[key] = true
                setAllCheck(true)
            }
            else {
                tempObj[key] = false
                setAllCheck(false)
            }

        }
        setPermissonsObj(tempObj)

    }
    const _handleClick = () => {
        setMap(true)
    }
    const _handleClick1 = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const handleChange = (item) => {
        setAddress(item)
    };
    const handleSelect = (item) => {
        geocodeByAddress(item)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setLat(latLng.lat)
                setLng(latLng.lng)
            })
            .catch(error => console.error('Error', error));
    };
    const locateOnMap = (e) => {
        setLat(e.latLng.lat())
        setLng(e.latLng.lng())
    }
    const fetchLocation = async () => {
        if (!navigator.geolocation) {
            setStatus('Geolocation is not supported by your browser');
        }
        else {
            setStatus('Locating...');
            navigator.geolocation.getCurrentPosition((position) => {
                setStatus(null);
                if (lat !== undefined || lng !== undefined) {
                    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAMbjwVD8stwnb3sKYaxCOefn6ranbU7bE`)
                        .then(response => {

                            setMap(false)
                            if (plantStatus !== true) {
                                registerPlant.lattitude = lat
                                registerPlant.longitude = lng
                                registerPlant.address = response.data.results[0].formatted_address
                                setAddress(response.data.results[0].formatted_address)
                            }
                            else {

                                updatePlant.Lattitude = lat
                                updatePlant.Longitude = lng
                                updatePlant.Address = response.data.results[0].formatted_address
                                setAddress(response.data.results[0].formatted_address)
                            }

                        })
                        .catch(error => {
                            console.log("error", error)
                        })
                }
                else {
                }

            }, () => {
                setStatus('Unable to retrieve your location');

                window.$.fn.show_notification({ message: `Unable to retrieve your location. Please enter manually. `, title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            });
        }
    }
    useEffect(() => {
        if (plantInfo) {
            setUpdatePlant(plantInfo)
            setBase(plantInfo)
        }
        if (userInfo) {
            setUpdateUser(userInfo)
        }
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        })
        setAddress(registerPlant.address)
        geocodeByAddress(registerPlant.address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setLat(latLng.lat)
                setLng(latLng.lng)
            })
            .catch(error => console.error('Error', error));

        if (registerUser.password.length !== 0 && registerUser.password === registerUser.confirmPassword) {
            setTick(true)
        }
        else {
            setTick(false)
        }
    }, [registerPlant, registerUser, dispatch, plantInfo, userInfo])
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
        setMap(false)
    }
    const _handleText = (item) => {
        if (item === 'department') {
            setToggleHead('department')
        }
        else if (item === 'userType')
            setToggleHead('userType')
    }
    const uploadFrontImage = async (e) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        if (plantStatus === true) {
            updatePlant.GstinUpload = base64
        }
        else {
            registerPlant.gstinUpload = base64
            setBase(base64)
            setGstShow(true)
        }
    }
    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const _onChange = (e) => {
        if (plantStatus === true) {
            setUpdatePlant({ ...updatePlant, [e.target.name]: e.target.value })
        }
        else {
            setRegisterPlant({ ...registerPlant, [e.target.name]: e.target.value })
        }
    }
    const createPlant = () => {
        if (registerPlant.plantName === '' || registerPlant.pinCode === '' || registerPlant.state === '' || registerPlant.pinCode === '' || registerPlant.city === '' || registerPlant.address === '' || registerPlant.gstin === '') {
            window.$.fn.show_notification({ message: `All Fields mandatory.. `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            if (registerPlant.password === registerPlant.confirmPassword) {
                setLoading(true)
                const token = localStorage.getItem('secretkey')
                const config = {
                    headers: { Authorization: token }
                };
                axios.post('https://www.betaenterprise.lynkit.io/api/plant/add-plant', { ...registerPlant }, { headers: config.headers })
                    .then(response => {
                        setLoading(false)
                        const err = response.data.error
                        const message = response.data.message
                        if (err === true) {
                            window.$.fn.show_notification({ message: `${message} `, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                        if (err === false) {
                            $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                            $(".custom-modal").removeClass("custom-modal-is-visible")
                            // dispatch(getUserList({
                            //     docType: "users",
                            //     bookmark: '',
                            //     param: "next"
                            // }))
                            window.location.reload();
                        }
                    })
                    .catch(error => {
                        // dispatch(showError(err))
                    })
            }
            else {
                window.$.fn.show_notification({ message: `Password does not match.. `, title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
        }
    }
    const toggleUserPassType = () => {
        if (userPasswordType == "password") {
            setUserPasswordType("text")
        }
        else {
            setUserPasswordType("password")
        }
    }
    const _handleChange = (e, item) => {
        if (userStatus !== true) {
            setRegisterUser({ ...registerUser, [e.target.name]: e.target.value })
        }
        else {
            if (item === 'plants') {
                var ind = e.target.value
                updateUser.PlantName = listing[ind].PlantName
                updateUser.PlantId = listing[ind]._id
                setUpdateUser(updateUser)
            }
            else if (item === 'departments') {
                var departInd = e.target.value
                updateUser.DepartmentName = departListing[departInd].DepartmentName
                updateUser.DepartmentId = departListing[departInd]._id
                setUpdateUser(updateUser)
            }
            else if (item === 'userType') {
                updateUser.UserType = e.target.value
                setUpdateUser({ ...updateUser, [e.target.name]: e.target.value })
            }
            else {
                setUpdateUser({ ...updateUser, [e.target.name]: e.target.value })
            }
        }

    }
    const createUser = () => {
        if (registerUser.departmentName === '' || registerUser.userType === '' || registerUser.plantName === '' || registerUser.name === '' || registerUser.mobileNo === '' || registerUser.emailId === '') {
            window.$.fn.show_notification({ message: `All Fields mandatory.. `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            if (registerUser.password === registerUser.confirmPassword) {
                setUserLoading(true)
                const token = localStorage.getItem('secretkey')
                const config = {
                    headers: { Authorization: token }
                };
                // let temp = {...registerUser}
                // temp.permissions = {...permissionsObj}
                axios.post('https://www.betaenterprise.lynkit.io/api/user/add-user', { ...registerUser }, { headers: config.headers })
                    .then(response => {
                        setUserLoading(false)
                        const err = response.data.error
                        const message = response.data.message
                        dispatch(getUserList({
                            param: "next",
                            pageSize: "",
                            bookmark: "",
                            departmentId: "",
                            plantId: "",
                            searchType: "",
                            searchValue: ""
                        }))
                        window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                        if (err === false) {
                            $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                            $(".custom-modal").removeClass("custom-modal-is-visible")
                            // window.location.reload();

                        }
                    })
                    .catch(error => {
                        // dispatch(showError(err))
                    })
            }
            else {
                window.$.fn.show_notification({ message: `Password does not match.. `, title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }
        }
    }
    const updatePlantFunction = () => {
        let changedObj = { ...updatePlant }
        const finalUpdate = {
            plantId: changedObj._id,
            plantName: changedObj.PlantName,
            address: changedObj.Address,
            country: "India",
            state: changedObj.State,
            city: changedObj.City,
            pinCode: changedObj.PinCode,
            gstin: changedObj.GSTIN,
            gstinUpload: changedObj.GstinUpload,
            lattitude: changedObj.Lattitude,
            longitude: changedObj.Longitude,
            status: changedObj.Status

        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/plant/UpdatePlant', { ...finalUpdate }, { headers: config.headers })
            .then(response => {
                const err = response.data.error
                const message = response.data.message
                dispatch(getPlantsListing({
                    docType: "plants",
                    bookmark: '',
                    param: "next"
                }))
                // dispatch(getUserList({
                //     param: "next",
                //     pageSize: "",
                //     bookmark: "",
                //     departmentId: "",
                //     plantId: "",
                //     searchType: "",  
                //     searchValue: ""
                // }))
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                if (err === false) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")

                }
            })
            .catch(error => {
                console.log('err', error)
            })
    }
    const SharedData = () => {
        setItem(updatePlant.GstinUpload.file_key)
    }
    const updateUserFunction = () => {
        let changedObj = { ...updateUser }
        const finalUpdate = {
            departmentName: changedObj.DepartmentName,
            departmentId: changedObj.DepartmentId,
            userType: changedObj.UserType,
            userId: changedObj._id,
            plantId: changedObj.PlantId,
            plantName: changedObj.PlantName,
            name: changedObj.Name,
            mobileNo: changedObj.MobileNo,
            emailId: changedObj.EmailId,
            address: changedObj.Address,
            city: changedObj.City,
            state: changedObj.State,
            pinCode: changedObj.PinCode,
            password: changedObj.Password,
            confirmPassword: changedObj.ConfirmPassword,
            userSubGroup: "Department",
            permissions: {
                createUser: true,
                editUser: true,
                viewUser: true,
                editHub: true,
                disableUser: true,
                viewListing: true,
                addPlant: true,
                viewPlant: true,
                addStaff: true,
                deleteStaff: true,
                viewStaff: true,
                editStaff: true,
                addHub: true,
                deleteHub: true,
                viewHub: true,
                viewInvoice: true,
                createInvoice: true,
                editInvoice: true,
                addVehicle: true,
                viewVehicle: true,
                editVehicle: true,
                addDriver: true,
                viewDriver: true,
                editDriver: true,
                addAddress: true,
                editAddress: true,
                viewAddress: true,
                addContract: true,
                viewContract: true,
                viewProfile: true,
                editProfile: true,
                changePassword: true,
                viewSettingPanel: true,
                addSettingsInSettingPanel: true,
                editSettingPanel: true,
                viewDashboard: true,
                downloadReport: true,
                vehicleAllocation: true,
                viewBookingDetails: true,
                requestForGateInInspecPassed: true,
                requestForGateInInspecFailed: true,
                weighmentOfVehicle: true,
                vehicleInspection: true,
                driverInspection: true,
                gpsDeviceInspection: true,
                allowVehicleForGateIn: true,
                addClauseForInspection: true,
                waivePenalty: true,
                rejectDriver: true,
                addCustomerVehicle: true,
                addTransporter: true,
                viewTransporter: true,
                approveTransporter: true,
                rejectTransporter: true,
                allocateSalesOrderCustVehicle: true,
                uploadDocuments: true,
                bookingViewDetails: true,
                pickupDropOffLocation: true,
                addDepartment: true,
                viewDepartment: true,
                editOrganisation: true,
                addBooking: true,
                editPlant: true,
                editDepartment: true,
                generatePOD: true,
                arrivedAtDestination: true,
                viewlr: true,
                editContract: true
            }

        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/user/UpdateUser', { ...finalUpdate }, { headers: config.headers })
            .then(response => {
                const err = response.data.error
                const message = response.data.message
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                if (err === false) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log('err', error)
            })
    }
    const _onDrop = (id, value) => {
        if (userStatus !== true) {
            if (id === 'plantSearch') {
                let temp = { ...ids }
                temp.plantId = value
                setIds(temp)
            }
            else if (id === 'departmentSelect') {
                let temp = { ...ids }
                temp.departmentId = value
                setIds(temp)
            }
            else if (id === 'userDrop') {
                let temp = { ...ids }
                temp.user = value
                setIds(temp)
            }
        }
        else {
            if (id === 'plantUpdateSearch') {
                let temp = { ...ids }
                temp.plantUpdateId = value
                setIds(temp)
            }
        }
    }
    useEffect(() => {
        if (userStatus) {
            setTimeout(() => {
                selectInit(_onDrop, "#plantSearch")
            }, 50);
        }
        if (ids.plantId !== '') {
            let temp = { ...registerUser }
            temp.plantName = listing[ids.plantId].PlantName
            temp.plantId = listing[ids.plantId]._id
            setRegisterUser(temp)
        }
        else if (ids.departmentId !== '') {
            let temp = { ...registerUser }
            temp.departmentName = departListing[ids.departmentId].DepartmentName
            temp.departmentId = departListing[ids.departmentId]._id
            setRegisterUser(temp)
        }
        else if (ids.user !== '') {
            let temp = { ...registerUser }
            temp.userType = ids.user
            setRegisterUser(temp)
        }
        else if (ids.plantUpdateId !== '') {
            let local = { ...updateUser }
            local.PlantName = listing[ids.plantUpdateId].PlantName
            local.PlantId = listing[ids.plantUpdateId]._id
            setUpdateUser(local)
        }
    }, [listing, ids, userStatus])
    // react select
    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white',
            width: '100%',

        }),
        control: (styles, state) => {
            return {
                ...styles,
                backgroundColor: "transparent",


                boxShadow:
                    state.isFocused
                        ? "0 0 3px #28a745"
                        : state.isFocused
                            ? "0 0 3px #dc3545"
                            : "none",
                cursor: "pointer",
                ":focus": {
                    ...styles[":focus"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                ":active": {
                    ...styles[":active"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },

                ":hover": {
                    ...styles[":hover"],
                    // borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                height: 35,
                minHeight: 35,
            };
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
            ...styles,
            background: isFocused ? '#ff7200' : isSelected ? '#fff' : undefined,
            color: isFocused ? '#fff' : 'black',
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? "var(--clr--accent)"
                        : undefined
                    : undefined,
            },
            zIndex: 1
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '35px',
            margin: '-8px 0 0 0'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '35px',
        }),


    }
    const options = [];
    listing && listing.map((item) => {
        options.push({ value: item._id, label: item.PlantName });
    });
    const _handlePlantChange = (item) => {
        if (userStatus !== true) {
            let temp = { ...registerUser }
            temp.plantName = item.label
            temp.plantId = item.value
            setRegisterUser(temp)
        }
        else {
            let local = { ...updateUser }
            local.PlantName = item.label
            local.PlantId = item.value
            setUpdateUser(local)
        }
    }
    const departmentOptions = [];
    departListing && departListing.map((item) => {
        departmentOptions.push({ value: item._id, label: item.DepartmentName });
    });
    const _handleDepartChange = (item) => {
        if (userStatus !== true) {
            let temp = { ...registerUser }
            temp.departmentName = item.label
            temp.departmentId = item.value
            setRegisterUser(temp)
        }
        else {
            let local = { ...updateUser }
            local.DepartmentName = item.label
            local.DepartmentId = item.value
            setUpdateUser(local)
        }
    }

    const userOptions = [
        { value: 'Admin', label: 'Admin' },
        { value: 'Users', label: 'Users' }
    ];

    const _handleUserChange = (item) => {
        if (userStatus !== true) {
            let temp = { ...registerUser }
            temp.userType = item.value
            setRegisterUser(temp)
        }
        else {
            let local = { ...updateUser }
            local.UserType = item.value
            setUpdateUser(local)
        }
    }

    const _userCloseModal = () => {
        setUpdateUser({
            DepartmentName: "",
            DepartmentId: "",
            UserType: "",
            PlantId: "",
            PlantName: "",
            Name: "",
            MobileNo: "",
            EmailId: "",
            Address: "",
            City: "",
            State: "",
            PinCode: "",
            Password: "",
            ConfirmPassword: "",
            UserSubGroup: "Department",
            Permissions: {
                CreateUser: true,
                EditUser: true,
                ViewUser: true,
                EditHub: true,
                DisableUser: true,
                ViewListing: true,
                AddPlant: true,
                ViewPlant: true,
                AddStaff: true,
                DeleteStaff: true,
                ViewStaff: true,
                EditStaff: true,
                AddHub: true,
                DeleteHub: true,
                ViewHub: true,
                ViewInvoice: true,
                CreateInvoice: true,
                EditInvoice: true,
                AddVehicle: true,
                ViewVehicle: true,
                EditVehicle: true,
                AddDriver: true,
                ViewDriver: true,
                EditDriver: true,
                AddAddress: true,
                EditAddress: true,
                ViewAddress: true,
                AddContract: true,
                ViewContract: true,
                ViewProfile: true,
                EditProfile: true,
                ChangePassword: true,
                ViewSettingPanel: true,
                AddSettingsInSettingPanel: true,
                EditSettingPanel: true,
                ViewDashboard: true,
                DownloadReport: true,
                VehicleAllocation: true,
                ViewBookingDetails: true,
                RequestForGateInInspecPassed: true,
                RequestForGateInInspecFailed: true,
                WeighmentOfVehicle: true,
                VehicleInspection: true,
                DriverInspection: true,
                GpsDeviceInspection: true,
                AllowVehicleForGateIn: true,
                AddClauseForInspection: true,
                WaivePenalty: true,
                RejectDriver: true,
                AddCustomerVehicle: true,
                AddTransporter: true,
                ViewTransporter: true,
                ApproveTransporter: true,
                RejectTransporter: true,
                AllocateSalesOrderCustVehicle: true,
                UploadDocuments: true,
                BookingViewDetails: true,
                PickupDropOffLocation: true,
                AddDepartment: true,
                ViewDepartment: true,
                EditOrganisation: true,
                AddBooking: true,
                EditPlant: true,
                EditDepartment: true,
                GeneratePOD: true,
                ArrivedAtDestination: true,
                Viewlr: true,
                editContract: true
            }
        })
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }

    return (
        <>
            {map === false ?
                props.sharedData.data === 'users' ?
                    <section id="addUserModal" className="custom-modal" style={{ width: "63%" }}>
                        <div className="custom-modal-header">
                            {props.sharedData.userdata === 'createUser' ?
                                <h1>Add User</h1>
                                :
                                <h1>Update User</h1>
                            }

                            <span className="ct-close" onClick={_userCloseModal}></span>
                        </div>
                        <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                            <div className="cust-row flexCol">
                                <div className='addUserDiv defHgt incremented'>
                                    <div className='addUserRow'>
                                        <div className='addUserInner'>
                                            <div>
                                                <span>Plant</span>
                                            </div>
                                            <div className='ccwdt reactSelect'>
                                                {
                                                    (updateUser.PlantName && userStatus) ?
                                                        <Select
                                                            onChange={(value) => _handlePlantChange(value)}
                                                            options={options}
                                                            styles={colourStyles}
                                                            value={{ label: updateUser.PlantName, value: updateUser.PlantId }}
                                                        />
                                                        :
                                                        !userStatus ?
                                                            <Select
                                                                onChange={(value) => _handlePlantChange(value)}
                                                                options={options}
                                                                styles={colourStyles}
                                                            /> : null
                                                }
                                            </div>
                                        </div>

                                        <div className='addUserInner'>
                                            <div>
                                                <span>Department</span>
                                                <button className='addBtnAbs' onClick={() => { _handleClick1('userModal'); _handleText('department') }}>Add</button>
                                            </div>
                                            <div className='ccwdt reactSelect'>
                                                {
                                                    (updateUser.DepartmentName && userStatus) ?
                                                        <Select
                                                            onChange={(value) => _handleDepartChange(value)}
                                                            options={departmentOptions}
                                                            styles={colourStyles}
                                                            value={{ label: updateUser.DepartmentName, value: updateUser.DepartmentId }}
                                                        />
                                                        :
                                                        !userStatus ?
                                                            <Select
                                                                onChange={(value) => _handleDepartChange(value)}
                                                                options={departmentOptions}
                                                                styles={colourStyles}
                                                            /> : null
                                                }
                                            </div>
                                        </div>
                                        <div className='addUserInner'>
                                            <div>
                                                {/* <button className='addBtnAbs' onClick={() => { _handleClick1('userModal'); _handleText('userType') }}>Add</button> */}
                                                <span>User Type</span>
                                            </div>
                                            <div className='ccwdt reactSelect'>
                                                {
                                                    (updateUser.UserType && userStatus) ?
                                                        <Select
                                                            onChange={(value) => _handleUserChange(value)}
                                                            options={userOptions}
                                                            styles={colourStyles}
                                                            value={{ label: updateUser.UserType, value: updateUser.UserType }}
                                                        />
                                                        :
                                                        !userStatus ?
                                                            <Select
                                                                onChange={(value) => _handleUserChange(value)}
                                                                options={userOptions}
                                                                styles={colourStyles}
                                                            /> : null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className='addUserRow'>
                                        <div className='addUserInner'>
                                            <span>Name</span>
                                            <input
                                                onChange={(e) => _handleChange(e, 'name')}
                                                type='text'
                                                placeholder=' Enter User Name'
                                                name={userStatus === true ? 'Name' : 'name'}
                                                value={userStatus === true ? updateUser && updateUser.Name ? updateUser.Name : '' : registerUser.name}
                                            />
                                        </div>
                                        <div className='addUserInner'>
                                            <span>Email Id</span>
                                            <input type='email'
                                                name={userStatus === true ? 'EmailId' : 'emailId'}
                                                value={userStatus === true ? updateUser && updateUser.EmailId ? updateUser.EmailId : '' : registerUser.emailId}
                                                onChange={(e) => _handleChange(e, 'email')} placeholder=' Enter Email Id' />
                                        </div>
                                        <div className='addUserInner'>
                                            <span>Mobile No.</span>
                                            <input
                                                type='number'
                                                name={userStatus === true ? 'MobileNo' : 'mobileNo'}
                                                value={userStatus === true ? updateUser && updateUser.MobileNo ? updateUser.MobileNo : '' : registerUser.mobileNo}
                                                onChange={(e) => _handleChange(e, 'mobile')}
                                                placeholder='Enter Mobile No.' />
                                        </div>

                                    </div>
                                    <div className='addUserRow'>
                                        <div className='addUserInner'>
                                            <span>Address</span>
                                            <input
                                                name={userStatus === true ? 'Address' : 'address'}
                                                value={userStatus === true ? updateUser && updateUser.Address ? updateUser.Address : '' : registerUser.address}
                                                onChange={(e) => _handleChange(e, 'address')} type='text' placeholder=' Enter Address' />
                                        </div>
                                        <div className='addUserInner'>
                                            <span>State</span>
                                            <input type='text'
                                                name={userStatus === true ? 'State' : 'state'}
                                                value={userStatus === true ? updateUser && updateUser.State ? updateUser.State : '' : registerUser.state}
                                                onChange={(e) => _handleChange(e, 'state')} placeholder=' Enter State' />
                                        </div>
                                        <div className='addUserInner'>
                                            <span>City</span>
                                            <input type='text'
                                                name={userStatus === true ? 'City' : 'city'}
                                                value={userStatus === true ? updateUser && updateUser.City ? updateUser.City : '' : registerUser.city}
                                                onChange={(e) => _handleChange(e, 'city')} placeholder='Enter City' />
                                        </div>
                                    </div>
                                    <div className='addUserRow'>
                                        <div className='addUserInner'>
                                            <span>PinCode</span>
                                            <input
                                                name={userStatus === true ? 'PinCode' : 'pinCode'}
                                                value={userStatus === true ? updateUser && updateUser.PinCode ? updateUser.PinCode : '' : registerUser.pinCode}
                                                onChange={(e) => _handleChange(e, 'pin')} type='number' placeholder=' Enter Pincode' />
                                        </div>
                                        <div className='addUserInner'>
                                            <span>Password</span>
                                            <input type={userPasswordType}
                                                name={userStatus === true ? 'Password' : 'password'}
                                                value={userStatus === true ? updateUser && updateUser.Password ? updateUser.Password : '' : registerUser.password}
                                                onChange={(e) => _handleChange(e, 'pass')} placeholder='Enter Password' />
                                            {userPasswordType === 'password' ?
                                                <i className="fa fa-eye-slash myVisibleEyeBlack usersEyeIconabs" onClick={toggleUserPassType}></i>
                                                :
                                                <i onClick={toggleUserPassType} className="fa fa-eye myVisibleEyeBlack usersEyeIconabs" ></i>
                                            }
                                        </div>
                                        <div className='addUserInner'>
                                            <span>Confirm Password</span>
                                            <input type='Password'
                                                name={userStatus === true ? 'ConfirmPassword' : 'confirmPassword'}
                                                value={userStatus === true ? updateUser && updateUser.ConfirmPassword ? updateUser.ConfirmPassword : '' : registerUser.confirmPassword}
                                                onChange={(e) => _handleChange(e, 'confirm')} placeholder='Re-enter Password' />
                                            {tick === true ?
                                                <i className="fa fa-check match myVisibleEyeBlack usersEyeIconabs"></i>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className='addUserDiv mgTop'>
                                    <div className='userHeading flexrow'>
                                        <span>Feature Permissions</span>
                                        <div className='selectAll'>
                                            <span className='selectText'>Select All</span>
                                            <input
                                                checked={allCheck === true ? true : false}
                                                onClick={setAllCheckBox} type='checkbox' onChange={e => { }} className='customCheckbox1' />
                                        </div>
                                    </div>

                                    <div className='addUserRow bgclr dynamicPermisiions'>

                                        {Object.entries(userType === 'goodsowner' ?  permissionsObj : permissionsObjTrans).map(([key, value], index) => {
                                            return (
                                                <div key={index} className='toggleBtns'>
                                                    <span>{key.replace(/([A-Z])/g, " $1").charAt(0).toUpperCase() + key.replace(/([A-Z])/g, " $1").slice(1)}</span>
                                                    <div className="flex-child-1">
                                                        <label className="switchWidth">
                                                            <input
                                                                checked={value === true ? true : false}
                                                                onClick={() => setCheckBox(key, !value, index)}
                                                                type="checkbox"
                                                                onChange={e => { }} />
                                                            <span className="sliderNew round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>































                                </div>
                                <div className='addaddressbottom createUserBtn'>
                                    {props.sharedData.userdata === 'createUser' ?
                                        <button onClick={() => createUser()}>Create User </button>
                                        :
                                        <button onClick={() => updateUserFunction()}>Update User </button>
                                    }
                                    {userLoading === true ?
                                        <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                                        : ''}
                                </div>
                            </div>
                            <UsersModal closeModal={_closeModal} text={toggleHead} />
                        </div>
                    </section>
                    :
                    <section id="addUserModal" className="custom-modal" style={{ width: "50%" }}>
                        <div className="custom-modal-header">
                            {props.sharedData.userdata === 'createPlant' ?
                                <h1>Add Plant</h1>
                                :
                                <h1>Edit Plant</h1>
                            }
                            <span className="ct-close" onClick={props.closeModal}></span>
                        </div>
                        <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                            <div className="cust-row flexCol">
                                <div className='addUserDiv addUserHeight'>
                                    <div className='addUserRow'>
                                        <div className='addUserInner'>
                                            <span>Plant Name</span>
                                            <input
                                                type='text'
                                                name={plantStatus === true ? 'PlantName' : 'plantName'}
                                                value={plantStatus === true ? updatePlant && updatePlant.PlantName ? updatePlant.PlantName : '' : registerPlant.plantName}
                                                onChange={(e) => _onChange(e)} placeholder='Enter Plant Name'
                                            />
                                        </div>
                                        <div className='addUserInner'>
                                            <span>Address</span>
                                            {plantStatus === true ?
                                                <button className='addBtnAbs allocateMap' onClick={() => { _handleClick() }}>Locate on Map</button>
                                                :
                                                registerPlant.address !== '' ?
                                                    <button className='addBtnAbs allocateMap' onClick={() => { _handleClick() }}>Locate on Map</button>
                                                    : ''
                                            }
                                            <input type='text' name={plantStatus === true ? 'Address' : 'address'} value={plantStatus === true ? updatePlant && updatePlant.Address ? updatePlant.Address : '' : registerPlant.address} onChange={(e) => _onChange(e)} placeholder='Enter Address' />
                                        </div>

                                        <div className='addUserInner'>
                                            <span>State</span>
                                            <input type='text' name={plantStatus === true ? 'State' : 'state'} value={plantStatus === true ? updatePlant && updatePlant.State ? updatePlant.State : '' : registerPlant.state} onChange={(e) => _onChange(e)} placeholder=' Enter State' />
                                        </div>
                                    </div>
                                    <div className='addUserRow'>
                                        <div className='addUserInner'>
                                            <span>City</span>
                                            <input type='text' name={plantStatus === true ? 'City' : 'city'} value={plantStatus === true ? updatePlant && updatePlant.City ? updatePlant.City : '' : registerPlant.city} onChange={(e) => _onChange(e)} placeholder='Enter City' />
                                        </div>
                                        <div className='addUserInner'>
                                            <span>Pin Code</span>
                                            <input type='number' name={plantStatus === true ? 'PinCode' : 'pinCode'} value={plantStatus === true ? updatePlant && updatePlant.PinCode ? updatePlant.PinCode : '' : registerPlant.pinCode} onChange={(e) => _onChange(e)} placeholder='Enter Pin code' />
                                        </div>

                                        <div className='addUserInner postRelative'>
                                            <span>GSTIN</span>
                                            <input type='text' name={plantStatus === true ? 'GSTIN' : 'gstin'} value={plantStatus === true ? updatePlant && updatePlant.GSTIN ? updatePlant.GSTIN : '' : registerPlant.gstin} onChange={(e) => _onChange(e)} placeholder='Enter GSTIN Number' />

                                            <div className='addUploadItem removeBrdr usersUpload'>
                                                <i className="fa fa-upload docUploadIcon usersUploadIcon"></i>
                                                <input className='addUploadfile' onChange={(e) => uploadFrontImage(e)} type="file" />
                                                {plantStatus === true ?
                                                    <i className="fa fa-eye myVisibleEyeBlack usersEyeIcon" onClick={() => { _handleClick1('Inspectimgmodal'); SharedData() }} ></i>
                                                    :
                                                    gstShow === true ?
                                                        <i className="fa fa-eye myVisibleEyeBlack usersEyeIcon" onClick={() => { _handleClick1('OverlapModal') }} ></i>
                                                        : ''
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className='addaddressbottom createUserBtn'>
                                    {props.sharedData.userdata === 'createPlant' ?
                                        <button onClick={() => createPlant()}>Create Plant </button>
                                        :
                                        <button onClick={() => updatePlantFunction()}>Update Plant </button>
                                    }
                                    {loading === true ?
                                        <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                                        : ''}
                                </div>
                            </div>
                            <UsersModal closeModal={_closeModal} text={toggleHead} />
                            <OverlapModal closeModal={_closeModal} imageData={base} />
                            <InspectImgModal base={imgItem} />
                        </div>
                    </section>

                :
                <section className="custom-modal" style={{ width: '80vw', maxHeight: '90vh' }}>
                    <div className="custom-modal-header">
                        <h1>Locate On Map</h1>
                        <span className="ct-close" onClick={() => _closeModal()}></span>
                    </div>

                    <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                        <PlacesAutocomplete
                            value={address}
                            onChange={(e) => handleChange(e, address)}
                            onSelect={(e) => handleSelect(e, address)}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'location-search-input',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            const style = suggestion.active
                                                ? { backgroundColor: '#ff7200', cursor: 'pointer', color: '#fff', fontSize: '0.8rem', width: '100%', borderRadius: '3px', padding: '3px' }
                                                : {
                                                    backgroundColor: '#ffffff', cursor: 'pointer', fontSize: '0.8rem', border: '1px solid rgb(0 0 0 / 21%)', width: '100%',
                                                    padding: '3px', borderRadius: '3px'
                                                };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                        <div className="cust-row">
                            {lat !== '' & lng !== '' ?
                                <div className='mapOuter'>
                                    <GoogleMap
                                        id="mapDimensions"
                                        zoom={18}
                                        center={{ lat: lat, lng: lng }}
                                        onClick={(e) => locateOnMap(e)}
                                    >
                                        <Marker position={{ lat: lat, lng: lng }} />
                                    </GoogleMap>
                                    <button className='addLocation' onClick={fetchLocation}>Add Location</button>
                                </div>
                                :
                                <p className='alertText'>Unable to fetch location . Please Enter Correct Address.</p>
                            }
                        </div>
                    </div>

                </section>
            }
        </>
    )

}
export default AddUser;