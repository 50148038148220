
import {ADDRESS_DATA} from './types'
import { post } from './../../../services/requests'
import $ from 'jquery'
import history from './../../../services/history'

export const getAddress = (data) =>{  
    return(dispatch)=>{
        post('addressbook/RegisterAddressBook',{...data})
        .then(response=>{
             console.log('register response',response);
             var error =response.error
             var message = response.message 
             dispatch(sendAddressData(data))
             if(error === false){
                $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                $(".custom-modal").removeClass("custom-modal-is-visible")
                history.push('/dashboard/address-book')
                window.location.reload();
             }
             else{
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                }) 
             }
             
             
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const sendAddressData = (data) =>{
    return{
        type :ADDRESS_DATA,
        payload:data
    }
}