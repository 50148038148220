import { post } from './../../../services/requests'
import {DRIVER_DATA} from './types'
import history from './../../../services/history'

export const getDriverData = (data) =>{ 
    return(dispatch)=>{
        post('driver/adddriver',{...data})
        .then(response=>{
            const status= response.error
            const message= response.message
             dispatch(sendDriverData(data))
             if(status === true){
                  window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                }) 
             }
             else{
                history.push('/dashboard/workforce')
                // window.location.reload();
             }
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const sendDriverData = (data) =>{
    return{
        type :DRIVER_DATA,
        payload:data
    }
}

