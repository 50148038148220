import {GET_DEPARTMENT} from  "../actions/types";

const initialState={
    departmentData: []
} 

const getDepartReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_DEPARTMENT : return{
            ...state,
            departmentData : payload
        }
        default: return state;  
    }
}
export default getDepartReducer;