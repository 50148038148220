import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery'
import { selectInit } from './../../services/functions'
import DashboardIcon from './../../static/images/icon/sidebar/updated/dashboard.svg'
import BookingIcon from './../../static/images/icon/sidebar/updated/booking.svg'
import VehicleIcon from './../../static/images/icon/sidebar/updated/vehicles.svg'
import ReceiptIcon from './../../static/images/icon/sidebar/updated/goods_receipt.svg'
import AddressIcon from './../../static/images/icon/sidebar/updated/address_book.svg'
import HubIcon from './../../static/images/icon/sidebar/updated/hubs.svg'
import DriverIcon from './../../static/images/icon/sidebar/updated/driver.svg'
import AnalyticIcon from './../../static/images/icon/sidebar/updated/analytics.svg'
import ContractIcon from './../../static/images/icon/sidebar/updated/contracts.svg'
import DashboardActiveIcon from './../../static/images/icon/sidebar/updated/dashboard_active.svg'
import PoDIcon from './../../static/images/icon/sidebar/updated/pod.svg'
import FileLogo from './../../static/images/icon/vehicle/upload-file.svg'
import SignatureCanvas from 'react-signature-canvas'

const MySettings = () => {
    useEffect(() => {
        setTimeout(() => {
            selectInit()
        }, 100);
    })

    let sigPad = useRef({})
    const [itemValue, setItemValue] = useState()
    const [imgURL, setImgURL] = useState()
    const [newimgURL, setNewImgUrl] = useState()
    const [signature64, setSignature64] = useState("")

    const [viewGR, setGR] = useState("addTermsRight")
    const [viewPI, setPI] = useState("hide")

    const [grBorder, setGrBorder] = useState("toggleBorder")
    const [piborder, setprborder] = useState("addTermsBottom")

    const [grspan, setgrspan] = useState("addTermsSpan-new")
    const [pispan, setpispan] = useState("addTermsSpan")
    // 
    const [viewGR_new, setGR_new] = useState("ViewTerms")
    const [viewPI_new, setPI_new] = useState("hide")

    const [grBorder_new, setGrBorder_new] = useState("toggleBorder")
    const [piborder_new, setprborder_new] = useState("addTermsBottom")

    const [grspan_new, setgrspan_new] = useState("addTermsSpan-new")
    const [pispan_new, setpispan_new] = useState("addTermsSpan")
    const [elemetArray, setElementArray] = useState([{ index: 1 }])
    const [newElemetArray, setNewElementArray] = useState([{ index: 1 }])
    const [addBasicArray, setaddBasicArray] = useState([{ index: 1 }])
    const [addBasicEmail, setaddBasicEmail] = useState([{ index: 1 }])

    const [pdfBasicArray, setPdfBasicArray] = useState([{ index: 1 }])
    const [pdfFaxArray, setFaxArray] = useState([{ index: 1 }])
    const [pdfBottomArray, setBottomArray] = useState([{ index: 1 }])

    const [pink, setPink] = useState(false)
    const [blue, setBlue] = useState(false)
    const [green, setGreen] = useState(false)
    const [yellow, setYellow] = useState(false)

    const [cpink, setcPink] = useState(false)
    const [cblue, setcBlue] = useState(false)
    const [cgreen, setcGreen] = useState(false)
    const [cyellow, setcYellow] = useState(false)

    const [consPink, setconsPink] = useState(false)
    const [consBlue, setconsBlue] = useState(false)
    const [consGreen, setconsGreen] = useState(false)
    const [consYellow, setconsYellow] = useState(false)




    const toogleHubView = (item,index) => {
       
        setItemValue(item)

        if (item === "one") {
            if ($(".settings-list2").eq(index).hasClass("color") && ($(".hide-view").eq(index).hasClass("open"))) {

                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".settings-list2").removeClass("color");
                $(".settings-list2").animate({ height: 45 }, 500);

                $(".settings-arrow-icon").removeClass("rotate");
                $(".settings-arrow-icon").animate({ height: 25 }, 100);

                $(".settings-span").removeClass("newColor");
                $(".settings-span").animate({ height: 25 }, 100);

            }
            else {

                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".settings-list2").animate({}, 500, () => {
                    $(".settings-list2").addClass("color");
                });
                $(".settings-arrow-icon").animate({ height: 25 }, 100, () => {
                    $(".settings-arrow-icon").addClass("rotate");
                });
                $(".settings-span").animate({ height: 25 }, 100, () => {
                    $(".settings-span").eq(index).addClass("newColor");
                });
                $(".toggle-settings-list2").removeClass("color");
                $(".toggle-settings-list2").animate({ height: 45 }, 500);

                $(".toggle-settings-arrow-icon").removeClass("rotate");
                $(".toggle-settings-arrow-icon").animate({ height: 25 }, 100);

                $(".settings-span-2").removeClass("newColor");
                $(".settings-span-2").animate({ height: 25 }, 100);
            }
        }
        else if (item === "two") {
            if ($(".toggle-settings-list2").eq(index).hasClass("color") && ($(".hide-view").eq(index).hasClass("open"))) {

                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".toggle-settings-list2").removeClass("color");
                $(".toggle-settings-list2").eq(index).animate({ height: 45 }, 500);

                $(".toggle-settings-arrow-icon").removeClass("rotate");
                $(".toggle-settings-arrow-icon").animate({ height: 25 }, 100);

                $(".settings-span-2").removeClass("newColor");
                $(".settings-span-2").animate({ height: 25 }, 100);
            }
            else {
                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".toggle-settings-list2").animate({}, 500, () => {
                    $(".toggle-settings-list2").addClass("color");
                });

                $(".toggle-settings-arrow-icon").animate({ height: 25 }, 100, () => {
                    $(".toggle-settings-arrow-icon").addClass("rotate");
                });

                $(".settings-span-2").animate({ height: 25 }, 100, () => {
                    $(".settings-span-2").addClass("newColor");
                });
                $(".settings-list2").removeClass("color");
                $(".settings-list2").animate({ height: 45 }, 500);

                $(".settings-arrow-icon").removeClass("rotate");
                $(".settings-arrow-icon").animate({ height: 25 }, 100);

                $(".settings-span").removeClass("newColor");
                $(".settings-span").animate({ height: 25 }, 100);

            }
        }
        else if (item === "three") {
            if ($(".settings-list-3").eq(index).hasClass("color") || ($(".hide-view").eq(index).hasClass("open"))) {

                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".settings-list-3").removeClass("color");
                $(".settings-list-3").animate({ height: 45 }, 500);

                $(".settings-arrow-icon-3").removeClass("rotate");
                $(".settings-arrow-icon-3").eq(index).animate({ height: 25 }, 100);

                $(".settings-span-3").removeClass("newColor");
                $(".settings-span-3").animate({ height: 25 }, 100);
            }
            else {
                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".settings-list-3").animate({}, 500, () => {
                    $(".settings-list-3").addClass("color");
                });

                $(".settings-arrow-icon-3").animate({ height: 25 }, 100, () => {
                    $(".settings-arrow-icon-3").addClass("rotate");
                });

                $(".settings-span-3").animate({ height: 25 }, 100, () => {
                    $(".settings-span-3").addClass("newColor");
                });


            }
        }
        else if (item === "four") {
            if ($(".settings-list-4").eq(index).hasClass("color") || ($(".hide-view").eq(index).hasClass("open"))) {

                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".settings-list-44").removeClass("color");
                $(".settings-list-44").animate({ height: 45 }, 500);

                $(".settings-arrow-icon-44").removeClass("rotate");
                $(".settings-arrow-icon-44").eq(index).animate({ height: 25 }, 100);

                $(".settings-span-44").removeClass("newColor");
                $(".settings-span-44").animate({ height: 25 }, 100);
            }
            else {
                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".settings-list-44").animate({}, 500, () => {
                    $(".settings-list-44").addClass("color");
                });

                $(".settings-arrow-icon-44").animate({ height: 25 }, 100, () => {
                    $(".settings-arrow-icon-44").addClass("rotate");
                });

                $(".settings-span-44").animate({ height: 25 }, 100, () => {
                    $(".settings-span-44").addClass("newColor");
                });


            }
        }
        else if (item === "five") {
            if ($(".settings-list4").hasClass("color") && ($(".hide-view").hasClass("open"))) {

                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".settings-list4").removeClass("color");
                $(".settings-list4").animate({ height: 45 }, 500);

                $(".settings-arrow-icon-4").removeClass("rotate");
                $(".settings-arrow-icon-4").animate({ height: 25 }, 100);

                $(".settings-span-4").removeClass("newColor");
                $(".settings-span-4").animate({ height: 25 }, 100);

            }
            else {
                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".settings-list4").animate({}, 500, () => {
                    $(".settings-list4").addClass("color");
                });
                $(".settings-arrow-icon-4").animate({ height: 25 }, 100, () => {
                    $(".settings-arrow-icon-4").addClass("rotate");
                });
                $(".settings-span-4").animate({ height: 25 }, 100, () => {
                    $(".settings-span-4").addClass("newColor");
                });
                // 
                $(".changeBack1").removeClass("color");
                $(".changeBack1").animate({ height: 45 }, 500);

                $(".changeArrow1").removeClass("rotate");
                $(".changeArrow1").animate({ height: 25 }, 100);

                $(".changeText1").removeClass("newColor");
                $(".changeText1").animate({ height: 25 }, 100);
            }
        }
        else if (item === "six") {
            if ($(".changeBack1").hasClass("color") && ($(".hide-view").hasClass("open"))) {
                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".changeBack1").removeClass("color");
                $(".changeBack1").animate({ height: 45 }, 500);

                $(".changeArrow1").removeClass("rotate");
                $(".changeArrow1").animate({ height: 25 }, 100);

                $(".changeText1").removeClass("newColor");
                $(".changeText1").animate({ height: 25 }, 100);

            }
            else {
                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".changeBack1").animate({}, 500, () => {
                    $(".changeBack1").addClass("color");
                });
                $(".changeArrow1").animate({ height: 25 }, 100, () => {
                    $(".changeArrow1").addClass("rotate");
                });
                $(".changeText1").animate({ height: 25 }, 100, () => {
                    $(".changeText1").addClass("newColor");
                });
                // 
                $(".settings-list4").removeClass("color");
                $(".settings-list4").animate({ height: 45 }, 500);

                $(".settings-arrow-icon-4").removeClass("rotate");
                $(".settings-arrow-icon-4").animate({ height: 25 }, 100);

                $(".settings-span-4").removeClass("newColor");
                $(".settings-span-4").animate({ height: 25 }, 100);
            }
        }
        else if (item === "seven") {
            if ($(".settings-list-7").hasClass("color") && ($(".hide-view").hasClass("open"))) {
                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".settings-list-7").removeClass("color");
                $(".settings-list-7").animate({ height: 45 }, 500);

                $(".settings-arrow-icon-7").removeClass("rotate");
                $(".settings-arrow-icon-7").animate({ height: 25 }, 100);

                $(".settings-span-7").removeClass("newColor");
                $(".settings-span-7").animate({ height: 25 }, 100);

            }
            else {
                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".settings-list-7").animate({}, 500, () => {
                    $(".settings-list-7").addClass("color");
                });
                $(".settings-arrow-icon-7").animate({ height: 25 }, 100, () => {
                    $(".settings-arrow-icon-7").addClass("rotate");
                });
                $(".settings-span-7").animate({ height: 25 }, 100, () => {
                    $(".settings-span-7").addClass("newColor");
                });
                // 
                $(".changeBack2").removeClass("color");
                $(".changeBack2").animate({ height: 45 }, 500);

                $(".changeArrow2").removeClass("rotate");
                $(".changeArrow2").animate({ height: 25 }, 100);

                $(".changeText2").removeClass("newColor");
                $(".changeText2").animate({ height: 25 }, 100);
            }
        }
        else if (item === "eight") {
            if ($(".changeBack2").hasClass("color") && ($(".hide-view").hasClass("open"))) {
                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".changeBack2").removeClass("color");
                $(".changeBack2").animate({ height: 45 }, 500);

                $(".changeArrow2").removeClass("rotate");
                $(".changeArrow2").animate({ height: 25 }, 100);

                $(".changeText2").removeClass("newColor");
                $(".changeText2").animate({ height: 25 }, 100);

            }
            else {
                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".changeBack2").animate({}, 500, () => {
                    $(".changeBack2").addClass("color");
                });
                $(".changeArrow2").animate({ height: 25 }, 100, () => {
                    $(".changeArrow2").addClass("rotate");
                });
                $(".changeText2").animate({ height: 25 }, 100, () => {
                    $(".changeText2").addClass("newColor");
                });
                // 
                $(".settings-list-7").removeClass("color");
                $(".settings-list-7").animate({ height: 45 }, 500);

                $(".settings-arrow-icon-7").removeClass("rotate");
                $(".settings-arrow-icon-7").animate({ height: 25 }, 100);

                $(".settings-span-7").removeClass("newColor");
                $(".settings-span-7").animate({ height: 25 }, 100);
            }
        }
        else if (item === "nine") {
            if ($(".settings-list-9").hasClass("color") && ($(".hide-view").hasClass("open"))) {
                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".settings-list-9").removeClass("color");
                $(".settings-list-9").animate({ height: 45 }, 500);

                $(".settings-arrow-icon-9").removeClass("rotate");
                $(".settings-arrow-icon-9").animate({ height: 25 }, 100);

                $(".settings-span-9").removeClass("newColor");
                $(".settings-span-9").animate({ height: 25 }, 100);

            }
            else {
                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".settings-list-9").animate({}, 500, () => {
                    $(".settings-list-9").addClass("color");
                });
                $(".settings-arrow-icon-9").animate({ height: 25 }, 100, () => {
                    $(".settings-arrow-icon-9").addClass("rotate");
                });
                $(".settings-span-9").animate({ height: 25 }, 100, () => {
                    $(".settings-span-9").addClass("newColor");
                });
                // 
                $(".changeBack3").removeClass("color");
                $(".changeBack3").animate({ height: 45 }, 500);

                $(".changeArrow3").removeClass("rotate");
                $(".changeArrow3").animate({ height: 25 }, 100);

                $(".changeText3").removeClass("newColor");
                $(".changeText3").animate({ height: 25 }, 100);

            }
        }
        else if (item === "ten") {
            if ($(".changeBack3").hasClass("color") && ($(".hide-view").hasClass("open"))) {
                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".changeBack3").removeClass("color");
                $(".changeBack3").animate({ height: 45 }, 500);

                $(".changeArrow3").removeClass("rotate");
                $(".changeArrow3").animate({ height: 25 }, 100);

                $(".changeText3").removeClass("newColor");
                $(".changeText3").animate({ height: 25 }, 100);

            }
            else {
                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".changeBack3").animate({}, 500, () => {
                    $(".changeBack3").addClass("color");
                });
                $(".changeArrow3").animate({ height: 25 }, 100, () => {
                    $(".changeArrow3").addClass("rotate");
                });
                $(".changeText3").animate({ height: 25 }, 100, () => {
                    $(".changeText3").addClass("newColor");
                });
                // 
                $(".settings-list-9").removeClass("color");
                $(".settings-list-9").animate({ height: 45 }, 500);

                $(".settings-arrow-icon-9").removeClass("rotate");
                $(".settings-arrow-icon-9").animate({ height: 25 }, 100);

                $(".settings-span-9").removeClass("newColor");
                $(".settings-span-9").animate({ height: 25 }, 100);
            }
        }
        else if (item === "eleven") {
            if ($(".settings-list-11").hasClass("color") && ($(".hide-view").hasClass("open"))) {
                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".settings-list-11").removeClass("color");
                $(".settings-list-11").animate({ height: 45 }, 500);

                $(".settings-arrow-icon-11").removeClass("rotate");
                $(".settings-arrow-icon-11").animate({ height: 25 }, 100);

                $(".settings-span-11").removeClass("newColor");
                $(".settings-span-11").animate({ height: 25 }, 100);

            }
            else {
                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".settings-list-11").animate({}, 500, () => {
                    $(".settings-list-11").addClass("color");
                });
                $(".settings-arrow-icon-11").animate({ height: 25 }, 100, () => {
                    $(".settings-arrow-icon-11").addClass("rotate");
                });
                $(".settings-span-11").animate({ height: 25 }, 100, () => {
                    $(".settings-span-11").addClass("newColor");
                });
                // 
                $(".changeBack").removeClass("color");
                $(".changeBack").animate({ height: 45 }, 500);

                $(".changeArrow").removeClass("rotate");
                $(".changeArrow").animate({ height: 25 }, 100);

                $(".changeText").removeClass("newColor");
                $(".changeText").animate({ height: 25 }, 100);

            }
        }
        else if (item === "twelve") {
            if ($(".changeBack").hasClass("color") && ($(".hide-view").hasClass("open"))) {
                $(".hide-view").eq(index).removeClass("open");
                $(".hide-view").eq(index).animate({ height: 45 }, 500);

                $(".changeBack").removeClass("color");
                $(".changeBack").animate({ height: 45 }, 500);

                $(".changeArrow").removeClass("rotate");
                $(".changeArrow").animate({ height: 25 }, 100);

                $(".changeText").removeClass("newColor");
                $(".changeText").animate({ height: 25 }, 100);

            }
            else {
                let height = Number($(".hide-view").eq(index).children("div").height()) + 2;
                $(".hide-view").eq(index).animate({ height }, 500, () => {
                    $(".hide-view").eq(index).addClass("open");
                });

                $(".changeBack").animate({}, 500, () => {
                    $(".changeBack").addClass("color");
                });
                $(".changeArrow").animate({ height: 25 }, 100, () => {
                    $(".changeArrow").addClass("rotate");
                });
                $(".changeText").animate({ height: 25 }, 100, () => {
                    $(".changeText").addClass("newColor");
                });
                // 
                $(".settings-list-11").removeClass("color");
                $(".settings-list-11").animate({ height: 45 }, 500);

                $(".settings-arrow-icon-11").removeClass("rotate");
                $(".settings-arrow-icon-11").animate({ height: 25 }, 100);

                $(".settings-span-11").removeClass("newColor");
                $(".settings-span-11").animate({ height: 25 }, 100);
            }
        }
       

    }















    const uploadImage = (e) => {
        const File = e.target.files[0]
        const reader = new FileReader();

        reader.onloadend = () => {
            setImgURL(reader.result)
        }
        reader.readAsDataURL(File);
        setImgURL(reader.result)



    }
    const clearSign = () => {
        sigPad.current.clear();
    }
    const showPreview = () => {
        setNewImgUrl(imgURL)
        let data = sigPad.current.toDataURL();
        setSignature64(data)
    }
    const getUserSignbase = () => {
        let data = sigPad.current.toDataURL();
        setSignature64(data)
    }
    const submitandsave = () => {
        if (signature64 !== "") {
            let data = sigPad.current.toDataURL();
            setSignature64(data)
        }
        else {

        }
    }
    const viewGRTC = () => {
        setGR("addTermsRight")
        setPI("hide")

        setGrBorder("toggleBorder")
        setprborder("addTermsBottom")

        setgrspan("addTermsSpan-new")
        setpispan("addTermsSpan")
    }
    const viewPITC = () => {
        setGR("hide")
        setPI("ViewTerms")

        setGrBorder("addTermsTop")
        setprborder("toggleBorder")

        setgrspan("addTermsSpan")
        setpispan("addTermsSpan-new")
    }
    const viewGRTC_1 = () => {
        setGR_new("ViewTerms")
        setPI_new("hide")

        setGrBorder_new("toggleBorder")
        setprborder_new("addTermsBottom")

        setgrspan_new("addTermsSpan-new")
        setpispan_new("addTermsSpan")
    }
    const viewPITC_1 = () => {
        setGR_new("hide")
        setPI_new("ViewTerms-new")

        setGrBorder_new("addTermsTop")
        setprborder_new("toggleBorder")

        setgrspan_new("addTermsSpan")
        setpispan_new("addTermsSpan-new")
    }
    const increaseInputField = () => {
        let randomNum = Math.floor(Math.random() * 10) + 1
        setElementArray([...elemetArray, { index: randomNum }])
    }
    const removeInputField = (index) => {
        let tempArr = [...elemetArray]
        tempArr.splice(index, 1)
        setElementArray(tempArr)
    }
    const increaseInputFieldPI = () => {
        let randomNum = Math.floor(Math.random() * 10) + 1
        setNewElementArray([...newElemetArray, { index: randomNum }])
    }
    const removeInputFieldpPI = (index) => {
        let tempArr = [...newElemetArray]
        tempArr.splice(index, 1)
        setNewElementArray(tempArr)
    }
    const addBasicInput = () => {
        let randomNum = Math.floor(Math.random() * 10) + 1
        setaddBasicArray([...addBasicArray, { index: randomNum }])
    }
    const removeBasicInput = (index) => {
        let tempArr = [...addBasicArray]
        tempArr.splice(index, 1)
        setaddBasicArray(tempArr)
    }
    const addBasicEmailFun = () => {
        let randomNum = Math.floor(Math.random() * 10) + 1
        setaddBasicEmail([...addBasicEmail, { index: randomNum }])
    }
    const removeBasicEmail = (index) => {
        let tempArr = [...addBasicEmail]
        tempArr.splice(index, 1)
        setaddBasicEmail(tempArr)
    }
    const pdfNumFun = () => {
        let randomNum = Math.floor(Math.random() * 10) + 1
        setPdfBasicArray([...pdfBasicArray, { index: randomNum }])
    }
    const removepdfNum = (index) => {
        let tempArr = [...pdfBasicArray]
        tempArr.splice(index, 1)
        setPdfBasicArray(tempArr)
    }
    const pdffaxFun = () => {
        let randomNum = Math.floor(Math.random() * 10) + 1
        setFaxArray([...pdfFaxArray, { index: randomNum }])
    }
    const removefaxNum = (index) => {
        let tempArr = [...pdfFaxArray]
        tempArr.splice(index, 1)
        setFaxArray(tempArr)
    }
    const pdfBottomFun = () => {
        let randomNum = Math.floor(Math.random() * 10) + 1
        setBottomArray([...pdfBottomArray, { index: randomNum }])
    }
    const removebottomNum = (index) => {
        let tempArr = [...pdfBottomArray]
        tempArr.splice(index, 1)
        setBottomArray(tempArr)
    }
    const chooseColor = (item) => {
        console.log(item);
        if (item.type === 'transporter') {
            if (item.num === '1') {
                setPink(true)
                setBlue(false)
                setGreen(false)
                setYellow(false)
            }
            else if (item.num === '2') {
                setBlue(true)
                setGreen(false)
                setYellow(false)
                setPink(false)
            }
            else if (item.num === '3') {
                setGreen(true)
                setPink(false)
                setBlue(false)
                setYellow(false)
            }
            else if (item.num === '4') {
                setYellow(true)
                setPink(false)
                setBlue(false)
                setGreen(false)
            }
        }
        else if (item.type === 'consignor') {
            if (item.num === '1') {
                setcPink(true)
                setcBlue(false)
                setcGreen(false)
                setcYellow(false)
            }
            else if (item.num === '2') {
                setcBlue(true)
                setcGreen(false)
                setcYellow(false)
                setcPink(false)
            }
            else if (item.num === '3') {
                setcGreen(true)
                setcPink(false)
                setcBlue(false)
                setcYellow(false)
            }
            else if (item.num === '4') {
                setcYellow(true)
                setcPink(false)
                setcBlue(false)
                setcGreen(false)
            }
        }
        else if(item.type === 'consignee') {
            if (item.num === '1') {
                setconsPink(true)
                setconsBlue(false)
                setconsGreen(false)
                setconsYellow(false)
            }
            else if (item.num === '2') {
                setconsBlue(true)
                setconsGreen(false)
                setconsYellow(false)
                setconsPink(false)
            }
            else if (item.num === '3') {
                setconsGreen(true)
                setconsPink(false)
                setconsBlue(false)
                setcYellow(false)
            }
            else if (item.num === '4') {
                setconsYellow(true)
                setconsPink(false)
                setconsBlue(false)
                setconsGreen(false)
            }
    }

    }
    return (
        <>
            <div className="contentpanel">
                <h1 className="title">My Settings</h1>
                <hr />

                <div className="cust-container-new">
                    <div className="cust-row">
                        <div className="cust-col-10">

                            <div className='settings-outer hide-view'>
                                <div className='varying-height'>
                                    <div className='settings-inner'>
                                        <div className='settings-list'>
                                            <div className='settings-list1'>
                                                <span className='settings-span'>Add Bank Details</span>
                                            </div>
                                            <div onClick={() => toogleHubView("one",0)} className='settings-list2'>
                                                <i className="fa fa-angle-right settings-arrow-icon" ></i>
                                            </div>
                                            <div className='settings-list3'>
                                                <span className='settings-span-2'>View Bank Details</span>
                                            </div>
                                            <div onClick={() => toogleHubView("two",0)} className='toggle-settings-list2'>
                                                <i className="fa fa-angle-right toggle-settings-arrow-icon"></i>
                                            </div>
                                            <div className='settings-list5'>
                                                <div className='setting5-I1'>
                                                    <div className='settings-inner-1'>
                                                        <span className='settings-span1'>Show Bank Detail in LR ?</span>
                                                    </div>
                                                    <div className='settings-inner-2'>
                                                        <button className='settings-yes'>Yes</button>
                                                        <button className='settings-no'>No</button>
                                                    </div>
                                                </div>
                                                <div className='setting5-I2'>
                                                    <div className='settings-inner-1'>
                                                        <span className='settings-span1'>Show Bank Detail in Proforma Invoice ?</span>
                                                    </div>
                                                    <div className='settings-inner-2'>
                                                        <button className='settings-yes'>Yes</button>
                                                        <button className='settings-no'>No</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {itemValue === "one" ?
                                        <div className="bankDivOuter-is-visible">
                                            <div className='bankDetail1'>
                                                <div className='bankDetailInner'>
                                                    <input className='bankDetailInputField' type="text" placeholder="Account Number" />
                                                </div>
                                                <div className='bankDetailInner'>
                                                    <select className='bankDetailInputField'>
                                                        {/* <select className='form-control select2-single'> */}
                                                        <option>Account Type</option>
                                                        <option>Business Account </option>
                                                        <option>Personal Account</option>
                                                    </select>
                                                </div>
                                                <div className='bankDetailInner'>
                                                    <input className='bankDetailInputField' type="text" placeholder="Beneficiary Name" />
                                                </div>
                                            </div>
                                            <div className='bankDetail1'>
                                                <div className='bankDetailInner'>
                                                    <input className='bankDetailInputField' type="text" placeholder="Bank Name" />
                                                </div>

                                                <div className='bankDetailInner'>
                                                    <input className='bankDetailInputField' type="text" placeholder="Branch Name" />
                                                </div>

                                                <div className='bankDetailInner'>
                                                    <input className='bankDetailInputField' type="text" placeholder="IFSC Code" />
                                                </div>

                                            </div>

                                            <div className='bankDetail1'>
                                                <div className='bankDetalsbtnDiv1'>
                                                    <button className='bankDetailbtn'>Submit</button>
                                                    <button className='bankDetailbtn'>Cancel</button>
                                                </div>

                                                <div className='bankDetalsbtnDiv1'></div>

                                                <div className='bankDetalsbtnDiv1'>

                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <div className="ViewBankDetails-is-visible">
                                            <div className='viewBankDetails1'>
                                                <div className='vieBankDetailInner1'>
                                                    <div className='viewDetailsend'>
                                                        <div className='settings-inner-1'>
                                                            <span className='settings-span1'>Enable For PDF ?</span>
                                                        </div>

                                                        <div className='settings-inner-2 wd-10'>
                                                            <button className='settings-yes'>Yes</button>
                                                            <button className='settings-no'>No</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='vieBankDetailInner2'>
                                                    <div className='set_wd_15'>
                                                        <span className='bold'>Beneficiary Name:</span>
                                                        <span className='light'>Aman Sharma</span>
                                                    </div>

                                                    <div className='set_wd_15'>
                                                        <span className='bold'>Bank Name:</span>
                                                        <span className='light'>HDFC</span>
                                                    </div>

                                                    <div className='set_wd_15'>
                                                        <span className='bold'>Account No. :</span>
                                                        <span className='light'>H09876111</span>
                                                    </div>

                                                    <div className='set_wd_15'>
                                                        <span className='bold'>IFSC Code:</span>
                                                        <span className='light'>HDFC0535486</span>
                                                    </div>

                                                    <div className='set_wd_15'>
                                                        <span className='bold'>Account Type:</span>
                                                        <span className='light'>Saving Account</span>
                                                    </div>

                                                    <div className='set_wd_15'>
                                                        <span className='bold'>Branch Name:</span>
                                                        <span className='light'>Okhla</span>
                                                    </div>

                                                    <div className='set_wd_5'>
                                                        <i className="fa fa-trash-o" id="basicdetailtrash"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='viewBankDetails2'>
                                                <div className='vieBankDetailInner1'>
                                                    <div className='viewDetailsend'>
                                                        <div className='settings-inner-1'>
                                                            <span className='settings-span1'>Enable For PDF ?</span>
                                                        </div>

                                                        <div className='settings-inner-2 wd-10'>
                                                            <button className='settings-yes'>Yes</button>
                                                            <button className='settings-no'>No</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='vieBankDetailInner2'>
                                                    <div className='set_wd_15'>
                                                        <span className='bold'>Beneficiary Name:</span>
                                                        <span className='light'>Aman Sharma</span>
                                                    </div>

                                                    <div className='set_wd_15'>
                                                        <span className='bold'>Bank Name:</span>
                                                        <span className='light'>HDFC</span>
                                                    </div>

                                                    <div className='set_wd_15'>
                                                        <span className='bold'>Account No. :</span>
                                                        <span className='light'>H09876111</span>
                                                    </div>

                                                    <div className='set_wd_15'>
                                                        <span className='bold'>IFSC Code:</span>
                                                        <span className='light'>HDFC0535486</span>
                                                    </div>

                                                    <div className='set_wd_15'>
                                                        <span className='bold'>Account Type:</span>
                                                        <span className='light'>Saving Account</span>
                                                    </div>

                                                    <div className='set_wd_15'>
                                                        <span className='bold'>Branch Name:</span>
                                                        <span className='light'>Okhla</span>
                                                    </div>

                                                    <div className='set_wd_5'>
                                                        <i className="fa fa-trash-o" id="basicdetailtrash"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className='settings-outer hide-view'>
                                <div className='varying-height'>

                                    <div className='settings-inner'>
                                        <div className='settings-list'>

                                            <div className='settings-list1'>
                                                <span className='settings-span-3'>Set PDF Color(s)</span>
                                            </div>

                                            <div onClick={() => toogleHubView("three", 1)} className='settings-list-3'>
                                                <i className="fa fa-angle-right settings-arrow-icon-3" ></i>
                                            </div>

                                            <div className='settings-list3'></div>

                                            <div className='set-list2'></div>

                                            <div className='settings-list5'>

                                                <div className='setting5-I1'></div>

                                                <div className='setting5-I2'>
                                                    <div className='settings-inner-1'>
                                                        <span className='settings-span1'>Show background color in Goods Receipt PDF ?</span>
                                                    </div>

                                                    <div className='settings-inner-2'>
                                                        <button className='settings-yes'>Yes</button>
                                                        <button className='settings-no'>No</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='pdfColorDiv'>
                                        <div className='pdfColorC1'>

                                            <div className='pdfColorDiv1'>
                                                <span className='settings-heading'>Transporter Copy Color</span>
                                                <i></i>
                                            </div>

                                            <div className='pdfColorDiv2'>

                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "transporter", num: "1" })}>
                                                    <div className='colorPink'>
                                                        {pink === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <span>Light Pink</span>
                                                </div>

                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "transporter", num: "2" })}>
                                                    <div className='colorblue'>
                                                        {blue === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <span>Light Blue</span>
                                                </div>

                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "transporter", num: "3" })}>
                                                    <div className='colorgreen'>
                                                        {green === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <span>Light Green</span>
                                                </div>

                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "transporter", num: "4" })}>
                                                    <div className='coloryellow'>
                                                        {yellow === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <span>Light Yellow</span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='pdfColorC1'>
                                            <div className='pdfColorDiv1'>
                                                <span className='settings-heading'>Consignor Copy Color</span>
                                                <i></i>
                                            </div>

                                            <div className='pdfColorDiv2'>
                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "consignor", num: "1" })}>
                                                    <div className='colorPink'>
                                                        {cpink === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <span>Light Pink</span>
                                                </div>

                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "consignor", num: "2" })}>
                                                    <div className='colorblue'>
                                                        {cblue === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <span>Light Blue</span>
                                                </div>

                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "consignor", num: "3" })}>
                                                    <div className='colorgreen'>
                                                        {cgreen === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <span>Light Green</span>
                                                </div>

                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "consignor", num: "4" })}>
                                                    <div className='coloryellow'>
                                                        {cyellow === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <span>Light Yellow</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='pdfColorC1'>
                                            <div className='pdfColorDiv1'>
                                                <span className='settings-heading'>Consignee Copy Color</span>
                                                <i></i>
                                            </div>

                                            <div className='pdfColorDiv2'>
                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "consignee", num: "1" })}>
                                                    <div className='colorPink'>
                                                        {consPink === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ""}
                                                    </div>
                                                    <span>Light Pink</span>
                                                </div>

                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "consignee", num: "2" })}>
                                                    <div className='colorblue'>
                                                        {consBlue === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <span>Light Blue</span>
                                                </div>

                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "consignee", num: "3" })}>
                                                    <div className='colorgreen'>
                                                        {consGreen === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <span>Light Green</span>
                                                </div>

                                                <div className='colorDiv1' onClick={() => chooseColor({ type: "consignee", num: "4" })}>
                                                    <div className='coloryellow'>
                                                        {consYellow === true ?
                                                            <div className='colorTick'>
                                                                <i className="fa fa-check"></i>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    <span>Light Yellow</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='pdfColorC1'>
                                            <div className='pdfColorDiv1'>
                                                <span className='settings-heading'>Transporter Copy Color</span>
                                                <i></i>
                                            </div>

                                            <div className='pdfColorDiv2'>
                                                <div className='colorDiv1'>

                                                    <div className='colorPink'>
                                                        {/* <div className='colorTick'>
                                                            <i className="fa fa-check"></i>
                                                        </div> */}
                                                    </div>
                                                    <span>Light Pink</span>
                                                </div>

                                                <div className='colorDiv1'>
                                                    <div className='colorblue'>
                                                        {/* <div className='colorTick'>
                                                            <i className="fa fa-check"></i>
                                                        </div> */}
                                                    </div>
                                                    <span>Light Blue</span>
                                                </div>

                                                <div className='colorDiv1'>
                                                    <div className='colorgreen'>
                                                        {/* <div className='colorTick'>
                                                            <i className="fa fa-check"></i>
                                                        </div> */}
                                                    </div>
                                                    <span>Light Green</span>
                                                </div>

                                                <div className='colorDiv1'>
                                                    <div className='coloryellow'>
                                                        <div className='colorTick'>
                                                            <i className="fa fa-check"></i>
                                                        </div>
                                                    </div>
                                                    <span>Light Yellow</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='pdfColorBtn'>
                                            <div className='pdfColorsBtnDiv'>
                                                <button >Submit</button>
                                                <button >Cancel</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='settings-outer hide-view '>
                                <div className='varying-height'>
                                    <div className='settings-inner'>
                                        <div className='settings-list'>

                                            <div className='settings-list1'>
                                                <span className='settings-span-44'>Set Home Screen Tab</span>
                                            </div>

                                            <div onClick={() => toogleHubView("four", 2)} className='settings-list-44'>
                                                <i className="fa fa-angle-right settings-arrow-icon-44" ></i>
                                            </div>

                                            <div className='settings-list3'></div>

                                            <div className='set-list2'></div>

                                            <div className='settings-list5'>

                                                <div className='setting5-I1'></div>

                                                <div className='setting5-I2'>
                                                    <div className='settings-inner-1'>
                                                        <span className='settings-span1'>Want to change Home Screen ?</span>
                                                    </div>

                                                    <div className='settings-inner-2'>
                                                        <button className='settings-yes'>Yes</button>
                                                        <button className='settings-no'>No</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='HomeScreenDiv'>
                                        <div className='HomeScreenInner'>
                                            <div className='homescreenHeading'>
                                                <span>Select an icon to set Home Screen</span>
                                            </div>
                                        </div>
                                        <div className='HomeScreenInner'>

                                            <div className='active_homeS-1'>
                                                <div className='homescreenInnerimg'>
                                                    <img src={DashboardActiveIcon} alt='none' />
                                                </div>
                                                <div className='homescreenInnerHead'>
                                                    <span className='active-home-title'>Dashboard</span>
                                                </div>
                                            </div>


                                            <div className='homeS-1'>
                                                <div className='homescreenInnerimg'>
                                                    <img src={BookingIcon} alt='none' />
                                                </div>
                                                <div className='homescreenInnerHead'>
                                                    <span className='home-title'>Booking</span>
                                                </div>
                                            </div>

                                            <div className='homeS-1'>
                                                <div className='homescreenInnerimg'>
                                                    <img src={ReceiptIcon} alt='none' />
                                                </div>
                                                <div className='homescreenInnerHead'>
                                                    <span className='home-title'>Lorry Receipt</span>
                                                </div>
                                            </div>

                                            <div className='homeS-1'>
                                                <div className='homescreenInnerimg'>
                                                    <img src={PoDIcon} alt='none' />
                                                </div>
                                                <div className='homescreenInnerHead'>
                                                    <span className='home-title'>Trip Completed</span>
                                                </div>
                                            </div>

                                            <div className='homeS-1'>
                                                <div className='homescreenInnerimg'>
                                                    <img src={AddressIcon} alt='none' />
                                                </div>
                                                <div className='homescreenInnerHead'>
                                                    <span className='home-title'>Address Book</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='HomeScreenInner'>
                                            <div className='homeS-1'>
                                                <div className='homescreenInnerimg'>
                                                    <i className="fa fa-user  master" />
                                                </div>
                                                <div className='homescreenInnerHead'>
                                                    <span className='home-title'>Transporter Master</span>
                                                </div>
                                            </div>
                                            <div className='homeS-1'>
                                                <div className='homescreenInnerimg'>
                                                    <img src={VehicleIcon} alt='none' />

                                                </div>
                                                <div className='homescreenInnerHead'>
                                                    <span className='home-title'>Vehicles</span>
                                                </div>
                                            </div>

                                            <div className='homeS-1'>
                                                <div className='homescreenInnerimg'>
                                                    <img src={DriverIcon} alt='none' />
                                                </div>
                                                <div className='homescreenInnerHead'>
                                                    <span className='home-title'>Drivers</span>
                                                </div>
                                            </div>

                                            <div className='homeS-1'>
                                                <div className='homescreenInnerimg'>
                                                    <img src={AnalyticIcon} alt='none' />
                                                </div>
                                                <div className='homescreenInnerHead'>
                                                    <span className='home-title'>Analytics</span>
                                                </div>
                                            </div>

                                            <div className='homeS-1'>
                                                <div className='homescreenInnerimg'>
                                                    <img src={ContractIcon} alt='none' />
                                                </div>
                                                <div className='homescreenInnerHead'>
                                                    <span className='home-title'>Contracts</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='HomeScreenInner'>

                                            <div className='homeS-1'>
                                                <div className='homescreenInnerimg'>
                                                    <img src={ReceiptIcon} alt='none' />
                                                </div>
                                                <div className='homescreenInnerHead'>
                                                    <span className='home-title'>Invoices</span>
                                                </div>
                                            </div>


                                        </div>
                                        <div className='HomeScreenInner'>
                                            <div className='homescreenbtndiv'>
                                                <button>Submit</button>
                                                <button>Cancel</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className='settings-outer hide-view'>
                                <div className='varying-height'>

                                    <div className='settings-inner'>
                                        <div className='settings-list'>

                                            <div className='settings-list1'>
                                                <span className='settings-span-4'>Add PDF Details</span>
                                            </div>

                                            <div onClick={() => toogleHubView("five", 3)} className='settings-list4'>
                                                <i className="fa fa-angle-right settings-arrow-icon-4" ></i>
                                            </div>

                                            <div className='settings-list3'>
                                                <span className='changeText1'>View PDF Details</span>
                                            </div>

                                            <div onClick={() => toogleHubView("six", 3)} className='changeBack1'>
                                                <i className="fa fa-angle-right changeArrow1"></i>
                                            </div>

                                            <div className='settings-list5 '>

                                                <div className='setting5-I1-new'>

                                                    <div className='settings5-V1'>
                                                        <div className='settings-inner-1'>
                                                            <span className='settings-span1'>Show Bottom Tagline in PDF ?</span>
                                                        </div>

                                                        <div className='settings-inner-2-new'>
                                                            <button className='settings-yes'>Yes</button>
                                                            <button className='settings-no'>No</button>
                                                        </div>
                                                    </div>

                                                    <div className='settings5-V2'>
                                                        <div className='settings-inner-1'>
                                                            <span className='settings-span1'>Show basic in PDF?</span>
                                                        </div>
                                                        <div className='settings-inner-2-new'>
                                                            <button className='settings-yes'>Yes</button>
                                                            <button className='settings-no'>No</button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='setting5-I2-new'>
                                                    <div className='settings5-V1'>
                                                        <div className='settings-inner-1'>
                                                            <span className='settings-span1'>Show bottom note in PDF ? </span>
                                                        </div>

                                                        <div className='settings-inner-2-new'>
                                                            <button className='settings-yes'>Yes</button>
                                                            <button className='settings-no'>No</button>
                                                        </div>
                                                    </div>

                                                    <div className='settings5-V2'>
                                                        <div className='settings-inner-1'>
                                                            <span className='settings-span1'>Show branch in PDF ?</span>
                                                        </div>
                                                        <div className='settings-inner-2-new'>
                                                            <button className='settings-yes'>Yes</button>
                                                            <button className='settings-no'>No</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {itemValue === "five" ?
                                        <div className='addPdfDetailsDiv'>

                                            <div className='addPdfDetailsInput1'>

                                                <div className='addPdfDetailc-1'>
                                                    <span className='addPdfspan1'>Website</span>
                                                    <input className='addpdfinput2_wd_100' type="text" />
                                                </div>

                                                <div className='addPdfDetailc-2'>
                                                    <span className='addPdfspan1'>CIN</span>
                                                    <input className='addpdfinput2_wd_100' type="text" />
                                                </div>

                                            </div>

                                            <div className='addPdfDetailsInput1'>

                                                <div className='addPdfDetailc-1'>
                                                    <span className='addPdfspan1'>Phone No(s)</span>

                                                    {pdfBasicArray.map((item, index) => {
                                                        return (
                                                            <div key={index} className='pDI_detail'>
                                                                <input className='addpdfinput2' type="text" placeholder='Phone No' />
                                                                <span onClick={pdfNumFun}> <i className="fa fa-plus add_icon"></i></span>
                                                                <span onClick={removepdfNum}><i className={index === 0 ? "hide" : "fa fa-minus minus_icon"} ></i></span>
                                                            </div>
                                                        )
                                                    })}

                                                </div>

                                                <div className='addPdfDetailc-2'>
                                                    <span className='addPdfspan1'>Fax No(s)</span>
                                                    {pdfFaxArray.map((item, index) => {
                                                        return (
                                                            <div key={index} className='pDI_detail'>
                                                                <input className='addpdfinput2' type="text" placeholder='Fax No' />
                                                                <span onClick={pdffaxFun}> <i className="fa fa-plus add_icon"></i></span>
                                                                <span onClick={removefaxNum}><i className={index === 0 ? "hide" : "fa fa-minus minus_icon"} ></i></span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                            <div className='addPdfDetailsInput1'>
                                                <div className='addPdfDetailc-1'>
                                                    <span className='addPdfspan1'>Bottom Tagline</span>
                                                    <input className='addpdfinput2_wd_100' type="text" />
                                                </div>

                                                <div className='addPdfDetailc-2'>
                                                    <span className='addPdfspan1'>Bottom Notes</span>
                                                    {pdfBottomArray.map((item, index) => {
                                                        return (
                                                            <div key={index} className='pDI_detail'>
                                                                <input className='addpdfinput2' type="text" />
                                                                <span onClick={pdfBottomFun}> <i className="fa fa-plus add_icon"></i></span>
                                                                <span onClick={removebottomNum}><i className={index === 0 ? "hide" : "fa fa-minus minus_icon"}></i></span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                            <div className='addpdftable'>
                                                <div className="addPdfTableInner">
                                                    <div className='addPdfTableHead'>
                                                        <span>Branch Address</span>
                                                    </div>
                                                    <div className='addPdfTableFooter'>
                                                        <div className='addpdftable1'>
                                                            <span className='pdfTableSpan'>State</span>

                                                            <select className='pdfTableSelect'>
                                                                <option>Delhi</option>
                                                                <option>Goa</option>
                                                                <option>Rajasthan</option>
                                                            </select>
                                                        </div>

                                                        <div className='addpdftable1'>
                                                            <span className='pdfTableSpan'>City</span>
                                                            <input className='pdfTableInput' placeholder='City' />

                                                        </div>

                                                        <div className='addpdftable1'>
                                                            <span className='pdfTableSpan'>Address</span>
                                                            <input className='pdfTableInput' placeholder='Address' />
                                                        </div>

                                                        <div className='addpdftable1'>
                                                            <span className='pdfTableSpan'>Pincode</span>
                                                            <input className='pdfTableInput' placeholder='Pincode' />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className='stampSubmitBtn'>
                                                <button className='submitMe'>Submit</button>
                                                <button className='cancelMe'>Cancel</button>
                                            </div>


                                        </div>
                                        :
                                        <div className='viewPdfDetailsDiv'>
                                            <div className='viewStampInner'>

                                                <div className='viewStampForm'>
                                                    <div className='viewStamInnerform'>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Website</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Not Available</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>CIN</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Not Available</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Phone No(s)</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Not Available</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Fax No(s) </span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Not Available</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Bottom Tagline</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Not Available</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Bottom Note(s)</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Not Available</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Branch Address</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Not Available</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className='settings-outer hide-view'>
                                <div className='varying-height'>

                                    <div className='settings-inner'>
                                        <div className='settings-list'>

                                            <div className='settings-list1'>
                                                <span className='settings-span-7'>Add Basic Details</span>
                                            </div>

                                            <div onClick={() => toogleHubView("seven", 4)} className='settings-list-7'>
                                                <i className="fa fa-angle-right settings-arrow-icon-7" ></i>
                                            </div>

                                            <div className='settings-list3'>
                                                <span className='changeText2'>View Basic Details</span>
                                            </div>

                                            <div onClick={() => toogleHubView("eight", 4)} className='changeBack2'>
                                                <i className="fa fa-angle-right changeArrow2"></i>
                                            </div>

                                            <div className='settings-list5'>

                                                <div className='setting5-I1'>
                                                    <div className='settings-inner-1'>
                                                        <span className='settings-span1'>Show tagline in PDF ?</span>
                                                    </div>

                                                    <div className='settings-inner-2'>
                                                        <button className='settings-yes'>Yes</button>
                                                        <button className='settings-no'>No</button>
                                                    </div>
                                                </div>

                                                <div className='setting5-I2'>
                                                    <div className='settings-inner-1'>
                                                        <span className='settings-span1'>Show mobile number & Email(s) in PDF?</span>
                                                    </div>

                                                    <div className='settings-inner-2'>
                                                        <button className='settings-yes'>Yes</button>
                                                        <button className='settings-no'>No</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {itemValue === "seven" ?
                                        <div className='basicDetailDiv'>

                                            <div className='basicDetailList'>
                                                <span className='basicDetailspan'>Tagline</span>
                                                <div className='inputAndAdd'>
                                                    <input className='basicDetailspan1' type="text" placeholder='Think Logistic Think us' />
                                                </div>
                                            </div>

                                            <div className='basicDetailList'>
                                                <span className='basicDetailspan'>Mobile Number(s)</span>
                                                {addBasicArray.map((item, index) => {
                                                    return (
                                                        <div key={index} className='inputAndAdd'>
                                                            <input className='basicDetailspan2' type="number" placeholder='987654321' />
                                                            <span onClick={addBasicInput}><i className="fa fa-plus add_icon"></i></span>
                                                            <span onClick={removeBasicInput}><i className={index === 0 ? "hide" : "fa fa-minus minus_icon"} ></i></span>
                                                        </div>
                                                    )
                                                })}


                                            </div>

                                            <div className='basicDetailList'>
                                                <span className='basicDetailspan'>Email ID(s)</span>
                                                {addBasicEmail.map((item, index) => {
                                                    return (
                                                        <div key={index} className='inputAndAdd'>
                                                            <input className='basicDetailspan2' type="text" placeholder='xyz@lynkit.in' />
                                                            <span onClick={addBasicEmailFun}><i className="fa fa-plus add_icon"></i></span>
                                                            <span onClick={removeBasicEmail}><i className={index === 0 ? "hide" : "fa fa-minus minus_icon"} ></i></span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className='stampSubmitBtn_new'>
                                                <button className='submitMe'>Submit</button>
                                                <button className='cancelMe'>Cancel</button>
                                            </div>

                                        </div>
                                        :
                                        <div className='viewBasicDetails'>
                                            <div className='viewStampInner-5'>

                                                <div className='viewBsicDetailsForm'>
                                                    <div className='viewStamInnerform'>

                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Tagline </span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Think Logistic Think Us</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Mobile Number(s)</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>987654323</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Email ID(s)</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>xyz@lynkit.in</span>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className='settings-outer hide-view'>
                                <div className='varying-height'>

                                    <div className='settings-inner'>
                                        <div className='settings-list'>

                                            <div className='settings-list1'>
                                                <span className='settings-span-9'>Add Terms and Conditions</span>
                                            </div>

                                            <div onClick={() => toogleHubView("nine", 5)} className='settings-list-9'>
                                                <i className="fa fa-angle-right settings-arrow-icon-9" ></i>
                                            </div>

                                            <div className='settings-list3'>
                                                <span className='changeText3'>View Terms and Conditions</span>
                                            </div>

                                            <div onClick={() => toogleHubView("ten", 5)} className='changeBack3'>
                                                <i className="fa fa-angle-right changeArrow3"></i>
                                            </div>

                                            <div className='settings-list5'>

                                                <div className='setting5-I1'>
                                                    <div className='settings-inner-1'>
                                                        <span className='settings-span1'>Terms & condition in Proforma Invoice ?</span>
                                                    </div>

                                                    <div className='settings-inner-2'>
                                                        <button className='settings-yes'>Yes</button>
                                                        <button className='settings-no'>No</button>
                                                    </div>
                                                </div>

                                                <div className='setting5-I2'>
                                                    <div className='settings-inner-1'>
                                                        <span className='settings-span1'>Terms & Condition on back of PDF ?</span>
                                                    </div>

                                                    <div className='settings-inner-2'>
                                                        <button className='settings-yes'>Yes</button>
                                                        <button className='settings-no'>No</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {itemValue === "nine" ?

                                        <div className='viewTermsCtns'>
                                            <div className='addTermsLeft'>
                                                <div onClick={viewGRTC_1} className={grBorder_new}>
                                                    <span className={grspan_new}>GR T&C</span>
                                                </div>
                                                <div onClick={viewPITC_1} className={piborder_new}>
                                                    <span className={pispan_new}>PROFORMA INVOICE T&C</span>
                                                </div>
                                            </div>

                                            <div className='addTermsInner'>

                                                <div className={viewGR_new}>
                                                    <div className='ViewTermsInner'>
                                                        {newElemetArray.map((item, index) => {
                                                            return (
                                                                <div key={index} className='viewTermsInner-1'>

                                                                    <div className='termsSno'>
                                                                        <span className='addTermsNo'>{index + 1}.)</span>
                                                                    </div>

                                                                    <div className='termsDiv'>
                                                                        <input className='terms_cdtnInput' type='text' />
                                                                    </div>

                                                                    <div className='termsBtn'>

                                                                        <button onClick={increaseInputFieldPI} className='terms_add'><i className="fa fa-plus fa_add" ></i></button>
                                                                        <button onClick={(e) => removeInputFieldpPI(item, index)} className={index === 0 ? "hide" : "terms_cancel"}><i className="fa fa-times fa_cross" ></i></button>
                                                                    </div>

                                                                </div>
                                                            )
                                                        })}

                                                        <div className='viewTermsBtn-1'>
                                                            <div className="viewTermsCtnBtn">
                                                                <button className='submitMe'>Submit</button>
                                                                <button className='cancelMe'>Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={viewPI_new}>

                                                    <div className='ViewTermsInner'>
                                                        {elemetArray.map((item, index) => {
                                                            return (
                                                                <div key={index} className='viewTermsInner-1'>

                                                                    <div className='termsSno'>
                                                                        <span className='addTermsNo'>{index + 1}.)</span>
                                                                    </div>

                                                                    <div className='termsDiv'>
                                                                        <input className='terms_cdtnInput' type='text' placeholder='Hi please make payemnt!!!' />
                                                                    </div>

                                                                    <div className='termsBtn'>

                                                                        <button onClick={increaseInputField} className='terms_add'><i className="fa fa-plus fa_add" ></i></button>
                                                                        <button onClick={(e) => removeInputField(item, index)} className={index === 0 ? "hide" : "terms_cancel"}><i className="fa fa-times fa_cross" ></i></button>
                                                                    </div>

                                                                </div>
                                                            )
                                                        })}

                                                        <div className='viewTermsBtn'>
                                                            <div className="viewTermsCtnBtn">
                                                                <button className='submitMe'>Submit</button>
                                                                <button className='cancelMe'>Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>
                                        </div>
                                        :

                                        <div className="addTermsDiv">

                                            <div className='addTermsLeft'>
                                                <div onClick={viewGRTC} className={grBorder}>
                                                    <span className={grspan}>GR T&C</span>
                                                </div>
                                                <div onClick={viewPITC} className={piborder}>
                                                    <span className={pispan}>PROFORMA INVOICE T&C</span>
                                                </div>
                                            </div>

                                            <div className='addTermsInner'>

                                                <div className={viewGR}>

                                                    <div className='addTermsPoint'>

                                                        <div className='addTermsPT'>
                                                            <div className='addTermsnum-1'>
                                                                <span className='addTermsNo'>1.)</span>
                                                            </div>
                                                            <div className='addTermsnum-2'>
                                                                <span className='addTermsp'>Vehicle challan VDC34 with Train tr345454 created for transportation.</span>
                                                            </div>
                                                        </div>

                                                        <div className='addTermsPB'>
                                                            <div className='addTermsnum-1'>
                                                                <span className='addTermsNo'>2.)</span>
                                                            </div>
                                                            <div className='addTermsnum-2'>
                                                                <span className='addTermsp'>Vehicle challan VDC34 with Train tr345454 created for transportation fVehicle challan VDC34 with Train tr345454 created for transportation.</span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div className={viewPI}>
                                                    <div className='addTermsPoint'>

                                                        <div className='addTermsPT'>
                                                            <div className='addTermsnum-1'>
                                                                <span className='addTermsNo'>1.)</span>
                                                            </div>
                                                            <div className='addTermsnum-2'>
                                                                <span className='addTermsp'>Hi please make payement.</span>
                                                            </div>
                                                        </div>

                                                        <div className='addTermsPB'>
                                                            <div className='addTermsnum-1'>
                                                                <span className='addTermsNo'>2.)</span>
                                                            </div>
                                                            <div className='addTermsnum-2'>
                                                                <span className='addTermsp'>Hi please make payement.</span>
                                                            </div>
                                                        </div>

                                                    </div>




























































                                                </div>

                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>

                            <div className='settings-outer hide-view'>
                                <div className='varying-height'>

                                    <div className='settings-inner'>
                                        <div className='settings-list'>

                                            <div className='settings-list1'>
                                                <span className='settings-span-11'>All Stamp/Signature</span>
                                            </div>

                                            <div onClick={() => toogleHubView("eleven", 6)} className='settings-list-11'>
                                                <i className="fa fa-angle-right settings-arrow-icon-11" ></i>
                                            </div>

                                            <div className='settings-list3'>
                                                <span className='changeText'>View Stamps</span>
                                            </div>

                                            <div onClick={() => toogleHubView("twelve", 6)} className='changeBack'>
                                                <i className="fa fa-angle-right changeArrow"></i>
                                            </div>

                                            <div className='settings-list5'>

                                                <div className='setting5-I1'>
                                                </div>

                                                <div className='setting5-I2'>
                                                    <div className='settings-inner-1'>
                                                        <span className='settings-span1'>Show Creation Stamp in PDF ?</span>
                                                    </div>

                                                    <div className='settings-inner-2'>
                                                        <button className='settings-yes'>Yes</button>
                                                        <button className='settings-no'>No</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {itemValue === "eleven" ?

                                        <div className='stampDiv'>
                                            <div className='stampInputDiv'>
                                                <div className='stampInputdiv2'>

                                                    <div className='stamInner2-i1'>
                                                        <span>Name(optional)</span>
                                                        <input type="text" placeholder='Name' />
                                                    </div>

                                                    <div className='stamInner2-i2'>

                                                        <span>Designation(optional)</span>
                                                        <input type="text" placeholder='Designation' />

                                                    </div>

                                                </div>

                                                <div className='stampInputdiv1'>
                                                    <span>Select User</span>
                                                    <select>
                                                        <option>Default</option>
                                                    </select>
                                                </div>



                                            </div>

                                            <div className='stampSignatureDiv'>
                                                <div className='stamSignatureDiv1'>
                                                    <span>Sign Here (optional)</span>
                                                    <button onClick={clearSign}>Clear sign</button>
                                                </div>

                                                <div className='stamSignatureDiv2'>
                                                    <div className='signCanvas'>
                                                        <SignatureCanvas
                                                            ref={sigPad}
                                                            canvasProps={{ width: 430, height: 180, className: 'MysignCanvas' }}
                                                            onBegin={getUserSignbase}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='stamSignatureDiv3'>
                                                    <button className='uploadStampImg'>Upload Stamp Image
                                                        <input className='stampInputFile' type="file" onChange={(e) => uploadImage(e)} />
                                                    </button>
                                                    {imgURL !== undefined ?
                                                        <div className='stamToggleDiv'>
                                                            <button className='cropBtn'>Crop</button>
                                                            <button className='resizeBtn'>Resize</button>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                            {imgURL !== undefined ?
                                                <div className='stampImageDiv'>
                                                    <div className='stamImageCont'>
                                                        <div className='stamUploaded'>
                                                            <img src={imgURL} />
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}

                                            {signature64 !== "" && imgURL !== undefined ?
                                                <div className='stampPreviewDiv'>
                                                    <button onClick={showPreview} className='previewStamp'>Preview Stamp(Sign+Stamp)</button>
                                                </div>
                                                : ""}

                                            {newimgURL !== undefined ?
                                                <div className='previewstampimg'>
                                                    <div className='previewstamimginner'>
                                                        <div className='newstamUploaded'>
                                                            <img className='belowImg' src={imgURL} />
                                                            <img className='overflowImg' src={signature64} />
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}


                                            <div className='stampSubmitBtn-6'>
                                                <button onClick={submitandsave} className='submitMe'>Submit</button>
                                                <button className='cancelMe'>Cancel</button>
                                            </div>


                                        </div>
                                        :
                                        <div className='ViewStampDiv'>
                                            <div className='viewStampInner-6'>

                                                <div className='viewStampForm-6'>

                                                    <div className='viewStamInnerform-6'>

                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>User Name</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Ajeet</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Name(for Stamp)</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Ajeet</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Designation(for Stamp)</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Manager</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Stamp</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                {imgURL === undefined ?
                                                                    <div className='viewOnlyStamp'>
                                                                        <img className='viewOnlyStampimg' src={signature64} />
                                                                    </div>
                                                                    :

                                                                    <div className='viewBoth'>
                                                                        <img className='viewOnlyimg' src={imgURL} />
                                                                        {signature64 !== "" ?
                                                                            <img className='viewOnlyStamp' src={signature64} />
                                                                            : ""}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Added by</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>Ajeet</span>
                                                            </div>
                                                        </div>
                                                        <div className="vewStampformL-1">
                                                            <div className="viewStampFormL">
                                                                <span className='viewStampformHead'>Added On</span>
                                                            </div>
                                                            <div className="viewStampFormR">
                                                                <span className='viewformhead2'>June 04 2021, 01:23:30 AM</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='ViewStamCancel'>
                                                    <button>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>













            </div >
        </>

    );
};

export default MySettings;