import React, { useEffect, useState } from 'react';
import bg from './../../static/images/registBg.png'
import Select from "react-dropdown-select";
import $ from 'jquery'
import history from './../../services/history';
import DocViewAddressModal from '../Dashboard/Modal/DocViewAddress'
import axios from 'axios'

const Dummy = () => {
    const [panShow, setPanShow] = useState(false)
    const [gstShow, setGstShow] = useState(false)
    const [base, setBase] = useState('')
    const [passwordType, setPasswordType] = useState("password")
    const [loading, setLoading] = useState(false)
    const [registerDetails, setRegisterDetails] = useState({
        registrationType: "goodsowner",
        orgName: '',
        plantName: 'dummy',
        pan: '',
        panCopy: '',
        address: '',
        city: '',
        state: '',
        pinCode: '',
        name: '',
        emailId: '',
        mobileNo: '',
        phone: '',
        gstin: '',
        gstinUpload: '',
        password: '',
        confirmPassword: '',
        userGroup: "goodsowner",
        userSubGroup: "goodsowner",
        userType: "admin",
        permissions: {
            createUser: true,
            editUser: true,
            viewUser: true,
            editHub: true,
            disableUser: true,
            viewListing: true,
            addPlant: true,
            viewPlant: true,
            addStaff: true,
            deleteStaff: true,
            viewStaff: true,
            editStaff: true,
            addHub: true,
            deleteHub: true,
            viewHub: true,
            viewInvoice: true,
            createInvoice: true,
            editInvoice: true,
            addVehicle: true,
            viewVehicle: true,
            editVehicle: true,
            addDriver: true,
            viewDriver: true,
            editDriver: true,
            addAddress: true,
            editAddress: true,
            viewAddress: true,
            addContract: true,
            viewContract: true,
            viewProfile: true,
            editProfile: true,
            changePassword: true,
            viewSettingPanel: true,
            addSettingsInSettingPanel: true,
            editSettingPanel: true,
            viewDashboard: true,
            downloadReport: true,
            vehicleAllocation: true,
            viewBookingDetails: true,
            requestForGateInInspecPassed: true,
            requestForGateInInspecFailed: true,
            weighmentOfVehicle: true,
            vehicleInspection: true,
            driverInspection: true,
            gpsDeviceInspection: true,
            allowVehicleForGateIn: true,
            addClauseForInspection: true,
            waivePenalty: true,
            rejectDriver: true,
            addCustomerVehicle: true,
            addTransporter: true,
            viewTransporter: true,
            approveTransporter: true,
            rejectTransporter: true,
            allocateSalesOrderCustVehicle: true,
            uploadDocuments: true,
            bookingViewDetails: true,
            pickupDropOffLocation: true,
            addDepartment: true,
            viewDepartment: true,
            editOrganisation: true,
            addBooking: true,
            editPlant: true,
            editDepartment: true,
            GeneratePOD: true,
            ArrivedAtDestination: true,
            viewlr: true,
            editContract : true
        }
    })
    const [licenseDetails, setLicenseDetails] = useState({
        totalPlantLicense : "" ,
        totalTransporterLicense : "",
        licenseStartDate : "",
        licenseEndDate :"",
    })
    const upload = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        if (item === 'panCopy') {
            registerDetails.panCopy = base64
            setPanShow(true)
            setBase(base64)
        }
        else if (item === 'gstinUpload') {
            registerDetails.gstinUpload = base64
            setGstShow(true)
            setBase(base64)
        }
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }

                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const _onChange = (e) => {
        setRegisterDetails({ ...registerDetails, [e.target.name]: e.target.value })

    }
    const _onChangeLicense = (e) => {
        setLicenseDetails({ ...licenseDetails, [e.target.name]: e.target.value })
    }
    const register = () => {
        if (registerDetails.orgName === '' || registerDetails.pan === '' || registerDetails.address === '' || registerDetails.pinCode === '' || registerDetails.name === '' || registerDetails.emailId === '' || registerDetails.gstin === '') {
            window.$.fn.show_notification({ message: `All fields mandatory..`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                if (result.type === 'confirm') {
                }
            })
        }
        else {
            if (registerDetails.password === registerDetails.confirmPassword) {
                // setLoading(true)
                const token = localStorage.getItem('secretkey')
                const config = {
                    headers: { Authorization: token }
                };
                const result = { ...registerDetails, ...licenseDetails }
                axios.post('https://www.betaenterprise.lynkit.io/api/user/register-admin', { ...result }, { headers: config.headers })
                    .then(response => {
                        // console.log('Goods Owner Register response', response);
                        // setLoading(false)
                        const err = response.data.error
                        const message = response.data.message
                        if (err === true) {
                            // setLoading(true)
                            window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                        else {
                            window.$.fn.show_notification({ message: `Registration successfull.`, title: 'Success!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            history.push('/')
                        }
                    })
                    .catch(error => {
                        // setLoading(true)
                        // dispatch(showError(err))
                    })
            }
            else {
                window.$.fn.show_notification({ message: `Password does not match.`, title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
            }


        }


    }
    const _moveBack = () => {
        history.goBack();
    }
    function _handleClick(id) {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible");
        $(`#${id}`).addClass("custom-modal-is-visible");
    }
    const togglePassType = () => {
        if (passwordType == "password") {
            setPasswordType("text")
        }
        else {
            setPasswordType("password")
        }

    }

    return (

        <div className="registrationElem">
            <div className='registrationL'>
                <img src={bg} alt='' />
                <div className='regOver'>
                    <span>Goods Owner <br />Registration Form</span>
                </div>
            </div>
            <div className='registrationR'>
                <button className='registerBack' onClick={() => _moveBack()}>Back</button>
                <div className='regInner'>
                    <div className='regRow alignRow'>
                        <div className='reg50'>
                            <span className='regHeading'>Company Name</span>
                            <div className='regSelect'>
                                <input className='regIn' name="orgName" value={registerDetails.orgName} onChange={(e) => _onChange(e)} type='text' placeholder='Enter Company Name' />
                            </div>
                        </div>
                        <div className='reg50'>
                            <span className='regHeading'>PAN</span>
                            <div className='regSelect'>
                                <input className='regIn' name="pan" value={registerDetails.pan} onChange={(e) => _onChange(e)} type='text' placeholder='Enter PAN Number' />
                                <div className='addUploadItem regUpload'>
                                    <i className="fa fa-upload " ></i>
                                    <input className='addUploadfile' type="file" onChange={(e) => upload(e, 'panCopy')} />
                                    {panShow === true ?
                                        <i className="fa fa-eye myVisibleEyeBlack" onClick={() => { _handleClick('DocViewAddressModal') }}></i>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='regRow'>
                        <span className='regHeading'>Address</span>
                        <div className='regSelect'>
                            <input className='regIn' name="address" value={registerDetails.address} onChange={(e) => _onChange(e)} type='text' placeholder='Enter your Address' />
                        </div>
                    </div>
                    <div className='regRow alignRow'>
                        <div className='reg3'>
                            <span className='regHeading'>State</span>
                            <div className='regSelect'>
                                <input className='regIn' name="state" value={registerDetails.state} onChange={(e) => _onChange(e)} type='text' placeholder='Enter your State' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>City</span>
                            <div className='regSelect'>
                                <input className='regIn' name="city" value={registerDetails.city} onChange={(e) => _onChange(e)} type='text' placeholder='Enter your City' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>PinCode</span>
                            <div className='regSelect'>
                                <input className='regIn' name="pinCode" value={registerDetails.pinCode} onChange={(e) => _onChange(e)} type='number' placeholder='Enter your Pin' />
                            </div>
                        </div>
                    </div>
                    <div className='regRow alignRow'>
                        <div className='reg50'>
                            <span className='regHeading'>Name</span>
                            <div className='regSelect'>
                                <input className='regIn' name="name" value={registerDetails.name} onChange={(e) => _onChange(e)} type='text' placeholder='Enter your Name' />
                            </div>
                        </div>
                        <div className='reg50'>
                            <span className='regHeading'>Email id</span>
                            <div className='regSelect'>
                                <input className='regIn' name="emailId" value={registerDetails.emailId} onChange={(e) => _onChange(e)} type='email' placeholder='Enter your Email Id' />
                            </div>
                        </div>
                    </div>
                    <div className='regRow alignRow'>
                        <div className='reg3'>
                            <span className='regHeading'>Mobile No</span>
                            <div className='regSelect'>
                                <input className='regIn' name="mobileNo" value={registerDetails.mobileNo} onChange={(e) => _onChange(e)} type='number' placeholder='Enter your Mobile No' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>Phone </span>
                            <div className='regSelect'>
                                <input className='regIn' name="phone" value={registerDetails.phone} onChange={(e) => _onChange(e)} type='number' placeholder='Enter your Phone No' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>GSTIN</span>
                            <div className='regSelect'>
                                <input className='regIn' name="gstin" value={registerDetails.gstin} onChange={(e) => _onChange(e)} type='text' placeholder='Enter your GSTIN number' />
                                <div className='addUploadItem regUpload'>
                                    <i className="fa fa-upload " ></i>
                                    <input className='addUploadfile' type="file" onChange={(e) => upload(e, 'gstinUpload')} />
                                    {gstShow === true ?
                                        <i className="fa fa-eye myVisibleEyeBlack" onClick={() => { _handleClick('DocViewAddressModal') }}></i>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='regRow alignRow'>
                        <div className='reg3'>
                            <span className='regHeading'>Total Plant License</span>
                            <div className='regSelect'>
                                <input className='regIn' name='totalPlantLicense' value={registerDetails.totalPlantLicense} onChange={(e) => _onChangeLicense(e)} type='text' placeholder='Enter Total Plant License' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>Total Transporter License</span>
                            <div className='regSelect'>
                                <input className='regIn' name='totalTransporterLicense' value={registerDetails.totalTransporterLicense} onChange={(e) => _onChangeLicense(e)} type='text' placeholder='Enter Total Transporter License' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>License Start Date</span>
                            <div className='regSelect'>
                                <input className='regIn' name='licenseStartDate' value={registerDetails.licenseStartDate} onChange={(e) => _onChangeLicense(e)} type='date' placeholder='Enter License Start Date' />
                            </div>
                        </div>
                    </div>
                    <div className='regRow alignRow'>
                        <div className='reg3'>
                            <span className='regHeading'>License End Date</span>
                            <div className='regSelect'>
                                <input className='regIn' name='licenseEndDate' value={registerDetails.licenseEndDate} onChange={(e) => _onChangeLicense(e)} type='date' placeholder='Enter License End Date' />
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>Password</span>
                            <div className='regSelect'>
                                <input className='regIn' name="password" value={registerDetails.password} onChange={(e) => _onChange(e)} type={passwordType} placeholder='Enter your Password' />
                                {passwordType === 'password' ?
                                    <i className="fa fa-eye-slash iconEyeabs" onClick={togglePassType}></i>
                                    :
                                    <i onClick={togglePassType} className="fa fa-eye iconEyeabs" ></i>
                                }
                            </div>
                        </div>
                        <div className='reg3'>
                            <span className='regHeading'>Confirm password</span>
                            <div className='regSelect'>
                                <input className='regIn' name="confirmPassword" value={registerDetails.confirmPassword} onChange={(e) => _onChange(e)} type='password' placeholder='Re-enter your Password' />
                            </div>
                        </div>
                    </div>
                    <div className='regRow alignCen'>
                        <button className='registerNowBtn' onClick={() => register()}>Register Now</button>
                        {loading === true ?
                            <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                            : ''}
                    </div>
                </div>
            </div>
            <DocViewAddressModal closeModal={_closeModal} imageData={base} />
        </div>
    )
}
export default Dummy;