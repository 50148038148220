import { ADDRESS_DATA } from "../actions/types";

const initialState={
    
} 

const addressReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case ADDRESS_DATA : return{
            ...state,
        }
        default: return state;  
    }
}
export default addressReducer