import { post } from './../../../services/requests'
import {DELIVERY , DELIVERY_HIDE , DELIVERY_SHOW} from './types'

const pageSize = 5
export const pod = (data) =>{  
    return(dispatch)=>{
        dispatch(showLoader())
        post('booking/GetAllLr',{
            pageSize: pageSize,
            bookmark: "",
            status: "PODGenerated",
            searchValue: data.searchValue,
            searchType: data.searchType,
        })
        .then(response=>{
             const podList = response.data
             const status = response.error
             dispatch(fetchPodData(podList))
             if(status === false){
                dispatch(hideLoader())  
             }
             else{
                dispatch(hideLoader())  
             }
        }) 
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const fetchPodData = (podList) =>{
    return{
        type :DELIVERY,
        payload:{podList}
    }
}
export const showLoader = () =>{
    return{
        type : DELIVERY_SHOW,
    }
}
export const hideLoader = () =>{
    return{
        type : DELIVERY_HIDE,
    }
}
