import React, { useState, useEffect } from 'react';
import user from './../../static/images/dummy-user.png'
import license from './../../static/images/license.jpg'
import history from '../../services/history'
import { selectInit } from './../../services/functions'
import $ from 'jquery';
import VaccineModal from './../Dashboard/Modal/Vaccine'
import DocumentModal from './../Dashboard/Modal/DocumentModal'
import Select from "react-dropdown-select";
import moment from 'moment'
import { getDriverinfo, getStaffinfo, getImages } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { resetBase } from '../../state/redux/actions/getImage';
import { resetStaff } from '../../state/redux/actions/getStaff';

const DriverDetails = (props) => {

    const [key, setKey] = useState('')
    const [childKey, setChildKey] = useState('')
    const [attachedDocuments, setAttachedDocuments] = useState([])
    const [dropValue, setDropValue] = useState("")   
    const [base, setBase] = useState("")
    const [fileName, setFileName] = useState("")
    const [loading, setLoading] = useState(false)
    const [disableState, setDisableState] = useState(true)
    const [driverData, setDriverData] = useState([
        {
            driverImg: user,
            driverDL: license,
            driverVaccine: ''
        }
    ])
    const allData = props.location.state
    const docValues = [
        { value: "select", label: "Select Document" },
        { value: "aadhar", label: "Aadhar Card" },
        { value: "DL", label: "DL" },
    ];
    const staffValues = [
        { value: "select", label: "Select Document" },
        { value: "aadhar", label: "Aadhar Card" },
        // { value: "DL", label: "DL" },
    ];
    const dispatch = useDispatch()
    const GetDl = props.location.state.DLNumber ? props.location.state.DLNumber : ''
    const singleDriverData = useSelector((state) => state.getSingleDriverReducer.driverData)
    const loader = useSelector((state) => state.getSingleDriverReducer.loading)
    const GetstaffId = props.location.state.StaffId
    const singleStaffData = useSelector((state) => state.getSingleStaffReducer.staffData)
    const staffLoader = useSelector((state) => state.getSingleStaffReducer.loading)
    const imageData = useSelector((state) => state.getImgBase.imgBaseArr)

    const _onChange = (value) => {
        setDropValue(value[0].label);
    }

    const convertBase64 = (file) => {
        if (file) {
            setLoading(true)
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    setLoading(false)

                    fileReader.onerror = (error) => {
                        reject(error);
                        setLoading(false)
                    }
                }, 400)
            })
        }
        else {
            setLoading(false)
        }
    }
    const _goBack = () => {
        history.goBack()
        setDisableState(true)
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _sendIndex = (index) => {
        var newArray = [...attachedDocuments]
        setBase(newArray[index])
        setFileName(newArray[index].documentName)
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const callback = (status) => {
        if (status === true) {
            setChildKey(true)
        }
    }
    const DownloadImage = (index) => {
        var newArray = [...attachedDocuments]
        var a = document.createElement("a");
        a.href = newArray[index].documentBase64
        a.download = newArray[index].documentName
        a.click();

    }
    const uploadDocs = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertDocsBase64(file)

        let localArray = [...driverData]
        if (item === 'driver') {
            localArray[0].driverImg = base64
            setDriverData(localArray)
        }
        else if (item === 'DL') {
            localArray[0].driverDL = base64
            setDriverData(localArray)
        }
    }
    const convertDocsBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const [updateObj, setUpdateObj] = useState({
        DLNumber: "",
        EndorseDate: "",
        EndorseNumber: "",
        ExpiryDate: "",
        FatherName: "",
        IssuedDate: "",
        LicenseType: "",
        VaccinationStatus: "",
        MobileNo: '',
        Addresses: [],
        BlacklistedFlag: '',
        Name: "",
        EmailId: "",
        Documents: [],
        AadharUpload: "",
        DlUpload: ""
        // UserDOB: ""

    })
    const allowEdit = () => {
        if ($(".driverSpan2").hasClass("underline")) {
            $(".driverSpan2").removeClass("underline");
            $(".vehicleInpField1").removeClass("underline");
            setDisableState(true)
            setUpdateObj(
                {
                    DLNumber: "",
                    EndorseDate: "",
                    EndorseNumber: "",
                    ExpiryDate: "",
                    FatherName: "",
                    IssuedDate: "",
                    LicenseType: "",
                    VaccinationStatus: "",
                    MobileNo: '',
                    Addresses: [],
                    BlacklistedFlag: '',
                    Name: "",
                    EmailId: "",
                    Documents: [],
                    // UserDOB: ""
                }
            )

        } else {
            $(".driverSpan2").addClass("underline");
            $(".vehicleInpField1").addClass("underline");
            setDisableState(false)
            let dummy = { ...updateObj }
            dummy.DLNumber = singleDriverData.DLNumber
            dummy.EndorseDate = singleDriverData.EndorseDate
            dummy.EndorseNumber = singleDriverData.EndorseNumber
            dummy.ExpiryDate = singleDriverData.ExpiryDate
            dummy.FatherName = singleDriverData.FatherName
            dummy.IssuedDate = singleDriverData.IssuedDate
            dummy.LicenseType = singleDriverData.LicenseType
            dummy.VaccinationStatus = singleDriverData.VaccinationStatus
            dummy.MobileNo = singleDriverData.MobileNo
            dummy.Addresses = singleDriverData.Addresses
            dummy.BlacklistedFlag = singleDriverData.BlacklistedFlag
            dummy.Name = singleDriverData.Name
            dummy.EmailId = singleDriverData.EmailId
            dummy.Documents = singleDriverData.AttachedDocuments
            // dummy.UserDOB = singleDriverData.UserDOB
            setUpdateObj(dummy)
        }
    }
    const updateData = (e) => {
        setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
    }
    const deleteDriverImage = (index) => {
        let tempArr = [...attachedDocuments]
        tempArr.splice(index, 1)
        setAttachedDocuments(tempArr)

        let updatLocal = {...updateObj}
        updatLocal.Documents.splice(index,1)
        setUpdateObj(updatLocal)
    }
    const updateFun = () => {
        const finalObj = {
            dlNumber: updateObj.DLNumber,
            endorseDate: updateObj.EndorseDate,
            endorseNumber: updateObj.EndorseNumber,
            expiryDate: updateObj.ExpiryDate,
            fatherName: updateObj.FatherName,
            issuedDate: updateObj.IssuedDate,
            licenseType: updateObj.LicenseType,
            vaccinationStatus: updateObj.VaccinationStatus,
            mobileNo: updateObj.MobileNo,
            addresses: [],
            blacklistedFlag: updateObj.BlacklistedFlag,
            name: updateObj.Name,
            emailId: updateObj.EmailId,
            documents: updateObj.Documents,
            aadharUpload: updateObj.AadharUpload,
            dlUpload: updateObj.DlUpload
            // UserDOB: updateObj.UserDOB

        }
        console.log('final',finalObj);
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/driver/UpdateDriver', { ...finalObj }, { headers: config.headers })
            .then(response => {
                const err = response.data.error
                const message = response.data.message
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                if (err === false) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log('err', error)
            })
    }
    // staff detail
    const [updateStaffObj, setStaffUpdateObj] = useState({
        StaffId: "",
        Name: "",
        UserDOB: "",
        Gender: "",
        MobileNo: "",
        EmailId: "",
        Role: "",
        Address: "",
        Country: "India",
        State: "",
        City: "",
        PinCode: "",
        StaffType: "",
        AadhaarNumber: "",
        Documents: [],
        Status: "",
        // AadharUpload: ""
    })
    const setDate = (e, item) => {
        if (item === 'dob') {
            let dummy = { ...updateStaffObj }
            dummy.UserDOB = e.target.value
            setStaffUpdateObj(dummy)
        }
        else if (item === 'addedOn') {
            let dummy = { ...updateStaffObj }
            dummy.AddedOn = e.target.value
            setStaffUpdateObj(dummy)
        }
    }
    const setDriverDate = (e, item) => {
        if (item === 'dob') {
            // let dummy = { ...updateObj }
            // let driverDOB = e.target.value
            // dummy.UserDOB = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(driverDOB)
            // setUpdateObj(dummy)
        }
        else if (item === 'endorsedate') {
            let dummy = { ...updateObj }
            dummy.EndorseDate = e.target.value
            setUpdateObj(dummy)
        }
        else if (item === 'issue') {
            let dummy = { ...updateObj }
            dummy.IssuedDate = e.target.value
            setUpdateObj(dummy)
        }
        else if (item === 'expiry') {
            let dummy = { ...updateObj }
            dummy.ExpiryDate = e.target.value
            setUpdateObj(dummy)
        }

    }
    const allowStaffEdit = () => {
        if ($(".driverSpan2").hasClass("underline")) {
            $(".driverSpan2").removeClass("underline");
            $(".vehicleInpField1").removeClass("underline");
            setDisableState(true)
            setUpdateObj(
                {
                    StaffId: "",
                    Name: "",
                    UserDOB: "",
                    Gender: "",
                    MobileNo: "",
                    EmailId: "",
                    Role: "",
                    Address: "",
                    Country: "India",
                    State: "",
                    City: "",
                    PinCode: "",
                    StaffType: "",
                    AadhaarNumber: "",
                    Documents: [],
                    Status: "ACTIVE"
                }
            )

        } else {
            $(".driverSpan2").addClass("underline");
            $(".vehicleInpField1").addClass("underline");
            setDisableState(false)
            let dummy = { ...updateStaffObj }
            dummy.StaffId = singleStaffData._id
            dummy.Name = singleStaffData.Name
            dummy.UserDOB = singleStaffData.UserDOB
            dummy.Gender = singleStaffData.Gender
            dummy.MobileNo = singleStaffData.MobileNo
            dummy.EmailId = singleStaffData.EmailId
            dummy.Role = singleStaffData.Role
            dummy.Address = singleStaffData.Address
            dummy.Country = singleStaffData.Country
            dummy.State = singleStaffData.State
            dummy.City = singleStaffData.City
            dummy.PinCode = singleStaffData.PinCode
            dummy.StaffType = singleStaffData.StaffType
            dummy.AadhaarNumber = singleStaffData.AadhaarNumber
            dummy.Documents = singleStaffData.AttachedDocuments
            dummy.Status = singleStaffData.Status
            setStaffUpdateObj(dummy)
        }
    }
    const uploadFrontImage = async (e) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)

        var newArray = [...attachedDocuments]
        newArray.push(base64);
        if (key === 'driver') {
            if (disableState !== true && dropValue !== '' && dropValue === 'Aadhar Card') {
                setAttachedDocuments(newArray)
                updateObj.Documents.push(base64)
            }
            else if (disableState !== true && dropValue !== '' && dropValue === 'DL') {
                setAttachedDocuments(newArray)
                updateObj.Documents.push(base64)
            }
        }
        else {
            if (disableState !== true && dropValue !== '' && dropValue === 'Aadhar Card') {
                setAttachedDocuments(newArray)
                updateStaffObj.Documents.push(base64)
            }
        }
    }
    const updateStaffData = (e) => {
        setStaffUpdateObj({ ...updateStaffObj, [e.target.name]: e.target.value })
    }
    const deleteImage = (index) => {
        let tempArr = [...attachedDocuments]
        tempArr.splice(index, 1)
        setAttachedDocuments(tempArr)

        let updatLocal = {...updateStaffObj}
        updatLocal.Documents.splice(index,1)
        setStaffUpdateObj(updatLocal)
    }
    const updateStaffFun = () => {
        dispatch(resetBase())
        const finalObj = {
            staffId: updateStaffObj.StaffId,
            name: updateStaffObj.Name,
            userDOB: updateStaffObj.UserDOB,
            gender: updateStaffObj.Gender,
            mobileNo: updateStaffObj.MobileNo,
            emailId: updateStaffObj.EmailId,
            role: updateStaffObj.Role,
            address: updateStaffObj.Address,
            country: "India",
            state: updateStaffObj.State,
            city: updateStaffObj.City,
            pinCode: updateStaffObj.PinCode,
            staffType: updateStaffObj.StaffType,
            aadhaarNumber: updateStaffObj.AadhaarNumber,
            documents: updateStaffObj.Documents,
            status: updateStaffObj.Active,
            // aadharUpload: updateStaffObj.AadharUpload
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/staff/UpdateStaff', { ...finalObj }, { headers: config.headers })
            .then(response => {
                const err = response.data.error
                const message = response.data.message
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                if (err === false) {
                    setAttachedDocuments([])
                    dispatch(getStaffinfo(GetstaffId))
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    if ($(".driverSpan2").hasClass("underline")) {
                        $(".driverSpan2").removeClass("underline");
                        $(".vehicleInpField1").removeClass("underline");
                        setDisableState(true)
                    } else {
                        $(".driverSpan2").addClass("underline");
                        $(".vehicleInpField1").addClass("underline");
                        setDisableState(false)
                    }
                }
            })
            .catch(error => {
                console.log('err', error)
            })
    }
    useEffect(() => {
        if (props.location.state.State === 'driver') {
            dispatch(getDriverinfo(GetDl))
        }
        else {
            dispatch(resetStaff())
            dispatch(getStaffinfo(GetstaffId))
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            selectInit()
        }, 100);
        if (allData) {
            setKey(allData.State)
        }
    }, [allData])

    // useEffect(() => {
    //     if (singleStaffData.AttachedDocuments) {
    //         let arr = singleStaffData.AttachedDocuments
    //         for (let i = 0; i < arr.length; i++) {
    //             let key = singleStaffData && singleStaffData.AttachedDocuments && singleStaffData.AttachedDocuments[i] && singleStaffData.AttachedDocuments[i].documentKey ? singleStaffData.AttachedDocuments[i].documentKey : ''
    //             if (key.length !== 0) {
    //                 dispatch(getImages(key))
    //             }
    //             else {
    //                 setAttachedDocuments([])
    //             }
    //         }
    //     }
    // }, [singleStaffData.AttachedDocuments])

    useEffect(() => {
        if (imageData.length !== 0) {
            let temp = [...imageData]
            setAttachedDocuments(temp)
            updateStaffObj.Documents.push(singleStaffData.AttachedDocuments)
        }
    }, [imageData])

    return (
        <>
            <div className="contentpanel">
                <div className="cust-container">
                    <div className="cust-row flex-algn-cent">
                        <div className='cust-col-4'>
                            <h1 className="title">
                                <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                                    <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                                </button>
                                {key === 'staff' ? 'Staff Detail' : 'Driver Detail'}
                            </h1>
                        </div>
                        <div className='cust-col-6 flex-jc-end'>
                            {key === 'driver' ?
                                <button className='button bg-orange-out mr-2 wd10' onClick={allowEdit}>
                                    <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                                    <div className="titleBoxnew">
                                        <span>Edit </span>
                                    </div>
                                </button>
                                :
                                <button className='button bg-orange-out mr-2 wd10' onClick={allowStaffEdit}>
                                    <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                                    <div className="titleBoxnew">
                                        <span>Edit </span>
                                    </div>
                                </button>
                            }
                            {/* <button className='button bg-orange-out mr-2 wd10'>
                                <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                                <div className="titleBoxnew">
                                    <span>Download </span>
                                </div>
                            </button> */}
                            {/* <button className='button bg-orange-out mr-2 wd10'>
                                <div className='btn_txt font-lg'><i className='fa fa-upload'></i></div>
                                <div className="titleBoxnew">
                                    <span>Upload </span>
                                </div>
                            </button> */}
                        </div>
                    </div>
                    <hr className='bgg' />
                    <div className="cust-row">
                        {key === 'driver' || key === '' ?
                            singleDriverData !== undefined ?
                                loader === true ?
                                    <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                        <i className="fa fa-spinner fa-spin loadingSpinner font-xxl  "></i>
                                    </div>
                                    :
                                    <div className='cust-col-10'>
                                        <div className='DriverDetail-1'>
                                            <div className='driverHead'>
                                                <span>Basic Details</span>
                                            </div>
                                            <div className='driverContent'>
                                                <div className='driver-l'>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Driver Name</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='Name'
                                                                value={disableState === true ? singleDriverData.Name : updateObj.Name}
                                                                onChange={(e) => updateData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Father's Name</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='FatherName'
                                                                value={disableState === true ? singleDriverData.FatherName : updateObj.FatherName}
                                                                onChange={(e) => updateData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Driver Number</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='MobileNo'
                                                                value={disableState === true ? singleDriverData.MobileNo : updateObj.MobileNo}
                                                                onChange={(e) => updateData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Date Of Birth</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            {/* {disableState === true ? */}
                                                            <input className='driverSpan2'
                                                                name='UserDOB'
                                                                value={disableState === true ? moment(singleDriverData.UserDOB).format('DD/MM/YYYY') : moment(updateObj.UserDOB).format('DD/MM/YYYY')}
                                                                // onChange={(e) => updateData(e)}
                                                                disabled={disableState}
                                                            />
                                                            {/* :
                                                                <span className='driverSpan2 calenderInp'>
                                                                    {moment(updateObj.UserDOB).format('DD/MM/YYYY')}
                                                                    <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                                        <i className="fa fa-calendar updateCalender"></i>
                                                                        <input className='hideCalend' name='UserDOB' value={updateObj.UserDOB} onChange={(e) => setDriverDate(e, 'dob')} type="date" />
                                                                    </div>
                                                                </span>
                                                            } */}
                                                        </div>

                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Endorse Date</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            {disableState === true ?
                                                                <input className='driverSpan2'
                                                                    name='EndorseDate'
                                                                    value={disableState === true ? moment(singleDriverData.EndorseDate).format('DD/MM/YYYY') : moment(updateObj.EndorseDate).format('DD/MM/YYYY')}
                                                                    onChange={(e) => updateData(e)}
                                                                    disabled={disableState}
                                                                />
                                                                :
                                                                <span className='driverSpan2 calenderInp'>
                                                                    {moment(updateObj.EndorseDate).format('DD/MM/YYYY')}
                                                                    <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                                        <i className="fa fa-calendar updateCalender"></i>
                                                                        <input className='hideCalend' name='EndorseDate' value={updateObj.EndorseDate} onChange={(e) => setDriverDate(e, 'endorsedate')} type="date" />
                                                                    </div>
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Endorse No.</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='EndorseNumber'
                                                                value={disableState === true ? singleDriverData.EndorseNumber : updateObj.EndorseNumber}
                                                                onChange={(e) => updateData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Blood Group</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2' value="O+"
                                                                name='EndorseNumber'
                                                                onChange={(e) => updateData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>City</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <span className='driCustm'>Local-</span>
                                                            <span className='driverSpan2'>{singleDriverData && singleDriverData.Addresses[0] && singleDriverData.Addresses[0].district ? singleDriverData.Addresses[0].district : '-'}</span>
                                                            <span className='driCustm' style={{ marginLeft: '12px' }}>Permanent-</span>
                                                            <span className='driverSpan2'>{singleDriverData && singleDriverData.Addresses[1] && singleDriverData.Addresses[1].district ? singleDriverData.Addresses[1].district : '-'}</span>
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>State</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <span className='driCustm'>Local-</span>
                                                            <span className='driverSpan2'>{singleDriverData && singleDriverData.Addresses[0] && singleDriverData.Addresses[0].state ? singleDriverData.Addresses[0].state : '-'}</span>
                                                            <span className='driCustm' style={{ marginLeft: '12px' }}>Permanent-</span>
                                                            <span className='driverSpan2'>{singleDriverData && singleDriverData.Addresses[1] && singleDriverData.Addresses[1].state ? singleDriverData.Addresses[1].state : '-'}</span>
                                                        </div>
                                                    </div>

                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Country</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2' value="India"
                                                                name='EndorseNumber'
                                                                onChange={(e) => updateData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Vaccination Status</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <span className='driverSpan2 spanUnderline' onClick={() => { _handleClick('VaccineModal') }}>Click to fetch your Certificate</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='driver-r'>
                                                    <div className='driver-img-div'>
                                                        <img className='userImg' src={driverData[0].driverImg} alt="" />
                                                        <input className='uploadFile' type='file' onChange={(e) => uploadDocs(e, 'driver')} />
                                                        <i class="fa fa-pencil-square-o uploadIcon"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {childKey === true ?
                                            <div className='DriverDetail-2'>
                                                <div className='driverHead'>
                                                    <span>Vaccination Details</span>
                                                </div>
                                                <div className='driverContent'>
                                                    <div className='driver-l'>
                                                        <div className='driverRow'>
                                                            <div className='driverc-1'>
                                                                <span className='driverSpan1'>Beneficary Reference ID</span>
                                                            </div>
                                                            <div className='driverc-2'>
                                                                <span className='driverSpan2'>XXXXXXXXXXXXX</span>
                                                            </div>
                                                        </div>
                                                        <div className='driverRow'>
                                                            <div className='driverc-1'>
                                                                <span className='driverSpan1'>Vccination Status</span>
                                                            </div>
                                                            <div className='driverc-2'>
                                                                <span className='driverSpan2'>Fully/Partially</span>
                                                            </div>
                                                        </div>
                                                        <div className='driverRow'>
                                                            <div className='driverc-1'>
                                                                <span className='driverSpan1'>Vaccination Date</span>
                                                            </div>
                                                            <div className='driverc-2'>
                                                                <span className='driverSpan2'>18-03-2022</span>
                                                            </div>
                                                        </div>
                                                        <div className='driverRow'>
                                                            <div className='driverc-1'>
                                                                <span className='driverSpan1'>Vaccinated At</span>
                                                            </div>
                                                            <div className='driverc-2'>
                                                                <span className='driverSpan2'>G-28 , Sarovar Hospital, Lajpat Nagar, Delhi</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='driver-r1'>
                                                        <div className='driverDataElem '>
                                                            <img className='userImg' src={user} alt="" />
                                                            <input className='uploadFile' type='file' onChange={(e) => uploadDocs(e, 'vaccine')} />
                                                            <i class="fa fa-pencil-square-o uploadIcon"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                        }
                                        <div className='DriverDetail-2'>
                                            <div className='driverHead'>
                                                <span>License Details</span>
                                            </div>
                                            <div className='driverContent'>
                                                <div className='driver-l'>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Driver License No.</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='DLNumber'
                                                                value={disableState === true ? singleDriverData.DLNumber : updateObj.DLNumber}
                                                                onChange={(e) => updateData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Driver License Type</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='LicenseType'
                                                                value={disableState === true ? singleDriverData.LicenseType : updateObj.LicenseType}
                                                                onChange={(e) => updateData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Date Of Issue</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            {disableState === true ?
                                                                <input className='driverSpan2'
                                                                    name='IssuedDate'
                                                                    value={disableState === true ? moment(singleDriverData.IssuedDate).format('DD/MM/YYYY') : moment(updateObj.IssuedDate).format('DD/MM/YYYY')}
                                                                    onChange={(e) => updateData(e)}
                                                                    disabled={disableState}
                                                                />
                                                                :
                                                                <span className='driverSpan2 calenderInp'>
                                                                    {moment(updateObj.IssuedDate).format('DD/MM/YYYY')}
                                                                    <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                                        <i className="fa fa-calendar updateCalender"></i>
                                                                        <input className='hideCalend' name='IssuedDate' value={updateObj.IssuedDate} onChange={(e) => setDriverDate(e, 'issue')} type="date" />
                                                                    </div>
                                                                </span>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Date Of Expiry</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            {disableState === true ?
                                                                <input className='driverSpan2'
                                                                    name='ExpiryDate'
                                                                    value={disableState === true ? moment(singleDriverData.ExpiryDate).format('DD/MM/YYYY') : moment(updateObj.ExpiryDate).format('DD/MM/YYYY')}
                                                                    onChange={(e) => updateData(e)}
                                                                    disabled={disableState}
                                                                />
                                                                :
                                                                <span className='driverSpan2 calenderInp'>
                                                                    {moment(updateObj.ExpiryDate).format('DD/MM/YYYY')}
                                                                    <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                                        <i className="fa fa-calendar updateCalender"></i>
                                                                        <input className='hideCalend' name='ExpiryDate' value={updateObj.ExpiryDate} onChange={(e) => setDriverDate(e, 'expiry')} type="date" />
                                                                    </div>
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='DriverDetail-3'>
                                            <div className='driverHead'>
                                                <span>Other Details</span>
                                            </div>
                                            <div className='multiDocDiv'>
                                                <div className='multiDocOtions'>
                                                    <div className='doc-type'>
                                                        <span className='docSpans'>Document Type :</span>
                                                        <div className='selectDiv'>
                                                            <Select
                                                                options={docValues}
                                                                className="css-1oy5eth-ReactDropdownSelect dropHeight"
                                                                onChange={(value) => _onChange(value)}
                                                                color={"#ff7200"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='docUpload'>
                                                        {disableState !== true ?
                                                            <button className='uploadimgBtn'>Upload
                                                                <input className='hideInputFile' type='file'
                                                                    onChange={(e) => uploadFrontImage(e)}
                                                                />
                                                            </button>
                                                            : ''}
                                                        {loading === true && attachedDocuments.length !== 0 ?
                                                            <i className="fa fa-spinner fa-spin loadingSpinner font-xl"></i>
                                                            : ''}
                                                        {attachedDocuments.length === 0 ?
                                                            <span className='noImgText'>No Image uploaded (select document type)  </span>
                                                            : ''}
                                                    </div>
                                                    <div className='doc-btn'>
                                                    </div>
                                                </div>

                                                <div className='uploadDiv'>
                                                    {attachedDocuments && attachedDocuments.map((item, index) => {
                                                        return (
                                                            <div key={index} className='pictureDisplay'>
                                                            {disableState !== true ?
                                                                <div className='closeTab'>
                                                                    <i className='fa fa-close' onClick={() => deleteDriverImage(index)}></i>
                                                                </div>
                                                            :''}
                                                                <div className='picturePreview' onClick={() => { _handleClick('DocumentModal'); _sendIndex(index) }}>
                                                                    <img alt="" src={item} />
                                                                </div>
                                                                
                                                                <div className='fullscreen med-size'>
                                                                    <i className='fa fa-expand' onClick={() => { _handleClick('DocumentModal'); _sendIndex(index) }}></i>
                                                                </div>
                                                                <div className='downloadDocBtn'>
                                                                    <i className="fa fa-download" onClick={() => DownloadImage(index)} ></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                            </div>
                                        </div>

                                        <div className='DriverDetail-4'>
                                            {disableState === false ?
                                                <button className='button bti bg-success-out m-0 btn-size' onClick={() => updateFun()} >
                                                    <div className='btn_icon'>
                                                        <i className="fa fa-upload font-xl"></i>
                                                    </div>
                                                    <div className='btn_txt font-md'>Update</div>
                                                </button>
                                                : ''}
                                        </div>

                                    </div>
                                :
                                <span className='noDataClass'>No Data Found</span>

                            :



                            singleStaffData !== undefined ?
                                staffLoader === true ?
                                    <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                        <i className="fa fa-spinner fa-spin loadingSpinner font-xxl  "></i>
                                    </div>
                                    :
                                    <div className='cust-col-10'>
                                        <div className='DriverDetail-1'>
                                            <div className='driverHead'>
                                                <span>Basic Details</span>
                                            </div>
                                            <div className='driverContent'>
                                                <div className='driver-l'>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Name</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='Name'
                                                                value={disableState === true ? singleStaffData.Name : updateStaffObj.Name}
                                                                onChange={(e) => updateStaffData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Staff Type</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='StaffType'
                                                                value={disableState === true ? singleStaffData.StaffType : updateStaffObj.StaffType}
                                                                onChange={(e) => updateStaffData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Gender</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='Gender'
                                                                value={disableState === true ? singleStaffData.Gender : updateStaffObj.Gender}
                                                                onChange={(e) => updateStaffData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Date Of Birth</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            {disableState === true ?
                                                                <input className='driverSpan2'
                                                                    name='UserDOB'
                                                                    value={disableState === true ? moment(singleStaffData.UserDOB).format('DD/MM/YYYY') : moment(updateStaffObj.UserDOB).format('DD/MM/YYYY')}
                                                                    onChange={(e) => updateStaffData(e)}
                                                                    disabled={disableState}
                                                                />
                                                                :
                                                                <span className='driverSpan2 calenderInp'>
                                                                    {moment(updateStaffObj.UserDOB).format('DD/MM/YYYY')}
                                                                    <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                                        <i className="fa fa-calendar updateCalender"></i>
                                                                        <input className='hideCalend' name='UserDOB' value={updateStaffObj.UserDOB} onChange={(e) => setDate(e, 'dob')} type="date" />
                                                                    </div>
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Mobile Number</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='MobileNo'
                                                                value={disableState === true ? singleStaffData.MobileNo : updateStaffObj.MobileNo}
                                                                onChange={(e) => updateStaffData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Email ID</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='EmailId'
                                                                value={disableState === true ? singleStaffData.EmailId : updateStaffObj.EmailId}
                                                                onChange={(e) => updateStaffData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Role</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='Role'
                                                                value={disableState === true ? singleStaffData.Role : updateStaffObj.Role}
                                                                onChange={(e) => updateStaffData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>City</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='City'
                                                                value={disableState === true ? singleStaffData.City : updateStaffObj.City}
                                                                onChange={(e) => updateStaffData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>State</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='State'
                                                                value={disableState === true ? singleStaffData.State : updateStaffObj.State}
                                                                onChange={(e) => updateStaffData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Pin-Code</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <input className='driverSpan2'
                                                                name='PinCode'
                                                                value={disableState === true ? singleStaffData.PinCode : updateStaffObj.PinCode}
                                                                onChange={(e) => updateStaffData(e)}
                                                                disabled={disableState}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Date Of Joining</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            {disableState === true ?
                                                                <input className='driverSpan2'
                                                                    name='AddedOn'
                                                                    value={disableState === true ? moment(singleStaffData.AddedOn).format('DD/MM/YYYY') : moment(updateStaffObj.AddedOn).format('DD/MM/YYYY')}
                                                                    onChange={(e) => updateStaffData(e)}
                                                                    disabled={disableState}
                                                                />
                                                                :
                                                                <span className='driverSpan2 calenderInp'>
                                                                    {moment(updateStaffObj.AddedOn).format('DD/MM/YYYY')}
                                                                    <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                                        <i className="fa fa-calendar updateCalender"></i>
                                                                        <input className='hideCalend' name='AddedOn' value={updateStaffObj.AddedOn} onChange={(e) => setDate(e, 'addedOn')} type="date" />
                                                                    </div>
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='driverRow'>
                                                        <div className='driverc-1'>
                                                            <span className='driverSpan1'>Vaccination Status</span>
                                                        </div>
                                                        <div className='driverc-2'>
                                                            <span className='driverSpan22 spanUnderline' onClick={() => { _handleClick('VaccineModal') }}>Click to fetch your Certificate</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='driver-r'>
                                                    <div className='driver-img-div'>
                                                        <img className='userImg' src={user} alt="" />
                                                        <input className='uploadFile' type='file' />
                                                        <i class="fa fa-pencil-square-o uploadIcon"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='DriverDetail-3'>
                                            <div className='driverHead justifyContentDriver'>
                                                <span>Other Details</span>
                                                {/* <button onClick={staffUpload}>View / Upload</button> */}
                                            </div>

                                            <div className='multiDocDiv'>
                                                <div className='multiDocOtions'>
                                                    <div className='doc-type'>
                                                        <span className='docSpans'>Document Type :</span>
                                                        <div className='selectDiv'>
                                                            <Select
                                                                options={staffValues}
                                                                className="css-1oy5eth-ReactDropdownSelect dropHeight"
                                                                onChange={(value) => _onChange(value)}
                                                                color={"#ff7200"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='docUpload'>
                                                        {disableState !== true ?
                                                            <button className='uploadimgBtn'>Upload
                                                                <input className='hideInputFile' type='file'
                                                                    onChange={(e) => uploadFrontImage(e)}
                                                                />
                                                            </button>
                                                            : ''}
                                                        {loading === true && attachedDocuments.length !== 0 ?
                                                            <i className="fa fa-spinner fa-spin loadingSpinner font-xl"></i>
                                                            : ""}
                                                        {attachedDocuments.length === 0 ?
                                                            <span className='noImgText'>No Image uploaded (select document type)  </span>
                                                            : ''}
                                                    </div>
                                                    <div className='doc-btn'>
                                                    </div>
                                                </div>

                                                <div className='uploadDiv'>
                                                    {attachedDocuments && attachedDocuments.map((item, index) => {
                                                        return (
                                                            <div key={index} className='pictureDisplay'>
                                                            {disableState !== true ?
                                                                <div className='closeTab'>
                                                                    <i className='fa fa-close' onClick={() => deleteImage(index)}></i>
                                                                </div>
                                                            :''}
                                                                <div className='picturePreview' onClick={() => { _handleClick('DocumentModal'); _sendIndex(index) }}>
                                                                    <img alt="" src={item} />
                                                                </div>

                                                                <div className='fullscreen med-size'>
                                                                    <i className='fa fa-expand' onClick={() => { _handleClick('DocumentModal'); _sendIndex(index) }}></i>
                                                                </div>
                                                                <div className='downloadDocBtn'>
                                                                    <i className="fa fa-download" onClick={() => DownloadImage(index)} ></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='DriverDetail-4'>
                                            {disableState !== true ?
                                                <button className='button bti bg-success-out m-0 btn-size' onClick={() => updateStaffFun()}>
                                                    <div className='btn_icon'>
                                                        <i className="fa fa-upload font-xl"></i>
                                                    </div>
                                                    <div className='btn_txt font-md'>Update</div>
                                                </button>
                                                : ''}

                                        </div>
                                    </div>
                                :
                                <span className='noDataClass'>No Data Found</span>
                        }
                    </div>
                </div>
                <VaccineModal closeModal={_closeModal} parentCallback={callback} />
                <DocumentModal closeModal={_closeModal} imageData={{ base, fileName }} />
            </div>
        </>
    );
};

export default DriverDetails; 