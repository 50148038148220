import history from '../../services/history'
import TransLogo from './../../static/images/credlynk_logo.png'
import PodModal from './../Dashboard/Modal/PodConfirmation'
import $ from 'jquery'
import TaggedImage from './../Dashboard/TaggedImage'
import { useState, useEffect } from 'react'

const ReceiptDetail = (props) => {

    const [updateTruckModal, setUpdateModal] = useState(false)
    const [getUrlParam, setUrlParam] = useState('')

    useEffect(() => {
        if (props.type === 'delivery') {
        }
        else {
            const allData = props.location.state.url_param
            setUrlParam(allData)
        }
    }, [getUrlParam])


    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }

    const _goBack = () => {
        history.goBack()
        // if (props.type === 'delivery') {
        //     history.push("/dashboard/delivery")
        // }
        // else {
        //     history.push("/dashboard/goods-receipt")
        // }
    }
    const updateTruck = () => {
        setUpdateModal(!updateTruckModal)
    }
    const closeTruckModal = () => {
        setUpdateModal(!updateTruckModal)
    }


    return (
        <div className="contentpanel">
            <div className="cust-container">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-4'>
                        <h1 className="title">
                            <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                                <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                            </button>
                            {props.type === 'delivery' ? "PoD" : "Receipt"} Detail
                        </h1>
                    </div>
                    <div className='cust-col-6 flex-jc-end'>
                        {
                            props.type === 'delivery' ? "" :
                        <>
                            <button className='button bg-grey-out mr-2 wd10'>
                                <div className='btn_txt font-lg'><i className='fa fa-close'></i></div>
                                <div className="titleBoxnew">
                                    <span>Close </span>
                                </div>
                            </button>
                            <button className='button bg-danger-out mr-2 wd10'>
                                <div className='btn_txt font-lg'><i className='fa fa-trash'></i></div>
                                <div className="titleBoxnew">
                                    <span>Delete </span>
                                </div>
                            </button>
                            <button className='button bg-orange-out mr-2 wd10'>
                                <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                                <div className="titleBoxnew">
                                    <span>Edit </span>
                                </div>
                            </button>
                            <button className='button bg-orange-out mr-2 wd10'>
                                <div className='btn_txt font-lg'><i className='fa fa-upload'></i></div>
                                <div className="titleBoxnew">
                                    <span>Upload </span>
                                </div>
                            </button>
                        </>
                        }
                        <button className='button bg-orange-out mr-2 wd10' onClick={() => { _handleClick('Taggedimage') }}>
                            <div className='btn_txt font-lg'><i className="fa fa-picture-o"></i></div>
                            <div className="titleBoxnew">
                                <span>Images</span>
                            </div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                            <div className="titleBoxnew">
                                <span>Download </span>
                            </div>
                        </button>
                    </div>
                </div>
                <hr className='bgg' />
                <div className='cust-row'>
                    <div className='cust-col-10'>

                        <div className='form-container'>
                            <div class="custom-collapse autoheight">
                                <div class="customBdy pdm5">
                                    <div className='tbl_div'>
                                        <div className='logo_sec_1'>
                                            <img src={TransLogo} alt="" />
                                        </div>
                                        <div className='content_sec_1'>
                                            <p className='font-bold font-xl'>XYZ Logitics Pvt. Ltd.</p>
                                            <p className='font-lg'>D-34, ABD Gali No. 4, New South Extension, phase - 4, New Delhi, Delhi - 220011</p>
                                            <p><span className='font-bold'>PAN - </span>QWASW4325G <span className='font-bold'>GSTIN - </span>09QWASW4325G1Z3</p>
                                            <p className='font-bold font-xl'>{props.type === 'delivery' ? "Proof of Delivery" : "Goods Receipt"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse autoheight">
                                <div class="customHead">
                                    Basic Details
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold black_color'>Consignment Note No.</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold grey_color'>123456</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold black_color'>Sales Order</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold grey_color'>654321</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Contract Type</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold grey_color'>Full Truck Load</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Payment Term</span>
                                        </div>
                                        <div className='wdt20'>
                                            <span className='font-bold grey_color'>To Pay</span>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold black_color'>Consignment Note Date</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold grey_color'>Jan 14th 2022 1:47 PM</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Delivery Type</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>Door Delivery</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Transportation Mode</span>
                                        </div>
                                        <div className='wdt15'>
                                            <span className='font-bold grey_color'>Road</span>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Movement Type</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold grey_color'>Domestic</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Carrier Risk</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Yes</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Est. Delivery Date</span>
                                        </div>
                                        <div className='wdt15'>
                                            <span className='font-bold black_color'>15-Feb-2021</span>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Managing Hub</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>XYZ Hub</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Remark</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>Remark</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Inspection Status</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>Pass</span>
                                        </div>
                                        <div className='wdt25 '>
                                            <span className='font-bold black_color'>Total Distance Covered</span>
                                        </div>
                                        <div className='wdt25'>
                                            <span className='font-bold grey_color'>1800 KM</span>
                                        </div>

                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Consignor</span>
                                        </div>
                                        <div className='wdt30 brd_rht'>
                                            <span className='font-bold grey_color'>Abhishek, punjab , Purnia, Bihar - 112121</span>
                                        </div>
                                        {getUrlParam === 'document-ready' ?
                                            <div className='wdt10 brd_rht'>
                                                <span className='font-bold black_color'>Consignor PAN</span>
                                            </div>
                                            : ''}
                                        {getUrlParam === 'document-ready' ?
                                            <div className='wdt10 brd_rht'>
                                                <span className='font-bold grey_color'>GKEG6699NN</span>
                                            </div>
                                            : ''}
                                        {getUrlParam === 'document-ready' ?
                                            <div className='wdt10 brd_rht'>
                                                <span className='font-bold black_color'>Consignor GSTIN</span>
                                            </div>
                                            : ''}
                                        {getUrlParam === 'document-ready' ?
                                            <div className='wdt10 brd_rht'>
                                                <span className='font-bold grey_color'>GKEG-6699-00NN</span>
                                            </div>
                                            : ''}
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Pickup</span>
                                        </div>
                                        <div className='wdt30'>
                                            <span className='font-bold grey_color'> Punjab , Purnia, Bihar - 112121</span>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Consignee</span>
                                        </div>
                                        <div className='wdt30 brd_rht'>
                                            <span className='font-bold grey_color'>Vikas, shahdra, Delhi, Delhi - 110006</span>
                                        </div>
                                        {getUrlParam === 'document-ready' ?
                                            <div className='wdt10 brd_rht'>
                                                <span className='font-bold black_color'>Consignee GSTIN</span>
                                            </div>
                                            : ''}
                                        {getUrlParam === 'document-ready' ?
                                            <div className='wdt10 brd_rht'>
                                                <span className='font-bold grey_color'>PU00-2277-320N</span>
                                            </div>
                                            : ''}
                                        {getUrlParam === 'document-ready' ?
                                            <div className='wdt10 brd_rht'>
                                                <span className='font-bold black_color'>Consignee GSTIN</span>
                                            </div>
                                            : ''}
                                        {getUrlParam === 'document-ready' ?
                                            <div className='wdt10 brd_rht'>
                                                <span className='font-bold grey_color'>POPO-H652-00NN</span>
                                            </div>
                                            : ''}
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Dropoff</span>
                                        </div>
                                        <div className='wdt30'>
                                            <span className='font-bold grey_color'> Shahdra, Delhi, Delhi - 110006</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse autoheight">
                                <div class="customHead">
                                    Goods Details
                                    <div class="doc_attachment" data-toggle="modal" data-target="#documentModal">
                                        <i class="fa fa-paperclip"></i> View Documents
                                    </div>
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex'>
                                        <div className='wdt55'>
                                            <div className='dv_flex brd_rht brd_btm pd5'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Eway Bill Number</span>
                                                </div>
                                                <div className='wdt20 brd_rht'>
                                                    <span className='font-bold grey_color'>12345678</span>
                                                </div>
                                                <div className='wdt20 brd_rht'>
                                                    <span className='font-bold black_color'>Validity</span>
                                                </div>
                                                <div className='wdt30'>
                                                    <span className='font-bold grey_color'>12-01-2022 to 12-02-2022</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='wdt45'>
                                            <div className='dv_flex brd_btm pd5'>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold black_color'>Goods Description</span>
                                                </div>
                                                <div className='wdt30 brd_rht'>
                                                    <span className='font-bold grey_color'>Boxes</span>
                                                </div>
                                                <div className='wdt20 brd_rht'>
                                                    <span className='font-bold black_color'>HSN Code</span>
                                                </div>
                                                <div className='wdt20'>
                                                    <span className='font-bold grey_color'>122</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt50 brd_rht'>
                                            <span className='font-bold black_color'>Product Type</span>
                                        </div>
                                        <div className='wdt50 brd_rht'>
                                            <span className='font-bold grey_color'>Copper</span>
                                        </div>
                                        <div className='wdt50 brd_rht'>
                                            <span className='font-bold black_color'>Require OTP to Confirm Delivery for my Organization?</span>
                                        </div>
                                        <div className='wdt50'>
                                            <span className='font-bold grey_color'>Yes</span>
                                        </div>
                                    </div>

                                    <div className='dv_flex'>
                                        <div className='wdt30 brd_btm brd_rht'>
                                            <div className='dv_flex pd5'>
                                                <div className='wdt50 brd_rht'>
                                                    <span className='font-bold black_color'>Actual Weight</span>
                                                </div>
                                                <div className='wdt50'>
                                                    <span className='font-bold grey_color'>100(Kg)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='wdt30 brd_btm brd_rht'>
                                            <div className='dv_flex pd5'>
                                                <div className='wdt50 brd_rht'>
                                                    <span className='font-bold black_color'>Weight Charged</span>
                                                </div>
                                                <div className='wdt50'>
                                                    <span className='font-bold grey_color'>100(Kg)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='wdt40 brd_btm'>
                                            <div className='dv_flex pd5'>
                                                <div className='wdt40 brd_rht'>
                                                    <span className='font-bold black_color'>Package Detail</span>
                                                </div>
                                                <div className='wdt60'>
                                                    <span className='font-bold grey_color'>XXXX , 100(Box)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {getUrlParam === 'weighment-out' || getUrlParam === 'logistics-gate-out' || getUrlParam === 'material-gate-out' ?
                                        <div className='dv_flex'>
                                            <div className='wdt30 brd_btm brd_rht'>
                                                <div className='dv_flex pd5'>
                                                    <div className='wdt50 brd_rht'>
                                                        <span className='font-bold black_color'>Tare Weight</span>
                                                    </div>
                                                    <div className='wdt50'>
                                                        <span className='font-bold grey_color'>1 TON</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='wdt30 brd_btm brd_rht'>
                                                <div className='dv_flex pd5'>
                                                    <div className='wdt50 brd_rht'>
                                                        <span className='font-bold black_color'>Loaded Weight</span>
                                                    </div>
                                                    <div className='wdt50'>
                                                        <span className='font-bold grey_color'>3 TON</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='wdt40 brd_btm'>
                                                <div className='dv_flex pd5'>
                                                    {/* <div className='wdt40 brd_rht'>
                                                    <span className='font-bold black_color'>Package Detail</span>
                                                </div>
                                                <div className='wdt60'>
                                                    <span className='font-bold grey_color'>100(Box)</span>
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Invoice Detail</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Number</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold grey_color'>123456</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Date</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold grey_color'>12/03/2021</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Value</span>
                                        </div>
                                        <div className='wdt20'>
                                            <span className='font-bold grey_color'>20000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse autoheight">
                                <div class="customHead">
                                    Payment Details
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold black_color'>Weight Charges</span>
                                        </div>
                                        <div className='wdt30 brd_rht'>
                                            <span className='font-bold grey_color'>1200(per KG)</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold black_color'>Insurance Charges</span>
                                        </div>
                                        <div className='wdt30 brd_rht'>
                                            <span className='font-bold grey_color'>12(% of Invoice Value)</span>
                                        </div>
                                    </div>
                                    <div className='dv_flex'>
                                        <div className='wdt50 brd_rht'>
                                            <div className='dv_flex pd5 brd_btm'>
                                                <div className='wdt100 text-center'>
                                                    <span className='font-bold black_color'>Freight Charges</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex pd5 brd_btm'>
                                                <div className='wdt50 brd_rht'>
                                                    <span className='font-bold black_color'>Total Freight</span>
                                                </div>
                                                <div className='wdt50'>
                                                    <span className='font-bold grey_color'>10000</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex pd5'>
                                                <div className='wdt50 brd_rht'>
                                                    <span className='font-bold black_color'>Delivery Charge</span>
                                                </div>
                                                <div className='wdt50'>
                                                    <span className='font-bold grey_color'>5000</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='wdt50'>
                                            <div className='dv_flex pd5 brd_btm'>
                                                <div className='wdt50 brd_rht'>
                                                    <span className='font-bold black_color'>Advance Freight</span>
                                                </div>
                                                <div className='wdt50'>
                                                    <span className='font-bold grey_color'>5000</span>
                                                </div>
                                            </div>
                                            <div className='dv_flex pd5 brd_btm'>
                                                <div className='wdt50 brd_rht'>
                                                    <span className='font-bold black_color'>Freight Due After Delivery</span>
                                                </div>
                                                <div className='wdt50'>
                                                    <span className='font-bold grey_color'>2000</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse autoheight">
                                <div class="customHead">
                                    Transportation Details
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Vehicle Type</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>Open Body Container</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Vehicle Number</span>

                                        </div>
                                        <div className='wdt15 brd_rht flex-me'>
                                            <span className='font-bold grey_color'>DL32GH5342</span>
                                            {/* <div className='btn_txt font-lg' onClick={() => updateTruck()}><i className='fa fa-edit clr'></i></div> */}

                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Driver Name</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>Raj Mohan</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Driver Mobile</span>
                                        </div>
                                        <div className='wdt15'>
                                            <span className='font-bold grey_color'>8759632014</span>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>DL Number</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>XXXX-992G-JW77</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Loaded On</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>07/02/2022 10:40 PM</span>
                                        </div>
                                        {getUrlParam === 'logistics-gate-out' || getUrlParam === 'material-gate-out' ?
                                            <div className='wdt10 brd_rht'>
                                                <span className='font-bold black_color'>Logistic Gate Out Time</span>
                                            </div>
                                            : ''}
                                        {getUrlParam === 'logistics-gate-out' || getUrlParam === 'material-gate-out' ?
                                            <div className='wdt15 brd_rht'>
                                                <span className='font-bold grey_color'>08/02/2022 10:30 AM</span>
                                            </div>
                                            : ''}
                                        {getUrlParam === 'material-gate-out' ?
                                            <div className='wdt10 brd_rht'>
                                                <span className='font-bold black_color'>Material Gate Out Time</span>
                                            </div>
                                            : ''}
                                        {getUrlParam === 'material-gate-out' ?
                                            <div className='wdt15'>
                                                <span className='font-bold grey_color'>28/02/2022 10:30 AM</span>
                                            </div>
                                            : ''}
                                    </div>
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Arrived On</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>(Vendor GPS System)</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Delivered On</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>(Lynkit)</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Waiting time at destination</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>(Delivery - Arrival)</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Delivered To</span>
                                        </div>
                                        <div className='wdt15'>
                                            <span className='font-bold grey_color'>(Lynkit)</span>
                                        </div>
                                    </div>
                                    <div className='dv_flex pd5'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Signature</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>(Lynkit)</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold black_color'>Delivery confirm By</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>(Lynkit)</span>
                                        </div>
                                        <div className='wdt45 text-center'>
                                            <span className='font-bold green_color'>Verified with OTP : <b className='font-bold'>(Lynkit)</b></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse autoheight">
                                <div class="customHead">
                                    Additional Documents
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex pd5 brd_btm'>
                                        <div className='wdt5 brd_rht'>
                                            <span className='font-bold black_color'>1.</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold black_color'>Document Type</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold grey_color'>Challan Receipt</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold black_color'>File Name</span>
                                        </div>
                                        <div className='wdt20 brd_rht'>
                                            <span className='font-bold grey_color'>xyz.pdf</span>
                                        </div>
                                        <div className='text-center wdt5'>
                                            <i className='fa fa-eye orange_color font-lg crsr-ptr'></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-collapse autoheight" >
                                <div class="customHead">
                                    Policy Details
                                </div>
                                <div class="customBdy">
                                    <div className='dv_flex pd5'>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Policy Number</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>TEMP12344</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Name Of Issuer</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>TEST Indud Ltd.</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Issue Date</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>12-01-2022</span>
                                        </div>
                                        <div className='wdt10 brd_rht'>
                                            <span className='font-bold black_color'>Expiry Date</span>
                                        </div>
                                        <div className='wdt15 brd_rht'>
                                            <span className='font-bold grey_color'>12-03-2022</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {updateTruckModal && (
                    <div className='updateTruckModal'>

                        <div className='truckOverlay'></div>
                        <div className='truck-content'>
                            <span className='tr-main'>Update Truck </span>
                            <div className='doc-close' onClick={closeTruckModal}>
                                <span><i className="fa fa-times" id="docView-cross" aria-hidden="true"></i></span>
                            </div>
                            <div className='truck_content_inner'>
                                <div className='truck_content_detail'>
                                    <span className='tr-he'>Vehicle Number</span>
                                    <input className='tr-in' type="text" placeholder='DL32GH5342' />
                                </div>

                                <div className='truck_content_detail'>
                                    <span className='tr-he'>New Vehicle Number</span>
                                    <input className='tr-in' type="text" placeholder='New Number' />
                                </div>

                                <div className='truck_content_detail'>
                                    <button className='truckUpdateBtn'>Update</button>
                                </div>
                            </div>

                        </div>
                    </div>
                )}
                {
                    props.type === 'delivery' ? "" :
                        <div className="cust-row flex-algn-cent prevent-overflow">
                            <div className='cust-col-3'></div>
                            <div className='cust-col-4'>
                                <button className='button bti bg-success-out m-0' onClick={() => { _handleClick('podModal') }}>
                                    <div className='btn_icon'>
                                        <i className='fa fa-check font-xl'></i>
                                    </div>
                                    <div className='btn_txt font-md'>Generate PoD</div>
                                </button>
                            </div>
                        </div>
                }
            </div>
            <PodModal closeModal={_closeModal} />
            <TaggedImage closeModal={_closeModal} />
        </div>
    )
}

export default ReceiptDetail