import React, { useState } from 'react'
import history from './../../services/history';
import { getHubData } from '../../state/redux/actions/index';
import { useDispatch } from 'react-redux';
import $ from 'jquery'
import DocViewAddressModal from './Modal/DocViewAddress'

const AddHub = () => {
    const dispatch = useDispatch()
    const [panShow, setPanShow] = useState(false)
    const [gstShow, setGstShow] = useState(false)
    const [base, setBase] = useState('')
    const _moveBck = () => {
        history.push("/dashboard/hubs")
    }
    const [hubDetails, setHubDetails] = useState({
        hubName: "",
        address: "",
        state: "",
        city: "",
        pinCode: "",
        pan: "",
        panCopy: "",
        gstin: "",
        gstinUpload: "",
        contactPersonName: "",
        mobileNo: "",
        emailId: "",
        totalUsers: ""
    })
    const _onChange = (e) => {
        setHubDetails({ ...hubDetails, [e.target.name]: e.target.value })
    }
    const registerHub = () => {
        dispatch(getHubData(hubDetails))
    }
    const uploadFrontImage = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        const localArray = hubDetails
        if (item === 'pan') {
            localArray.panCopy = base64
            setPanShow(true)
            setHubDetails(localArray)
        }
        else if (item === 'gst') {
            localArray.gstinUpload = base64
            setGstShow(true)
            setHubDetails(localArray)
        }
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }

                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _sendData = (item) => {
        var newArray = hubDetails
        if (item === 'pan') {
            setBase(newArray.panCopy)
        }
        else if (item === 'gst') {
            setBase(newArray.gstinUpload)
        }
    }

    return (
        <div className="contentpanel">
            <div className="cust-container">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-4'>
                        <h1 className="title">
                            <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_moveBck}>
                                <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                            </button>
                            Add Hub
                        </h1>
                    </div>
                    <div className='cust-col-2'>
                    </div>
                </div>
                <hr />
                <div className="ContractDetails1">
                    <div className="addContract-I">
                        <div className="contract-in">
                            <div className="contract-span">
                                <span className="ct-span">Hub Name :</span>
                            </div>
                            <div className="contract-input">
                                <input className="ct-input" name="hubName" value={hubDetails.hubName} onChange={(e) => _onChange(e)} type='text' placeholder="Enter Hub Name" />
                            </div>
                        </div>
                        <div className="contract-in">
                            <div className="contract-span">
                                <span className="ct-span">Hub Address :</span>
                            </div>
                            <div className="contract-input">
                                <input className="ct-input" type='text' name="address" value={hubDetails.address} onChange={(e) => _onChange(e)} placeholder="Enter Hub Address" />
                            </div>
                        </div>
                        <div className="contract-in">
                            <div className="contract-span">
                                <span className="ct-span">State :</span>
                            </div>
                            <div className="contract-input">
                                <input className="ct-input" type='text' name="state" value={hubDetails.state} onChange={(e) => _onChange(e)} placeholder="Enter State" />
                            </div>
                        </div>
                    </div>
                    <div className="addContract-I">
                        <div className="contract-in">
                            <div className="contract-span">
                                <span className="ct-span">City :</span>
                            </div>
                            <div className="contract-input">
                                <input className="ct-input" type='text' name="city" value={hubDetails.city} onChange={(e) => _onChange(e)} placeholder="Enter City" />
                            </div>
                        </div>
                        <div className="contract-in">
                            <div className="contract-span">
                                <span className="ct-span">Pincode :</span>
                            </div>
                            <div className="contract-input">
                                <input className="ct-input" type='number' name="pinCode" value={hubDetails.pinCode} onChange={(e) => _onChange(e)} placeholder="Enter Pincode" />
                                {hubDetails.pinCode.match(/^(\d{6})$/) ?
                                    <i className="fa fa-check validationCheck setPostCheck"></i>
                                    :
                                    hubDetails.pinCode.length > 0 ?
                                        <i className="fa fa-close setPostWrongCheck"></i>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="contract-in">
                            <div className="contract-span">
                                <span className="ct-span">Contact Person :</span>
                            </div>
                            <div className="contract-input">
                                <input className="ct-input" type='text' name="contactPersonName" value={hubDetails.contactPersonName} onChange={(e) => _onChange(e)} placeholder="Enter Contact Person Name" />
                            </div>
                        </div>

                    </div>
                    <div className="addContract-I">
                        <div className="contract-in">
                            <div className="contract-span">
                                <span className="ct-span">Email ID :</span>
                            </div>
                            <div className="contract-input">
                                <input className="ct-input" type='email' name="emailId" value={hubDetails.emailId} onChange={(e) => _onChange(e)} placeholder="Enter Email ID" />
                                {hubDetails.emailId.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ?
                                    <i className="fa fa-check validationCheck setPostCheck"></i>
                                    :
                                    hubDetails.emailId.length > 0 ?
                                        <i className="fa fa-close setPostWrongCheck"></i>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="contract-in">
                            <div className="contract-span">
                                <span className="ct-span">Mobile Number :</span>
                            </div>
                            <div className="contract-input">
                                <input className="ct-input" type='text' name="mobileNo" value={hubDetails.mobileNo} onChange={(e) => _onChange(e)} placeholder="Enter Number" />
                                {hubDetails.mobileNo.length === 10 ?
                                    <i className="fa fa-check validationCheck setPostCheck"></i>
                                    :
                                    hubDetails.mobileNo.length > 0 ?
                                        <i className="fa fa-close setPostWrongCheck"></i>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="contract-in">
                            <div className="contract-span">
                                <span className="ct-span">PAN :</span>
                            </div>
                            <div className="contract-input">
                                <input className="ct-input" type='text' name="pan" value={hubDetails.pan} onChange={(e) => _onChange(e)} placeholder="Enter PAN Number" />
                                <div className='addUploadItemHub'>
                                    <i className="fa fa-upload uploadIconn" ></i>
                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'pan')} type="file" />
                                    {panShow === true ?
                                        <i className="fa fa-eye myVisibleEyeNew" onClick={() => { _handleClick('DocViewAddressModal'); _sendData('pan') }}></i>
                                        : ''}
                                </div>
                                {/* {hubDetails.pan.match(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/) ?
                                    <i className="fa fa-check validationCheck setPostCheck"></i>
                                    :
                                    hubDetails.pan.length > 0 ?
                                        <i className="fa fa-close setPostWrongCheck"></i>
                                        : ''
                                } */}

                            </div>
                        </div>
                    </div>
                    <div className="addContract-I">
                        <div className="contract-in">
                            <div className="contract-span">
                                <span className="ct-span">GSTIN :</span>
                            </div>
                            <div className="contract-input">
                                <input className="ct-input" type='text' name="gstin" value={hubDetails.gstin} onChange={(e) => _onChange(e)} placeholder="Enter GSTIN" />
                                <div className='addUploadItemHub'>
                                    <i className="fa fa-upload uploadIconn" ></i>
                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'gst')} type="file" />
                                    {gstShow === true ?
                                        <i className="fa fa-eye myVisibleEyeNew" onClick={() => { _handleClick('DocViewAddressModal'); _sendData('gst') }}></i>
                                        : ''}
                                </div>
                                {/* {hubDetails.gstin.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/) ?
                                    <i className="fa fa-check validationCheck setPostCheck"></i>
                                    :
                                    hubDetails.gstin.length > 0 ?
                                        <i className="fa fa-close setPostWrongCheck"></i>
                                        : ''
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='addHubBtn'>
                    <button className='button bti bg-orange-out' onClick={registerHub} >
                        <div className='btn_icon'>
                            <i className="fa fa-plus font-lg" ></i>
                        </div>
                        <div className='btn_txt font-md'>Add Hub</div>
                    </button>
                </div>
            </div>
            <DocViewAddressModal closeModal={_closeModal} imageData={base} />
        </div>

    )
}

export default AddHub;