import { post } from './../../../services/requests'
import {CLAUSES_ACTION} from './types' 
 
export const getCategories = (data) =>{ 
    return(dispatch)=>{
        post('contract/getcategories',{...data})
        .then(response=>{
            const data =response.data
            const status= response.error
            const message= response.message
             dispatch(sendCategories(data))
             if(status === true){
                  window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                }) 
             }
             else{
              
             }
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const sendCategories = (data) =>{
    return{
        type :CLAUSES_ACTION,
        payload:data
    }
}

