import { get } from './../../../services/requests'
import { getImages } from './getImage'
import {GET_SINGLE_DRIVER, HIDE_SINGLE_DRIVER } from './types'

export const getDriverinfo = (DLNumber) =>{ 
    return(dispatch)=>{
        get(`driver/GetDriver/${DLNumber}`)
        .then(response=>{
             const driverData= response.data
             dispatch(storeDriverData(driverData))
             dispatch(hideDriverLoader())

             let arr = driverData.AttachedDocuments
             for (let i = 0; i < arr.length; i++) {
                 let key = driverData && driverData.AttachedDocuments && driverData.AttachedDocuments[i] && driverData.AttachedDocuments[i].documentKey ? driverData.AttachedDocuments[i].documentKey : ''
                 if (key.length !== 0) {
                     dispatch(getImages(key))
                 }
             }  


        })
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const storeDriverData = (addressData) => {
    return {
        type: GET_SINGLE_DRIVER,
        payload: addressData
    }
}
export const hideDriverLoader = () => {
    return {
        type: HIDE_SINGLE_DRIVER
    }
}