import {GET_VEHICLE,HIDE} from  "../actions/types";

const initialState={
    vehicleData: [],
    loading:true
} 

const getVehicleReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_VEHICLE : return{
            ...state,
            vehicleData:payload
        }
        case HIDE : return{
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default getVehicleReducer