import React, { useState } from 'react';
import $ from 'jquery'
import history from '../../../services/history';
import driverInactive from './../../../static/images/icon/workforce/driver-inact.svg'
import driverActive from './../../../static/images/icon/workforce/driver-act.svg'
import moment from 'moment'
import staffInactive from './../../../static/images/icon/workforce/staff-inact.svg'
import staffActive from './../../../static/images/icon/workforce/staff-act.svg'

const AddWorkforce = (props) => {
    const [driverImg,setDriverImg]=useState(driverInactive)
    const [staffImg,setStaffImg]=useState(staffInactive)
    const [checkState,setCheckState]=useState('')

    const setImgFun=(item)=>{
        if(item==='driver'){
            setDriverImg(driverActive)
            setStaffImg(staffInactive)
            setCheckState('driver')
        }
        else if(item==='staff'){
            setStaffImg(staffActive)
            setDriverImg(driverInactive)
            setCheckState('staff')
        }

    }
    const _handleClick = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
        if(checkState) {
            history.push({
                pathname: '/dashboard/workforce/add',
                state: { checkState } 
            })
        }
    }

    return (
        <section id="workforceModal" className="custom-modal toggle_width">
            <div className="custom-modal-header">
                <h1>Add Workforce</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                <div className="cust-row">
                    <div className='workForceDiv'>
                        <div className='wfOuter'>
                            <div className='workForceRow'>
                            <div className='wFInnrow2'>
                                    <img className='driverIcon' src={driverImg} alt="" onClick={()=>setImgFun("driver")} />
                                </div>                               
                                <div className='wFInnerrow3'>
                
                                </div>
                                <div className='wFInnerrow1'>
                                    <span onClick={()=>setImgFun("driver")}>DRIVER</span>
                                </div>
                            </div>
                            <div className='workForceRow1'>
                                <div className='wFInnrow2'>
                                    <img className='driverIcon' src={staffImg} alt="" onClick={()=>setImgFun("staff")} />
                                </div>
                                <div className='wFInnerrow3'>
                                   
                                </div>
                                <div className='wFInnerrow1'>
                                    <span onClick={()=>setImgFun("staff")}>STAFF</span>
                                </div>
                            </div>
                        </div>
                        <div className='workForceRow'>
                            <div className='WFI3'>
                                <button className='wfBtn' onClick={_handleClick}>Add</button>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </section>
    )

}

export default AddWorkforce;