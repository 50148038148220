import { get } from './../../../services/requests'
import { GET_SINGLE_HUB, HIDE_SINGLE_HUB } from './types'

export const getSingleHub = (HubId) =>{  
    return(dispatch)=>{
        get(`hub/GetHubDetails/${HubId}`)
        .then(response=>{
             console.log('single hub response',response);
             const hubData= response.data
             dispatch(storeHubData(hubData))
             dispatch(hideHubLoader())
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
} 
export const storeHubData = (addressData) => {
    return {
        type: GET_SINGLE_HUB,
        payload: addressData
    }
}
export const hideHubLoader = () => {
    return {
        type: HIDE_SINGLE_HUB
    }
}


