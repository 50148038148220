import { get } from './../../../services/requests'
import { USER_PROFILE , USER_PROFILE_LOADER } from './types'

export const getUserProfile = () =>{  
    return(dispatch)=>{
        get('user/GetUserProfile')
        .then(response=>{
             const profileData= response.data
             dispatch(storeProfileData(profileData))
             dispatch(hideHubLoader())
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
} 
export const storeProfileData = (profileData) => {
    return {
        type: USER_PROFILE,
        payload: profileData
    }
}
export const hideHubLoader = () => {
    return {
        type: USER_PROFILE_LOADER
    }
}


