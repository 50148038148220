import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "./../../static/images/logo.png";
// import logo_samsung from "../../static/images/logo_samsung.svg";
import history from "./../../services/history";
import PasswordModal from "./Modal/ChangePassword";
import $ from "jquery";
import jwt_decode from "jwt-decode";

const Header = (props) => {
  const [token, setToken] = useState("");

  const changeRoute = () => {
    history.push("/dashboard/userProfile");
  };
  const openSettings = () => {
    history.push("/dashboard/my-Settings");
  };

  const _openModal = (id) => {
    $(".custom-modal-overlay").addClass("custom-modal-is-visible");
    $(`#${id}`).addClass("custom-modal-is-visible");
  };

  const _closeModal = () => {
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible");
    $(".custom-modal").removeClass("custom-modal-is-visible");
  };

  useEffect(() => {
    if (localStorage.getItem("secretkey") !== "") {
      var getToken = localStorage.getItem("secretkey");
      setToken(getToken);
      var decoded = jwt_decode(getToken);
      localStorage.setItem("gname", decoded.Name);
      localStorage.setItem("name", decoded.OrgName);
      localStorage.setItem("email", decoded.EmailId);
      localStorage.setItem("userSubGroup", decoded.UserSubGroup);
      localStorage.setItem("department", decoded.DepartmentName);
      localStorage.setItem("plantName", decoded.PlantName);
    }
  }, [token]);
  return (
    <header>
      <div className="headerpanel">
        <div className="logopanel">
          <h2>
            <Link to="/dashboard">
              <img
                src={logo}
                alt="logo"

                // <img
                //     src={logo_samsung}
                //     alt="logo"
                //     style={{
                //       display: "block",
                //       width: "100%",
                //       marginLeft: "0.25em",
                //     }}
                //   />
              />
            </Link>
          </h2>
        </div>
        <div className="headerbar">
          <a
            id="menuToggle"
            className="menutoggle"
            href="abcd"
            onClick={(event) => event.preventDefault()}
          >
            <i className="fa fa-bars"></i>
          </a>
          <div className="header-right">
            <ul className="headermenu">
              <li>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-logged"
                    data-toggle="dropdown"
                    data-target="#headdp"
                    aria-expanded="false"
                  >
                    <img src={props?.image} alt="" />
                    <div className="udeta">
                      {localStorage.getItem("userGroup") === "Goods-Owner" ? (
                        <span className="n1">
                          {localStorage.getItem("gname")}
                        </span>
                      ) : (
                        <span className="n1">
                          {localStorage.getItem("name")}
                        </span>
                      )}

                      <span className="n2">
                        {localStorage.getItem("email")}
                      </span>
                      <span className="n3">
                        {localStorage.getItem("userGroup")}
                      </span>
                    </div>
                    <span className="caret"></span>
                  </button>
                  <div className="user-dropmenu" id="headdp">
                    <ul>
                      <li onClick={changeRoute}>
                        <i className="fa fa-user" aria-hidden="true"></i>{" "}
                        Profile
                      </li>
                      <li
                        onClick={() => {
                          _openModal("changePasswordModal");
                        }}
                      >
                        <i className="fa fa-key" aria-hidden="true"></i> Change
                        Password
                      </li>
                      <li onClick={openSettings}>
                        <i className="fa fa-cog"></i> Setting
                      </li>
                      <li onClick={props.handleClick}>
                        <i className="fa fa-sign-out"></i> Logout
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PasswordModal closeModal={_closeModal} />
    </header>
  );
};

export default Header;
