import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery'
import { selectInit } from '../../../services/functions'
import history from '../../../services/history'
import ConfirmBookingModal from '../Modal/ConfirmBooking'
import AllocateTruckModal from '../Modal/AllocateTruck'
import InspectionModal from '../Modal/Inspection'
import view from '../../../static/images/icon/buttons/ViewDetail.svg'
import allocate from '../../../static/images/icon/buttons/allocate.svg'
import cancel from '../../../static/images/icon/buttons/Cancel.svg'
import documentView from '../../../static/images/icon/buttons/ViewDocuments.svg'
import AnalysisModal from '../Modal/Analysis'
import TrackingModal from '../Modal/Tracking'
import AddBookingModal from '../Modal/AddBooking'
import ViewDocumentsModal from '../Modal/ViewDocuments'
import { BookingAction, searchFunction, allocatelists, listingApi } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import axios from 'axios'

const Booking = (props) => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.bookingReducers.bookingListing)
    const listingLoader = useSelector((state) => state.bookingReducers.loader)
    const bookmark = useSelector((state) => state.bookingReducers.bookmarks)
    const records = useSelector((state) => state.bookingReducers.records)
    const [type, setType] = useState('truck-reporting')
    const [userGroup, setUserGroup] = useState('')
    const [subPart, setSubPart] = useState('')
    const [gateInRequestData, setGateInData] = useState({})
    const [val, setVal] = useState('')
    const [transVal , setTransVal] = useState('')
    const [addState, setAddState] = useState([])
    const transportersList = useSelector((state) => state.getListingReducer.transporterListing)
    
    const [allocateObject, setAllocateObject] = useState({
        load: " ",
        shipmentGID: "",
        RemainingLoadableCapacity: "",
        GrossWeight: "",
        TareWeight: ""
    })
    const [obj, setObj] = useState({
        param: "next",
        status: props.type,
        bookmark: "",
        vehicleStatus: "",
        search: "",
        searchBy: "",
        inspectionStatus: "",
        inspectionType: "",
        vehicleBelongsTo: "",
        transporterId: ""
    })
    const [searchObject, setSearchObject] = useState(
        {
            bookmark: "",
            vehicleStatus: "",
            search: "",
            searchBy: "",
            inspectionStatus: "",
            inspectionType: "",
            vehicleBelongsTo: "",
            transporterId: ""
        }
    )
    useEffect(() => {
        setType(props.type)
        setProps()
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 100);
        setTimeout(() => {
            selectInit(onChangeValue)
        }, 100);
        setUserGroup(localStorage.getItem("userGroup") === 'transporter' ? '2' : "1")
        bookmark.length = 0
        if (type !== '') {
            setObj(
                {
                    param: "next",
                    status: props.type,
                    bookmark: "",
                    vehicleStatus: "",
                    search: "",
                    searchBy: "",
                    inspectionStatus: "",
                    inspectionType: "",
                    vehicleBelongsTo: "",
                    transporterId: ""
                }
            )
            dispatch(BookingAction(obj))
        }
        else { }
    }, [props.type])

    const triggerSearch = () => {
        dispatch(BookingAction(
            {
                param: "next",
                status: props.type,
                bookmark: "",
                vehicleStatus: "",
                search: searchObject.search,
                searchBy: searchObject.searchBy,
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: "",
                transporterId: ""
            }
        ))
    }
    const setProps = () => {
        if (props.type === 'truck-reporting') {
            searchObject.vehicleStatus = 'truckReporting'
        }
    }
    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")
        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }
    const onChangeValue = (evt) => {
        if (evt === 'hil') {
            setSubPart('hil')
        }
        else if (evt === 'customer') {
            setSubPart('customer')
        }
    }
    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchBy = value
            searchObject.searchBy = value
            setSearchObject(temp)
        }
        else if (id === 'VehicleOf') {
            setVal(value)
        }
        else if (id === 'transporter'){
            setTransVal(value)
        }
    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const confirmBooking = () => {
        window.$.fn.show_notification({ message: "confirmation is required for acceptance of booking", title: 'Are you sure!!', type: 'confirm', autohide: false }, (result) => {
            if (result.type === "confirm") {
                _closeModal()
            }
        })
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _handleClickAddBooking = (id) => {
        setAddState(transportersList)
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const cancelBooking = (index) => {
        window.$.fn.show_notification({ message: "Cancel your booking from your OTM Platform.", title: 'Are you sure!!', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
            if (result.type === 'confirm') {
            }
        })
    }
    const _viewDetail = (item) => {
        history.push({
            pathname: "/dashboard/booking/detail",
            state: { url_param: type, data: item }

        })
    }
    const allocateFun = (index) => {
        let allObject = { ...allocateObject }
        allObject.load = listing[index].ShipQuantityAccepted
        allObject.shipmentGID = listing[index].ShipmentGID
        allObject.RemainingLoadableCapacity = listing[index].RemainingLoadableCapacity
        setAllocateObject(allObject)
    }
    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            status: props.type
        }
        setObj(object)
        dispatch(BookingAction(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            status: props.type
        }
        setObj(object)
        dispatch(BookingAction(object))
        bookmark.pop()
    }
    const getAllocateList = (id) => {
        dispatch(allocatelists(id))
    }
    useEffect(() => {
        dispatch(listingApi({
            docType: "transporter",
            param: "",
            pageSize: "",
            bookmark: "",
            blacklistedFlag: "",
            searchType: "",
            searchValue: ""
        }))
    }, [])
    const shareAllData = (index) => {
        let allObject = { ...allocateObject }
        allObject.load = listing[index].ShipQuantityAccepted
        allObject.shipmentGID = listing[index].ShipmentGID
        allObject.RemainingLoadableCapacity = listing[index].RemainingLoadableCapacity
        setAllocateObject(allObject)
    }
    useEffect(() => {
        if (val) {
            dispatch(BookingAction({
                param: "",
                status: "truck-reporting",
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: val,
                transporterId: ""
            }))
        }
    }, [val])

    useEffect(() => {
        if (transVal) {
            dispatch(BookingAction({
                param: "next",
                status: "truck-reporting",
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: "",
                transporterId: transVal
            }))
        }
    }, [transVal])


    return (
        <div className="contentpanel">
            <h1 className="title">Booking</h1>
            <hr />
            <div className="cust-container">
                <div className='cust-row'>
                    <div className='cust-col-1_2 flex-algn-cent'>
                        <div className='tt2'>Booking List</div>
                    </div>
                    <div className='cust-col-3'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <select className="form-control select2-single" defaultValue="select" id="searchType">
                                    <option value="select">Select</option>
                                    <option value="shipmentGID">Shipment GID</option>
                                </select>
                            </div>
                            <input type="text" name='search' value={searchObject.search} onChange={(e) => _onChangeSearch(e)} className="form-control" placeholder="Search here..." />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2" onClick={() => triggerSearch()}>
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    {type !== 'all' && userGroup !== '2' ?
                        <div className='cust-col-1_2'>
                            <select className="form-control select2-single" defaultValue="Vehicle" id="VehicleOf">
                                <option value="Vehicle">Vehicle of</option>
                                <option value="transporter">Transporter vehicles</option>
                                <option value="customer">Customer vehicles</option>
                            </select>
                        </div>
                        : ""}
                    {type === 'truck-reporting' ?
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="pending" id="allocation">
                                <option value="pending">Allocation Pending</option>
                                <option value="partially">Partially Allocated</option>
                                <option value="fully">Fully Allocated</option>
                            </select>
                        </div>
                        : ""}
                    {userGroup === '1' ?
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="pending" id="transporter">
                                <option value="select">Transporter Name</option>
                                {transportersList && transportersList.map((item, index) => {
                                    return (
                                        <option key={index} value={transportersList[index]._id}>{item.OrgName}</option>
                                    )
                                })}
                            </select>
                        </div>
                      
                    :""}
                    {type === 'truck-reporting' && userGroup === '1' ?
                        <div className='cust-col-2 flex-jc-end'>
                            <button className='btn btn-shadow btn-secondary size_hgt' onClick={() => { _handleClickAddBooking('AddBookingModal') }}>Add Booking</button>
                        </div>
                        : ""}

                </div>

                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='data-cont'>
                            {
                                listing && listing.length !== 0 ?
                                    listing.map((item, index) => {
                                        return (
                                            <div key={index} className='data-cont-item '>
                                                {userGroup === '1' ?
                                                    <div className='data_item_content align-div pad-0'>
                                                        <div className='wd_30 clip-path display-col'>
                                                            <span className='tbl-hd-1'>Transporter :</span>
                                                            <span className='tbl-hd-2'>{item.TransporterName ? item.TransporterName : "-"}</span>
                                                            <span className='tbl-hd-3'>{item.OrgReadableID ? `(ID - ${item.OrgReadableID})` : ""}</span>
                                                        </div>
                                                        <div className='wd_30 bg-trans inc-hgt'>
                                                            <div className='dateTime'>
                                                                <span>Date/Time</span>
                                                            </div>
                                                            <div className='date'>
                                                                <i className="fa fa-calendar"></i>
                                                                <span>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>
                                                            </div>
                                                            <div className='time'>
                                                                <i className="fa fa-clock-o xl"></i>
                                                                <span>{moment(item.TruckReportingTime).format("h:mm:ss a")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''}
                                                <div className='data_item_content set_height_xxl pad-top-0 set-new-hght'>
                                                    <div className='wd_5 align-cent set-hght'>
                                                        <div className='sr-no '>
                                                            <span>{index + 1}</span>
                                                        </div>
                                                    </div>
                                                    <div className={userGroup === '1' ? 'wd_15' : 'wd_20'}>
                                                        <span>Shipment GID</span>
                                                        <span className='orange_color font-xmd'>{item.ShipmentGID}</span>
                                                        <span>{item.CreationDay}</span>
                                                    </div>
                                                    <div className={userGroup === '1' ? 'wd_15' : 'wd_20'}>
                                                        <span>Source Location GID</span>
                                                        <span className='flex_box_2'>{item.SourceLocationGID}</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Destination Location GID</span>
                                                        <span className='flex_box_2'>{item.DestinationLocationGID}</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Reference Ship ID</span>
                                                        <span>{item.ReferenceShipGID}</span>
                                                    </div>
                                                    <div className="wd_10">
                                                        <span className='flex_box_2'>Transport Mode GID</span>
                                                        <span>{item.TransportModeGID}</span>
                                                    </div>
                                                    <div className="wd_10">
                                                        <span className='flex_box_2'>Service Provider GID</span>
                                                        <span>{item.ServiceProviderGID}</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Total weight</span>
                                                        <span>{item.TotalWeight}</span>
                                                    </div>
                                                    {userGroup === '1' ?
                                                        <div className='wd_10'>
                                                            <span>Status</span>
                                                            <span>Inspection Pending</span>
                                                        </div>
                                                        : ''}
                                                    <div className='wd_5 align-cent set-hght set-bg'>
                                                        <div className='sr-no trans' onClick={() => { toogleItemView(index) }}>
                                                            <i className="fa fa-plus expand-tbl open"></i>
                                                            <i className="fa fa-minus contract-tbl hide" ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='data_item_content clps set_height_xxxl setColor '>
                                                    <>
                                                        <div className='wd_20'>
                                                            <span>Ship Quantity Accepted</span>
                                                            <span>{item.ShipQuantityAccepted}</span>
                                                        </div>
                                                        <div className='wd_20'>
                                                            <span>Loadable Capacity</span>
                                                            <span>{item.LoadableCapacity === "" ? "-" : item.LoadableCapacity}</span>
                                                        </div>
                                                        <div className='wd_20 '>
                                                            <span>Planning Type</span>
                                                            <span>{item.PlanningType}</span>
                                                        </div>
                                                        <div className='wd_15'>
                                                            <span>Goods Type</span>
                                                            <span>{item.GoodsType ? item.GoodsType : '-'}</span>
                                                        </div>

                                                        <div className='wd_25'>
                                                            <span>Truck Reporting Time</span>
                                                            <span>{item.TruckReportingTime === null ? "-" : moment(item.TruckReportingTime).format("h:mm:ss a")}</span>
                                                        </div>
                                                        <div className='wd_30 no-mg-pd designChange'>
                                                            <div className='btn_row_1'>
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 btn_size' onClick={() => { _handleClick('ViewDocumentsModal') }} ><img src={documentView} alt="none" />View Documents</button>
                                                                {userGroup === '2' ?
                                                                    <button className={item.ShipQuantityAccepted === item.RemainingLoadableCapacity ? 'btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1  inline-flex' : 'hideBtn'}>
                                                                        <div className='btn_text' onClick={() => { _handleClick('confirmBookingModal'); allocateFun(index) }}>
                                                                            <span><img src={allocate} alt="none" />Allocate Truck </span>
                                                                        </div>
                                                                        <div className='btn_pic' onClick={() => { _handleClick('confirmBookingModal'); allocateFun(index) }}>
                                                                            <span><i className='fa fa-plus-circle ccIcon' ></i></span>
                                                                        </div>
                                                                    </button>
                                                                    : ''}
                                                                {userGroup === '2' ?

                                                                    <button className={item.ShipQuantityAccepted !== item.RemainingLoadableCapacity ? 'btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 inline-flex' : 'hideBtn'}>
                                                                        <div className='btn_text ' onClick={() => { _handleClick('AllocateTruckModal'); getAllocateList(item.ShipmentGID); shareAllData(index) }}>
                                                                            <span><img src={allocate} alt="none" />Update Truck </span>
                                                                        </div>
                                                                        <div className='btn_pic' onClick={() => { _handleClick('confirmBookingModal'); allocateFun(index) }}>
                                                                            <span><i className='fa fa-plus-circle ccIcon' ></i></span>
                                                                        </div>
                                                                    </button>
                                                                    : ''}

                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 ' onClick={() => { cancelBooking(0) }}> <img src={cancel} alt="none" />Cancel Booking</button>
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={() => { _viewDetail(item) }}> <img src={view} alt="none" />View Detail</button>

                                                            </div>
                                                        </div>

                                                    </>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <span className='noDataClass'>No Data Found</span>
                            }
                        </div>
                        {listing && listing.length !== 0 ?
                            <div className='paginationDiv'>
                                <button disabled={obj && obj.bookmark === '' ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                                <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                            </div>
                            : ''}
                    </div>
                </div>
            </div>
            <ConfirmBookingModal closeModal={_closeModal} confirmBooking={confirmBooking} allocate={allocateObject} />
            <AllocateTruckModal closeModal={_closeModal} allocate={allocateObject} />
            <AnalysisModal closeModal={_closeModal} />
            <TrackingModal closeModal={_closeModal} />
            <InspectionModal closeModal={_closeModal} gateInRequestData={gateInRequestData} />
            <ViewDocumentsModal closeModal={_closeModal} />
            <AddBookingModal closeModal={_closeModal} transportersList={transportersList}/> 
        </div>
    )
}
export default Booking;