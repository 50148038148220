import React, { useEffect } from 'react';
import $ from 'jquery'
import history from '../../services/history';
import view from './../../static/images/icon/buttons/ViewDetail.svg'
import Delete from './../../static/images/icon/buttons/Delete.svg'
import update from './../../static/images/icon/buttons/Update.svg'
import documentView from './../../static/images/icon/buttons/ViewDocuments.svg'
import { selectInit } from './../../services/functions'

const InvoiceListing = () => {

    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")

        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }
    const createInvoice = () => {
        history.push("/dashboard/lorry-receipt/Select-PoD")
    }
    useEffect(() => {
        setTimeout(() => {
            selectInit()
        }, 100);
    })
    return (
        <>
            <div className="contentpanel">
                <h1 className="title">Invoices</h1>
                <hr />
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Invoices List</div>
                        </div>
                        <div className='cust-col-4'>
                            <div className="input-group">
                                <div className="input-group-prepend ">
                                    <select className="form-control select2-single" id="searchType">
                                        <option value="bill_to">Bill To</option>
                                        <option value="bill_to">Bill By</option>
                                    </select>
                                </div>
                                <input type="text" className="form-control" placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="all" id="ownershipStatus">
                                <option value="Invoice_no">Invoice Type</option>
                                <option value="paid">Paid</option>
                                <option value="notPaid">Not Paid</option>
                            </select>
                        </div>
                        <div className='cust-col-2'>
                            {
                                localStorage.getItem("userGroup") !== 'transporter' ?
                                    <select className="form-control select2-single" defaultValue="all" id="transporter">
                                        <option value="all">Transporter's Name</option>
                                        <option value="ajay">Ajay-(0010)</option>
                                        <option value="nitin">Nitin-(0020)</option>
                                    </select>
                                    : ""
                            }
                        </div>
                        {
                            localStorage.getItem("userGroup") === 'transporter' ?
                                <div className='cust-col-2 flex-jc-end'>
                                    <button onClick={createInvoice} className='btn btn-shadow btn-secondary size_hgt'>Create Invoice</button>
                                </div>
                                : ""
                        } 
                    </div>

                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='data-cont'>
                                <div className='data-cont-item '>
                                    {localStorage.getItem("userGroup") === 'transporter' ? "" :
                                        <div className='data_item_content align-div pad-0'>
                                            <div className='wd_30 clip-path display-col'>
                                                <span className='tbl-hd-1'>Transporter :</span>
                                                <span className='tbl-hd-2'>Ajay</span>
                                                <span className='tbl-hd-3'>(ID : XXXX)</span>
                                            </div>
                                            <div className='wd_25 bg-trans inc-hgt'>
                                                <div className='dateTime'>
                                                    <span>Date/Time</span>
                                                </div>
                                                <div className='date'>
                                                    <i className="fa fa-calendar"></i>
                                                    <span>18-03-2022</span>
                                                </div>
                                                <div className='time'>
                                                    <i className="fa fa-clock-o xl"></i>
                                                    <span>08:30:24 AM</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className='data_item_content set_height pad-top-0'>
                                        <div className='wd_5 align-cent set-hght'>
                                            <div className='sr-no '>
                                                <span>1</span>
                                            </div>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Invoive no</span>
                                            <span>IN-0001</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>LR No</span>
                                            <span>LR-0011, LR-0012 </span>
                                        </div>
                                        <div className='wd_20'>
                                            <span>Invoice Date & Time</span>
                                            <span>11.10.2022 , 11:34:20 AM </span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Product</span>
                                            <span> Copper </span>
                                        </div>
                                        {/* <div className='wd_10'>
                                            <span>Total Billing Weight</span>
                                            <span> 195 kg </span>
                                        </div> */}
                                        <div className='wd_25'>
                                            <span>Bill To</span>
                                            <span >Hindalco Faridabad -(GSTIN- IA0725711)</span>
                                        </div>
                                         <div className='wd_10'>
                                            <span>Contract ID</span>
                                            <span>00010 </span>
                                        </div>
                                         <div className='wd_10'>
                                            <span>Total Penalty</span>
                                            <span>₹  500 </span>
                                        </div>
                                        {/* <div className='wd_20'>
                                            <span>Bill By</span>
                                            <span>Dahej Plant</span>
                                        </div> */}
                                        <div className='wd_5 align-cent set-hght set-bg'>
                                            <div className='sr-no trans' onClick={() => { toogleItemView(0) }}>
                                                <i className="fa fa-plus expand-tbl open"></i>
                                                <i className="fa fa-minus contract-tbl hide" ></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='data_item_content set_height_xxl clps setColor'>
                                        <div className='wd_10'>
                                            <span>Total Gross Weight</span>
                                            <span>45.26 MT</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Total Tare Weight</span>
                                            <span>15.20 MT</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Total Loaded Weight</span>
                                            <span>30.20 MT</span>
                                        </div>
                                        {/* <div className='wd_10'>
                                            <span>Total Amount </span>
                                            <span>₹ 12000</span>
                                        </div> */}
                                        {/* <div className='wd_10'>
                                            <span> </span>
                                            <span>Boxes</span>
                                        </div> */}
                                        <div className='wd_15'>
                                            <span>GSTIN %</span>
                                            <span>18%</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Total Amount (To be paid)</span>
                                            <span>₹ 15000</span>
                                        </div>
                                        <div className='wd_40 no-mg-pd'>
                                            <div className='btn_row_1'>
                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={documentView} alt="none" />View Documents</button>
                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={update} alt="none" />Update</button>

                                            </div>
                                            <div className='btn_row_2'>
                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={Delete} alt="none" />Delete</button>
                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' ><img src={view} alt="none" />View Detail</button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvoiceListing;