import {GET_SINGLE_ADDRESS, HIDE_SINGLE_ADDRESS } from  "../actions/types";

const initialState={
    addressData: [],
    loading:true
} 

const getSingleAddReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_SINGLE_ADDRESS : return{
            ...state,
            addressData: payload
        }
        case HIDE_SINGLE_ADDRESS : return{
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default getSingleAddReducer