import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import axios from 'axios';

const Weighmentin = (props) => {
    const weighObject = props.weighmentin
    const [weighmentObject, setWeighObject] = useState(
        {
            action: "weighmentIn",
            shipmentGID: "",
            truckAllocationId: "",
        }
    )
    const [inpObj, setInpObj] = useState({
        tareWeight: "",
        weighmentNo: ""
    })
    const _handleChange = (e) => {
        setInpObj({ ...inpObj, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        if (weighObject !== undefined) {
            if ('TruckDetails' in weighObject) {
                weighmentObject.shipmentGID = weighObject.ShipmentGID
                weighmentObject.truckAllocationId = weighObject.TruckDetails.TruckAllocationId
            }
        }
    })
    const concate = { ...weighmentObject, ...inpObj }
    const _closeModal = (id) => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#WeighmentinModal").removeClass("custom-modal-is-visible")
    }
    const allowWeigment = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if (inpObj.tareWeight !== '' && inpObj.weighmentNo !== '') {
            axios.post('https://www.betaenterprise.lynkit.io/api/booking/booking', { ...concate }, { headers: config.headers })
                .then(response => {
                    const error = response.data.error
                    const message = response.data.message
                    if (error === false) {
                        props.closeModal()
                        setInpObj(
                            {
                                tareWeight: "",
                                weighmentNo: ""
                            }
                        )
                        window.$.fn.show_notification({ message: `${message}`, title: 'Success...!!', type: 'confirm', autohide: false }, (result) => {
                            if (result.type === "confirm") {
                            }
                        })
                        window.location.reload();
                    }
                    else {
                        window.$.fn.show_notification({ message: `${message}`, title: 'Alert..!!', type: 'confirm', autohide: false }, (result) => {
                            if (result.type === "confirm") {
                            }
                        })
                    }

                })
                .catch(error => {
                    console.log('err', error);
                })
        }
        else {
            window.$.fn.show_notification({ message: 'Tare weight and Weighment no mandatory.', title: 'Message...!!', type: 'confirm', autohide: false }, (result) => {
                if (result.type === "confirm") {
                }
            })
        }


    }
    return (
        <section id="WeighmentinModal" style={{ width: '48%' }} className="custom-modal">
            <div className="custom-modal-header">
                <h1>Weighment-in</h1>
                <span className="ct-close" onClick={() => _closeModal("WeighmentinModal")}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                <div className="cust-row">
                    <div className='hilElem'>
                        <div className='hilRow'>
                            <div className='hilR'>
                                <span className='hilcustSpan'>Tare Weight :</span>
                                <input name='tareWeight' value={inpObj.tareWeight} type='number' placeholder='Please Enter Tare Weight' onChange={(e) => _handleChange(e)} className='hilcustInpNew' />
                            </div>
                            <div className='hilR'>
                                <span className='hilcustSpan'>Weighment No :</span>
                                <input name='weighmentNo' value={inpObj.weighmentNo} placeholder='Please Enter Weighment No' onChange={(e) => _handleChange(e)} type='text' className='hilcustInpNew' />
                            </div>

                        </div>
                        <div className='hilRow centralise' style={{ background: '#fff' }}>
                            <button className='hilBtns' onClick={allowWeigment}>Submit</button>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )

}

export default Weighmentin;