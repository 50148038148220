import { post } from './../../../services/requests'
import {REGISTER_VEHICLE,SHOW_LOADER, HIDE_LOADER} from './types'
import $ from 'jquery'

export const getRegisteredVehicle = (data) =>{  
    return(dispatch)=>{
        dispatch(showLoader())
        post('vehicle/RegisterVehicle',{...data})
        .then(response=>{
             console.log('add Vehicle response',response);
             const status= response.error
             const message= response.message
             dispatch(getVehicleDataStatus(status))
             dispatch(hideLoader())  
             if(status === true){
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
             }
             else{
                $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                $(".custom-modal").removeClass("custom-modal-is-visible")
                window.location.reload();
             }
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const getVehicleDataStatus = (status) =>{
    return{
        type :REGISTER_VEHICLE,
        payload:{status}
    }
}
export const showLoader = () =>{
    return{
        type : SHOW_LOADER,
    }
}
export const hideLoader = () =>{
    return{
        type : HIDE_LOADER,
    }
}
