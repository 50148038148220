import React, { useEffect, useState } from "react";
import SimpleText from "./SimpleText";
import Units from "./Units";
import Input from "./Input";
import Checkbox from "./Checkbox";
import Radio from "./Radio";
import Date from "./Date";
import CustomSelect from "./Select";
import Number from "./Number";
import PerInterval from "./PerInterval";

const ContractIndex = (props) => {
  const { setRenderItem, AllItems, saveInputFields } = props;
  console.log("contractIndex- elem", props.ele);
  const inputValueHandler = ({ e, index, innerIndex }) => {
    let tempArr = [...AllItems];
    if (tempArr[index].type === "input") {
      if (e.target.name === "input") {
        tempArr[index].value = e.target.value;
      } else if (e.target.name === "drop") {
        tempArr[index].label = e.target.value;
        tempArr[index].unit = AllItems[index].values[e.target.value].label;
        tempArr[index].values.map((item) => {
          return (item.check = false);
        });
        tempArr[index].values[e.target.value].check = true;
        setRenderItem(tempArr);
      }
    } else if (tempArr[index].type === "simpletext") {
      tempArr[index].value = e.target.value;
    } else if (tempArr[index].type === "number") {
      tempArr[index].value = e.target.value;
    } else if (tempArr[index].type === "units") {
      tempArr[index].value = e;
      tempArr[index].values.map((item) => {
        return (item.check = false);
      });
      tempArr[index].values[innerIndex].check = true;
    } else if (tempArr[index].type === "checkbox") {
      tempArr[index].values.map((item) => {
        return (item.check = false);
      });
      tempArr[index].values[innerIndex].check = true;
    } else if (tempArr[index].type === "radio") {
      tempArr[index].values.map((item) => {
        return (item.check = false);
      });
      tempArr[index].values[innerIndex].check = true;
    } else if (tempArr[index].type === "date") {
      tempArr[index].value = e.target.value;
    } else if (tempArr[index].type === "dropdown") {
      tempArr[index].value = e;
      tempArr[index].values.map((item) => {
        return (item.check = false);
      });
      tempArr[index].values[innerIndex].check = true;
    } else if (tempArr[index].type === "perinterval") {
      if (e.target.name === "perinterval") {
        tempArr[index].value = e.target.value;
      } else if (e.target.name === "drop") {
        tempArr[index].label = e.target.value;
        tempArr[index].unit = AllItems[index].values[e.target.value].label;
        tempArr[index].values.map((item) => {
          return (item.check = false);
        });
        tempArr[index].values[e.target.value].check = true;
        setRenderItem(tempArr);
      }
    }
    setRenderItem(tempArr);
  };

  const removeItem = ({ index, innerIndex }) => {
    let dummyArr = [...AllItems];
    if (dummyArr[index].type === "input") {
      if (dummyArr[index].value === "" && dummyArr[index].unit === "") {
        dummyArr.splice(index, 1);
        setRenderItem(dummyArr);
      } else {
        saveInputFields();
        dummyArr.splice(index, 1);
        setRenderItem(dummyArr);
      }
    } else if (dummyArr[index].type === "units") {
      if (dummyArr[index].value === "") {
        dummyArr.splice(index, 1);
        setRenderItem(dummyArr);
      } else {
        saveInputFields();
        dummyArr.splice(index, 1);
        setRenderItem(dummyArr);
      }
    } else if (dummyArr[index].type === "dropdown") {
      if (dummyArr[index].value === "") {
        dummyArr.splice(index, 1);
        setRenderItem(dummyArr);
      } else {
        saveInputFields();
        dummyArr.splice(index, 1);
        setRenderItem(dummyArr);
      }
    } else if (dummyArr[index].type === "checkbox") {
      if (dummyArr[index].values.length > 1) {
        saveInputFields();
        dummyArr[index].values.splice(innerIndex, 1);
        setRenderItem(dummyArr);
      } else {
        saveInputFields();
        dummyArr.splice(index, 1);
        setRenderItem(dummyArr);
      }
    } else if (dummyArr[index].type === "radio") {
      if (dummyArr[index].values.length > 1) {
        saveInputFields();
        dummyArr[index].values.splice(innerIndex, 1);
        setRenderItem(dummyArr);
      } else {
        saveInputFields();
        dummyArr.splice(index, 1);
        setRenderItem(dummyArr);
      }
    } else if (dummyArr[index].type === "perinterval") {
      if (dummyArr[index].value === "" && dummyArr[index].unit === "") {
        dummyArr.splice(index, 1);
        setRenderItem(dummyArr);
      } else {
        saveInputFields();
        dummyArr.splice(index, 1);
        setRenderItem(dummyArr);
      }
    } else {
      dummyArr.splice(index, 1);
      setRenderItem(dummyArr);
    }
  };

  return (
    <>
      {props.ele.type === "simpletext" && (
        <SimpleText
          {...props}
          inputValueHandler={inputValueHandler}
          removeItem={removeItem}
        />
      )}
      {props.ele.type === "units" && (
        <Units
          {...props}
          inputValueHandler={inputValueHandler}
          removeItem={removeItem}
        />
      )}
      {props.ele.type === "checkbox" && (
        <Checkbox
          {...props}
          inputValueHandler={inputValueHandler}
          removeItem={removeItem}
        />
      )}
      {props.ele.type === "radio" && (
        <Radio
          {...props}
          inputValueHandler={inputValueHandler}
          removeItem={removeItem}
        />
      )}
      {props.ele.type === "date" && (
        <Date
          {...props}
          inputValueHandler={inputValueHandler}
          removeItem={removeItem}
        />
      )}
      {props.ele.type === "dropdown" && (
        <CustomSelect
          {...props}
          inputValueHandler={inputValueHandler}
          removeItem={removeItem}
        />
      )}
      {props.ele.type === "time" && (
        <Date
          {...props}
          inputValueHandler={inputValueHandler}
          removeItem={removeItem}
        />
      )}
      {props.ele.type === "input" && (
        <Input
          {...props}
          inputValueHandler={inputValueHandler}
          removeItem={removeItem}
        />
      )}
      {props.ele.type === "number" && (
        <Number
          {...props}
          inputValueHandler={inputValueHandler}
          removeItem={removeItem}
        />
      )}

      {props.ele.type === "perinterval" && (
        <PerInterval
          {...props}
          inputValueHandler={inputValueHandler}
          removeItem={removeItem}
        />
      )}
    </>
  );
};

export default ContractIndex;
