import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import history from './../../services/history';
import TransLogo from './../../static/images/credlynk_logo.png'
import moment from 'moment';
import { viewLrDetail } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import AnalysisModal from './../Dashboard/Modal/Analysis'
import TrackingModal from './../Dashboard/Modal/Tracking'
import analytics from './../../static/images/icon/buttons/Analytics.svg'
import tracking from './../../static/images/icon/buttons/Tracking.svg'
import GeneratePod from './../Dashboard/Modal/GeneratePod'

const PodDetail = (props) => {
    const dispatch = useDispatch()
    const status = props.location.state.show
    console.log('sss', status)
    const lrId = props.location.state.id
    const lrData = useSelector((state) => state.viewLrDetailRed.lrData)
    const loader = useSelector((state) => state.viewLrDetailRed.loading)
    const [generateObj, setGenerateObj] = useState({
        ind: '',
        truckAllocationId: "",
        shipmentGID: "",

    })
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const storeInd = (item) => {
        const tempObj = { ...generateObj }
        tempObj.ind = item._id
        tempObj.truckAllocationId = item.TruckAllocationId
        tempObj.shipmentGID = item.ShipmentGID
        setGenerateObj(tempObj)
    }
    const _goBack = () => {
        history.goBack()
    }
    useEffect(() => {
        dispatch(viewLrDetail(lrId))
    }, [dispatch]);

    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-4 flex-algn-cent'>
                    <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                        <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                    </button>
                    <h1 className="title">
                        Documents
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color"></h1>
                </div>
                <div className='cust-row'>
                    <div className='cust-col-8 flex-algn-cent'>

                    </div>
                    <div className='cust-col-2 flex-jc-end' >
                        <div className='cust-col-2 flex-jc-end' >
                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 less-size' onClick={() => { _handleClick('trackingModal') }}><img src={tracking} alt="none" />Tracking</button>
                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 less-size' onClick={() => { _handleClick('analysisModal') }}><img src={analytics} alt="none" />Analytics</button>
                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 less-size'>Download</button>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div className="cust-row">
                    <div className="cust-col-10">
                        <div className="table-responsive">
                            {lrData !== undefined ?
                                <table className='cctbl no_brdr'>
                                    <tbody>
                                        <tr>
                                            <div className='bookingDetailDiv'>
                                                <div className='form-container'>
                                                    <div class="custom-collapse autoheight">
                                                        <div class="customBdy pdm5">
                                                            <div className='tbl_div'>
                                                                <div className='logo_sec_1'>
                                                                    <img src={TransLogo} alt="" />
                                                                </div>
                                                                <div className='content_sec_1'>
                                                                    <p className='font-bold font-xl'>{lrData.Transporter ? lrData.Transporter.Name : '-'}<span className='font-size'></span></p>
                                                                    <p className='font-lg'>{lrData.Transporter ? lrData.Transporter.address : '-'}</p>
                                                                    <p>GSTIN -{lrData.Transporter ? lrData.Transporter.GSTIN : '-'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="custom-collapse autoheight">
                                                        <div class="customHead">
                                                            Basic Details
                                                        </div>
                                                        <div class="customBdy">
                                                            <div className='dv_flex pd5 brd_btm'>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>LR No.</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>{lrData.LrNo}</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Shipment GID</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>{lrData.ShipmentGID}</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Contract Type</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>{lrData.ContractType}</span>
                                                                </div>
                                                            </div>
                                                            <div className='dv_flex pd5 brd_btm'>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>LR Date</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>{moment(lrData.LrDate).format('DD/MM/YYYY')}</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Delivery Type</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>{lrData.DeliveryType}</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Transportation Mode</span>
                                                                </div>
                                                                <div className='wdt20'>
                                                                    <span className='font-bold grey_color'>{lrData.TransportationMode}</span>
                                                                </div>
                                                            </div>
                                                            <div className='dv_flex pd5 brd_btm'>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Consignor</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>{lrData.Consignor && lrData.Consignor.PlantName ? lrData.Consignor.PlantName : '-'}</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Pickup</span>
                                                                </div>
                                                                <div className='wdt20'>
                                                                    <span className='font-bold grey_color'>{lrData.From && lrData.From.ConsignorAddress ? lrData.From.ConsignorAddress : '-'}</span>
                                                                </div>
                                                            </div>
                                                            <div className='dv_flex pd5 brd_btm'>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Consignee</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>{lrData.To && lrData.To.ConsigneeName ? lrData.To.ConsigneeName : '-'}</span>
                                                                </div>

                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Dropoff</span>
                                                                </div>

                                                                <div className='wdt20'>
                                                                    <span className='font-bold grey_color'>{lrData.To && lrData.To.Address ? lrData.To.Address : '-'}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="custom-collapse autoheight">
                                                        <div class="customHead">
                                                            Goods Details
                                                            <div class="doc_attachment" data-toggle="modal" data-target="#documentModal">
                                                                <i class="fa fa-paperclip"></i> View Documents
                                                            </div>
                                                        </div>
                                                        <div class="customBdy">
                                                            <div className='dv_flex pd5 brd_btm'>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Eway Bill Number</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>NA</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Validity From</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>NA</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Validity Upto</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>NA</span>
                                                                </div>
                                                            </div>
                                                            {lrData.InvoiceDetails ? lrData.InvoiceDetails.map((a, b) => {
                                                                return (
                                                                    <div key={b} className='dv_flex pd5 brd_btm'>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Sales Category</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{a ? a.SaleCategory : '-'}</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Sub Category</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{a ? a.ProductSubGroup : '-'}</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Total Coils</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{lrData.CoilDetails.length}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) : ''}

                                                            <div >
                                                                <div className='dv_flex pd5 brd_btm'>
                                                                    <div className='wdt20 brd_rht'>
                                                                        <span className='font-bold black_color'>Invoice Number</span>
                                                                    </div>
                                                                    <div className='wdt20 brd_rht'>
                                                                        <span className='font-bold grey_color'>{lrData.InvoiceDetails && lrData.InvoiceDetails[0].InvoiceNumber ? lrData.InvoiceDetails[0].InvoiceNumber : '-'}</span>
                                                                    </div>
                                                                    <div className='wdt20 brd_rht'>
                                                                        <span className='font-bold black_color'>Invoice Date</span>
                                                                    </div>
                                                                    <div className='wdt20 brd_rht'>
                                                                        <span className='font-bold grey_color'>{lrData.InvoiceDetails && lrData.InvoiceDetails[0].InvoiceDate ? lrData.InvoiceDetails[0].InvoiceDate : '-'}</span>
                                                                    </div>
                                                                    <div className='wdt20 brd_rht'>
                                                                        <span className='font-bold black_color'>Invoice Status</span>
                                                                    </div>
                                                                    <div className='wdt20 brd_rht'>
                                                                        <span className='font-bold grey_color'>{lrData.InvoiceDetails && lrData.InvoiceDetails[0].Invoicestatus ? lrData.InvoiceDetails[0].Invoicestatus : '-'}</span>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className='dv_flex pd5 brd_btm'>
                                                                        <div className='wdt7 brd_rht'>
                                                                            <span className='font-bold black_color'>S.no</span>
                                                                        </div>
                                                                        <div className='wdt50 brd_rht'>
                                                                            <span className='font-bold black_color'>Lot Number </span>
                                                                        </div>
                                                                        <div className='wdt50 brd_rht'>
                                                                            <span className='font-bold black_color'>Lot Quantity</span>
                                                                        </div>
                                                                        <div className='wdt50 brd_rht'>
                                                                            <span className='font-bold black_color'>Material Type </span>
                                                                        </div>
                                                                        <div className='wdt50 brd_rht'>
                                                                            <span className='font-bold black_color'>Material Quantity</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {lrData.CoilDetails ? lrData.CoilDetails.map((a, b) => {
                                                                    return (
                                                                        <div key={b}>
                                                                            <div className='dv_flex pd5 brd_btm'>
                                                                                <div className='wdt7 brd_rht'>
                                                                                    <span className='font-bold black_color'>{b + 1}</span>
                                                                                </div>
                                                                                <div className='wdt50 brd_rht'>
                                                                                    <span className='font-bold grey_color'>{a.lot_Number}</span>
                                                                                </div>
                                                                                <div className='wdt50 brd_rht'>
                                                                                    <span className='font-bold grey_color'>{a.lot_Qty}</span>
                                                                                </div>
                                                                                <div className='wdt50 brd_rht'>
                                                                                    <span className='font-bold grey_color'>{a.MaterialType}</span>
                                                                                </div>
                                                                                <div className='wdt50 brd_rht'>
                                                                                    <span className='font-bold grey_color'>{a.MaterialQuantity}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }) : ''}
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div class="custom-collapse autoheight">
                                                        <div class="customHead">
                                                            Transportation Details
                                                        </div>
                                                        <div class="customBdy">
                                                            <div className='dv_flex pd5 brd_btm'>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Vehicle Type</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>{lrData.VehicleType}</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Vehicle Number</span>

                                                                </div>
                                                                <div className='wdt20 brd_rht '>
                                                                    <span className='font-bold grey_color'>{lrData.VehicleNumber}</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold black_color'>Gate in Time</span>
                                                                </div>
                                                                <div className='wdt20 brd_rht'>
                                                                    <span className='font-bold grey_color'>{moment(lrData.GateInTime).format('h:mm:ss a')}</span>
                                                                </div>
                                                            </div>
                                                            <div className='dv_flex pd5 brd_btm'>
                                                                <div className='wdt50 brd_rht'>
                                                                    <span className='font-bold black_color'>Loaded On</span>
                                                                </div>
                                                                <div className='wdt50 brd_rht'>
                                                                    <span className='font-bold grey_color'>{moment(lrData.LoadedDateTime).format('DD/MM/YYYY')}, {moment(lrData.LoadedDateTime).format('h:mm:ss a')}</span>
                                                                </div>

                                                                <div className='wdt50 brd_rht'>
                                                                    <span className='font-bold black_color'>Fixed Tracker Id</span>
                                                                </div>
                                                                <div className='wdt50 brd_rht'>
                                                                    <span className='font-bold grey_color'>{lrData.FixedTrackerID}</span>
                                                                </div>
                                                            </div>

                                                            {lrData.DriverDetails ? lrData.DriverDetails.map((a, b) => {
                                                                return (
                                                                    <div key={b} className='dv_flex pd5 brd_btm'>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Driver Name</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{a && a.name ? a.name : ''}</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Driver Mobile</span>
                                                                        </div>
                                                                        <div className='wdt20'>
                                                                            <span className='font-bold grey_color'>{a && a.mobileNo ? a.mobileNo : ''}</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>DL Number</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{a && a.dlNumber ? a.dlNumber : ''}</span>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            }) : ''}


                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </tr>
                                    </tbody>
                                </table>
                                : <span className='noDataClass'>No Data Found</span>}
                        </div>
                        {status !== true ?
                            <div className='lrBtnDetailConfirm'>
                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 view-detail' onClick={() => { _handleClick('GeneratePoDModal'); storeInd(lrData) }}>Confirm POD</button>
                            </div>
                            : ''
                        }
                    </div>
                </div>
            </div>
            <AnalysisModal closeModal={_closeModal} />
            <TrackingModal closeModal={_closeModal} />
            <GeneratePod closeModal={_closeModal} generateObj={generateObj} />
        </div>

    )
}
export default PodDetail;