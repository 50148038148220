import { STAFF_DATA } from "../actions/types";

const initialState={
    staffArray: []
} 

const inputReducers =(state=initialState,{type,payload})=>{
    switch(type){
        case STAFF_DATA : return{
            ...state,
        }
        default: return state;  
    }
}
export default inputReducers