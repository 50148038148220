import {GET_SINGLE_USER, HIDE_SINGLE_USER} from  "../actions/types";

const initialState={
    userData: [],
    loading:true
} 

const getSingleUserReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_SINGLE_USER : return{
            ...state,
            userData : payload
        }
        case HIDE_SINGLE_USER : return{
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default getSingleUserReducer