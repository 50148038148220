import React, { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import $ from "jquery";
import ContractIndex from "./ContractElement/Index";
import { Draggable, Droppable } from "react-drag-and-drop";
import history from "../../services/history";
import "../../static/css/addContract.css";
import axios from "axios";
import { selectInit } from "./../../services/functions";
import { getCategories } from "../../state/redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import GenericSelect from "../Utils/GenericSelect";

const addClauseAsDefault = {
  clause: false,
  charges: false,
  penalty: false,
};

const ContractClauses = (props) => {
  const dispatch = useDispatch();
  const indexx = props;
  const [btnClass, setBtnClass] = useState("field");
  const [checkBoxState, setCheckState] = useState(addClauseAsDefault);
  const [renderItem, setRenderItem] = useState([]);
  const [variable, setVariable] = useState("A");
  const [show, setShow] = useState(false);
  const [getIndex, setIndex] = useState();
  const [innerTempArr, setInnerTempArr] = useState([]);
  const [currentCode, setCurrentCode] = useState(2);
  const [warning, setWarning] = useState("");
  const [disable, setDisable] = useState(true);
  const [dataObject, setDataObject] = useState({
    Category: "",
    InspectionAPP: false,
    FreightContract: false,
    ClauseName: "",
    Clause: [],
    ContractId: "",
    Status: "",
  });
  const [previewShow, setPreview] = useState(false);
  const [previewArr, setPreviewArr] = useState([]);
  const [model, setModel] = useState(false);
  const [clauseModel, setclauseModel] = useState(false);
  const [categoryNameState, setCategoryName] = useState({ CategoryName: "" });

  const categoriesList = useSelector((state) => state.categoriesRed.categories);

  const expandElement = () => {
    if (
      $(".addContractDiv2").hasClass("hide-me") &&
      $(".flex-mid").hasClass("open")
    ) {
      $(".addContractDiv2").removeClass("hide-me");
      $(".addContractDiv2").removeClass("hidden");
      $(".addContractDiv2").addClass("open");

      $(".flex-mid").removeClass("open");
      $(".flex-mid").addClass("hide");
    } else {
      $(".addContractDiv2").addClass("hide-me");
      $(".addContractDiv2").addClass("hide-me");
      $(".flex-mid").removeClass("hide");
      $(".flex-mid").addClass("open");
    }
  };

  const onDrop = (data) => {
    if (!data.simpletext) {
      increaseChar();
    }
    const key = Object.keys(data).find((x) => data[x]);
    setRenderItem([...renderItem, dropData(key, variable)]);
  };

  const increaseChar = () => {
    setCurrentCode(currentCode + 1);
    var character = String.fromCharCode(64 + currentCode);
    setVariable(character);
  };
  const toggleContBtn = (e, item) => {
    if (item === "field") {
      setBtnClass("field");
    } else {
      setBtnClass("custom");
    }
  };
  const shareIndex = (index, Values) => {
    setInnerTempArr(Values);
    setIndex(index);

    let tempArr = [...renderItem];
    if (tempArr[index].show === true) {
      setShow(true);
    } else {
      // setShow(false)
    }
    if (tempArr[index].dropDownShow === true) {
      setShow(true);
    } else {
      // setShow(false)
    }
  };
  const edit = (e, dataIndex) => {
    const { name, value, checked } = e.target;
    const result = Array.isArray(innerTempArr);
    if (result === true) {
      let editArray = [...innerTempArr];
      console.log(editArray, dataIndex, checked);
      if (name === "label") {
        editArray[dataIndex].label = value;
      } else if (name === "check") {
        editArray[dataIndex].check = checked;
      } else if (name === "checkbox") {
        // if (innerTempArr[dataIndex].check === false) {
        //     editArray[dataIndex].check = true
        // }
        // else {
        //     editArray[dataIndex].check = false
        // }
      } else if (name === "radioCheck") {
        // if (innerTempArr[dataIndex].check === false) {
        //     editArray[dataIndex].check = true
        // }
        // else {
        //     editArray[dataIndex].check = false
        // }
      } else if (name === "perInterval") {
        if (innerTempArr[dataIndex].check === false) {
          editArray[dataIndex].check = true;
        } else {
          editArray[dataIndex].check = false;
        }
      } else if (name === "dropLabel") {
        editArray[dataIndex].dropLabel = value;
      }
      setInnerTempArr(editArray);
    } else if (result === false) {
      let editArray = innerTempArr;
      if (name === "placeholder") {
        editArray.placeholder = value;
      }
      setInnerTempArr(editArray);
    }
  };
  const increaseElems = () => {
    let increaseArray = [...innerTempArr];
    increaseArray.push({ label: "", check: false });
    setInnerTempArr(increaseArray);
  };
  const subElems = (dataIndex) => {
    let decreaseArray = [...innerTempArr];
    decreaseArray.splice(dataIndex, 1);
    setInnerTempArr(decreaseArray);
  };
  const save = () => {
    let saveArray = [...renderItem];
    saveArray[getIndex].show = false;
    saveArray[getIndex].values = innerTempArr;
    // saveArray[getIndex].unit = unit
    setShow(false);
    setRenderItem(saveArray);
  };
  const saveInputFields = () => {
    let saveArray = [...renderItem];
    setShow(false);
    setRenderItem(saveArray);
  };
  const _moveTo = () => {
    let dummy = [...renderItem];
    dummy.forEach((object) => {
      delete object["description"];
      delete object["placeholder"];
      delete object["dropDownShow"];
      delete object["label"];
      delete object["show"];
      // delete object['values'];
      delete object["type"];
    });
    dataObject.Clause.push(...dummy);

    // console.log(dataObject);
    if (checkBoxState.clause) {
      const token = localStorage.getItem("secretkey");
      const config = {
        headers: { Authorization: token },
      };
      axios
        .post(
          "https://www.betaenterprise.lynkit.io/api/contract/addclause",
          { ...dataObject },
          { headers: config.headers }
        )
        .then((response) => {
          const status = response.data.error;
          const message = response.data.message;
          if (status === false) {
            window.$.fn.show_notification(
              {
                message: `${message}`,
                title: "Success!!",
                autohide: true,
                confirmVal: "Ok",
                showInput: false,
              },
              (result) => {
                if (result.type === "confirm") {
                }
              }
            );

            history.push("/dashboard/Contracts");
            // history.push({
            //     state: { index: index },
            //     pathname: '/dashboard/Contracts/details'
            // })
          } else {
            window.$.fn.show_notification(
              {
                message: `${message}`,
                title: "Alert!!",
                autohide: true,
                confirmVal: "Ok",
                showInput: false,
              },
              (result) => {
                if (result.type === "confirm") {
                }
              }
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else if (checkBoxState.charges || checkBoxState.penalty) {
      history.push({
        pathname: "/dashboard/Contracts/Create-Formula",
        state: dataObject,
      });
    }
    setclauseModel(!clauseModel);
  };
  const preview = () => {
    let previewArray = [...renderItem];
    setPreviewArr(previewArray);
    setPreview(!previewShow);
  };
  const _handleOnChange = (e) => {
    let { name, value, checked, type } = e.target;
    if (type === "checkbox") value = checked;
    setDataObject({ ...dataObject, [name]: value });
  };
  const handleSelectChange = (selected, actionMeta) => {
    const { name } = actionMeta;
    let { label, value } = selected;
    if (name === "InspectionAppChecks") value = selected;
    // console.log(name, label, value);
    if (name === "Category" && value === "addCategory") {
      setModel(!model);
      return;
    }
    setDataObject({ ...dataObject, [name]: value });
  };

  const setCategoryNameFun = (e) => {
    setCategoryName({ ...categoryNameState, [e.target.name]: e.target.value });
  };

  const saveCategory = () => {
    const token = localStorage.getItem("secretkey");
    const config = {
      headers: { Authorization: token },
    };
    axios
      .post(
        "https://www.betaenterprise.lynkit.io/api/contract/addcategory",
        { ...categoryNameState },
        { headers: config.headers }
      )
      .then((response) => {
        const error = response.data.error;
        const message = response.data.message;
        if (error === false) {
          setModel(!model);
          dispatch(getCategories());
        } else {
          window.$.fn.show_notification(
            {
              message: `${message}`,
              title: "Alert!!",
              autohide: true,
              confirmVal: "Ok",
              showInput: false,
            },
            (result) => {
              if (result.type === "confirm") {
              }
            }
          );
        }
      })
      .catch((error) => {
        // dispatch(showError(err))
      });
  };
  useEffect(() => {
    dataObject.ContractId = indexx.id;
    let checkClause = { ...dataObject };
    let dummyObject = renderItem.map((item) => {
      return item;
    });
    let valueArr = dummyObject.map((item) => {
      return item.value === "";
    });
    let unitArr = dummyObject.map((item) => {
      return item.unit === "";
    });

    if (checkClause.Category === "" && checkClause.ClauseName == "") {
      setWarning("Category and Clause name required..");
    } else if (checkClause.Category === "" && checkClause.ClauseName !== "") {
      setWarning("Category field required..");
    } else if (checkClause.Category !== "" && checkClause.ClauseName === "") {
      setWarning("Clause Name required");
    } else {
      if (valueArr.includes(true) || unitArr.includes(true)) {
        setDisable(true);
        setWarning("Drop Fields cannot be empty..");
      } else {
        setWarning("run");
        setDisable(false);
      }
    }
  });
  useEffect(() => {
    // setTimeout(() => {
    //   selectInit(_searchDrop);
    // }, 100);
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    console.log(dataObject);
  }, [dataObject]);

  const checkBoxCheck = (e) => {
    // let local = { ...checkBoxState };
    const { name, checked } = e.target;
    setCheckState({ ...addClauseAsDefault, [name]: checked });
    // console.log('local', local, name, checked);
  };

  const categoryOptions = categoriesList.map((item, index) => ({
    label: item.CategoryName,
    value: item.CategoryName,
  }));
  console.log({ renderItem });
  return (
    <>
      <div className="ContractClausesElem">
        <div className="addContract2-1">
          <span>Add Contract Clauses</span>
          <i
            onClick={expandElement}
            className="fa fa-minus font-xl subIconColor"
          ></i>
        </div>
        <div className="contractParent">
          <div className="contractData">
            <div className="contract__data">
              <div className="contract_form_input_wrapper">
                <span className="contractSpan">Select Category :</span>
                <div className="contract_form_input_select">
                  <GenericSelect
                    options={[
                      { label: "select", value: "", isDisabled: true },
                      { label: "Add Category", value: "addCategory" },
                      ...categoryOptions,
                    ]}
                    onChange={handleSelectChange}
                    name="Category"
                  />

                  {model && (
                    <div className="addCtegoryModal">
                      <div
                        className="closeContModal"
                        onClick={() => setModel(!model)}
                      >
                        <i id="headingCross" className="fa fa-times"></i>
                      </div>
                      <div className="addCategInner">
                        <span className="categoryModalSpan">
                          Please Enter your Category:
                        </span>
                        <input
                          type="text"
                          name="CategoryName"
                          value={categoryNameState.CategoryName}
                          className="categoryModalInput"
                          onChange={(e) => setCategoryNameFun(e)}
                          placeholder="Enter Category name."
                        />
                        <button
                          className="categoryModelBtn"
                          onClick={saveCategory}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="contract_form_input_wrapper">
                <span className="contractSpan">Clause Name :</span>
                <input
                  name="ClauseName"
                  value={dataObject.ClauseName}
                  onChange={(e) => _handleOnChange(e)}
                  type="text"
                  placeholder="Enter Clause Name"
                />
              </div>
              <div className="contract_form_input_wrapper">
                <span className="contractSpan ">Clause Required for?</span>
                <div className="contract_form_checkbox_wrapper">
                  <input
                    type="checkbox"
                    name="InspectionAPP"
                    value={dataObject.InspectionAPP}
                    onChange={(e) => _handleOnChange(e)}
                  />
                  <span className="contractSpanLight">Inspection App</span>
                </div>
                <div className="contract_form_checkbox_wrapper">
                  <input
                    type="checkbox"
                    name="FreightContract"
                    value={dataObject.FreightContract}
                    onChange={(e) => _handleOnChange(e)}
                  />
                  <span className="contractSpanLight">Freight Contracts</span>
                </div>
              </div>
              <div className="contract_form_input_wrapper">
                <span className="contractSpan">Status :</span>
                <div className="contract_form_input_select">
                  <GenericSelect
                    options={[
                      { label: "select", value: "", isDisabled: true },
                      { label: "Active", value: "active" },
                      { label: "In-active", value: "inActive" },
                    ]}
                    onChange={handleSelectChange}
                    name="Status"
                  />
                </div>
              </div>
              {dataObject.InspectionAPP ? (
                <div className="contract_form_input_wrapper">
                  <span className="contractSpan">Checks :</span>
                  <div className="contract_form_input_select">
                    <GenericSelect
                      isMulti
                      name="InspectionAppChecks"
                      className="InspectionAppChecks"
                      options={[
                        { label: "select", value: "", isDisabled: true },
                        {
                          label: "Logistics Gate Out",
                          value: "Logistics Gate Out",
                        },
                        {
                          label: "Material Gate Out",
                          value: "Material Gate Out",
                        },
                        { label: "Gate In", value: "Gate In" },
                      ]}
                      onChange={handleSelectChange}
                    />
                  </div>
                </div>
              ) : null}
            </div>

            <div className="contractFieldsElem incHgt">
              <div className="cont30">
                {/* <div className='contIcon'>
                                    <i className="fa fa-pencil-square-o editContIc"></i>
                                    <i className="fa fa-plus addContIc" ></i>
                                </div> */}
              </div>

              <div className="contDropZone">
                {previewShow === false ? (
                  <Droppable
                    className="dropZone"
                    types={[
                      "simpletext",
                      "units",
                      "checkbox",
                      "radio",
                      "date",
                      "dropdown",
                      "time",
                      "input",
                      "number",
                      "perinterval",
                      "vehicletype",
                      "vehiclecategory",
                    ]}
                    onDrop={(data) => onDrop(data)}
                  >
                    {renderItem.length !== 0 ? (
                      renderItem &&
                      renderItem.length > 0 &&
                      renderItem.map((ele, index) => {
                        return (
                          <ContractIndex
                            edit={true}
                            ele={ele}
                            key={index}
                            mainIndex={index}
                            length={renderItem.length}
                            AllItems={renderItem}
                            setRenderItem={setRenderItem}
                            shareIndex={shareIndex}
                            save={save}
                            saveInputFields={saveInputFields}
                          />
                        );
                      })
                    ) : (
                      <div
                        className="alignCenterDrop"
                        style={{ width: "100%", display: "flex" }}
                      >
                        <span className="alternateText">Drop here...</span>
                      </div>
                    )}
                  </Droppable>
                ) : (
                  <div className="previewDiv">
                    {previewArr.map((item, index) => {
                      return (
                        <span
                          key={index}
                          className={
                            item.type === "input" ||
                            item.type === "perinterval" ||
                            item.type === "number" ||
                            item.type === "checkbox" ||
                            item.type === "units" ||
                            item.type === "date" ||
                            item.type === "dropdown"
                              ? "colorOrangePreview"
                              : "normalTextSpan"
                          }
                        >
                          {item.value} {item.unit}
                          {(item.values !== undefined &&
                            item.type === "checkbox") ||
                          item.type === "radio"
                            ? item.values.map((x, y) => {
                                return (
                                  <div className="checkRadio">
                                    <input
                                      type="checkbox"
                                      checked={x.check === true ? true : false}
                                    />
                                    <span className="checkContainerInner">
                                      {x.label}
                                    </span>
                                  </div>
                                );
                              })
                            : ""}
                          {/* {item.values !== undefined && item.type === 'checkbox' ? item.values.map((x,y)=>{return(x.label)}) : ''} */}
                        </span>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="cont30">
                <div className="contIcon wdAuto ">
                  {previewShow === false ? (
                    <button
                      disabled={renderItem.length === 0 ? true : false}
                      className="contBtn"
                      onClick={() => preview()}
                    >
                      Preview<i className="fa fa-eye-slash slashEye"></i>
                    </button>
                  ) : (
                    <button
                      disabled={renderItem.length === 0 ? true : false}
                      className="contBtn"
                      onClick={() => preview()}
                    >
                      Hide <i className="fa fa-eye slashEye"></i>
                    </button>
                  )}
                  <div className="CalBox">
                    <button
                      disabled={
                        warning === "run" && disable === false ? false : true
                      }
                      className="fxBtn"
                      onClick={() => setclauseModel(!clauseModel)}
                    >
                      Add Clause
                    </button>
                    {warning !== "run" ? (
                      <div className="newTitleBox">
                        <span>{warning}</span>
                      </div>
                    ) : (
                      ""
                    )}

                    {clauseModel && (
                      <div className="addCtegoryModal addCtegoryModalHgt">
                        <div
                          className="closeContModal"
                          onClick={() => setclauseModel(!clauseModel)}
                        >
                          <i id="headingCross" className="fa fa-times"></i>
                        </div>
                        <div className="addCategInner">
                          <div
                            className="clauseAdd"
                            style={{ background: "#fff" }}
                          >
                            <span
                              className="typeText"
                              style={{ color: "#727376" }}
                            >
                              Add Clause as -:
                            </span>
                          </div>
                          <div className="clauseAdd">
                            <input
                              onChange={(e) => checkBoxCheck(e)}
                              checked={checkBoxState.clause}
                              name="clause"
                              value={checkBoxState.clause}
                              type="checkbox"
                            />
                            <span className="typeText">Only Clause</span>
                          </div>
                          <div className="clauseAdd">
                            <input
                              onChange={(e) => checkBoxCheck(e)}
                              checked={checkBoxState.charges}
                              name="charges"
                              value={checkBoxState.charges}
                              type="checkbox"
                            />
                            <span className="typeText">As Charges</span>
                          </div>
                          <div className="clauseAdd">
                            <input
                              onChange={(e) => checkBoxCheck(e)}
                              checked={checkBoxState.penalty}
                              name="penalty"
                              value={checkBoxState.penalty}
                              type="checkbox"
                            />
                            <span className="typeText">As Penalty</span>
                          </div>
                          <button
                            className="categoryModelBtn"
                            onClick={() => _moveTo()}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contractInput">
            <div className="draggableElem">
              <div className="draggableHead">
                <div
                  className={
                    btnClass === "field" ? "cont50Active" : "cont50Inactive"
                  }
                  onClick={(e) => toggleContBtn(e, "field")}
                >
                  <span> Fields </span>
                </div>
              </div>
              {btnClass === "field" && show === false ? (
                <div className="contCol">
                  {fieldDraggables.map((field, index) => {
                    return (
                      <Draggable
                        type={field.type}
                        data={field.type}
                        key={index}
                      >
                        <div className="dragItem">
                          <span>{field.value}</span>
                        </div>
                      </Draggable>
                    );
                  })}
                </div>
              ) : renderItem[getIndex].type &&
                renderItem[getIndex].type === "units" &&
                renderItem[getIndex].show === true ? (
                <div className="customDiv flexColumn overflow">
                  <div className="customRow flexRow">
                    <div className="cust10 flexCen">
                      <span className="contractLabell">
                        <b></b>
                      </span>
                    </div>
                    <div className="cust70 flexCen">
                      <span className="contractLabell">
                        <b>Label</b>
                      </span>
                    </div>
                    <div className="cust5 flexCen"></div>
                  </div>
                  <div style={{ height: "auto", overflow: "auto" }}>
                    {innerTempArr.map((data, dataIndex) => {
                      return (
                        <div className="customRow flexRow" key={dataIndex}>
                          <div className="cust10 ">
                            <input
                              type="checkbox"
                              name="check"
                              value={data.check}
                              checked={data.check}
                              onChange={(e) => edit(e, dataIndex)}
                            />
                          </div>
                          <div className="cust70 borderMe">
                            <input
                              className="contractInputInner"
                              name="label"
                              type="text"
                              value={data.label}
                              onChange={(e) => edit(e, dataIndex)}
                            />
                          </div>
                          <div className="cust5">
                            {innerTempArr.length !== 1 ? (
                              <i
                                className="fa fa-minus constSub"
                                onClick={() => subElems(dataIndex)}
                              ></i>
                            ) : (
                              ""
                            )}
                            {innerTempArr.length - 1 === dataIndex ? (
                              <i
                                className="fa fa-plus contAdd"
                                onClick={() => increaseElems(dataIndex)}
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="customRow">
                    <button className="custContBtn" onClick={save}>
                      Save
                    </button>
                  </div>
                </div>
              ) : (renderItem[getIndex].type &&
                  renderItem[getIndex].type === "input" &&
                  renderItem[getIndex].show === true) ||
                renderItem[getIndex].dropDownShow === false ? (
                <div className="customDiv flexColumn overflow assignHgt">
                  <div className="customRow flexRow alignEnd">
                    <span className="contractLabell">
                      <b>Placeholder</b>
                    </span>
                  </div>
                  <div className="customRow flexRow borderMe">
                    <input
                      className="contractInputInner"
                      name="placeholder"
                      placeholder={innerTempArr.placeholder}
                      type="text"
                      onChange={(e) => edit(e)}
                    />
                  </div>
                  <div className="customRow">
                    <button className="custContBtn" onClick={saveInputFields}>
                      Save
                    </button>
                  </div>
                </div>
              ) : (renderItem[getIndex].type &&
                  renderItem[getIndex].type === "input" &&
                  renderItem[getIndex].show === false) ||
                renderItem[getIndex].dropDownShow === true ? (
                <div className="customDiv flexColumn ">
                  <div
                    className="customRow flexRow"
                    style={{ marginBottom: "0px" }}
                  >
                    <div className="cust10 flexCen">
                      <span className="contractLabell">
                        <b></b>
                      </span>
                    </div>
                    <div className="cust70 flexCen">
                      <span className="contractLabell">
                        <b>Label</b>
                      </span>
                    </div>
                    <div className="cust5 flexCen"></div>
                  </div>
                  <div style={{ height: "auto", overflow: "auto" }}>
                    {innerTempArr.map((data, dataIndex) => {
                      return (
                        <div className="customRow flexRow " key={dataIndex}>
                          <div className="cust10 ">
                            <input
                              type="checkbox"
                              name="check"
                              value={data.check}
                              checked={data.check}
                              onChange={(e) => edit(e, dataIndex)}
                            />
                          </div>
                          <div className="cust70 borderMe">
                            <input
                              className="contractInputInner"
                              name="label"
                              type="text"
                              value={data.label}
                              onChange={(e) => edit(e, dataIndex)}
                            />
                          </div>
                          <div className="cust5">
                            {innerTempArr.length !== 1 ? (
                              <i
                                className="fa fa-minus constSub"
                                onClick={() => subElems(dataIndex)}
                              ></i>
                            ) : (
                              ""
                            )}
                            {innerTempArr.length - 1 === dataIndex ? (
                              <i
                                className="fa fa-plus contAdd"
                                onClick={() => increaseElems(dataIndex)}
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="customRow">
                    <button className="custContBtn" onClick={save}>
                      Save
                    </button>
                  </div>
                </div>
              ) : renderItem[getIndex].type &&
                renderItem[getIndex].type === "dropdown" ? (
                <div className="customDiv flexColumn overflow">
                  <div className="customRow flexRow">
                    <div className="cust10 flexCen">
                      <span className="contractLabell">
                        <b></b>
                      </span>
                    </div>
                    <div className="cust70 flexCen">
                      <span className="contractLabell">
                        <b>Label</b>
                      </span>
                    </div>
                    <div className="cust5 flexCen"></div>
                  </div>
                  <div style={{ height: "auto", overflow: "auto" }}>
                    {innerTempArr.map((data, dataIndex) => {
                      return (
                        <div className="customRow flexRow" key={dataIndex}>
                          <div className="cust10 ">
                            <input
                              type="checkbox"
                              name="check"
                              value={data.check}
                              checked={data.check}
                              onChange={(e) => edit(e, dataIndex)}
                            />
                          </div>
                          <div className="cust70 borderMe">
                            <input
                              className="contractInputInner"
                              name="label"
                              type="text"
                              value={data.label}
                              onChange={(e) => edit(e, dataIndex)}
                            />
                          </div>
                          <div className="cust5">
                            {innerTempArr.length !== 1 ? (
                              <i
                                className="fa fa-minus constSub"
                                onClick={() => subElems(dataIndex)}
                              ></i>
                            ) : (
                              ""
                            )}
                            {innerTempArr.length - 1 === dataIndex ? (
                              <i
                                className="fa fa-plus contAdd"
                                onClick={() => increaseElems(dataIndex)}
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="customRow">
                    <button className="custContBtn" onClick={save}>
                      Save
                    </button>
                  </div>
                </div>
              ) : renderItem[getIndex].type &&
                renderItem[getIndex].type === "checkbox" ? (
                <div className="customDiv flexColumn overflow">
                  <div className="customRow flexRow">
                    <div className="cust10 flexCen">
                      <span className="contractLabell">
                        <b></b>
                      </span>
                    </div>
                    <div className="cust70 flexCen">
                      <span className="contractLabell">
                        <b>Label</b>
                      </span>
                    </div>
                    <div className="cust5 flexCen"></div>
                  </div>
                  <div style={{ height: "auto", overflow: "auto" }}>
                    {innerTempArr.map((data, dataIndex) => {
                      return (
                        <div className="customRow flexRow" key={dataIndex}>
                          <div className="cust10 ">
                            <input
                              type="checkbox"
                              name="checkbox"
                              value={data.check}
                              checked={data.check}
                              onChange={(e) => edit(e, dataIndex)}
                            />
                          </div>
                          <div className="cust70 borderMe">
                            <input
                              className="contractInputInner"
                              name="label"
                              type="text"
                              value={data.label}
                              onChange={(e) => edit(e, dataIndex)}
                            />
                          </div>
                          <div className="cust5">
                            {innerTempArr.length !== 1 ? (
                              <i
                                className="fa fa-minus constSub"
                                onClick={() => subElems(dataIndex)}
                              ></i>
                            ) : (
                              ""
                            )}
                            {innerTempArr.length - 1 === dataIndex ? (
                              <i
                                className="fa fa-plus contAdd"
                                onClick={() => increaseElems(dataIndex)}
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="customRow">
                    <button className="custContBtn" onClick={save}>
                      Save
                    </button>
                  </div>
                </div>
              ) : renderItem[getIndex].type &&
                renderItem[getIndex].type === "radio" ? (
                <div className="customDiv flexColumn overflow">
                  <div className="customRow flexRow">
                    <div className="cust10 flexCen">
                      <span className="contractLabell">
                        <b></b>
                      </span>
                    </div>
                    <div className="cust70 flexCen">
                      <span className="contractLabell">
                        <b>Label</b>
                      </span>
                    </div>
                    <div className="cust5 flexCen"></div>
                  </div>
                  {innerTempArr.map((data, dataIndex) => {
                    return (
                      <div className="customRow flexRow" key={dataIndex}>
                        <div className="cust10 ">
                          <input
                            type="checkbox"
                            name="radioCheck"
                            value={data.check}
                            checked={data.check}
                            onChange={(e) => edit(e, dataIndex)}
                          />
                        </div>
                        <div className="cust70 borderMe">
                          <input
                            className="contractInputInner"
                            name="label"
                            type="text"
                            value={data.label}
                            onChange={(e) => edit(e, dataIndex)}
                          />
                        </div>
                        <div className="cust5">
                          {innerTempArr.length !== 1 ? (
                            <i
                              className="fa fa-minus constSub"
                              onClick={() => subElems(dataIndex)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {innerTempArr.length - 1 === dataIndex ? (
                            <i
                              className="fa fa-plus contAdd"
                              onClick={() => increaseElems(dataIndex)}
                            ></i>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}

                  <div className="customRow">
                    <button className="custContBtn" onClick={save}>
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractClauses;

function dropData(key, variable) {
  var dataObj = {
    simpletext: {
      description: "Enter your input",
      placeholder: "Write here..",
      type: "simpletext",
      value: "",
      field: "text",
    },
    number: {
      description: "Enter number only",
      placeholder: "Write here..",
      type: "number",
      value: "",
      field: "number",
      variable: variable,
    },
    units: {
      type: "units",
      placeholder: "Select",
      values: [
        {
          label: "Km",
          check: false,
        },
        {
          label: "days",
          check: false,
        },
        {
          label: "Rupees",
          check: false,
        },
        {
          label: "kg",
          check: false,
        },
      ],
      show: false,
      value: "",
      field: "units",
      variable: variable,
    },
    checkbox: {
      variable: variable,
      type: "checkbox",
      field: "checkbox",
      values: [
        {
          label: "Option 1",
          check: false,
        },
      ],
      check: false,
    },
    radio: {
      type: "radio",
      label: "Radio",
      variable: variable,
      values: [
        {
          label: "Option 1",
          check: false,
        },
      ],
      check: false,
      field: "radio",
    },
    date: {
      variable: variable,
      label: "Date",
      placeholder: "Date",
      type: "date",
      required: false,
      value: "",
      field: "date",
    },
    dropdown: {
      type: "dropdown",
      label: "Select",
      placeholder: "Select",
      show: false,
      values: [
        {
          label: "Enter value",
          check: false,
        },
      ],
      value: "",
      field: "dropdown",
      variable: variable,
    },
    input: {
      label: "Input",
      placeholder: "Input Field",
      type: "input",
      value: "",
      show: false,
      dropDownShow: false,
      field: "input",
      variable: variable,
      unit: "",
      values: [
        {
          label: "Kmph",
          check: false,
        },
        {
          label: "Tonnes",
          check: false,
        },
        {
          label: "Kg",
          check: false,
        },
        {
          label: "days",
          check: false,
        },
        {
          label: "Rupees",
          check: false,
        },
      ],
    },
    perinterval: {
      label: "Input",
      placeholder: "Input Field",
      type: "perinterval",
      value: "",
      show: false,
      dropDownShow: false,
      field: "input",
      variable: variable,
      unit: "",
      values: [
        {
          label: "per Day",
          check: false,
        },
        {
          label: "per Tonnes",
          check: false,
        },
        {
          label: "per Kg",
          check: false,
        },
        {
          label: "per Km",
          check: false,
        },
      ],
    },
    vehicletype: {
      type: "dropdown",
      label: "Select",
      placeholder: "Select",
      show: false,
      values: [
        {
          label: "Open Body Truck",
          check: false,
        },
        {
          label: "Mini Truck/LCV",
          check: false,
        },
        {
          label: "Closed Body/Container",
          check: false,
        },
        {
          label: "Trailer",
          check: false,
        },
        {
          label: "Tanker",
          check: false,
        },
        {
          label: "Tipper",
          check: false,
        },
      ],
      value: "",
      field: "dropdown",
      variable: variable,
    },
    vehiclecategory: {
      type: "dropdown",
      label: "Select",
      placeholder: "Select",
      show: false,
      values: [
        { label: "Canter (14 -17 Feet)", check: false },
        { label: "Canter (19 Feet)", check: false },
        { label: "Car Carrier", check: false },
        { label: "Container Close Body (20-40)", check: false },
        { label: "Container Close Body (32-36)", check: false },
        { label: "LCV (Light Commercial Vehicle - 4 tons)", check: false },
        { label: "LCV 5 MT", check: false },
        { label: "LCV 7 MT", check: false },
        { label: "Multi Axle (24 Feet)", check: false },
        { label: "Multi Axle (28 Feet)", check: false },
        { label: "Multi Axle (32 Feet)", check: false },
        { label: "ODC (Over Dimensional Cargo)", check: false },
        { label: "Pick Up", check: false },
        { label: "Refrigerated Trucks", check: false },
        { label: "Road Tankers (10 wheel)", check: false },
        { label: "Road Tankers (6 wheel)", check: false },
        { label: "Tipper Dumper", check: false },
        { label: "Trailer (14 wheel)", check: false },
        { label: "Trailer (16 wheel)", check: false },
        { label: "Truck Flat Bed", check: false },
        { label: "Flat Bed Trailers (20-32 Feet)", check: false },
        { label: "Flat Bed Trailers (40-54 Feet)", check: false },
        { label: "Truck (6 wheel)", check: false },
        { label: "Truck 10 wheel (22 Feet)", check: false },
        { label: "Truck 12 wheel (22 Feet)", check: false },
        { label: "Truck Close (14 Feet)", check: false },
        { label: "Truck Open (14 Feet)", check: false },
        { label: "Truck Open (17 Feet)", check: false },
        { label: "Truck Close (17 Feet)", check: false },
        { label: "Truck Open (19 Feet)", check: false },
        { label: "Truck Close (20 Feet)", check: false },
        { label: "Bus", check: false },
        { label: "Cab(Taxi)", check: false },
        { label: "Canter (14-17 Feet)", check: false },
        { label: "Tata-12 Feet - 3 MT", check: false },
        { label: "3 Wheel Ape", check: false },
        { label: "Omni Van", check: false },
        { label: "Tata Zip", check: false },
        { label: "Tata Ace", check: false },
        { label: "Maximo", check: false },
        { label: "Super Ace", check: false },
        { label: "Dost", check: false },
        { label: "Bolero Pickup", check: false },
        { label: "Isuzu Pickup", check: false },
        { label: "Tata 407", check: false },
        { label: "Tata Intra", check: false },
        { label: "Canter/ 709 14 ft-3.5 MT", check: false },
        { label: "Canter 17 ft- 5 MT", check: false },
        { label: "19 ft-6 Wheeler - 7 MT", check: false },
        { label: "19 ft-6 Wheeler 8 MT", check: false },
      ],
      value: "",
      field: "dropdown",
      variable: variable,
    },
  };
  return dataObj[key];
}

var fieldDraggables = [
  {
    type: "simpletext",
    value: "Simple Text",
  },
  {
    type: "date",
    value: "Date",
  },
  {
    type: "dropdown",
    value: "Dropdown",
  },
  {
    type: "units",
    value: "Units",
  },
  {
    type: "input",
    value: "Input",
  },
  {
    type: "number",
    value: "Number",
  },
  {
    type: "checkbox",
    value: "Checkbox",
  },
  {
    type: "radio",
    value: "Radio Button",
  },
  {
    type: "perinterval",
    value: "Per Interval",
  },
  {
    type: "vehiclecategory",
    value: "Vehicle Category",
  },
  {
    type: "vehicletype",
    value: "Vehicle Type",
  },
];
