import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery'
import { selectInit } from '../../../services/functions'
import history from '../././../../services/history'
import AllocateTruckModal from '../../Dashboard/Modal/AllocateTruck'
import view from '../../../static/images/icon/buttons/ViewDetail.svg'
import allocate from '../../../static/images/icon/buttons/allocate.svg'
import documentView from '../../../static/images/icon/buttons/ViewDocuments.svg'
import TrackingModal from '../../Dashboard/Modal/Tracking'
import ViewDocumentsModal from '../../Dashboard/Modal/ViewDocuments'
import Weighmentout from '../../Dashboard/Modal/Weighmentout'
import { BookingAction , listingApi } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import axios from 'axios';

const LogisticOut = (props) => {
    const dispatch = useDispatch()
    //logistic
    const logisticlist = useSelector((state) => state.bookingReducers.logistics)
    const bookmark = useSelector((state) => state.bookingReducers.bookmarks)
    const records = useSelector((state) => state.bookingReducers.records)
    const listingLoader = useSelector((state) => state.bookingReducers.loader)
    const [type, setType] = useState('truck-reporting')
    const [userGroup, setUserGroup] = useState('')
    const [val, setVal] = useState('')
    const transportersList = useSelector((state) => state.getListingReducer.transporterListing)
    const [transVal, setTransVal] = useState('')
    const [obj, setObj] = useState({
        param: "next",
        status: props.type,
        bookmark: "",
        vehicleStatus: "",
        search: "",
        searchBy: "",
        inspectionStatus: "",
        inspectionType: "",
        vehicleBelongsTo: ""
    })
    const [weighmentout, setweighmentout] = useState({})
    const [searchObject, setSearchObject] = useState(
        {
            bookmark: "",
            vehicleStatus: "",
            search: "",
            searchBy: "",
            inspectionStatus: "",
            inspectionType: "",
            vehicleBelongsTo: ""
        }
    )
    useEffect(() => {
        setType(props.type)
        setProps()
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 100);
        setUserGroup(localStorage.getItem("userGroup") === 'transporter' ? '2' : "1")
        bookmark.length = 0
        setObj(
            {
                param: "next",
                status: props.type,
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: ""
            }
        )
        if (type !== '') {
            dispatch(BookingAction(obj))
        }
        else { }
    }, [dispatch])
    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchBy = value
            searchObject.searchBy = value
            setSearchObject(temp)
        }
        else if (id === 'VehicleOf') {
            setVal(value)
        }
        else if (id === 'transporter') {
            setTransVal(value)
        }
    }
    const setProps = () => {
        if (props.type === 'logistics-gate-out') {
            searchObject.vehicleStatus = 'logisticsGateOut'
        }
    }

    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")
        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(BookingAction({
            param: "next",
            status: props.type,
            bookmark: "",
            vehicleStatus: "",
            search: searchObject.search,
            searchBy: searchObject.searchBy,
            inspectionStatus: "",
            inspectionType: "",
            vehicleBelongsTo: ""
        }))
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const cancelBooking = (index) => {
        window.$.fn.show_notification({ message: "Cancel your booking from your OTM Platform.", title: 'Are you sure!!', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
            if (result.type === 'confirm') {
            }
        })
    }
    const _moveTo = (item, index) => {
        history.push({
            pathname: "/dashboard/booking/detail/docs",
            state: { url_param: type, bookingDetail: item, num: index, key: 'logistic' }
        })
    }
    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            status: props.type
        }
        setObj(object)
        dispatch(BookingAction(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            status: props.type
        }
        setObj(object)
        dispatch(BookingAction(object))
        bookmark.pop()
    }
    useEffect(() => {
        if (val) {
            dispatch(BookingAction({
                param: "next",
                status: props.type,
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: val
            }))
        }
    }, [val])
    
    useEffect(() => {
        dispatch(listingApi({
            docType: "transporter",
            param: "",
            pageSize: "",
            bookmark: "",
            blacklistedFlag: "",
            searchType: "",
            searchValue: ""
        }))
    }, [])

    useEffect(() => {
        if (transVal) {
            dispatch(BookingAction({
                param: "next",
                status: props.type,
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: "",
                transporterId: transVal
            }))
        }
    }, [transVal])
    return (
        <div className="contentpanel">
            <h1 className="title">Booking</h1>
            <hr />
            <div className="cust-container">
                <div className='cust-row'>
                    <div className='cust-col-1_2 flex-algn-cent'>
                        <div className='tt2'>Booking List</div>
                    </div>

                    <div className='cust-col-3'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <select className="form-control select2-single" defaultValue="select" id="searchType">
                                    <option value="select">Select</option>
                                    <option value="shipmentGID">Shipment GID</option>
                                    <option value="vehicle">Vehicle Number</option>
                                    <option value="driverName">Driver Name</option>
                                    <option value="dlNo">DL Number</option>
                                </select>
                            </div>
                            <input type="text" name='search' value={searchObject.search} onChange={(e) => _onChangeSearch(e)} className="form-control" placeholder="Search here..." />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2" onClick={() => triggerSearch()}>
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    {type !== 'all' && userGroup !== '2' ?
                        <div className='cust-col-1_2'>
                            <select className="form-control select2-single" defaultValue="Vehicle" id="VehicleOf">
                                <option value="Vehicle">Vehicle of</option>
                                <option value="transporter">Transporter vehicles</option>
                                <option value="customer">Customer vehicles</option>
                            </select>
                        </div>
                        : ""}
                    {userGroup !== '2' ?
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="pending" id="transporter">
                                <option value="select">Transporter Name</option>
                                {transportersList && transportersList.map((item, index) => {
                                    return (
                                        <option key={index} value={transportersList[index]._id}>{item.OrgName}</option>
                                    )
                                })}
                            </select>
                        </div>
                        : ""}

                </div>

                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='data-cont'>
                            {logisticlist && logisticlist.length !== 0 ?
                                logisticlist.map((item, index) => {
                                    return (
                                        <div key={index} className='data-cont-item '>
                                            {userGroup === '1' ?
                                                <div className='data_item_content align-div pad-0'>
                                                    <div className='wd_30 clip-path display-col'>
                                                        <span className='tbl-hd-1'>Transporter :</span>
                                                        <span className='tbl-hd-2'>{item.TransporterName ? item.TransporterName : "-"}</span>
                                                        <span className='tbl-hd-3'>{item.OrgReadableID ? `(ID - ${item.OrgReadableID})` : ""}</span>
                                                    </div>
                                                    <div className='wd_30 bg-trans inc-hgt'>
                                                        <div className='dateTime'>
                                                            <span>Date/Time</span>
                                                        </div>
                                                        <div className='date'>
                                                            <i className="fa fa-calendar"></i>
                                                            <span>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div className='time'>
                                                            <i className="fa fa-clock-o xl"></i>
                                                            <span>{moment(item.TruckReportingTime).format("h:mm:ss a")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                            <div className='data_item_content set_height_xxl pad-top-0 set-new-hght'>
                                                <div className='wd_5 align-cent set-hght'>
                                                    <div className='sr-no '>
                                                        <span>{index + 1}</span>
                                                    </div>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Shipment GID</span>
                                                    <span className='orange_color font-lg'>{item.ShipmentGID}</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Vehicle No.</span>
                                                    <span>{item.TruckDetails && item.TruckDetails.VehicleNumber ? item.TruckDetails.VehicleNumber : "-"}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Destination Location GID</span>
                                                    <span className='flex_box_2'>{item.DestinationLocationGID}</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Reference Ship GID</span>
                                                    <span>{item.ReferenceShipGID}</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Transport Mode GID</span>
                                                    <span>{item.TransportModeGID}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Service Provider GID</span>
                                                    <span>{item.ServiceProviderGID}</span>
                                                </div>

                                                <div className='wd_15'>
                                                    <span>Loading Slip No. / Date & Time</span>
                                                    <span>{item.TruckDetails && item.TruckDetails.LoadingSlipNo ? item.TruckDetails.LoadingSlipNo : "-"} ,<br />  {item.TruckDetails && item.TruckDetails.LoadedDateTime ? moment(item.TruckDetails.LoadedDateTime).format("DD/MM/YYYY") : "-"} ,{item.TruckDetails && item.TruckDetails.LoadedDateTime ? moment(item.TruckDetails.LoadedDateTime).format("h:mm:ss a") : "-"} </span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Status</span>
                                                    <span>Gate-Out Done</span>
                                                </div>
                                                <div className='wd_5 align-cent set-hght set-bg'>
                                                    <div className='sr-no trans' onClick={() => { toogleItemView(index) }}>
                                                        <i className="fa fa-plus expand-tbl open"></i>
                                                        <i className="fa fa-minus contract-tbl hide" ></i>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='data_item_content clps set_height_xxxl setColor '>
                                                <>
                                                    <div className='wd_15'>
                                                        <span>Tare Weight</span>
                                                        <span>{item.TruckDetails && item.TruckDetails.TareWeight ? item.TruckDetails.TareWeight : "-"}</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Gross Weight</span>
                                                        <span>{item.TruckDetails && item.TruckDetails.GrossWeight ? item.TruckDetails.GrossWeight : "-"}</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Loaded Weight</span>
                                                        <span>{item.TruckDetails && item.TruckDetails.FirstLoadedWeight ? item.TruckDetails.FirstLoadedWeight : "-"}</span>
                                                    </div>
                                                    <div className='wd_20'>
                                                        <span>Document Ready Date & Time</span>
                                                        <span>{item.TruckDetails && item.TruckDetails.DocumentReadyDate ? moment(item.TruckDetails.DocumentReadyDate).format('DD/MM/YYYY') : "-"} , {item.TruckDetails && item.TruckDetails.DocumentReadyDate ? moment(item.TruckDetails.DocumentReadyDate).format("h:mm:ss a") : "-"}</span>
                                                    </div>
                                                    <div className='wd_20'>
                                                        <span>Logistics Gate-Out By</span>
                                                        <span>{item.TruckDetails && item.TruckDetails.LogisticsGateOutBy && item.TruckDetails.LogisticsGateOutBy.name ? item.TruckDetails.LogisticsGateOutBy.name : '-'}</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Logistics Gate-Out Time </span>
                                                        <span>{item.TruckDetails && item.TruckDetails.LogisticsGateOutTime ? `${moment(item.TruckDetails.LogisticsGateOutTime).format('DD/MM/YYYY')} , ${moment(item.TruckDetails.LogisticsGateOutTime).format('h:mm:ss a')}` : '-'}</span>
                                                    </div>

                                                    <div className='wd_35 no-mg-pd designChange' >
                                                        <div className='btn_row_1'>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 less-size' onClick={() => { _handleClick('ViewDocumentsModal') }} ><img className='imgg' src={documentView} alt="none" />View Documents</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 myBtn less-size'>
                                                                <img src={view} alt="none" />View Detail
                                                                <div className="titleBox">
                                                                    <div className='docOptions' onClick={() => _moveTo(item, '0')}>
                                                                        <span>Booking Details</span>
                                                                    </div>
                                                                    <div className='docOptions' onClick={() => _moveTo(item, '1')}>
                                                                        <span>Loading Details</span>
                                                                    </div>
                                                                    <div className='docOptions' onClick={() => _moveTo(item, '2')}>
                                                                        <span>Eway Bill Details</span>
                                                                    </div>
                                                                    <div className='docOptions' onClick={() => _moveTo(item, '3')}>
                                                                        <span>LR Details</span>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    )
                                })
                                : <span className='noDataClass'>No Data Found</span>
                            }
                        </div>

                        {logisticlist && logisticlist.length !== 0 ?
                            <div className='paginationDiv'>
                                <button disabled={obj && obj.bookmark === '' ? true : false ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                                <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                            </div>
                            : ''}
                    </div>
                </div>
            </div>
            <ViewDocumentsModal closeModal={_closeModal} />
            <Weighmentout closeModal={_closeModal} weighmentout={weighmentout} />
        </div>
    )
}
export default LogisticOut;