import React from 'react';
import $ from 'jquery'

const DocViewAddress = (props) => {
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#DocViewAddressModal").removeClass("custom-modal-is-visible")
    }
    return (
        <section id="DocViewAddressModal"  className="custom-modal">
            <div className="custom-modal-header">
                <h1>Uploaded Document</h1>
                <span className="ct-close" onClick={()=>_closeModal("DocViewAddressModal")}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                <div className="cust-row">
                    <img className='panGstDoc' src={props.imageData} alt=''/>
                </div>
            </div>
        </section>
    )
 
}

export default DocViewAddress;