import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import Markers from './Markers';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import history from '../../services/history';



const Map = (props) => {

    const mapData = props.location.state
    const lat = mapData.lat
    const lng = mapData.lng
    const taggedimages = mapData.newarray || []
    
    const position = {
        lat: 28.77, lng: 77.89
    }

    useEffect(() => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }, [])

    const _goBack=()=>{
        history.goBack()
    }

    return (
        <>
            <div className='mapOuter'>
                <div className='map_btn_div' onClick={_goBack}>
                    <button className='map_btn' ><i className="fa fa-arrow-left map_icon"> </i></button>
                </div>
                <LoadScript
                    googleMapsApiKey='AIzaSyAMbjwVD8stwnb3sKYaxCOefn6ranbU7bE'>

                    <GoogleMap id="mapDimensions" zoom={12} center={position} >
                    {/* <Markers position={position} icon={taggedimages} /> */}
                        {/* {taggedimages.length > 1 ? (<Markers position={position} icon={taggedimages} />) : <Markers position={position} icon={taggedimages} />} */}

                    </GoogleMap>

                </LoadScript>
            </div>
        </>

    );
};
export default Map;