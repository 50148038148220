import React, { useEffect, useState } from 'react';
import history from './../../services/history';
import { getSingleUser } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'

const UserDetails = (props) => {
    const dispatch = useDispatch()
    const getIndex = props.location.state
    const singleUserData = useSelector((state) => state.getSingleUserReducer.userData)
    const loader = useSelector((state) => state)
    const group = localStorage.getItem('userGroup')
    const subGroup = localStorage.getItem('userSubGroup')

    const _goBack = () => {
        history.push('/dashboard/users')
    }
    useEffect(() => {
        dispatch(getSingleUser(getIndex))
    }, [dispatch]);

    return (
        <>
            <div className="contentpanel">
                <div className="cust-container">
                    {singleUserData !== undefined ?
                        loader === true ?
                            <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                <i className="fa fa-spinner fa-spin loadingSpinner font-xxl  "></i>
                            </div>
                            :
                            <div className="contentpanel">
                                <div className='cust-row'>
                                    <div className='cust-col-4 flex-algn-cent'>
                                        <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                                            <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                                        </button>
                                        <h1 className="title">User Details</h1>
                                    </div>
                                    <div className='cust-col-6 flex-jc-end'>
                                        <button className='button bg-orange-out mr-2 wd10'>
                                            <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                                            <div className="titleBoxnew">
                                                <span>Edit </span>
                                            </div>
                                        </button>
                                        <button className='button bg-orange-out mr-2 wd10'>
                                            <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                                            <div className="titleBoxnew">
                                                <span>Download </span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <hr className='bgg' />
                                <div className="cust-container">
                                    <div className='cust-row'>
                                        <div className='cust-col-10'>
                                            <div className='AddressDetailMain'>
                                                <div className='addressDetails-L'>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>Plant :</span>
                                                        </div> 
                                                        <div className='add-detail-c2'>
                                                        {singleUserData.UserGroup === 'goodsowner' && (singleUserData.UserSubGroup === "Plant" || singleUserData.UserSubGroup === "Department") ?
                                                                <span className='add-detail-span2'>{singleUserData.PlantName}</span>
                                                                : '-'
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>Department :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            {singleUserData.UserGroup === 'goodsowner' && singleUserData.UserSubGroup === "Department" ?
                                                                <span className='add-detail-span2'>{singleUserData.DepartmentName}</span>
                                                                : '-'
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>User Type :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <span className='add-detail-span2'>{singleUserData.UserType}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='addressDetails-R'>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>Name :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <span className='add-detail-span2'>{singleUserData.Name}  </span>
                                                        </div>
                                                    </div>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>Email Id:</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <span className='add-detail-span2'>{singleUserData.EmailId} </span>
                                                        </div>
                                                    </div>
                                                    <div className='add-Details-tab'>
                                                        <div className='add-detail-c1'>
                                                            <span className='add-detail-span1'>Mobile Number :</span>
                                                        </div>
                                                        <div className='add-detail-c2'>
                                                            <span className='add-detail-span2'>{singleUserData.MobileNo} </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                        <span className='noDataClass'>No Data Found</span>
                    }
                </div>
            </div>

        </>
    )
}

export default UserDetails;
