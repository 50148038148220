import React, { useEffect, useState } from 'react';
import { allocateTruck, listingApi } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'
import moment from 'moment'
import Select from 'react-select';

const ConfirmBooking = (props) => {
    const allocateData = props.allocate
    const dispatch = useDispatch()
    const usersList = useSelector((state) => state.getListingReducer.listing)
    const driverList = useSelector((state) => state.getListingReducer.driverListing)
    const [totalLoad, setTotalLoad] = useState()
    const [shipmentGID, setShipmentGID] = useState('')
    const [remaining, setRemaining] = useState()
    const [vehicleModal, setVehicleModal] = useState({})
    const [driverModal, setDriverModal] = useState({})
    const [warning, setWarning] = useState([
        {
            grossWarning: false,
            remainingWarn: false
        }
    ])
    const [vehicleList, setVehicleDetails] = useState(
        [{
            vehicleNumber: "",
            vehicleType: "",
            registeredCapacity: "",
            unloadWeight: "",
            grossVehicleWeight: "",
            vehicleBelongsTo: '',

            driverDetails: [
                {
                    dlNumber: "",
                    mobileNo: "",
                    name: ""
                }
            ]
        }]
    )
    const [calRemaining, setCalRem] = useState()

    const _onChange = (e, item, index) => {
        const tempArr = [...vehicleList]
        if (item === 'number') {
            var ind = e.target.value
            setVehicleModal(usersList[ind])
            tempArr[index].vehicleNumber = usersList[ind].VehicleNumber
            if (usersList[ind].VehicleType !== undefined) {
                tempArr[index].vehicleType = usersList[ind].VehicleType
                tempArr[index].grossVehicleWeight = usersList[ind].GrossVehicleWeight
                tempArr[index].unloadWeight = usersList[ind].UnloadWeight
                tempArr[index].vehicleBelongsTo = usersList[ind].VehicleBelongsTo
                var difference = usersList[ind].GrossVehicleWeight - usersList[ind].UnloadWeight
                vehicleList[index].registeredCapacity = difference
            }
            else {
                tempArr[index].vehicleType = 'Not found'
            }
            setVehicleDetails(tempArr)
        }
        else if (item === 'gross') {
            tempArr[index].grossVehicleWeight = e.target.value
            setVehicleDetails(tempArr)
        }
        else if (item === 'tare') {
            tempArr[index].unloadWeight = e.target.value
            setVehicleDetails(tempArr)
        }
    }
    const _onChange1 = (data) => {
        const tempArr = [...vehicleList]
        setDriverModal(driverList[data.event.target.value])
        if (data.item === 'dl') {
            tempArr[data.ind1].driverDetails[data.ind2].dlNumber = driverList[data.event.target.value].DLNumber
            tempArr[data.ind1].driverDetails[data.ind2].name = driverList[data.event.target.value].Name
            tempArr[data.ind1].driverDetails[data.ind2].mobileNo = driverList[data.event.target.value].MobileNo
            setVehicleDetails(tempArr)
        }
        else if (data.item === 'mNo') {
            tempArr[data.ind1].driverDetails[data.ind2].mobileNo = data.event.target.value
            setVehicleDetails(tempArr)
        }
        else if (data.item === 'name') {
            tempArr[data.ind1].driverDetails[data.ind2].name = data.event.target.value
            setVehicleDetails(tempArr)
        }
    }
    const _empty = () => {
        setVehicleDetails(
            [{
                vehicleNumber: "",
                vehicleType: "",
                registeredCapacity: "",
                unloadWeight: "",
                grossVehicleWeight: "",
                driverDetails: [
                    {
                        dlNumber: "",
                        mobileNo: "",
                        name: ""
                    }
                ]
            }]
        )
        setCalRem(allocateData.RemainingLoadableCapacity)
        setRemaining(allocateData.RemainingLoadableCapacity)
        // setTotalLoad(allocateData.load);
    }
    const _appendRow = (index) => {
        var tempArr = vehicleList;
        tempArr[index].driverDetails.push({
            dlNumber: "",
            mobileNo: "",
        })
        setVehicleDetails([...tempArr]);
    }
    const _descendRow = (index, driverIndex) => {
        var tempArr = [...vehicleList];
        tempArr[index].driverDetails.splice(driverIndex, 1)
        setVehicleDetails(tempArr);
    }
    const _deleteRow = (item, index) => {
        var tempArr = [...vehicleList];
        var dumWarnArr = [...warning]
        if (tempArr[index].registeredCapacity !== '') {
            var tempObj = calRemaining + parseInt(tempArr[index].registeredCapacity)
            setCalRem(tempObj)
        }
        else {
            var tempObj = calRemaining
            setCalRem(tempObj)
        }

        tempArr.splice(index, 1)
        setVehicleDetails(tempArr);

        dumWarnArr.splice(index, 1)
        setWarning(dumWarnArr)
    }
    const calculation = (index) => {
        var tempArr = [...vehicleList];
        let gross = tempArr[index].grossVehicleWeight
        let tare = tempArr[index].unloadWeight
        var calc = parseFloat(gross) - parseFloat(tare);

        if (calc > 0) {
            tempArr[index].registeredCapacity = calc
            setVehicleDetails(tempArr)
            warning[index].grossWarning = false

        }
        else {
            tempArr[index].registeredCapacity = 0
            setVehicleDetails(tempArr)
            warning[index].grossWarning = true
        }
    }
    const _add = () => {
        setRemaining(calRemaining)
        var tempArr = vehicleList;
        var warnArr = [...warning]
        tempArr.push({
            vehicleNumber: "",
            vehicleType: "",
            registeredCapacity: "",
            UnloadWeight: "",
            grossVehicleWeight: "",

            driverDetails: [
                {
                    dlNumber: "",
                    mobileNo: "",
                    name: ""
                }
            ]
        })
        setVehicleDetails([...tempArr])

        warnArr.push(
            {
                grossWarning: false,
                remainingWarn: false
            }
        )
        setWarning(warnArr)
    }
    const cal = (index) => {
        var tempArr = [...vehicleList];
        var sub = remaining - tempArr[index].registeredCapacity
        if (sub >= 0) {
            setCalRem(sub)
            warning[index].remainingWarn = false
        }
        else if (sub < 0) {
            setCalRem(remaining)
            warning[index].remainingWarn = true
        }
    }
    const allocateTruckFun = () => {
        const concatedData = { shipmentGID, vehicleList }
        dispatch(allocateTruck(concatedData))
        setRemaining(allocateData.RemainingLoadableCapacity)
        setVehicleDetails(
            [{
                vehicleNumber: "",
                vehicleType: "",
                registeredCapacity: "",
                UnloadWeight: "",
                grossVehicleWeight: "",
                driverDetails: [
                    {
                        dlNumber: "",
                        mobileNo: "",
                        name: ""
                    }
                ]
            }]
        )
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const fetchData = (item) => {
        if (item === 'num') {
            dispatch(listingApi({
                docType: "vehicle",
                bookmark: '',
                param: "next"
            }))
        }
        else if (item === 'dl') {
            dispatch(listingApi(
                {
                    docType: "driver",
                    bookmark: "",
                    param: "next"
                }))
        }
    }
    useEffect(() => {
        dispatch(listingApi({
            docType: "vehicle",
            bookmark: '',
            param: "next"
        }))
        dispatch(listingApi(
            {
                docType: "driver",
                bookmark: "",
                param: "next"
            }))
    }, [])
    useEffect(() => {
        if (allocateData !== undefined) {
            setTotalLoad(allocateData.load)
            setShipmentGID(allocateData.shipmentGID)
            setRemaining(allocateData.RemainingLoadableCapacity)
            setCalRem(allocateData.RemainingLoadableCapacity)
        }
        if (vehicleList[0].grossVehicleWeight > vehicleList[0].UnloadWeight) {
            warning[0].grossWarning = false
        }
        else {
            warning[0].grossWarning = true
        }
    }, [props, allocateData])

    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white',
            width: '100%',

        }),
        control: (styles, state) => {
            return {
                ...styles,
                backgroundColor: "transparent",


                boxShadow:
                    state.isFocused
                        ? "0 0 3px #28a745"
                        : state.isFocused
                            ? "0 0 3px #dc3545"
                            : "none",
                cursor: "pointer",
                ":focus": {
                    ...styles[":focus"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                ":active": {
                    ...styles[":active"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },

                ":hover": {
                    ...styles[":hover"],
                    // borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                height: 35,
                minHeight: 35,
            };
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
            ...styles,
            background: isFocused ? '#ff7200' : isSelected ? '#fff' : undefined,
            color: isFocused ? '#fff' : 'black',
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? "var(--clr--accent)"
                        : undefined
                    : undefined,
            },
            zIndex: 1
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '35px',
            margin: '-8px 0 0 0'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '35px',
        }),


    }
    const options = [];
    usersList && usersList.map((item, index) => {
        options.push({ value: item._id, id: index, label: item.VehicleNumber, vehicleNumber: item.VehicleNumber, vehicleType: item.VehicleType, grossVehicleWeight: item.GrossVehicleWeight, unloadWeight: item.UnloadWeight, registeredCapacity: '', vehicleBelongsTo: item.VehicleBelongsTo });
    });

    const _handleVehicleChange = (item) => {
        console.log('item',item);
        let ind = item.id - 1
        const tempArr = [...vehicleList]
        tempArr[ind].vehicleNumber = item.vehicleNumber
        tempArr[ind].vehicleType = item.vehicleType
        tempArr[ind].grossVehicleWeight = item.grossVehicleWeight
        tempArr[ind].unloadWeight = item.unloadWeight
        var difference = item.grossVehicleWeight - item.unloadWeight
        tempArr[ind].registeredCapacity = difference
        tempArr[ind].vehicleBelongsTo = item.vehicleBelongsTo
        setVehicleDetails(tempArr)
        console.log('vehicleList', vehicleList);
        // let local = {...vehicleModal}
        // local = item
        // setVehicleModal(local)


        //         vehicleList[index].registeredCapacity = difference
        // if (item === 'number') {
        //     var ind = e.target.value
        //     setVehicleModal(usersList[ind])
        //     tempArr[index].vehicleNumber = usersList[ind].VehicleNumber
        //     if (usersList[ind].VehicleType !== undefined) {
        //         tempArr[index].vehicleType = usersList[ind].VehicleType
        //         tempArr[index].grossVehicleWeight = usersList[ind].GrossVehicleWeight
        //         tempArr[index].unloadWeight = usersList[ind].UnloadWeight
        //         var difference = usersList[ind].GrossVehicleWeight - usersList[ind].UnloadWeight
        //         vehicleList[index].registeredCapacity = difference
        //     }
        //     else {
        //         tempArr[index].vehicleType = 'Not found'
        //     }
        //     setVehicleDetails(tempArr)
        // }
    }

    return (
        <section id="confirmBookingModal" className="custom-modal" style={{ width: "65%" }}>
            <div className="custom-modal-header">
                <h1>Add Details Against Indent</h1>
                <span className="ct-close" onClick={() => _closeModal()} ></span>
            </div>
            <div className="custom-modal-body flex-col" style={{ overflowX: 'auto', maxHeight: '65vh' }}>
                <div className='allocation1'>
                    <div className='allR'>
                        <span className='custRspan'><b>Total Load Capacity</b></span>
                        <input className='custRinput mgRight' type='number' disabled placeholder={allocateData !== undefined ? allocateData.load : "-"} />
                    </div>
                    <div className='allR'>
                        <span className='custRspan'><b>Remaining Load</b></span>
                        <input className='custRinput mgRight' type='number' disabled placeholder={calRemaining} />
                    </div>
                </div>
                {vehicleList.map((item, index) => {
                    return (
                        <div key={index} className='allocation2'>
                            <span className='vehicleIndex'>{index + 1}</span>
                            {index === 0 && vehicleList.length === 1 ?
                                <i className="fa fa-minus vehicleMinus1" onClick={() => _empty(item, index)}></i>
                                : ''}
                            {index >= 1 ?
                                <i className="fa fa-minus vehicleMinus" onClick={() => _deleteRow(item, index)}></i>
                                : ''
                            }
                            <div className='allRow1'>
                                <span>Truck Details</span>
                            </div>
                            <div className='allRow2'>
                                <div className='allR40'>
                                    <div className='all40I'>
                                        <span className='custRspan'>Truck Number</span>
                                    </div>
                                    <div className='all60I'>
                                        <div className='allInpI80'>
                                            <select name="vehicleNumber" defaultValue='select' onClick={() => fetchData('num')} onChange={(e) => _onChange(e, 'number', index)} className='allInputTag'>
                                                <option value='select' >Select Vehicle</option>
                                                {usersList !== undefined && usersList !== [] && usersList.length !== 0 ?
                                                    usersList.map((item, VehicleIndex) => {
                                                        return (
                                                            <option key={VehicleIndex} value={VehicleIndex}>{item.VehicleNumber}</option>
                                                        )
                                                    })
                                                    :
                                                    <option>Not found</option>
                                                }
                                            </select>
                                            {/* <Select
                                                onChange={(value) => _handleVehicleChange(value)}
                                                options={options}
                                                styles={colourStyles}
                                            /> */}
                                        </div>
                                        <div className='allInpI20'>
                                            <div className='custInnerR'>
                                                {vehicleList[index].vehicleNumber.length !== 0 ?
                                                    <div className='calDiv'>
                                                        <div className='popdv'>
                                                            <button className='allocateBtns1'>
                                                                <i className="fa fa-info-circle allInfo"></i>
                                                            </button>
                                                            <div className="btmplc">
                                                                <div className='dv_flex pd5 brd_rht brd_btm brd_tp brd_lft'>
                                                                    <div className='wdt100 text-center'>
                                                                        <span className='font-bold black_color'>Vehicle Number : <span className='grey_color'>{vehicleModal.VehicleNumber}</span></span>
                                                                    </div>
                                                                </div>
                                                                <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                                    <div className='wdt40 brd_rht text-left'>
                                                                        <span className='font-bold black_color font-sm'>Registration upto</span>
                                                                    </div>
                                                                    <div className='wdt40 brd_rht'>
                                                                        <span className='font-bold grey_color font-sm'>{moment(vehicleModal.RegistrationValidTill).format('DD/MM/YYYY')}</span>
                                                                    </div>
                                                                    <div className='wdt20'>
                                                                        {moment().isBefore(moment(vehicleModal.RegistrationValidTill)) ?
                                                                            <i className='fa fa-check green_color'></i>
                                                                            :
                                                                            <span className='font-bold red_color font-xsm'>Expired</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                                    <div className='wdt40 brd_rht text-left'>
                                                                        <span className='font-bold black_color font-sm'>PUC Valid Upto</span>
                                                                    </div>
                                                                    <div className='wdt40 brd_rht'>
                                                                        <span className='font-bold grey_color font-sm'>{moment(vehicleModal.PucValidTill).format('DD/MM/YYYY')}</span>
                                                                    </div>

                                                                    <div className='wdt20'>
                                                                        {moment().isBefore(moment(vehicleModal.PucValidTill)) ?
                                                                            <i className='fa fa-check green_color'></i>
                                                                            :
                                                                            <span className='font-bold red_color font-xsm'>Expired</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                                    <div className='wdt40 brd_rht text-left'>
                                                                        <span className='font-bold black_color font-sm'>Insurance Validity</span>
                                                                    </div>
                                                                    <div className='wdt40 brd_rht'>
                                                                        <span className='font-bold grey_color font-sm'>{moment(vehicleModal.InsuranceValidTill).format('DD/MM/YYYY')}</span>
                                                                    </div>

                                                                    <div className='wdt20'>
                                                                        {moment().isBefore(moment(vehicleModal.InsuranceValidTill)) ?
                                                                            <i className='fa fa-check green_color'></i>
                                                                            :
                                                                            <span className='font-bold red_color font-xsm'>Expired</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {localStorage.getItem("userGroup") !== 'transporter' ?
                                                                    <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                                        <div className='wdt50'>
                                                                            <button className='button bg-success'>
                                                                                <span className='btn_txt'>Accept</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className='wdt50'>
                                                                            <button className='button bg-danger'>
                                                                                <span className='btn_txt'>Reject</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    : ""}
                                                                <i></i>
                                                            </div>
                                                        </div>
                                                        <i className="fa fa-check allCheck" ></i>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='allR60'>
                                    <div className='all30I'>
                                        <span className='custRspan'>Loadable Capacity</span>
                                    </div>
                                    <div className='all60I'>
                                        <div className='all30'>
                                            <input name="grossVehicleWeight" value={item.grossVehicleWeight} id='load1'
                                                onChange={(e) => _onChange(e, 'gross', index)}
                                                onKeyUp={() => { calculation(index); cal(index); }}
                                                type='number' className='calInput'
                                                disabled={vehicleList.length - 1 === index ? false : true}
                                            />
                                            <span className='overlapping'>Gross Weight</span>
                                        </div>
                                        <div className='all5'>
                                            <span>-</span>
                                        </div>
                                        <div className='all30'>
                                            <input type='number' className='calInput' name="unloadWeight" value={item.unloadWeight}
                                                onChange={(e) => _onChange(e, 'tare', index)} id='load2'
                                                onKeyUp={() => { calculation(index); cal(index); }}
                                                disabled={vehicleList.length - 1 === index ? false : true}
                                            />
                                            <span className='overlapping'>Tare Weight</span>
                                        </div>
                                        <div className='all5'>
                                            <span>=</span>
                                        </div>
                                        <div className='all30'>
                                            <input type='number' className='calInput' value={item.registeredCapacity} disabled />
                                        </div>
                                    </div>
                                    {warning[index].grossWarning === true ?
                                        <span className='warnText'>Gross weight should be greater than Tare Weight.</span>
                                        : ''}
                                    {warning[index].remainingWarn === true ?
                                        <span className='warnText'>Registered capacity({item.registeredCapacity}) cannot be greater than Remaining Weight({remaining}).</span>
                                        : ''}
                                </div>
                            </div>
                            <div className='allRow2'>
                                <div className='allR40'>
                                    <div className='all40I'>
                                        <span className='custRspan'>Vehicle Type</span>
                                    </div>
                                    <div className='all60I'>
                                        <div className='allInpI80'>
                                            <input name="vehicleType" value={item.vehicleType} className='allInputTag' type='text' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='allRow1'>
                                <span>Driver Details</span>
                            </div>
                            {vehicleList[index].driverDetails.map((driverItem, driverIndex) => {
                                return (
                                    <div className='parentAll' key={driverIndex}>
                                        {/* <span className="snoAbs">{driverIndex+1}</span> */}
                                        <div className='allRow2' >
                                            <div className='allR40'>
                                                <div className='all40I'>
                                                    <span className='custRspan'>DL Number</span>
                                                </div>
                                                <div className='all60I'>
                                                    <div className='allInpI80'>
                                                        <select name="dlNumber" onClick={() => fetchData('dl')} defaultValue='default' onChange={(e) => _onChange1({ 'event': e, 'item': 'dl', 'ind1': index, 'ind2': driverIndex })} className='allInputTag'>
                                                            <option value='default'>select DL Number</option>
                                                            {driverList !== undefined && driverList !== [] && driverList.length !== 0 ?
                                                                driverList.map((item, dlIndex) => {
                                                                    return (
                                                                        <option key={dlIndex} value={dlIndex}>{item.DLNumber}</option>
                                                                    )
                                                                })
                                                                :
                                                                <option >Not Found</option>
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className='allInpI20'>
                                                        <div className='custInnerR'>
                                                            {vehicleList[index].vehicleNumber.length !== 0 ?
                                                                <div className='calDiv'>
                                                                    <div className='popdv'>
                                                                        <button className='allocateBtns1'>
                                                                            <i className="fa fa-info-circle allInfo"></i>
                                                                        </button>
                                                                        <div className="btmplc">
                                                                            <div className='dv_flex pd5 brd_rht brd_btm brd_tp brd_lft'>
                                                                                <div className='wdt100 text-center'>
                                                                                    <span className='font-bold black_color'>DL Number : <span className='grey_color'>{driverModal !== undefined ? driverModal.DLNumber : '-'}</span></span>
                                                                                </div>
                                                                            </div>
                                                                            <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                                                <div className='wdt40 brd_rht text-left'>
                                                                                    <span className='font-bold black_color font-sm'>Expiry Upto</span>
                                                                                </div>
                                                                                <div className='wdt40 brd_rht'>
                                                                                    <span className='font-bold grey_color font-sm'> <span className='font-bold grey_color font-sm'>{driverModal !== undefined ? moment(driverModal.ExpiryDate).format('DD/MM/YYYY') : ''}</span></span>
                                                                                </div>
                                                                                <div className='wdt20'>
                                                                                    {driverModal !== undefined ? moment().isBefore(moment(driverModal.ExpiryDate)) ?
                                                                                        <i className='fa fa-check green_color'></i>
                                                                                        :
                                                                                        <span className='font-bold red_color font-xsm'>Expired</span>
                                                                                        : ''
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                                                <div className='wdt40 brd_rht text-left'>
                                                                                    <span className='font-bold black_color font-sm'>PUC Valid Upto</span>
                                                                                </div>
                                                                                <div className='wdt40 brd_rht'>
                                                                                    <span className='font-bold grey_color font-sm'>(e-Vahan)</span>
                                                                                </div>
                                                                                <div className='wdt20'>
                                                                                    <span className='font-bold red_color font-xsm'>Expired</span>
                                                                                </div>
                                                                            </div> */}
                                                                            {/* <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                                                <div className='wdt40 brd_rht text-left'>
                                                                                    <span className='font-bold black_color font-sm'>Insurance Validity</span>
                                                                                </div>
                                                                                <div className='wdt40 brd_rht'>
                                                                                    <span className='font-bold grey_color font-sm'>(e-Vahan)</span>
                                                                                </div>
                                                                                <div className='wdt20'>
                                                                                    <i className='fa fa-check green_color'></i>
                                                                                </div>
                                                                            </div> */}
                                                                            {localStorage.getItem("userGroup") !== 'transporter' ?
                                                                                <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                                                    <div className='wdt50'>
                                                                                        <button className='button bg-success'>
                                                                                            <span className='btn_txt'>Accept</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className='wdt50'>
                                                                                        <button className='button bg-danger'>
                                                                                            <span className='btn_txt'>Reject</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                : ""}
                                                                            <i></i>
                                                                        </div>
                                                                    </div>
                                                                    <i className="fa fa-check allCheck" ></i>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='allR60'>
                                                <div className='all30I'>
                                                    <span className='custRspan'>Mobile Number</span>
                                                </div>
                                                <div className='all60I'>
                                                    {/* onChange={(e) => _onChange1({ 'event': e, 'item': 'mNo', 'ind1': index, 'ind2': driverIndex })} */}
                                                    <div className='mobElem'>
                                                        <input name='mobileNo' value={driverItem.mobileNo} className='allInputTag alterWidth' type='number' />
                                                        {vehicleList[index].driverDetails.length - 1 === driverIndex ?

                                                            <button className='allAdd' onClick={() => _appendRow(index, driverIndex)}>Add</button>

                                                            : ''}
                                                        {vehicleList[index].driverDetails.length === 1 ?
                                                            ''
                                                            :
                                                            <button className='allSub' onClick={() => _descendRow(index, driverIndex)}>Remove</button>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='allRow2' >
                                            <div className='allR40'>
                                                <div className='all40I'>
                                                    <span className='custRspan'>Driver Name</span>
                                                </div>
                                                <div className='all60I'>
                                                    <div className='allInpI80'>
                                                        <input name='name' value={driverItem.name} className='allInputTag' type='text' />
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}
                            <div className={vehicleList.length - 1 === index ? 'allocation3' : 'hide'}>
                                <button className='allBtn' onClick={() => allocateTruckFun()}>Allocate</button>
                                <button disabled={warning[index].grossWarning === false && warning[index].remainingWarn === false ? false : true} className='allAddBtn' onClick={() => _add(index)} >Add More</button>
                            </div>

                        </div>
                    )
                })}


            </div>
        </section>
    )

}

export default ConfirmBooking;