import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Sidebar from "../../views/Dashboard/Sidebar";
import Header from "../../views/Dashboard/Header";
import CustomBreadcrumb from "./../../views/Dashboard/CustomBreadcrumb";
import DashboardView from "../../views/Dashboard/DashboardView";
import Receipt from "../../views/Dashboard/Receipt";
import Vehicle from "../../views/Dashboard/Vehicle";
import AddressBook from "../../views/Dashboard/AddressBook";
import AddAddress from "../../views/Dashboard/AddAddress";
import Hubs from "../../views/Dashboard/Hubs";
import Driver from "../../views/Dashboard/Driver";
import DriverDetails from "../../views/Dashboard/DriverDetails";
import UserProfile from "../../views/Dashboard/UserProfile";
import VehicleDetail from "./../../views/Dashboard/VehicleDetail";
import BookingDetail from "../../views/Dashboard/BookingDetail";
import AddressBookDetails from "./../../views/Dashboard/AddressBookDetails";
import DeliveryView from "./../../views/Dashboard/Delivery";
import PodDetail from "../../views/Dashboard/PodDetail";
import Contracts from "../../views/Dashboard/Contracts";
import ReceiptDetail from "../../views/Dashboard/ReceiptDetail";
import CreateReceipt from "../../views/Dashboard/CreateReceipt";
import Analytics from "./../../views/Dashboard/Analytics";
import Map from "../../views/Dashboard/Map";
import ContractDetailView from "./../../views/Dashboard/ContractDetail";
import TransporterMasterView from "./../../views/Dashboard/TransporterMaster";
import "./../../static/css/dashboard.css";
import logo from "./../../static/images/logo3.png";
import $ from "jquery";
import Invoice from "../../views/Dashboard/Invoice";
import InvoiceListing from "../../views/Dashboard/InvoiceListing";
import SelectInvoice from "../../views/Dashboard/SelectInvoice";
import MySettings from "../../views/Dashboard/MySettings";
import history from "./../../services/history";
import AddContract from "../../views/Dashboard/AddContract";
import TransporterMasterDetail from "../../views/Dashboard/TransporterMasterDetail";
import HubsDetail from "../../views/Dashboard/HubsDetail";
import Workforce from "../../views/Dashboard/Workforce";
import AddDriverStaff from "../../views/Dashboard/AddDriverStaff";
import BookingMultDoc from "../../views/Dashboard/BookingMultDoc";
import AddHub from "../../views/Dashboard/AddHub";
import Users from "../../views/Dashboard/Users";
import Registration from "../../views/Landing/Registration";
import Plants from "../../views/Dashboard/Plants";
import UserDetails from "../../views/Dashboard/UserDetail";
import Formula from "../../views/Dashboard/Formula";
import RegisterTrans from "../../views/Dashboard/RegisterTrans";
import Arrived from "../../views/Dashboard/Arrived";
import Booking from "../../views/Dashboard/BookingFolder/Booking";
import Inspection from "../../views/Dashboard/BookingFolder/Inspection";
import RequestGateIn from "../../views/Dashboard/BookingFolder/RequestGateIn";
import Gatedin from "../../views/Dashboard/BookingFolder/Gatedin";
import WeighmentIn from "../../views/Dashboard/BookingFolder/WeighmentIn";
import WeighmentOut from "../../views/Dashboard/BookingFolder/WeighmentOut";
import DocReady from "../../views/Dashboard/BookingFolder/DocReady";
import LogisticOut from "../../views/Dashboard/BookingFolder/LogisticOut";
import Material from "../../views/Dashboard/BookingFolder/Material";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this._logout = this._logout.bind(this);
  }

  componentDidMount() {
    $("[data-toggle='dropdown']").on("click", function (e) {
      e.stopPropagation();
      let target = $(this).data("target");
      $(target).parent().toggleClass("open");
    });

    setTimeout(() => {
      $(".custom-modal-overlay").height("100vh");
    }, 1000);

    $("[data-toggle!='dropdown']").on("click", function () {
      $(".btn-group,.media").removeClass("open");
    });

    $("#menuToggle").on("click", function () {
      $(".sidebar").toggleClass("open");
    });
  }

  _logout() {
    localStorage.removeItem("secretkey");
    localStorage.removeItem("token");
    localStorage.removeItem("userGroup");
    localStorage.removeItem("userType");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("gname");
    localStorage.removeItem("userSubGroup");
    localStorage.removeItem("email");
    localStorage.removeItem("sserSubGroup");
    history.push("/");
  }

  render() {
    return (
      <div>
        <Header handleClick={this._logout} image={logo} />
        <div className="main-content">
          <Sidebar image={logo} {...this.props} />
          <div className="content-body">
            <CustomBreadcrumb {...this.props} />
            <Switch>
              <Route
                path={this.props.match.url + "/analytics"}
                exact={true}
                render={(props) => <Analytics {...props} />}
              />
              <Route
                path={this.props.match.url}
                exact={true}
                render={(props) => <DashboardView {...props} />}
              />
              <Route
                path={`${this.props.match.url}/users`}
                exact={true}
                render={(props) => <Users {...props} type="users" />}
              />
              <Route
                path={`${this.props.match.url}/plants`}
                exact={true}
                render={(props) => <Plants {...props} type="users" />}
              />
              <Route
                path={`${this.props.match.url}/booking`}
                exact={true}
                render={(props) => (
                  <Booking {...props} type="truck-reporting" />
                )}
              />
              <Route
                path={`${this.props.match.url}/booking/pre-gate-in`}
                exact={true}
                render={(props) => (
                  <Booking {...props} type="truck-reporting" />
                )}
              />
              <Route
                path={`${this.props.match.url}/booking/pre-gate-in/truck-reporting-pending`}
                exact={true}
                render={(props) => (
                  <Booking {...props} type="truck-reporting" />
                )}
              />
              <Route
                path={`${this.props.match.url}/booking/pre-gate-in/inspection-completed`}
                exact={true}
                render={(props) => <Inspection {...props} type="inspection" />}
              />
              <Route
                path={`${this.props.match.url}/booking/pre-gate-in/gate-in-allowed`}
                exact={true}
                render={(props) => (
                  <RequestGateIn {...props} type="gate-in-allowed" />
                )}
              />
              <Route
                path={`${this.props.match.url}/booking/gate-in`}
                exact={true}
                render={(props) => <Gatedin {...props} type="gatein" />}
              />
              <Route
                path={`${this.props.match.url}/booking/gate-in/gated-in`}
                exact={true}
                render={(props) => <Gatedin {...props} type="gatein" />}
              />
              <Route
                path={`${this.props.match.url}/booking/gate-in/weighment-in`}
                exact={true}
                render={(props) => (
                  <WeighmentIn {...props} type="weighment-in" />
                )}
              />
              <Route
                path={`${this.props.match.url}/booking/gate-in/weighment-out`}
                exact={true}
                render={(props) => (
                  <WeighmentOut {...props} type="weighment-out" />
                )}
              />
              <Route
                path={`${this.props.match.url}/booking/gate-out`}
                exact={true}
                render={(props) => (
                  <DocReady {...props} type="document-ready" />
                )}
              />
              <Route
                path={`${this.props.match.url}/booking/gate-out/document-ready`}
                exact={true}
                render={(props) => (
                  <DocReady {...props} type="document-ready" />
                )}
              />
              <Route
                path={`${this.props.match.url}/booking/gate-out/logistics-gate-out`}
                exact={true}
                render={(props) => (
                  <LogisticOut {...props} type="logistics-gate-out" />
                )}
              />
              <Route
                path={`${this.props.match.url}/booking/gate-out/material-gate-out`}
                exact={true}
                render={(props) => (
                  <Material {...props} type="material-gate-out" />
                )}
              />
              <Route
                path={`${this.props.match.url}/booking/arrived-at-destination`}
                exact={true}
                render={(props) => <Booking {...props} type="arrived" />}
              />
              <Route
                path={`${this.props.match.url}/arrived`}
                exact={true}
                render={(props) => <Arrived {...props} type="arrived" />}
              />
              <Route
                path={`${this.props.match.url}/booking/detail/docs`}
                exact={true}
                render={(props) => (
                  <BookingMultDoc {...props} type="logistics-gate-out-doc" />
                )}
              />
              <Route
                path={`${this.props.match.url}/booking/detail`}
                exact={true}
                render={(props) => <BookingDetail {...props} />}
              />
              <Route
                path={`${this.props.match.url}/lorry-receipt`}
                exact={true}
                render={(props) => <Receipt {...props} type="all" />}
              />
              <Route
                path={`${this.props.match.url}/goods-receipt/detail`}
                exact={true}
                render={(props) => <ReceiptDetail {...props} />}
              />
              <Route
                path={`${this.props.match.url}/goods-receipt/create`}
                exact={true}
                render={(props) => <CreateReceipt {...props} />}
              />
              <Route
                path={`${this.props.match.url}/delivery`}
                exact={true}
                render={(props) => <DeliveryView {...props} />}
              />
              <Route
                path={`${this.props.match.url}/delivery/detail`}
                exact={true}
                render={(props) => <PodDetail {...props} />}
              />
              <Route
                path={`${this.props.match.url}/Map`}
                exact={true}
                render={(props) => <Map {...props} />}
              />
              <Route
                path={`${this.props.match.url}/address-book`}
                exact={true}
                render={(props) => <AddressBook {...props} />}
              />
              <Route
                path={`${this.props.match.url}/address-book/add-address`}
                exact={true}
                render={(props) => <AddAddress {...props} />}
              />
              <Route
                path={`${this.props.match.url}/address-book/details`}
                exact={true}
                render={(props) => <AddressBookDetails {...props} />}
              />
              <Route
                path={`${this.props.match.url}/user/details`}
                exact={true}
                render={(props) => <UserDetails {...props} />}
              />
              <Route
                path={`${this.props.match.url}/vehicle`}
                exact={true}
                render={(props) => <Vehicle {...props} />}
              />
              <Route
                path={`${this.props.match.url}/vehicle/details`}
                exact={true}
                render={(props) => <VehicleDetail {...props} />}
              />
              <Route
                path={`${this.props.match.url}/driver`}
                exact={true}
                render={(props) => <Driver {...props} />}
              />
              <Route
                path={`${this.props.match.url}/workforce`}
                exact={true}
                render={(props) => <Workforce {...props} />}
              />
              <Route
                path={`${this.props.match.url}/workforce/add`}
                exact={true}
                render={(props) => <AddDriverStaff {...props} />}
              />
              <Route
                path={`${this.props.match.url}/driver/details`}
                exact={true}
                render={(props) => <DriverDetails {...props} />}
              />

              <Route
                path={`${this.props.match.url}/Workforce/details`}
                exact={true}
                render={(props) => <DriverDetails {...props} />}
              />
              <Route
                path={`${this.props.match.url}/driver/details`}
                exact={true}
                render={(props) => <DriverDetails {...props} />}
              />
              <Route
                path={`${this.props.match.url}/hubs`}
                exact={true}
                render={(props) => <Hubs {...props} />}
              />
              <Route
                path={`${this.props.match.url}/hubs/Add-Hub`}
                exact={true}
                render={(props) => <AddHub {...props} />}
              />
              <Route
                path={`${this.props.match.url}/hubs/details`}
                exact={true}
                render={(props) => <HubsDetail {...props} />}
              />
              <Route
                path={`${this.props.match.url}/transporter-master`}
                exact={true}
                render={(props) => <TransporterMasterView {...props} />}
              />
              <Route
                path={`${this.props.match.url}/transporter-master/details`}
                exact={true}
                render={(props) => <TransporterMasterDetail {...props} />}
              />
              <Route
                path={`${this.props.match.url}/transporter-master/registration`}
                exact={true}
                render={(props) => <RegisterTrans {...props} />}
              />
              <Route
                path={`${this.props.match.url}/Contracts`}
                exact={true}
                render={(props) => <Contracts {...props} />}
              />
              <Route
                path={`${this.props.match.url}/Contracts/Add-Contract`}
                exact={true}
                render={(props) => <AddContract {...props} />}
              />
              <Route
                path={`${this.props.match.url}/Contracts/Create-Formula`}
                exact={true}
                render={(props) => <Formula {...props} />}
              />
              <Route
                path={`${this.props.match.url}/Contracts/details`}
                exact={true}
                render={(props) => <ContractDetailView {...props} />}
              />
              <Route
                path={`${this.props.match.url}/UserProfile`}
                exact={true}
                render={(props) => <UserProfile {...props} />}
              />
              <Route
                path={`${this.props.match.url}/Invoices`}
                exact={true}
                render={(props) => <InvoiceListing {...props} />}
              />
              <Route
                path={`${this.props.match.url}/Invoices/draft`}
                exact={true}
                render={(props) => <InvoiceListing {...props} />}
                type="draft"
              />
              <Route
                path={`${this.props.match.url}/Invoices/correction`}
                exact={true}
                render={(props) => <InvoiceListing {...props} />}
                type="correction"
              />
              <Route
                path={`${this.props.match.url}/Invoices/confirmed`}
                exact={true}
                render={(props) => <InvoiceListing {...props} />}
                type="confirmed"
              />
              <Route
                path={`${this.props.match.url}/lorry-receipt/Select-PoD`}
                exact={true}
                render={(props) => <SelectInvoice {...props} />}
              />
              <Route
                path={`${this.props.match.url}/Select/Create-Invoice`}
                exact={true}
                render={(props) => <Invoice {...props} />}
              />
              <Route
                path={`${this.props.match.url}/My-Settings`}
                exact={true}
                render={(props) => <MySettings {...props} />}
              />
              <Redirect to="/dashboard" />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
