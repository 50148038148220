import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery'
import { selectInit } from '../../../services/functions'
import history from '../././../../services/history'
import AllocateTruckModal from '../../Dashboard/Modal/AllocateTruck'
import view from '../../../static/images/icon/buttons/ViewDetail.svg'
import allocate from '../../../static/images/icon/buttons/allocate.svg'
import documentView from '../../../static/images/icon/buttons/ViewDocuments.svg'
import TrackingModal from '../../Dashboard/Modal/Tracking'
import ViewDocumentsModal from '../../Dashboard/Modal/ViewDocuments'
import Weighmentout from '../../Dashboard/Modal/Weighmentout'
import { BookingAction ,listingApi } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import axios from 'axios';

const DocReady = (props) => {
    const dispatch = useDispatch()
    const docreadylist = useSelector((state) => state.bookingReducers.docready)
    const bookmark = useSelector((state) => state.bookingReducers.bookmarks)
    const records = useSelector((state) => state.bookingReducers.records)
    const listingLoader = useSelector((state) => state.bookingReducers.loader)
    const transportersList = useSelector((state) => state.getListingReducer.transporterListing)
    const [transVal, setTransVal] = useState('')
    const [obj, setObj] = useState({
        param: "next",
        status: props.type,
        bookmark: "",
        vehicleStatus: "",
        search: "",
        searchBy: "",
        inspectionStatus: "",
        inspectionType: "",
        vehicleBelongsTo: ""
    })
    const [val, setVal] = useState('')
    const [type, setType] = useState('truck-reporting')
    const [userGroup, setUserGroup] = useState('')
    const [weighmentout, setweighmentout] = useState({})
    const [searchObject, setSearchObject] = useState(
        {
            bookmark: "",
            vehicleStatus: "",
            search: "",
            searchBy: "",
            inspectionStatus: "",
            inspectionType: "",
            vehicleBelongsTo: ""
        }
    )
    const [logistic, setLogistic] = useState(
        {
            action: 'logisticsGateOut',
            shipmentGID: '',
            truckAllocationId: '',
            totalCoils: '209',
            customerDetails: [
                {
                    customerName: 'MEW ELECTRICALS LIMITED',
                    customerAddress: '26, 329,330,, G.I.D.C EDSTATE,',
                    customerPincode: '391760',
                    customerCity: 'WAGHODIA',
                    customerState: 'GUJARAT',
                    invoiceDetails: [
                        {
                            invoiceNumber: 'AW/23002752',
                            invoiceDate: '19-APR-2022',
                            invoiceStatus: 'P',
                            sale_category: 'Deemed - Export',
                            product_sub_group: 'CATH',
                            materialDetails: [
                                {
                                    materialType: 'Cathode',
                                    materialQty: ' 24.85',
                                    lot_Details: [
                                        {
                                            lot_Number: '22-24003926',
                                            lot_Qty: '2.655'
                                        },
                                        {
                                            lot_Number: '22-24003929',
                                            lot_Qty: '2.745'
                                        },
                                        {
                                            lot_Number: '22-24003937',
                                            lot_Qty: '2.517'
                                        },
                                        {
                                            lot_Number: "22-20002165",
                                            lot_Qty: '1.92'
                                        },
                                        {
                                            lot_Number: '22-22001090',
                                            lot_Qty: '1.976'
                                        },
                                        {
                                            lot_Number: '22-24003920',
                                            lot_Qty: '2.692'
                                        },
                                        {
                                            lot_Number: '22-24003921',
                                            lot_Qty: '2.706'
                                        },
                                        {
                                            lot_Number: '22-24003922',
                                            lot_Qty: '2.57'
                                        },
                                        {
                                            lot_Number: '22-24003923',
                                            lot_Qty: '2.556'
                                        },
                                        {
                                            lot_Number: '22-24003925',
                                            lot_Qty: '2.513'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    customerName: 'HINDALCO INDUSTRIES LIMITED',
                    customerAddress: 'VADODARA DEPOT,P.NO.10 GODOWN-3, V.P.INDL. WAREHOUSING CO.OP.,,PADMALA, OPP. L T NIRO LTD.,',
                    customerPincode: '391350',
                    customerCity: 'VADODARA',
                    customerState: 'GUJARAT',
                    invoiceDetails: [
                        {
                            invoiceNumber: 'S24/23000253',
                            invoiceDate: '19-APR-2022',
                            invoiceStatus: 'P',
                            sale_category: 'STA',
                            product_sub_group: 'CCRD',
                            materialDetails: [
                                {
                                    materialType: 'CCR 8 mm',
                                    materialQty: '13.981',
                                    lot_Details: [
                                        {
                                            lot_Number: '22-11001267',
                                            lot_Qty: '3'
                                        },
                                        {
                                            lot_Number: '22-11001286',
                                            lot_Qty: '2.992'
                                        },
                                        {
                                            lot_Number: '22-13002544',
                                            lot_Qty: '3.994'
                                        },
                                        {
                                            lot_Number: '22-13002545',
                                            lot_Qty: '3.995'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }


    )
    useEffect(() => {
        setType(props.type)
        setProps()
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 100);
        setUserGroup(localStorage.getItem("userGroup") === 'transporter' ? '2' : "1")
        bookmark.length = 0
        setObj(
            {
                param: "next",
                status: props.type,
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: ""
            }
        )
        if (type !== '') {
            dispatch(BookingAction(obj))
        }
        else { }

    }, [dispatch])

    const setProps = () => {
        if (props.type === 'document-ready') {
            searchObject.vehicleStatus = 'documentReady'
        }
    }

    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")
        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }
    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchBy = value
            searchObject.searchBy = value
            setSearchObject(temp)
        }
        else if (id === 'VehicleOf') {
            setVal(value)
        }
        else if (id === 'transporter') {
            setTransVal(value)
        }
    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(BookingAction({
            param: "next",
            status: props.type,
            bookmark: "",
            vehicleStatus: "",
            search: searchObject.search,
            searchBy: searchObject.searchBy,
            inspectionStatus: "",
            inspectionType: "",
            vehicleBelongsTo: ""
        }))
    }
    const confirmBooking = () => {
        window.$.fn.show_notification({ message: "confirmation is required for acceptance of booking", title: 'Are you sure!!', type: 'confirm', autohide: false }, (result) => {
            if (result.type === "confirm") {
                _closeModal()
            }
        })
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const logisticGateOut = (index) => {
        if (props.type === 'document-ready') {
            logistic.shipmentGID = docreadylist[index].ShipmentGID
            if ('TruckDetails' in docreadylist[index]) {
                logistic.truckAllocationId = docreadylist[index].TruckDetails.TruckAllocationId
            }
            else {
            }
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/booking/booking', { ...logistic }, { headers: config.headers })
            .then(response => {
                const error = response.data.error
                const message = response.data.message
                if (error === false) {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Success!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                    // window.location.reload();
                    dispatch(BookingAction(obj))
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Alert!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('err', error);
            })

    }
    const _moveTo = (item, index) => {
        history.push({
            pathname: "/dashboard/booking/detail/docs",
            state: { url_param: type, bookingDetail: item, num: index }
        })
    }
    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            status: props.type
        }
        setObj(object)
        dispatch(BookingAction(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            status: props.type
        }
        setObj(object)
        dispatch(BookingAction(object))
        bookmark.pop()
    }
    useEffect(() => {
        if (val) {
            dispatch(BookingAction({
                param: "next",
                status: props.type,
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: val
            }))
        }
    }, [val])
    useEffect(() => {
        dispatch(listingApi({
            docType: "transporter",
            param: "",
            pageSize: "",
            bookmark: "",
            blacklistedFlag: "",
            searchType: "",
            searchValue: ""
        }))
    }, [])

    useEffect(() => {
        if (transVal) {
            dispatch(BookingAction({
                param: "next",
                status: props.type,
                bookmark: "",
                vehicleStatus: "",
                search: "",
                searchBy: "",
                inspectionStatus: "",
                inspectionType: "",
                vehicleBelongsTo: "",
                transporterId: transVal
            }))
        }
    }, [transVal])


    return (
        <div className="contentpanel">
            <h1 className="title">Booking</h1>
            <hr />
            <div className="cust-container">
                <div className='cust-row'>
                    <div className='cust-col-1_2 flex-algn-cent'>
                        <div className='tt2'>Booking List</div>
                    </div>

                    <div className='cust-col-3'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <select className="form-control select2-single" defaultValue="select" id="searchType">
                                    <option value="select">Select</option>
                                    <option value="shipmentGID">Shipment GID</option>
                                    <option value="vehicle">Vehicle Number</option>
                                    <option value="driverName">Driver Name</option>
                                    <option value="dlNo">DL Number</option>
                                </select>
                            </div>
                            <input type="text" name='search' value={searchObject.search} onChange={(e) => _onChangeSearch(e)} className="form-control" placeholder="Search here..." />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2" onClick={() => triggerSearch()}>
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    {type !== 'all' && userGroup !== '2' ?
                    <div className='cust-col-1_2'>
                            <select className="form-control select2-single" defaultValue="Vehicle" id="VehicleOf">
                                <option value="Vehicle">Vehicle of</option>
                                <option value="transporter">Transporter vehicles</option>
                                <option value="customer">Customer vehicles</option>
                            </select>
                        </div>
                        : ""}
                        {userGroup !== '2' ?
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="pending" id="transporter">
                                <option value="select">Transporter Name</option>
                                {transportersList && transportersList.map((item, index) => {
                                    return (
                                        <option key={index} value={transportersList[index]._id}>{item.OrgName}</option>
                                    )
                                })}
                            </select>
                        </div>
                        : ""}
                </div>

                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='data-cont'>
                            {docreadylist && docreadylist.length !== 0 ?
                                docreadylist.map((item, index) => {
                                    return (
                                        <div key={index} className='data-cont-item '>
                                            {userGroup === '1' ?
                                                <div className='data_item_content align-div pad-0'>
                                                    <div className='wd_30 clip-path display-col'>
                                                        <span className='tbl-hd-1'>Transporter :</span>
                                                        <span className='tbl-hd-2'>{item.TransporterName ? item.TransporterName : "-"}</span>
                                                        <span className='tbl-hd-3'>{item.OrgReadableID ? `(ID - ${item.OrgReadableID})` : ""}</span>
                                                    </div>
                                                    <div className='wd_30 bg-trans inc-hgt'>
                                                        <div className='dateTime'>
                                                            <span>Date/Time</span>
                                                        </div>
                                                        <div className='date'>
                                                            <i className="fa fa-calendar"></i>
                                                            <span>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div className='time'>
                                                            <i className="fa fa-clock-o xl"></i>
                                                            <span>{moment(item.TruckReportingTime).format("h:mm:ss a")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                            <div className='data_item_content set_height_xxl pad-top-0 set-new-hght'>
                                                <div className='wd_5 align-cent set-hght'>
                                                    <div className='sr-no '>
                                                        <span>{index + 1}</span>
                                                    </div>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Shipment GID</span>
                                                    <span className='orange_color font-lg'>{item.ShipmentGID}</span>
                                                    {/* <span>(Date:Ekayan)</span> */}
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Vehicle No.</span>
                                                    <span>{item.TruckDetails && item.TruckDetails.VehicleNumber ? item.TruckDetails.VehicleNumber : "-"}</span>
                                                </div>
                                                <div className='wd_15' >
                                                    <span>Source Location GID</span>
                                                    <span className='flex_box_2'>{item.SourceLocationGID}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Destination Location GID</span>
                                                    <span className='flex_box_2'>{item.DestinationLocationGID}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Reference Ship GID</span>
                                                    <span>{item.ReferenceShipGID}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Transport Mode GID</span>
                                                    <span>{item.TransportModeGID}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Service Provider GID</span>
                                                    <span>{item.ServiceProviderGID} </span>
                                                </div>
                                                <div className='wd_5 align-cent set-hght set-bg'>
                                                    <div className='sr-no trans' onClick={() => { toogleItemView(index) }}>
                                                        <i className="fa fa-plus expand-tbl open"></i>
                                                        <i className="fa fa-minus contract-tbl hide" ></i>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='data_item_content clps set_height_xxxl setColor '>
                                                <>
                                                    <div className='wd_15'>
                                                        <span>Gross Weight</span>
                                                        <span>{item.TruckDetails && item.TruckDetails.GrossWeight ? item.TruckDetails.GrossWeight : "-"}</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Tare Weight</span>
                                                        <span>{item.TruckDetails && item.TruckDetails.TareWeight ? item.TruckDetails.TareWeight : "-"}</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Loaded Weight</span>
                                                        <span>{item.TruckDetails && item.TruckDetails.FirstLoadedWeight ? item.TruckDetails.FirstLoadedWeight : "-"}</span>

                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Document Ready By</span>
                                                        <span>{item.TruckDetails && item.TruckDetails.DocumentReadyBy && item.TruckDetails.DocumentReadyBy.name ? item.TruckDetails.DocumentReadyBy.name : "-"}</span>
                                                    </div>
                                                    <div className='wd_20'>
                                                        <span>Document Ready Date & Time</span>
                                                        <span>{item.TruckDetails && item.TruckDetails.DocumentReadyDate ? moment(item.TruckDetails.DocumentReadyDate).format('DD/MM/YYYY') : "-"}, {item.TruckDetails && item.TruckDetails.DocumentReadyDate ? moment(item.TruckDetails.DocumentReadyDate).format('h:mm:ss a') : "-"}</span>
                                                    </div>

                                                    <div className='wd_20'>
                                                        <span>Loading Slip No,/Date </span>
                                                        <span>{item.TruckDetails && item.TruckDetails.LoadingSlipNo ? item.TruckDetails.LoadingSlipNo : "-"} , {item.TruckDetails && item.TruckDetails.LoadedDateTime ? moment(item.TruckDetails.LoadedDateTime).format("DD/MM/YYYY") : "-"} </span>
                                                    </div>
                                                    <div className='wd_30 no-mg-pd designChange' >
                                                        <div className='btn_row_1'>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 less-size' onClick={() => { _handleClick('ViewDocumentsModal') }}><img className='imgg' src={documentView} alt="none" />View Documents</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 myBtn less-size'>
                                                                <img src={view} alt="none" />View Detail
                                                                <div className="titleBox">
                                                                    <div className='docOptions' onClick={() => _moveTo(item, '0')}>
                                                                        <span>Booking Details</span>
                                                                    </div>
                                                                    <div className='docOptions' onClick={() => _moveTo(item, '1')}>
                                                                        <span>Loading Details</span>
                                                                    </div>
                                                                    <div className='docOptions' onClick={() => _moveTo(item, '2')}>
                                                                        <span>Eway Bill Details</span>
                                                                    </div>
                                                                    {/* <div className='docOptions' onClick={() => _moveTo(item, '3')}>
                                                                                                        <span>LR Details</span>
                                                                                                    </div> */}
                                                                </div>
                                                            </button>
                                                            {userGroup === '1' ?
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 less-size' onClick={() => logisticGateOut(index)}>Logistic Gate Out</button>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    )
                                })
                                : <span className='noDataClass'>No Data Found</span>
                            }


                        </div>

                        {docreadylist && docreadylist.length !== 0 ?
                            <div className='paginationDiv'>
                                <button disabled={obj && obj.bookmark === '' ? true : false ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                                <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                            </div>
                            : ''}

                    </div>
                </div>
            </div>
            <ViewDocumentsModal closeModal={_closeModal} />
            <Weighmentout closeModal={_closeModal} weighmentout={weighmentout} />
        </div>
    )
}
export default DocReady;