import { get } from './../../../services/requests'
import { VIEW_LR , LR_HIDE } from './types'

export const viewLrDetail = (lrId) =>{ 
    return(dispatch)=>{
        get(`booking/GetLR/${lrId}`)
        .then(response=>{
             console.log('lr details response',response);
             const lrData= response.data
             dispatch(storeLrData(lrData)) 
             dispatch(hideLoader())
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
} 
export const storeLrData = (lrData) => {
    return {
        type: VIEW_LR,
        payload: lrData
    }
}
export const hideLoader = () => {
    return {
        type: LR_HIDE
    }
}


