import { CLAUSES_ACTION } from  "../actions/types";

const initialState={
    categories: [], 
    loading: true
} 

const categoriesRed =(state=initialState,{type,payload})=>{
    switch(type){
        case CLAUSES_ACTION : return{
            ...state,
            categories : payload 
        }
        default: return state;  
    }
}
export default categoriesRed ;