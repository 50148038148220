import {
    BOOKING, BOOK_SHOW_LOADER, BOOK_HIDE_LOADER, INSPECTION, GATEINALLOWED, GATEIN, WEIGHMENTIN, WEIGHMENTOUT, DOCREADY, LOGISTICS, MATERIALS, ARRIVED, BOOKINGRECORD, TRUCKPAGINATION } from "../actions/types";

const initialState = {
    bookingListing: [],
    inspectionListing: [],
    gateinallowedlisting: [],
    gateInListing: [],
    weighmentInListing: [],
    weighmentOut: [],
    docready: [],
    logistics: [],
    materials: [],
    arrived: [],
    loading: false,
    //truck reporting
    bookmarks: [],
    records: '',
}

const bookingReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case BOOKING: return {
            ...state,
            bookingListing: payload
        }
        case INSPECTION: return {
            ...state,
            inspectionListing: payload
        }
        case GATEINALLOWED: return {
            ...state,
            gateinallowedlisting: payload
        }
        case GATEIN: return {
            ...state,
            gateInListing: payload
        }
        case WEIGHMENTIN: return {
            ...state,
            weighmentInListing: payload
        }
        case WEIGHMENTOUT: return {
            ...state,
            weighmentOut: payload
        }
        case DOCREADY: return {
            ...state,
            docready: payload
        }
        case LOGISTICS: return {
            ...state,
            logistics: payload
        }
        case MATERIALS: return {
            ...state,
            materials: payload
        }
        case ARRIVED: return {
            ...state,
            arrived: payload
        }
        // truck pagination
        case TRUCKPAGINATION:
            state.bookmarks.push(payload)
            console.log('state',state.bookmarks);
            return {
                ...state,
                bookmarks: state.bookmarks
            }
        case BOOKINGRECORD:
            return {
                ...state,
                records: payload
            }
        // Loader
        case BOOK_SHOW_LOADER:
            return {
                ...state,
                loader: true
            }
        case BOOK_HIDE_LOADER:
            return {
                loader: false
            }
        default: return state;
    }
}
export default bookingReducers 