import React, { useEffect, useState } from 'react';
import { get_pdf_thumbnail, base64Convert } from './../../services/functions'
import $ from 'jquery'
import FileUpload from './FileUpload'

const ViewDocuments = (props) => {

    const [newArray, setNewArray] = useState([])
    const [previewState, setPreviewState] = useState("")
    const [imgIndex, setimgIndex] = useState()
    const [togglediv, settogglediv] = useState("")
    const [showdiv, setshowdiv] = useState("")

    useEffect(() => {
        if (newArray.length !== 0) {
            settogglediv("ToggleViewDoc-modal-body")
            setshowdiv("toggleuploadDiv")
        }
        else if (newArray.length === 0) {
            settogglediv("ViewDoc-modal-body")
            setshowdiv("hide")
        }
    }, [newArray])

    const _handleFiles = async (files) => {
        if (files.length > 0) {
            let tempArr = [...newArray]
            let tarr = Array.from(files)
            for (let el of tarr) {
                if (el.type.includes('image') || el.type === "application/pdf") {
                    try {
                        let base64 = await base64Convert(el)
                        let obj = { base64, fileType: el.type.includes('image') ? 'image' : 'pdf', name: el.name }

                        if (obj.fileType === 'pdf') {
                            let pdf = base64.replace(/^data:application\/\pdf;base64,/, "")
                            var binary_string = window.atob(pdf);
                            var len = binary_string.length;
                            var bytes = new Uint8Array(len);
                            for (var i = 0; i < len; i++) {
                                bytes[i] = binary_string.charCodeAt(i);
                            }
                            var buffer = bytes.buffer
                            var file3 = new Blob([buffer], { type: 'application/pdf' });
                            obj['dataLocalUrl'] = file3
                            get_pdf_thumbnail(file3).then((url) => {
                                obj['imageSrc'] = url
                            })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                        tempArr.unshift(obj)
                    }
                    catch (err) {

                    }
                }
                else {
                }
            }
            setNewArray(tempArr)
        }
    }
    const previewImg = ({ item, index }) => {
        setPreviewState(item.base64)
        setimgIndex(index)
    }
    const DownloadImage = (index) => {
        var localArray = [...newArray]
        var a = document.createElement("a");
        a.href = localArray[index].base64
        a.download = localArray[index].name
        a.click();

    }
    const Download = () => {
        var localArray = [...newArray]
        var a = document.createElement("a");
        a.href = localArray[imgIndex].base64
        a.download = localArray[imgIndex].name
        a.click();
    }
    const removeFile = (index) => {
        let localArr = [...newArray]
        localArr.splice(index, 1)
        setNewArray(localArr)
    }
    const removeBase = () => {
        setPreviewState()
    }

    return (
        <>
            {previewState === '' || previewState === undefined ?
                <section id="viewDocumentsModal" className="custom-modal" style={{ width: "50%" }}>
                    <div className="custom-modal-header">
                        <h1>Upload Documents</h1>
                        <span className="ct-close" onClick={props.closeModal}></span>
                    </div>
                    <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '60vh' }}>
                        <div className="cust-row">
                            <div className="cust-row">
                                <div className='cust-col-10'>
                                    <FileUpload handleFiles={_handleFiles} />
                                </div>
                            </div>
                        </div>
                        <div className='vd_cont_3_sec_bdy'>
                            {newArray.length ?
                                <div className='file_preview'>
                                    <p>Uploaded Files</p>
                                    <div className='file_preview_cont'>
                                        {newArray.map((item, index) => {
                                            return (
                                                <div key={index} className='pictureDisplay'>
                                                    <div className='closeTab'>
                                                        <i className='fa fa-close' onClick={(e) => removeFile(index)}></i>
                                                    </div>
                                                    <div className='picturePreview' >
                                                        <img alt="" src={item.base64} onClick={() => previewImg({ item, index })} />
                                                    </div>
                                                    <div className='pictureName'>
                                                        <span>{item.name}</span>
                                                    </div>
                                                    <div className='fullscreen med-size'>
                                                        <i className='fa fa-expand' onClick={() => previewImg({ item, index })}></i>
                                                    </div>
                                                    <div className='downloadDocBtn'>
                                                        <i className="fa fa-download" onClick={() => DownloadImage(index)} ></i>
                                                    </div>
                                                </div>

                                            )
                                        })}
                                    </div>
                                </div>
                                : ''}

                        </div>
                        <div className='btn_cont post-btm'>
                            <button className='button bti bg-orange-out m-0 btn-size'>
                                <div className='btn_icon'>
                                    <i className='fa fa-upload'></i>
                                </div>
                                <div className='btn_txt font-lg'>Upload Now</div>
                            </button>
                        </div>
                    </div>
                </section>
                :
                <section id="viewDocumentsModal" className="custom-modal" style={{ width: "40%" }}>
                    <div className="custom-modal-header">
                        <h1>Upload Documents</h1>
                        <span className="ct-close" onClick={() => { removeBase() }}></span>
                    </div>
                    <div className="custom-modal-body setWidtHgt" style={{ overflowX: 'auto', maxHeight: '60vh' }}>
                        <div className='imgContainer setHgt'>
                            <img src={previewState} alt='' />
                        </div>
                        <div className='downloadContractdoc'>
                            <button onClick={() => Download()}>Download</button>
                        </div>
                    </div>
                </section>
            }

        </>

    )

}

export default ViewDocuments;