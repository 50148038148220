import { post } from './../../../services/requests'
import { ALLOCATE_LISTING , META_DATA } from './types'

const pageSize = 5
export const allocatelists = (data) =>{ 
    return(dispatch)=>{
        post('booking/get-allocated-vehicle',{pageSize: pageSize, bookmark: '' , shipmentGID :data})
        .then(response=>{
            //  console.log('allocate listing response',response);
             const allocateData= response.data
             const metaData =response.metaData
             if(allocateData){
                dispatch(storeAllocation(allocateData))
                dispatch(storeMeta(metaData))
             }
             else{
                console.log('allocateData',allocateData);
             }
             
             
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const storeAllocation = (allocateData) => {
    return {
        type: ALLOCATE_LISTING,
        payload: allocateData
    }
}
export const storeMeta = (metaData) => {
    return {
        type: META_DATA,
        payload: metaData
    }
}