import React, { useState } from 'react';
import $ from 'jquery'
// import AllocateOnMapModal from './../Modal/AllocateOnMap'

const UserPlantView = (props) => {
    const details = props.plantDetail

    const _handleClick = (id) => {
        setTimeout(() => {
            $(".custom-modal-overlay").addClass("custom-modal-is-visible")
            $(`#${id}`).addClass("custom-modal-is-visible")
        }, [500])
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#UserPlantView").removeClass("custom-modal-is-visible")
    }
    return (
        <section id="UserPlantView" className="custom-modal" style={{ width: '50%' }}>
            <div className="custom-modal-header"> 
                <h1>Plant Details</h1>
                <span className="ct-close" onClick={() => _closeModal("UserPlantView")}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                <div className="cust-row">
                    <div className='viewElem'>
                        <div className='viewRow'>
                            <div className='viewR1'>
                                <span className='plantlabel'>Plant Name </span>
                                <span className='plantspan'>{details && details.PlantName ? details.PlantName  : ''}</span>
                            </div>
                            <div className='viewR2'>
                            <span className='plantlabel'>GSTIN </span>
                                {details && details.GSTIN ? details.GSTIN  : ''}
                            </div>
                        </div>
                        <div className='viewRow'>
                            <div className='viewR3'>
                                <span className='plantlabel'>Address </span>
                                {details && details.Address ? details.Address  : ''}
                                <button className='locateMapNew' >Locate on Map</button>
                            </div>
                        </div>
                        <div className='viewRow'>
                            <div className='viewR4'>
                            <span className='plantlabel'>State </span>
                                {details && details.State ? details.State  : ''}
                            </div>
                            <div className='viewR4'>
                                <span className='plantlabel'>City </span>
                                {details && details.City ? details.City  : ''}
                            </div>
                            <div className='viewR4'>
                            <span className='plantlabel'>Pincode </span>
                                {details && details.PinCode ? details.PinCode  : ''}
                            </div>
                        </div>
                        <div className='viewRow'>

                        </div>
                    </div>
                </div>
                {/* <AllocateOnMapModal closeModal={_closeModal} /> */}
            </div>
        </section>
    )

}

export default UserPlantView;