import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import axios from 'axios';

const BookDetail = (props) => {
    const [base, setBase] = useState()
    const key = props.base

    const _closeModal = () => {
        setBase()
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    useEffect(() => {
        if (key !== '') {
            const token = localStorage.getItem('secretkey')
            const config = {
                headers: { Authorization: token }
            };
            axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                .then(response => {
                    console.log('base64 response', response);
                    const storeBase = response.data.file
                    setBase(storeBase)

                })
                .catch(error => {
                    // dispatch(showError(err))
                })
        }
    }, [key])

    return (
        <section id="BookDeatilModal" className="custom-modal" style={{ width: '55%' }}>
            <div className="custom-modal-header">
                <h1>Uploaded Document</h1>
                <span className="ct-close" onClick={() => _closeModal("BookDeatilModal")}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto' }}>
                <div className="cust-row centerImage">
                    {base !== undefined ?
                        <img className='classicImg' src={base} alt='' />
                        :
                        <div className='noImgCont'>
                        <span>No Image Found</span>
                        </div>
                    }
                </div>
            </div>
        </section>
    )

}

export default BookDetail;