import { REGISTER_HUB } from "../actions/types";

const initialState={
   hubData:[]
} 

const registerHubReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case REGISTER_HUB: return{
            ...state,
            hubData:payload
        }
        default: return state;  
    }
}
export default registerHubReducer 