import React, { Component } from "react";
import { Link } from "react-router-dom";
import history from "./../../services/history";
import $ from "jquery";
//InActive
import DashboardIcon from "./../../static/images/icon/sidebar/updated/dashboard.svg";
import BookingIcon from "./../../static/images/icon/sidebar/updated/booking.svg";
import VehicleIcon from "./../../static/images/icon/sidebar/updated/vehicles.svg";
import ReceiptIcon from "./../../static/images/icon/sidebar/updated/goods_receipt.svg";
import AddressIcon from "./../../static/images/icon/sidebar/updated/address_book.svg";
import HubIcon from "./../../static/images/icon/sidebar/updated/hubs.svg";
import DriverIcon from "./../../static/images/icon/sidebar/updated/driver.svg";
import PoDIcon from "./../../static/images/icon/sidebar/updated/pod.svg";
import AnalyticIcon from "./../../static/images/icon/sidebar/updated/analytics.svg";
import ContractIcon from "./../../static/images/icon/sidebar/updated/contracts.svg";
import plantsInactive from "./../../static/images/icon/sidebar/updated/plantsInactive.png";
import trip from "./../../static/images/icon/sidebar/updated/trip.png";

//Active
import DashboardActiveIcon from "./../../static/images/icon/sidebar/updated/dashboard_active.svg";
import BookingActiveIcon from "./../../static/images/icon/sidebar/updated/booking_active.svg";
import VehicleActiveIcon from "./../../static/images/icon/sidebar/updated/vehicles_active.svg";
import ReceiptActiveIcon from "./../../static/images/icon/sidebar/updated/goods_receipt_active.svg";
import AddressActiveIcon from "./../../static/images/icon/sidebar/updated/address_book_active.svg";
import HubActiveIcon from "./../../static/images/icon/sidebar/updated/hubs_active.svg";
import DriverActiveIcon from "./../../static/images/icon/sidebar/updated/driver_active.svg";
import PoDActiveIcon from "./../../static/images/icon/sidebar/updated/pod_active.svg";
import AnalyticActiveIcon from "./../../static/images/icon/sidebar/updated/analytics_active.svg";
import ContractActiveIcon from "./../../static/images/icon/sidebar/updated/contracts_active.svg";
import plantsActive from "./../../static/images/icon/sidebar/updated/plantsActive.png";
import tripactive from "./../../static/images/icon/sidebar/updated/tripactive.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${props.location.pathname}${props.location.search}${props.location.hash}`,
      userGroup:
        localStorage.getItem("userGroup") === "transporter" ? "2" : "1",
      icon: BookingIcon,
    };
  }
  componentDidMount() {
    this.unlisten = history.listen((location, action) => {
      this.setState({
        url: `${location.pathname}${location.search}${location.hash}`,
      });
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  toggle(index) {
    // if ($(".nav-parent").eq(index).hasClass("active")) {
    //     $(".nav-parent").eq(index).removeClass("active");
    //     console.log('1');
    //   } else {
    //     $(".nav-parent").eq(index).addClass("active");
    //     console.log('22');
    //   }
  }

  render() {
    return (
      <div className="sidebar open">
        <div className="tab-content">
          <div className="tab-pane" id="mainmenu">
            <ul className="menu-nav">
              <li className={this.state.url === "/dashboard" ? "active" : ""}>
                <Link to="/dashboard">
                  <img
                    className="sicon"
                    src={
                      this.state.url === "/dashboard"
                        ? DashboardActiveIcon
                        : DashboardIcon
                    }
                    alt=""
                  />
                  <span className="txt">Dashboard</span>
                </Link>
              </li>
              {localStorage.getItem("userGroup") === "goodsowner" ? (
                <li
                  className={
                    this.state.url === "/dashboard/plants" ? "active" : ""
                  }
                >
                  <Link to="/dashboard/plants">
                    <img
                      className="newSicon"
                      src={
                        this.state.url.includes("/dashboard/plants")
                          ? plantsActive
                          : plantsInactive
                      }
                    />
                    <span className="txt">Plants</span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              <li
                className={
                  this.state.url === "/dashboard/users" ? "active" : ""
                }
              >
                <Link to="/dashboard/users">
                  <i
                    className={
                      this.state.url.includes("/dashboard/users")
                        ? "fa fa-users white_color ssicon"
                        : "fa fa-users ssicon"
                    }
                  />
                  <span className="txt">Users</span>
                </Link>
              </li>
              <li
                className={
                  this.state.url.includes("/dashboard/booking")
                    ? "nav-parent active"
                    : "nav-parent"
                }
              >
                <Link
                  to="/dashboard/booking/pre-gate-in/truck-reporting-pending"
                  onClick={() => this.toggle(0)}
                >
                  <img
                    className="sicon"
                    src={
                      this.state.url.includes("/dashboard/booking")
                        ? BookingActiveIcon
                        : BookingIcon
                    }
                    alt=""
                  />
                  <span className="txt">Booking</span>
                </Link>
                <ul className="children">
                  <li
                    className={
                      this.state.url.includes("/dashboard/booking/pre-gate-in")
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/dashboard/booking/pre-gate-in/truck-reporting-pending">
                      Pre Gate-In
                    </Link>
                    <ul className="children1 no-triangle">
                      <li
                        className={
                          this.state.url.includes(
                            "/dashboard/booking/pre-gate-in/truck-reporting-pending"
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/booking/pre-gate-in/truck-reporting-pending">
                          Truck Reporting Pending
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.url.includes(
                            "/dashboard/booking/pre-gate-in/inspection-completed"
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/booking/pre-gate-in/inspection-completed">
                          Inspection Completed
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.url.includes(
                            "/dashboard/booking/pre-gate-in/gate-in-allowed"
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/booking/pre-gate-in/gate-in-allowed">
                          Requested for Gate In
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="children no-triangle">
                  <li
                    className={
                      this.state.url.includes("/dashboard/booking/gate-in")
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/dashboard/booking/gate-in/gated-in">
                      Gated-In
                    </Link>
                    <ul className="children1 no-triangle">
                      <li
                        className={
                          this.state.url.includes(
                            "/dashboard/booking/gate-in/gated-in"
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/booking/gate-in/gated-in">
                          Gated In
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.url.includes(
                            "/dashboard/booking/gate-in/weighment-in"
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/booking/gate-in/weighment-in">
                          Weighment In
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.url.includes(
                            "/dashboard/booking/gate-in/weighment-out"
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/booking/gate-in/weighment-out">
                          Weighment out
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="children no-triangle">
                  <li
                    className={
                      this.state.url.includes("/dashboard/booking/gate-out")
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/dashboard/booking/gate-out/document-ready">
                      Gate Out
                    </Link>
                    <ul className="children1 no-triangle">
                      <li
                        className={
                          this.state.url.includes(
                            "/dashboard/booking/gate-out/document-ready"
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/booking/gate-out/document-ready">
                          Document Ready
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.url.includes(
                            "/dashboard/booking/gate-out/logistics-gate-out"
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/booking/gate-out/logistics-gate-out">
                          Logistics Gate Out
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.url.includes(
                            "/dashboard/booking/gate-out/material-gate-out"
                          )
                            ? "active"
                            : ""
                        }
                      >
                        <Link to="/dashboard/booking/gate-out/material-gate-out">
                          Material Gate Out
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="children no-triangle">
                  <li
                    className={
                      this.state.url.includes(
                        "/dashboard/booking/arrived-at-destination"
                      )
                        ? "active"
                        : ""
                    }
                  >
                    <Link to="/dashboard/booking/arrived-at-destination">
                      Arrived at Destination
                    </Link>
                    <ul className="children1 no-triangle"></ul>
                  </li>
                </ul>
              </li>
              <li
                className={
                  this.state.url.includes("/dashboard/arrived") ? "active" : ""
                }
              >
                <Link to="/dashboard/arrived">
                  <img
                    className="sicon"
                    src={
                      this.state.url.includes("/dashboard/arrived")
                        ? PoDActiveIcon
                        : PoDIcon
                    }
                    alt=""
                  />
                  <span className="txt">Arrived</span>
                </Link>
              </li>
              <li
                className={
                  this.state.url.includes("/dashboard/delivery") ? "active" : ""
                }
              >
                <Link to="/dashboard/delivery">
                  <img
                    className="sicon"
                    src={
                      this.state.url.includes("/dashboard/delivery")
                        ? trip
                        : tripactive
                    }
                    alt=""
                  />
                  <span className="txt">Trip Completed</span>
                </Link>
              </li>
              <li
                className={
                  this.state.url.includes("/dashboard/address-book")
                    ? "active"
                    : ""
                }
              >
                <Link to="/dashboard/address-book">
                  <img
                    className="sicon"
                    src={
                      this.state.url.includes("/dashboard/address-book")
                        ? AddressActiveIcon
                        : AddressIcon
                    }
                    alt=""
                  />
                  <span className="txt">Address Book</span>
                </Link>
              </li>
              {localStorage.getItem("userGroup") !== "transporter" ? (
                <li
                  className={
                    this.state.url.includes("/dashboard/transporter-master")
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/dashboard/transporter-master">
                    <i
                      className={
                        this.state.url.includes("/dashboard/transporter-master")
                          ? "fa fa-user white_color ssicon"
                          : "fa fa-user ssicon"
                      }
                    />
                    <span className="txt">Transporter Master</span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              <li
                className={
                  this.state.url.includes("/dashboard/vehicle") ? "active" : ""
                }
              >
                <Link to="/dashboard/vehicle">
                  <img
                    className="sicon"
                    src={
                      this.state.url.includes("/dashboard/vehicle")
                        ? VehicleActiveIcon
                        : VehicleIcon
                    }
                    alt=""
                  />
                  <span className="txt">Vehicles</span>
                </Link>
              </li>
              {localStorage.getItem("userGroup") === "transporter" ? (
                <li
                  className={
                    this.state.url.includes("/dashboard/workforce")
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/dashboard/workforce">
                    <img
                      className="sicon"
                      src={
                        this.state.url.includes("/dashboard/workforce")
                          ? DriverActiveIcon
                          : DriverIcon
                      }
                      alt=""
                    />
                    <span className="txt">Workforce</span>
                  </Link>
                </li>
              ) : (
                <li
                  className={
                    this.state.url.includes("/dashboard/driver") ? "active" : ""
                  }
                >
                  <Link to="/dashboard/driver">
                    <img
                      className="sicon"
                      src={
                        this.state.url.includes("/dashboard/driver")
                          ? DriverActiveIcon
                          : DriverIcon
                      }
                      alt=""
                    />
                    <span className="txt">Drivers</span>
                  </Link>
                </li>
              )}
              {localStorage.getItem("userGroup") === "transporter" ? (
                <li
                  className={
                    this.state.url.includes("/dashboard/hubs") ? "active" : ""
                  }
                >
                  <Link to="/dashboard/hubs">
                    <img
                      className="sicon"
                      src={
                        this.state.url.includes("/dashboard/hubs")
                          ? HubActiveIcon
                          : HubIcon
                      }
                      alt=""
                    />
                    <span className="txt">Hubs</span>
                  </Link>
                </li>
              ) : (
                ""
              )}
              <li
                className={
                  this.state.url.includes("/dashboard/analytics")
                    ? "active"
                    : ""
                }
              >
                <Link to="/dashboard/analytics">
                  <img
                    className="sicon"
                    src={
                      this.state.url.includes("/dashboard/analytics")
                        ? AnalyticActiveIcon
                        : AnalyticIcon
                    }
                    alt=""
                  />
                  <span className="txt">Analytics</span>
                </Link>
              </li>
              <li
                className={
                  this.state.url.includes("/dashboard/Contracts")
                    ? "active"
                    : ""
                }
              >
                <Link to="/dashboard/Contracts">
                  <img
                    className="sicon"
                    src={
                      this.state.url.includes("/dashboard/Contracts")
                        ? ContractActiveIcon
                        : ContractIcon
                    }
                    alt=""
                  />
                  <span className="txt">Contracts</span>
                </Link>
              </li>
              <ul className="menu-nav">
                <li
                  className={
                    this.state.url.includes("/dashboard/Invoices")
                      ? "nav-parent active"
                      : "nav-parent "
                  }
                >
                  <Link to="/dashboard/Invoices/draft">
                    <img
                      className="sicon"
                      src={
                        this.state.url.includes("/dashboard/Invoices")
                          ? ReceiptActiveIcon
                          : ReceiptIcon
                      }
                      alt=""
                    />
                    <span className="txt">Invoices</span>
                  </Link>
                  <ul className="children no-brdr">
                    <li
                      className={
                        this.state.url.includes("/dashboard/Invoices/draft")
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/dashboard/Invoices/draft">Draft</Link>
                    </li>
                    <li
                      className={
                        this.state.url.includes(
                          "/dashboard/Invoices/correction"
                        )
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/dashboard/Invoices/correction">
                        Correction Needed
                      </Link>
                    </li>
                    <li
                      className={
                        this.state.url.includes("/dashboard/Invoices/confirmed")
                          ? "active"
                          : ""
                      }
                    >
                      <Link to="/dashboard/Invoices/confirmed">Confirmed</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
