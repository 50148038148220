import { FETCH_USERS_SUCCESS } from "../actions/types";

const initialState={
   users:[]
} 

const userReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case FETCH_USERS_SUCCESS: return{
            ...state,
            users:payload
        }
        default: return state;  
    }
}
export default userReducer 