import React, { useEffect, useState, useRef } from "react";
import history from "./../../services/history";
import { selectInit } from "./../../services/functions";
import $ from "jquery";
import UploadContractCopyModal from "./../Dashboard/Modal/UploadContractCopy";
import Select from "react-select";
import ContractClauses from "./ContractClauses";
import {
  listingApi,
  getPlantsListing,
  getContractDetail,
  getClause,
} from "../../state/redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import DocumentModal from "./../Dashboard/Modal/DocumentModal";
import DocViewAddressModal from "./Modal/DocViewAddress";
import { resetBase } from "../../state/redux/actions/getImage";

const AddContract = (props) => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const getIndex = props.location.state.index;
  const contractData = useSelector(
    (state) => state.getContractDetailRed
  ).contractData;
  const imageData = useSelector((state) => state.getImgBase.imgBaseArr);
  const clauseLists = useSelector((state) => state.getClauseRed.getClause);
  const [btnState, setBtnState] = useState("transporter");
  const [sortedArr, setSortedArr] = useState([]);
  const [base, setBase] = useState("");
  const [attachedDocuments, setAttachedDocuments] = useState([]);

  const [dropdownVal, setDropdownVal] = useState([{ optionValue: "" }]);
  const [getUrlParam, setUrlParam] = useState("");
  const [dropState, setDropState] = useState("");
  const [plantState, setPlantState] = useState("");
  const [multiContract, setMultiContract] = useState([]);
  const [fileArr, setFileArr] = useState([]);
  const allData = props.location.state;

  const transportersList = useSelector(
    (state) => state.getListingReducer.transporterListing
  );
  const plantlisting = useSelector(
    (state) => state.getPlantsListReducers.plantsData
  );

  const [transporterState, setTransState] = useState({
    transporterId: "",
    transName: "",
  });
  const [plantObject, setPlantObject] = useState({
    plantId: "",
    plantName: "",
  });
  const [contractState, setContractState] = useState({
    transporterId: "",
    plantId: "",
    contractName: "",
    contractId: "",
    contractType: "",
    startDate: "",
    endDate: "",
    documents: [],
  });

  const options =
    transportersList &&
    transportersList.map(function (item) {
      return {
        label: item.OrgName,
        value: item.OrgName,
        transporterId: item._id,
      };
    });
  const plantOptions =
    plantlisting &&
    plantlisting.map(function (item) {
      return {
        label: item.PlantName,
        value: item.PlantName,
        id: item._id,
      };
    });

  const _onSelect = (item) => {
    setDropState(item.value);
    contractState.transporterId = item.transporterId;
    let transDetails = { ...transporterState };
    transDetails.transporterId = item.label;
    transDetails.transName = item.transporterId;
    setTransState(transDetails);
  };
  const _onPlantSelect = (item) => {
    setPlantState(item.value);
    contractState.plantId = item.id;
    let plantObj = { ...plantObject };
    plantObj.plantName = item.label;
    plantObj.plantId = item.id;
    setPlantObject(plantObj);
  };
  const _handleChange = (e) => {
    setContractState({ ...contractState, [e.target.name]: e.target.value });
  };
  const dropDownMenu = ({ index, value }) => {
    let newArray = [...dropdownVal];
    if (value[0] === undefined) {
      newArray[index].optionValue = "select";
      setDropdownVal(newArray);
    } else {
      newArray[index].optionValue = value[0].value;
      setDropdownVal(newArray);
    }
  };
  const slideFun = (item) => {
    setBtnState(item);
  };
  const _goBack = () => {
    history.push("/dashboard/Contracts");
    clauseLists.length = 0;
  };
  const _handleClick = (id) => {
    $(".custom-modal-overlay").addClass("custom-modal-is-visible");
    $(`#${id}`).addClass("custom-modal-is-visible");
  };
  const _closeModal = () => {
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible");
    $(".custom-modal").removeClass("custom-modal-is-visible");
  };
  const expandElement = () => {
    if (
      $(".addContractDiv2").hasClass("hide-me") &&
      $(".flex-mid").hasClass("open")
    ) {
      $(".addContractDiv2").removeClass("hide-me");
      $(".addContractDiv2").removeClass("hidden");
      $(".addContractDiv2").addClass("open");
      $(".flex-mid").removeClass("open");
      $(".flex-mid").addClass("hide");
    } else {
      $(".addContractDiv2").addClass("hide-me");
      $(".addContractDiv2").addClass("hide-me");
      $(".flex-mid").removeClass("hide");
      $(".flex-mid").addClass("open");
    }
  };
  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: "smooth" });
  useEffect(() => {
    setTimeout(() => {
      selectInit(dropDownMenu);
    }, 100);
    if (allData !== undefined) {
      setUrlParam(allData.state);
    }
    dispatch(
      listingApi({
        docType: "transporter",
        bookmark: "",
        param: "next",
      })
    );
    dispatch(
      getPlantsListing({
        docType: "plants",
        bookmark: "",
        param: "next",
      })
    );
    dispatch(resetBase());
    dispatch(getContractDetail(getIndex));
  }, [dispatch]);

  useEffect(() => {
    if (clauseLists && clauseLists.length === 0 && getIndex !== "") {
      dispatch(getClause(getIndex));
    } else {
      sortValues();
    }
  }, [clauseLists]);

  const sortValues = () => {
    const unique = [
      ...new Set(clauseLists && clauseLists.map((item) => item.Category)),
    ];
    let newlist = [];
    unique.forEach((element) => {
      let tmp = [];
      let tmps = clauseLists.map((item) => {
        if (item.Category == element) tmp.push(item);
        return;
      });
      newlist.push({
        Category: element,
        clause: tmp,
      });
    });
    setSortedArr(newlist);
  };
  const addPlant = () => {
    history.push("/dashboard/plants");
  };
  // }, [dropdownVal, getUrlParam, dispatch])

  const addContract = () => {
    const token = localStorage.getItem("secretkey");
    const config = {
      headers: { Authorization: token },
    };
    if (contractState.transporterId === "" && contractState.plantId === "") {
      window.$.fn.show_notification(
        {
          message: `Transporter name and plant Name mandatory.`,
          title: "Alert!!",
          type: "confirm",
          autohide: false,
        },
        (result) => {
          if (result.type === "confirm") {
            _closeModal();
          }
        }
      );
    } else {
    }
    axios
      .post(
        "https://www.betaenterprise.lynkit.io/api/contract/addcontract",
        { ...contractState },
        { headers: config.headers }
      )
      .then((response) => {
        const error = response.data.error;
        const message = response.data.message;
        if (error === true) {
          window.$.fn.show_notification(
            {
              message: `${message}`,
              title: "Alert!!",
              type: "confirm",
              autohide: false,
            },
            (result) => {
              if (result.type === "confirm") {
                _closeModal();
              }
            }
          );
        } else {
          history.push("/dashboard/Contracts");
        }
      })
      .catch((error) => {
        // dispatch(showError(err))
      });
  };
  const append = () => {
    let tempArray = [...multiContract];
    tempArray.push({
      contactPerson: "",
      contactNumber: "",
      contactEmail: "",
    });
    dispatch(
      getPlantsListing({
        docType: "plants",
        bookmark: "",
        param: "next",
      })
    );
    setMultiContract(tempArray);
  };
  const removeContractDetail = (index) => {
    let tempArray = [...multiContract];
    tempArray.splice(index, 1);
    setMultiContract(tempArray);
  };
  const viewEdit = (item) => {
    history.push({
      pathname: "/dashboard/Contracts/Create-Formula",
      state: item,
    });
  };
  const colourStyles = {
    menuList: (styles) => ({
      ...styles,
      background: "white",
      width: "100%",
    }),
    control: (styles, state) => {
      return {
        ...styles,
        backgroundColor: "transparent",

        boxShadow: state.isFocused
          ? "0 0 3px #28a745"
          : state.isFocused
          ? "0 0 3px #dc3545"
          : "none",
        cursor: "pointer",
        ":focus": {
          ...styles[":focus"],
          //   borderColor: "var(--clr--accent)",
          // boxShadow: "0 0 3px var(--clr--accent)",
        },
        ":active": {
          ...styles[":active"],
          //   borderColor: "var(--clr--accent)",
          // boxShadow: "0 0 3px var(--clr--accent)",
        },

        ":hover": {
          ...styles[":hover"],
          // borderColor: "var(--clr--accent)",
          // boxShadow: "0 0 3px var(--clr--accent)",
        },
        height: 35,
        minHeight: 35,
      };
    },
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      background: isFocused ? "#ff7200" : isSelected ? "#fff" : undefined,
      color: isFocused ? "#fff" : "black",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "var(--clr--accent)"
            : undefined
          : undefined,
      },
      zIndex: 1,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      margin: "-8px 0 0 0",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
    }),
  };

  const uploadFrontImage = async (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    const base = await convertBase64(file);
    let temp = [...fileArr];
    temp.push({ base64: base, name: fileName });
    setFileArr(temp);
    contractState.documents.push(base);
  };
  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        }, 400);
      });
    } else {
    }
  };
  const deleteFile = (index) => {
    let tempArr = [...fileArr];
    tempArr.splice(index, 1);
    setFileArr(tempArr);

    let dummy = { ...contractState };
    dummy.documents.splice(index, 1);
    setContractState(dummy);
  };
  const _sendData = (index) => {
    var newArray = [...attachedDocuments];
    setBase(newArray[index]);
  };
  const DownloadImage = (index) => {
    var newArray = [...fileArr];
    var a = document.createElement("a");
    a.href = newArray[index].base64;
    a.download = newArray[index].name;
    a.click();
  };
  useEffect(() => {
    if (imageData.length !== 0) {
      setAttachedDocuments([]);
      let temp = [...imageData];
      setAttachedDocuments(temp);
    }
  }, [imageData]);
  return (
    <>
      <div className="contentpanel removescroll">
        <div className="cust-container">
          <div className="cust-row flex-algn-cent">
            <div className="cust-col-4">
              {getUrlParam === "clause" ? (
                <h1 className="title">
                  <button
                    className="button bg-orange mr-2 wd10 flex-inline"
                    onClick={() => _goBack()}
                  >
                    <div className="btn_txt font-lg">
                      <i className="fa fa-arrow-left"></i>
                    </div>
                  </button>
                  Add Contract
                </h1>
              ) : (
                <h1 className="title">
                  <button
                    className="button bg-orange mr-2 wd10 flex-inline"
                    onClick={() => _goBack()}
                  >
                    <div className="btn_txt font-lg">
                      <i className="fa fa-arrow-left"></i>
                    </div>
                  </button>
                  Add Clauses
                </h1>
              )}
            </div>
            {/* {getUrlParam === 'clause' ?
                            <div className='cust-col-6 flex-jc-end'>
                                <button className='button bg-orange-out mr-2 wd10' onClick={() => { _handleClick('UploadContractCopyModal') }}>
                                    <div className='btn_txt font-lg'><i className='fa fa-upload'></i></div>
                                    <div className="titleBox">
                                        <span>Upload Contract Copy </span>
                                    </div>
                                </button>
                            </div>
                            : ''} */}
          </div>
          <hr className="bgg" />
          <div className="cust-row">
            <div className="cust-col-10">
              {getUrlParam === "clause" ? (
                <div className="addContractDiv1">
                  <div className="ContractDetails1">
                    <div className="addContract-I">
                      <div className="contract-in">
                        <div className="contract-span">
                          <span className="ct-span">Transporter Name :</span>
                        </div>
                        <div className="contract-input wd_100">
                          <Select
                            onChange={(value) => _onSelect(value)}
                            options={options}
                            styles={colourStyles}
                          />
                        </div>
                      </div>

                      <div className="contract-in">
                        <div className="contract-span">
                          <span className="ct-span">Plant Name :</span>
                        </div>
                        <div className="contract-input">
                          <Select
                            onChange={(value) => _onPlantSelect(value)}
                            options={plantOptions}
                            styles={colourStyles}
                          />
                        </div>
                      </div>
                      <div
                        className="contract-in"
                        style={{ position: "relative", bottom: "5px" }}
                      >
                        <button className="addPlant" onClick={addPlant}>
                          Add New Plant
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="ContractDetails1">
                    <div className="addContract-I">
                      <div className="contract-in">
                        <div className="contract-span">
                          <span className="ct-span">Contract Name :</span>
                        </div>
                        <div className="contract-input">
                          <input
                            name="contractName"
                            value={contractState.contractName}
                            onChange={(e) => _handleChange(e)}
                            className="ct-input"
                            type="text"
                            placeholder="Enter Contract Name"
                          />
                        </div>
                      </div>
                      <div className="contract-in">
                        <div className="contract-span">
                          <span className="ct-span">Contract ID :</span>
                        </div>
                        <div className="contract-input">
                          <input
                            className="ct-input"
                            name="contractId"
                            value={contractState.contractId}
                            onChange={(e) => _handleChange(e)}
                            type="text"
                            placeholder="Enter Contract ID"
                          />
                        </div>
                      </div>
                      <div className="contract-in">
                        <div className="contract-span">
                          <span className="ct-span">Contract Type :</span>
                        </div>
                        <div className="contract-input">
                          <input
                            className="ct-input"
                            type="text"
                            name="contractType"
                            value={contractState.contractType}
                            onChange={(e) => _handleChange(e)}
                            placeholder="Enter Contract Type"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="addContract-I">
                      <div className="contract-in">
                        <div className="contract-span">
                          <span className="ct-span">Start Date :</span>
                        </div>
                        <div className="contract-input">
                          <input
                            className="ct-input"
                            name="startDate"
                            value={contractState.startDate}
                            onChange={(e) => _handleChange(e)}
                            type="date"
                            placeholder="Select Date"
                          />
                          {/* <i className="fa fa-calendar calenderInput" ></i> */}
                        </div>
                      </div>
                      <div className="contract-in">
                        <div className="contract-span">
                          <span className="ct-span">End Date :</span>
                        </div>
                        <div className="contract-input">
                          <input
                            className="ct-input"
                            name="endDate"
                            value={contractState.endDate}
                            onChange={(e) => _handleChange(e)}
                            type="date"
                            placeholder="Enter Contract Type"
                          />
                          {/* <i className="fa fa-calendar calenderInput" ></i> */}
                        </div>
                      </div>
                      <div className="contract-in"></div>
                    </div>
                  </div>
                  <div className="contractUpload">
                    <div className="contractUploadHeading">
                      <span>Upload Contract Copy</span>
                      <button className="contractUpBtn">
                        <i
                          className="fa fa-upload"
                          style={{ marginRight: "13px" }}
                        ></i>
                        Upload
                        <input
                          className="contractUpInp"
                          onChange={(e) => uploadFrontImage(e)}
                          type="file"
                        />
                      </button>
                    </div>

                    <div className="uploadedDocs">
                      {fileArr && fileArr.length ? (
                        <div className="file_preview">
                          <p>Uploaded Files</p>
                          <div className="file_preview_cont">
                            {fileArr &&
                              fileArr.map((item, index) => {
                                return (
                                  <div
                                    key={`pp-` + index}
                                    className="preview_itm bttmm-mg"
                                    style={{ width: "11%" }}
                                  >
                                    <div
                                      className="closeb"
                                      onClick={() => {
                                        deleteFile(index);
                                      }}
                                    >
                                      <i className="fa fa-close"></i>
                                    </div>
                                    <img
                                      src={item.base64}
                                      alt=""
                                      onClick={() => {
                                        _handleClick("DocViewAddressModal");
                                        _sendData("contract", item);
                                      }}
                                    />
                                    <div className="fullscreen">
                                      <i
                                        className="fa fa-expand"
                                        onClick={() => {
                                          _handleClick("DocViewAddressModal");
                                          _sendData("contract", item);
                                        }}
                                      ></i>
                                    </div>
                                    <div className="downloadDocBtn">
                                      <i
                                        className="fa fa-download"
                                        onClick={() => DownloadImage(index)}
                                      ></i>
                                    </div>
                                    <span className="overSpanName">
                                      {item.name}
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="newBtnElem">
                    <div className="newContElem">
                      <div
                        className="addContract-I flex-mid open"
                        style={{ width: "auto" }}
                      >
                        <button
                          className="addClauseBtn"
                          style={{ width: "130px" }}
                          onClick={expandElement}
                        >
                          {" "}
                          Add Clauses
                        </button>
                      </div>
                      <button
                        className="addClauseBtn rightMg"
                        onClick={addContract}
                      >
                        Add Contract
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="contract-div-1">
                  <div className="transporterPlant">
                    <div className="transporterPlantInner">
                      <div className="togglabelDiv">
                        <div className="togglebTns inches">
                          <button
                            className={
                              btnState === "transporter"
                                ? "activeTPBtn"
                                : "transporterBtn"
                            }
                            onClick={() => slideFun("transporter")}
                          >
                            TRANSPORTER'S DETAIL
                          </button>
                          <button
                            className={
                              btnState === "plant" ? "activeTPBtn" : "plantBtn"
                            }
                            onClick={() => slideFun("plant")}
                          >
                            PLANT'S DETAIL
                          </button>
                        </div>
                      </div>
                      {btnState === "transporter" ? (
                        <div className="ContractSection newColorContract">
                          <div className="contract-details newHeight">
                            <div className="contract-inner">
                              <div className="contract-data1">
                                <span className="contractDetailSpan3">
                                  Transporter Name :
                                </span>
                              </div>
                              <div className="contract-data2">
                                <span className="contractDetailSpan4">
                                  {contractData !== undefined
                                    ? contractData.OrgName
                                    : ""}
                                </span>
                              </div>
                            </div>
                            <div className="contract-inner">
                              <div className="contract-data1">
                                <span className="contractDetailSpan3">
                                  Transporter Address :
                                </span>
                              </div>
                              <div className="contract-data2">
                                <span className="contractDetailSpan4">
                                  {contractData !== undefined
                                    ? contractData.TransporterAddress
                                    : ""}
                                </span>
                              </div>
                            </div>
                            <div className="contract-inner">
                              {/* <div className='contract-data1'>
                                                            <span className='contractDetailSpan3'>Transporter Address :</span>
                                                        </div>
                                                        <div className='contract-data2'>
                                                            <span className='contractDetailSpan4'>{contractData.TransporterAddress}</span>
                                                        </div> */}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {btnState === "plant" ? (
                        <div className="ContractSection newColorContract">
                          <div className="contract-details newHeight">
                            <div className="contract-inner">
                              <div className="contract-data1">
                                <span className="contractDetailSpan3">
                                  Plant Name :
                                </span>
                              </div>
                              <div className="contract-data2">
                                <span className="contractDetailSpan4">
                                  {contractData !== undefined
                                    ? contractData.PlantName
                                    : ""}
                                </span>
                              </div>
                            </div>
                            <div className="contract-inner">
                              <div className="contract-data1">
                                <span className="contractDetailSpan3">
                                  Plant Address :
                                </span>
                              </div>
                              <div className="contract-data2">
                                <span className="contractDetailSpan4">
                                  {contractData !== undefined
                                    ? contractData.PlantAddress
                                    : ""}
                                </span>
                              </div>
                            </div>
                            <div className="contract-inner"></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="togglabelDiv">
                    <div className="togglebTns reduceWidth">
                      <button className="contracBtn">CONTRACT DETAIL</button>
                    </div>
                  </div>

                  <div className="ContractSection btmBorder">
                    <div className="contract-details">
                      <div className="contract-inner">
                        <div className="contract-data1">
                          <span className="contractDetailSpan1">
                            Contract Name :
                          </span>
                        </div>
                        <div className="contract-data2">
                          <span className="contractDetailSpan2">
                            {contractData !== undefined
                              ? contractData.ContractName
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="contract-inner">
                        <div className="contract-data1">
                          <span className="contractDetailSpan1">
                            Contract ID :
                          </span>
                        </div>
                        <div className="contract-data2">
                          <span className="contractDetailSpan2">
                            {contractData !== undefined
                              ? contractData.ContractId
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="contract-inner">
                        <div className="contract-data1">
                          <span className="contractDetailSpan1">
                            Contract Type :
                          </span>
                        </div>
                        <div className="contract-data2">
                          <span className="contractDetailSpan2">
                            {contractData !== undefined
                              ? contractData.ContractType
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="contract-details"
                      style={{ borderBottom: "none" }}
                    >
                      <div className="contract-inner">
                        <div className="contract-data1">
                          <span className="contractDetailSpan1">
                            Start Date :
                          </span>
                        </div>
                        <div className="contract-data2">
                          <span className="contractDetailSpan2">
                            {contractData !== undefined
                              ? moment(contractData.StartDate).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="contract-inner">
                        <div className="contract-data1">
                          <span className="contractDetailSpan1">
                            End Date :
                          </span>
                        </div>
                        <div className="contract-data2">
                          <span className="contractDetailSpan2">
                            {contractData !== undefined
                              ? moment(contractData.EndDate).format(
                                  "DD/MM/YYYY"
                                )
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="contract-inner"></div>
                    </div>
                    <div className="uploadDiv" style={{ width: "100%" }}>
                      <div className="contHeaddd">
                        <span>Uploaded Files :</span>

                        {attachedDocuments.length === 0 ? (
                          <span className="noImgText">
                            No Files uploaded...
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {attachedDocuments &&
                        attachedDocuments.map((item, index) => {
                          return (
                            <div key={index} className="pictureDisplay">
                              <div className="closeTab">
                                {/* <i className='fa fa-close' onClick={() => deleteFile(index)}></i> */}
                              </div>
                              <div
                                className="picturePreview"
                                onClick={() => {
                                  _handleClick("DocumentModal");
                                  _sendData(index);
                                }}
                              >
                                <img alt="" src={item} />
                              </div>
                              <div className="fullscreen med-size">
                                <i
                                  className="fa fa-expand"
                                  onClick={() => {
                                    _handleClick("DocumentModal");
                                    _sendData(index);
                                  }}
                                ></i>
                              </div>
                              <div className="downloadDocBtn">
                                <i
                                  className="fa fa-download"
                                  onClick={() => DownloadImage(index)}
                                ></i>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="addContract-I flex-mid open">
                    <button
                      className="button wd20 bti bg-grey-out m-0 index"
                      onClick={() => {
                        expandElement();
                        executeScroll();
                      }}
                    >
                      <div className="button_text">Add Contract Clauses</div>
                      <div className="button_icon">
                        <i className="fa fa-plus font-xl"></i>
                      </div>
                    </button>
                  </div>
                </div>
              )}

              <div className="contract-div-2">
                <div className="contractClauseHead justifyEnd">
                  <span>Contract Clauses</span>
                </div>

                <div className="contractClauseBody">
                  {sortedArr.length !== 0 ? (
                    sortedArr &&
                    sortedArr.map((item, index) => {
                      return (
                        <div key={index} className="contractClauseCat1">
                          <div className="catDiv">
                            <span className="catspan">
                              Category :{" "}
                              <span className="catOrangeSpan">
                                {" "}
                                {item.Category}{" "}
                              </span>
                            </span>
                          </div>
                          {item &&
                            item.clause.map((a, b) => {
                              return (
                                <div
                                  key={b}
                                  className="catDivBody contractClauseItem"
                                  onClick={() => viewEdit(a)}
                                >
                                  <div className="cat5">{b + 1}.</div>
                                  {a &&
                                    a.Clause.map((x, y) => {
                                      return x.field !== "checkbox" &&
                                        x.field !== "radio" ? (
                                        <div
                                          key={y}
                                          className={
                                            x.field === "text"
                                              ? "catClauses"
                                              : "catClausesGrey"
                                          }
                                        >
                                          {x.value} {x.unit}
                                        </div>
                                      ) : (
                                        x.values &&
                                          x.values.map((obj, ind) => {
                                            return obj.check === true ? (
                                              <div
                                                key={ind}
                                                className={
                                                  x.field === "text"
                                                    ? "catClauses"
                                                    : "catClausesGrey"
                                                }
                                              >
                                                <span className="   ">
                                                  {obj.label}
                                                </span>
                                              </div>
                                            ) : (
                                              ""
                                            );
                                          })
                                      );
                                    })}
                                  <div className="catIcon clauseIcon">
                                    {/* <button
                                      className="clauseEditBtn orangeBg"
                                      //   onClick={() => viewEdit(a)}
                                    >
                                      View / Edit
                                    </button> */}
                                    <button className="clauseEditBtn greyBg">
                                      <i className="fa fa-trash catDelete"></i>
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })
                  ) : (
                    <div className="notFound">
                      <span>No Clause found.</span>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="addContractDiv2 hidden hide-me"
                ref={myRef}
                style={{ behavior: "smooth", block: "start" }}
              >
                <ContractClauses
                  id={contractData !== undefined ? contractData._id : ""}
                />
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <UploadContractCopyModal
          closeModal={_closeModal}
          contractState={contractState}
        />
        <DocViewAddressModal closeModal={_closeModal} imageData={base} />
        <DocumentModal closeModal={_closeModal} imageData={{ base }} />
      </div>
    </>
  );
};

export default AddContract;
