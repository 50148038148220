import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import lessthan from "./../../static/images/icon/sidebar/signs/lessthan.png";
import greaterthan from "./../../static/images/icon/sidebar/signs/greaterthan.png";
import less from "./../../static/images/icon/sidebar/signs/less.png";
import greater from "./../../static/images/icon/sidebar/signs/greater.png";
import OperatorIndex from "./Operators/Index";
import { Draggable, Droppable } from "react-drag-and-drop";
import axios from "axios";
import history from "./../../services/history";
import { selectInit } from "./../../services/functions";
import { getCategories, getDataFields } from "../../state/redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { FaCheck, FaEdit, FaPlus, FaTimes } from "react-icons/fa";
import { nanoid } from "nanoid";
import "../../static/css/formula.css";
import GenericSelect from "../Utils/GenericSelect";

const Formula = (props) => {
  const dispatch = useDispatch();
  // const contractData = props.location.state;
  const [contractData, setContractData] = useState(props.location.state);
  const [renderItem, setRenderItem] = useState([]);

  // const [clauseFormula, setClauseForm] = useState({
  //   MinLimit: contractData.MinLimit,
  //   MaxLimit: contractData.MaxLimit,
  //   Category: contractData.Category,
  //   InspectionAPP: contractData.InspectionAPP,
  //   FreightContract: contractData.FreightContract,
  //   ClauseName: contractData.ClauseName,
  //   ContractId: contractData.ContractId,
  // });
  const [temp, setTemp] = useState({});
  const [storeValues, setStoreValues] = useState([]);
  const [dataIndex, setDataIndex] = useState();
  const categoriesList = useSelector((state) => state.categoriesRed.categories);
  const dataFieldsArr = useSelector((state) => state.fieldsRed.dataFields);

  const [customVars, setCustomVars] = useState([]);
  const bracketMapRef = useRef(new Map());
  // console.log(bracketMapRef.current);

  useEffect(() => {
    if (renderItem.length === 0) {
      bracketMapRef.current.set("formulaBracketsArr", []);
    }
  }, [renderItem]);
  useEffect(() => {
    customVars.forEach((cVar) => {
      if (cVar.value.length === 0) {
        bracketMapRef.current.set(cVar.variableKey, []);
      }
    });
  }, [customVars]);

  const handleContractDataChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta
      ? e.label
      : e.target.type === "checkbox"
      ? e.target.checked
      : e.target.value;
    // console.log({ name, value });
    setContractData((cd) => ({ ...cd, [name]: value }));
  };

  const onDrop = (data) => {
    if (data.item) {
      setRenderItem([
        ...renderItem,
        {
          type: "item",
          FieldValue: data.item,
          FieldAs: "variable",
          FieldType: "",
          color: null,
        },
      ]);
    } else if (data.formulafields) {
      setRenderItem([
        ...renderItem,
        {
          type: "formulafields",
          FieldValue: data.formulafields,
          FieldAs: "field",
          FieldType: dataFieldsArr[dataIndex].FieldType,
          color: null,
        },
      ]);
    } else if (data.customvar) {
      //we recieve a stringified array
      let cVar = JSON.parse(data.customvar);
      setRenderItem([
        ...renderItem,
        {
          type: "item",
          FieldValue: cVar,
          FieldAs: "customVariable",
          FieldType: "",
          color: null,
        },
      ]);
    } else {
      const key = Object.keys(data).find((x) => data[x]);
      setRenderItem([...renderItem, dropObject[key]]);
    }
  };

  const removeItem = (index) => {
    let dummyArray = [...storeValues];
    dummyArray.splice(index, 1);
    setStoreValues(dummyArray);
  };
  const addClause = () => {
    const Formula = renderItem;
    // const Clause = contractData.Clause;
    // const combineArray = { ...clauseFormula, Formula, Clause };
    const CustomVariables = customVars.map((cVar) => {
      return {
        value: cVar.value,
        variable: cVar.variable,
        variableKey: cVar.variableKey,
      };
    });
    const combineArray = { ...contractData, Formula, CustomVariables };

    const token = localStorage.getItem("secretkey");
    const config = {
      headers: { Authorization: token },
    };
    console.log("combibne", combineArray);

    // if (renderItem.length !== 0) {
    // axios
    //   .post(
    //     "https://www.betaenterprise.lynkit.io/api/contract/addclause",
    //     { ...combineArray },
    //     { headers: config.headers }
    //   )
    //   .then((response) => {
    //     const status = response.data.error;
    //     const message = response.data.message;
    //     if (status === false) {
    //       window.$.fn.show_notification(
    //         {
    //           message: `${message}`,
    //           title: "Success!!",
    //           autohide: true,
    //           confirmVal: "Ok",
    //           showInput: false,
    //         },
    //         (result) => {
    //           if (result.type === "confirm") {
    //           }
    //         }
    //       );

    //       history.push("/dashboard/Contracts");
    //       // history.push({
    //       //     state: { index: index },
    //       //     pathname: '/dashboard/Contracts/details'
    //       // })
    //     } else {
    //       window.$.fn.show_notification(
    //         {
    //           message: `${message}`,
    //           title: "Alert!!",
    //           autohide: true,
    //           confirmVal: "Ok",
    //           showInput: false,
    //         },
    //         (result) => {
    //           if (result.type === "confirm") {
    //           }
    //         }
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
    // }
    // else {
    //     window.$.fn.show_notification({ message: 'Create Formula to calculate..', title: 'Alert!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
    //         if (result.type === 'confirm') {
    //         }
    //     })
    // }
  };
  const goBack = () => {
    history.goBack();
  };
  const editText = (index) => {
    var bodytext = $(`#${index}`).text();
    temp.Clause[index].value = bodytext;
  };

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getDataFields());
    setTemp(contractData);
    if ("Formula" in contractData) {
      setRenderItem(contractData.Formula);
    }
  }, []);

  const colourStyles = {
    menuList: (styles) => ({
      ...styles,
      background: "white",
      width: "100%",
      height: "200px",
    }),
    control: (styles, state) => {
      return {
        ...styles,
        backgroundColor: "white",
        borderColor: "#ff7200",
        boxShadow: "none",
        cursor: "pointer",
        ":focus": {
          ...styles[":focus"],
          borderColor: "#ff7200",
          boxShadow: "none",
        },
        ":active": {
          ...styles[":active"],
          borderColor: "#ff7200",
          boxShadow: "none",
        },

        ":hover": {
          ...styles[":hover"],
          borderColor: "#ff7200",
          boxShadow: "none",
        },
        //   height: 35,
        //   minHeight: 35,
      };
    },
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      background: isDisabled
        ? "#727376"
        : isFocused
        ? "#ff7200"
        : isSelected
        ? "#fff"
        : undefined,
      color: isDisabled ? "#fff" : isFocused ? "#fff" : "black",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "var(--clr--accent)"
            : undefined
          : undefined,
        boxShadow: "none",
        border: "1px solid #ff7200",
      },

      cursor: isDisabled ? "not-allowed" : "default",
      zIndex: 1,
    }),
    //   menu: (base) => ({
    //     ...base,
    //     zIndex: 100,
    //   }),
    //   valueContainer: (provided, state) => ({
    //     ...provided,
    //     height: "35px",
    //     // margin: "-8px 0 0 0",
    //   }),

    //   input: (provided, state) => ({
    //     ...provided,
    //     margin: "0",
    //   }),
    //   indicatorSeparator: (state) => ({
    //     display: "none",
    //   }),
    //   indicatorsContainer: (provided, state) => ({
    //     ...provided,
    //     height: "35px",
    //   }),
  };

  const options = categoriesList.map((item) => ({
    value: item.OrgId,
    label: item.CategoryName,
  }));

  const fieldArray = dataFieldsArr.map((item, index) => ({
    value: index,
    label: item.ReadableName
      ? item.ReadableName
      : item.FieldName.replace(/([A-Z])/g, " $1")
          .charAt(0)
          .toUpperCase() + item.FieldName.replace(/([A-Z])/g, " $1").slice(1),
  }));

  const _handleFields = (item) => {
    setDataIndex(item.value);
    let dummyArray = [...storeValues];
    dummyArray.push(item.label);
    setStoreValues(dummyArray);
  };

  const _handleRemoveFormulaItem = (e, elemIndex, outerIndx) => {
    const elem = e.target.closest(".formulaItem");
    const elemClr = elem.style.color;
    let elemArr;
    if (elemClr) {
      highlightBrackets(elem, "remove");
      let isLeftBracket = true;

      if (outerIndx !== undefined) elemArr = [...customVars[outerIndx].value];
      else elemArr = [...renderItem];

      const key = elemArr[0].variableKey || "formulaBracketsArr";
      // console.log(elemArr);

      for (let i = elemIndex - 1; i >= 0; i--) {
        // console.log(key);
        if (elemArr[i].color === elemClr) {
          bracketMapRef.current.get(key).push(elemArr[i]);
          isLeftBracket = false;
          break;
        }
        // console.log(bracketMapRef.current.get(key));
      }
      if (isLeftBracket) {
        bracketMapRef.current.get(key).pop();
      }
    }

    if (outerIndx !== undefined) {
      setCustomVars((cvs) =>
        cvs.map((cv, indx) => {
          if (indx === outerIndx) {
            // console.log(cv);
            return {
              ...cv,
              value: cv.value.filter((item, idx) => idx !== elemIndex),
            };
          }
          return cv;
        })
      );
    } else {
      setRenderItem((rt) => rt.filter((_, ind) => ind !== elemIndex));
    }
  };

  // custom variables
  const _handleAddCustomVariable = () => {
    let newCustVar = {
      variable: `V${customVars.length + 1}`,
      variableKey: `V${customVars.length + 1}`,
      value: [],
      v_type: "drop",
    };
    if (!bracketMapRef.current.get(newCustVar.variableKey)) {
      bracketMapRef.current.set(newCustVar.variableKey, []);
    }
    setCustomVars((cv) => [...cv, newCustVar]);
  };

  const _dropCustomVar = (data, index) => {
    // console.log(data, index);

    if (data.item) {
      setCustomVars((cvs) =>
        cvs.map((cv, ind) => {
          if (ind === index) {
            let updated = {
              ...cv,
              value: [
                ...cv.value,
                {
                  type: "item",
                  FieldValue: data.item,
                  FieldAs: "variable",
                  FieldType: "",
                  color: null,
                  variableKey: cv.variableKey,
                },
              ],
            };

            return updated;
          }
          return cv;
        })
      );
    } else if (data.formulafields) {
      setCustomVars((cvs) =>
        cvs.map((cv, ind) => {
          if (ind === index) {
            let updated = {
              ...cv,
              value: [
                ...cv.value,
                {
                  type: "formulafields",
                  FieldValue: data.formulafields,
                  FieldAs: "field",
                  FieldType: dataFieldsArr[dataIndex].FieldType,
                  color: null,
                  variableKey: cv.variableKey,
                },
              ],
            };
            return updated;
          }
          return cv;
        })
      );
    } else {
      const key = Object.keys(data).find((x) => data[x]);
      setCustomVars((cvs) =>
        cvs.map((cv, ind) => {
          if (ind === index) {
            // console.log({ key, cv });
            let updated = {
              ...cv,
              value: [
                ...cv.value,
                { ...dropObject[key], variableKey: cv.variableKey },
              ],
            };
            return updated;
          }
          return cv;
        })
      );
    }
  };
  const _handleSaveCustomVar = (index) => {
    // console.log(index);
    setIsDrop(index, "drag");
  };
  const _handleEditCustomVar = (index) => {
    // console.log(index);
    setIsDrop(index, "drop");
  };

  const _handleCancelCustomVar = (index) => {
    bracketMapRef.current.set(customVars[index].variableKey, []);
    setCustomVars((cvs) => cvs.filter((cv, ind) => ind !== index));
  };

  function setIsDrop(index, type) {
    setCustomVars((cvs) =>
      cvs.map((cv, ind) => {
        if (ind === index) {
          // console.log(cv);
          return { ...cv, v_type: type };
        }
        return cv;
      })
    );
  }

  const _handleBracketPairColoring = (
    ele,
    index,
    bracArrId,
    isCustom,
    outerIndx
  ) => {
    const value = ele.FieldValue;
    const bracketsArr = bracketMapRef.current.get(bracArrId);

    // 1. if bracketsArr == empty && elem is CLOSING BRACKET -> show error toast
    if (bracketsArr.length === 0 && value === ")") {
      //show error notif
      window.$.fn.show_notification(
        {
          message: "You need to add an opening bracket first",
          title: "Error",
          autohide: true,
          confirmVal: "Ok",
          showInput: false,
        },
        () => {}
      );
      return -1; // do not render the elem
    }
    // 2. if elem is OPENING BRACKET
    if (value === "(") {
      //-> add a random color prop to the elem
      const randClr = randomColor();
      _setBracColor(index, randClr, isCustom, outerIndx);
      // -> push into bracket arr
      bracketsArr.push({ ...ele, color: randClr });
    }
    // 3. if elem is CLOSING BRACKET
    if (value === ")") {
      // get the last bracket from the bracket array (open bracket)
      const lastBrac = bracketsArr.pop();
      // console.log({ lastBrac });
      // set color prop of closing bracket to the color prop of the last popped elem from bracketsArr
      _setBracColor(index, lastBrac.color, isCustom, outerIndx);
    }

    // console.log(bracketsArr);
  };

  function _setBracColor(index, color, flag, outerIndx) {
    if (flag) {
      setCustomVars((cvs) =>
        cvs.map((cv, idx) => {
          if (idx === outerIndx) {
            return {
              ...cv,
              value: cv.value.map((item, indx) => {
                if (indx === index) {
                  return { ...item, color };
                }
                return item;
              }),
            };
          }
          return cv;
        })
      );
    } else {
      setRenderItem((rt) =>
        rt.map((item, idx) => {
          if (idx === index) {
            return { ...item, color };
          }
          return item;
        })
      );
    }
  }

  const _handleMouseEnter = (e) => {
    highlightBrackets(e.target.closest(".formulaItem"), "add");
  };
  const _handleMouseLeave = (e) => {
    highlightBrackets(e.target.closest(".formulaItem"), "remove");
  };

  function highlightBrackets(elem, opType) {
    const elemClr = elem.style.color;
    if (!elemClr) return;

    let elemKey = elem.dataset.key;
    let formulaArr = Array.from(
      document.querySelector(`.dropZone-${elemKey}`).children
    );
    // console.log(formulaArr);
    const pair = formulaArr.filter((curr) => curr.style.color === elemClr);
    if (pair.length === 2) {
      // console.log(pair);
      pair[0].classList[opType]("formulaHighlight");
      pair[1].classList[opType]("formulaHighlight");
    } else {
      // console.log(pair);
      pair[0].classList[opType]("formulaHighlight--err");
    }
  }

  // useEffect(() => {
  //   console.log({ customVars });
  // }, [customVars]);

  // const _handleChange = (e) => {
  //   if (e.target.name === "MinLimit") {
  //     setClauseForm({ ...clauseFormula, [e.target.name]: e.target.value });
  //   } else if (e.target.name === "MaxLimit") {
  //     setClauseForm({ ...clauseFormula, [e.target.name]: e.target.value });
  //   }
  // };
  // const _onChange = (e) => {
  //   setDataIndex(e.target.value);
  //   let dummyArray = [...storeValues];
  //   dummyArray.push(dataFieldsArr[e.target.value].FieldName);
  //   setStoreValues(dummyArray);
  // };
  // const _handleOnChange = (e) => {
  //   if (e.target.name === "ClauseName") {
  //     setTemp({ ...temp, [e.target.name]: e.target.value });
  //   }
  // };
  // const _handleDrop = (e) => {
  //   let values = { ...temp };
  //   if (e.target.name === "InspectionAPP") {
  //     if (temp.InspectionAPP === true || temp.InspectionAPP === "true") {
  //       values.InspectionAPP = false;
  //       setTemp(values);
  //     } else if (
  //       temp.InspectionAPP === false ||
  //       temp.InspectionAPP === "false"
  //     ) {
  //       values.InspectionAPP = true;
  //       setTemp(values);
  //     }
  //   } else if (e.target.name === "FreightContract") {
  //     if (temp.FreightContract === true || temp.FreightContract === "true") {
  //       values.FreightContract = false;
  //       setTemp(values);
  //     } else if (
  //       temp.FreightContract === false ||
  //       temp.FreightContract === "false"
  //     ) {
  //       values.FreightContract = true;
  //       setTemp(values);
  //     }
  //   }
  // };
  // const _handleDropChange = (item) => {
  //   let dummy = { ...temp };
  //   dummy.Category = item.label;
  //   setTemp(dummy);
  // };

  return (
    <>
      <div className="contentpanel removescroll">
        <div className="cust-container">
          <div className="cust-row flex-algn-cent">
            <div className="cust-col-4">
              <h1 className="title">
                <button
                  className="button bg-orange mr-2 wd10 flex-inline"
                  onClick={goBack}
                >
                  <div className="btn_txt font-lg">
                    <i className="fa fa-arrow-left"></i>
                  </div>
                </button>
                Create Formula
              </h1>
            </div>
          </div>
          <hr className="bgg" />
        </div>

        <div className="contractParent" style={{ background: "#fff" }}>
          <div className="contractData" style={{ width: "100%" }}>
            <div className="contractFieldsElem">
              <div
                className="contract40"
                style={{ justifyContent: "space-around" }}
              >
                <span className="contractSpan">Category :</span>
                <div className="contractDrop">
                  <GenericSelect
                    name="Category"
                    // onChange={(value) => _handleDropChange(value)}
                    onChange={handleContractDataChange}
                    options={options}
                    defaultValue={{
                      label: contractData.Category,
                      value: contractData.Category,
                    }}
                  />
                </div>
              </div>
              <div
                className="contract60"
                style={{ justifyContent: "space-around" }}
              >
                <div className="contract30 flexend">
                  <span className="contractSpan ">Clause Required for?</span>
                </div>
                <div className="contract30">
                  <input
                    type="checkbox"
                    name="InspectionAPP"
                    // value={temp.InspectionAPP}
                    // checked={
                    //   temp.InspectionAPP === true ||
                    //   temp.InspectionAPP === "true"
                    //     ? true
                    //     : false
                    // }
                    checked={
                      contractData.InspectionAPP === true ||
                      contractData.InspectionAPP === "true"
                        ? true
                        : false
                    }
                    // onChange={(e) => _handleDrop(e)}
                    onChange={handleContractDataChange}
                  />
                  <span className="contractSpanLight">Inspection App</span>
                </div>
                <div className="contract30">
                  <input
                    type="checkbox"
                    name="FreightContract"
                    // checked={
                    //   temp.FreightContract === true ||
                    //   temp.FreightContract === "true"
                    //     ? true
                    //     : false
                    // }
                    // onChange={(e) => _handleDrop(e)}
                    // value={temp.FreightContract}
                    checked={
                      contractData.FreightContract === true ||
                      contractData.FreightContract === "true"
                        ? true
                        : false
                    }
                    onChange={handleContractDataChange}
                  />
                  <span className="contractSpanLight">Freight Contracts</span>
                </div>
              </div>
            </div>
            <div className="contractFieldsElem">
              <div
                className="contract40"
                style={{ justifyContent: "space-around" }}
              >
                <span className="contractSpan">Contract Clause :</span>
                <div className="contractDrop">
                  <input
                    name="ClauseName"
                    // value={temp.ClauseName}
                    // onChange={_handleOnChange}
                    value={contractData.ClauseName}
                    onChange={handleContractDataChange}
                    className="contractInpNew"
                    type="text"
                    placeholder="Enter Clause Name"
                  />
                </div>
              </div>
              <div
                className="contract40"
                style={{ justifyContent: "space-around" }}
              >
                <span className="contractSpan">Status :</span>
                <div className="contractDrop">
                  <GenericSelect
                    options={[
                      { label: "select", value: "", isDisabled: true },
                      { label: "Active", value: "active" },
                      { label: "In-active", value: "inActive" },
                    ]}
                    value={{
                      label: contractData.Status,
                      value: contractData.Status,
                    }}
                    onChange={handleContractDataChange}
                    name="Status"
                  />
                </div>
              </div>
            </div>
            <div
              className="contractFieldsElem pdTop"
              style={{ background: "#F5F5F5" }}
            >
              <div
                className="contract100 previewDiv"
                style={{ display: "flex" }}
              >
                {temp && temp.Clause
                  ? temp.Clause.map((item, index) => {
                      return (
                        <div>
                          {/* <button className="editClause" onClick={editText}>Edit</button> */}
                          <span
                            onKeyUp={() => editText(index)}
                            contentEditable={true}
                            id={index}
                            key={index}
                            className={
                              item.field === "input" ||
                              item.field === "number" ||
                              item.field === "date" ||
                              item.field === "units" ||
                              item.field === "dropdown"
                                ? "colorOrangeText"
                                : ""
                            }
                          >
                            {item.value} {item.unit}
                          </span>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
            <div className="formula100">
              <div className="formula40">
                {/* {temp && temp.Clause
                  ? temp.Clause.map((item, index) => {
                      return item.field === "input" ? (
                        <div key={index} className="formula1001">
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}
                            </span>
                            <span className="formulaValue">{item.value}</span>
                          </div>
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}-Type
                            </span>
                            <span className="formulaValue">{item.unit}</span>
                          </div>
                        </div>
                      ) : item.field === "checkbox" ? (
                        <div key={index} className="formula1001">
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}
                            </span>
                            <span className="formulaValue">
                              {item.values.map((item, ind) => {
                                return ind === 0
                                  ? `${item.label}`
                                  : `/${item.label}`;
                              })}
                            </span>
                          </div>
                        </div>
                      ) : item.field === "radio" ? (
                        <div key={index} className="formula1001">
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}
                            </span>
                            <span className="formulaValue">
                              {item.values.map((item, ind) => {
                                return ind === 0
                                  ? `${item.label}`
                                  : `/${item.label}`;
                              })}
                            </span>
                          </div>
                        </div>
                      ) : item.field === "date" ? (
                        <div key={index} className="formula1001">
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}
                            </span>
                            <span className="formulaValue">{item.value}</span>
                          </div>
                        </div>
                      ) : item.field === "units" ? (
                        <div key={index} className="formula1001">
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}
                            </span>
                            <span className="formulaValue">{item.value}</span>
                          </div>
                        </div>
                      ) : item.field === "dropdown" ? (
                        <div key={index} className="formula1001">
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}
                            </span>
                            <span className="formulaValue">{item.value}</span>
                          </div>
                        </div>
                      ) : item.field === "number" ? (
                        <div key={index} className="formula1001">
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}
                            </span>
                            <span className="formulaValue">{item.value}</span>
                          </div>
                        </div>
                      ) : (
                        ""
                      );
                    })
                  : ""} */}
                {contractData && contractData.Clause
                  ? contractData.Clause.map((item, index) => {
                      return item.field === "input" ? (
                        <div key={index} className="formula1001">
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}
                            </span>
                            <span className="formulaValue">{item.value}</span>
                          </div>
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}-Type
                            </span>
                            <span className="formulaValue">{item.unit}</span>
                          </div>
                        </div>
                      ) : item.field === "checkbox" ||
                        item.field === "radio" ? (
                        <div key={index} className="formula1001">
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}
                            </span>
                            <span className="formulaValue">
                              {item.values.map((item, ind) => {
                                return ind === 0
                                  ? `${item.label}`
                                  : `/${item.label}`;
                              })}
                            </span>
                          </div>
                        </div>
                      ) : //  item.field === "radio" ? (
                      //   <div key={index} className="formula1001">
                      //     <div className="formula15">
                      //       <span className="formulaField">
                      //         {item.variable}
                      //       </span>
                      //       <span className="formulaValue">
                      //         {item.values.map((item, ind) => {
                      //           return ind === 0
                      //             ? `${item.label}`
                      //             : `/${item.label}`;
                      //         })}
                      //       </span>
                      //     </div>
                      //   </div>
                      // )
                      // ['date', 'units', 'dropdown', 'number']
                      ["date", "units", "dropdown", "number"].includes(
                          item.field
                        ) ? (
                        <div key={index} className="formula1001">
                          <div className="formula15">
                            <span className="formulaField">
                              {item.variable}
                            </span>
                            <span className="formulaValue">{item.value}</span>
                          </div>
                        </div>
                      ) : (
                        // item.field === "units" ? (
                        //   <div key={index} className="formula1001">
                        //     <div className="formula15">
                        //       <span className="formulaField">
                        //         {item.variable}
                        //       </span>
                        //       <span className="formulaValue">{item.value}</span>
                        //     </div>
                        //   </div>
                        // ) :
                        //  item.field === "dropdown" ? (
                        //   <div key={index} className="formula1001">
                        //     <div className="formula15">
                        //       <span className="formulaField">
                        //         {item.variable}
                        //       </span>
                        //       <span className="formulaValue">{item.value}</span>
                        //     </div>
                        //   </div>
                        // ) : item.field === "number" ? (
                        //   <div key={index} className="formula1001">
                        //     <div className="formula15">
                        //       <span className="formulaField">
                        //         {item.variable}
                        //       </span>
                        //       <span className="formulaValue">{item.value}</span>
                        //     </div>
                        //   </div>
                        // ) :
                        ""
                      );
                    })
                  : ""}

                <div className="limitFormula__wrapper">
                  <div className="limitFormula">
                    <span className="formulaField">Minimum Limit</span>
                    <input
                      name="MinLimit"
                      // value={clauseFormula.MinLimit}
                      // onChange={_handleChange}
                      value={contractData.MinLimit}
                      onChange={handleContractDataChange}
                      className="formulaInput"
                      type="number"
                      placeholder="Enter Limit"
                    />
                  </div>
                </div>
                <div className="limitFormula__wrapper">
                  <div className="limitFormula">
                    <span className="formulaField">Maximum Limit</span>
                    <input
                      name="MaxLimit"
                      // value={clauseFormula.MaxLimit}
                      // onChange={_handleChange}
                      value={contractData.MaxLimit}
                      onChange={handleContractDataChange}
                      className="formulaInput"
                      type="number"
                      placeholder="Enter Limit"
                    />
                  </div>
                </div>
              </div>

              <div className="formula30">
                <div className="calculatorElem">
                  <div className="calHeadingElem">
                    <span className="calHeading">Operators</span>
                  </div>
                  <div className="calDragElem">
                    <div className="calElems">
                      <Draggable className="calElem20" type="plus" data="plus">
                        <div>
                          <span className="operators">+</span>
                        </div>
                      </Draggable>
                      <Draggable
                        className="calElem20"
                        type="subtract"
                        data="subtract"
                      >
                        <div>
                          <span className="operators">-</span>
                        </div>
                      </Draggable>
                      <Draggable className="calElem20" type="mult" data="mult">
                        <div>
                          <span className="operators">x</span>
                        </div>
                      </Draggable>
                      <Draggable
                        className="calElem20"
                        type="divide"
                        data="divide"
                      >
                        <div>
                          <span className="operators">/</span>
                        </div>
                      </Draggable>
                      <Draggable
                        className="calElem20"
                        type="equal"
                        data="equal"
                      >
                        <div>
                          <span className="operators">=</span>
                        </div>
                      </Draggable>
                    </div>
                    <div className="calElems">
                      <Draggable
                        className="calElem20"
                        type="leftbracket"
                        data="leftbracket"
                      >
                        <div>
                          <span className="operators">(</span>
                        </div>
                      </Draggable>
                      <Draggable
                        className="calElem20"
                        type="rightbracket"
                        data="rightbracket"
                      >
                        <div>
                          <span className="operators">)</span>
                        </div>
                      </Draggable>
                      <Draggable
                        className="calElem20"
                        type="leftsquare"
                        data="leftsquare"
                      >
                        <div>
                          <span className="operators">[</span>
                        </div>
                      </Draggable>
                      <Draggable
                        className="calElem20"
                        type="rightsquare"
                        data="rightsquare"
                      >
                        <div>
                          <span className="operators">]</span>
                        </div>
                      </Draggable>
                      <Draggable
                        className="calElem20"
                        type="greaterthan"
                        data="greaterthan"
                      >
                        <div>
                          <img
                            className="operatorImg"
                            src={greaterthan}
                            alt=""
                          />
                        </div>
                      </Draggable>
                    </div>
                    <div className="calElems">
                      <Draggable
                        className="calElem20"
                        type="lessthan"
                        data="lessthan"
                      >
                        <div>
                          <img className="operatorImg" src={lessthan} alt="" />
                        </div>
                      </Draggable>
                      <Draggable
                        className="calElem20"
                        type="greater"
                        data="greater"
                      >
                        <div>
                          <img className="operatorImg" src={greater} alt="" />
                        </div>
                      </Draggable>
                      <Draggable className="calElem20" type="less" data="less">
                        <div>
                          <img className="operatorImg" src={less} alt="" />
                        </div>
                      </Draggable>
                      <Draggable
                        className="calElem20"
                        type="percent"
                        data="percent"
                      >
                        <div>
                          <span className="operators">%</span>
                        </div>
                      </Draggable>
                      <Draggable
                        className="calElem20"
                        type="point"
                        data="point"
                      >
                        <div>
                          <span className="operators">.</span>
                        </div>
                      </Draggable>
                    </div>
                    <div className="calElems">
                      <Draggable className="calElem20" type="if" data="if">
                        <div>
                          <span className="operators">If</span>
                        </div>
                      </Draggable>
                      <Draggable className="calElem20" type="else" data="else">
                        <div>
                          <span className="operators">Else</span>
                        </div>
                      </Draggable>
                      <Draggable className="calElem20" type="and" data="and">
                        <div>
                          <span className="operators">And</span>
                        </div>
                      </Draggable>
                      <Draggable className="calElem20" type="then" data="then">
                        <div>
                          <span className="operators">Then</span>
                        </div>
                      </Draggable>
                      <div className="calElem20 hide">
                        <span></span>
                      </div>
                    </div>
                    <div className="variableElem">
                      {temp && temp.Clause
                        ? temp.Clause.map((item, index) => {
                            return item.field === "input" ? (
                              <div key={index} className="wd48">
                                <Draggable
                                  className="calElem20 colorOrange"
                                  type="item"
                                  data={item.variable}
                                >
                                  <div className="calElem20 colorOrange">
                                    <span>
                                      {item.variable} = {item.value} {item.unit}
                                    </span>
                                  </div>
                                </Draggable>
                              </div>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                      {temp && temp.Clause
                        ? temp.Clause.map((item, index) => {
                            return item.field === "checkbox" ? (
                              <div key={index} className="wd48">
                                <Draggable
                                  className="calElem20 colorOrange"
                                  type="item"
                                  data={item.variable}
                                >
                                  <div className="calElem20 colorOrange">
                                    <span>
                                      {item.variable} ={" "}
                                      {item.values.map((item, ind) => {
                                        return ind === 0
                                          ? `${item.label}`
                                          : `/${item.label}`;
                                      })}
                                    </span>
                                  </div>
                                </Draggable>
                              </div>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                      {temp && temp.Clause
                        ? temp.Clause.map((item, index) => {
                            return item.field === "radio" ? (
                              <div key={index} className="wd48">
                                <Draggable
                                  className="calElem20 colorOrange"
                                  type="item"
                                  data={item.variable}
                                >
                                  <div className="calElem20 colorOrange">
                                    <span>
                                      {item.variable} ={" "}
                                      {item.values.map((item, ind) => {
                                        return ind === 0
                                          ? `${item.label}`
                                          : `/${item.label}`;
                                      })}
                                    </span>
                                  </div>
                                </Draggable>
                              </div>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                      {temp && temp.Clause
                        ? temp.Clause.map((item, index) => {
                            return item.field === "date" ? (
                              <div key={index} className="wd48">
                                <Draggable
                                  className="calElem20 colorOrange"
                                  type="item"
                                  data={item.variable}
                                >
                                  <div className="calElem20 colorOrange">
                                    <span>
                                      {item.variable} = {item.value}
                                    </span>
                                  </div>
                                </Draggable>
                              </div>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                      {temp && temp.Clause
                        ? temp.Clause.map((item, index) => {
                            return item.field === "units" ? (
                              <div key={index} className="wd48">
                                <Draggable
                                  className="calElem20 colorOrange"
                                  type="item"
                                  data={item.variable}
                                >
                                  <div className="calElem20 colorOrange">
                                    <span>
                                      {item.variable} = {item.value}
                                    </span>
                                  </div>
                                </Draggable>
                              </div>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                      {temp && temp.Clause
                        ? temp.Clause.map((item, index) => {
                            return item.field === "dropdown" ? (
                              <div key={index} className="wd48">
                                <Draggable
                                  className="calElem20 colorOrange"
                                  type="item"
                                  data={item.variable}
                                >
                                  <div className="calElem20 colorOrange">
                                    <span>
                                      {item.variable} = {item.value}
                                    </span>
                                  </div>
                                </Draggable>
                              </div>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                      {temp && temp.Clause
                        ? temp.Clause.map((item, index) => {
                            return item.field === "number" ? (
                              <div key={index} className="wd48">
                                <Draggable
                                  className="calElem20 colorOrange"
                                  type="item"
                                  data={item.variable}
                                >
                                  <div className="calElem20 colorOrange">
                                    <span>
                                      {item.variable} = {item.value}
                                    </span>
                                  </div>
                                </Draggable>
                              </div>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="formula30">
                <div className="calculatorElem">
                  <div className="calHeadingElem">
                    <span className="calHeading">Custom Variables</span>

                    <FaPlus
                      className="customVarPlusIcon"
                      onClick={_handleAddCustomVariable}
                    />
                  </div>
                  <div className="calDragElem customVarArea">
                    {customVars.map((cVar, idx) => {
                      // console.log(cVar);
                      return cVar.v_type === "drop" ? (
                        <div className="customVarWrapper" key={idx}>
                          <Droppable
                            className={`customVarDropZone algnCnt dropZone-${cVar.variableKey}`}
                            types={[
                              "plus",
                              "subtract",
                              "mult",
                              "divide",
                              "equal",
                              "leftbracket",
                              "rightbracket",
                              "leftsquare",
                              "rightsquare",
                              "greaterthan",
                              "percent",
                              "lessthan",
                              "greater",
                              "less",
                              "point",
                              "if",
                              "else",
                              "item",
                              "formulafields",
                              "and",
                              "then",
                            ]}
                            onDrop={(data) => _dropCustomVar(data, idx)}
                          >
                            {cVar && cVar.value?.length ? (
                              cVar.value?.map((ele, index) => {
                                // console.log(ele);

                                if (!ele.color) {
                                  _handleBracketPairColoring(
                                    ele,
                                    index,
                                    ele.variableKey,
                                    true,
                                    idx
                                  );
                                }
                                return (
                                  <OperatorIndex
                                    edit={true}
                                    ele={ele}
                                    key={index}
                                    mainIndex={index}
                                    length={cVar.length}
                                    AllItems={cVar}
                                    handleItemRemove={_handleRemoveFormulaItem}
                                    dataIndex={dataIndex}
                                    customVarInfo={{
                                      isCustVar: true,
                                      setCustomVars,
                                      outerIndx: idx,
                                      innerIndx: index,
                                    }}
                                    mouseEnter={_handleMouseEnter}
                                    mouseLeave={_handleMouseLeave}
                                  />
                                );
                              })
                            ) : (
                              <div
                                className="alignCenterDrop"
                                style={{ width: "100%", display: "flex" }}
                              >
                                <span>Drag here to make a custom variable</span>
                              </div>
                            )}
                          </Droppable>
                          <div className="customVarBtnsWrapper">
                            <FaCheck
                              className="custVarIcon saveIcon"
                              onClick={() => _handleSaveCustomVar(idx)}
                            />
                            <FaTimes
                              className="custVarIcon cancelIcon"
                              onClick={() => _handleCancelCustomVar(idx)}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="customVarWrapper" key={idx}>
                            <Draggable
                              className="calElem20 dragElemCustomVar"
                              type="customvar"
                              data={JSON.stringify(cVar.variableKey)}
                              key={idx}
                            >
                              <div>
                                <span className="operators">
                                  {cVar.variable} = {formulaToStr(cVar.value)}
                                </span>
                              </div>
                            </Draggable>
                            <div className="customVarBtnsWrapper">
                              <FaEdit
                                className="custVarIcon saveIcon"
                                onClick={() => _handleEditCustomVar(idx)}
                              />
                              <FaTimes
                                className="custVarIcon cancelIcon"
                                onClick={() => _handleCancelCustomVar(idx)}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="formula100 limitHgt mt-3">
              <div className="formula70">
                <div className="formHeader">
                  <span>Calculate clause condition for deduction</span>
                </div>
                <div className="formulaBody">
                  <div className="formDropZone" style={{ zIndex: "20" }}>
                    <Droppable
                      className="dropZone algnCnt formulaDropZone dropZone-formula"
                      types={[
                        "plus",
                        "subtract",
                        "mult",
                        "divide",
                        "equal",
                        "leftbracket",
                        "rightbracket",
                        "leftsquare",
                        "rightsquare",
                        "greaterthan",
                        "percent",
                        "lessthan",
                        "greater",
                        "less",
                        "point",
                        "if",
                        "else",
                        "item",
                        "formulafields",
                        "and",
                        "then",
                        "customvar",
                      ]}
                      onDrop={(data) => onDrop(data)}
                    >
                      {renderItem && renderItem.length !== 0 ? (
                        renderItem &&
                        renderItem.length > 0 &&
                        renderItem.map((ele, index) => {
                          //only change color when the bracket doesn't already have a color
                          console.log("rerender");
                          let res;
                          // console.log(ele);
                          if (
                            !bracketMapRef.current.get("formulaBracketsArr")
                          ) {
                            bracketMapRef.current.set("formulaBracketsArr", []);
                          }
                          if (!ele.color) {
                            // debugger;
                            res = _handleBracketPairColoring(
                              ele,
                              index,
                              "formulaBracketsArr"
                            );
                          }
                          if (res === -1) {
                            // console.log(renderItem);
                            setRenderItem((rt) =>
                              rt.filter((_, ind) => ind !== index)
                            );
                            return;
                          }

                          /* color bracket todos
                        5. NOTE: when removing elems -> if the elem is a bracket, then pop one item from the bracket array
                      
                        fin */
                          return (
                            <OperatorIndex
                              edit={true}
                              ele={ele}
                              key={index}
                              mainIndex={index}
                              length={renderItem.length}
                              AllItems={renderItem}
                              handleItemRemove={_handleRemoveFormulaItem}
                              dataIndex={dataIndex}
                              mouseEnter={_handleMouseEnter}
                              mouseLeave={_handleMouseLeave}
                            />
                          );
                        })
                      ) : (
                        <div
                          className="alignCenterDrop"
                          style={{ width: "100%", display: "flex" }}
                        >
                          <span className="alternateText">
                            Drag the Fields to calculate
                          </span>
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
              </div>

              <div className="formulaDrop">
                <div className="form100 empty reactSelect">
                  <Select
                    onChange={(value) => _handleFields(value)}
                    options={fieldArray}
                    styles={colourStyles}
                  />
                </div>
                <div className="selecteFieldElem">
                  {storeValues.length !== 0 ? (
                    storeValues.map((item, index) => {
                      return (
                        <Draggable
                          key={index}
                          className="dynamicElem"
                          type="formulafields"
                          data={item}
                        >
                          <div>
                            <i
                              className="fa fa-minus absMius"
                              onClick={() => removeItem(index)}
                            ></i>
                            <span>{item}</span>
                          </div>
                        </Draggable>
                      );
                    })
                  ) : (
                    <span className="alternateText">
                      Select from Dropdown and drag
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="formula100 limitHgt">
                            <div className="formula70">
                                <div className="formHeader">
                                    <span>Freight Calculation</span>
                                </div>
                                <div className="formulaBody">
                                    <div className="formDropZone">

                                    </div>
                                </div>
                            </div>
                            <div className="formulaDrop">
                                <div className="form100 empty">
                                </div>
                                <div className="form100 empty">
                                    <select className="formDropdown" defaultValue="static">
                                        <option value="static">select data fields</option>
                                        <option>a</option>
                                        <option>a</option>
                                        <option>a</option>
                                    </select>
                                </div>

                            </div>
                        </div> */}
            <div className="formula70 bthHeight ">
              <button className="addBtnClause" onClick={() => addClause()}>
                Add Clause
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Formula;

var dropObject = {
  plus: {
    type: "plus",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "+",
    color: null,
  },
  subtract: {
    type: "subtract",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "-",
    color: null,
  },
  mult: {
    type: "mult",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "x",
    color: null,
  },
  divide: {
    type: "divide",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "/",
    color: null,
  },
  equal: {
    type: "equal",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "=",
    color: null,
  },
  leftbracket: {
    type: "leftbracket",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "(",
    color: null,
  },
  rightbracket: {
    type: "rightbracket",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: ")",
    color: null,
  },
  leftsquare: {
    type: "leftsquare",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "[",
    color: null,
  },
  rightsquare: {
    type: "rightsquare",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "]",
    color: null,
  },
  greaterthan: {
    type: "greaterthan",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: ">=",
    color: null,
  },
  lessthan: {
    type: "lessthan",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "<=",
    color: null,
  },
  greater: {
    type: "greater",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: ">",
    color: null,
  },
  less: {
    type: "less",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "<",
    color: null,
  },
  percent: {
    type: "percent",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "%",
    color: null,
  },
  point: {
    type: "point",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: ".",
    color: null,
  },
  if: {
    type: "if",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "if",
    color: null,
  },
  else: {
    type: "else",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "else",
    color: null,
  },
  and: {
    type: "and",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "and",
    color: null,
  },
  then: {
    type: "then",
    FieldAs: "operator",
    FieldType: "",
    FieldValue: "then",
    color: null,
  },
};

function formulaToStr(formulaArr) {
  let str = formulaArr
    .reduce((acc, curr) => (acc += " " + curr.FieldValue), "")
    .replace(/\s+/g, " ")
    .trim();
  // console.log(str);
  return str;
}

// function randomColor() {
//   return "#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0");
// }

function randomColor() {
  var o = Math.round,
    r = Math.random,
    s = 255;
  return "rgb(" + o(r() * s) + ", " + o(r() * s) + ", " + o(r() * s) + ")";
}
