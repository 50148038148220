import React, { useState , useEffect } from 'react';
import $ from 'jquery'
import DatePicker from "react-datepicker";
import axios from 'axios'

const Weighmentout = (props) => {
    const weighmentOut = props.weighmentout
    const [cal, setcal] =useState()
    const [weighmentOutObject, setWeighOutObject] = useState(
        {
            action: "weighmentOut",
            shipmentGID: "",
            truckAllocationId: "",
            firstLoadedWeight: "",
        }
    )
    const [inpObj, setInpObj] = useState({
        grossWeight: "",
        loadingSlipNo: "",
        loadedDateTime: "01-06-2022 12:12PM",
    })
    const concate = {...weighmentOutObject , ...inpObj}

    const _handleChange = (e) => {
        setInpObj({...inpObj , [e.target.name] : e.target.value})
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#WeighmentoutModal").removeClass("custom-modal-is-visible")
    }
    useEffect(() => {
        if (weighmentOut !== undefined) {
            if ('TruckDetails' in weighmentOut) {
                weighmentOutObject.shipmentGID = weighmentOut.ShipmentGID
                weighmentOutObject.truckAllocationId = weighmentOut.TruckDetails.TruckAllocationId
                weighmentOutObject.firstLoadedWeight = weighmentOut.TruckDetails.TareWeight
            }
        }
    },[props,weighmentOut])
    
    const calculation = () => {
        var tempArr = {...inpObj};
        let gross = tempArr.grossWeight
        let tare = weighmentOutObject.firstLoadedWeight
        var calc = parseFloat(gross) - parseFloat(tare);
        if(calc >= 0){
            setcal(calc)
        }
        else{
            setcal(0)
        }
    }
    const allowWeigment = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        if(inpObj.grossWeight !== '' && inpObj.loadingSlipNo !== '' && inpObj.loadedDateTime !== ''){
        axios.post('https://www.betaenterprise.lynkit.io/api/booking/booking', { ...concate }, { headers: config.headers })
            .then(response => {
                const error = response.data.error
                const message = response.data.message
                if (error === false) {
                    props.closeModal()
                    setInpObj({
                        grossWeight: "",
                        loadingSlipNo: "",
                        loadedDateTime: "01-06-2022 12:12PM",
                    })
                    window.$.fn.show_notification({ message: `${message}`, title: 'Success...!!', type: 'confirm', autohide: false }, (result) => {
                        if (result.type === "confirm") {
                        }
                    })
                    window.location.reload();
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Alert..!!', type: 'confirm', autohide: false }, (result) => {
                        if (result.type === "confirm") {
                        }
                    })
                }

            })
            .catch(error => {
                console.log('err', error);
            })
        }
        else{
            window.$.fn.show_notification({ message: 'Gross weight and Weighment no mandatory.', title: 'Message...!!', type: 'confirm', autohide: false }, (result) => {
                if (result.type === "confirm") {
                }
            })
        }


    }

    // console.log(weighmentOutObject);
    return (
        <section id="WeighmentoutModal" style={{ width: '58%' }} className="custom-modal">
            <div className="custom-modal-header">
                <h1>Weighment-out</h1>
                <span className="ct-close" onClick={() => _closeModal("WeighmentinModal")}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '60vh' }}>
                <div className="cust-row">
                    <div className='hilElem'>
                        <div className='hilRow hilMg '>
                            <div className='hilR'>
                                <div className='hil1'>
                                    <span className='hilcustSpan'>Gross Weight :</span>
                                </div>
                                <div className='hil2'>
                                    <input name='grossWeight' value={inpObj.grossWeight} onChange={(e)=>_handleChange(e)} onKeyUp={() =>{ calculation()}} placeholder='Please Enter Gross weight' type='number' className='hilcustInp' />
                                </div>
                            </div>
                            <div className='hilR'>
                                <div className='hil1'>
                                    <span className='hilcustSpan'>Tare Weight :</span>
                                </div>
                                <div className='hil2'>
                                {weighmentOut.TruckDetails !== undefined ?
                                    <input type='number' name='weighmentOutObject'  value={weighmentOut.TruckDetails.TareWeight !== undefined ? weighmentOut.TruckDetails.TareWeight : '' } className='hilcustInp' />
                                    :
                                    <input type='number' name='weighmentOutObject'  value='-' className='hilcustInp' />
                                }
                                </div>
                            </div>
                        </div>
                        <div className='hilRow hilMg'>
                            <div className='hilR'>
                                <div className='hil1'>
                                    <span className='hilcustSpan'>Loaded Weight :</span>
                                </div>
                                <div className='hil2'>
                                    <input type='number' value={cal} className='hilcustInp' />
                                </div>

                            </div>

                            <div className='hilR'>
                                <div className='hil1'>
                                    <span className='hilcustSpan'>Loading Slip No :</span>
                                </div>
                                <div className='hil2'>
                                    <input type='text' name='loadingSlipNo' value={inpObj.loadingSlipNo}  onChange={(e)=>_handleChange(e)} placeholder='Enter Loading Slip no' className='hilcustInp' />
                                </div>
                            </div>

                        </div>
                       
                        <div className='hilRow centralise' style={{ background: '#fff' }}>
                            <button className='hilBtns' onClick={allowWeigment}>Submit</button>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )

}

export default Weighmentout;