import { GET_CLAUSE , HIDE_CONTRACT} from  "../actions/types";

const initialState={
    getClause: [],
    loading:true
} 

const getClauseRed =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_CLAUSE : return{
            ...state,
            getClause : payload
        }
        case HIDE_CONTRACT : return{ 
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default getClauseRed ;