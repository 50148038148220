import {GET_STAFF } from  "../actions/types";

const initialState={
    staffData: []
} 

const getStaffReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_STAFF : return{
            ...state,
            staffData:payload
        }
        default: return state;    
    }
}
export default getStaffReducer