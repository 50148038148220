import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import history from '../../services/history'
import { getSingleHub } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import ImgPreviewModal from './../Dashboard/Modal/ImgPreviewModal'

const HubsDetail = (props) => {
    const dispatch = useDispatch()
    const GetHubId = props.location.state.hubId
    const singleHubData = useSelector((state) => state.getSingleHubReducer.hubData)
    const loader = useSelector((state) => state.getSingleHubReducer.loading)
    const [disableState, setDisableState] = useState(true)
    const [base, setBase] = useState('')
    useEffect(() => {
        dispatch(getSingleHub(GetHubId))
    }, [dispatch]);

    const [updateObj, setUpdateObj] = useState({
        hubId: "",
        HubName: "",
        Address: "",
        Country: "India",
        State: "",
        City: "",
        PinCode: "",
        PAN: "",
        PanCopy: "",
        GSTIN: "",
        GstinUpload: "",
        Name: "",
        MobileNo: "",
        EmailId: "",
        TotalUsers: "",
        Status: "ACTIVE",
        AddedOn:""

    })
    const allowEdit = () => {

        if ($(".hubUpdateInp").hasClass("underline")) {
            $(".hubUpdateInp").removeClass("underline");
            setDisableState(true)
            setUpdateObj(
                {
                    HubId: "",
                    HubName: "",
                    Address: "",
                    Country: "India",
                    State: "",
                    City: "",
                    PinCode: "",
                    PAN: "",
                    PanCopy: {},
                    GSTIN: "",
                    GstInUpload: "",
                    Name: "",
                    MobileNo: "",
                    EmailId: "",
                    TotalUsers: "",
                    Status: "ACTIVE"
                }
            )

        } else {
            $(".hubUpdateInp").addClass("underline");
            setDisableState(false)
            let dummy = { ...updateObj }
            dummy.HubId = singleHubData._id
            dummy.HubName = singleHubData.HubName
            dummy.Address = singleHubData.Address
            dummy.Country = singleHubData.Country
            dummy.State = singleHubData.State
            dummy.City = singleHubData.City
            dummy.PinCode = singleHubData.PinCode
            dummy.PAN = singleHubData.PAN
            dummy.PanCopy = singleHubData.PanCopy
            dummy.GSTIN = singleHubData.GSTIN
            dummy.GstinUpload = singleHubData.GstinUpload
            dummy.Name = singleHubData.Name
            dummy.MobileNo = singleHubData.MobileNo
            dummy.EmailId = singleHubData.EmailId
            dummy.TotalUsers = singleHubData.TotalUsers
            dummy.Status = singleHubData.Status
            dummy.AddedOn = singleHubData.AddedOn
            setUpdateObj(dummy)
        }
    }
    const updateData = (e) => {
        setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
    }
    const updateFun = () => {
        const finalObj = {
            status: updateObj.Status,
            hubId: updateObj.HubId,
            hubName: updateObj.HubName,
            address: updateObj.Address,
            country: updateObj.Country,
            state: updateObj.State,
            city: updateObj.City,
            pinCode: updateObj.PinCode,
            pan: updateObj.PAN,
            panCopy: updateObj.PanCopy,
            gstin: updateObj.GSTIN,
            gstinUpload: updateObj.GstinUpload,
            name: updateObj.Name,
            mobileNo: updateObj.MobileNo,
            emailId: updateObj.EmailId,
            totalUsers: updateObj.TotalUsers,
            status: updateObj.Status,
            addedOn : updateObj.AddedOn
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/hub/UpdateHub', { ...finalObj }, { headers: config.headers })
            .then(response => {
                const err = response.data.error
                const message = response.data.message
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                if (err === false) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log('err', error)
            })
    }
    const _goBack = () => {
        history.push('/dashboard/hubs')
    }
    const uploadFrontImage = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        const localArray = updateObj
        if (item === 'pan') {
            localArray.PanCopy = base64
            setUpdateObj(localArray)
        }
        else if (item === 'gst') {
            localArray.GstinUpload = base64
            setUpdateObj(localArray)
        }
    }
    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }

                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const _handleClick1 = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const SharedData = (item) => {
        if (item === 'gst') {
            let key = singleHubData.GstinUpload.file_key
            const token = localStorage.getItem('secretkey')
            const config = {
                headers: { Authorization: token }
            };
            axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                .then(response => {
                    const storeBase = response.data.file
                    setBase(storeBase)
                })
                .catch(error => {
                    // dispatch(showError(err))
                })
        }
        else if (item === 'pan') {
            let key = singleHubData.PanCopy.file_key
            const token = localStorage.getItem('secretkey')
            const config = {
                headers: { Authorization: token }
            };
            axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                .then(response => {
                    const storeBase = response.data.file
                    setBase(storeBase)
                })
                .catch(error => {
                    // dispatch(showError(err))
                })
        }

    }
    const setDate = (e) => {
        updateObj.AddedOn = e.target.value
    }
    return (
        <>
            <div className="contentpanel">
                <div className='cust-row'>
                    <div className='cust-col-4 flex-algn-cent'>
                        <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                            <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                        </button>
                        <h1 className="title">Hubs Details</h1>
                    </div>
                    <div className='cust-col-6 flex-jc-end'>
                        <button className='button bg-orange-out mr-2 wd10' onClick={allowEdit}>
                            <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                            <div className="titleBoxnew">
                                <span>Edit</span>
                            </div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                            <div className="titleBoxnew">
                                <span>Download</span>
                            </div>
                        </button>
                    </div>
                </div>
                <hr className='bgg' />
                <div className="cust-container">
                    <div className='cust-row'>
                        {singleHubData !== undefined ?
                            loader === true ?
                                <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                    <i className="fa fa-spinner fa-spin loadingSpinner font-xxl  "></i>
                                </div>
                                :
                                <div className='cust-col-10'>
                                    <div className="vd_cont brdrColor">
                                        <div className="vd_cont_tbl brtd bgGrey">

                                            <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    Hub Name
                                                </div>
                                                <div className="vd_cont_tbl_sec_2">
                                                    <input className='hubUpdateInp'
                                                        name='HubName'
                                                        onChange={(e) => updateData(e)}
                                                        disabled={disableState}
                                                        value={disableState === true ? singleHubData.HubName : updateObj.HubName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    Contact Person
                                                </div>
                                                <div className="vd_cont_tbl_sec_2">
                                                    <input className='hubUpdateInp'
                                                        name='Name'
                                                        onChange={(e) => updateData(e)}
                                                        disabled={disableState}
                                                        value={disableState === true ? singleHubData.Name : updateObj.Name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    Contact Person Email
                                                </div>
                                                <div className="vd_cont_tbl_sec_2">
                                                    <input className='hubUpdateInp'
                                                        name='EmailId'
                                                        onChange={(e) => updateData(e)}
                                                        disabled={disableState}
                                                        value={disableState === true ? singleHubData.EmailId : updateObj.EmailId}
                                                    />
                                                </div>
                                            </div>
                                            <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    Contact Person Mobile No.
                                                </div>
                                                <div className="vd_cont_tbl_sec_2">
                                                    <input className='hubUpdateInp'
                                                        name='MobileNo'
                                                        onChange={(e) => updateData(e)}
                                                        disabled={disableState}
                                                        value={disableState === true ? singleHubData.MobileNo : updateObj.MobileNo}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    Added On
                                                </div>
                                                <div className="vd_cont_tbl_sec_2" style={{ position: 'relative' }}>
                                                    {disableState === true ?
                                                        <input className='hubUpdateInp'
                                                            name='AddedOn'
                                                            onChange={(e) => updateData(e)}
                                                            disabled={disableState}
                                                            value={disableState === true ? moment(singleHubData.AddedOn).format('DD/MM/YYYY') : moment(updateObj.AddedOn).format('DD/MM/YYYY')}
                                                        />
                                                        :
                                                        <span style={{ paddingRight: '30px' }}>
                                                            {moment(singleHubData.AddedOn).format('DD/MM/YYYY')}
                                                            <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                                <i className="fa fa-calendar updateCalender"></i>
                                                                <input className='hideCalend' name='AddedOn' value={updateObj.AddedOn} onChange={(e) => setDate(e)} type="date" />
                                                            </div>

                                                        </span>

                                                    }
                                                </div>
                                            </div> */}
                                            <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    Total Users
                                                </div>
                                                <div className="vd_cont_tbl_sec_2">
                                                    <input className='hubUpdateInp'
                                                        name='TotalUsers'
                                                        onChange={(e) => updateData(e)}
                                                        disabled={disableState}
                                                        value={disableState === true ? singleHubData.TotalUsers : updateObj.TotalUsers}
                                                    />
                                                </div>
                                            </div>
                                            <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    PAN
                                                </div>
                                                <div className="vd_cont_tbl_sec_2" style={{ position: 'relative' }} >
                                                    <input className='hubUpdateInp'
                                                        name='PAN'
                                                        onChange={(e) => updateData(e)}
                                                        disabled={disableState}
                                                        value={disableState === true ? singleHubData.PAN : updateObj.PAN}
                                                        style={{ paddingRight: '47px' }}
                                                    />
                                                    {disableState === false ?
                                                        <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                            <i className="fa fa-upload uploadIconn"  ></i>
                                                            <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'pan')} type="file" />
                                                        </div>
                                                        : ''}
                                                    <i className="fa fa-eye userEye black hubUploadIconEye" onClick={() => { _handleClick1('ImgPreviewModal'); SharedData('pan') }}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="vd_cont_tbl no_btr bgGrey">

                                            
                                            <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    GSTIN
                                                </div>
                                                <div className="vd_cont_tbl_sec_2" style={{ position: 'relative' }}>
                                                    <input className='hubUpdateInp'
                                                        name='GSTIN'
                                                        onChange={(e) => updateData(e)}
                                                        disabled={disableState}
                                                        value={disableState === true ? singleHubData.GSTIN : updateObj.GSTIN}
                                                        style={{ paddingRight: '47px' }}
                                                    />
                                                    {disableState === false ?
                                                        <div className='hubUploadIcon' style={{ border: 'none' }}>
                                                            <i className="fa fa-upload uploadIconn"  ></i>
                                                            <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'gst')} type="file" />
                                                        </div>
                                                        : ''}
                                                    <i className="fa fa-eye userEye black hubUploadIconEye" onClick={() => { _handleClick1('ImgPreviewModal'); SharedData('gst') }}></i>
                                                </div>
                                            </div>
                                            <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    Address
                                                </div>
                                                <div className="vd_cont_tbl_sec_2">
                                                    <input className='hubUpdateInp'
                                                        name='Address'
                                                        onChange={(e) => updateData(e)}
                                                        disabled={disableState}
                                                        value={disableState === true ? singleHubData.Address : updateObj.Address}
                                                    />
                                                </div>
                                            </div>
                                            <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    State
                                                </div>
                                                <div className="vd_cont_tbl_sec_2">
                                                    <input className='hubUpdateInp'
                                                        name='State'
                                                        onChange={(e) => updateData(e)}
                                                        disabled={disableState}
                                                        value={disableState === true ? singleHubData.State : updateObj.State}
                                                    />
                                                </div>
                                            </div>
                                            <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    City
                                                </div>
                                                <div className="vd_cont_tbl_sec_2">
                                                    <input className='hubUpdateInp'
                                                        name='City'
                                                        onChange={(e) => updateData(e)}
                                                        disabled={disableState}
                                                        value={disableState === true ? singleHubData.City : updateObj.City}
                                                    />
                                                </div>
                                            </div>
                                            <div className="vd_cont_tbl_sec">
                                                <div className="vd_cont_tbl_sec_1">
                                                    Pin Code
                                                </div>
                                                <div className="vd_cont_tbl_sec_2">
                                                    <input className='hubUpdateInp'
                                                        name='PinCode'
                                                        onChange={(e) => updateData(e)}
                                                        disabled={disableState}
                                                        value={disableState === true ? singleHubData.PinCode : updateObj.PinCode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {disableState === false ?
                                        <div className='updateElem'>
                                            <button onClick={() => updateFun()}>Update</button>
                                        </div>
                                        : ''}
                                </div>

                            :
                            <span className='noDataClass'>No Data Found</span>
                        }
                    </div>
                </div>
                <ImgPreviewModal base={base} />
            </div>
        </>

    );
};

export default HubsDetail;