import React, { useState } from 'react';
import $ from 'jquery'
import user from './../../../static/images/dummy-user.png'
import license from './../../../static/images/license.jpg'

const ViewDocuments = (props) => {
    const uploadFrontImage = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        console.log(base64);
    }
    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }

                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    return (
        <>
            <section id="ViewDocumentsModal" className="custom-modal" style={{ width: "55%" }}>
                <div className="custom-modal-header">
                    <h1>View Documents</h1>
                    <span className="ct-close" onClick={props.closeModal}></span>
                </div>
                <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                    <div className='docViewModal'>
                        <div className='docViewInn'>
                            <div className='docVehicleHeader'>
                                <span>Driver - <span className='specialDoc'>DL-QC-2001</span> </span>
                                {/* <div className='addUploadItem removeBrdr'>
                                    <i className="fa fa-upload docUploadIcon"></i>
                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'gst')} type="file" />
                                </div> */}
                            </div>
                            <div className='docVehicleBody'>
                                <div className='docImageInfo'>
                                    <div className='docCard'>
                                        <img src={user} alt='' />
                                        <span>Aadhar card</span>
                                    </div>
                                    <div className='docCard'>
                                        <img src={license} alt='' />
                                        <span>Aadhar card</span>
                                    </div>
                                    <div className='docCard'>
                                        <img src={user} alt='' />
                                        <span>Aadhar card</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='docViewInn'>
                            <div className='docVehicleHeader applyBrdr'>
                                <span>Driver - <span className='specialDoc'>xxxx- (0010)</span> </span>
                                {/* <div className='addUploadItem removeBrdr'>
                                    <i className="fa fa-upload docUploadIcon"></i>
                                    <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'gst')} type="file" />
                                </div> */}
                            </div>
                            <div className='docVehicleBody'>
                                <div className='docImageInfo'>
                                    <div className='docCard'>
                                        <img src={user} alt='' />
                                        <span>Aadhar card</span>
                                    </div>
                                    <div className='docCard'>
                                        <img src={license} alt='' />
                                        <span>Aadhar card</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}
export default ViewDocuments;