import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import history from '../../services/history';


const SelectInvoice = () => {
    const [inputValue, setInputValue] = useState('')

    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")

        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }
    const _onChange = (e) => {
        if (inputValue === '') {
            setInputValue(e.target.value);
        }
        else {
            setInputValue('')
        }
    }
    const openCreateInvoice = () => {
        history.push("/dashboard/Select/Create-Invoice")
    }
    const _goBack = () => {
        history.push("/dashboard/Invoices")
    }
    return (
        <>
            <div className="contentpanel">
                <div className='cust-row'>
                    <div className='cust-col-4 flex-algn-cent'>
                        <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                            <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                        </button>
                        <h1 className="title">Select PoD</h1>
                    </div>
                    <div className='cust-col-6 flex-jc-end'></div>
                </div>
                <hr className='bgg' />
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='cust_back_div'>
                                <div className='tt2'>PoD List</div>
                            </div>
                        </div>
                        <div className='cust-col-4-new'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" id="searchType">
                                        <option value="Invoice_no">Invoice No.</option>
                                        <option value=""></option>
                                    </select>
                                </div>
                                <input type="text" className="form-control" placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="all" id="ownershipStatus">
                                <option value="all">All Type</option>
                                <option value="invoice_no">Invoice No.</option>
                                <option value="invoice_id">Invoice ID</option>
                            </select>
                        </div>
                        <div className='cust-col-2'>
                            {
                                localStorage.getItem("userGroup") !== 'transporter' ?
                                    <select className="form-control select2-single" defaultValue="all" id="transporter">
                                        <option value="all">Transporter's Name</option>
                                        <option value="ajay">Ajay</option>
                                        <option value="nitin">Nitin</option>
                                    </select>
                                    : ""
                            }
                        </div>
                        <div className='cust-col-2 flex-jc-end'>
                            {inputValue === 'checked' ?
                                <button onClick={openCreateInvoice} className='btn btn-shadow btn-secondary size_hgt'>Create Invoice</button>
                                : ''}
                        </div>
                    </div>
                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='data-cont'>
                                <div className='data-cont-item'>
                                    <div className='input_div'>
                                        <input value='checked' onChange={(e) => _onChange(e)} className='input_checkbox' type='checkbox' />
                                    </div>
                                    {localStorage.getItem("userGroup") === 'transporter' ? "" :
                                        <div className='data_item_content align-div pad-0'>
                                            <div className='wd_30 clip-path display-col'>
                                                <span className='tbl-hd-1'>Transporter :</span>
                                                <span className='tbl-hd-2'>Ajay</span>
                                                <span className='tbl-hd-3'>(ID : XXXX)</span>
                                            </div>
                                            <div className='wd_25 bg-trans inc-hgt'>
                                                <div className='dateTime'>
                                                    <span>Date/Time</span>
                                                </div>
                                                <div className='date'>
                                                    <i className="fa fa-calendar"></i>
                                                    <span>18-03-2022</span>
                                                </div>
                                                <div className='time'>
                                                    <i className="fa fa-clock-o xl"></i>
                                                    <span>08:30:24 AM</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className='data_item_content set_height_xxl pad-top-0'>
                                        <div className='wd_5 align-cent set-hght'>
                                            <div className='sr-no '>
                                                <span>1</span>
                                            </div>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Receipt No.</span>
                                            <span className='orange_color font-lg'>12345</span>
                                            <span>(Oct 12th 2021 11:35AM)</span>
                                        </div>
                                        <div>
                                            <span>From</span>
                                            <span>Coke, w-101, Industrial Area, Ahmedabad, Gujarat- 343431 </span>
                                        </div>
                                        <div>
                                            <span>To</span>
                                            <span>New Delhi Office,G-89 Govind Puri New Delhi-110009</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Total Distance Covered</span>
                                            <span>1800KM</span>
                                        </div>
                                        <div className='wd_10'>
                                            <span>Delivered On</span>
                                            <span>Oct 12th 2021 11:40AM</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Delivery Date(Est.)</span>
                                            <span>Dec 12th 2021 01:40PM</span>
                                        </div>
                                        <div className='wd_5 align-cent set-hght set-bg'>
                                            <div className='sr-no trans' onClick={() => { toogleItemView(0) }}>
                                                <i className="fa fa-plus expand-tbl open"></i>
                                                <i className="fa fa-minus contract-tbl hide" ></i>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='data_item_content set_height_xxxl clps setColor'>
                                        <div>
                                            <span>Consignor</span>
                                            <span className='flex_box_2'>Dettol, vishnu garden , Ahmedabad, Gujarat - 110020(GSTIN-24AZSXK7676L1Z6)</span>
                                        </div>
                                        <div>
                                            <span>Consignee</span>
                                            <span className='flex_box_2'>RISHABH CONSIGNOR, Test Address of consignor, Bhagalpur, Bihar - 110092</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Goods Description</span>
                                            <span>Boxes</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Arrived On</span>
                                            <span>Oct 12th 2021 10:35 AM</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Total Freight</span>
                                            <span>100000</span>
                                        </div>
                                        <div className='wd_15'>
                                            <span>Tracking ID</span>
                                            <span>9988776655 <i className='fa fa-clone copyIcon'></i></span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelectInvoice;