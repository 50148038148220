import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import moment from 'moment';
import $ from 'jquery'
import axios from 'axios'

const AllocateTruck = (props) => {
    const loads = props.allocate
    const allocationListing = useSelector((state) => state.getAllocationListRed.allocationListingArr)
    const metaArr = useSelector((state) => state.getAllocationListRed.meta)
    const [data, setData] = useState([])

    const slideElement = (item) => {
        setData(item)
        if ($(".updateAllocate").hasClass("hide")) {
            $(".updateAllocate").removeClass("hide");
            $(".updateAllocate").addClass("show");
        } else {
            // $(".updateAllocate").addClass("hide");
            // $(".updateAllocate").removeClass("show");
        }
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
        $(".updateAllocate").addClass("hide");
        $(".updateAllocate").removeClass("show");
    }
    const _handleUpdate = (e, item, index) => {
        let dummy = { ...data }
        console.log(dummy)
        if (item === 'dl') {
            dummy.DriverDetails[index].dlNumber = e.target.value
            setData(dummy)
        }
        else if (item === 'ml') {
            dummy.DriverDetails[index].mobileNo = e.target.value
            setData(dummy)
        }
        else {
            dummy.DriverDetails[index].name = e.target.value
            setData(dummy)
        }



    }
    const openallocateTruck = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")

        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#confirmBookingModal`).addClass("custom-modal-is-visible")
    }
    const updateTruckData = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        const id = { id: data._id }
        const concate = { DriverDetails: data.DriverDetails, ...id }
        axios.post('https://www.betaenterprise.lynkit.io/api/booking/UpdateAllocatedTruck', { ...concate }, { headers: config.headers })
            .then(response => {
                const error = response.data.error
                const message = response.data.message
                if (error === false) {
                    $(".updateAllocate").addClass("hide");
                    $(".updateAllocate").removeClass("show");
                }
                else {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('err', error);
            })
    }
    const _delete = (_id) => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        const id = { id: _id }
        window.$.fn.show_notification({ message: 'Delete Allocated Vehicle ?', title: 'Alert!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
            if (result.type === 'confirm') {
                axios.post('https://www.betaenterprise.lynkit.io/api/booking/DeleteAllocatedTruck', { ...id }, { headers: config.headers })
                    .then(response => {
                        const error = response.data.error
                        const message = response.data.message
                        if (error === false) {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Success!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
                                if (result.type === 'confirm') {

                                }
                            })
                            $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                            $(".custom-modal").removeClass("custom-modal-is-visible")
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Meesage!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('err', error);
                    })
            }
        })
    }

    return (
        <section id="AllocateTruckModal" className="custom-modal" style={{ width: "62%" }}>
            <div className="custom-modal-header">
                <h1>Allocated Vehicles Details</h1>
                <span className="ct-close" onClick={_closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', minHeight: '25vh' }}>
                <div className='allocated_vehicle_innner'>
                    <div className='allocated-heading'>
                        <span>Total Vehicles Allocated : <span className='allocatecolor'>{metaArr ? metaArr.RecordsCount : '-'}</span></span>
                        <span>Total Load : <span className='allocatecolor'>{loads ? loads.load : '-'}</span></span>
                        <span>Remaining Load : <span className='allocatecolor'>{loads ? loads.RemainingLoadableCapacity : '-'}</span></span>
                        <button onClick={openallocateTruck} className='allocateTruck'>Allocate Truck</button>
                    </div>
                    <div className='allocate-header'>
                        <div className='all-col' style={{ width: '6%' }}>
                            <span>S.no</span>
                        </div>
                        <div className='all-col'>
                            <span>Vehicle No & Type</span>
                        </div>
                        <div className='all-col'>
                            <span>Gross Weight</span>
                        </div>
                        <div className='all-col'>
                            <span>Tare Weight</span>
                        </div>
                        <div className='all-col'>
                            <span>Loadable Capacity</span>
                        </div>
                        <div className='all-col'>
                            <span>Driver Name</span>
                        </div>
                        <div className='all-col'>
                            <span>DL Number</span>
                        </div>
                        <div className='all-col'>
                            <span>Driver No</span>
                        </div>
                        <div className='all-col'>
                            <span>Allocation Date</span>
                        </div>
                        <div className='all-col'>
                            <span>Action</span>
                        </div>

                    </div>
                    {allocationListing ?
                        allocationListing.map((item, index) => {
                            return (
                                <div key={index} className='allocate-body'>
                                    <div className='allocate-body-cover'>
                                        <div className='all-row' style={{ width: '6%' }}>
                                            <span>{index + 1}</span>
                                        </div>
                                        <div className='all-row'>
                                            <span>{item ? item.VehicleNumber : '-'} ({item ? item.VehicleType : '-'})</span>
                                        </div>
                                        <div className='all-row'>
                                            <span>{item && item.GrossVehicleWeight ? item.GrossVehicleWeight : '-'}</span>
                                        </div>
                                        <div className='all-row'>
                                            <span>{item && item.UnloadWeight ? item.UnloadWeight : '-'}</span>
                                        </div>
                                        <div className='all-row'>
                                            <span>{item && item.RegisteredCapacity ? item.RegisteredCapacity : '-'}</span>
                                        </div>
                                        <div className='all-row flexColumnAll'>
                                            {item && item.DriverDetails[0] && item.DriverDetails ? item.DriverDetails.map((item, index) => {
                                                return (
                                                    <span>{item.name}</span>
                                                )
                                            }) : ''}
                                        </div>
                                        <div className='all-row flexColumnAll' >
                                            {item && item.DriverDetails[0] && item.DriverDetails ? item.DriverDetails.map((item, index) => {
                                                return (
                                                    <span>{item.dlNumber}</span>
                                                )
                                            }) : '-'}

                                        </div>
                                        <div className='all-row flexColumnAll' >
                                            {item && item.DriverDetails[0] && item.DriverDetails ? item.DriverDetails.map((item, index) => {
                                                return (
                                                    <span>{item.mobileNo}</span>
                                                )
                                            })
                                                : '-'}
                                        </div>
                                        <div className='all-row'>
                                            <span>{item ? moment(item.AddedOn).format('DD/MM/YYYY') : ''}</span>
                                        </div>
                                        <div className='all-row flex-col'>
                                            <div className='cust-col-1 flex-algn-cent itemDiv '>
                                                <div className="popdv">
                                                    {/* <i className='fa fa-info-circle infoIcon'></i> */}
                                                    <div className="btmplc">
                                                        <div className='dv_flex pd5 brd_rht brd_btm brd_tp brd_lft'>
                                                            <div className='wdt100 text-center'>
                                                                <span className='font-bold black_color'>Vehicle Number : <span className='grey_color'>(enter by user)</span></span>
                                                            </div>
                                                        </div>
                                                        <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                            <div className='wdt40 brd_rht text-left'>
                                                                <span className='font-bold black_color font-sm'>Fitness Upto</span>
                                                            </div>
                                                            <div className='wdt40 brd_rht'>
                                                                <span className='font-bold grey_color font-sm'>(e-Vahan)</span>
                                                            </div>
                                                            <div className='wdt20'>
                                                                <i className='fa fa-check green_color'></i>
                                                            </div>
                                                        </div>
                                                        <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                            <div className='wdt40 brd_rht text-left'>
                                                                <span className='font-bold black_color font-sm'>PUC Valid Upto</span>
                                                            </div>
                                                            <div className='wdt40 brd_rht'>
                                                                <span className='font-bold grey_color font-sm'>(e-Vahan)</span>
                                                            </div>
                                                            <div className='wdt20'>
                                                                <span className='font-bold red_color font-xsm'>Expired</span>
                                                            </div>
                                                        </div>
                                                        <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                            <div className='wdt40 brd_rht text-left'>
                                                                <span className='font-bold black_color font-sm'>Insurance Validity</span>
                                                            </div>
                                                            <div className='wdt40 brd_rht'>
                                                                <span className='font-bold grey_color font-sm'>(e-Vahan)</span>
                                                            </div>
                                                            <div className='wdt20'>
                                                                <i className='fa fa-check green_color'></i>
                                                            </div>
                                                        </div>
                                                        {localStorage.getItem("userGroup") === 'transporter' ?
                                                            <div className='dv_flex pd5 brd_btm brd_lft brd_rht'>
                                                                <div className='wdt50'>
                                                                    <button className='button bg-success'>
                                                                        <span className='btn_txt'>Accept</span>
                                                                    </button>
                                                                </div>
                                                                <div className='wdt50'>
                                                                    <button className='button bg-danger'>
                                                                        <span className='btn_txt'>Reject</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : ""}
                                                        <i></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <i className='fa fa-edit clr' onClick={() => slideElement(item)}></i>
                                            <button className='btnstage'
                                                onClick={() => _delete(item.TruckAllocationId)}
                                                disabled={item.VehicleStatus === "truckAllocated" ? false : true}>
                                                x
                                                {item.VehicleStatus !== "truckAllocated" ?
                                                <div className="titleBoxnew1">
                                                    <span>Vehicle already Inspected.. </span>
                                                </div>
                                                :''}
                                            </button>
                                          
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : 'No Vehicle Allocated'}

                    {data && data.DriverDetails && data.DriverDetails.length !== 0 ?
                        <div className='updateAllocate hide'>
                            <div className='updateallocateHeader'>
                                <span>Driver Details</span>
                            </div>
                            {data && data.DriverDetails !== undefined ? data.DriverDetails.map((item, index) => {
                                return (
                                    <div key={index} className='updateallocateBody'>
                                        <div className='updateAllRow1'>
                                            <div className='update40'>
                                                <span>DL Number :</span>
                                            </div>
                                            <div className='update60'>
                                                <input type='text' name="dlNumber" value={item.dlNumber} onChange={(e) => _handleUpdate(e, 'dl', index)} />
                                            </div>
                                        </div>
                                        <div className='updateAllRow1'>
                                            <div className='update40'>
                                                <span>Mobile Number :</span>
                                            </div>
                                            <div className='update60'>
                                                <input type='number' name="mobileNo" value={item.mobileNo} onChange={(e) => _handleUpdate(e, 'ml', index)} />
                                            </div>
                                        </div>
                                        <div className='updateAllRow1'>
                                            <div className='update40'>
                                                <span>Name :</span>
                                            </div>
                                            <div className='update60'>
                                                <input type='text' name="name" value={item.name} onChange={(e) => _handleUpdate(e, 'name', index)} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : ''}
                            <div className='updateBtnElem'>
                                <button onClick={() => updateTruckData()}>Update</button>
                            </div>
                        </div>
                        :
                        ''

                    }
                </div>
            </div>
        </section>
    )

}

export default AllocateTruck;