import React, { useState, useEffect } from 'react';
import $ from 'jquery'
import AddUser from './Modal/AddUser'
import history from './../../services/history';
import { getUserList, getSingleUser, getDepartments, getPlantsListing } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { selectInit } from './../../services/functions'

const Users = () => {
    const [heading, setHeading] = useState('users')
    const [userdata, setData] = useState('')
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.getUserRed.userData)
    const loader = useSelector((state) => state.getUserRed.loading)
    const bookmark = useSelector((state) => state.getUserRed.bookmarks)
    const records = useSelector((state) => state.getUserRed.records)
    const departListing = useSelector((state) => state.getDepartReducer.departmentData)
    const plantListing = useSelector((state) => state.getPlantsListReducers.plantsData)
    const [userInfo, setUserInfo] = useState([])
    const [val, setVal] = useState('')
    const [plantDrop, setPlant] = useState('')
    const [userTypeState, setUserType] = useState('')
    let userGroup = localStorage.getItem('userGroup')
    const [obj, setObj] = useState(
        {
            docType: "users",
            bookmark: '',
            param: "next"
        }
    )
    const [searchObject, setSearchObject] = useState(
        {
            pageSize: "",
            bookmark: "",
            departmentId: "",
            plantId: "",
            searchType: "",
            searchValue: ""
        }
    )
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const updateCreate = (item, info) => {
        if (item === 'createUser') {
            setData('createUser')
            setUserInfo({ list: info, update: false })

        }
        else {
            setData('updateUser')
            setUserInfo({ list: info, update: true })
        }
    }
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 20);
        bookmark.length = 0
        setObj(
            {
                // docType: "users",
                param: "next",
                pageSize: "",
                bookmark: "",
                departmentId: "",
                plantId: "",
                searchType: "",
                searchValue: ""
            }
        )
        dispatch(getUserList(obj))
        dispatch(getDepartments())
        dispatch(getPlantsListing({
            docType: "plants",
            bookmark: '',
            param: "next"
        }))
    }, [])
    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            docType: "users"
        }
        setObj(object)
        dispatch(getUserList(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            docType: "users"
        }
        setObj(object)
        dispatch(getUserList(object))
        bookmark.pop()
    }
    const openUserDetails = (userIndex) => {
        history.push({
            pathname: "/dashboard/user/details",
            state: userIndex
        })
        // dispatch(getSingleUser(userIndex))
    }
    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchType = value
            setSearchObject(temp)
        }
        else if (id === 'userDepart') {
            setVal(value)
        }
        else if (id === 'userPlant') {
            setPlant(value)
        }
        else if (id === 'userType') {
            setUserType(value)
        }
    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(getUserList({
            // docType: "plants",
            param: "next",
            pageSize: "",
            bookmark: "",
            departmentId: "",
            plantId: "",
            searchType: searchObject.searchType,
            searchValue: searchObject.searchValue
        }))
    }
    useEffect(() => {
        if (val) {
            dispatch(getUserList({
                param: "next",
                pageSize: "",
                bookmark: "",
                departmentId: departListing[val]._id,
                plantId: "",
                searchType: "",
                searchValue: ""
            }))
        }
    }, [val])

    useEffect(() => {
        if (plantDrop) {
            dispatch(getUserList({
                param: "next",
                pageSize: "",
                bookmark: "",
                departmentId: "",
                plantId: plantListing[plantDrop]._id,
                searchType: "",
                searchValue: ""
            }))
        }
    }, [plantDrop])

    useEffect(() => {
        if (userTypeState) {
            dispatch(getUserList({
                param: "next",
                pageSize: "",
                bookmark: "",
                departmentId: "",
                plantId: "",
                searchType: "",
                searchValue: "",
                userType: userTypeState
            }))
        }
    }, [userTypeState])

    return (
        <>
            <div className="contentpanel">
                <h1 className="title">Users</h1>
                <hr />
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Users List</div>
                        </div>
                        <div className='cust-col-4'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="select" id='searchType'>
                                        <option value="select" disabled>Select</option>
                                        <option value="name">Name</option>
                                        <option value="mobileNo">Mobile Number</option>
                                    </select>
                                </div>
                                <input type="text" name='searchValue' value={searchObject.searchValue} onChange={(e) => _onChangeSearch(e)} className="form-control" placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2" disabled={searchObject.searchType === 'select' || searchObject.searchType === undefined ? true : false}
                                        onClick={() => triggerSearch()}>
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='cust-col-1_2'>
                            <select className="form-control select2-single" defaultValue="Vehicle" id="userDepart">
                                <option value="Vehicle" disabled>Department</option>
                                {departListing.map((x, departIndex) => {
                                    return (
                                        <option key={departIndex} value={departIndex}>{x.DepartmentName}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className='cust-col-1_2'>
                            <select className="form-control select2-single" defaultValue="select" id="userType">
                                <option value="select" disabled>User type</option>
                                <option value="Admin">Admin</option>
                                <option value="User">User</option>
                            </select>
                        </div>
                        {userGroup === 'goodsowner' ?
                            <div className='cust-col-1_2'>
                                <select className="form-control select2-single" defaultValue="Vehicle" id="userPlant">
                                    <option value="Vehicle" disabled>Plant Name</option>
                                    {plantListing && plantListing.map((plants, plantsIndex) => {
                                        return (
                                            <option key={plantsIndex} value={plantsIndex} >{plants.PlantName}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            : ""}
                        <div className='cust-col-2 flex-jc-end'>
                            <button className='btn btn-shadow btn-secondary size_hgt' onClick={() => { _handleClick('addUserModal'); updateCreate('createUser'); }}>Create New User</button>
                        </div>
                    </div>
                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            {listing && listing.length !== 0 ?
                                loader === true ?
                                    <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                        <i className="fa fa-spinner fa-spin loadingSpinner font-xxl  "></i>
                                    </div>
                                    :
                                    <div className='userTable'>
                                        <div className='userRow'>
                                            <div className='userR1'>
                                                <span className='userspan'>SNo.</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>NAME</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>MOBILE NO.</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>EMAIL ID</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>DEPARTMENT</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>USER TYPE</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>USER STATUS</span>
                                            </div>
                                            <div className='userR2'>
                                                <span className='userspan'>ACTION</span>
                                            </div>
                                        </div>
                                        {listing.map((item, index) => {
                                            return (
                                                <div key={index} className='userDynamicRow'>
                                                    <div className='userR1 hgt4'>
                                                        <span className='userspan roundSpan'>{index + 1}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan txtColored'>{item.Name}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan normaltxt'>{item.MobileNo}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan normaltxt'>{item.EmailId ? item.EmailId : '-'} </span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        {item.UserGroup === 'goodsowner' && item.UserSubGroup === "Department" ?
                                                            <span className='userspan normaltxt'>{item.DepartmentName}</span>
                                                            : '-'
                                                        }
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <span className='userspan normaltxt'>{item.UserType}</span>
                                                    </div>
                                                    <div className='userR2 hgt100'>
                                                        <button className={item.Status === 'active' || item.Status === 'ACTIVE' ? 'activeBtn' : 'hide'}>Active</button>
                                                        <button className={item.Status === 'inactive' || item.Status === 'INACTIVE' ? 'activeBtn inactiveBtn' : 'hide'}>Inactive</button>
                                                    </div>
                                                    <div className='userR2 hgt100 justItem'>
                                                        <i className="fa fa-eye userEye" onClick={() => openUserDetails(item._id)} ></i>
                                                        <i className="fa fa-edit userEdit" onClick={() => { _handleClick('addUserModal'); updateCreate('updateUser', item); }}></i>
                                                        <i className="fa fa-times userCross" ></i>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                :
                                <span className='noDataClass'>No Data Found</span>
                            }
                        </div>
                    </div>
                </div>
                {listing && listing.length !== 0 ?
                    <div className='paginationDiv'>
                        <button disabled={obj && obj.bookmark === '' ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                        <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                    </div>
                    : ''}
                <AddUser closeModal={_closeModal} sharedData={{ data: heading, userdata: userdata, userInfo: userInfo }} />
            </div>
        </>
    )
}
export default Users;