import React, { useState } from "react";
import lessthan from "./../../../static/images/icon/sidebar/signs/lessthan.png";
import greaterthan from "./../../../static/images/icon/sidebar/signs/greaterthan.png";
import less from "./../../../static/images/icon/sidebar/signs/less.png";
import greater from "./../../../static/images/icon/sidebar/signs/greater.png";

const OperatorIndex = (props) => {
  const { handleItemRemove, AllItems, customVarInfo = {} } = props;
  const { isCustVar, setCustomVars, outerIndx, innerIndx } = customVarInfo;

  const removeItem = ({ e, index }) => {
    if (!isCustVar) {
      handleItemRemove(e, index);
    } else {
      handleItemRemove(e, innerIndx, outerIndx);
      // console.log({ outerIndx, innerIndx });
    }
  };
  return (
    <>
      <OpertorsComponent {...props} removeItem={removeItem} />
    </>
  );
};

export default OperatorIndex;

const OpertorsComponent = (props) => {
  const { mainIndex, removeItem, ele, mouseEnter, mouseLeave } = props;
  // console.log(ele);
  return (
    <>
      <div
        className="operatorElem alignCenter formulaItem"
        style={ele.color ? { color: ele.color } : {}}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        data-key={ele.variableKey || "formula"}
      >
        <i
          className="fa fa-minus absMius"
          onClick={(e) => removeItem({ e, index: mainIndex })}
        ></i>
        <span className="plusSign formulaText">
          {ele.type === "item" || ele.type === "formulafields"
            ? ele.FieldValue
            : elemType[ele.type]}
        </span>
      </div>
    </>
  );
};

var elemType = {
  plus: "+",
  subtract: "-",
  mult: "x",
  divide: "/",
  equal: "=",
  leftbracket: "(",
  rightbracket: ")",
  leftsquare: "[",
  rightsquare: "]",
  greaterthan: <img className="operatorImg" src={greaterthan} alt="" />,
  lessthan: <img className="operatorImg" src={lessthan} alt="" />,
  greater: <img className="operatorImg" src={greater} alt="" />,
  less: <img className="operatorImg" src={less} alt="" />,
  percent: "%",
  point: ".",
  if: "if",
  else: "else",
  then: "then",
  and: "and",
};
