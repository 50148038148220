import { HIDE_LOADER, REGISTER_VEHICLE, SHOW_LOADER } from "../actions/types";

const initialState={
    // data:{},
    loading:false,
    error:''
} 

const registerVehicleReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case REGISTER_VEHICLE : return{
            ...state,
            error:payload.status
        }
        case SHOW_LOADER : return{
            ...state, 
            loading: true
        }
        case HIDE_LOADER : return{
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default registerVehicleReducer