import $ from 'jquery'
import history from '../../services/history'
import view from './../../static/images/icon/buttons/ViewDetail.svg'
import Delete from './../../static/images/icon/buttons/Delete.svg'
import update from './../../static/images/icon/buttons/Update.svg'
import React, { useEffect, useState } from 'react'
import documentView from './../../static/images/icon/buttons/ViewDocuments.svg'
import { listingApi, getSingleHub } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { selectInit } from './../../services/functions'

const Hubs = () => {
    const dispatch = useDispatch()
    const hubsList = useSelector((state) => state.getListingReducer.hubListing)
    const loader = useSelector((state) => state.getListingReducer.loading)
    const bookmark = useSelector((state) => state.getListingReducer.bookmarks)
    const records = useSelector((state) => state.getListingReducer.records)
    const [val, setVal] = useState('')
    const [obj, setObj] = useState(
        {
            bookmark: "",
            docType: "hub",
            param: "next"
        }
    )
    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")
        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }
    const _moveTo = (HubId) => {
        dispatch(getSingleHub(HubId))
        history.push({
            pathname: "/dashboard/hubs/details",
            state: { hubId: HubId }
        })
    }
    const _redirectTo = () => {
        history.push("/dashboard/hubs/Add-Hub")
    }
    const [searchObject, setSearchObject] = useState(
        {
            pageSize: "",
            bookmark: "",
            searchType: "",
            searchValue: "",
            status: "",
            docType: "hub",
            param: ""
        }
    )
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 100);
        bookmark.length = 0
        setObj(
            {
                pageSize: "",
                bookmark: "",
                searchType: "",
                searchValue: "",
                status: "",
                docType: "hub",
                param: ""
            }
        )
        dispatch(listingApi(obj))
    }, [dispatch])

    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            docType: "hub"
        }
        setObj(object)
        dispatch(listingApi(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            docType: "hub"
        }
        setObj(object)
        dispatch(listingApi(object))
        bookmark.pop()
    }
    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchType = value
            setSearchObject(temp)
        }
        else if (id === 'status') {
            setVal(value)
        }
    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(listingApi({
            pageSize: "",
            bookmark: "",
            searchType: searchObject.searchType,
            searchValue: searchObject.searchValue,
            status: "",
            docType: "hub",
            param: "next"
        }))
    }
    useEffect(() => {
        if (val) {
            dispatch(listingApi({
                pageSize: "",
                bookmark: "",
                searchType: "",
                searchValue: "",
                status: val,
                docType: "hub",
                param: "next"
            }))
        }
    }, [val])
    return (
        <div className="contentpanel">
            <h1 className="title">Hubs</h1>
            <hr />
            <span></span>
            <div className="cust-container">
                <div className='cust-row'>
                    <div className='cust-col-2 flex-algn-cent'>
                        <div className='tt2'>Hubs List</div>
                    </div>
                    <div className='cust-col-4-new'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <select className="select2-single" defaultValue="select" id="searchType">
                                    <option value="select" disabled>Select</option>
                                    <option value="name">Hub Name</option>
                                    <option value="gstin">GSTIN</option>
                                    <option value="pan">PAN</option>
                                </select>
                            </div>
                            <input type="text" name='searchValue' value={searchObject.searchValue} onChange={(e) => _onChangeSearch(e)} className="form-control" placeholder="Search here..." />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2"
                                    disabled={searchObject.searchType === 'select' || searchObject.searchType === undefined ? true : false}
                                    onClick={() => triggerSearch()}>
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='cust-col-2'>
                        <select className="form-control select2-single" defaultValue="select" id="status">
                            <option value="select" disabled>Status</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">InActive</option>
                        </select>
                    </div>
                    <div className='cust-col-2'>
                    </div>
                    <div className='cust-col-2 flex-jc-end'>
                        <button className='btn btn-shadow btn-secondary size_hgt' onClick={_redirectTo}>Add Hub</button>
                    </div>
                </div>
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='data-cont'>
                            {hubsList && hubsList.length !== 0 ?
                                loader === true ?
                                    <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                        <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                                    </div>
                                    :
                                    hubsList.map((item, index) => {
                                        return (
                                            <div key={index} className='data-cont-item'>
                                                {localStorage.getItem("userGroup") === 'transporter' ? "" :
                                                    <div className='data_item_content align-div pad-0'>
                                                        <div className='wd_30 clip-path display-col'>
                                                            <span className='tbl-hd-1'>Transporter :</span>
                                                            <span className='tbl-hd-2'>Ajay</span>
                                                            <span className='tbl-hd-3'>(ID : XXXX)</span>
                                                        </div>
                                                        <div className='wd_25 bg-trans inc-hgt'>
                                                            <div className='dateTime'>
                                                                <span>Date/Time</span>
                                                            </div>
                                                            <div className='date'>
                                                                <i className="fa fa-calendar"></i>
                                                                <span>18-03-2022</span>
                                                            </div>
                                                            <div className='time'>
                                                                <i className="fa fa-clock-o xl"></i>
                                                                <span>08:30:24 AM</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                }
                                                <div className='data_item_content set_height_xxl pad-top-0 set-new-hght'>
                                                    <div className='wd_5 align-cent set-hght'>
                                                        <div className='sr-no '>
                                                            <span>{index + 1}</span>
                                                        </div>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>Hub ID</span>
                                                        <span className='orange_color font-xsm'>{item.HubId}</span>
                                                    </div>
                                                    <div>
                                                        <span>Hub Name</span>
                                                        <span>{item.HubName}</span>
                                                    </div>
                                                    <div className='wd_20'>
                                                        <span>Address</span>
                                                        <span className='flex_box_2'>{item.Address} {item.State} {item.City}, {item.PinCode}</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>PAN</span>
                                                        <span>{item.PAN}</span>
                                                    </div>
                                                    <div className='wd_15'>
                                                        <span>GSTIN</span>
                                                        <span>{item.GSTIN}</span>
                                                    </div>
                                                    <div className='wd_10'>
                                                        <span>Status</span>
                                                        <span>{item.Status}</span>
                                                    </div>
                                                    <div className='wd_5 align-cent set-hght set-bg'>
                                                        <div className='sr-no trans' onClick={() => toogleItemView(index)}>
                                                            <i className="fa fa-plus expand-tbl open"></i>
                                                            <i className="fa fa-minus contract-tbl hide" ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='data_item_content set_height_xxxl clps setColor'>
                                                    <div className='wd_20'>
                                                        <span>Contact Person Name</span>
                                                        <span>null</span>
                                                    </div>
                                                    <div className='wd_20'>
                                                        <span>Contact Person Mobile</span>
                                                        <span>{item.MobileNo}</span>
                                                    </div>
                                                    <div lassName='wd_20'>
                                                        <span>Contact Person Email</span>
                                                        <span>{item.EmailId}</span>
                                                    </div>
                                                    <div lassName='wd_20'>
                                                        <span>Added On</span>
                                                        <span>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>
                                                    </div>
                                                    <div className='wd_20'>
                                                        <span>Total Users</span>
                                                        <span>{item.TotalUsers}</span>
                                                    </div>

                                                    <div className='wd_30 no-mg-pd designChange'>
                                                        <div className='btn_row_1'>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={() => _moveTo(item._id)}><img src={view} alt="none" />View Detail</button>
                                                            {/* <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={documentView} alt="none" />View User</button> */}
                                                            {/* <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={update} alt="none" />Update</button> */}
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={Delete} alt="none" />Delete</button>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })
                                :
                                <span className='noDataClass'>No Data Found</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {hubsList && hubsList.length !== 0 ?
                <div className='paginationDiv'>
                    <button disabled={obj && obj.bookmark === '' ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                    <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                </div>
                : ''}
        </div>
    )
}

export default Hubs;