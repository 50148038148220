import React, { useEffect, useState } from 'react';
import FileUpload from './../FileUpload'
import { get_pdf_thumbnail, base64Convert } from './../../../services/functions'

const UploadContractCopy = (props) => {

    const [fileArr, setFileArr] = useState([])
    const [imgBase, setImgBase] = useState('')  
    const [imgIndex, setImgIndex] = useState()

    const _handleFiles = async (files) => {
        if (files.length > 0) {
            let tempArr = [...fileArr]
            let tarr = Array.from(files)
            for (let el of tarr) {
                if (el.type.includes('image') || el.type === "application/pdf") {
                    try {
                        let base64 = await base64Convert(el)
                        let obj = { base64, fileType: el.type.includes('image') ? 'image' : 'pdf', name: el.name }

                        if (obj.fileType === 'pdf') {
                            let pdf = base64.replace(/^data:application\/\pdf;base64,/, "")
                            var binary_string = window.atob(pdf);
                            var len = binary_string.length;
                            var bytes = new Uint8Array(len);
                            for (var i = 0; i < len; i++) {
                                bytes[i] = binary_string.charCodeAt(i);
                            }
                            var buffer = bytes.buffer
                            var file3 = new Blob([buffer], { type: 'application/pdf' });
                            obj['dataLocalUrl'] = file3
                            get_pdf_thumbnail(file3).then((url) => {
                                obj['imageSrc'] = url
                            })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                        tempArr.unshift(obj)
                    }
                    catch (err) {

                    }
                }
                else {
                    //Swal.fire("Oops","Only image or PDF file is allowed to upload",'warning')
                }
            }
            setFileArr(tempArr)
        }
    }
    const deleteFile = (index) => {
        let tempArr = [...fileArr]
        tempArr.splice(index, 1)
        setFileArr(tempArr)
    }
    const previewImg = ({ item, index }) => {
        setImgBase(item.base64)
        setImgIndex(index)
    }
    const removeBase = () => {
        setImgBase()
    }
    const DownloadImage = (index) => {
        var newArray = [...fileArr]
        var a = document.createElement("a");
        a.href = newArray[index].base64
        a.download = newArray[index].name
        a.click();

    }
    const Download = () => {
        var newArray = [...fileArr]
        var a = document.createElement("a");
        a.href = newArray[imgIndex].base64
        a.download = newArray[imgIndex].name
        a.click();
    }
    useEffect(() => {
        var documentObj = fileArr.map(function(obj){
            return obj.base64
        })      
        props.contractState.documents = documentObj
    }, [fileArr])

    return (
        <>
            {imgBase === '' || imgBase === undefined ?
                <section id="UploadContractCopyModal" className="custom-modal" style={{ width: "45%" }}>
                    <div className="custom-modal-header">
                        <h1>Upload Contract Copy</h1>
                        <span className="ct-close" onClick={props.closeModal}></span>
                    </div>

                    <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '60vh' }}>
                        <div className="cust-row">
                            <div className='cust-col-10'>
                                <FileUpload handleFiles={_handleFiles} />
                            </div>
                        </div>
                        <div className="vd_cont_3_sec_bdy">
                            {fileArr.length ?
                                <div className='file_preview'>
                                    <p>Uploaded Files</p>
                                    <div className='file_preview_cont'>
                                        {
                                            fileArr.map((item, index) => {
                                                return <div key={`pp-` + index} className='preview_itm bttmm-mg' >
                                                    <div className='closeb' onClick={() => { deleteFile(index) }}>
                                                        <i className='fa fa-close'></i>
                                                    </div>
                                                    <img src={item.base64} alt="" onClick={() => previewImg({ item, index })} />
                                                    <div className='fullscreen'>
                                                        <i className='fa fa-expand' onClick={() => previewImg({ item, index })}></i>
                                                    </div>
                                                    <div className='downloadDocBtn'>
                                                        <i className="fa fa-download" onClick={() => DownloadImage(index)}></i>
                                                    </div>
                                                    <span className='overSpanName'>{item.name}</span>
                                                </div>

                                            })
                                        }
                                    </div>
                                </div>
                                : ""}
                        </div>
                        <div className='btn_cont post-btm'>
                            <button className='button bti bg-orange-out m-0 btn-size'>
                                <div className='btn_icon'>
                                    <i className='fa fa-upload'></i>
                                </div>
                                <div className='btn_txt font-lg'>Upload Now</div>
                            </button>
                        </div>
                    </div>
                </section>
                : 
                <section id="UploadContractCopyModal" className="custom-modal" style={{ width: "45%" }}>
                    <div className="custom-modal-header">
                        <h1>Upload Contract Copy</h1>
                        <span className="ct-close" onClick={() => { removeBase() }}></span>
                    </div>
                    <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '60vh' }}>
                        <div className='imgContainer'>
                            <img src={imgBase} alt='' />
                        </div>
                        <div className='downloadContractdoc'>
                            <button onClick={() => Download()}>Download</button>
                        </div>
                    </div>
                </section>
            }
        </>
    )

}

export default UploadContractCopy;