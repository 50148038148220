import {USER_PROFILE , USER_PROFILE_LOADER} from  "../actions/types";

const initialState={
    profileData: [],
    loading:true
} 

const getUserProfileRed =(state=initialState,{type,payload})=>{
    switch(type){
        case USER_PROFILE : return{
            ...state,
            profileData:payload
        }
        case USER_PROFILE_LOADER : return{
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default getUserProfileRed;