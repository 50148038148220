import {GET_SINGLE_DRIVER, HIDE_SINGLE_DRIVER} from  "../actions/types";

const initialState={
    driverData: [],
    loading:true
} 

const getSingleDriverReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_SINGLE_DRIVER : return{
            ...state,
            driverData : payload
        }
        case HIDE_SINGLE_DRIVER : return{ 
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default getSingleDriverReducer