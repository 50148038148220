import {FETCH_VEC_DETAILS ,SHOW_LOADER, HIDE_LOADER } from  "../actions/types";

const initialState={
    fetchedData: [], 
    loading:true
} 

const fetchVehRed =(state=initialState,{type,payload})=>{
    switch(type){
        case FETCH_VEC_DETAILS : return{
            ...state,
            fetchedData : payload 
        }
        case HIDE_LOADER : return{
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default fetchVehRed 