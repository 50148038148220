import { post } from './../../../services/requests'
import {FETCH_VEC_DETAILS , SHOW_LOADER , HIDE_LOADER} from './types'

export const getFetchedData = (data) =>{  
    return(dispatch)=>{
        dispatch(showLoader())
        post('vehicle/fetchvehicle',{...data})
        .then(response=>{
             const vehData =response.data
             console.log('Fetch Vehicle response',vehData);
             const status= response.error
             dispatch(fetchVehicleData(vehData))
             dispatch(hideLoader())  
            //  if(status === false){
            //     window.$.fn.show_notification({ message: 'Vehicle added successfully..', title: 'Success!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
            //         if (result.type === 'confirm') {
            //         }
            //     })
            //  }
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const fetchVehicleData = (vehData) =>{
    return{
        type :FETCH_VEC_DETAILS,
        payload:{vehData}
    }
}
export const showLoader = () =>{
    return{
        type : SHOW_LOADER,
    }
}
export const hideLoader = () =>{
    return{
        type : HIDE_LOADER,
    }
}
