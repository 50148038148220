import { get } from './../../../services/requests'
import {GET_SINGLE_USER, HIDE_SINGLE_USER } from './types'

export const getSingleUser = (userIndex) =>{ 
    return(dispatch)=>{
        get(`user/GetUserDetails/${userIndex}`)
        .then(response=>{
             console.log('single user response',response);
             const singleUserRes= response.data
             dispatch(storeUserDet(singleUserRes))
             dispatch(hideUserLoader())
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
} 
export const storeUserDet = (singleUserRes) => {
    return {
        type: GET_SINGLE_USER,
        payload: singleUserRes
    }
}
export const hideUserLoader = () => { 
    return {
        type: HIDE_SINGLE_USER
    }
}
