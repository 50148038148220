import React, { useState } from 'react';
import $ from 'jquery'

const OverlapModal = (props) => {
    const _closeModal = () => {
        // $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $("#OverlapModal").removeClass("custom-modal-is-visible")
    }
    return (
        <section id="OverlapModal"  className="custom-modal" style={{ width: '55vw', maxHeight: '80vh' }}>
            <div className="custom-modal-header">
                <h1>Uploaded Document</h1>
                <span className="ct-close" onClick={()=>_closeModal("OverlapModal")}></span>
            </div>
            <div className="custom-modal-body" style={{ overflowX: 'auto', maxHeight: '70vh' }}>
                <div className="cust-row">
                    <img className='panGstDoc' src={props.imageData} alt=''/>
                </div>
            </div>
        </section>
    )

}

export default OverlapModal;