import {GET_CONTRACT , HIDE , RESET_CONTRACT_DATA} from  "../actions/types";

const initialState={ 
    contractData: [],
    loading:true
} 

const getContractDetailRed =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_CONTRACT : return{
            ...state,
            contractData:payload   
        }
        case HIDE : return{
            ...state,
            loading:false
        }   
        case RESET_CONTRACT_DATA:
            return {
                ...state,
                contractData:[]
            }
            
        default: return state;  
    }
}
export default getContractDetailRed;