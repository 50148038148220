import React, { useEffect, useState } from 'react';
import { selectInit } from './../../services/functions'
import $, { data } from 'jquery'
import AddVehicle from './Modal/AddVehicle'
import history from '../../services/history';
import view from './../../static/images/icon/buttons/ViewDetail.svg'
import cancel from './../../static/images/icon/buttons/Cancel.svg'
import Delete from './../../static/images/icon/buttons/Delete.svg'
import update from './../../static/images/icon/buttons/Update.svg'
import documentView from './../../static/images/icon/buttons/ViewDocuments.svg'
import { getVehicleData, listingApi } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import axios from 'axios'

const Vehicle = () => {
    const dispatch = useDispatch()
    const usersList = useSelector((state) => state.getListingReducer.listing)
    const bookmark = useSelector((state) => state.getListingReducer.bookmarks)
    const loader = useSelector((state) => state.getListingReducer.loading)
    const records = useSelector((state) => state.getListingReducer.records)
    const transportersList = useSelector((state) => state.getListingReducer.transporterListing)
    const [val, setVal] = useState('')
    const [valBlack, setValBlack] = useState('')
    const [ownerStatus, setOwnerStatus] = useState('')
    const [searchObject, setSearchObject] = useState(
        {
            bookmark: "",
            docType: "vehicle",
            pageSize: "",
            searchValue: "",
            vehicleType: "",
            orgId: ""
        }
    )
    const [obj, setObj] = useState(
        {
            docType: "vehicle",
            bookmark: '',
            param: "next",
            searchValue: "",
            vehicleType: "",
            orgId: "",
            blacklistedFlag: "",

        }
    )
    const toogleView = (index, check = true) => {
        if ($(".collapse-view").eq(index).hasClass("open") && check) {
            $(".collapse-view").eq(index).removeClass("open");
            $(".collapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
            let height =
                Number($(".collapse-view").eq(index).children("table").height()) + 2;
            $(".collapse-view")
                .eq(index)
                .animate({ height }, 500, () => {
                    $(".collapse-view").eq(index).addClass("open");
                });
        }
    }
    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")

        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const _goto = (VehicleNumber) => {
        history.push({
            pathname: "/dashboard/vehicle/details",
            state: { VehicleNumber: VehicleNumber }
        })
        // dispatch(getVehicleData(VehicleNumber))
    }
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 100);

        dispatch(listingApi({
            docType: "transporter",
            bookmark: "",
            param: "next"
        }))
        bookmark.length = 0
        setObj(
            {
                docType: "vehicle",
                bookmark: '',
                param: "next",
                searchValue: "",
                vehicleType: "",
                orgId: "",
                blacklistedFlag: "",
                searchBy: ""
            }
        )
        dispatch(listingApi(obj))
    }, [dispatch])

    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            docType: "vehicle",
            searchValue: "",
            vehicleType: "",
            orgId: "",
            blacklistedFlag: "",
            searchBy: ""
        }
        setObj(object)
        dispatch(listingApi(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            docType: "vehicle",
            searchValue: "",
            vehicleType: "",
            orgId: "",
            blacklistedFlag: "",
            searchBy: ""
        }
        setObj(object)
        dispatch(listingApi(object))
        bookmark.pop()
    }
    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchBy = value
            setSearchObject(temp)
        }
        else if (id === 'transporter') {
            setVal(value)
        }
        else if (id === 'blacklist') {
            setValBlack(value)
        }
        else if (id === 'ownershipStatus') {
            setOwnerStatus(value)
        }
    }
    useEffect(() => {
        if (val) {
            dispatch(listingApi({
                docType: "vehicle",
                bookmark: '',
                param: "next",
                searchValue: '',
                vehicleType: "",
                orgId: transportersList[val]._id,
                blacklistedFlag: "",
                searchBy: ""
            }))
        }
    }, [val])

    useEffect(() => {
        if (valBlack) {
            dispatch(listingApi({
                docType: "vehicle",
                bookmark: '',
                param: "next",
                searchValue: '',
                vehicleType: "",
                orgId: "",
                blacklistedFlag: JSON.parse(valBlack), 
                searchBy: ""
            }))

        }
    }, [valBlack])

    useEffect(() => {
        if (ownerStatus) {
            dispatch(listingApi({
                docType: "vehicle",
                bookmark: '',
                param: "next",
                searchValue: '',
                vehicleType: "",
                orgId: "",
                blacklistedFlag: "",
                searchBy: ""
            }))

        }
    }, [valBlack])

    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(listingApi({
            docType: "vehicle",
            bookmark: '',
            param: "next",
            searchValue: searchObject.searchValue,
            vehicleType: "",
            orgId: "",
            blacklistedFlag: "",
            searchBy: ""
        }))
    }
    const blackList = (item) => {

        const finalObj = {
            vehicleNumber: item.VehicleNumber,
            vehicleSerialNo: "NA",
            ownerName: item.OwnerName,
            gpsNumber: 'NA',
            bodyGroup: item.BodyGroup,
            bodyType: item.BodyType,
            vehicleType: item.VehicleType,
            vehicleModel: item.VehicleModel,
            registrationDate: item.RegistrationDate,
            registrationValidTill: item.RegistrationValidTill,
            registeredCapacity: item.RegisteredCapacity,
            capacityUOM: 'NA',
            ftlRegisteredCapacity: 'NA',
            pucCheck: item.PucCheck,
            pucValidTill: item.PucValidTill,
            insuranceCheck: item.InsuranceCheck,
            insuranceValidTill: item.InsuranceValidTill,
            blacklistedFlag: item.BlacklistedFlag === false ? true : false,
            address: item.Address,
            country: item.Country,
            state: item.State,
            district: item.District,
            pinCode: item.PostalCode,
            mobileNo: "NA",
            permitStates: item.PermitStates,
            permitValidTill: item.PermitValidTill,
            permitRegisteredCapacity: item.RegisteredCapacity,
            permitCapacityUOM: "NA",
            permitActive: item.PermitActive,
            unloadWeight: item.UnloadWeight,
            grossVehicleWeight: item.GrossVehicleWeight,
            status: 'NA'
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: `Are you sure ? `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {
                axios.post('https://www.betaenterprise.lynkit.io/api/vehicle/UpdateVehicle', { ...finalObj }, { headers: config.headers })
                    .then(response => {
                        const message = response.data.message
                        const err = response.data.error
                        dispatch(listingApi(obj))
                        if (err === 'false') {
                            window.$.fn.show_notification({ message: `${message} `, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message} `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                    })
            }
        })


    }
    return (
        <>
            <div className="contentpanel">
                <h1 className="title">Vehicle</h1>
                <hr />
                <div className="cust-container">
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Vehicle List</div>
                        </div>
                        <div className='cust-col-4-new'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="select" id="searchType">
                                        <option value="select" disabled>Select</option>
                                        <option value="truck_no">Vehicle No.</option>
                                    </select>
                                </div>
                                <input type="text" name='searchValue' value={searchObject.searchValue} onChange={(e) => _onChangeSearch(e)} className="form-control" placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2"
                                        disabled={searchObject.searchBy === 'select' || searchObject.searchBy === undefined ? true : false}
                                        onClick={() => triggerSearch()}>
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="select" id="blacklist">
                                <option value="select" disabled>Vehicle Status</option>
                                <option value="false">Approved Vehicles</option>
                                <option value="true">Blacklisted Vehicles</option>
                            </select>
                        </div> 
                        {localStorage.getItem("userGroup") === 'transporter' ?
                            <div className='cust-col-2'>
                                <select className="form-control select2-single" defaultValue="all" id="ownershipStatus">
                                    <option value="all" disabled>Vehicle Type</option>
                                    <option value="owned">Owned Vehicle</option>
                                    <option value="hired">Hired Vehicle</option>
                                </select>
                            </div>
                            : ''}
                        <div className='cust-col-2'>
                            {
                                localStorage.getItem("userGroup") !== 'transporter' ?
                                    <select className="form-control select2-single" defaultValue="all" id="transporter">
                                        <option value="all" disabled>Transporter's Name</option>
                                        {transportersList.map((item, index) => {
                                            return (
                                                <option value={index} key={index} >{item.OrgName}</option>
                                            )
                                        })}
                                    </select>
                                    : ""
                            }
                        </div>
                        <div className='cust-col-2 flex-jc-end'>
                            {localStorage.getItem("userGroup") === 'transporter' ?
                                <button className='btn btn-shadow btn-secondary size_hgt' onClick={() => { _handleClick('addVehicleModal') }}>Add Vehicle</button>
                                : ''}
                        </div>
                    </div>
                    <div className='cust-row'>
                        <div className='cust-col-10'>
                            <div className='data-cont'>
                                {usersList && usersList.length !== 0 ?
                                    loader === true ?
                                        <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                            <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                                        </div>
                                        :
                                        usersList.map((item, index) => {
                                            return (
                                                <div key={index} className={item.BlacklistedFlag === true ? 'data-cont-item blackListBrdr' : 'data-cont-item'}>
                                                    {localStorage.getItem("userGroup") === 'transporter' ? "" :
                                                        <div className='data_item_content align-div pad-0'>
                                                            <div className='wd_30 clip-path display-col'>
                                                                <span className='tbl-hd-1'>Transporter :</span>
                                                                <span className='tbl-hd-2'>{item.OrgName ? item.OrgName : "-"}</span>
                                                                <span className='tbl-hd-3'>{item.OrgReadableID ? `(ID - ${item.OrgReadableID})` : ""}</span>
                                                            </div>
                                                            <div className='wd_30 bg-trans inc-hgt'>
                                                                <div className='dateTime'>
                                                                    <span>Date/Time</span>
                                                                </div>
                                                                <div className='date'>
                                                                    <i className="fa fa-calendar"></i>
                                                                    <span>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>
                                                                </div>
                                                                <div className='time'>
                                                                    <i className="fa fa-clock-o xl"></i>
                                                                    <span>{moment(item.AddedOn).format("h:mm:ss a")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='data_item_content set_height pad-top-0 '>
                                                        <div className='wd_5 align-cent set-hght'>
                                                            <div className='sr-no '>
                                                                <span>{index + 1}</span>
                                                            </div>
                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>Vehicle Reg. Number</span>
                                                            <span>{item.VehicleNumber}</span>
                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>Vehicle Serial Number</span>
                                                            <span>{item.VehicleSerialNo === "" ? item.VehicleSerialNo : "-"}</span>
                                                        </div>
                                                        <div className='wd_15'>
                                                            <span>Vehicle Owner Name</span>
                                                            <span>{item.BlacklistedFlag}</span>
                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>Vehicle Type</span>
                                                            <span>{item.VehicleType}</span>
                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>Registration Date</span>
                                                            <span>{moment(item.RegistrationDate).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>Registration valid till</span>
                                                            <span>{moment(item.RegistrationValidTill).format('DD/MM/YYYY')}</span>

                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>Permit Valid Till</span>
                                                            <span>{moment(item.PermitValidTill).format('DD/MM/YYYY')}</span>

                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>Vehicle Category</span>
                                                            <span>-</span>
                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>Blacklisted Status</span>
                                                            <span>{item.BlacklistedFlag === true ? 'True' : 'False'}</span>

                                                        </div>
                                                        <div className='wd_5 align-cent set-hght set-bg'>
                                                            <div className='sr-no trans' onClick={() => toogleItemView(index)}>
                                                                <i className="fa fa-plus expand-tbl open"></i>
                                                                <i className="fa fa-minus contract-tbl hide" ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='data_item_content clps set_height_xxl setColor'>
                                                        <div className='wd_10'>
                                                            <span>Vehicle Belonging</span>
                                                            <span>{item.VehicleBelongsTo === '' ? item.VehicleBelongsTo : '-'}</span>
                                                        </div>
                                                        <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_10" : "wd_10"}>
                                                            <span>PUC Valid Upto</span>
                                                            <span>{moment(item.PucValidTill).format('DD/MM/YYYY')}</span>

                                                        </div>
                                                        <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_15" : "wd_15"}>
                                                            <span>Insurance Valid Upto</span>
                                                            <span>{moment(item.InsuranceValidTill).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_15" : "wd_15"}>
                                                            <span>Vehicle addded on</span>
                                                            <span>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div className="wd_20">
                                                            <span>Engine Number</span>
                                                            <span>{item.EngineNumber.replace(/^(.{3})(.{3})(.{3})(.*)$/, "$1- $2- $3")}</span>
                                                        </div>
                                                        <div className='wd_20'>
                                                            <span>Chasis Number</span>
                                                            <span>{item.ChassisNumber.replace(/^(.{4})(.{4})(.{4})(.{4})(.*)$/, "$1- $2- $3- $4")}</span>
                                                        </div>
                                                        <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_10" : "wd_10"}>
                                                            <span>Registered Capacity</span>
                                                            <span>{item.RegisteredCapacity}</span>
                                                        </div>
                                                        <div className='wd_40 no-mg-pd designChange'>
                                                            <div className='btn_row_1'>
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={documentView} alt="none" />View Documents</button>
                                                                {localStorage.getItem("userGroup") !== 'transporter' ?
                                                                    <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={update} alt="none" />Update</button>
                                                                    : ""}
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={Delete} alt="none" />Delete</button>
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={() => _goto(item.VehicleNumber)}><img src={view} alt="none" />View Detail</button>
                                                                {/* {localStorage.getItem("userGroup") !== 'transporter' ? */}
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={() => blackList(item)}><img src={cancel} alt="none" />Blacklist</button>
                                                                {/* : ""} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    :
                                    <span className='noDataClass'>No Data Found</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {usersList && usersList.length !== 0 ?
                    <div className='paginationDiv'>
                        <button disabled={obj && obj.bookmark === '' ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                        <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                    </div>
                    : ''}

                <AddVehicle closeModal={_closeModal} toogleView={toogleView} />
            </div>
        </>
    )
}


export default Vehicle;