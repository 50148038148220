import { post } from './../../../services/requests'
import {FETCH_DRI_THIRD , SHOW_LOADER , HIDE_LOADER} from './types'

export const getThirdDriverData = (data) =>{  
    return(dispatch)=>{
        dispatch(showLoader())
        post('driver/fetchdriver',{...data})
        .then(response=>{
             const driverData =response.data
             console.log('3rd party driver response',driverData);
             const status= response.error
             dispatch(fetchDriverData(driverData))
             dispatch(hideLoader())  
            //  if(status === false){
            //     window.$.fn.show_notification({ message: 'Vehicle added successfully..', title: 'Success!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
            //         if (result.type === 'confirm') {
            //         }
            //     })
            //  }
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
}
export const fetchDriverData = (driverData) =>{
    return{
        type :FETCH_DRI_THIRD,
        payload:{driverData}
    }
}
export const showLoader = () =>{
    return{
        type : SHOW_LOADER,
    }
}
export const hideLoader = () =>{
    return{
        type : HIDE_LOADER,
    }
}
