
export const USER_LIST='USER_LIST'
export const LOGIN='LOGIN'
export const DECODE='DECODE'
export const SEND_DATA='SEND_DATA'
export const FETCH_USERS_SUCCESS='FETCH_USERS_SUCCESS'
export const STAFF_DATA='STAFF_DATA'
export const DRIVER_DATA='DRIVER_DATA'
export const ADDRESS_DATA='ADDRESS_DATA'
export const REGISTER_VEHICLE='REGISTER_VEHICLE'
export const REGISTER_HUB='REGISTER_HUB'
export const GET_VEHICLE='GET_VEHICLE'
export const GET_DRIVER='GET_DRIVER'
export const GET_STAFF='GET_STAFF'
export const GET_ADDRESS='GET_ADDRESS'
export const BOOKING='BOOKING'
export const SHOW_LOADER='SHOW_LOADER'
export const HIDE_LOADER='HIDE_LOADER'
export const LISTING_DATA='LISTING_DATA'
export const DRIVER_LIST='DRIVER_LIST'
export const STAFF_LIST='STAFF_LIST'
export const HUB_LIST='HUB_LIST'
export const ADDRESS_LIST='ADDRESS_LIST'
export const TRANSPORTER_LIST='TRANSPORTER_LIST'
export const DRIVERS_LIST='DRIVERS_LIST'
export const SHOW_VEHICLE_LOADER='SHOW_VEHICLE_LOADER'
export const HIDE_VEHICLE_LOADER='HIDE_VEHICLE_LOADER'
export const HIDE='HIDE'
export const GET_SINGLE_ADDRESS='GET_SINGLE_ADDRESS'
export const HIDE_SINGLE_ADDRESS='HIDE_SINGLE_ADDRESS' 
export const GET_DEPARTMENT='GET_DEPARTMENT'
export const FETCH_VEC_DETAILS='FETCH_VEC_DETAILS' 
// 
export const GET_SINGLE_TRANS='GET_SINGLE_TRANS' 
export const HIDE_TRANS_LOADER='HIDE_TRANS_LOADER'

export const GET_SINGLE_DRIVER='GET_SINGLE_TRANS'
export const HIDE_SINGLE_DRIVER='HIDE_TRANS_LOADER'
export const GET_SINGLE_STAFF='GET_SINGLE_STAFF'
export const HIDE_SINGLE_STAFF='HIDE_SINGLE_STAFF'
export const GET_SINGLE_HUB='GET_SINGLE_HUB'
export const HIDE_SINGLE_HUB='HIDE_SINGLE_HUB'
export const PAGINATION='PAGINATION'
export const RECORDS='RECORDS'
export const PLANTS_LIST='PLANTS_LIST'
export const PLANTS_HIDE='PLANTS_HIDE'
export const PLANTS_SUCCESS='PLANTS_SUCCESS'
export const GET_USERS='GET_USERS' 
export const SHOW_USER='SHOW_USER' 
export const HIDE_USER='HIDE_USER'
export const GET_SINGLE_USER='GET_SINGLE_USER'
export const HIDE_SINGLE_USER='HIDE_SINGLE_USER'   
export const USER_PROFILE='USER_PROFILE' 
export const USER_PROFILE_LOADER='USER_PROFILE_LOADER'  
export const GET_ALL_CONTRACTS='GET_ALL_CONTRACTS'  
export const HIDE_CONTRACT='HIDE_CONTRACT' 
export const GET_CONTRACT='GET_CONTRACT'  
export const FETCH_DRI_THIRD='FETCH_DRI_THIRD'
export const GET_CLAUSE='GET_CLAUSE' 
export const INSPECTION='INSPECTION'
export const GATEINALLOWED='GATEINALLOWED'  
export const GATEIN='GATEIN'  
export const WEIGHMENTIN='WEIGHMENTIN'  
export const WEIGHMENTOUT='WEIGHMENTOUT'
export const DOCREADY='DOCREADY'  
export const LOGISTICS='LOGISTICS' 
export const MATERIALS='MATERIALS' 
export const ARRIVED='ARRIVED'  
export const BOOKINGRECORD='BOOKINGRECORD'
export const TRUCKPAGINATION='TRUCKPAGINATION'
export const DELIVERY='DELIVERY'
export const DELIVERY_HIDE='DELIVERY_HIDE'
export const DELIVERY_SHOW='DELIVERY_SHOW'
export const ARRIVED_ACTION='ARRIVED_ACTION'
export const ARRIVED_HIDE='ARRIVED_HIDE'
export const ARRIVED_SHOW='ARRIVED_SHOW' 
export const VIEW_LR='VIEW_LR' 
export const LR_HIDE='LR_HIDE'
export const BOOK_SHOW_LOADER='BOOK_SHOW_LOADER' 
export const BOOK_HIDE_LOADER='BOOK_HIDE_LOADER'  
export const ALLOCATE_LISTING='ALLOCATE_LISTING'
export const META_DATA='META_DATA'
export const TRANS_PAGE='TRANS_PAGE'
export const TRANS_RECORD='TRANS_RECORD'
export const PAGINATION_STAFF='PAGINATION_STAFF'
export const RECORDS_STAFF='RECORDS_STAFF'
export const CLAUSES_ACTION='CLAUSES_ACTION'
export const DATA_FIELDS='DATA_FIELDS' 
export const GET_IMAGE = 'GET_IMAGE'
export const RESET_IMAGE = 'RESET_IMAGE'
export const RESET_STAFF_DATA = 'RESET_STAFF_DATA'
export const RESET_CONTRACT_DATA = 'RESET_CONTRACT_DATA'
export const PLANTS_RECORDS = 'PLANTS_RECORDS'
export const MESSAGE = 'MESSAGE'










