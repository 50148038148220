import {GET_IMAGE, RESET_IMAGE} from  "../actions/types";

const initialState={
    imgBaseArr: []
} 

const getImgBase =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_IMAGE : 
        let stor=[...state.imgBaseArr]
        stor.push(payload)
        return{
            ...state,
            imgBaseArr : stor 
        }
        case RESET_IMAGE:
         return {
                ...state,
                imgBaseArr : []
            }
        default: return state;  
    }
}
export default getImgBase