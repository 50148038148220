import { post } from './../../../services/requests'
import {REGISTER_HUB} from './types'
import history from './../../../services/history';

export const getHubData = (data) =>{
    return(dispatch)=>{
        post('hub/RegisterHub',{...data})
        .then(response=>{
            const status= response.error
            const message =response.message
             console.log('staff response',response);
             dispatch(sendData(data))
             if(status === true){
                  window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
             }
             else{
                history.push('/dashboard/hubs')
                window.location.reload();
             }
        })
        .catch(error=>{
            console.log('error',error);
        }) 
    }
}
export const sendData = (data) =>{
    return{
        type :REGISTER_HUB,
        payload:data
    }
}