import { get } from './../../../services/requests'
import {GET_SINGLE_ADDRESS, HIDE_SINGLE_ADDRESS } from './types'

export const getAddressData = (addressIndex) =>{ 
    return(dispatch)=>{
        get(`addressbook/GetAddressBookDetails/${addressIndex}`)
        .then(response=>{
             console.log('single address response',response);
             const addressData= response.data
             dispatch(storeAddress(addressData))
             dispatch(hideAddressLoader())
        })
        .catch(error=>{
            console.log('error',error);
        })
    }
} 
export const storeAddress = (addressData) => {
    return {
        type: GET_SINGLE_ADDRESS,
        payload: addressData
    }
}
export const hideAddressLoader = () => { 
    return {
        type: HIDE_SINGLE_ADDRESS
    }
}
