import React, { useEffect, useState } from 'react';
import history from './../../services/history'
import DocViewAddressModal from './Modal/DocViewAddress'
import { getAddress } from '../../state/redux/actions/index';
import $ from 'jquery'
import { useDispatch } from 'react-redux'

const AddAddress = () => {
    const dispatch = useDispatch()
    const [base, setBase] = useState('')
    const [panShow, setPanShow] = useState(false)
    const [gstShow, setGstShow] = useState(false)
    const [addressDetail, setAddressDetail] = useState({
        companyName: "",
        name: "",
        mobileNo: "",
        emailId: "",
        panNo: "",
        gstin: "",
        address: "",
        state: "",
        city: "",
        pinCode: "",
        pan: "",
        panCopy:"",
        gstinUpload: "",
        tripsCount: ""
    })
    const _handleAddress = (e) => {
        setAddressDetail({ ...addressDetail, [e.target.name]: e.target.value })
    }
    const addAddress = () => {
        dispatch(getAddress(addressDetail))
    }

    const OpenAddressBook = () => {
        history.push("/dashboard/address-book")
    }
    const uploadFrontImage = async (e, item) => {
        const file = e.target.files[0]
        const base64 = await convertBase64(file)
        const localArray = addressDetail
        if (item === 'pan') {
            localArray.panCopy = base64
            setPanShow(true)
        }
        else if (item === 'gst') {
            localArray.gstinUpload = base64
            setGstShow(true)
        }
    }

    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }

                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const _sendData = (item) => {
        var newArray = addressDetail
        if (item === 'pan') {
            setBase(newArray.panCopy)
        }
        else if (item === 'gst') {
            setBase(newArray.gstinUpload)
        }
    }

    return (
        <>
            <div className="contentpanel">
                <div className="cust-container">
                    <div className="cust-row flex-algn-cent">
                        <div className='cust-col-4'>
                            <h1 className="title"> 
                                <button className='button bg-orange mr-2 wd10 flex-inline' onClick={OpenAddressBook} >
                                    <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                                </button>
                                Add Address
                            </h1>
                        </div>

                    </div>
                    <hr className='bgg' />
                </div>

                <div className='addAddressBox'>
                    <div className='addaddressinner'>
                        <div className='addaddressform'>
                            <div className='addadresstop'>
                                <div className='addressL'>
                                    <div className='addressInpFields'>
                                        <div className='addressSpans'>
                                            <span>PAN</span>
                                        </div>
                                        <div className='addressDataField'>
                                            <div className='addInpElem'>
                                                <input name="pan" value={addressDetail.pan} onChange={(e) => _handleAddress(e)} className='inputElemAdd' type="text" placeholder='PAN' />
                                            </div>
                                            <div className='addUploadItem'>
                                                <i className="fa fa-upload uploadIconn" ></i>
                                                <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'pan')} type="file" />
                                                {panShow === true ?
                                                    <i className="fa fa-eye myVisibleEye" onClick={() => { _handleClick('DocViewAddressModal'); _sendData('pan') }}></i>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='addressInpFields'>
                                        <div className='addressSpans'>
                                            <span>Contact Person<span className='asterisk'>*</span></span>
                                        </div>
                                        <div className='addressDataField '>
                                            <div className='addInpElemExtra '>
                                                <input name="name" value={addressDetail.name} onChange={(e) => _handleAddress(e)} className='inputElemAdd' type="text" placeholder='Contact Person Name' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='addressInpFields'>
                                        <div className='addressSpans'>
                                            <span>Email</span>
                                        </div>
                                        <div className='addressDataField '>
                                            <div className='addInpElemExtra '>
                                                <input name="emailId" value={addressDetail.emailId} onChange={(e) => _handleAddress(e)} className='inputElemAdd' type="email" placeholder='Email-Id' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='addressInpFields'>
                                        <div className='addressSpans'>
                                            <span>State<span className='asterisk'>*</span></span>
                                        </div>
                                        <div className='addressDataField '>
                                            <div className='addInpElemExtra '>
                                                <input name="state" value={addressDetail.state} onChange={(e) => _handleAddress(e)} className='inputElemAdd' type="text" placeholder='State' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='addressInpFields'>
                                        <div className='addressSpans'>
                                            <span>City<span className='asterisk'>*</span></span>
                                        </div>
                                        <div className='addressDataField '>
                                            <div className='addInpElemExtra '>
                                                <input name="city" value={addressDetail.city} onChange={(e) => _handleAddress(e)} className='inputElemAdd' type="text" placeholder='City' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='addressL nobrdr'>
                                    <div className='addressInpFields'>
                                        <div className='addressSpans'>
                                            <span>Company<span className='asterisk'>*</span></span>
                                        </div>
                                        <div className='addressDataField '>
                                            <div className='addInpElemExtra '>
                                                <input name="companyName" value={addressDetail.companyName} onChange={(e) => _handleAddress(e)} className='inputElemAdd' type="text" placeholder='Company Name' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='addressInpFields'>
                                        <div className='addressSpans'>
                                            <span>Mobile<span className='asterisk'>*</span></span>
                                        </div>
                                        <div className='addressDataField '>
                                            <div className='addInpElemExtra '>
                                                <input name="mobileNo" value={addressDetail.mobileNo} onChange={(e) => _handleAddress(e)} className='inputElemAdd' type="number" placeholder='Mobile Number' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='addressInpFields'>
                                        <div className='addressSpans'>
                                            <span>Address<span className='asterisk'>*</span></span>
                                        </div>
                                        <div className='addressDataField '>
                                            <div className='addInpElemExtra '>
                                                <input name="address" value={addressDetail.address} onChange={(e) => _handleAddress(e)} className='inputElemAdd' type="text" placeholder='Address' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='addressInpFields'>
                                        <div className='addressSpans'>
                                            <span>GSTIN</span>
                                        </div>
                                        <div className='addressDataField'>
                                            <div className='addInpElem'>
                                                <input name="gstin" value={addressDetail.gstin} onChange={(e) => _handleAddress(e)} className='inputElemAdd' type="text" placeholder='GSTIN' />
                                            </div>
                                            <div className='addUploadItem'>
                                                <i className="fa fa-upload uploadIconn" ></i>
                                                <input className='addUploadfile' onChange={(e) => uploadFrontImage(e, 'gst')} type="file" />
                                                {gstShow === true ?
                                                    <i className="fa fa-eye myVisibleEye" onClick={() => { _handleClick('DocViewAddressModal'); _sendData('gst') }}></i>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='addressInpFields'>
                                        <div className='addressSpans'>
                                            <span>PinCode<span className='asterisk'>*</span></span>
                                        </div>
                                        <div className='addressDataField '>
                                            <div className='addInpElemExtra '>
                                                <input name="pinCode" value={addressDetail.pinCode} onChange={(e) => _handleAddress(e)} className='inputElemAdd' type="number" placeholder='PinCode' />
                                            </div>
                                        </div>
                                    </div>   
                                </div>



























                            </div>
                            <div className='addaddressbottom'>
                                <button onClick={() => addAddress()}>Add to Address Book  </button>
                            </div>
                        </div>
                    </div>
                </div>
                <DocViewAddressModal closeModal={_closeModal} imageData={base} />
            </div>
        </>
    );
};

export default AddAddress;

