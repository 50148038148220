import { post } from './../../../services/requests'
import $ from 'jquery';

export const allocateTruck = (vehicleDetails) => {
    return () => {
        post('booking/truck-allocate', {...vehicleDetails })
            .then(response => {
                console.log('allocate response', response);
                var status = response.error
                var message = response.message
                console.log('allocate Truck response', response);
                if(status === false){
                      window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                 }
                 if(status === false){
                        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                        $(".custom-modal").removeClass("custom-modal-is-visible")
                 }
            })
            .catch(error => {
                console.log('error', error);
            })
    }
}

