import React from 'react'

const Date = (props) =>{ 
    const { ele, mainIndex, inputValueHandler, removeItem } = props;
    return(
        <>
            <div className='FieldElem alignCenter custBrdrBox'>
            <i className="fa fa-minus absMius" onClick={(e)=>removeItem({e,index: mainIndex})} ></i>
                <input className='inputField inlineBlock' type={ele.type} value={ele.value} onChange={(e)=>inputValueHandler({ e, index: mainIndex})}/>
                {/* <input className='inputField inlineBlock' type={ele.type1} value="kjhhj" onChange={(e)=>inputValueHandler({index: mainIndex, e})}/> */}
            </div>
        </>
    )
}

export default Date;