import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import history from './../../services/history';
import TransLogo from './../../static/images/credlynk_logo.png'
import moment from 'moment';
import analytics from './../../static/images/icon/buttons/Analytics.svg'
import tracking from './../../static/images/icon/buttons/Tracking.svg'
import AnalysisModal from './../Dashboard/Modal/Analysis'
import TrackingModal from './../Dashboard/Modal/Tracking'
import axios from 'axios'
import BookDetail from './../Dashboard/Modal/BookDetail'

const BookingMultDoc = (props) => {
    const key = props.location.state.key
    const [getUrlParam, setUrlParam] = useState('')
    const allData = props.location.state
    const bookingDetail = props.location.state.bookingDetail
    const [LrArr, setLrArr] = useState([])
    const [param, setParam] = useState('')
    const [imgItem, setItem] = useState('')
    const [generateObj, setGenerateObj] = useState({
        ind: '',
        truckAllocationId: "",
        shipmentGID: "",

    })
    const [lrObject, setLrObject] = useState(
        {
            pageSize: 10,
            bookmark: "",
            truckAllocationId: "",
            shipmentGID: ""
        }
    )
    const [arrivedObj, setArrivedObj] = useState({ lrId: '' })

    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    useEffect(() => {
        setTimeout(() => {
            toogleHubView(allData.num)
        }, 100);
        setUrlParam(allData.url_param)
        lrObject.shipmentGID = bookingDetail.ShipmentGID
        lrObject.truckAllocationId = bookingDetail.TruckDetails.TruckAllocationId
        generateObj.shipmentGID = bookingDetail.ShipmentGID
        generateObj.truckAllocationId = bookingDetail.TruckDetails.TruckAllocationId
        generatePod();
    }, [param])

    const toogleHubView = (index, check = true) => {
        if ($(".collapse-view").eq(index).hasClass("open") && check) {
            $(".collapse-view").eq(index).removeClass("open");
            $(".collapse-view").eq(index).animate({ height: 35 }, 500);
        } else {
            let height =
                Number($(".collapse-view").eq(index).children("table").height()) + 2;
            $(".collapse-view")
                .eq(index)
                .animate({ height }, 500, () => {
                    $(".collapse-view").eq(index).addClass("open");
                });
        }
    }
    const _goBack = () => {
        history.goBack()
    }
    const storeInd = (index) => {
        let tempObj = { ...generateObj }
        tempObj.ind = LrArr[index]._id
        setGenerateObj(tempObj)
    }
    const generatePod = () => {
        if (allData.num === '3') {
            if (lrObject.truckAllocationId === '' && lrObject.truckAllocationId === undefined && lrObject.shipmentGID === '' && lrObject.shipmentGID === undefined) {
            }
            else {
                const token = localStorage.getItem('secretkey')
                const config = {
                    headers: { Authorization: token }
                };
                axios.post('https://www.betaenterprise.lynkit.io/api/booking/GetAllLr', { ...lrObject }, { headers: config.headers })
                    .then(response => {
                        const res = response.data.data
                        console.log('res', res)
                        setLrArr(res)
                        const error = response.data.data.error
                        const message = response.data.data.message
                        if (error === false) {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                        else { }
                    })
                    .catch(error => {
                        console.log('err', error);
                    })
            }
        }
        else {

        }
    }
    const arrived = (item) => {
        arrivedObj.lrId = item._id
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: "Are you sure ?", title: 'Alert!!', type: 'confirm', autohide: true, confirmVal: "Yes", showInput: false, }, (result) => {
            if (result.type === 'confirm') {
                axios.post('https://www.betaenterprise.lynkit.io/api/booking/ArrivedAtDestination', { ...arrivedObj }, { headers: config.headers })
                    .then(response => {
                        const res = response.data
                        console.log('res', res)
                        const error = response.data.error
                        const message = response.data.message
                        if (error === false) {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Success!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            history.push('/dashboard/arrived')
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', type: 'confirm', autohide: true, confirmVal: "Okay", showInput: false, inputPlaceholder: "Reason for cancellation?" }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('err', error);
                    })

            }
        })

    }
    const toggleRepport = (index) => {
        if ($(".reportElem").eq(index).hasClass("close")) {
            $(".reportElem").eq(index).removeClass("close");
            $(".reportElem").eq(index).addClass("open");
            $(".reportElem").parent().parent().parent().parent().parent().height("auto")

        } else {
            $(".reportElem").eq(index).removeClass("open");
            $(".reportElem").eq(index).addClass("close");

        }
    }
    const SharedData = (item) => {
        setItem(item)
    }
    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className='cust-col-4 flex-algn-cent'>
                    <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                        <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                    </button>
                    <h1 className="title">
                        Documents
                    </h1>
                </div>
                <div className='cust-col-5 flex-jc-end'>
                    <h1 className="title font-bold orange_color"></h1>
                </div>
            </div>
            <hr className='bgg' />
            <div className="cust-container">
                <div className="cust-row">
                    <div className="cust-col-10">
                        <div className="table-responsive collapse-view reduceMargin ">
                            <div className="collapsable_icon" onClick={() => { toogleHubView(0) }}></div>
                            <table className='cctbl no_brdr'>
                                <thead>
                                    <tr className="bg_orange">
                                        <th colSpan="6">Booking Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <div className='bookingDetailDiv'>
                                            <div className='cust-row flex-jc-end mg-btm-15'>
                                                {localStorage.getItem("userGroup") !== 'transporter' ?
                                                    <div className='cust-col-6 flex-jc-end'>
                                                        <button className='button bg-orange-out mr-2 wd10'>
                                                            <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                                                            <div className="titleBoxnew">
                                                                <span>Edit</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                    : ''}
                                            </div>
                                            {getUrlParam === 'document-ready' || getUrlParam === 'logistics-gate-out' || getUrlParam === 'material-gate-out' || getUrlParam === 'arrived' ?
                                                <div className="vd_cont">
                                                    <span className='overflowingText'>TRUCK REPORTING</span>
                                                    <div className="vd_cont_tbl brtd">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Shipment GID
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                <span className='font-weight-bold'>{bookingDetail.ShipmentGID}</span>
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Reference Ship GID
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.ReferenceShipGID}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Transport Mode GID
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TransportModeGID}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Source Location GID
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.SourceLocationGID}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Destination Location GID
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.DestinationLocationGID}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Planning Type
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.PlanningType}
                                                            </div>
                                                        </div>

                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Created By
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                -
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Creation Day
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {moment(bookingDetail.AddedOn).format('DD/MM/YYYY')}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Last Update On
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                -
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Last Updated By
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                -
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vd_cont_tbl no_btr">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Status
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                <span className='font-weight-bold'>Truck Allocation Pending</span>
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Truck Reporting Time
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckReportingTime !== '' ? moment(bookingDetail.TruckReportingTime).format('h:mm:ss a') : "-"}
                                                            </div>

                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Service Provider GID
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.ServiceProviderGID}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Ship Quantity Accepted
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.ShipQuantityAccepted}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Total Weight
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TotalWeight}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Total Weight UOM Code
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TotalWeightUOMCode}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Contract ID
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.ContractId !== '' ? bookingDetail.ContractId : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                SO Number
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.SoNo !== "" ? bookingDetail.SoNo : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Goods Type
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.GoodsType}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                            {getUrlParam === 'document-ready' || getUrlParam === 'logistics-gate-out' || getUrlParam === 'material-gate-out' || getUrlParam === 'arrived' ?
                                                <div className="vd_cont">
                                                    <span className='overflowingText'>VEHICLE INSPECTION</span>
                                                    <div className="vd_cont_tbl brtd">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Inspection DATE & Time
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? moment(bookingDetail.TruckDetails.Inspection[0].InspectionTime).format("DD/MM/YYYY") : '-'} ,
                                                                {bookingDetail.TruckDetails ? moment(bookingDetail.TruckDetails.Inspection[0].InspectionTime).format("h:mm:ss a") : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Inspection Status
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? bookingDetail.TruckDetails.Inspection[0].InspectionStatus : '-'}
                                                                <button className='reportBtn' onClick={() => toggleRepport(0)}>View Report</button>
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Vehicle No.
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? bookingDetail.TruckDetails.VehicleNumber : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Vehicle Type
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? bookingDetail.TruckDetails.VehicleType : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Driver Name
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails.DriverDetails !== [] ? bookingDetail.TruckDetails.DriverDetails.map((a, b) => { return (a.name) }) : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vd_cont_tbl no_btr">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                User
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? '-' : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                User Type
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? '-' : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Gate Number
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? '5' : '5'}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                DL Number
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails.DriverDetails !== [] ? bookingDetail.TruckDetails.DriverDetails.map((a, b) => { return (a.dlNumber) }) : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Driver Mobile
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails.DriverDetails !== [] ? bookingDetail.TruckDetails.DriverDetails.map((a, b) => { return (a.mobileNo) }) : '-'}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                : ''}
                                            {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.Inspection[0] && bookingDetail.TruckDetails.Inspection[0].InspectionReport ?
                                                <div className='reportElem close'>
                                                    {bookingDetail && bookingDetail.TruckDetails.Inspection[0] && bookingDetail.TruckDetails.Inspection[0].InspectionReport ? bookingDetail.TruckDetails.Inspection[0].InspectionReport.map((item, index) => {
                                                        return (
                                                            item.type === "vehicleChecks" ?
                                                                <table key={index} className='cctbl inspectDetTb'>
                                                                    <thead>
                                                                        <tr class="bg_black">
                                                                            <div className='insTableHead'>
                                                                                <div className='insHeader1'>
                                                                                    <span>Vehicle Checks</span>
                                                                                </div>
                                                                                <div className='insHeader2'>
                                                                                    <span>{item.status === 'Fail' ? <span className='inspectFail'>{item.status} </span> : <span className='inspectPass'>{item.status} </span>}</span>
                                                                                </div>
                                                                            </div>
                                                                        </tr>
                                                                        <tr class="bg_black">
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <div>
                                                                                <div className="btblock">
                                                                                    <div className='inpectionInner1'>
                                                                                        <div className='inpectionHeader'>
                                                                                            <div className='inpectBox11'>
                                                                                                <span className='inpectHeadSpan'>SNo.</span>
                                                                                            </div>
                                                                                            <div className='inpectBox33'>
                                                                                                <span className='inpectHeadSpan'>Clauses</span>
                                                                                            </div>
                                                                                            <div className='inpectBox22'>
                                                                                                <span className='inpectHeadSpan'>Status</span>
                                                                                            </div>
                                                                                            <div className='inpectBox22'>
                                                                                                <span className='inpectHeadSpan'>Reason</span>
                                                                                            </div>
                                                                                            <div className='inpectBox22'>
                                                                                                <span className='inpectHeadSpan'>Action</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        {item.checks.map((a, b) => {
                                                                                            return (
                                                                                                <div className='inspectBody'>
                                                                                                    <div className='inpectBox11'>
                                                                                                        <span>{b + 1}</span>
                                                                                                    </div>
                                                                                                    <div className='inpectBox33'>
                                                                                                        <span>{a.clause}</span>
                                                                                                    </div>
                                                                                                    <div className='inpectBox22'>
                                                                                                        {a.result === 'Pass' ? <span className='inpectHeadSpan inspectPass'>Pass</span>
                                                                                                            :
                                                                                                            <span className='inpectHeadSpan inspectFail'>Fail</span>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className='inpectBox22'>
                                                                                                        <span className='inpectHeadBody fontCCL'>{a.result === 'Fail' ? a.reason : '-'}</span>
                                                                                                    </div>
                                                                                                    <div className='inpectBox22'>
                                                                                                        <span className='inpectHeadBody fontCCL '>
                                                                                                            <span className='inpectHeadBody'>
                                                                                                                {a.attachedDocuments && a.attachedDocuments.length !== 0 ?
                                                                                                                    <i className="fa fa-eye InspectionEye" onClick={() => { _handleClick('BookDeatilModal'); SharedData(a.attachedDocuments[0].documentKey) }}></i>
                                                                                                                    : '-'}
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                : ''
                                                        )
                                                    }) : ''}
                                                    {bookingDetail.TruckDetails.Inspection[0] && bookingDetail.TruckDetails.Inspection[0].InspectionReport ? bookingDetail.TruckDetails.Inspection[0].InspectionReport.map((item, index) => {
                                                        return (
                                                            item.type === "validityChecks" ?
                                                                <table key={index} className='cctbl inspectDetTb'>
                                                                    <thead>
                                                                        <tr class="bg_black">
                                                                            <div className='insTableHead'>
                                                                                <div className='insHeader1'>
                                                                                    <span>Statutory Checks</span>
                                                                                </div>
                                                                                <div className='insHeader2'>
                                                                                    <span>{item.status === 'Fail' ? <span className='inspectFail'>{item.status} </span> : <span className='inspectPass'>{item.status} </span>}</span>
                                                                                </div>
                                                                            </div>
                                                                        </tr>
                                                                        <tr class="bg_black">

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>

                                                                            <div>
                                                                                <div className="btblock">
                                                                                    <div className='inpectionInner1'>
                                                                                        <div className='inpectionHeader'>
                                                                                            <div className='inpectBox11'>
                                                                                                <span className='inpectHeadSpan'>SNo.</span>
                                                                                            </div>
                                                                                            <div className='inpectBox33'>
                                                                                                <span className='inpectHeadSpan'>Clauses</span>
                                                                                            </div>
                                                                                            <div className='inpectBox33'>
                                                                                                <span className='inpectHeadSpan'>Expiry</span>
                                                                                            </div>
                                                                                            <div className='inpectBox33'>
                                                                                                <span className='inpectHeadSpan'>Action</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        {item.checks.map((a, b) => {
                                                                                            return (
                                                                                                <div key={b} className='inspectBody'>
                                                                                                    <div className='inpectBox11'>
                                                                                                        <span>{b + 1}</span>
                                                                                                    </div>
                                                                                                    <div className='inpectBox33'>
                                                                                                        <span>{a.clause}</span>
                                                                                                    </div>
                                                                                                    <div className='inpectBox33'>
                                                                                                        <span className='inpectHeadBody fontCCL '>
                                                                                                            {a.date}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div className='inpectBox33'>
                                                                                                        <span className='inpectHeadBody'>{a.result === 'Fail' ? <span className='inspectFail'>{a.result} </span> : <span className='inspectPass'>{a.result} </span>}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                : ''
                                                        )
                                                    }) : ''}
                                                    {bookingDetail.TruckDetails.Inspection[0] && bookingDetail.TruckDetails.Inspection[0].InspectionReport ? bookingDetail.TruckDetails.Inspection[0].InspectionReport.map((item, index) => {
                                                        return (
                                                            item.type === "driverChecks" ?
                                                                <table className='cctbl inspectDetTb'>
                                                                    <thead>
                                                                        <tr class="bg_black">
                                                                            <div className='insTableHead'>
                                                                                <div className='insHeader1'>
                                                                                    <span>Driver Checks</span>
                                                                                </div>
                                                                                <div className='insHeader2'>
                                                                                    <span>{item.status === 'Fail' ? <span className='inspectFail'>{item.status} </span> : <span className='inspectPass'>{item.status} </span>}</span>
                                                                                </div>
                                                                            </div>
                                                                        </tr>
                                                                        <tr class="bg_black">

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>

                                                                            <div>
                                                                                <div className="btblock">
                                                                                    <div className='inpectionInner1'>
                                                                                        <div className='inpectionHeader'>
                                                                                            <div className='inpectBox11'>
                                                                                                <span className='inpectHeadSpan'>SNo.</span>
                                                                                            </div>
                                                                                            <div className='inpectBox33'>
                                                                                                <span className='inpectHeadSpan'>Clauses</span>
                                                                                            </div>
                                                                                            <div className='inpectBox22'>
                                                                                                <span className='inpectHeadSpan'>Status</span>
                                                                                            </div>
                                                                                            <div className='inpectBox22'>
                                                                                                <span className='inpectHeadSpan'>Reason</span>
                                                                                            </div>
                                                                                            <div className='inpectBox22'>
                                                                                                <span className='inpectHeadSpan'>Action</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        {item.checks.map((a, b) => {
                                                                                            return (
                                                                                                <div className='inspectBody'>
                                                                                                    <div className='inpectBox11'>
                                                                                                        <span>{b + 1}</span>
                                                                                                    </div>
                                                                                                    <div className='inpectBox33'>
                                                                                                        <span>{a.clause}</span>
                                                                                                    </div>
                                                                                                    <div className='inpectBox22'>
                                                                                                        {a.result === 'Pass' ? <span className='inpectHeadSpan inspectPass'>Pass</span>
                                                                                                            :
                                                                                                            <span className='inpectHeadSpan inspectFail'>Fail</span>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className='inpectBox22'>
                                                                                                        <span className='inpectHeadBody fontCCL'>{a.result === 'Fail' ? a.reason : '-'}</span>
                                                                                                    </div>
                                                                                                    <div className='inpectBox22'>
                                                                                                        <span className='inpectHeadBody'>
                                                                                                            {a.attachedDocuments && a.attachedDocuments.length !== 0 ?
                                                                                                                <i className="fa fa-eye InspectionEye" onClick={() => {
                                                                                                                    _handleClick('BookDeatilModal');

                                                                                                                    SharedData(a && a.attachedDocuments[0] && a.attachedDocuments[0].documentKey ? a.attachedDocuments[0].documentKey : '')
                                                                                                                }}></i>
                                                                                                                : '-'}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                : ''
                                                        )
                                                    }) : ''}
                                                </div>
                                                :
                                                <div className='reportElemNotFound'>
                                                    <span>No Report found..</span>
                                                </div>
                                            }
                                            {getUrlParam === 'document-ready' || getUrlParam === 'logistics-gate-out' || getUrlParam === 'material-gate-out' || getUrlParam === 'arrived' ?
                                                <div className="vd_cont">
                                                    <span className='overflowingText'>GATE IN REQUEST</span>
                                                    <div className="vd_cont_tbl brtd">

                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Requested Gate Number
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? bookingDetail.TruckDetails.RequestedGateNo : "-"}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="vd_cont_tbl no_btr">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Requested Date & Time
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? `${moment(bookingDetail.TruckDetails.RequestedDateTime).format("DD/MM/YYYY")},  ${moment(bookingDetail.TruckDetails.RequestedDateTime).format("h:mm:ss a")}` : "-"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                            {getUrlParam === 'document-ready' || getUrlParam === 'logistics-gate-out' || getUrlParam === 'material-gate-out' || getUrlParam === 'arrived' ?
                                                <div className="vd_cont">
                                                    <span className='overflowingText'>GATED IN</span>
                                                    <div className="vd_cont_tbl brtd">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Gate Number
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? bookingDetail.TruckDetails.RequestedGateNo : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                HIL Trip ID
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                -
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vd_cont_tbl no_btr">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Gate In Date & Time
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? moment(bookingDetail.TruckDetails.GateInTime).format("DD/MM/YYYY") : '-'} ,
                                                                {bookingDetail.TruckDetails ? moment(bookingDetail.TruckDetails.GateInTime).format("h:mm:ss a") : '-'}

                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Gate In Allowed By
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">

                                                                <span>{bookingDetail.TruckDetails && bookingDetail.TruckDetails.GateInAllowedBy && bookingDetail.TruckDetails.GateInAllowedBy.name ? bookingDetail.TruckDetails.GateInAllowedBy.name : "-"}</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}


                                            {getUrlParam === 'document-ready' || getUrlParam === 'logistics-gate-out' || getUrlParam === 'material-gate-out' || getUrlParam === 'arrived' ?
                                                <div className="vd_cont">
                                                    <span className='overflowingText'>WEIGHMENT IN</span>
                                                    <div className="vd_cont_tbl brtd">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Tare Weight
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.TareWeight ? bookingDetail.TruckDetails.TareWeight : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Weighment Number
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.WeighmentNo ? bookingDetail.TruckDetails.WeighmentNo : "-"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vd_cont_tbl no_btr">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Weighment Date & Time
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails ? `${moment(bookingDetail.TruckDetails.WeighmentDateTime).format("DD/MM/YYYY")} , ${moment(bookingDetail.TruckDetails.WeighmentDateTime).format("h:mm:ss a")}` : "-"}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                : ''}
                                            {getUrlParam === 'document-ready' || getUrlParam === 'logistics-gate-out' || getUrlParam === 'material-gate-out' || getUrlParam === 'arrived' ?
                                                <div className="vd_cont ">
                                                    <span className='overflowingText'>WEIGHMENT OUT</span>
                                                    <div className="vd_cont_tbl brtd">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Gross Weight
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.GrossWeight ? bookingDetail.TruckDetails.GrossWeight : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Loaded Weight
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.FirstLoadedWeight ? bookingDetail.TruckDetails.FirstLoadedWeight : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Loading Date & Time
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.LoadedDateTime ? `${moment(bookingDetail.TruckDetails.LoadedDateTime).format("DD/MM/YYYY")} , ${moment(bookingDetail.TruckDetails.LoadedDateTime).format("h:mm:ss a")}` : "-"}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="vd_cont_tbl no_btr">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Asset Tracker ID
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.AssetTrackerId ? bookingDetail.TruckDetails.AssetTrackerId : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Fixed Tracker ID
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.FixedTrackerID ? bookingDetail.TruckDetails.FixedTrackerID : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Loading Slip Number
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.LoadingSlipNo ? bookingDetail.TruckDetails.LoadingSlipNo : "-"}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Coil Number
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.CoilNo ? bookingDetail.TruckDetails.CoilNo : "-"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}

                                            {getUrlParam === 'document-ready' || 'logistics-gate-out' || getUrlParam === 'material-gate-out' || getUrlParam === 'arrived' ?
                                                <div className="vd_cont" >
                                                    <span className='overflowingText'>DOCUMENT READY</span>
                                                    <div className="vd_cont_tbl brtd">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Document Ready By
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails.DocumentReadyBy ? bookingDetail.TruckDetails.DocumentReadyBy.name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vd_cont_tbl no_btr">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Document Ready Date & Time
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails ? moment(bookingDetail.TruckDetails.DocumentReadyDate).format('DD/MM/YYYY') : '-'} ,
                                                                {bookingDetail.TruckDetails ? moment(bookingDetail.TruckDetails.DocumentReadyDate).format('h:mm:ss a') : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='wd_15'>

                                                    </div>
                                                </div>
                                                : ''}

                                            {getUrlParam === 'logistics-gate-out' || getUrlParam === 'material-gate-out' || getUrlParam === 'arrived' ?
                                                <div className="vd_cont" >
                                                    <span className='overflowingText'>LOGISTIC GATE OUT</span>
                                                    <div className="vd_cont_tbl brtd">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Logistics Gate-Out By
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.LogisticsGateOutBy ? bookingDetail.TruckDetails.LogisticsGateOutBy.name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="vd_cont_tbl no_btr">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Logistics Gate-Out Date & Time
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.LogisticsGateOutTime ? moment(bookingDetail.TruckDetails.LogisticsGateOutTime).format('DD/MM/YYYY') : '-'} ,
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.LogisticsGateOutTime ? moment(bookingDetail.TruckDetails.LogisticsGateOutTime).format('h:mm:ss a') : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='wd_15'>

                                                    </div>
                                                </div>
                                                : ''}
                                            {getUrlParam === 'material-gate-out' || getUrlParam === 'arrived' ?
                                                <div className="vd_cont">
                                                    <span className='overflowingText'>MATERIAL GATE OUT</span>
                                                    <div className="vd_cont_tbl brtd">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Material Gate-out By
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails.MaterialGateOutBy ? bookingDetail.TruckDetails.MaterialGateOutBy.name : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Material Gate Out Status
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail.TruckDetails && bookingDetail.TruckDetails.Inspection[1] && bookingDetail.TruckDetails.Inspection[1].InspectionStatus ? bookingDetail.TruckDetails.Inspection[1].InspectionStatus : '-'}
                                                                <button className='reportBtn' onClick={() => toggleRepport(1)}>View Report</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vd_cont_tbl no_btr">
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Material Gate Out Date & Time
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.MaterialGateOutTime ? moment(bookingDetail.TruckDetails.MaterialGateOutTime).format("DD/MM/YYYY") : '-'} ,
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.MaterialGateOutTime ? moment(bookingDetail.TruckDetails.MaterialGateOutTime).format("h:mm:ss a") : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec">
                                                            <div className="vd_cont_tbl_sec_1_new">
                                                                Total Coils
                                                            </div>
                                                            <div className="vd_cont_tbl_sec_2">
                                                                {bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.TotalCoils ? bookingDetail.TruckDetails.TotalCoils : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''}
                                            {getUrlParam === 'material-gate-out' || getUrlParam === 'arrived' ?
                                                bookingDetail && bookingDetail.TruckDetails && bookingDetail.TruckDetails.Inspection[1] && bookingDetail.TruckDetails.Inspection[1].InspectionReport ?
                                                    <div className='reportElem close'>
                                                        {bookingDetail.TruckDetails.Inspection[1] && bookingDetail.TruckDetails.Inspection[1].InspectionReport ? bookingDetail.TruckDetails.Inspection[1].InspectionReport.map((item, index) => {
                                                            return (
                                                                item.type === "GateOutChecks" ?
                                                                    <table key={index} className='cctbl inspectDetTb'>
                                                                        <thead>
                                                                            <tr class="bg_black">
                                                                                <div className='insTableHead'>
                                                                                    <div className='insHeader1' style={{ width: 'auto' }}>
                                                                                        <span>Gate Out Inspection</span>
                                                                                    </div>
                                                                                    <div className='insHeader2'>
                                                                                        <span>{item.status === 'Fail' ? <span className='inspectFail'>{item.status} </span> : <span className='inspectPass'>{item.status} </span>}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </tr>
                                                                            <tr class="bg_black">
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <div>
                                                                                    <div className="btblock">
                                                                                        <div className='inpectionInner1'>
                                                                                            <div className='inpectionHeader'>
                                                                                                <div className='inpectBox22'>
                                                                                                    <span className='inpectHeadSpan'>SNo.</span>
                                                                                                </div>
                                                                                                <div className='inpectBox33'>
                                                                                                    <span className='inpectHeadSpan'>Clauses</span>
                                                                                                </div>
                                                                                                <div className='inpectBox33'>
                                                                                                    <span className='inpectHeadSpan'>Status</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            {item.checks.map((a, b) => {
                                                                                                return (
                                                                                                    <div className='inspectBody'>
                                                                                                        <div className='inpectBox22'>
                                                                                                            <span>{b + 1}</span>
                                                                                                        </div>
                                                                                                        <div className='inpectBox33'>
                                                                                                            <span>{a.clause}</span>
                                                                                                        </div>
                                                                                                        <div className='inpectBox33'>
                                                                                                            {a.result === 'Pass' ? <span className='inpectHeadSpan inspectPass'>Pass</span>
                                                                                                                :
                                                                                                                <span className='inpectHeadSpan inspectFail'>Fail</span>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    : ''
                                                            )
                                                        }) : ''}

                                                    </div>
                                                    :
                                                    <div className='reportElemNotFound'>
                                                        <span>No Report found..</span>
                                                    </div>
                                                : ""}
                                        </div>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                        <div className="table-responsive collapse-view reduceMargin ">
                            <div className="collapsable_icon" onClick={() => { toogleHubView(1) }}></div>
                            <table className='cctbl no_brdr'>
                                <thead>
                                    <tr className="bg_orange">
                                        <th colSpan="6">Loading Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <div className='bookingDetailDiv '>
                                            {getUrlParam === 'document-ready' || getUrlParam === 'logistics-gate-out' || getUrlParam === 'material-gate-out' || getUrlParam === 'arrived' ?
                                                <div className="vd_cont algnVert1">
                                                    <span className='overflowingText alterSides'>DELIVERY LOCATIONS</span>
                                                    <div className="vd_cont_tbl algnCent algnVert1">
                                                        <div className="vd_cont_tbl_sec ">
                                                            <div className="vd_cont_tbl_sec_1_new_new">
                                                                <span className='highlightText'>Driver 1</span>
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec brdrNone locationsElem fff">
                                                            <div className='source'>
                                                                <span>Source</span>
                                                                <span>Hindalco Dahej</span>
                                                            </div>
                                                            <div className='destinations'>
                                                                <div className='midDestin'>
                                                                    <span>Destination 1</span>
                                                                    <span>Hindalco Dahej</span>
                                                                    <div className='templates'>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Company Name</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Address</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Contract Name</span>
                                                                                <span className='docValue'>xxxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Contact Person Name</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Arrived at</span>
                                                                                <span className='docValue'>xxxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Shipment Details</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className='tempRow'></div> */}
                                                                        <div className='tempRow alnCent'>
                                                                            <button>Arrived</button>
                                                                            <button>Generate PoD</button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className='destinations'>
                                                                <div className='midDestin'>
                                                                    <span>Destination 2</span>
                                                                    <span>Hindalco Dahej</span>
                                                                    <div className='templates'>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Company Name</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Address</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Contract Name</span>
                                                                                <span className='docValue'>xxxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Contact Person Name</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Arrived at</span>
                                                                                <span className='docValue'>xxxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Shipment Details</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className='tempRow'></div> */}
                                                                        <div className='tempRow alnCent'>
                                                                            <button>Arrived</button>
                                                                            <button>Generate PoD</button>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className='source'>
                                                                <span>Destination</span>
                                                                <span>Hindalco Dahej</span>
                                                            </div>

                                                        </div>


                                                    </div>

                                                    <div className="vd_cont_tbl algnCent algnVert1">
                                                        <div className="vd_cont_tbl_sec ">
                                                            <div className="vd_cont_tbl_sec_1_new_new">
                                                                <span className='highlightText'>Driver 2</span>
                                                            </div>
                                                        </div>
                                                        <div className="vd_cont_tbl_sec brdrNone locationsElem fff">
                                                            <div className='source'>
                                                                <span>Source</span>
                                                                <span>Hindalco Dahej</span>
                                                            </div>
                                                            <div className='destinations'>
                                                                <div className='midDestin'>
                                                                    <span>Destination 1</span>
                                                                    <span>Hindalco Dahej</span>
                                                                    <div className='templates'>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Company Name</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Address</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Contract Name</span>
                                                                                <span className='docValue'>xxxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Contact Person Name</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Arrived at</span>
                                                                                <span className='docValue'>xxxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Shipment Details</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className='tempRow'></div> */}
                                                                        <div className='tempRow alnCent'>
                                                                            <button>Arrived</button>
                                                                            <button>Generate PoD</button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className='destinations'>
                                                                <div className='midDestin'>
                                                                    <span>Destination 2</span>
                                                                    <span>Hindalco Dahej</span>
                                                                    <div className='templates'>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Company Name</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Address</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Contract Name</span>
                                                                                <span className='docValue'>xxxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Contact Person Name</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='tempRow'>
                                                                            <div className='destElems'>
                                                                                <span className='docHeading'>Arrived at</span>
                                                                                <span className='docValue'>xxxxx</span>
                                                                            </div>
                                                                            <div className='destElems1'>
                                                                                <span className='docHeading'>Shipment Details</span>
                                                                                <span className='docValue'>xxxx</span>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className='tempRow'></div> */}
                                                                        <div className='tempRow alnCent'>
                                                                            <button>Arrived</button>
                                                                            <button>Generate PoD</button>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className='source'>
                                                                <span>Destination</span>
                                                                <span>Hindalco Dahej</span>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </div>
                                                : ''}

                                        </div>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                        <div className="table-responsive collapse-view reduceMargin">
                            <div className="collapsable_icon" onClick={() => { toogleHubView(2) }}></div>
                            <table className='cctbl no_brdr'>
                                <thead>
                                    <tr className="bg_orange">
                                        <th colSpan="6">Eway Bill Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <div className='bookingDetailDiv'>
                                            <div className='noDataFound'>
                                                <span>No Data Found</span>
                                            </div>
                                        </div>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                        <div className="table-responsive collapse-view reduceMargin">
                            <div className="collapsable_icon" onClick={() => { toogleHubView(3) }}></div>
                            {LrArr && LrArr.length !== 0 ?
                                LrArr.map((item, ind) => {
                                    return (
                                        <table key={ind} className='cctbl no_brdr'>
                                            <thead>
                                                <tr className="bg_orange">
                                                    <th colSpan="6">LR Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <div className='bookingDetailDiv'>
                                                        <div className='cust-row flex-jc-end mg-btm-15'>
                                                            {localStorage.getItem("userGroup") !== 'goodsowner' ?
                                                                <div className='cust-col-6 flex-jc-end cust6Center'>
                                                                    {item.Status === 'Created' ?
                                                                        key && key === 'material' ?
                                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 less-size' onClick={() => arrived(item, 'arrived')}>Arrived at Destination</button>
                                                                            : ''
                                                                        : ''}

                                                                </div>
                                                                : ''}

                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 less-size' onClick={() => { _handleClick('trackingModal') }}><img src={tracking} alt="none" />Tracking</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 less-size' onClick={() => { _handleClick('analysisModal') }}><img src={analytics} alt="none" />Analytics</button>
                                                            <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 less-size'>
                                                                <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>

                                                            </button>
                                                        </div>
                                                        <div className='form-container'>
                                                            <div class="custom-collapse autoheight">
                                                                <div class="customBdy pdm5">
                                                                    <div className='tbl_div'>
                                                                        <div className='logo_sec_1'>
                                                                            <img src={TransLogo} alt="" />
                                                                        </div>
                                                                        <div className='content_sec_1'>
                                                                            <p className='font-bold font-xl'>{item.Transporter.Name}<span className='font-size'></span></p>
                                                                            <p className='font-lg'>{item.Transporter.address}</p>
                                                                            <p>GSTIN -{item.Transporter.GSTIN}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="custom-collapse autoheight">
                                                                <div class="customHead">
                                                                    Basic Details
                                                                </div>
                                                                <div class="customBdy">
                                                                    <div className='dv_flex pd5 brd_btm'>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>LR No.</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{item.LrNo}</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Shipment GID</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{item.ShipmentGID}</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Contract Type</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{item.ContractType}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='dv_flex pd5 brd_btm'>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>LR Date</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{moment(item.LrDate).format('DD/MM/YYYY')}</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Delivery Type</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{item.DeliveryType}</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Transportation Mode</span>
                                                                        </div>
                                                                        <div className='wdt20'>
                                                                            <span className='font-bold grey_color'>{item.TransportationMode}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='dv_flex pd5 brd_btm'>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Consignor</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{item.Consignor && item.Consignor.PlantName ? item.Consignor.PlantName : '-'}</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Pickup</span>
                                                                        </div>
                                                                        <div className='wdt20'>
                                                                            <span className='font-bold grey_color'>{item.From && item.From.ConsignorAddress ? item.From.ConsignorAddress : '-'}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='dv_flex pd5 brd_btm'>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Consignee</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{item.To.ConsigneeName}</span>
                                                                        </div>

                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Dropoff</span>
                                                                        </div>

                                                                        <div className='wdt20'>
                                                                            <span className='font-bold grey_color'>{item.To && item.To.Address ? item.To.Address : '-'}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="custom-collapse autoheight">
                                                                <div class="customHead">
                                                                    Goods Details
                                                                    <div class="doc_attachment" data-toggle="modal" data-target="#documentModal">
                                                                        <i class="fa fa-paperclip"></i> View Documents
                                                                    </div>
                                                                </div>
                                                                <div class="customBdy">
                                                                    <div className='dv_flex pd5 brd_btm'>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Eway Bill Number</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>NA</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Validity From</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>NA</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Validity Upto</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>NA</span>
                                                                        </div>
                                                                    </div>
                                                                    {item.InvoiceDetails.map((a, b) => {
                                                                        return (
                                                                            <div key={b} className='dv_flex pd5 brd_btm'>
                                                                                <div className='wdt20 brd_rht'>
                                                                                    <span className='font-bold black_color'>Sales Category</span>
                                                                                </div>
                                                                                <div className='wdt20 brd_rht'>
                                                                                    <span className='font-bold grey_color'>{a && a.SaleCategory ? a.SaleCategory : '-'}</span>
                                                                                </div>
                                                                                <div className='wdt20 brd_rht'>
                                                                                    <span className='font-bold black_color'>Sub Category</span>
                                                                                </div>
                                                                                <div className='wdt20 brd_rht'>
                                                                                    <span className='font-bold grey_color'>{a && a.ProductSubGroup ? a.ProductSubGroup : '-'}</span>
                                                                                </div>
                                                                                <div className='wdt20 brd_rht'>
                                                                                    <span className='font-bold black_color'>Total Coils</span>
                                                                                </div>
                                                                                <div className='wdt20 brd_rht'>
                                                                                    <span className='font-bold grey_color'>{item.CoilDetails.length}</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}

                                                                    <div >
                                                                        <div className='dv_flex pd5 brd_btm'>
                                                                            <div className='wdt20 brd_rht'>
                                                                                <span className='font-bold black_color'>Invoice Number</span>
                                                                            </div>
                                                                            <div className='wdt20 brd_rht'>
                                                                                <span className='font-bold grey_color'>{item && item.InvoiceDetails[0] && item.InvoiceDetails[0].InvoiceNumber ? item.InvoiceDetails[0].InvoiceNumber : '-'}</span>
                                                                            </div>
                                                                            <div className='wdt20 brd_rht'>
                                                                                <span className='font-bold black_color'>Invoice Date</span>
                                                                            </div>
                                                                            <div className='wdt20 brd_rht'>
                                                                                <span className='font-bold grey_color'>{item && item.InvoiceDetails[0] && item.InvoiceDetails[0].InvoiceDate ? item.InvoiceDetails[0].InvoiceDate : '-'}</span>
                                                                            </div>
                                                                            <div className='wdt20 brd_rht'>
                                                                                <span className='font-bold black_color'>Invoice Status</span>
                                                                            </div>
                                                                            <div className='wdt20 brd_rht'>
                                                                                <span className='font-bold grey_color'>{item && item.InvoiceDetails[0] && item.InvoiceDetails[0].Invoicestatus ? item.InvoiceDetails[0].Invoicestatus : '-'}</span>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <div className='dv_flex pd5 brd_btm'>
                                                                                <div className='wdt7 brd_rht'>
                                                                                    <span className='font-bold black_color'>S.no</span>
                                                                                </div>
                                                                                <div className='wdt50 brd_rht'>
                                                                                    <span className='font-bold black_color'>Lot Number </span>
                                                                                </div>
                                                                                <div className='wdt50 brd_rht'>
                                                                                    <span className='font-bold black_color'>Lot Quantity</span>
                                                                                </div>
                                                                                <div className='wdt50 brd_rht'>
                                                                                    <span className='font-bold black_color'>Material Type </span>
                                                                                </div>
                                                                                <div className='wdt50 brd_rht'>
                                                                                    <span className='font-bold black_color'>Material Quantity</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {item.CoilDetails.map((a, b) => {
                                                                            return (
                                                                                <div key={b}>
                                                                                    <div className='dv_flex pd5 brd_btm'>
                                                                                        <div className='wdt7 brd_rht'>
                                                                                            <span className='font-bold black_color'>{b + 1}</span>
                                                                                        </div>
                                                                                        <div className='wdt50 brd_rht'>
                                                                                            <span className='font-bold grey_color'>{a.lot_Number}</span>
                                                                                        </div>
                                                                                        <div className='wdt50 brd_rht'>
                                                                                            <span className='font-bold grey_color'>{a.lot_Qty}</span>
                                                                                        </div>
                                                                                        <div className='wdt50 brd_rht'>
                                                                                            <span className='font-bold grey_color'>{a.MaterialType}</span>
                                                                                        </div>
                                                                                        <div className='wdt50 brd_rht'>
                                                                                            <span className='font-bold grey_color'>{a.MaterialQuantity}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <div class="custom-collapse autoheight">
                                                                <div class="customHead">
                                                                    Transportation Details
                                                                </div>
                                                                <div class="customBdy">
                                                                    <div className='dv_flex pd5 brd_btm'>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Vehicle Type</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{item.VehicleType}</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Vehicle Number</span>

                                                                        </div>
                                                                        <div className='wdt20 brd_rht '>
                                                                            <span className='font-bold grey_color'>{item.VehicleNumber}</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Gate in Time</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>{moment(item.GateInTime).format('h:mm:ss a')}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='dv_flex pd5 brd_btm'>
                                                                        <div className='wdt50 brd_rht'>
                                                                            <span className='font-bold black_color'>Loaded On</span>
                                                                        </div>
                                                                        <div className='wdt50 brd_rht'>
                                                                            <span className='font-bold grey_color'>{moment(item.LoadedDateTime).format('DD/MM/YYYY')}, {moment(item.LoadedDateTime).format('h:mm:ss a')}</span>
                                                                        </div>

                                                                        <div className='wdt50 brd_rht'>
                                                                            <span className='font-bold black_color'>Fixed Tracker Id</span>
                                                                        </div>
                                                                        <div className='wdt50 brd_rht'>
                                                                            <span className='font-bold grey_color'>{item.FixedTrackerID}</span>
                                                                        </div>
                                                                    </div>

                                                                    {item.DriverDetails !== [] ? item.DriverDetails.map((a, b) => {
                                                                        return (
                                                                            <div key={b} className='dv_flex pd5 brd_btm'>
                                                                                <div className='wdt20 brd_rht'>
                                                                                    <span className='font-bold black_color'>Driver Name</span>
                                                                                </div>
                                                                                <div className='wdt20 brd_rht'>
                                                                                    <span className='font-bold grey_color'>{a && a.name ? a.name : ''}</span>
                                                                                </div>
                                                                                <div className='wdt20 brd_rht'>
                                                                                    <span className='font-bold black_color'>Driver Mobile</span>
                                                                                </div>
                                                                                <div className='wdt20'>
                                                                                    <span className='font-bold grey_color'>{a && a.mobileNo ? a.mobileNo : ''}</span>
                                                                                </div>
                                                                                <div className='wdt20 brd_rht'>
                                                                                    <span className='font-bold black_color'>DL Number</span>
                                                                                </div>
                                                                                <div className='wdt20 brd_rht'>
                                                                                    <span className='font-bold grey_color'>{a && a.dlNumber ? a.dlNumber : ''}</span>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    }) : ''}

                                                                    {/* <div className='dv_flex pd5 brd_btm'>
                                    <div className='wdt10 brd_rht'>
                                        <span className='font-bold black_color'>Arrived On</span>
                                    </div>
                                    <div className='wdt15 brd_rht'>
                                        <span className='font-bold grey_color'>(Vendor GPS System)</span>
                                    </div>
                                    <div className='wdt10 brd_rht'>
                                        <span className='font-bold black_color'>Delivered On</span>
                                    </div>
                                    <div className='wdt15 brd_rht'>
                                        <span className='font-bold grey_color'>(Lynkit)</span>
                                    </div>
                                    <div className='wdt10 brd_rht'>
                                        <span className='font-bold black_color'>Waiting time at destination</span>
                                    </div>
                                    <div className='wdt15 brd_rht'>
                                        <span className='font-bold grey_color'>(Delivery - Arrival)</span>
                                    </div>
                                    <div className='wdt10 brd_rht'>
                                        <span className='font-bold black_color'>Delivered To</span>
                                    </div>
                                    <div className='wdt15'>
                                        <span className='font-bold grey_color'>(Lynkit)</span>
                                    </div>
                                </div>
                                <div className='dv_flex pd5'>
                                    <div className='wdt10 brd_rht'>
                                        <span className='font-bold black_color'>Signature</span>
                                    </div>
                                    <div className='wdt15 brd_rht'>
                                        <span className='font-bold grey_color'>(Lynkit)</span>
                                    </div>
                                    <div className='wdt15 brd_rht'>
                                        <span className='font-bold black_color'>Delivery confirm By</span>
                                    </div>
                                    <div className='wdt15 brd_rht'>
                                        <span className='font-bold grey_color'>(Lynkit)</span>
                                    </div>
                                    <div className='wdt45 text-center'>
                                        <span className='font-bold green_color'>Verified with OTP : <b className='font-bold'>(Lynkit)</b></span>
                                    </div>
                                </div> */}
                                                                </div>
                                                            </div>
                                                            <div class="custom-collapse autoheight">
                                                                <div class="customHead">
                                                                    Additional Documents
                                                                </div>
                                                                <div class="customBdy">
                                                                    <div className='dv_flex pd5 brd_btm'>
                                                                        <div className='wdt5 brd_rht'>
                                                                            <span className='font-bold black_color'>1.</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>Document Type</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>Challan Receipt</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold black_color'>File Name</span>
                                                                        </div>
                                                                        <div className='wdt20 brd_rht'>
                                                                            <span className='font-bold grey_color'>xyz.pdf</span>
                                                                        </div>
                                                                        <div className='text-center wdt5'>
                                                                            <i className='fa fa-eye orange_color font-lg crsr-ptr'></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div class="custom-collapse autoheight" >
                            <div class="customHead">
                                Policy Details
                            </div>
                            <div class="customBdy">
                                <div className='dv_flex pd5'>
                                    <div className='wdt10 brd_rht'>
                                        <span className='font-bold black_color'>Policy Number</span>
                                    </div>
                                    <div className='wdt15 brd_rht'>
                                        <span className='font-bold grey_color'>TEMP12344</span>
                                    </div>
                                    <div className='wdt10 brd_rht'>
                                        <span className='font-bold black_color'>Name Of Issuer</span>
                                    </div>
                                    <div className='wdt15 brd_rht'>
                                        <span className='font-bold grey_color'>TEST Indud Ltd.</span>
                                    </div>
                                    <div className='wdt10 brd_rht'>
                                        <span className='font-bold black_color'>Issue Date</span>
                                    </div>
                                    <div className='wdt15 brd_rht'>
                                        <span className='font-bold grey_color'>12-01-2022</span>
                                    </div>
                                    <div className='wdt10 brd_rht'>
                                        <span className='font-bold black_color'>Expiry Date</span>
                                    </div>
                                    <div className='wdt15 brd_rht'>
                                        <span className='font-bold grey_color'>12-03-2022</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                                        </div>


                                                    </div>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )
                                })
                                :
                                <div className='reportElemNotFound'>
                                    <span>No Data found..</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <AnalysisModal closeModal={_closeModal} />
            <TrackingModal closeModal={_closeModal} />
            <BookDetail base={imgItem} />
        </div>

    )
}
export default BookingMultDoc