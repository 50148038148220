import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import axios from 'axios';

const InspectImgModal = (props) => {
    const [base, setBase] = useState('')
    const key = props.base

    const _closeModal = () => {
        $("#Inspectimgmodal").removeClass("custom-modal-is-visible")
    } 
    useEffect(() => {
        if (key !== '' || base !== undefined ) {
            const token = localStorage.getItem('secretkey')
            const config = {
                headers: { Authorization: token }
            };
            axios.post('https://www.betaenterprise.lynkit.io/api/common/get-image', { key }, { headers: config.headers })
                .then(response => {
                    const storeBase = response.data.file
                    setBase(storeBase)
                })
                .catch(error => {
                    // dispatch(showError(err))
                })
        }
        else{
        }
    }, [base,key])

    return (
        <section id="Inspectimgmodal" className="custom-modal" style={{ width: '70%', top:'20%' }}>
            <div className="custom-modal-header">
                <h1>Uploaded Document</h1>
                <span className="ct-close" onClick={() => _closeModal("Inspectimgmodal")}></span>
            </div>
            <div className="custom-modal-body"  style={{ overflow: 'auto', background:'#F8F8F8' ,height:'305px' }}>
                <div className="cust-row centerImage">
                    {base !== undefined ?
                        <img className='classicImg' src={base} alt='' />
                        :
                        <div className='noImgCont'>
                            <span>No Image Found</span>
                        </div>
                    }
                </div>
            </div>
        </section>
    )

}

export default InspectImgModal;