import { selectInit } from './../../../services/functions'
import { useEffect } from 'react'
import { getPlantsListing } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react';
import axios from 'axios';
import history from './../../../services/history'
import $ from 'jquery'
import Select from 'react-select';

const ReferTrans = (props) => {
    const [email, setEmail] = useState('')
    const [referData, setReferData] = useState(
        {
            plantId: "",
            emailId: []
        }
    )
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.getPlantsListReducers.plantsData)

    const _handleChange = (e) => {
        setEmail(e.target.value)
    }
    const add = () => {
        let dummyArr = { ...referData }
        dummyArr.emailId = [...dummyArr.emailId, email]
        setReferData(dummyArr)
        setEmail('')
    }
    const removeEmail = (index) => {
        let removeArr = { ...referData }
        removeArr.emailId.splice(index, 1)
        setReferData(removeArr)
    }
    const sendEmail = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/user/refer-transporter', { ...referData }, { headers: config.headers })
            .then(response => {
                // setLoading(false)
                const err = response.data.error
                const message = response.data.message
                history.push('/dashboard/transporter-master')
                if (err === true) {
                    window.$.fn.show_notification({ message: `${message} `, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
                if (err === false) {
                    history.push('/dashboard/transporter-master')
                }
            })
            .catch(error => {
                // dispatch(showError(err))
            })
    }

    useEffect(() => {
        setTimeout(() => {
            selectInit()
        }, 100);
        dispatch(getPlantsListing({
            docType: "plants",
            bookmark: '',
            param: "next"
        }))
    }, [])
    const addPlant = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
        history.push('/dashboard/plants')
    }

    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white',
            width: '100%',

        }),
        control: (styles, state) => {
            return {
                ...styles,
                backgroundColor: "transparent",


                boxShadow:
                    state.isFocused
                        ? "0 0 3px #28a745"
                        : state.isFocused
                            ? "0 0 3px #dc3545"
                            : "none",
                cursor: "pointer",
                ":focus": {
                    ...styles[":focus"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                ":active": {
                    ...styles[":active"],
                    //   borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },

                ":hover": {
                    ...styles[":hover"],
                    // borderColor: "var(--clr--accent)",
                    // boxShadow: "0 0 3px var(--clr--accent)",
                },
                height: 35,
                minHeight: 35,
            };
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
            ...styles,
            background: isFocused ? '#ff7200' : isSelected ? '#fff' : undefined,
            color: isFocused ? '#fff' : 'black',
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? "var(--clr--accent)"
                        : undefined
                    : undefined,
            },
            zIndex: 1
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '35px',
            margin: '-8px 0 0 0'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '35px',
        }),


    }
    const options = [];
    listing && listing.map((item) => {
        options.push({ value: item._id, label: item.PlantName });
    });
    const _handlePlantChange = (item) => {
        let temp = { ...referData }
        temp.plantId = item.value
        setReferData(temp)
    }

    return (
        <section id="ReferTransModal" className="custom-modal toggle_width" style={{ width: "40%" }}>
            <div className="custom-modal-header">
                <h1>Refer Transporter</h1>
                <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" >
                <div className='referElem'>
                    <div className='referRoww'>
                        <span className='spanName'>Plant Name :</span>
                        <Select
                            onChange={(value) => _handlePlantChange(value)}
                            options={options}
                            styles={colourStyles}
                        />
                        <div style={{ width: '20%' }}>
                            <button className='addPlant' onClick={addPlant}>Add New Plant</button>
                        </div>
                    </div>
                    <div className='referRow unsetJs'>
                        <span className='spanName'>Email Id :</span>
                        <input value={email} onChange={(e) => _handleChange(e)} className='referInp' placeholder='Enter Email Id' />
                        <div style={{ width: '20%' }} className='newCls'>
                            <button disabled={email === '' ? true : false} className='referBtn' onClick={() => add()}>Add</button>
                        </div>
                    </div>
                    <div className='referRow'>
                        <div className='emailBox'>
                            {referData.emailId.length !== 0 ?
                                referData.emailId.map((item, index) => {
                                    return (
                                        <div key={index} className='emailContainer'>
                                            <i className="fa fa-times emailCross" onClick={() => removeEmail(index)} ></i>
                                            <span>{item}</span>
                                        </div>
                                    )
                                })
                                :
                                <span className='addEmailspan'>Add Email..</span>
                            }
                        </div>
                    </div>
                    <div className='referRow alignCenter'>
                        <button disabled={referData.emailId.length === 0 ? true : false} onClick={() => sendEmail()} className='referBtnn'>Send Email</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ReferTrans;