import {GET_USERS , SHOW_USER,  HIDE_USER, PAGINATION , RECORDS} from  "../actions/types";

const initialState={
    userData: [],
    loading:true,
    bookmarks: [],
    records: '' 
} 

const getUserRed =(state=initialState,{type,payload})=>{
    switch(type){
        case GET_USERS : return{
            ...state,
            userData:payload
        } 
        case SHOW_USER : return{
            ...state,
            loading:true
        }
        case HIDE_USER : return{
            ...state,
            loading:false
        } 
        case PAGINATION:
            state.bookmarks.push(payload) 
            return {
                ...state,
                bookmarks: state.bookmarks 
            }
        case RECORDS:
            return {
                ...state,
                records: payload
            }
        default: return state;  
    }
}
export default getUserRed; 