import { combineReducers } from 'redux';
import userReducer from './userReducer';
import staffReducer from './staffReducer';
import driverReducer from './driverReducer';
import addressReducer from './addressReducer';
import registerVehicleReducer from './registerVehicleReducer'
import getVehicleReducer from './getVehicleReducer'
import getDriverReducer from './getDriverReducer'
import getSingleDriverReducer from './getSingleDriverReducer'
import getStaffReducer from './getStaffReducer'
import getAddressReducer from './getAddressReducer'
import registerHubReducer from './addHubReducer'
import bookingReducers from './bookingReducers' 
import getListingReducer from './getListingReducers'
import getSingleAddReducer from './singleAddReducer'
import getTransReducer from './getSingleTrans'
import getSingleStaffReducer from './getSingleStaffRed' 
import getSingleHubReducer from './getSingleHub'
import getPlantsListReducers from './getPlantsListRed'   
import getDepartReducer from './getDepartReducer'
import getUserRed from './getUserRed' 
import getSingleUserReducer from './SingleUserRed'  
import getUserProfileRed from './getUserProfileRed'
import getContractReducers from './getContractRed'
import fetchVehRed from './fetchVehRed'
import getContractDetailRed from './getContractDetailRed'
import thirdDriverRed from './thirdDriverRed' 
import getClauseRed from './getClauseRed'
import deliverRed from './deliverRed'
import arrivedRed from './arrivedRed'
import viewLrDetailRed from './viewLrDetailRed'          
import getAllocationListRed from './getAllocationListRed' 
import categoriesRed from './clausesRed'
import fieldsRed from './dataFieldRed'   
import getImgBase from './getImgRed'

export default combineReducers({
    userReducer,
    staffReducer,
    driverReducer,
    addressReducer,
    registerVehicleReducer,
    getVehicleReducer,
    getDriverReducer,
    getStaffReducer,
    getAddressReducer,
    registerHubReducer,
    bookingReducers,
    getListingReducer,
    getSingleAddReducer,
    getTransReducer,
    getSingleDriverReducer,
    getSingleStaffReducer,
    getSingleHubReducer,
    getPlantsListReducers,
    getDepartReducer,
    getUserRed,
    getSingleUserReducer,
    getUserProfileRed,
    getContractReducers,
    fetchVehRed,
    getContractDetailRed,
    thirdDriverRed,
    getClauseRed,
    deliverRed,
    arrivedRed,
    viewLrDetailRed,
    getAllocationListRed,
    categoriesRed,
    fieldsRed,
    getImgBase
});