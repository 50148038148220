import {
    LISTING_DATA, PAGINATION, RECORDS, DRIVER_LIST, STAFF_LIST, HUB_LIST, ADDRESS_LIST, SHOW_VEHICLE_LOADER, TRANSPORTER_LIST, DRIVERS_LIST, HIDE_VEHICLE_LOADER,
    TRANS_PAGE, TRANS_RECORD, PAGINATION_STAFF, RECORDS_STAFF
} from './types'
import { post } from './../../../services/requests'

const pageSize = 5
export const listingApi = (data) => {
    if (data.docType === 'vehicle') {
        return (dispatch) => {
            dispatch(showLoader())
            post('vehicle/GetAllVehicle', {
                pageSize: pageSize,
                bookmark: data.bookmark,
                docType: 'vehicle',
                searchValue: data.searchValue,
                vehicleType: data.vehicleType,
                orgId: data.orgId,
                blacklistedFlag: data.blacklistedFlag,
            })
                .then(response => {
                    // console.log('vehicle listing response', response);
                    const vehicleLists = response.data
                    dispatch(hideLoader())
                    if (vehicleLists && vehicleLists.length === 0) { }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(getListingOfData(vehicleLists))
                        dispatch(getRecordCount(records))
 
                        if (data.param === "next" || data.param === "") {
                            dispatch(vehiclePagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (data.docType === 'driver') {
        return (dispatch) => {
            dispatch(showLoader())
            post('driver/GetAllDriver', {
                pageSize: pageSize,
                bookmark: data.bookmark, 
                docType: 'driver',
                searchType: data.searchType,
                searchValue: data.searchValue,
                orgId: data.orgId,
                blacklistedFlag: data.blacklistedFlag,
            })
                .then(response => {
                    const driverLists = response.data
                    dispatch(hideLoader())
                    if (driverLists && driverLists.length === 0) { }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(getDriverListing(driverLists))
                        dispatch(getRecordCount(records))
                        if (data.param === "next" || data.param === "") {
                            dispatch(vehiclePagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (data.docType === 'staff') {
        return (dispatch) => {
            dispatch(showLoader())
            post('staff/GetAllStaff', { 
                pageSize: pageSize, 
                bookmark: data.bookmark,
                searchType: data.searchType,
                searchValue: data.searchValue,
                gender: data.gender,
                docType: "staff",
            })
                .then(response => {
                    const staffLists = response.data
                    dispatch(hideLoader())
                    if (staffLists && staffLists.length === 0) { }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(getStaffListing(staffLists))
                        dispatch(getRecordCountStaff(records))
                        if (data.param === "next" || data.param === "") {
                            dispatch(vehiclePaginationStaff(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (data.docType === 'hub') {
        return (dispatch) => {
            dispatch(showLoader())
            post('hub/GetAllHubs', { 
                 pageSize: pageSize,
                 bookmark: data.bookmark,
                 searchType : data.searchType,
                 searchValue : data.searchValue,
                 status : data.status,
                //  docType: "hub",
                 })
                .then(response => {
                    const hubLists = response.data
                    dispatch(hideLoader())
                    if (hubLists && hubLists.length === 0) { }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(gethubListing(hubLists))
                        dispatch(getRecordCount(records))
                        if (data.param === "next" || data.param === "") {
                            dispatch(vehiclePagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (data.docType === 'address-book') {
        return (dispatch) => {
            dispatch(showLoader())
            post('addressbook/GetAllAddressBook', {
                pageSize: pageSize, 
                bookmark: data.bookmark,
                docType: "address-book",
                // param: "",
                searchType: data.searchType,
                searchValue: data.searchValue,
                status: data.status
            })
                .then(response => {
                    const addressLists = response.data  
                    dispatch(hideLoader())
                    if (addressLists && addressLists.length === 0) { }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(getAddressListing(addressLists))
                        dispatch(getRecordCount(records))
                        if (data.param === "next" || data.param === "") {
                            dispatch(vehiclePagination(bookmark))
                        }
                    }


                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
    else if (data.docType === 'transporter') {
        return (dispatch) => {
            dispatch(showLoader())
            post('user/GetAllTransporter', {
                 pageSize: pageSize,
                  bookmark: data.bookmark, 
                  blacklistedFlag: data.blacklistedFlag,
                  searchType : data.searchType,
                  searchValue : data.searchValue,
                  docType: 'transporter',
                 })
                .then(response => {
                    const transporterLists = response.data
                    dispatch(hideLoader())
                    if (transporterLists && transporterLists.length === 0) { }
                    else {
                        const bookmark = response.metaData !== undefined ? response.metaData.bookmark : 0
                        const records = response.metaData !== undefined ? response.metaData.RecordsCount : 0
                        dispatch(getTransporterListing(transporterLists))
                        dispatch(gettansRecordCount(records))

                        if (data.param === "next" || data.param === "") {
                            dispatch(transPagination(bookmark))
                        }
                    }
                })
                .catch(error => {
                    console.log('error', error);
                })
        }
    }
}
export const getListingOfData = (vehicleLists) => {
    return {
        type: LISTING_DATA,
        payload: vehicleLists
    }
}
export const vehiclePagination = (bookmark) => {
    return {
        type: PAGINATION,
        payload: bookmark
    }
}
export const getRecordCount = (records) => {
    return {
        type: RECORDS,
        payload: records
    }
}
export const getDriverListing = (driverLists) => {
    return {
        type: DRIVER_LIST,
        payload: driverLists
    }
}
export const getStaffListing = (staffLists) => {
    return {
        type: STAFF_LIST,
        payload: staffLists
    }
}
export const gethubListing = (hubLists) => {
    return {
        type: HUB_LIST,
        payload: hubLists
    }
}
export const getAddressListing = (addressLists) => {
    return {
        type: ADDRESS_LIST,
        payload: addressLists
    }
}
export const getTransporterListing = (transporterLists) => {
    return {
        type: TRANSPORTER_LIST,
        payload: transporterLists
    }
}
// vehicle
export const showLoader = () => {
    return {
        type: SHOW_VEHICLE_LOADER,
    }
}
export const hideLoader = () => {
    return {
        type: HIDE_VEHICLE_LOADER,
    }
}
// transporter
export const transPagination = (bookmark) => {
    return {
        type: TRANS_PAGE,
        payload: bookmark
    }
}
export const gettansRecordCount = (records) => {
    return {
        type: TRANS_RECORD,
        payload: records
    }
}
export const vehiclePaginationStaff = (bookmark) => {
    return {
        type: PAGINATION_STAFF,
        payload: bookmark
    }
}
export const getRecordCountStaff = (records) => {
    return {
        type: RECORDS_STAFF,
        payload: records
    }
}