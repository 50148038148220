import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { selectInit } from './../../services/functions'
import AnalysisModal from './../Dashboard/Modal/Analysis'
import TrackingModal from './../Dashboard/Modal/Tracking'
import ViewDocumentsModal from './../Dashboard/Modal/ViewDocuments'
import history from '../../services/history';
import moment from 'moment'
import view from './../../static/images/icon/buttons/ViewDetail.svg'
import analytics from './../../static/images/icon/buttons/Analytics.svg'
import cancel from './../../static/images/icon/buttons/Cancel.svg'
import Delete from './../../static/images/icon/buttons/Delete.svg'
import tracking from './../../static/images/icon/buttons/Tracking.svg'
import update from './../../static/images/icon/buttons/Update.svg'
import documentView from './../../static/images/icon/buttons/ViewDocuments.svg'
import { listingApi, pod } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'


const Delivery = (props) => {
    const dispatch = useDispatch()
    const podListing = useSelector((state) => state.deliverRed.podLists.podList)
    const [searchObject, setSearchObject] = useState(
        {
            bookmark: "",
            pageSize: "",
            searchValue: "",
            searchType: "",
            status : "PODGenerated"
        }
    )
    const [podDetail, setPodDetails] = useState(
        {
            pageSize: '',
            bookmark: "",
            status: "PODGenerated",
            searchValue: "",
            searchType: "",
        }
    )
    useEffect(() => {
        setTimeout(() => {
            selectInit(_searchDrop)
        }, 100);

        dispatch(pod(podDetail))
    }, [dispatch])

    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")

        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }

    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchType = value
            setSearchObject(temp)
        }
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }

    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    const _goto = (id) => {
        history.push(
            {
                pathname: '/dashboard/delivery/detail',
                state: { id: id, show: true }
            }
        )
    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(pod({
            pageSize: '',
            bookmark: "",
            status: "PODGenerated",
            searchValue: searchObject.searchValue,
            searchType: searchObject.searchType,
        }))
    }

    return (
        <div className="contentpanel">
            <h1 className="title">Proof of Delivery</h1>
            <hr />
            <div className="cust-container">
                <div className='cust-row'>
                    <div className='cust-col-2 flex-algn-cent'>
                        <div className='tt2'>PoD List</div>
                    </div>
                    <div className='cust-col-4'>
                        <div className="input-group">
                            <div className="input-group-prepend">

                                <select className="select2-single" defaultValue="select" id="searchType">
                                    <option value="select">Select</option>
                                    <option value="shipmentGID">Shipment GID</option>
                                    <option value="vehicleNumber">vehicle Number</option>
                                    <option value="lrNo">LR Number</option>
                                </select>
                            </div>
                            <input type="text" name='searchValue' value={searchObject.searchValue} onChange={(e) => _onChangeSearch(e)} className="form-control" placeholder="Search here..." />
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2"
                                 disabled={searchObject.searchType === 'select' || searchObject.searchType === undefined ? true : false}
                                        onClick={() => triggerSearch()}>
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='cust-col-2'>
                        {/* <select className="form-control select2-single" defaultValue="all" id="ownershipStatus">
                            <option value="all">Delivery Type</option>
                            <option value="owned">Normal Delivery</option>
                            <option value="hired">Exception Delivery</option>
                        </select> */}
                    </div>
                    <div className='cust-col-2 flex-jc-end'>
                    </div>
                </div>
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='data-cont'>
                            {podListing && podListing !== [] ?
                                podListing.map((item, index) => {
                                    return (
                                        <div key={index} className='data-cont-item'>
                                            {localStorage.getItem("userGroup") === 'transporter' ? "" :
                                                <div className='data_item_content align-div pad-0'>
                                                    <div className='wd_30 clip-path display-col'>
                                                        <span className='tbl-hd-1'>Transporter :</span>
                                                        <span className='tbl-hd-2'>{item.Transporter ? item.Transporter.Name : "-"}</span>
                                                        <span className='tbl-hd-3'>{item.Transporter.OrgReadableID ? `(ID - ${item.Transporter.OrgReadableID})` : ""}</span>
                                                    </div>
                                                    <div className='wd_30 bg-trans inc-hgt'>
                                                        <div className='dateTime'>
                                                            <span>Date/Time</span>
                                                        </div>
                                                        <div className='date'>
                                                            <i className="fa fa-calendar"></i>
                                                            <span>{item.PodDetails && item.PodDetails.Generated_on ? moment(item.PodDetails.Generated_on).format("DD-MM-YYYY") : '-'}</span>
                                                        </div>

                                                        <div className='time'>
                                                            <i className="fa fa-clock-o xl"></i>
                                                            <span>{item.PodDetails && item.PodDetails.Generated_on ? moment(item.PodDetails.Generated_on).format("h:mm:ss a") : '-'}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                            <div className='data_item_content set_height_xxl pad-top-0 set-new-hght'>
                                                <div className='wd_5 align-cent set-hght'>
                                                    <div className='sr-no '>
                                                        <span>{index + 1}</span>
                                                    </div>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>LR No. & Date</span>
                                                    <span className='orange_color font-lg'>{item.LrNo}</span>
                                                    <span>{moment(item.LrDate).format('DD/MM/YYYY')}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Pickup</span>
                                                    <span className='flex_box_2'>{item.From && item.From.ConsignorAddress ? item.From.ConsignorAddress : '-'}
                                                        {item.From && item.From.State ? item.From.State : ''}  {item.From && item.From.City ? item.From.City : ''} </span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Dropoff</span>
                                                    <span className='flex_box_2'>{item.To && item.To.Address ? item.To.Address : '-'} {item.To && item.To.State ? item.To.State : ''}  {item.To && item.To.City ? item.To.City : ''}</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Shipment GID</span>
                                                    <span className='flex_box_2'>{item.ShipmentGID}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Vehicle Number & Type</span>
                                                    <span>
                                                        {item.VehicleNumber}, ({item.VehicleType})
                                                    </span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Fixed Tracker ID</span>
                                                    <span>{item.FixedTrackerID ? item.FixedTrackerID : '-'}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>POD Time</span>
                                                    <span>{item.PodDetails && item.PodDetails.Generated_on ? moment(item.PodDetails.Generated_on).format("h:mm:ss a") : '-'}</span>
                                                </div>
                                                {/* <div className='wd_15'>
                                        <span>Deliverd On</span>
                                        <span>11:35AM on 12.10.2022 <br /> (Est was 11:34AM on 11.10.2022)</span>
                                    </div> */}
                                                <div className='wd_5 align-cent set-hght set-bg'>
                                                    <div className='sr-no trans' onClick={() => { toogleItemView(index) }}>
                                                        <i className="fa fa-plus expand-tbl open"></i>
                                                        <i className="fa fa-minus contract-tbl hide" ></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='data_item_content set_height_xxxl clps setColor'>
                                                <div className='wd_15'>
                                                    <span>Consignor Name</span>
                                                    <span className='flex_box_2'>{item.Consignor && item.Consignor.PlantName ? item.Consignor.PlantName : '-'}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Consignee Name</span>
                                                    <span className='flex_box_2'>{item.To && item.To.ConsigneeName ? item.To.ConsigneeName : '-'}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Total Coils</span>
                                                    <span className='flex_box_2'>{item.CoilDetails.length}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Total Quantity </span>
                                                    <span className='flex_box_2'>{item.CoilDetails[0] && item.CoilDetails[0].MaterialQuantity ? item.CoilDetails[0].MaterialQuantity : '-'}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Gate-in time </span>
                                                    <span className='flex_box_2'>{moment(item.GateInTime).format("h:mm:ss a")}</span>
                                                </div>
                                                <div className='wd_10'>
                                                    <span>Confirmed by </span>
                                                    <span className='flex_box_2'>{item.PodDetails && item.PodDetails.CustomerName ? item.PodDetails.CustomerName : '-'}</span>
                                                </div>
                                                <div className='wd_15'>
                                                    <span>Arrived at Time</span>
                                                    <span className='flex_box_2'>{moment(item.ArrivedOn).format("h:mm:ss a")}</span>
                                                </div>

                                                <div className='wd_30 no-mg-pd designChange'>
                                                    <div className='btn_row_1'>
                                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 view-doc' onClick={() => { _handleClick('ViewDocumentsModal') }}><img src={documentView} alt="" />View Documents</button>
                                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 update' onClick={() => { _handleClick('trackingModal') }}><img src={tracking} alt="" />Tracking</button>
                                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 update' onClick={() => { _handleClick('analysisModal') }}><img src={analytics} alt="" />Analytics</button>
                                                        <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1 view-detail' onClick={() => _goto(item._id)}><img src={view} alt="" />View Detail</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <span className='noDataClass'>No Data Found</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <AnalysisModal closeModal={_closeModal} />
            <TrackingModal closeModal={_closeModal} />
            <ViewDocumentsModal closeModal={_closeModal} />
        </div>
    )
}

export default Delivery