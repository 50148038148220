import FileUpload from './FileUpload'
import OwnerLogo from './../../static/images/icon/vehicle/owner.svg'
import RegistrationLogo from './../../static/images/icon/vehicle/registration.svg'
import EngineLogo from './../../static/images/icon/vehicle/engine.svg'
import ChasisLogo from './../../static/images/icon/vehicle/chasis.svg'
import FuelLogo from './../../static/images/icon/vehicle/fuel.svg'
import React, { useEffect, useState } from 'react';
import { get_pdf_thumbnail, base64Convert } from './../../services/functions'
import history from '../../services/history'
import { getVehicleData } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import $ from 'jquery'
import axios from 'axios'

const VehicleDetail = (props) => {
    const dispatch = useDispatch()
    const getNum = props.location.state.VehicleNumber
    const [userGroup, setUserGroup] = useState('')
    const singleVehicleData = useSelector((state) => state.getVehicleReducer.vehicleData)
    const loader = useSelector((state) => state.getVehicleReducer.loading)
    const [fileArr, setFileArr] = useState([])
    const [disableState, setDisableState] = useState(true)

    const _handleFiles = async (files) => {
        if (files.length > 0) {
            let tempArr = [...fileArr]
            let tarr = Array.from(files)
            for (let el of tarr) {
                if (el.type.includes('image') || el.type === "application/pdf") {
                    try {
                        let base64 = await base64Convert(el)
                        let obj = { base64, fileType: el.type.includes('image') ? 'image' : 'pdf', name: el.name }

                        if (obj.fileType === 'pdf') {
                            let pdf = base64.replace(/^data:application\/\pdf;base64,/, "")
                            var binary_string = window.atob(pdf);
                            var len = binary_string.length;
                            var bytes = new Uint8Array(len);
                            for (var i = 0; i < len; i++) {
                                bytes[i] = binary_string.charCodeAt(i);
                            }
                            var buffer = bytes.buffer
                            var file3 = new Blob([buffer], { type: 'application/pdf' });
                            obj['dataLocalUrl'] = file3
                            get_pdf_thumbnail(file3).then((url) => {
                                obj['imageSrc'] = url
                            })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                        tempArr.unshift(obj)
                    }
                    catch (err) {

                    }
                }
                else {
                    //Swal.fire("Oops","Only image or PDF file is allowed to upload",'warning')
                }
            }
            setFileArr(tempArr)
        }
    }
    const deleteFile = (index) => {
        let tempArr = [...fileArr]
        tempArr.splice(index, 1)
        setFileArr(tempArr)
    }
    const _goBack = () => {
        history.push("/dashboard/vehicle")
    }
    const [updateObj, setUpdateObj] = useState({
        VehicleNumber: "",
        OwnerName: "",
        EngineNumber: "",
        ChassisNumber: "",
        VehicleType: "",
        BodyGroup: "",
        BodyType: "",
        RegistrationDate: "",
        RegistrationValidTill: "",
        InsuranceValidTill: "",
        GpsNumber: "",
        PucCheck: "",
        PucValidTill: "",
        PermitValidTill: "",
        RegisteredCapacity: "",
        CapacityUOM: "",
        FtlRegisteredCapacity: "",
        BlacklistedFlag: "",
        // fields not on ui but in update api
        VehicleSerialNo: "",
        VehicleModel: "",
        InsuranceCheck: "",
        Address: "",
        Country: "",
        State: "",
        District: "",
        PinCode: "",
        MobileNo: "",
        PermitStates: [],
        PermitRegisteredCapacity: "",
        PermitCapacityUOM: "",
        PermitActive: "",
        UnloadWeight: "",
        GrossVehicleWeight: "",
        Status: ""

    })
    const allowEdit = () => {
        if ($(".vehicleInpField").hasClass("underline")) {
            $(".vehicleInpField").removeClass("underline");
            $(".vehicleInpField1").removeClass("underline");
            setDisableState(true)
            setUpdateObj(
                {
                    VehicleNumber: "",
                    OwnerName: "",
                    EngineNumber: "",
                    ChassisNumber: "",
                    VehicleNumber: "",
                    VehicleType: "",
                    BodyGroup: "",
                    BodyType: "",
                    RegistrationDate: "",
                    RegistrationValidTill: "",
                    InsuranceValidTill: "",
                    GpsNumber: "",
                    PucCheck: "",
                    PucValidTill: "",
                    PermitValidTill: "",
                    RegisteredCapacity: "",
                    CapacityUOM: "",
                    FtlRegisteredCapacity: "",
                    BlacklistedFlag: "",
                    // fields not on ui but in update api
                    VehicleSerialNo: "",
                    VehicleModel: "",
                    InsuranceCheck: "",
                    Address: "",
                    Country: "",
                    State: "",
                    District: "",
                    PinCode: "",
                    MobileNo: "",
                    PermitStates: '',
                    PermitRegisteredCapacity: "",
                    PermitCapacityUOM: "",
                    PermitActive: "",
                    UnloadWeight: "",
                    GrossVehicleWeight: "",
                    Status: ""
                }
            )

        } else {
            $(".vehicleInpField").addClass("underline");
            $(".vehicleInpField1").addClass("underline");
            setDisableState(false)
            let dummy = { ...updateObj }
            dummy.VehicleNumber = singleVehicleData.VehicleNumber
            dummy.OwnerName = singleVehicleData.OwnerName
            dummy.EngineNumber = singleVehicleData.EngineNumber
            dummy.ChassisNumber = singleVehicleData.ChassisNumber
            dummy.VehicleType = singleVehicleData.VehicleType
            dummy.BodyGroup = singleVehicleData.BodyGroup
            dummy.BodyType = singleVehicleData.BodyType
            dummy.RegistrationDate = singleVehicleData.RegistrationDate
            dummy.RegistrationValidTill = singleVehicleData.RegistrationValidTill
            dummy.InsuranceValidTill = singleVehicleData.InsuranceValidTill
            dummy.GpsNumber = singleVehicleData.GpsNumber
            dummy.PucCheck = singleVehicleData.PucCheck
            dummy.PucValidTill = singleVehicleData.PucValidTill
            dummy.PermitValidTill = singleVehicleData.PermitValidTill
            dummy.RegisteredCapacity = singleVehicleData.RegisteredCapacity
            dummy.CapacityUOM = singleVehicleData.CapacityUOM
            dummy.FtlRegisteredCapacity = singleVehicleData.FtlRegisteredCapacity
            dummy.BlacklistedFlag = singleVehicleData.BlacklistedFlag
            dummy.VehicleSerialNo = singleVehicleData.VehicleSerialNo
            dummy.VehicleModel = singleVehicleData.VehicleModel
            dummy.InsuranceCheck = singleVehicleData.InsuranceCheck
            dummy.Address = singleVehicleData.Address
            dummy.Country = singleVehicleData.Country
            dummy.State = singleVehicleData.State
            dummy.District = singleVehicleData.District
            dummy.PinCode = singleVehicleData.PostalCode
            dummy.MobileNo = singleVehicleData.MobileNo
            dummy.PermitStates = singleVehicleData.PermitStates
            dummy.PermitRegisteredCapacity = singleVehicleData.PermitRegisteredCapacity
            dummy.PermitCapacityUOM = singleVehicleData.PermitCapacityUOM
            dummy.PermitActive = singleVehicleData.PermitActive
            dummy.UnloadWeight = singleVehicleData.UnloadWeight
            dummy.GrossVehicleWeight = singleVehicleData.GrossVehicleWeight
            dummy.Status = singleVehicleData.Status
            setUpdateObj(dummy)
        }
    }
    const updateData = (e) => {
        setUpdateObj({ ...updateObj, [e.target.name]: e.target.value })
    }
    const updateFun = () => {
        const finalObj = {
            vehicleNumber : updateObj.VehicleNumber,
            vehicleSerialNo : updateObj.VehicleSerialNo,
            ownerName :  updateObj.OwnerName,
            gpsNumber :  updateObj.GpsNumber,
            bodyGroup :  updateObj.BodyGroup,
            bodyType :  updateObj.BodyType,
            vehicleType :  updateObj.VehicleType,
            vehicleModel:  updateObj.VehicleModel,
            registrationDate:  updateObj.RegistrationDate,
            registrationValidTill:  updateObj.RegistrationValidTill,
            registeredCapacity:  updateObj.RegisteredCapacity,
            capacityUOM :  updateObj.CapacityUOM,
            ftlRegisteredCapacity :  updateObj.FtlRegisteredCapacity,
            pucCheck :  updateObj.PucCheck,
            pucValidTill :  updateObj.PucValidTill,
            insuranceCheck :  updateObj.InsuranceCheck,
            insuranceValidTill :  updateObj.InsuranceValidTill,
            blacklistedFlag :  updateObj.BlacklistedFlag,
            address :  updateObj.Address,
            country:  updateObj.Country,
            state:  updateObj.State,
            district:  updateObj.District,
            pinCode:  updateObj.PostalCode,
            mobileNo :  updateObj.MobileNo,
            permitStates: updateObj.PermitStates,
            permitValidTill:  updateObj.PermitValidTill,
            permitRegisteredCapacity:  updateObj.PermitRegisteredCapacity,
            permitCapacityUOM:  updateObj.PermitCapacityUOM,
            permitActive:  updateObj.PermitActive,
            unloadWeight:  updateObj.UnloadWeight,
            grossVehicleWeight: updateObj.GrossVehicleWeight,
            status:  updateObj.Status,
        }

        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post('https://www.betaenterprise.lynkit.io/api/vehicle/UpdateVehicle', { ...finalObj }, { headers: config.headers })
            .then(response => {
                const err = response.data.error
                const message = response.data.message
                window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                if (err === false) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log('err', error)
            })
    }
    useEffect(() => {
        setUserGroup(localStorage.getItem("userGroup") === 'transporter' ? '2' : "1")
        dispatch(getVehicleData(getNum))
    }, [dispatch]);


    return (
        <div className="contentpanel">
            <div className="cust-container">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-4'>
                        <h1 className="title">
                            <button className='button bg-orange mr-2 wd10 flex-inline' onClick={_goBack}>
                                <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                            </button>
                            Vehicle Detail
                        </h1>
                    </div>
                    <div className='cust-col-6 flex-jc-end'>
                        {userGroup === '2' ?
                            <button className='button bg-orange-out mr-2 wd10' onClick={allowEdit}>
                                <div className='btn_txt font-lg'><i className='fa fa-edit'></i></div>
                                <div className="titleBoxnew">
                                    <span>Edit </span>
                                </div>
                            </button>
                            : ''}
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-download'></i></div>
                            <div className="titleBoxnew">
                                <span>Download </span>
                            </div>
                        </button>
                        <button className='button bg-orange-out mr-2 wd10'>
                            <div className='btn_txt font-lg'><i className='fa fa-upload'></i></div>
                            <div className="titleBoxnew">
                                <span>Upload </span>
                            </div>
                        </button>
                    </div>
                </div>
                <hr className='bgg' />
                <div className="cust-row">
                    <div className='cust-col-10'>
                        {singleVehicleData !== undefined ?
                            loader === true ?
                                <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                    <i className="fa fa-spinner fa-spin loadingSpinner font-xxl  "></i>
                                </div>
                                :
                                <div className="vd_cont">
                                    <div className="vd_cont_1">
                                        <div className="vd_cont_1_sec">
                                            <div className="vd_cont_1_sec_icon">
                                                <img src={RegistrationLogo} alt="logo" />
                                            </div>
                                            <div className="vd_cont_1_sec_tt">
                                                Vehicle No.
                                            </div>
                                            <div className="vd_cont_1_sec_txt">
                                                <input className='vehicleInpField'
                                                    name='VehicleNumber'
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.VehicleNumber : updateObj.VehicleNumber}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_1_sec">
                                            <div className="vd_cont_1_sec_icon">
                                                <img src={OwnerLogo} alt="logo" />
                                            </div>
                                            <div className="vd_cont_1_sec_tt">
                                                Owner Name
                                            </div>
                                            <div className="vd_cont_1_sec_txt">
                                                <input className='vehicleInpField'
                                                    name='OwnerName'
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.OwnerName : updateObj.OwnerName}
                                                />
                                            </div>

                                        </div>
                                        <div className="vd_cont_1_sec">
                                            <div className="vd_cont_1_sec_icon">
                                                <img src={EngineLogo} alt="logo" />
                                            </div>
                                            <div className="vd_cont_1_sec_tt">
                                                Engine No.
                                            </div>
                                            <div className="vd_cont_1_sec_txt">
                                                <input className='vehicleInpField'
                                                    name="EngineNumber"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.EngineNumber : updateObj.EngineNumber}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_1_sec">
                                            <div className="vd_cont_1_sec_icon">
                                                <img src={FuelLogo} alt="logo" />
                                            </div>
                                            <div className="vd_cont_1_sec_tt">
                                                Fuel Type
                                            </div>
                                            <div className="vd_cont_1_sec_txt">
                                                <input className='vehicleInpField' value='Diesel' />
                                            </div>
                                        </div>
                                        <div className="vd_cont_1_sec">
                                            <div className="vd_cont_1_sec_icon">
                                                <img src={ChasisLogo} alt="logo" />
                                            </div>
                                            <div className="vd_cont_1_sec_tt">
                                                Chasis No
                                            </div>
                                            <div className="vd_cont_1_sec_txt">
                                                <input className='vehicleInpField'
                                                    name="ChassisNumber"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.ChassisNumber : updateObj.ChassisNumber}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vd_cont_2">
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Vehicle Reg. Number
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="VehicleNumber"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.VehicleNumber : updateObj.VehicleNumber}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Vehicle Type
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="VehicleType"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.VehicleType : updateObj.VehicleType}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Body Group
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="BodyGroup"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.BodyGroup : updateObj.BodyGroup}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Body Type
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="BodyType"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.BodyType : updateObj.BodyType}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Registration Date
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    value={disableState === true ? moment(singleVehicleData.RegistrationDate).format('DD/MM/YYYY') : moment(updateObj.RegistrationDate).format('DD/MM/YYYY')} />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Registration Valid Till
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="RegistrationValidTill"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? moment(singleVehicleData.RegistrationValidTill).format('DD/MM/YYYY') : moment(updateObj.RegistrationValidTill).format('DD/MM/YYYY')}
                                                />

                                            </div>
                                        </div>



                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Insurance Valid Upto
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="InsuranceValidTill"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? moment(singleVehicleData.InsuranceValidTill).format('DD/MM/YYYY') : moment(updateObj.InsuranceValidTill).format('DD/MM/YYYY')}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                GPS Number
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="GpsNumber"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.GpsNumber : updateObj.GpsNumber}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Permit Issue Date
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                (e-Vahan)
                                            </div>
                                        </div> */}
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                PUC Check
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="PucCheck"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.PucCheck : updateObj.PucCheck}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                PUC Valid Upto
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="PucValidTill"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? moment(singleVehicleData.PucValidTill).format('DD/MM/YYYY') : moment(updateObj.PucValidTill).format('DD/MM/YYYY')}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Permit Valid Upto
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="PermitValidTill"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? moment(singleVehicleData.PermitValidTill).format('DD/MM/YYYY') : moment(updateObj.PermitValidTill).format('DD/MM/YYYY')}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Registered Capacity
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="RegisteredCapacity"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.RegisteredCapacity : updateObj.RegisteredCapacity}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Capacity UOM
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="CapacityUOM"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.CapacityUOM : updateObj.CapacityUOM}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                FTL Registered Capacity
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="FtlRegisteredCapacity"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.FtlRegisteredCapacity : updateObj.FtlRegisteredCapacity}
                                                />
                                            </div>
                                        </div>
                                        <div className="vd_cont_2_sec">
                                            <div className="vd_cont_2_sec_1">
                                                Blacklisted Flag
                                            </div>
                                            <div className="vd_cont_2_sec_2">
                                                <input className='vehicleInpField1'
                                                    name="BlacklistedFlag"
                                                    onChange={(e) => updateData(e)}
                                                    disabled={disableState}
                                                    value={disableState === true ? singleVehicleData.BlacklistedFlag : updateObj.BlacklistedFlag}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="vd_cont_3">
                                        <div className="vd_cont_3_sec">
                                            <div className="vd_cont_3_sec_header">
                                                Upload Vehicle Documents
                                            </div>
                                            <div className="vd_cont_3_sec_bdy">
                                                <FileUpload handleFiles={_handleFiles} />
                                                {fileArr.length ?
                                                    <div className='file_preview'>
                                                        <p>Uploaded Files</p>
                                                        <div className='file_preview_cont'>
                                                            {
                                                                fileArr.map((item, index) => {
                                                                    return <div key={`pp-` + index} className='preview_itm'>
                                                                        <div className='closeb' onClick={() => { deleteFile(index) }}>
                                                                            <i className='fa fa-close'></i>
                                                                        </div>
                                                                        <img src={item.base64} alt="" />:
                                                                        {/* {
                                                                    item.fileType=='image'?
                                                                    ""
                                                                } */}
                                                                        <div className='fullscreen'>
                                                                            <i className='fa fa-expand'></i>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    : ""}
                                                <div className='btn_cont'>
                                                    <button className='button bti bg-orange-out m-0'>
                                                        <div className='btn_icon'>
                                                            <i className='fa fa-upload'></i>
                                                        </div>
                                                        <div className='btn_txt font-lg'>Upload Now</div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                            <span className='noDataClass'>No Data Found</span>
                        }
                    </div>
                </div>
                {disableState === false ?
                    <div className='updateElem'>
                        <button onClick={() => updateFun()}>Update</button>
                    </div>
                    : ''}
            </div>
        </div>
    )
}

export default VehicleDetail