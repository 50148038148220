import { DATA_FIELDS } from  "../actions/types";

const initialState={
    dataFields: [], 
    loading: true
} 

const fieldsRed =(state=initialState,{type,payload})=>{
    switch(type){
        case DATA_FIELDS : return{
            ...state,
            dataFields : payload 
        }
        default: return state;  
    }
}
export default fieldsRed ;