import {ARRIVED_ACTION , ARRIVED_HIDE , ARRIVED_SHOW } from  "../actions/types";

const initialState={
    arrivedLists: [], 
    loading:true
} 

const arrivedRed =(state=initialState,{type,payload})=>{
    switch(type){
        case ARRIVED_ACTION : return{
            ...state,
            arrivedLists : payload 
        }
        case ARRIVED_HIDE : return{
            ...state,
            loading:false
        }
        default: return state;  
    }
}
export default arrivedRed ;