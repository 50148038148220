import { useEffect, useState } from 'react'
import { selectInit,formatLineChartData,make_pie_chart } from './../../services/functions'
import { listingApi } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux';

const Analytics = () =>{
    const dispatch = useDispatch()
    const transportersList = useSelector((state) => state.getListingReducer.transporterListing)
    const [dataArr,setDataArr]=useState([
        {
            "value": 20,
            "name": "Aug"
        },
        {
            "value": 1,
            "name": "Sep"
        },
        {
            "value": 11,
            "name": "Oct"
        },
        {
            "value": 18,
            "name": "Nov"
        },
        {
            "value": 4,
            "name": "Dec"
        },
        {
            "value": 13,
            "name": "Jan"
        }
    ])

    const [lineChartData,setLineChartData]=useState([])

    const [analyticsObj,setAnalyticsObj]=useState({
        "goods_reciept": {
          "total": 4778,
          "contract_type": {
            "FTL": 3368,
            "FCL": 384,
            "LCL": 11,
            "PTL": 1015
          },
          "delivery_type": {
            "door": 3717,
            "station": 395,
            "godown": 666
          },
          "transportation_mode": {
            "road": 4590,
            "train": 130,
            "air": 26,
            "multimode": 32
          }
        },
        "invoice": {
          "total": 342,
          "status": {
            "paid": 160,
            "created": 182
          }
        },
        "challan": {
          "total": 944,
          "type": {
            "vehicle_challan": 470,
            "local_challan": 474
          }
        },
        "unloading_report": {
          "total": 62
        },
        "hire_slip": {
          "total": 102
        },
        "pod": {
          "total": 3257
        },
        "address": {
          "total": 235,
          "business_account": 100,
          "individual_account": 83
        },
        "drivers": 17,
        "hubs": {
          "total": 124,
          "subadmin": 73,
          "admin": 57,
          "hubmanager": 99,
          "user": 29
        },
        "transportation": {
          "_id": null,
          "total": 225,
          "owned": 181,
          "hired": 44,
          "2022-01": 3,
          "2021-12": 4,
          "2021-11": 1,
          "2021-10": 1,
          "2021-09": 0,
          "2021-08": 0
        }
    })
    const [transporterName, setTransporterName]=useState({userName:"xyz"})
    const [route1, setRoute1]=useState("") 
    const [route2, setRoute2]=useState("") 


    useEffect(()=>{
        setTimeout(() => {
            selectInit(_onChange)
            make_pie_chart("piechart",[
                {
                    data:30,
                    color:"#1CA0BF",
                    label:""
                },
                {
                    data:40,
                    color:"#88C540",
                    label:""
                },
                {
                    data:50,
                    color:"#43BB95",
                    label:""
                },
                {
                    data:72,
                    color:"#515AA8",
                    label:""
                }                
            ])
        }, 100);
        let lineArr=formatLineChartData(dataArr,300)
        setLineChartData(lineArr)

        if(transporterName.userName ==='xyz'){
            setRoute1("HIL Dahej to Bhilad")
            setRoute2("HIL Dahej to Fardidabad")
            
        }
        else if(transporterName.userName ==='abc'){
            setRoute1("HIL Dahej to Srinagar")
            setRoute2("HIL Dahej to Ujjain")
            
        }      
        dispatch(listingApi({
            docType: "transporter",
            bookmark: "",
            param: "next"
        }))
       
    },[dispatch])

    const _onChange = (id,value) => {
        // console.log('id',id);
        // console.log('value',value);
    }
    // form-control select2-single
    return(
        <div className="contentpanel">
            <div className="cust-container">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-5'>
                        <h1 className="title font-bold">
                            Analytics   
                        </h1>
                    </div>
                    { 
                        localStorage.getItem("userGroup")!=='transporter'?
                        <div className='dynamicDropdown'>
                            <select className="form-control select2-single"  id="transDropdown" onChange={(e)=>_onChange(e)}>
                                <option value="all">Select Transporter</option>
                                {transportersList.map((item, index) => {
                                            return (
                                                <option value={index} key={index} >{item.OrgName}</option>
                                            )
                                        })}
                            </select>
                        </div>:""
                    }                    
                    <div className='cust-col-1'></div>
                    {localStorage.getItem("userGroup") !== 'transporter' ? 
                    <div className='cust-col-2'>
                        <select className="form-control select2-single"  id="ddlProducts">
                            {/* <option value="all">Select Route</option> */}
                            <option value="gujarat">{route1}</option>
                            <option value="goa">{route2}</option>
                            
                        </select>
                    </div>
                    :
                    <div className='cust-col-2'>
                        <select className="form-control select2-single"  id="ddlProducts">
                            {/* <option value="all">Select Route</option> */}
                            <option value="gujarat">HIL Dahej to Bhilad</option>
                            <option value="goa">HIL Dahej to Fardidabad</option>
                            
                        </select>
                    </div>

                    }
                </div>
                <hr className='bgg'/>
                <div className='cust-row'>
                    {
                        localStorage.getItem("userGroup")!=='transporter'?
                        <div className='cust-col-3'>
                            <h3 className='font-lg orange_color'><span className='font-bold grey_color'>Transporter : </span> XYZ Logistics Pvt. Ltd.</h3>
                        </div>
                        :""
                    }
                    <div className='cust-col-3'>
                        <h3 className='font-lg orange_color'><span className='font-bold grey_color'>Route : </span> HIL Dahej to HIL  Bhiwad</h3>
                    </div>
                </div>
                <div className='cust-row'>
                    <div className='cust-col-5'>
                        <div className="chartContainer ppchrt">
                            <h2 className='font-lg mt-2 orange_color'>Average Speed</h2>
                            <div className="chartCon">
                                <figure className="css-chart" style={{"--widget-size": "300px"}}>
                                    <div className="xlabel">
                                        <div className="labelsegment">
                                            <ul>
                                                {
                                                    lineChartData.map((el,index)=>{
                                                        return <li key={`xl-${index}`}>{el.name}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="ylabel font-md">
                                        Speed
                                    </div>
                                    <ul className="line-chart">
                                        {
                                            lineChartData.map((el,index)=>{
                                                return  <li key={`lc-${index}`}>
                                                            <div className="data-point" style={{"left":(el.left - 5)+'px','bottom':(el.bottom - 5)+'px'}} data-value={el.value}>
                                                                <div className="cConte">
                                                                    Count : {el.value}
                                                                </div>
                                                            </div>
                                                            <div className="line-segment" style={{'left': el.left+'px','bottom':el.bottom+'px','transform': 'rotate(calc('+el.angle+' * 1deg))','width': 'calc('+el.hypotenuse+' * 1px)'}}></div>
                                                        </li>
                                            })
                                        }
                                    </ul>
                                </figure>  
                            </div>
                        </div>
                    </div>
                    <div className='cust-col-5'>
                    <div className="chartContainer ppchrt">
                            <h2 className='font-lg mt-2 orange_color'>Average Stopping Time</h2>
                            <div className="chart-wrap vertical">
                                <div className="grid">
                                    <div className="ylabel"></div>
                                    <div className="xlabel">Time</div>
                                    <div className="bar" style={{"--bar-color":"#0081CC","width":"40%"}} data-lbl="Jan"></div>
                                    <div className="bar" style={{"--bar-color":"#500050","width":"60%"}} data-lbl="Feb"></div>
                                    <div className="bar" style={{"--bar-color":"#CC0000","width":"30%"}} data-lbl="Mar"></div>
                                    <div className="bar" style={{"--bar-color":"#FFC000","width":"80%"}} data-lbl="Apr"></div>
                                    <div className="bar" style={{"--bar-color":"#0081CC","width":"40%"}} data-lbl="May"></div>
                                    <div className="bar" style={{"--bar-color":"#500050","width":"60%"}} data-lbl="Jun"></div>
                                    <div className="bar" style={{"--bar-color":"#CC0000","width":"30%"}} data-lbl="Jul"></div>
                                    <div className="bar" style={{"--bar-color":"#FFC000","width":"80%"}} data-lbl="Aug"></div>
                                    <div className="bar" style={{"--bar-color":"#0081CC","width":"40%"}} data-lbl="Sep"></div>
                                    <div className="bar" style={{"--bar-color":"#500050","width":"60%"}} data-lbl="Oct"></div>
                                    <div className="bar" style={{"--bar-color":"#CC0000","width":"30%"}} data-lbl="Nov"></div>
                                    <div className="bar" style={{"--bar-color":"#FFC000","width":"80%"}} data-lbl="Dec"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cust-row'>
                    <div className='cust-col-5'>
                        <div className="chartContainer ppchrt">
                            <h2 className='font-lg mt-2 orange_color'>Average KM Covered</h2>
                            <div className='dv_flex wdt100'>
                                <div className='wdt60'>
                                    <canvas id="piechart" height="200" width="200"></canvas>
                                </div>
                                <div className='wdt40'>
                                    <div className='chartlb'>
                                        <div className='dv_flex chartlb-item'>
                                            <div className='chartlb-ccbox wdt50' style={{"--bg":"#43BB95"}}></div>
                                            <div className='chartlb-txt wdt50'>March - 11,236 KM</div>
                                        </div>
                                        <div className='dv_flex chartlb-item'>
                                            <div className='chartlb-ccbox wdt50' style={{"--bg":"#1CA0BF"}}></div>
                                            <div className='chartlb-txt wdt50'>April - 13,000 KM</div>
                                        </div>
                                        <div className='dv_flex chartlb-item'>
                                            <div className='chartlb-ccbox wdt50' style={{"--bg":"#88C540"}}></div>
                                            <div className='chartlb-txt wdt50'>May - 14,456 KM</div>
                                        </div>
                                        <div className='dv_flex chartlb-item'>
                                            <div className='chartlb-ccbox wdt50' style={{"--bg":"#515AA8"}}></div>
                                            <div className='chartlb-txt wdt50'>June - 15,156 KM</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cust-col-5'>
                        <div className="chartContainer ppchrt">
                            <h2 className='font-lg mt-2 orange_color'>Average Time of PoD After Arrival</h2>
                            <div className='dv_flex wdt100'>
                                <div className='wdt25 chart-blue'>
                                    <div className='wdt100 flex-jc-center dntc position-relative'>
                                        <div className="pie animate-chart no-round" style={{"--p":"70","--c":"#225079","--b":"10px","--w":"120px"}}> 70hr</div>
                                    </div>
                                    <div className='lbal'>
                                        <span className='lbl_btn'>June</span>
                                    </div>
                                </div>
                                <div className='wdt25 chart-lb'>
                                    <div className='wdt100 flex-jc-center dntc position-relative'>
                                        {/* <div className="donut-chart chart2">
                                            <div className="slice one"></div>
                                            <div className="slice two"></div>
                                            <div className="chart-center">
                                            <span></span>
                                            </div>
                                        </div> */}
                                        <div className="pie animate-chart no-round" style={{"--p":"65","--c":"#46C1C1","--b":"10px","--w":"120px"}}> 65hr</div>
                                    </div>
                                    <div className='lbal'>
                                        <span className='lbl_btn'>July</span>
                                    </div>
                                </div>
                                <div className='wdt25 chart-lg'>
                                    <div className='wdt100 flex-jc-center dntc position-relative'>
                                        <div className="pie animate-chart no-round" style={{"--p":"85","--c":"#B9D874","--b":"10px","--w":"120px"}}> 85hr</div>
                                    </div>
                                    <div className='lbal'>
                                        <span className='lbl_btn'>August</span>
                                    </div>
                                </div>
                                <div className='wdt25 chart-orange'>
                                    <div className='wdt100 flex-jc-center dntc position-relative'>
                                        <div className="pie animate-chart no-round" style={{"--p":"60","--c":"#FF7200","--b":"10px","--w":"120px"}}> 60hr</div>
                                    </div>
                                    <div className='lbal'>
                                        <span className='lbl_btn'>September</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className="chartContainer ppchrt">
                            <h2 className='font-lg mt-2 orange_color'>Shortest Journey Time and Longest Journey Time</h2>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Analytics