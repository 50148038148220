import { post } from './../../../services/requests'
import { STAFF_DATA } from './types'
import history from './../../../services/history'

export const getStaffData = (data) => {
    return (dispatch) => {
        post('staff/RegisterStaff', { ...data })
            .then(response => {
                console.log('staff response', response);
                var status = response.error
                var message = response.message
                dispatch(sendData(data))
                if (status === true) {
                    window.$.fn.show_notification({ message: `${message}`, title: 'Message!!', autohide: true, confirmVal: "Ok", showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
                else{
                    history.push('/dashboard/workforce')
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log('error', error);
            })
    }
}
export const sendData = (data) => {
    return {
        type: STAFF_DATA,
        payload: data
    }
}

