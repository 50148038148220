import {ALLOCATE_LISTING , META_DATA} from  "../actions/types";

const initialState={
    allocationListingArr: [],
    meta:[]
} 

const getAllocationListRed =(state=initialState,{type,payload})=>{
    switch(type){
        case ALLOCATE_LISTING : return{
            ...state,
            allocationListingArr : payload
        }
        case META_DATA : return{
            ...state,
            meta : payload
        }
        default: return state;  
    }
}
export default getAllocationListRed;