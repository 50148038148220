import { DRIVER_DATA } from "../actions/types";

const initialState={
    driverArray: []
} 

const driverReducer =(state=initialState,{type,payload})=>{
    switch(type){
        case DRIVER_DATA : return{
            ...state,
        }
        default: return state;  
    }
}
export default driverReducer