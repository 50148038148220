import React, { useEffect, useState } from 'react';
import $ from 'jquery'
import './../../static/css/driver.css'
import history from './../../services/history';
import ForceModal from './Modal/ChooseWorkforce'
import axios from 'axios'
import view from './../../static/images/icon/buttons/ViewDetail.svg'
import cancel from './../../static/images/icon/buttons/Cancel.svg'
import Delete from './../../static/images/icon/buttons/Delete.svg'
import update from './../../static/images/icon/buttons/Update.svg'
import documentView from './../../static/images/icon/buttons/ViewDocuments.svg'
import { selectInit } from './../../services/functions'
import { getDriverinfo, getStaffinfo, listingApi } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { resetBase } from '../../state/redux/actions/getImage';

const Workforce = () => {
    const [force, setWorForce] = useState('driver')
    const dispatch = useDispatch()
    const driverList = useSelector((state) => state.getListingReducer.driverListing)
    const staffList = useSelector((state) => state.getListingReducer.staffListing)
    const loader = useSelector((state) => state.getListingReducer.loading)
    const bookmark = useSelector((state) => state.getListingReducer.bookmarks)
    const records = useSelector((state) => state.getListingReducer.records)
    const staffBook = useSelector((state) => state.getListingReducer.staffBook)
    const staffRecords = useSelector((state) => state.getListingReducer.staffRecords)
    const [searchObject, setSearchObject] = useState(
        {
            pageSize: "",
            bookmark: "",
            searchType: "",
            searchValue: "",
            orgId: "",
            docType: 'driver'
        }
    )
    const [staffSearchObj, setStaffObj] = useState({
        pageSize: "",
        bookmark: "",
        searchType: "",
        searchValue: "",
        status: "",
        gender: "",
        docType: "staff",
        param: "next"
    })
    const [val, setVal] = useState('')
    const [genVal , setGenVal] = useState('')
    const [obj, setObj] = useState(
        {
            docType: "driver",
            bookmark: '',
            param: "next"
        }
    )
    const [staffobj, setObjStaff] = useState(
        {
            docType: "staff",
            bookmark: '',
            param: "next",

        }
    )
    const setWorkForce = (item) => {
        if (item === 'driver') {
            setWorForce('driver')
            bookmark.length = 0
            setObj(
                {
                    docType: "driver",
                    bookmark: '',
                    param: "next"
                }
            )
            dispatch(listingApi(obj))
        }
        else {
            setWorForce('staff')
            staffBook.length = 0
            setObjStaff(
                {
                    docType: "staff",
                    bookmark: '',
                    param: "next"
                }
            )
            dispatch(listingApi(staffobj))
        }
    }
    const toogleItemView = (index) => {
        if ($(".data-cont-item").eq(index).hasClass("open")) {
            $(".data-cont-item").eq(index).removeClass("open")
            $(".expand-tbl").eq(index).addClass("open")
            $(".contract-tbl").eq(index).addClass("hide")
        }
        else {
            $(".data-cont-item").eq(index).addClass("open")
            $(".expand-tbl").eq(index).removeClass("open")
            $(".contract-tbl").eq(index).removeClass("hide")
        }
    }
    const navigateAndShow = (DLNumber) => {
        history.push({
            pathname: "/dashboard/Workforce/details",
            state: { State: 'driver', DLNumber: DLNumber }
        })
        // dispatch(getDriverinfo(DLNumber))
    }
    const navigateAndShowStaff = (StaffId) => {
        history.push({
            pathname: "/dashboard/Workforce/details",
            state: { State: 'staff', StaffId: StaffId }
        })
        // dispatch(getStaffinfo(StaffId))
    }
    const _handleClick = (id) => {
        $(".custom-modal-overlay").addClass("custom-modal-is-visible")
        $(`#${id}`).addClass("custom-modal-is-visible")
    }
    const _closeModal = () => {
        $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
        $(".custom-modal").removeClass("custom-modal-is-visible")
    }
    useEffect(() => {
        bookmark.length = 0
        setObj(
            {
                docType: "driver",
                bookmark: '',
                param: "next",
                pageSize: "",
                searchType: "",
                searchValue: "",
                orgId: "",
            }
        )
        dispatch(listingApi(obj))
        dispatch(resetBase())
       
    }, [dispatch])

    useEffect(() => {
        if (force !== 'staff') {
            setTimeout(() => {
                selectInit(_searchDrop)
            }, 100);
        }
        else {
            setTimeout(() => {
                selectInit(_staffsearchDrop)
            }, 100);
        }

    }, [force])

    const nextPagination = () => {
        var object = {
            param: "next",
            bookmark: bookmark[bookmark.length - 1],
            docType: "driver"
        }
        setObj(object)
        dispatch(listingApi(object))
    }
    const previousPagination = () => {
        var object = {
            param: "prev",
            bookmark: bookmark.length >= 3 ? bookmark[bookmark.length - 3] : '',
            docType: "driver"
        }
        setObj(object)
        dispatch(listingApi(object))
        bookmark.pop()
    }

    const _searchDrop = (id, value) => {
        if (id === 'searchType') {
            let temp = { ...searchObject }
            temp.searchType = value
            console.log(temp)
            setSearchObject(temp)
        }
        else if (id === 'driverStatus') {
            setVal(value)
        }

    }
    const _onChangeSearch = (e) => {
        setSearchObject({ ...searchObject, [e.target.name]: e.target.value })
    }
    const triggerSearch = () => {
        dispatch(listingApi({
            pageSize: "",
            bookmark: "",
            searchType: searchObject.searchType,
            searchValue: searchObject.searchValue,
            orgId: "",
            docType: 'driver'
        }))
    }
    useEffect(() => {
        if (val) {
            dispatch(listingApi({
                pageSize: "",
                bookmark: "",
                searchType: '',
                searchValue: '',
                orgId: "",
                docType: 'driver',
                orgId: "",
                blacklistedFlag: JSON.parse(val),
            }))
        }
    }, [val])

    // staff

    const nextPaginationStaff = () => {
        var object = {
            param: "next",
            bookmark: staffBook[staffBook.length - 1],
            docType: "staff"
        }
        setObjStaff(object)
        dispatch(listingApi(object))
    }
    const previousPaginationStaff = () => {
        var object = {
            param: "prev",
            bookmark: staffBook.length >= 3 ? staffBook[staffBook.length - 3] : '',
            docType: "staff"
        }
        setObjStaff(object)
        dispatch(listingApi(object))
        staffBook.pop()
    }
    const _staffsearchDrop = (id, value) => {
        if (id === 'staffsearchType') {
            let temp = { ...staffSearchObj }
            temp.searchType = value
            setStaffObj(temp)
        }
        else if (id === 'genderDrop') {
            setGenVal(value)
        }
    }
    const _onStaffChangeSearch = (e) => {
        setStaffObj({ ...staffSearchObj, [e.target.name]: e.target.value })
    }
    const triggerStaffSearch = () => {
        dispatch(listingApi({
            pageSize: "",
            bookmark: "",
            searchType: staffSearchObj.searchType,
            searchValue: staffSearchObj.searchValue,
            gender: "",
            docType: "staff",
            param: "next"
        }))
    }
    useEffect(() => {
        if (genVal) {
            dispatch(listingApi({
                pageSize: "",
                bookmark: "",
                searchType: "",
                searchValue: "",
                gender: genVal,
                docType: "staff",
                // param: "next"
            }))
        }
    }, [genVal])

    const blacklist = (item) => {
        const finalObj = {
            dlNumber : item.DLNumber,
            endorseDate  :  item.EndorseDate,
            endorseNumber : item.EndorseNumber,
            expiryDate : item.ExpiryDate,
            fatherName : item.FatherName,
            issuedDate : item.IssuedDate,
            licenseType : item.LicenseType,
            vaccinationStatus : item.VaccinationStatus,
            mobileNo : item.MobileNo,
            addresses : item.Addresses,
            blacklistedFlag: item.BlacklistedFlag === false ? true : false,
            name : item.Name,
            emailId : item.EmailId 
        }
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        window.$.fn.show_notification({ message: `Are you sure ? `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
            if (result.type === 'confirm') {
                axios.post('https://www.betaenterprise.lynkit.io/api/driver/UpdateDriver', { ...finalObj }, { headers: config.headers })
                    .then(response => {
                        const message = response.data.message
                        const err = response.data.error
                       dispatch(listingApi(obj))
                        if (err === 'false') {
                            window.$.fn.show_notification({ message: `${message} `, title: 'Success!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                            
                        }
                        else {
                            window.$.fn.show_notification({ message: `${message} `, title: 'Alert!!', autohide: true, showInput: false }, (result) => {
                                if (result.type === 'confirm') {
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                    })
            }
        })

    }
    return (
        <div className="contentpanel">
            <h1 className="title">Workforce</h1>
            <hr />
            <div className="cust-container">
                {force === 'driver' ?
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Workforce List</div>
                        </div>
                        <div className='cust-col-8'>
                            <div className="input-group">

                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="select" id="searchType">
                                        <option value="select" disabled>Select</option>
                                        <option value="dlNumber">License No.</option>
                                        <option value="name">Driver Name</option>
                                        <option value="mobileNo">Mobile Number</option>
                                    </select>
                                </div>
                                <input type="text" className="form-control" name='searchValue' value={searchObject.searchValue} onChange={(e) => _onChangeSearch(e)} placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2"
                                        disabled={searchObject.searchType === 'select' || searchObject.searchType === undefined ? true : false}
                                        onClick={() => triggerSearch()}>
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                                <div className='inputBtnAppend'>
                                    <button className={force === 'driver' ? 'DriverInputActive' : 'DriverInputInactive'} onClick={() => setWorkForce('driver')}>Driver</button>
                                    <button className={force === 'staff' ? 'DriverInputActive' : 'DriverInputInactive'} onClick={() => setWorkForce('staff')}>Staff</button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="status" id="driverStatus">
                                <option value="status" disabled>Driver Status</option>
                                <option value="false">Approved Driver</option>
                                <option value="true">Blacklisted Driver</option>
                            </select>
                        </div>
                        <div className='cust-col-2'>
                            {
                                localStorage.getItem("userGroup") !== 'transporter' ?
                                    <select className="form-control select2-single" defaultValue="all" id="transporter">
                                        <option value="all">Transporter's Name</option>
                                        <option value="ajay">Ajay-(1101)</option>
                                        <option value="nitin">Nitin-(1102)</option>
                                    </select>
                                    : ""
                            }
                        </div>
                        <div className='cust-col-2 flex-jc-end'>
                            <button className='btn btn-shadow btn-secondary size_hgt' onClick={() => { _handleClick('workforceModal') }}>Add Workforce</button>
                        </div>
                    </div>
                    : ''}
                {force === 'staff' ?
                    <div className='cust-row'>
                        <div className='cust-col-2 flex-algn-cent'>
                            <div className='tt2'>Workforce List</div>
                        </div>
                        <div className='cust-col-8'>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <select className="select2-single" defaultValue="select" id="staffsearchType">
                                    <option value="select">select</option>
                                        <option value="name">Name</option>
                                        <option value="mobileNo">Mobile Number</option>
                                        {/* <option value="staffId">Staff ID</option> */}
                                    </select>
                                </div>
                                <input type="text" className="form-control" name='searchValue' value={staffSearchObj.searchValue} onChange={(e) => _onStaffChangeSearch(e)} placeholder="Search here..." />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2"
                                        disabled={staffSearchObj.searchType === 'select' || staffSearchObj.searchType === undefined ? true : false}
                                        onClick={() => triggerStaffSearch()}>
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                                <div className='inputBtnAppend'>
                                    <button className={force === 'driver' ? 'DriverInputActive' : 'DriverInputInactive'} onClick={() => setWorkForce('driver')}>Driver</button>
                                    <button className={force === 'staff' ? 'DriverInputActive' : 'DriverInputInactive'} onClick={() => setWorkForce('staff')}>Staff</button>
                                </div>
                            </div>
                        </div>
                        <div className='cust-col-2'>
                            <select className="form-control select2-single" defaultValue="status" id="genderDrop">
                                <option value="gender">Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className='cust-col-2'>
                            {
                                localStorage.getItem("userGroup") !== 'transporter' ?
                                    <select className="form-control select2-single" defaultValue="all" id="transporter">
                                        <option value="all">Transporter's Name</option>
                                        <option value="ajay">Ajay-(1101)</option>
                                        <option value="nitin">Nitin-(1102)</option>
                                    </select>
                                    : ""
                            }
                        </div>
                        <div className='cust-col-2 flex-jc-end'>
                            <button className='btn btn-shadow btn-secondary size_hgt' onClick={() => { _handleClick('workforceModal') }}>Add Workforce</button>
                        </div>
                    </div>
                    : ''}


                <div className='cust-row'>
                    <div className='cust-col-10'>
                        <div className='data-cont'>
                            {force === 'driver' ?
                                driverList && driverList.length !== 0 ?
                                    loader === true ?
                                        <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                            <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                                        </div>
                                        :
                                        driverList.map((item, index) => {
                                            return (
                                                <div key={index} className={item.BlacklistedFlag === true ? 'data-cont-item blackListBrdr' : 'data-cont-item'}>
                                                    <div className='data_item_content align-div pad-0'>
                                                        <div className='wd_20 clip-path display-col'>
                                                            <span className='wfSpan'>Driver </span>
                                                        </div>
                                                        <div className='wd_25 bg-trans inc-hgt'>
                                                            <div className='dateTime'>
                                                                <span>Date/Time</span>
                                                            </div>
                                                            <div className='date'>
                                                                <i className="fa fa-calendar"></i>
                                                                <span>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>
                                                            </div>
                                                            <div className='time'>
                                                                <i className="fa fa-clock-o xl"></i>
                                                                <span>{moment(item.AddedOn).format('h:mm:ss a')}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='data_item_content set_height pad-top-0'>
                                                        <div className='wd_5 align-cent set-hght'>
                                                            <div className='sr-no '>
                                                                <span>{index + 1}</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span>Name</span>
                                                            <span>{item.Name}</span>
                                                        </div>
                                                        <div className='wd_15'>
                                                            <span>Phone Number</span>
                                                            <span>{item.MobileNo}</span>
                                                        </div>
                                                        <div className='wd_15'>
                                                            <span>License Number</span>
                                                            <span>{item.DLNumber}</span>
                                                        </div>
                                                        <div className='wd_15'>
                                                            <span>Validity from</span>
                                                            <span>{moment(item.IssuedDate).format('DD/MM/YYYY')}</span>

                                                        </div>
                                                        <div className='wd_15'>
                                                            <span>Validity to</span>
                                                            <span>{moment(item.ExpiryDate).format('DD/MM/YYYY')}</span>
                                                        </div> 
                                                        <div className='wd_15'>
                                                    <span>Blacklisted Status</span>
                                                    <span>{item.BlacklistedFlag === true ? 'True' : 'False'}</span>
                                                </div>
                                                        <div className='wd_5 align-cent set-hght set-bg'>
                                                            <div className='sr-no trans' onClick={() => { toogleItemView(index) }}>
                                                                <i className="fa fa-plus expand-tbl open"></i>
                                                                <i className="fa fa-minus contract-tbl hide" ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='data_item_content set_height_xxl setColor clps  '>
                                                        <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_15" : "wd_15"}>
                                                            <span>Date of Birth</span>
                                                            <span>{moment(item.UserDOB).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>Aadhar No.</span>
                                                            <span>{item.AadhaarNumber}</span>
                                                        </div>
                                                        <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_15" : "wd_15"}>
                                                            <span>State</span>
                                                            <span>Local -{item.Addresses[0] && item.Addresses[0].state} </span>
                                                            <span>Permanent -{item.Addresses[1] && item.Addresses[1].state}</span>
                                                        </div>
                                                        <div className='wd_15'>
                                                            <span>City</span>
                                                            <span>Local -{item.Addresses[0] && item.Addresses[0].district} </span>
                                                            <span>Permanent -{item.Addresses[1] && item.Addresses[1].district}</span>
                                                        </div>
                                                        <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_20" : "wd_20"}>
                                                            <span>Endorse Date</span>
                                                            <span>{moment(item.EndorseDate).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div className={localStorage.getItem("userGroup") === 'transporter' ? "wd_15" : "wd_15"}>
                                                            <span>Vaccination status</span>
                                                            <span>-</span>
                                                            {/* <i className="fa fa-file" id="vaccination_document"></i> */}
                                                        </div>
                                                        {localStorage.getItem("userGroup") === 'transporter' ?
                                                            <div className='wd_10' >
                                                                <span>Status</span>
                                                                <span>Active</span>
                                                            </div>
                                                            : ""}
                                                        <div className={localStorage.getItem("userGroup") === 'transporter' ? 'wd_30 no-mg-pd designChange' : 'wd_40 no-mg-pd designChange'} >
                                                            <div className='btn_row_1 '>
                                                                {localStorage.getItem("userGroup") !== 'transporter' ?
                                                                    <button className='btn btn-shadow btn-sm btn-outline-orange  '><img src={update} alt="none" />Update</button>
                                                                    : ""}
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={documentView} alt="none" />View Documents</button>
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={Delete} alt="none" />Delete</button>
                                                                {/* </div>

                                                        <div className='btn_row_2'> */}
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={() => navigateAndShow(item._id)}><img src={view} alt="none" />View Detail</button>
                                                                {localStorage.getItem("userGroup") !== 'transporter' ?
                                                                    <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={() => blacklist(item)}><img src={cancel} alt="none" />Blacklist</button>
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    :
                                    <span className='noDataClass'>No Driver Data Found</span>
                                : ''}

                            {force === 'staff' ?
                                staffList && staffList.length !== 0 ?
                                    loader === true ?
                                        <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                            <i className="fa fa-spinner fa-spin loadingSpinner font-xxl "></i>
                                        </div>
                                        :
                                        staffList.map((item, staffIndex) => {
                                            return (
                                                <div key={staffIndex} className='data-cont-item'>
                                                    <div className='data_item_content align-div pad-0'>
                                                        <div className='wd_20 clip-path display-col'>
                                                            <span className='wfSpan'>Staff </span>
                                                        </div>
                                                        <div className='wd_25 bg-trans inc-hgt'>
                                                            <div className='dateTime'>
                                                                <span>Date/Time</span>
                                                            </div>
                                                            <div className='date'>
                                                                <i className="fa fa-calendar"></i>
                                                                <span>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>
                                                            </div>
                                                            <div className='time'>
                                                                <i className="fa fa-clock-o xl"></i>
                                                                <span>{moment(item.AddedOn).format('h:mm:ss a')}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='data_item_content set_height pad-top-0'>
                                                        <div className='wd_5 align-cent set-hght'>
                                                            <div className='sr-no '>
                                                                <span>{staffIndex + 1}</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span>Name</span>
                                                            <span>{item.Name} {item.lastName}</span>
                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>Staff ID</span>
                                                            <span>{item.StaffId}</span>
                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>Gender</span>
                                                            <span>{item.Gender}</span>
                                                        </div>
                                                        <div className='wd_15'>
                                                            <span>Date of Birth</span>
                                                            <span>{moment(item.UserDOB).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div>
                                                            <span>Phone Number</span>
                                                            <span>{item.MobileNo}</span>
                                                        </div>
                                                        <div>
                                                            <span>Aadhar No.</span>
                                                            <span>{item.AadhaarNumber}</span>
                                                        </div>
                                                        <div className='wd_5 align-cent set-hght set-bg'>
                                                            <div className='sr-no trans' onClick={() => { toogleItemView(staffIndex) }}>
                                                                <i className="fa fa-plus expand-tbl open"></i>
                                                                <i className="fa fa-minus contract-tbl hide" ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='data_item_content set_height_xxl setColor clps  '>
                                                        <div className='wd_15'>
                                                            <span>Email</span>
                                                            <span>{item.EmailId}</span>
                                                        </div>
                                                        <div className='wd_20'>
                                                            <span>Address</span>
                                                            <span>{item.Address}</span>
                                                        </div>
                                                        <div className='wd_20'>
                                                            <span>Added On</span>
                                                            <span>{moment(item.AddedOn).format('DD/MM/YYYY')}</span>
                                                        </div>
                                                        <div className="wd_20">
                                                            <span>State</span>
                                                            <span>{item.State}</span>
                                                        </div>
                                                        <div className='wd_10'>
                                                            <span>City</span>
                                                            <span>{item.City}</span>
                                                        </div>
                                                        <div className="wd_15">
                                                            <span>Vaccination status</span>
                                                            <span>Full Vacinated on 12-09-2021<i className="fa fa-file" id="vaccination_document"></i></span>
                                                        </div>
                                                        <div className={localStorage.getItem("userGroup") === 'transporter' ? 'wd_30 no-mg-pd designChange' : 'wd_35 no-mg-pd designChange'} >
                                                            <div className='btn_row_1'>
                                                                {localStorage.getItem("userGroup") !== 'transporter' ?
                                                                    <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={update} alt="none" />Update</button>
                                                                    : ""}
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={documentView} alt="none" />View Documents</button>
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={Delete} alt="none" />Delete</button>
                                                                {/* </div>

                                                        <div className='btn_row_2'> */}
                                                                <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1' onClick={() => navigateAndShowStaff(item._id)}><img src={view} alt="none" />View Detail</button>
                                                                {localStorage.getItem("userGroup") !== 'transporter' ?
                                                                    <button className='btn btn-shadow btn-sm btn-outline-orange ml-1 mr-1'><img src={cancel} alt="none" />Blacklist</button>
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    :
                                    <span className='noDataClass'>No Staff Data Found</span>
                                : ''}

                        </div>
                    </div>
                </div>
                {force === 'driver' && driverList && driverList.length !== 0 ?
                    <div className='paginationDiv' style={{position:'absolute', bottom:'-15px'}}>
                        <button disabled={obj && obj.bookmark === '' ? true : false} onClick={() => previousPagination()} className='paginateNext' >Previous</button>
                        <button className='paginateNext' disabled={records < 5 ? true : false} onClick={() => nextPagination()}>Next</button>
                    </div>
                    : ''}
                {force === 'staff' && staffList && staffList.length !== 0 ?
                    <div className='paginationDiv' style={{position:'absolute', bottom:'-15px'}}>
                        <button disabled={staffobj && staffobj.bookmark === '' ? true : false} onClick={() => previousPaginationStaff()} className='paginateNext' >Previous</button>
                        <button className='paginateNext' disabled={staffRecords < 5 ? true : false} onClick={() => nextPaginationStaff()}>Next</button>
                    </div>
                    : ''}

            </div>
            <ForceModal closeModal={_closeModal} />
        </div >

    )

}

export default Workforce;